// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "../Title.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as DateFns from "../DateFns.mjs";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as AvoUtils from "../../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "../AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../ModelUtils.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as IconErrorHexagon from "../IconErrorHexagon.mjs";
import * as IconTrackingPlan from "../IconTrackingPlan.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as IconCheckmarkCircle from "../IconCheckmarkCircle.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as InspectorViewSelectors from "./InspectorViewSelectors.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";
import * as InspectorViewTrackingPlanUtils from "./InspectorViewTrackingPlanUtils.mjs";

var locationStyles = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.FontFamily.polar),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.medium),
        tl: {
          hd: Css.fontStyle("normal"),
          tl: /* [] */0
        }
      }
    });

function InspectorViewContextSidebarIssues$IssueSeen(Props) {
  var cell = Props.cell;
  var sourceName = Props.sourceName;
  var versionSeen = Props.versionSeen;
  var tmp;
  if (typeof cell === "object") {
    var variant = cell.NAME;
    tmp = variant === "EventSourceIssues" || variant === "PropertySourceIssues" || variant === "EventSourceVolume" ? React.createElement(React.Fragment, undefined, "in version ", React.createElement("em", {
                className: locationStyles
              }, versionSeen.version)) : (
        variant === "EventIssues" || variant === "EventName" || variant === "EventVolume" || variant === "PropertyIssues" ? React.createElement(React.Fragment, undefined, "on ", React.createElement("em", {
                    className: locationStyles
                  }, sourceName + " (" + versionSeen.version + ")")) : null
      );
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: DateFns.formatDistance(undefined, undefined, new Date(), new Date(versionSeen.date)) + " ago "
                }), tmp);
}

var IssueSeen = {
  locationStyles: locationStyles,
  make: InspectorViewContextSidebarIssues$IssueSeen
};

function getCellPropertyVolume(cell, propertyName) {
  var $$event;
  if (typeof cell !== "object") {
    return ;
  }
  var variant = cell.NAME;
  if (variant === "PropertyVolume" || variant === "PropertyName" || variant === "PropertyIssues") {
    $$event = cell.VAL[0];
  } else {
    if (variant === "PropertySourceVolume" || variant === "PropertySourceIssues") {
      var match = cell.VAL;
      var $$event$1 = match[0];
      return Belt_Option.map(match[2], (function (propertySource) {
                    var maybeEventSource = InspectorViewSelectors.getEventSourceBySourceId($$event$1.sources, propertySource.sourceId);
                    var currentPropertyVolume = InspectorViewSelectors.countPropertyVolume("current", [propertySource]);
                    var eventCount = Belt_Option.mapWithDefault(maybeEventSource, 0, (function (source) {
                            return source.count.current.total;
                          }));
                    return currentPropertyVolume / eventCount * 100;
                  }));
    }
    if (variant === "EventIssues" || variant === "EventName" || variant === "EventVolume") {
      $$event = cell.VAL;
    } else {
      var match$1 = cell.VAL;
      var maybeEventSource = match$1[1];
      var maybeProperty = Belt_Array.getByU(match$1[0].properties, (function (property) {
              return property.propertyName === propertyName;
            }));
      if (maybeEventSource === undefined) {
        return ;
      }
      if (maybeProperty === undefined) {
        return ;
      }
      var propertySource = Belt_Array.getByU(maybeProperty.sources, (function (propertySource) {
              return propertySource.sourceId === maybeEventSource.sourceId;
            }));
      return Belt_Option.map(propertySource, (function (source) {
                    var currentPropertyVolume = InspectorViewSelectors.countPropertyVolume("current", [source]);
                    var eventSourceCount = maybeEventSource.count.current.total;
                    return currentPropertyVolume / eventSourceCount * 100;
                  }));
    }
  }
  return Belt_Option.map(Belt_Array.getByU($$event.properties, (function (property) {
                    return property.propertyName === propertyName;
                  })), (function (property) {
                var currentPropertyVolume = InspectorViewSelectors.countPropertyVolume("current", property.sources);
                var eventCount = $$event.count.current.total;
                return currentPropertyVolume / eventCount * 100;
              }));
}

var rootStyles = Curry._1(Css.style, {
      hd: Css.listStyleType("none"),
      tl: {
        hd: Css.paddingTop(Css.px(16)),
        tl: {
          hd: Css.paddingBottom(Css.px(16)),
          tl: {
            hd: Css.marginRight(Css.px(24)),
            tl: {
              hd: Css.marginLeft(Css.px(24)),
              tl: {
                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var typeStyles = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.FontFamily.polar),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.medium),
        tl: {
          hd: Css.fontStyle("normal"),
          tl: /* [] */0
        }
      }
    });

var origins = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexWrap("wrap"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginBottom(Css.px(4)),
            tl: {
              hd: Css.marginTop(Css.px(4)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var originsList = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(0)),
      tl: {
        hd: Css.marginBottom(Css.px(4)),
        tl: {
          hd: Css.margin(Css.px(0)),
          tl: /* [] */0
        }
      }
    });

var origin = Curry._1(Css.style, {
      hd: Css.listStyleType("none"),
      tl: {
        hd: Css.display("inlineBlock"),
        tl: {
          hd: Css.color(Styles.Color.white),
          tl: {
            hd: Css.fontSize(Styles.FontSize.small),
            tl: {
              hd: Css.fontWeight(Styles.FontWeight.semi),
              tl: {
                hd: Css.backgroundColor(Styles.Color.light07),
                tl: {
                  hd: Css.paddingLeft(Css.px(8)),
                  tl: {
                    hd: Css.paddingTop(Css.px(2)),
                    tl: {
                      hd: Css.paddingRight(Css.px(8)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(2)),
                        tl: {
                          hd: Css.borderRadius(Css.px(100)),
                          tl: {
                            hd: Css.marginTop(Css.px(4)),
                            tl: {
                              hd: Css.marginRight(Css.px(4)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function InspectorViewContextSidebarIssues$IssueGroup(Props) {
  var schemaSources = Props.schemaSources;
  var cell = Props.cell;
  var issueGroup = Props.issueGroup;
  var issues = Belt_Array.mapU(issueGroup, (function (param) {
          return param[1];
        }));
  return Belt_Option.mapWithDefault(Belt_Array.get(issueGroup, 0), null, (function (param) {
                var issue = param[1];
                var variant = issue.NAME;
                var tmp;
                if (variant === "MissingExpectedProperty") {
                  tmp = React.createElement(React.Fragment, undefined, "Required Property not sent with event ", React.createElement("em", {
                            className: typeStyles
                          }));
                } else if (variant === "InconsistentType") {
                  var propertyTypes = InspectorViewSelectors.getPropertyTypesFromIssues(issues);
                  tmp = React.createElement(React.Fragment, undefined, "sending types ", Belt_Array.mapWithIndexU(propertyTypes, (function (index, issue) {
                              if ((propertyTypes.length - 2 | 0) === index) {
                                return React.createElement(React.Fragment, {
                                            children: null,
                                            key: index
                                          }, React.createElement("em", {
                                                className: typeStyles
                                              }, issue), " and ");
                              } else if ((propertyTypes.length - 1 | 0) === index) {
                                return React.createElement("em", {
                                            key: String(index),
                                            className: typeStyles
                                          }, issue);
                              } else {
                                return React.createElement(React.Fragment, {
                                            children: null,
                                            key: index
                                          }, React.createElement("em", {
                                                className: typeStyles
                                              }, issue), ", ");
                              }
                            })));
                } else if (variant === "SometimesMissing") {
                  var propertyPresences = InspectorViewSelectors.getPropertyPresencesFromIssues(issues);
                  var propertyPresences$1 = propertyPresences.length !== 1 ? Belt_Array.keepU(propertyPresences, (function (presence) {
                            return presence !== "key-and-value-present";
                          })) : [propertyPresences[0]];
                  var prettyPrintPresence = function (presence) {
                    switch (presence) {
                      case "key-and-value-present" :
                          return "always sent";
                      case "key-missing" :
                          return "sometimes not provided";
                      case "value-missing" :
                          return "sometimes sent as null or undefined";
                      default:
                        return presence;
                    }
                  };
                  tmp = React.createElement(React.Fragment, undefined, " is ", Belt_Array.mapWithIndexU(propertyPresences$1, (function (index, presence) {
                              if ((propertyPresences$1.length - 2 | 0) === index) {
                                return React.createElement(React.Fragment, {
                                            children: null,
                                            key: index
                                          }, React.createElement("strong", undefined, prettyPrintPresence(presence)), " and ");
                              } else if ((propertyPresences$1.length - 1 | 0) === index) {
                                return React.createElement("strong", {
                                            key: String(index)
                                          }, prettyPrintPresence(presence));
                              } else {
                                return React.createElement(React.Fragment, {
                                            children: null,
                                            key: index
                                          }, React.createElement("strong", undefined, prettyPrintPresence(presence)), ", ");
                              }
                            })), Belt_Option.mapWithDefault(getCellPropertyVolume(cell, issue.VAL.propertyName), null, (function (volume) {
                              return React.createElement($$Text.make, {
                                          color: Styles.Color.light10,
                                          children: null
                                        }, "Missing in ", React.createElement($$Text.make, {
                                              element: "Span",
                                              weight: "Semi",
                                              children: null
                                            }, String(Js_math.ceil(100.0 - volume)), "%"), " of Event Volume");
                            })));
                } else if (variant === "UnexpectedProperty") {
                  tmp = React.createElement(React.Fragment, undefined, "Unexpected Property on event", Belt_Option.mapWithDefault(getCellPropertyVolume(cell, issue.VAL.propertyName), null, (function (volume) {
                              return React.createElement($$Text.make, {
                                          color: Styles.Color.light10,
                                          children: null
                                        }, "appeared in ", React.createElement($$Text.make, {
                                              element: "Span",
                                              weight: "Semi",
                                              children: null
                                            }, String(Js_math.ceil(volume)), "%"), " of Event Volume");
                            })));
                } else if (variant === "PropertyTypeInconsistentWithTrackingPlan") {
                  var match = issue.VAL;
                  tmp = React.createElement(React.Fragment, undefined, "Tracking Plan expects ", React.createElement("em", {
                            className: typeStyles
                          }, match.modelType), " but is sent as ", React.createElement("em", {
                            className: typeStyles
                          }, match.propertyType.type_));
                } else {
                  tmp = "not found in Tracking Plan";
                }
                var tmp$1;
                if (typeof cell === "object") {
                  var variant$1 = cell.NAME;
                  if (variant$1 === "PropertySourceVolume" || variant$1 === "EventSourceIssues" || variant$1 === "PropertySourceIssues" || variant$1 === "EventSourceVolume") {
                    var variant$2 = issue.NAME;
                    tmp$1 = React.createElement("div", {
                          className: origins
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light10,
                              children: "Versions"
                            }), React.createElement(Spacer.make, {
                              width: 4
                            }), React.createElement("ul", {
                              className: originsList
                            }, variant$2 === "MissingExpectedProperty" ? Belt_Array.mapU(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.mapU(Belt_Array.keepMapU(issueGroup, (function (param) {
                                                                var issue = param[1];
                                                                if (typeof issue === "object" && issue.NAME === "MissingExpectedProperty") {
                                                                  return issue.VAL.propertyType;
                                                                }
                                                                
                                                              })), (function (type_) {
                                                            return type_.count.history.versions;
                                                          }))), (function (version) {
                                                    return version.version;
                                                  })))).sort(Caml.caml_string_compare), (function (version) {
                                      return React.createElement("li", {
                                                  key: version,
                                                  className: origin
                                                }, version);
                                    })) : (
                                variant$2 === "InconsistentType" ? Belt_Array.mapU(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.mapU(issueGroup, (function (param) {
                                                                        var issue = param[1];
                                                                        if (typeof issue === "object") {
                                                                          if (issue.NAME === "InconsistentType") {
                                                                            return issue.VAL.types;
                                                                          } else {
                                                                            return [];
                                                                          }
                                                                        } else {
                                                                          return [];
                                                                        }
                                                                      }))), (function (type_) {
                                                                return type_.count.history.versions;
                                                              }))), (function (version) {
                                                        return version.version;
                                                      })))).sort(Caml.caml_string_compare), (function (version) {
                                          return React.createElement("li", {
                                                      key: version,
                                                      className: origin
                                                    }, version);
                                        })) : (
                                    variant$2 === "SometimesMissing" ? Belt_Array.mapU(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.mapU(issueGroup, (function (param) {
                                                                            var issue = param[1];
                                                                            if (typeof issue === "object") {
                                                                              if (issue.NAME === "SometimesMissing") {
                                                                                return issue.VAL.presences;
                                                                              } else {
                                                                                return [];
                                                                              }
                                                                            } else {
                                                                              return [];
                                                                            }
                                                                          }))), (function (type_) {
                                                                    return type_.count.history.versions;
                                                                  }))), (function (version) {
                                                            return version.version;
                                                          })))).sort(Caml.caml_string_compare), (function (version) {
                                              return React.createElement("li", {
                                                          key: version,
                                                          className: origin
                                                        }, version);
                                            })) : (
                                        variant$2 === "UnexpectedProperty" ? Belt_Array.mapU(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.keepMapU(issueGroup, (function (param) {
                                                                                var issue = param[1];
                                                                                if (typeof issue === "object" && issue.NAME === "UnexpectedProperty") {
                                                                                  return issue.VAL.propertyTypes;
                                                                                }
                                                                                
                                                                              }))), (function (type_) {
                                                                        return type_.count.history.versions;
                                                                      }))), (function (version) {
                                                                return version.version;
                                                              })))).sort(Caml.caml_string_compare), (function (version) {
                                                  return React.createElement("li", {
                                                              key: version,
                                                              className: origin
                                                            }, version);
                                                })) : (
                                            variant$2 === "PropertyTypeInconsistentWithTrackingPlan" ? Belt_Array.mapU(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.mapU(Belt_Array.keepMapU(issueGroup, (function (param) {
                                                                                var issue = param[1];
                                                                                if (typeof issue === "object" && issue.NAME === "PropertyTypeInconsistentWithTrackingPlan") {
                                                                                  return issue.VAL.propertyType;
                                                                                }
                                                                                
                                                                              })), (function (type_) {
                                                                            return type_.count.history.versions;
                                                                          }))), (function (version) {
                                                                    return version.version;
                                                                  })))).sort(Caml.caml_string_compare), (function (version) {
                                                      return React.createElement("li", {
                                                                  key: version,
                                                                  className: origin
                                                                }, version);
                                                    })) : Belt_Array.mapU(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.keepMapU(issueGroup, (function (param) {
                                                                                    var issue = param[1];
                                                                                    if (typeof issue === "object" && issue.NAME === "PropertyNotInTrackingPlan") {
                                                                                      return issue.VAL.propertyTypes;
                                                                                    }
                                                                                    
                                                                                  }))), (function (type_) {
                                                                            return type_.count.history.versions;
                                                                          }))), (function (version) {
                                                                    return version.version;
                                                                  })))).sort(Caml.caml_string_compare), (function (version) {
                                                      return React.createElement("li", {
                                                                  key: version,
                                                                  className: origin
                                                                }, version);
                                                    }))
                                          )
                                      )
                                  )
                              )));
                  } else {
                    tmp$1 = variant$1 === "EventIssues" || variant$1 === "EventName" || variant$1 === "EventVolume" || variant$1 === "PropertyIssues" ? React.createElement("div", {
                            className: origins
                          }, React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light10,
                                children: "Sources"
                              }), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement("ul", {
                                className: originsList
                              }, Belt_Array.mapU(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(issueGroup, (function (param) {
                                                  return param[0];
                                                })))), (function (sourceId) {
                                      return React.createElement("li", {
                                                  key: sourceId,
                                                  className: origin
                                                }, Belt_Option.getWithDefault(AvoConfig.sourceIdToName(sourceId, schemaSources), "-"));
                                    })))) : null;
                  }
                } else {
                  tmp$1 = null;
                }
                var match$1 = InspectorViewSelectors.getFirstOrLastSeenIssue("FirstSeen", issueGroup);
                return React.createElement("article", {
                            className: rootStyles
                          }, React.createElement($$Text.make, {
                                element: "H1",
                                size: "Medium",
                                weight: "Semi",
                                children: InspectorViewSelectors.getIssuePropertyName(issue)
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.red,
                                children: tmp
                              }), tmp$1, match$1 !== undefined ? React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light10,
                                  children: null
                                }, "First seen ", React.createElement(InspectorViewContextSidebarIssues$IssueSeen, {
                                      cell: cell,
                                      sourceName: Belt_Option.getWithDefault(AvoConfig.sourceIdToName(match$1[0], schemaSources), "-"),
                                      versionSeen: match$1[2]
                                    })) : React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light10,
                                  children: null
                                }, "First seen ", React.createElement($$Text.make, {
                                      element: "Span",
                                      weight: "Semi",
                                      children: "Unknown"
                                    })), Belt_Option.mapWithDefault(InspectorViewSelectors.getFirstOrLastSeenIssue("LastSeen", issueGroup), null, (function (param) {
                                  return React.createElement($$Text.make, {
                                              size: "Small",
                                              color: Styles.Color.light10,
                                              children: null
                                            }, "Last seen ", React.createElement(InspectorViewContextSidebarIssues$IssueSeen, {
                                                  cell: cell,
                                                  sourceName: Belt_Option.getWithDefault(AvoConfig.sourceIdToName(param[0], schemaSources), "-"),
                                                  versionSeen: param[2]
                                                }));
                                })));
              }));
}

var IssueGroup = {
  rootStyles: rootStyles,
  typeStyles: typeStyles,
  origins: origins,
  originsList: originsList,
  origin: origin,
  make: InspectorViewContextSidebarIssues$IssueGroup
};

var rootStyles$1 = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: /* [] */0
    });

function buttonStyles(disabled) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("row"),
                          tl: {
                            hd: Css.alignItems("flexStart"),
                            tl: {
                              hd: Css.padding(Css.px(16)),
                              tl: {
                                hd: Css.marginTop(Css.px(-1)),
                                tl: {
                                  hd: Css.marginLeft(Css.px(8)),
                                  tl: {
                                    hd: Css.marginRight(Css.px(8)),
                                    tl: {
                                      hd: Css.cursor(disabled ? "default" : "pointer"),
                                      tl: {
                                        hd: Css.borderRadius(Styles.Border.radius),
                                        tl: {
                                          hd: Css.hover(disabled ? /* [] */0 : ({
                                                    hd: Css.backgroundColor(Styles.Color.light02),
                                                    tl: /* [] */0
                                                  })),
                                          tl: {
                                            hd: Css.backgroundColor("transparent"),
                                            tl: {
                                              hd: Css.transition({
                                                    NAME: "ms",
                                                    VAL: Styles.Duration.$$default
                                                  }, undefined, undefined, "background"),
                                              tl: {
                                                hd: Css.after({
                                                      hd: Css.display("block"),
                                                      tl: {
                                                        hd: Css.position("absolute"),
                                                        tl: {
                                                          hd: Css.backgroundColor(Styles.Color.light02),
                                                          tl: {
                                                            hd: Css.height(Css.px(1)),
                                                            tl: {
                                                              hd: Css.left(Css.px(16)),
                                                              tl: {
                                                                hd: Css.right(Css.px(16)),
                                                                tl: {
                                                                  hd: Css.bottom(Css.px(0)),
                                                                  tl: {
                                                                    hd: Css.unsafe("content", "' '"),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var buttonInfoStyles = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.marginLeft(Css.px(12)),
        tl: /* [] */0
      }
    });

function buttonChevronStyles(isOpen) {
  return Curry._1(Css.style, {
              hd: Css.alignSelf("stretch"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
                    tl: {
                      hd: Css.transform({
                            NAME: "rotate",
                            VAL: {
                              NAME: "deg",
                              VAL: isOpen ? 0.0 : 90.0
                            }
                          }),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$default
                            }, undefined, undefined, "transform"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var openBorderStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.bottom(Css.px(16)),
          tl: {
            hd: Css.top(Css.px(16)),
            tl: {
              hd: Css.width(Css.px(2)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.red),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function InspectorViewContextSidebarIssues$IssueType(Props) {
  var schemaSources = Props.schemaSources;
  var cell = Props.cell;
  var issueGroups = Props.issueGroups;
  var title = Props.title;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setisOpen = match[1];
  var isOpen = match[0];
  var isDisabled = Caml_obj.caml_equal(issueGroups, []);
  var match$1 = issueGroups.length;
  var tmp;
  var exit = 0;
  if (match$1 !== 0) {
    if (typeof cell === "object") {
      var variant = cell.NAME;
      if (variant === "PropertySourceVolume" || variant === "PropertyVolume" || variant === "PropertyName" || variant === "PropertySourceIssues" || variant === "PropertyIssues") {
        tmp = React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.red,
              children: "Affects this property"
            });
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
  } else {
    tmp = null;
  }
  if (exit === 1) {
    tmp = React.createElement($$Text.make, {
          size: "Small",
          weight: "Semi",
          color: Styles.Color.red,
          children: "Affects " + AvoUtils.plural("properties", undefined, match$1, "property")
        });
  }
  return React.createElement("section", {
              className: rootStyles$1
            }, React.createElement("a", {
                  className: buttonStyles(isDisabled),
                  role: "button",
                  tabIndex: 0,
                  onClick: (function (param) {
                      if (!isOpen) {
                        AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle, "ExpandIssueType", schemaBundle.schemaId);
                      }
                      return Curry._1(setisOpen, (function (isOpen) {
                                    if (isDisabled) {
                                      return false;
                                    } else {
                                      return !isOpen;
                                    }
                                  }));
                    })
                }, React.createElement(IconErrorHexagon.make, {
                      size: 16
                    }), React.createElement("header", {
                      className: buttonInfoStyles
                    }, React.createElement($$Text.make, {
                          element: "H1",
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: title
                        }), tmp), Caml_obj.caml_notequal(issueGroups, []) ? React.createElement("div", {
                        className: buttonChevronStyles(isOpen)
                      }, React.createElement("svg", {
                            height: "6",
                            width: "10",
                            fill: "none",
                            viewBox: "0 0 10 6",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, React.createElement("path", {
                                d: "M1 1L5 5L9 1",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeMiterlimit: "10"
                              }))) : null), isOpen ? Belt_Array.mapWithIndexU(issueGroups, (function (index, issueGroup) {
                      return React.createElement(InspectorViewContextSidebarIssues$IssueGroup, {
                                  schemaSources: schemaSources,
                                  cell: cell,
                                  issueGroup: issueGroup,
                                  key: String(index)
                                });
                    })) : null, isOpen ? React.createElement("div", {
                    className: openBorderStyles
                  }) : null);
}

var IssueType = {
  rootStyles: rootStyles$1,
  buttonStyles: buttonStyles,
  buttonInfoStyles: buttonInfoStyles,
  buttonChevronStyles: buttonChevronStyles,
  openBorderStyles: openBorderStyles,
  make: InspectorViewContextSidebarIssues$IssueType
};

var issueFreeGroup = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("flexStart"),
            tl: {
              hd: Css.marginLeft(Css.px(24)),
              tl: {
                hd: Css.marginRight(Css.px(24)),
                tl: {
                  hd: Css.paddingTop(Css.px(16)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(16)),
                    tl: {
                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function InspectorViewContextSidebarIssues$IssueFreeType(Props) {
  var healthyCell = Props.healthyCell;
  var title = Props.title;
  var hasVolume = Props.hasVolume;
  return React.createElement("div", {
              className: issueFreeGroup
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexShrink(0.0),
                        tl: {
                          hd: Css.marginRight(Css.px(12)),
                          tl: /* [] */0
                        }
                      })
                }, hasVolume ? React.createElement(IconCheckmarkCircle.make, {
                        color: healthyCell ? Styles.Color.green : Styles.Color.light08
                      }) : React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light08,
                        children: "—"
                      })), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: title
                }));
}

var IssueFreeType = {
  issueFreeGroup: issueFreeGroup,
  make: InspectorViewContextSidebarIssues$IssueFreeType
};

var rootStyles$2 = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: /* [] */0
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(16)),
      tl: {
        hd: Css.paddingBottom(Css.px(16)),
        tl: {
          hd: Css.marginLeft(Css.px(24)),
          tl: {
            hd: Css.marginRight(Css.px(24)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
              tl: /* [] */0
            }
          }
        }
      }
    });

var titleStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.alignItems("center"),
            tl: /* [] */0
          }
        }
      }
    });

var titleTextStyles = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.small),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.color(Styles.Color.light08),
          tl: {
            hd: Css.margin(Css.px(0)),
            tl: /* [] */0
          }
        }
      }
    });

var subtitleStyles = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.huge),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.margin4(Css.px(6), Css.px(0), Css.px(0), Css.px(0)),
          tl: {
            hd: Css.overflowWrap("breakWord"),
            tl: /* [] */0
          }
        }
      }
    });

var issuesContext = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.regular),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.color(Styles.Color.light08),
          tl: {
            hd: Css.marginTop(Css.px(8)),
            tl: {
              hd: Css.marginBottom(Css.px(0)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var trackingPlanStatus = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("left"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.marginTop(Css.px(8)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var trackingPlanStatusText = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

function InspectorViewContextSidebarIssues(Props) {
  var cell = Props.cell;
  var eventName = Props.eventName;
  var onAddEvent = Props.onAddEvent;
  var onAddProperty = Props.onAddProperty;
  var sourceIds = Props.sourceIds;
  var sourceIssues = Props.sourceIssues;
  var model = Props.model;
  var viewerRole = Props.viewerRole;
  var addToast = Toast.useAddToast(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var modelEvent = ModelUtils.getEventByName(eventName, model);
  var match;
  if (typeof cell === "object") {
    var variant = cell.NAME;
    if (variant === "PropertySourceVolume" || variant === "PropertyVolume" || variant === "PropertyName" || variant === "PropertySourceIssues" || variant === "PropertyIssues") {
      var property = cell.VAL[1];
      match = [
        ModelUtils.getPropertyByName(property.propertyName, model),
        property
      ];
    } else {
      match = [
        undefined,
        undefined
      ];
    }
  } else {
    match = [
      undefined,
      undefined
    ];
  }
  var inspectorProperty = match[1];
  var modelProperty = match[0];
  var handleCopyLink = function (_event) {
    CopyTextToClipboard(window.location.href);
    AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle, "CopyEventIssuesLink", schemaBundle.schemaId);
    return Curry._1(addToast, {
                message: "Link copied!",
                toastType: /* Success */0
              });
  };
  var workspace = WorkspaceContext.use(undefined);
  var tmp;
  if (typeof cell === "object") {
    var variant$1 = cell.NAME;
    tmp = variant$1 === "PropertySourceVolume" || variant$1 === "EventSourceIssues" || variant$1 === "PropertySourceIssues" || variant$1 === "EventSourceVolume" ? Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_List.head(sourceIds), (function (sourceId) {
                  return AvoConfig.sourceIdToName(sourceId, model.sources);
                })), "N/A") : "All Sources";
  } else {
    tmp = "All Sources";
  }
  var tmp$1;
  if (typeof cell === "object") {
    var variant$2 = cell.NAME;
    tmp$1 = variant$2 === "PropertyIssues" || variant$2 === "PropertySourceVolume" || variant$2 === "PropertyVolume" || variant$2 === "PropertyName" || variant$2 === "PropertySourceIssues" ? cell.VAL[1].propertyName : "All Properties";
  } else {
    tmp$1 = "All Properties";
  }
  var tmp$2;
  if (modelProperty !== undefined) {
    if (inspectorProperty !== undefined && modelEvent !== undefined) {
      var worstIssue = InspectorViewTrackingPlanUtils.getWorstPropertyIssue(inspectorProperty);
      var worstIssue$1 = worstIssue !== undefined && typeof worstIssue === "object" && worstIssue.NAME === "UnexpectedProperty" && !InspectorViewTrackingPlanUtils.isUnexpectedPropertyOnAllSources(inspectorProperty) ? undefined : worstIssue;
      var exit = 0;
      if (worstIssue$1 !== undefined) {
        var variant$3 = worstIssue$1.NAME;
        if (variant$3 === "PropertyTypeInconsistentWithTrackingPlan" || variant$3 === "SometimesMissing" || variant$3 === "InconsistentType" || variant$3 === "MissingExpectedProperty") {
          exit = 1;
        } else {
          tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                    height: 4
                  }), React.createElement("div", {
                    className: trackingPlanStatus
                  }, React.createElement(Button.make, {
                        label: "Add Property To Event",
                        onClick: (function (param) {
                            AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle, "ClickAddPropertyToTrackingPlan", schemaBundle.schemaId);
                            return Curry._1(onAddProperty, inspectorProperty);
                          })
                      })));
        }
      } else {
        exit = 1;
      }
      if (exit === 1) {
        tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement("div", {
                  className: trackingPlanStatus
                }, React.createElement(Button.make, {
                      icon: "sidebar",
                      label: "View Property",
                      onClick: (function (param) {
                          AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle, "ClickViewPropertyInTrackingPlan", schemaBundle.schemaId);
                          return Router.Schema.pushDrawerItem(undefined, undefined, {
                                      NAME: "property",
                                      VAL: [
                                        modelProperty.id,
                                        undefined
                                      ]
                                    });
                        }),
                      style: "outline"
                    })));
      }
      
    } else {
      tmp$2 = null;
    }
  } else {
    tmp$2 = inspectorProperty !== undefined && modelEvent !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                height: 4
              }), React.createElement("div", {
                className: trackingPlanStatus
              }, React.createElement(Button.make, {
                    label: "Create and add Property To Event",
                    onClick: (function (param) {
                        AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle, "ClickAddPropertyToTrackingPlan", schemaBundle.schemaId);
                        return Curry._1(onAddProperty, inspectorProperty);
                      })
                  }))) : null;
  }
  var match$1 = InspectorViewSelectors.partitionTrackingPlanAndNonTrackingPlanIssues(sourceIssues);
  var nonTrackingPlanIssues = match$1[1];
  var trackingPlanIssues = match$1[0];
  var groupedTrackingPlanIssues = InspectorViewSelectors.groupIssuesFromMultipleSources(trackingPlanIssues);
  var trackingPlanStatus$1 = InspectorViewSelectors.getEventTrackingPlanStatusFromCell(cell);
  var inconsistentPropertyTypeIssues = InspectorViewSelectors.groupIssuesFromMultipleSources(Belt_Array.keepU(nonTrackingPlanIssues, (function (param) {
              var issue = param[1];
              if (typeof issue === "object") {
                return issue.NAME === "InconsistentType";
              } else {
                return false;
              }
            })));
  var sometimesMissingIssues = InspectorViewSelectors.groupIssuesFromMultipleSources(Belt_Array.keepU(nonTrackingPlanIssues, (function (param) {
              var issue = param[1];
              if (typeof issue === "object") {
                return issue.NAME === "SometimesMissing";
              } else {
                return false;
              }
            })));
  var healthyCell = sourceIssues.length === 0;
  var hasVolume;
  if (typeof cell === "object") {
    var variant$4 = cell.NAME;
    if (variant$4 === "PropertyVolume" || variant$4 === "PropertyName" || variant$4 === "PropertyIssues") {
      hasVolume = cell.VAL[0].count.history.total > 0;
    } else if (variant$4 === "PropertySourceVolume" || variant$4 === "PropertySourceIssues") {
      var match$2 = cell.VAL;
      hasVolume = Belt_Option.mapWithDefault(match$2[2], match$2[0].count.history.total, (function (source) {
              return InspectorViewSelectors.countPropertyVolume("current", [source]);
            })) > 0;
    } else if (variant$4 === "EventIssues" || variant$4 === "EventName" || variant$4 === "EventVolume") {
      hasVolume = cell.VAL.count.history.total > 0;
    } else {
      var match$3 = cell.VAL;
      hasVolume = Belt_Option.mapWithDefault(match$3[1], match$3[0].count.history.total, (function (source) {
              return source.count.current.total;
            })) > 0;
    }
  } else {
    hasVolume = false;
  }
  var tmp$3;
  if (inconsistentPropertyTypeIssues.length !== 0) {
    tmp$3 = React.createElement(InspectorViewContextSidebarIssues$IssueType, {
          schemaSources: model.sources,
          cell: cell,
          issueGroups: inconsistentPropertyTypeIssues,
          title: "Inconsistent Property Type"
        });
  } else {
    var tmp$4;
    if (typeof cell === "object") {
      var variant$5 = cell.NAME;
      tmp$4 = variant$5 === "PropertySourceVolume" || variant$5 === "PropertyVolume" || variant$5 === "PropertyName" || variant$5 === "PropertySourceIssues" || variant$5 === "PropertyIssues" ? "Property type is consistent" : "Properties have consistent types";
    } else {
      tmp$4 = null;
    }
    tmp$3 = React.createElement(InspectorViewContextSidebarIssues$IssueFreeType, {
          healthyCell: healthyCell,
          title: tmp$4,
          hasVolume: hasVolume
        });
  }
  var tmp$5;
  if (sometimesMissingIssues.length !== 0) {
    tmp$5 = React.createElement(InspectorViewContextSidebarIssues$IssueType, {
          schemaSources: model.sources,
          cell: cell,
          issueGroups: sometimesMissingIssues,
          title: "Property Sometimes Missing"
        });
  } else {
    var tmp$6;
    if (typeof cell === "object") {
      var variant$6 = cell.NAME;
      tmp$6 = variant$6 === "PropertySourceVolume" || variant$6 === "PropertyVolume" || variant$6 === "PropertyName" || variant$6 === "PropertySourceIssues" || variant$6 === "PropertyIssues" ? "Property is present as expected" : "Properties are present as expected";
    } else {
      tmp$6 = null;
    }
    tmp$5 = React.createElement(InspectorViewContextSidebarIssues$IssueFreeType, {
          healthyCell: healthyCell,
          title: tmp$6,
          hasVolume: hasVolume
        });
  }
  var tmp$7;
  if (trackingPlanStatus$1 !== undefined && typeof trackingPlanStatus$1 === "object") {
    if (trackingPlanStatus$1.NAME === "EventFoundInTrackingPlan") {
      if (typeof cell === "object") {
        var variant$7 = cell.NAME;
        if (variant$7 === "PropertySourceVolume" || variant$7 === "PropertyVolume" || variant$7 === "PropertyName" || variant$7 === "PropertySourceIssues" || variant$7 === "PropertyIssues") {
          var match$4 = Belt_Array.partitionU(trackingPlanIssues, (function (param) {
                  var trackingPlanIssue = param[1];
                  if (typeof trackingPlanIssue !== "object") {
                    return false;
                  }
                  var variant = trackingPlanIssue.NAME;
                  if (variant === "PropertyNotInTrackingPlan") {
                    return true;
                  } else {
                    return false;
                  }
                }));
          var conflictWithTrackingPlanIssues = match$4[1];
          var exit$1 = 0;
          if (match$4[0].length !== 0 || conflictWithTrackingPlanIssues.length !== 0) {
            exit$1 = 1;
          } else {
            var tmp$8;
            if (typeof cell === "object") {
              var variant$8 = cell.NAME;
              tmp$8 = variant$8 === "PropertySourceVolume" || variant$8 === "PropertyVolume" || variant$8 === "PropertyName" || variant$8 === "PropertySourceIssues" || variant$8 === "PropertyIssues" ? "Property matches Tracking Plan" : "Event matches Tracking Plan";
            } else {
              tmp$8 = null;
            }
            tmp$7 = React.createElement(InspectorViewContextSidebarIssues$IssueFreeType, {
                  healthyCell: healthyCell,
                  title: tmp$8,
                  hasVolume: hasVolume
                });
          }
          if (exit$1 === 1) {
            tmp$7 = conflictWithTrackingPlanIssues.length !== 0 ? React.createElement(InspectorViewContextSidebarIssues$IssueType, {
                    schemaSources: model.sources,
                    cell: cell,
                    issueGroups: groupedTrackingPlanIssues,
                    title: "Property conflicts with Tracking Plan"
                  }) : React.createElement(InspectorViewContextSidebarIssues$IssueType, {
                    schemaSources: model.sources,
                    cell: cell,
                    issueGroups: groupedTrackingPlanIssues,
                    title: "Property not in Tracking Plan"
                  });
          }
          
        } else {
          tmp$7 = groupedTrackingPlanIssues.length !== 0 ? React.createElement(InspectorViewContextSidebarIssues$IssueType, {
                  schemaSources: model.sources,
                  cell: cell,
                  issueGroups: groupedTrackingPlanIssues,
                  title: "Event conflicts with Tracking Plan"
                }) : React.createElement(InspectorViewContextSidebarIssues$IssueFreeType, {
                  healthyCell: healthyCell,
                  title: "Event matches Tracking Plan",
                  hasVolume: hasVolume
                });
        }
      } else {
        tmp$7 = null;
      }
    } else if (typeof cell === "object") {
      var variant$9 = cell.NAME;
      if (variant$9 === "PropertySourceVolume" || variant$9 === "PropertyVolume" || variant$9 === "PropertyName" || variant$9 === "PropertySourceIssues" || variant$9 === "PropertyIssues") {
        var match$5 = Belt_Array.partitionU(trackingPlanIssues, (function (param) {
                var trackingPlanIssue = param[1];
                if (typeof trackingPlanIssue !== "object") {
                  return false;
                }
                var variant = trackingPlanIssue.NAME;
                if (variant === "PropertyNotInTrackingPlan") {
                  return true;
                } else {
                  return false;
                }
              }));
        var conflictWithTrackingPlanIssues$1 = match$5[1];
        var exit$2 = 0;
        if (match$5[0].length !== 0 || conflictWithTrackingPlanIssues$1.length !== 0) {
          exit$2 = 1;
        } else {
          var tmp$9;
          if (typeof cell === "object") {
            var variant$10 = cell.NAME;
            tmp$9 = variant$10 === "PropertySourceVolume" || variant$10 === "PropertyVolume" || variant$10 === "PropertyName" || variant$10 === "PropertySourceIssues" || variant$10 === "PropertyIssues" ? "Property matches Tracking Plan" : "Event matches Tracking Plan";
          } else {
            tmp$9 = null;
          }
          tmp$7 = React.createElement(InspectorViewContextSidebarIssues$IssueFreeType, {
                healthyCell: healthyCell,
                title: tmp$9,
                hasVolume: hasVolume
              });
        }
        if (exit$2 === 1) {
          tmp$7 = conflictWithTrackingPlanIssues$1.length !== 0 ? React.createElement(InspectorViewContextSidebarIssues$IssueType, {
                  schemaSources: model.sources,
                  cell: cell,
                  issueGroups: groupedTrackingPlanIssues,
                  title: "Property conflicts with Tracking Plan"
                }) : React.createElement(InspectorViewContextSidebarIssues$IssueType, {
                  schemaSources: model.sources,
                  cell: cell,
                  issueGroups: groupedTrackingPlanIssues,
                  title: "Property not in Tracking Plan"
                });
        }
        
      } else {
        tmp$7 = React.createElement(InspectorViewContextSidebarIssues$IssueType, {
              schemaSources: model.sources,
              cell: cell,
              issueGroups: groupedTrackingPlanIssues,
              title: "Event conflicts with Tracking Plan"
            });
      }
    } else {
      tmp$7 = null;
    }
  } else {
    tmp$7 = null;
  }
  return React.createElement("div", {
              className: rootStyles$2
            }, React.createElement("header", {
                  className: headerStyles
                }, React.createElement("div", {
                      className: titleStyles
                    }, React.createElement("h1", {
                          className: titleTextStyles
                        }, "Issue Inspector"), React.createElement(Button.make, {
                          icon: "link",
                          onClick: handleCopyLink,
                          size: "tiny",
                          style: "ghost"
                        })), React.createElement("h2", {
                      className: subtitleStyles
                    }, eventName), modelEvent !== undefined ? React.createElement("div", {
                        className: trackingPlanStatus
                      }, React.createElement("div", {
                            className: trackingPlanStatusText
                          }, React.createElement(IconTrackingPlan.make, {}), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light10,
                                children: "Found in tracking plan"
                              }), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement(Button.make, {
                                icon: "sidebar",
                                label: "View",
                                onClick: (function (param) {
                                    AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle, "ClickViewEventInTrackingPlan", schemaBundle.schemaId);
                                    return Router.Schema.pushDrawerItem(undefined, undefined, {
                                                NAME: "event",
                                                VAL: [
                                                  modelEvent.id,
                                                  undefined,
                                                  undefined,
                                                  false
                                                ]
                                              });
                                  }),
                                style: "outline"
                              }))) : React.createElement("div", {
                        className: trackingPlanStatus
                      }, React.createElement("div", {
                            className: trackingPlanStatusText
                          }, React.createElement(IconTrackingPlan.make, {
                                color: Styles.Color.light08
                              }), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light10,
                                children: "Event not in tracking plan"
                              })), React.createElement(Spacer.make, {
                            height: 4
                          }), React.createElement(Button.make, {
                            label: "Add Event to Tracking Plan",
                            onClick: (function ($$event) {
                                AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle, "ClickAddEventToTrackingPlan", schemaBundle.schemaId);
                                return Curry._1(onAddEvent, $$event);
                              })
                          })), React.createElement("p", {
                      className: issuesContext
                    }, tmp, " - ", tmp$1), tmp$2), React.createElement("div", {
                  className: Curry._1(Css.style, AvoLimits.isInspectorVolumeBlocked(workspace) ? ({
                            hd: Css.position("relative"),
                            tl: {
                              hd: Css.maxHeight(Css.px(240)),
                              tl: {
                                hd: Css.overflow("hidden"),
                                tl: {
                                  hd: Css.pointerEvents("none"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }) : /* [] */0)
                }, AvoLimits.isInspectorVolumeBlocked(workspace) ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("absolute"),
                              tl: {
                                hd: Css.top(Css.px(0)),
                                tl: {
                                  hd: Css.right(Css.px(0)),
                                  tl: {
                                    hd: Css.bottom(Css.px(0)),
                                    tl: {
                                      hd: Css.left(Css.px(0)),
                                      tl: {
                                        hd: Css.zIndex(1),
                                        tl: {
                                          hd: Css.background({
                                                NAME: "linearGradient",
                                                VAL: [
                                                  {
                                                    NAME: "deg",
                                                    VAL: 180.0
                                                  },
                                                  [
                                                    [
                                                      Css.pct(0.0),
                                                      Styles.Color.setAlpha(Styles.Color.white, 0.0)
                                                    ],
                                                    [
                                                      Css.pct(100.0),
                                                      Styles.Color.setAlpha(Styles.Color.white, 1.0)
                                                    ]
                                                  ]
                                                ]
                                              }),
                                          tl: {
                                            hd: Css.pointerEvents("none"),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }) : null, tmp$3, tmp$5, tmp$7), AvoLimits.isInspectorVolumeBlocked(workspace) ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding(Css.px(40)),
                          tl: /* [] */0
                        })
                  }, React.createElement(Title.make, {
                        children: "Upgrade to unlock Inspector issues",
                        size: "Small"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light10,
                        maxWidth: Css.px(540),
                        children: "Issue details are limited as you have exceeded the monthly event volume limit provided on our free plan."
                      }), React.createElement(Spacer.make, {
                        height: 24
                      }), viewerRole === "Admin" ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.flexDirection("column"),
                                  tl: {
                                    hd: Css.justifyContent("stretch"),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement(Button.make, {
                              label: "Explore plans",
                              onClick: (function (param) {
                                  return Router.push(undefined, Router.Link.getSchemaRouteLink(undefined, "billing"));
                                }),
                              size: "large"
                            })) : React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.light10,
                          maxWidth: Css.px(540),
                          children: "To unlock these details contact your workspace Admin to upgrade your plan. "
                        })) : null);
}

var make = InspectorViewContextSidebarIssues;

export {
  IssueSeen ,
  getCellPropertyVolume ,
  IssueGroup ,
  IssueType ,
  IssueFreeType ,
  rootStyles$2 as rootStyles,
  headerStyles ,
  titleStyles ,
  titleTextStyles ,
  subtitleStyles ,
  issuesContext ,
  trackingPlanStatus ,
  trackingPlanStatusText ,
  make ,
  
}
/* locationStyles Not a pure module */
