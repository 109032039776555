// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as PortalMenu from "./PortalMenu.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SelectBranchPopup from "./SelectBranchPopup.mjs";
import * as IconDocumentChanges from "./IconDocumentChanges.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var overlay = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.zIndex(Styles.ZIndex.aboveAll),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var container = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.maxWidth(Css.px(450)),
        tl: {
          hd: Css.padding4(Css.px(35), Css.px(32), Css.px(32), Css.px(32)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.borderRadius(Css.px(10)),
              tl: {
                hd: Css.boxShadow(Styles.Shadow.standard),
                tl: {
                  hd: Css.cursor("default"),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var iconContainer = Curry._1(Css.style, {
      hd: Css.margin2(Css.px(15), "auto"),
      tl: {
        hd: Css.marginTop(Css.px(-78)),
        tl: {
          hd: Css.textAlign("center"),
          tl: {
            hd: Css.width(Css.pct(100)),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  overlay: overlay,
  container: container,
  iconContainer: iconContainer
};

function MasterEditingModal(Props) {
  var onClose = Props.onClose;
  var onContinue = Props.onContinue;
  var onGoToBranch = Props.onGoToBranch;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var globalSend = GlobalSendContext.use(undefined);
  var selectBranchButtonRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setShowBranchPopup = match[1];
  var handleDismiss = function (param) {
    Curry._1(onClose, undefined);
    return AnalyticsRe.branchPromptInteraction(schemaBundle, "Dismiss", schemaBundle.branchId, schemaBundle.schemaId);
  };
  return React.createElement("div", {
              className: overlay,
              onClick: handleDismiss
            }, React.createElement("div", {
                  className: container,
                  onClick: (function (prim) {
                      prim.stopPropagation();
                      
                    })
                }, React.createElement("div", {
                      className: iconContainer
                    }, React.createElement(IconDocumentChanges.make, {
                          size: 112
                        })), React.createElement($$Text.make, {
                      size: "Huge",
                      weight: "Semi",
                      textAlign: "center",
                      maxWidth: Css.px(250),
                      children: "Want to make these changes on a branch?"
                    }), React.createElement(Spacer.make, {
                      height: 24
                    }), React.createElement($$Text.make, {
                      color: Styles.Color.light11,
                      children: "Draft and collaborate on your tracking plan and keep an overview of what’s changed, then merge it to your main branch when it’s ready."
                    }), React.createElement(Spacer.make, {
                      height: 32
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: /* [] */0
                            }
                          })
                    }, workspace.settings.protectedMainBranch && role !== "Admin" ? React.createElement(Button.make, {
                            label: "Discard changes",
                            onClick: handleDismiss,
                            size: "large",
                            style: "ghost"
                          }) : React.createElement(Button.make, {
                            label: "Continue editing on main",
                            onClick: (function (param) {
                                Curry._1(onContinue, undefined);
                                Curry._1(onClose, undefined);
                                return AnalyticsRe.branchPromptInteraction(schemaBundle, "StayOnMaster", schemaBundle.branchId, schemaBundle.schemaId);
                              }),
                            size: "large",
                            style: "ghost"
                          }), React.createElement(Spacer.make, {
                          width: 8
                        }), Belt_List.length(openBranches) === 0 ? React.createElement(Button.make, {
                            label: "Create branch",
                            onClick: (function (param) {
                                Curry._1(onClose, undefined);
                                AnalyticsRe.branchPromptInteraction(schemaBundle, "GoToBranch", schemaBundle.branchId, schemaBundle.schemaId);
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "NewBranch",
                                              VAL: [
                                                Curry.__2(onGoToBranch),
                                                ""
                                              ]
                                            }
                                          });
                              }),
                            size: "large"
                          }) : React.createElement(Button.make, {
                            label: "Switch to branch",
                            onClick: (function (param) {
                                return Curry._1(setShowBranchPopup, (function (state) {
                                              return !state;
                                            }));
                              }),
                            size: "large",
                            ref: selectBranchButtonRef
                          }), React.createElement(PortalMenu.make, {
                          position: "Right",
                          placement: "Start",
                          offset: {
                            top: -8
                          },
                          visible: match[0],
                          onClose: (function (param) {
                              return Curry._1(setShowBranchPopup, (function (param) {
                                            return false;
                                          }));
                            }),
                          referenceItemRef: selectBranchButtonRef,
                          children: React.createElement(SelectBranchPopup.make, {
                                canSelectMain: false,
                                onClose: (function (param) {
                                    return Curry._1(setShowBranchPopup, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                onSelect: (function (selectedBranch) {
                                    if (typeof selectedBranch !== "object") {
                                      return ;
                                    }
                                    if (selectedBranch.NAME === "Create") {
                                      return Curry._1(globalSend, {
                                                  TAG: /* OpenModal */4,
                                                  _0: {
                                                    NAME: "NewBranch",
                                                    VAL: [
                                                      (function (branchId, branchName) {
                                                          Curry._2(onGoToBranch, branchId, branchName);
                                                          Curry._1(onClose, undefined);
                                                          return AnalyticsRe.branchPromptInteraction(schemaBundle, "GoToBranch", schemaBundle.branchId, schemaBundle.schemaId);
                                                        }),
                                                      selectedBranch.VAL
                                                    ]
                                                  }
                                                });
                                    }
                                    var match = selectedBranch.VAL;
                                    var id = match.id;
                                    Curry._2(onGoToBranch, id, match.name);
                                    Curry._1(onClose, undefined);
                                    var match$1 = Router.getStaticRoute(undefined);
                                    var link = Router.Link.getSchemaLink(match$1.baseRoute, {
                                          NAME: "branch",
                                          VAL: id
                                        }, Belt_Option.getWithDefault(match$1.schemaRoute, "dashboard"), match$1.drawerItems);
                                    Router.push(undefined, link);
                                    return AnalyticsRe.branchPromptInteraction(schemaBundle, "GoToBranch", schemaBundle.branchId, schemaBundle.schemaId);
                                  }),
                                openBranches: openBranches
                              })
                        }))));
}

var make = MasterEditingModal;

export {
  Style ,
  make ,
  
}
/* overlay Not a pure module */
