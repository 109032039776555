// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DiffPrint from "./DiffPrint.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as FramerMotion from "framer-motion";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as CopyButtonLazy from "./CopyButtonLazy.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var containerStyles = Curry._1(Css.style, {
      hd: Css.border(Css.px(1), "solid", Styles.Color.deepBlue),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.backgroundColor(Styles.Color.lightBlue),
          tl: {
            hd: Css.maxWidth(Css.px(736)),
            tl: {
              hd: Css.width(Css.pct(100)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var emptySpacerStyles = Curry._1(Css.style, {
      hd: Css.width(Css.px(394)),
      tl: {
        hd: Css.marginLeft(Css.px(20)),
        tl: {
          hd: Css.flexShrink(0.0),
          tl: /* [] */0
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.padding2(Css.px(8), Css.px(16)),
            tl: {
              hd: Css.margin(Css.px(-1)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.darkBlue),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.deepBlue),
                  tl: {
                    hd: Css.borderTopRightRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.borderTopLeftRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var wrappingHeaderStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexGrow(1.0),
        tl: {
          hd: Css.flexWrap("wrap"),
          tl: /* [] */0
        }
      }
    });

var copyButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.marginRight(Css.px(8)),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.color(Styles.Color.darkBlue),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: {
                                hd: Css.outlineColor(Styles.Color.white),
                                tl: {
                                  hd: Css.transition({
                                        NAME: "ms",
                                        VAL: Styles.Duration.$$default
                                      }, undefined, undefined, "background-color"),
                                  tl: {
                                    hd: Css.focus({
                                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.light07)),
                                          tl: {
                                            hd: Css.outlineStyle("none"),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.backgroundColor(Styles.Color.light01),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.padding2(Css.px(4), Css.px(12)),
                                        tl: {
                                          hd: Css.height("auto"),
                                          tl: {
                                            hd: Css.position("relative"),
                                            tl: {
                                              hd: Css.whiteSpace("nowrap"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var expandButtonStyles = Curry._1(Css.style, {
      hd: Css.cursor("pointer"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.width(Css.px(24)),
              tl: {
                hd: Css.height(Css.px(24)),
                tl: {
                  hd: Css.borderRadius(Css.pct(100.0)),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: Styles.Duration.$$default
                        }, undefined, undefined, "background-color"),
                    tl: {
                      hd: Css.hover({
                            hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.1)),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var markdownStyles = Curry._1(Css.style, {
      hd: Css.whiteSpace("preWrap"),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.polar),
        tl: {
          hd: Css.fontSize(Styles.FontSize.small),
          tl: {
            hd: Css.wordBreak("breakAll"),
            tl: {
              hd: Css.padding2(Css.px(8), Css.px(16)),
              tl: {
                hd: Css.maxHeight(Css.px(500)),
                tl: {
                  hd: Css.overflowX("auto"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var deprecationNoticeBox = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.paddingTop(Css.px(12)),
              tl: {
                hd: Css.paddingRight(Css.px(16)),
                tl: {
                  hd: Css.paddingBottom(Css.px(12)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(16)),
                    tl: {
                      hd: Css.borderBottomRightRadius(Css.px(5)),
                      tl: {
                        hd: Css.borderBottomLeftRadius(Css.px(5)),
                        tl: {
                          hd: Css.borderTop(Css.px(1), "solid", Styles.Color.deepBlue),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function trackInteraction(schemaBundle, interaction, format) {
  return AnalyticsRe.implementationInstructionInteraction(schemaBundle, interaction, DiffPrint.instructionsFormatToAnalyticsFormat(format), schemaBundle.branchId, schemaBundle.schemaId);
}

function DiffDetails(Props) {
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var actions = Props.actions;
  var branchId = Props.branchId;
  var workspace = WorkspaceContext.use(undefined);
  var hasAnyValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("AnyValues");
  var hasLongValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("LongValues");
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setShowChanges = match[1];
  var showChanges = match[0];
  var match$1 = React.useState(function () {
        return /* Markdown */1;
      });
  var setFormatOfChoice = match$1[1];
  var formatOfChoice = match$1[0];
  var formatOfChoiceString = React.useMemo((function () {
          return DiffPrint.stylingFormatToJs(formatOfChoice);
        }), [formatOfChoice]);
  var match$2 = React.useState(function () {
        return /* Unloaded */0;
      });
  var setDiffString = match$2[1];
  var diffString = match$2[0];
  var isLoading = diffString === /* Unloaded */0 && showChanges;
  React.useEffect((function () {
          if (diffString) {
            Curry._1(setDiffString, (function (param) {
                    return /* Unloaded */0;
                  }));
          }
          
        }), [formatOfChoice]);
  var makeDiffString = function (param) {
    return DiffPrint.printInstructions(formatOfChoice, branchModel, masterModel, actions, workspace.id, branchId, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag);
  };
  React.useEffect((function () {
          if (diffString || !showChanges) {
            
          } else {
            Curry._1(setDiffString, (function (param) {
                    return /* Loaded */{
                            _0: makeDiffString(undefined)
                          };
                  }));
          }
          
        }), [
        showChanges,
        diffString
      ]);
  var getDiffString = function (param) {
    if (!diffString) {
      return "";
    }
    var diffString$1 = diffString._0;
    if (diffString$1 === "") {
      return "No diff to show!";
    } else {
      return diffString$1;
    }
  };
  Hooks.useDidUpdate1((function (param) {
          trackInteraction(schemaBundle, "ChangeFormat", formatOfChoice);
          
        }), [formatOfChoice]);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.marginTop(Css.px(20)),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: containerStyles
                }, React.createElement("div", {
                      className: headerStyles,
                      id: "tour-branch-implementation-header",
                      onClick: (function (param) {
                          return Curry._1(setShowChanges, (function (previous) {
                                        trackInteraction(schemaBundle, previous ? "Collapse" : "Expand", formatOfChoice);
                                        if (!previous) {
                                          FirebaseUtils.setImplementationDetailsGrabbed(workspace.id);
                                        }
                                        return !previous;
                                      }));
                        })
                    }, React.createElement("div", {
                          className: wrappingHeaderStyles
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.marginRight(Css.px(8)),
                                    tl: /* [] */0
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.white,
                                  children: "Diff Details"
                                })), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.flexGrow(1.0),
                                    tl: {
                                      hd: Css.marginRight(Css.px(8)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.white,
                                  children: isLoading ? "Loading..." : "Quickly copy to your clipboard to share with your team."
                                })), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (prim) {
                                  prim.stopPropagation();
                                  
                                })
                            }, React.createElement(Select.make, {
                                  additionalStyles: {
                                    hd: Css.backgroundColor(Css.transparent),
                                    tl: {
                                      hd: Css.borderWidth(Css.px(0)),
                                      tl: {
                                        hd: Css.color(Styles.Color.light02),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.color(Styles.Color.white),
                                                tl: /* [] */0
                                              }),
                                          tl: {
                                            hd: Css.backgroundImage({
                                                  NAME: "url",
                                                  VAL: "'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4zIDMuNUwzLjggMWwyLjUgMi41bS01IDNMMy44IDlsMi41LTIuNSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg=='"
                                                }),
                                            tl: {
                                              hd: Css.padding4("zero", Css.px(20), "zero", "zero"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  onSelect: (function (value) {
                                      return Curry._1(setFormatOfChoice, (function (param) {
                                                    return DiffPrint.stylingFormatFromJs(value);
                                                  }));
                                    }),
                                  options: [
                                    [
                                      {
                                        NAME: "Label",
                                        VAL: DiffPrint.stylingFormatToJs(/* Markdown */1)
                                      },
                                      DiffPrint.stylingFormatToJs(/* Markdown */1)
                                    ],
                                    [
                                      {
                                        NAME: "Label",
                                        VAL: DiffPrint.stylingFormatToJs(/* Jira */0)
                                      },
                                      DiffPrint.stylingFormatToJs(/* Jira */0)
                                    ]
                                  ],
                                  value: DiffPrint.stylingFormatToJs(formatOfChoice)
                                }))), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              }),
                          onClick: (function (prim) {
                              prim.stopPropagation();
                              
                            })
                        }, React.createElement(CopyButtonLazy.make, {
                              generateCopyString: (function (param) {
                                  if (diffString) {
                                    return diffString._0;
                                  }
                                  var copyString = makeDiffString(undefined);
                                  Curry._1(setDiffString, (function (param) {
                                          return /* Loaded */{
                                                  _0: copyString
                                                };
                                        }));
                                  return copyString;
                                }),
                              styles: copyButtonStyles,
                              title: "Copy " + formatOfChoiceString,
                              onClick: (function (param) {
                                  trackInteraction(schemaBundle, "Copy", formatOfChoice);
                                  return FirebaseUtils.setImplementationDetailsGrabbed(workspace.id);
                                }),
                              children: "Copy"
                            })), React.createElement("div", {
                          className: expandButtonStyles,
                          onClick: (function (param) {
                              
                            })
                        }, showChanges ? React.createElement(Icons.AccordionMinus.make, {
                                color: Styles.Color.white
                              }) : React.createElement(Icons.AccordionPlus.make, {
                                color: Styles.Color.white
                              }))), React.createElement(FramerMotion.AnimatePresence, {
                      children: showChanges ? React.createElement(FramerMotion.motion.div, {
                              animate: {
                                opacity: 1,
                                height: "auto"
                              },
                              transition: {
                                duration: 0.2
                              },
                              initial: {
                                opacity: 0,
                                height: "0px"
                              },
                              exit: {
                                opacity: 0,
                                height: "0px"
                              },
                              className: Curry._1(Css.style, {
                                    hd: Css.cursor("auto"),
                                    tl: /* [] */0
                                  }),
                              key: "markdown",
                              onClick: (function (prim) {
                                  prim.stopPropagation();
                                  
                                }),
                              children: React.createElement("div", {
                                    className: markdownStyles
                                  }, getDiffString(undefined))
                            }) : null
                    }), React.createElement("div", {
                      className: deprecationNoticeBox
                    }, React.createElement(Icon.make, {
                          type_: "warning",
                          size: "medium",
                          color: Styles.Color.darkBlue
                        }), React.createElement(Spacer.make, {
                          width: 12
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.light11,
                          children: null
                        }, "With the new ", React.createElement(TextButton.make, {
                              onClick: (function (param) {
                                  return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                              NAME: "diff",
                                              VAL: {
                                                NAME: "implementation",
                                                VAL: "overview"
                                              }
                                            });
                                }),
                              style: "Black",
                              children: "Code Changes"
                            }), " tab we’re deprecating this \"Copy implementation markdown/snippet\" sharing functionality.\nUse the sharing options in the Code Changes tab to give anyone with the link access to the implementation guide for this branch."))), React.createElement("div", {
                  className: emptySpacerStyles
                }));
}

var make = DiffDetails;

export {
  containerStyles ,
  emptySpacerStyles ,
  headerStyles ,
  wrappingHeaderStyles ,
  copyButtonStyles ,
  expandButtonStyles ,
  markdownStyles ,
  deprecationNoticeBox ,
  trackInteraction ,
  make ,
  
}
/* containerStyles Not a pure module */
