// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as React from "react";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as LoadingFullscreen from "./LoadingFullscreen.mjs";
import * as ShareBranchImplement from "./ShareBranchImplement.mjs";
import * as ImplementationShareEndpoint from "../../shared/endpoints/ImplementationShareEndpoint.mjs";

var pending = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.padding(Css.px(80)),
        tl: /* [] */0
      }
    });

var notFound = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.padding(Css.px(80)),
        tl: /* [] */0
      }
    });

var Style = {
  pending: pending,
  notFound: notFound
};

function getData(id) {
  return fetch("/api/share/implementation/?shareId=" + id, Fetch.RequestInit.make(/* Get */0, [[
                          "Accept",
                          "application/json"
                        ]], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
                return prim.json();
              }).then(ImplementationShareEndpoint.$$Response.decode);
}

function Share(Props) {
  var id = Props.id;
  var authState = Props.authState;
  var match = React.useState(function () {
        return /* Pending */0;
      });
  var setStatus = match[1];
  var status = match[0];
  var userId = typeof authState === "object" && authState.NAME === "Authenticated" ? authState.VAL.uid : undefined;
  React.useEffect((function () {
          $$Promise.$$catch(getData(id).then(function (data) {
                    return Curry._1(setStatus, (function (param) {
                                  return /* Success */{
                                          _0: data
                                        };
                                }));
                  }), (function (error) {
                  console.log("Failed to get shared data", error);
                  Curry._1(setStatus, (function (param) {
                          return /* NotFound */1;
                        }));
                  return Promise.reject(error);
                }));
          
        }), []);
  if (typeof status === "number") {
    if (status !== 0) {
      return React.createElement("div", {
                  className: notFound
                }, "Couldn't find anything shared for id " + id + ". Sorry");
    } else {
      return React.createElement(LoadingFullscreen.make, {
                  message: "Loading shared view…"
                });
    }
  } else {
    return React.createElement(ShareBranchImplement.make, {
                data: status._0,
                userId: userId,
                shareId: id
              });
  }
}

var make = Share;

export {
  Style ,
  getData ,
  make ,
  
}
/* pending Not a pure module */
