// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as SheetUtils from "./SheetUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RouterStore from "./RouterStore.mjs";
import * as ExportButton from "./ExportButton.mjs";
import * as Core from "@mantine/core";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as SegmentControl from "./SegmentControl.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as PropertyAbsenceViewHelpers from "./PropertyAbsenceViewHelpers.mjs";
import * as GetPropertiesSheetConstraintsUseCase from "./propertiesSheet/GetPropertiesSheetConstraintsUseCase.mjs";

var _map = {"propertyName":"Property Name","propertyType":"Property Type","propertyValueType":"Property Value Type","valueIsList":"Value is list","events":"Events","valueConstraints":"Value Constraints","presence":"Presence","eventPropertyBundle":"Event Property Bundle","nameMapping":"Name Mapping"};

var _revMap = {"Property Name":"propertyName","Property Type":"propertyType","Property Value Type":"propertyValueType","Value is list":"valueIsList","Events":"events","Value Constraints":"valueConstraints","Presence":"presence","Event Property Bundle":"eventPropertyBundle","Name Mapping":"nameMapping"};

function columnToJs(param) {
  return _map[param];
}

function columnFromJs(param) {
  return _revMap[param];
}

function getColumns(param) {
  return [
          "propertyName",
          "propertyType",
          "propertyValueType",
          "valueIsList",
          "events",
          "valueConstraints",
          "presence",
          "eventPropertyBundle",
          "nameMapping"
        ];
}

function getCell(model, column, property) {
  if (column === "presence") {
    return Belt_Option.getWithDefault(PropertyAbsenceViewHelpers.getPropertyAbsenceString(model, undefined, property, undefined), "Always sent");
  }
  if (column === "eventPropertyBundle") {
    return Belt_Option.mapWithDefaultU(Belt_List.getByU(model.propertyBundles, (function (propertyBundle) {
                      return Belt_List.hasU(propertyBundle.properties, property.id, (function (a, b) {
                                    return a === b;
                                  }));
                    })), "", (function (bundle) {
                  return bundle.name;
                }));
  }
  if (column === "propertyValueType") {
    return property.type_;
  }
  if (column === "events") {
    return Belt_Array.mapU(Belt_List.toArray(ModelUtils.eventsSendingProperty(model, undefined, property.id)), (function (param) {
                    return param.name;
                  })).join(",");
  }
  if (column === "propertyName") {
    return property.name;
  }
  if (column !== "propertyType") {
    if (column === "nameMapping") {
      return Belt_Array.keepMapU(Belt_List.toArray(ModelUtils.getPropertyNameMappingsOnAllEvents(model, property.id, undefined, undefined)), (function (rule) {
                      var match = rule.definition;
                      if (typeof match !== "object") {
                        return ;
                      }
                      if (match.NAME !== "NameMapping") {
                        return ;
                      }
                      var match$1 = match.VAL;
                      var destination = match$1.destination;
                      if (destination === undefined) {
                        return ;
                      }
                      var name = match$1.name;
                      if (name === undefined) {
                        return ;
                      }
                      var destinationName = typeof destination === "object" ? ModelUtils.getDestinationNameById(model, destination.VAL) : "all destinations";
                      return "sent as \"" + name + "\" to " + destinationName;
                    })).join(",");
    } else if (column === "valueConstraints") {
      return GetPropertiesSheetConstraintsUseCase.get(model, property);
    } else {
      return Pervasives.string_of_bool(property.list);
    }
  }
  var groupId = property.sendAs;
  if (typeof groupId === "number") {
    switch (groupId) {
      case /* SystemProperty */0 :
          return "System";
      case /* EventProperty */1 :
          return "Event";
      case /* UserProperty */2 :
          return "User";
      
    }
  } else {
    var groupId$1 = groupId._0;
    return Belt_Option.mapWithDefault(Belt_Array.getBy(model.groupTypes, (function (param) {
                      return param.id === groupId$1;
                    })), "Unknown Group", (function (param) {
                  return param.name + " (Group)";
                }));
  }
}

var ExportButton$1 = ExportButton.Make({});

function PropertiesSheet__Header$CSVExport(Props) {
  var properties = Curry._1(TrackingPlanMappedModel.Properties.toArray, ModelStore.Mapped.useProperties(undefined));
  var model = ModelStore.useModel(undefined);
  return React.createElement(ExportButton$1.make, {
              getCell: (function (param, param$1) {
                  return getCell(model, param, param$1);
                }),
              getColumnName: columnToJs,
              getColumns: getColumns,
              items: properties,
              name: "PropertiesSheet",
              tooltip: "Export properties to CSV"
            });
}

var CSVExport = {
  columnToJs: columnToJs,
  columnFromJs: columnFromJs,
  getColumns: getColumns,
  getCell: getCell,
  ExportButton: ExportButton$1,
  make: PropertiesSheet__Header$CSVExport
};

var dropdownContainer_0 = Css.minWidth(Css.px(200));

var dropdownContainer_1 = {
  hd: Css.paddingTop(Css.px(8)),
  tl: {
    hd: Css.paddingBottom(Css.px(8)),
    tl: {
      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                    NAME: "num",
                    VAL: 0.15
                  }))),
      tl: {
        hd: Css.transform(Css.translateY(Css.px(-6))),
        tl: {
          hd: Css.borderColor(Styles.Color.light04),
          tl: /* [] */0
        }
      }
    }
  }
};

var dropdownContainer = {
  hd: dropdownContainer_0,
  tl: dropdownContainer_1
};

var Styles$1 = {
  dropdownContainer: dropdownContainer
};

function PropertiesSheet__Header$CreatePropertyButton(Props) {
  var groupTypes = ModelStore.useGroupTypes(undefined);
  var openModal = GlobalSendContext.useOpenModal(undefined);
  var createProperty = function (sendAs) {
    return Curry._1(openModal, {
                NAME: "NewProperty",
                VAL: [
                  "",
                  sendAs,
                  undefined,
                  (function (propertyId, _propertyName) {
                      return Router.Schema.pushDrawerItem(undefined, undefined, {
                                  NAME: "property",
                                  VAL: [
                                    propertyId,
                                    undefined
                                  ]
                                });
                    }),
                  "PropertiesView"
                ]
              });
  };
  return React.createElement(Mantine.Menu.make, {
              position: "bottom-start",
              withinPortal: true,
              children: null
            }, React.createElement(Core.Menu.Target, {
                  children: React.createElement("div", undefined, React.createElement(Button.make, {
                            icon: "plus",
                            id: "create-property",
                            label: "New Property",
                            size: "tiny"
                          }))
                }), React.createElement(Mantine.Menu.Dropdown.make, {
                  additionalStyles: dropdownContainer,
                  children: null
                }, React.createElement(Mantine.Menu.Item.make, {
                      additionalStyles: {
                        hd: Css.padding2(Css.px(8), Css.px(16)),
                        tl: /* [] */0
                      },
                      onClick: (function (param) {
                          return createProperty(/* EventProperty */1);
                        }),
                      children: React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            children: "Event property"
                          })
                    }), React.createElement(Mantine.Menu.Item.make, {
                      additionalStyles: {
                        hd: Css.padding2(Css.px(8), Css.px(16)),
                        tl: /* [] */0
                      },
                      onClick: (function (param) {
                          return createProperty(/* UserProperty */2);
                        }),
                      children: React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            children: "User property"
                          })
                    }), React.createElement(Mantine.Menu.Item.make, {
                      additionalStyles: {
                        hd: Css.padding2(Css.px(8), Css.px(16)),
                        tl: /* [] */0
                      },
                      onClick: (function (param) {
                          return createProperty(/* SystemProperty */0);
                        }),
                      children: React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            children: "System property"
                          })
                    }), Caml_obj.caml_equal(groupTypes, []) ? null : React.createElement(React.Fragment, undefined, React.createElement(Core.Menu.Label, {
                            children: null
                          }), React.createElement(Core.Menu.Label, {
                            children: React.createElement($$Text.make, {
                                  children: "Group properties"
                                })
                          }), React.createElement(Mantine.Menu.Divider.make, {}), Belt_Array.map(groupTypes, (function (groupType) {
                              return React.createElement(Mantine.Menu.Item.make, {
                                          additionalStyles: {
                                            hd: Css.padding2(Css.px(8), Css.px(16)),
                                            tl: /* [] */0
                                          },
                                          onClick: (function (param) {
                                              return createProperty(/* GroupProperty */{
                                                          _0: groupType.id
                                                        });
                                            }),
                                          children: React.createElement($$Text.make, {
                                                size: "Small",
                                                weight: "Semi",
                                                children: groupType.name + " property"
                                              }),
                                          key: groupType.id
                                        });
                            })))));
}

var CreatePropertyButton = {
  Styles: Styles$1,
  make: PropertiesSheet__Header$CreatePropertyButton
};

var headerStyles = Curry._1(Css.style, {
      hd: Css.height(Css.px(64)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingLeft(Css.px(30)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var Style = {
  headerHeight: 64,
  headerStyles: headerStyles
};

function parseSegmentQueryParam(value, groupTypes) {
  if (value === undefined) {
    return ;
  }
  switch (value) {
    case "event" :
        return /* EventProperty */1;
    case "system" :
        return /* SystemProperty */0;
    case "user" :
        return /* UserProperty */2;
    default:
      return Belt_Option.mapU(Belt_Array.getByU(groupTypes, (function (param) {
                        return param.name === value;
                      })), (function (param) {
                    return /* GroupProperty */{
                            _0: param.id
                          };
                  }));
  }
}

function printSegmentQueryParam(segmentation, groupTypes) {
  if (segmentation === undefined) {
    return ;
  }
  if (typeof segmentation === "number") {
    switch (segmentation) {
      case /* SystemProperty */0 :
          return "system";
      case /* EventProperty */1 :
          return "event";
      case /* UserProperty */2 :
          return "user";
      
    }
  } else {
    var groupTypeId = segmentation._0;
    return Belt_Option.mapU(Belt_Array.getByU(groupTypes, (function (param) {
                      return param.id === groupTypeId;
                    })), (function (param) {
                  return param.name;
                }));
  }
}

function PropertiesSheet__Header(Props) {
  var segmentation = Props.segmentation;
  var setSegmentation = Props.setSegmentation;
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var groupTypes = ModelStore.useGroupTypes(undefined);
  var properties = ModelStore.useProperties(undefined);
  var propertyBundles = ModelStore.usePropertyBundles(undefined);
  var hasExportSheetsToCSV = AppFeatureFlag.useFeatureFlag("ExportSheetsToCSV");
  var match = Router.QueryParams.useStringKey("segment");
  var removeSegmentQueryParam = match.remove;
  var setSegmentQueryParam = match.set;
  React.useEffect((function () {
          var value = printSegmentQueryParam(segmentation, groupTypes);
          if (value !== undefined) {
            Curry._1(setSegmentQueryParam, value);
          } else {
            Curry._1(removeSegmentQueryParam, undefined);
          }
          return (function (param) {
                    return Curry._1(removeSegmentQueryParam, undefined);
                  });
        }), [
        segmentation,
        drawerItems
      ]);
  var handleSegmentation = React.useCallback((function (newSegmentation) {
          AnalyticsRe.propertiesViewConfigured(schemaBundle, "PropertyTypeSegmentation", SheetUtils.Properties.sendAsToAnalyticsSegmentation(segmentation), SheetUtils.Properties.sendAsToAnalyticsSegmentation(newSegmentation), undefined, schemaBundle.branchId, schemaBundle.schemaId);
          return Curry._1(setSegmentation, (function (param) {
                        return newSegmentation;
                      }));
        }), []);
  return React.createElement("header", {
              className: headerStyles
            }, React.createElement(Title.make, {
                  children: null,
                  shrink: 0.0,
                  size: "Tiny"
                }, "Properties ", React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("inlineBlock"),
                            tl: {
                              hd: Css.minWidth(Css.px(48)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          size: "Medium",
                          color: Styles.Color.light10,
                          children: "(" + Belt_List.length(properties).toString() + ")"
                        }))), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement(PropertiesSheet__Header$CreatePropertyButton, {}), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement(SegmentControl.make, {
                  options: Belt_Array.concat([
                        [
                          "All Properties",
                          undefined
                        ],
                        [
                          "Event",
                          /* EventProperty */1
                        ],
                        [
                          "User",
                          /* UserProperty */2
                        ],
                        [
                          "System",
                          /* SystemProperty */0
                        ]
                      ], Belt_Array.mapU(groupTypes, (function (param) {
                              return [
                                      param.name,
                                      /* GroupProperty */{
                                        _0: param.id
                                      }
                                    ];
                            }))),
                  onSelect: handleSegmentation,
                  selected: segmentation,
                  disabled: properties === /* [] */0
                }), hasExportSheetsToCSV ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                        width: 16
                      }), React.createElement(PropertiesSheet__Header$CSVExport, {})) : null, React.createElement(Spacer.make, {
                  width: 16,
                  grow: 1
                }), React.createElement(Link.make, {
                  path: Router.Link.getSchemaRouteLink(undefined, "propertyGroups"),
                  children: React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Medium",
                        color: Styles.Color.darkBlue,
                        children: "View Event Property Bundles (" + Belt_List.length(propertyBundles).toString() + ")"
                      })
                }), React.createElement(Spacer.make, {
                  width: 16
                }));
}

var make = PropertiesSheet__Header;

export {
  CSVExport ,
  CreatePropertyButton ,
  Style ,
  parseSegmentQueryParam ,
  printSegmentQueryParam ,
  make ,
  
}
/* ExportButton Not a pure module */
