// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Markdown from "../Markdown.mjs";
import * as Tooltips from "../markdown/tooltips/tooltips.mjs";
import * as DiffEvent from "../DiffEvent.mjs";
import * as HoverTitle from "./HoverTitle.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BranchHeader from "../BranchHeader.mjs";
import * as ChangedEvent from "./ChangedEvent.mjs";
import * as DiffComments from "../DiffComments.mjs";
import * as SnippetUtils from "../detailviews/SnippetUtils.mjs";
import * as SimpleTooltip from "../SimpleTooltip.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as VariantEventIdUseCase from "../../../model/src/variants/variantEventIdUseCase.mjs";
import * as BranchImplementationUtils from "./BranchImplementationUtils.mjs";
import * as EventImplementationSnippet from "../detailviews/EventImplementationSnippet.mjs";

var container = Curry._1(Css.merge, {
      hd: ChangedEvent.Style.container,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("static"),
              tl: {
                hd: Css.minWidth(Css.px(480)),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var modificationStatus = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(10)),
      tl: {
        hd: Css.padding2(Css.px(2), Css.px(8)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.lightRed),
          tl: {
            hd: Css.color(Styles.Color.red),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  container: container,
  modificationStatus: modificationStatus
};

function DeletedEvent(Props) {
  var branchStatus = Props.branchStatus;
  var $$event = Props.event;
  var goToCommentId = Props.goToCommentId;
  var toModel = Props.toModel;
  var sourceId = Props.sourceId;
  var enrichedModel = Props.enrichedModel;
  var fromModelWithFlattenedEventVariants = Props.fromModelWithFlattenedEventVariants;
  var diffViewType = Props.diffViewType;
  var containerRef = React.useRef(null);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var isVariant = VariantEventIdUseCase.isVariantEventId($$event.id);
  var itemExists = isVariant ? ModelUtils.getEventVariantById(toModel, {
          baseEventId: VariantEventIdUseCase.baseEventIdFromVariantEventId($$event.id),
          variantId: VariantEventIdUseCase.variantIdFromVariantEventId($$event.id)
        }) !== undefined : ModelUtils.getEventById($$event.id, toModel) !== undefined;
  SnippetUtils.useScrollIntoView($$event.id, "BranchImplementation", (BranchHeader.branchHeaderHeight + BranchImplementationUtils.codeChangesHeaderHeight | 0) + 2 | 0, containerRef);
  var tmp;
  if (itemExists && !isVariant) {
    var tmp$1 = {
      branchId: Router.Schema.getBranchId(undefined),
      threadId: $$event.id,
      itemType: "Event",
      itemName: $$event.name,
      stickyTop: (BranchHeader.branchHeaderHeight + BranchImplementationUtils.codeChangesHeaderHeight | 0) + 4 | 0,
      events: toModel.events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp = React.createElement(DiffComments.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: itemExists ? Styles.emptyStyle : Css.marginRight(Css.px(BranchImplementationUtils.commentMarginWidth)),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("section", {
                  ref: containerRef,
                  className: container
                }, React.createElement("header", {
                      className: ChangedEvent.Style.header
                    }, React.createElement("span", {
                          className: modificationStatus
                        }, isVariant ? React.createElement(SimpleTooltip.WithComponent.make, {
                                arrowSize: 8,
                                arrowOffset: 12,
                                maxWidth: 300,
                                offset: 4,
                                position: "bottom-start",
                                tooltip: React.createElement($$Text.make, {
                                      size: "Small",
                                      children: React.createElement(Markdown.make, {
                                            source: Tooltips.codeChangesEventVariant
                                          })
                                    }),
                                children: React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      children: itemExists ? "Removed" : "Deleted Variant"
                                    })
                              }) : React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                children: itemExists ? "Removed" : "Deleted"
                              })), React.createElement(HoverTitle.make, {
                          label: Belt_Option.getWithDefault($$event.uniqueName, $$event.name)
                        }), React.createElement(Spacer.make, {
                          width: 0,
                          grow: 1.0
                        }), itemExists ? React.createElement(Button.make, {
                            icon: "sidebar",
                            label: "View",
                            onClick: (function (param) {
                                return Router.Schema.pushDrawerItem(undefined, undefined, {
                                            NAME: "event",
                                            VAL: [
                                              $$event.id,
                                              undefined,
                                              undefined,
                                              false
                                            ]
                                          });
                              }),
                            style: "outline"
                          }) : (
                        isVariant ? null : React.createElement(Button.make, {
                                label: "Unarchive",
                                onClick: (function (param) {
                                    return DiffEvent.unarchiveEvent($$event, toModel, sendActions, Router.Schema.getBranchId(undefined), schemaBundle);
                                  }),
                                style: "outline"
                              })
                      )), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light09,
                      children: "All references to this " + (
                        isVariant ? "event variant" : "event"
                      ) + " should be removed"
                    }), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement(EventImplementationSnippet.make, {
                      branchStatus: branchStatus,
                      eventDiffState: "deleted",
                      enrichedToModel: enrichedModel,
                      event: $$event,
                      fromModel: fromModelWithFlattenedEventVariants,
                      toModel: toModel,
                      pageName: "BranchImplementation",
                      viewType: /* SingleSource */{
                        _0: sourceId
                      },
                      diffViewType: diffViewType,
                      key: sourceId
                    })), tmp);
}

var make = DeletedEvent;

export {
  Style ,
  make ,
  
}
/* container Not a pure module */
