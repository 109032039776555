// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Models from "../Models.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App from "firebase/app";
import * as LoadingCircle from "../LoadingCircle.mjs";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as IconSlackEmblem from "../IconSlackEmblem.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";

var RemoveChannelFailed = /* @__PURE__ */Caml_exceptions.create("ConnectToSlackButton.ConnectedToSlack.RemoveChannelFailed");

function removeEventType(schemaId, channel, eventType) {
  if (channel.events.length > 1) {
    return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("notificationChannels").doc(channel.id).update(function (param) {
                return {
                        events: Belt_Array.keep(channel.events, (function (_type) {
                                return _type !== Models.NotificationChannel.printEventType(eventType);
                              }))
                      };
              });
  } else {
    return Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
                  return fetch(Firebase.apiUrl + "/disconnectNotificationChannel?schemaId=" + schemaId + "&channelId=" + channel.id, Fetch.RequestInit.make(/* Post */2, [[
                                      "Authorization",
                                      "Bearer " + userToken
                                    ]], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                }).then(function (response) {
                if (response.status === 200) {
                  return Promise.resolve(undefined);
                } else {
                  return Promise.reject({
                              RE_EXN_ID: RemoveChannelFailed
                            });
                }
              });
  }
}

var connectedBoxStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.borderRadius(Css.px(10)),
          tl: {
            hd: Css.paddingTop(Css.px(16)),
            tl: {
              hd: Css.paddingRight(Css.px(20)),
              tl: {
                hd: Css.paddingBottom(Css.px(16)),
                tl: {
                  hd: Css.paddingLeft(Css.px(20)),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function ConnectToSlackButton$ConnectedToSlack(Props) {
  var channel = Props.channel;
  var viewerRole = Props.viewerRole;
  var disconnectWarningMessage = Props.disconnectWarningMessage;
  var eventType = Props.eventType;
  var onDisconnectOpt = Props.onDisconnect;
  var onDisconnect = onDisconnectOpt !== undefined ? onDisconnectOpt : (function (param) {
        
      });
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = React.useState(function () {
        return /* Initial */0;
      });
  var setState = match[1];
  var handleRemoveEventType = function (param) {
    if (window.confirm(disconnectWarningMessage)) {
      Curry._1(setState, (function (param) {
              return /* Loading */1;
            }));
      $$Promise.$$catch(removeEventType(workspace.id, channel, eventType).then(function (param) {
                Curry._1(onDisconnect, undefined);
                return Promise.resolve(undefined);
              }), (function (error) {
              console.log("Failed to disconnect notification channel", error);
              window.alert("Something went wrong when trying to disconnect");
              Curry._1(setState, (function (param) {
                      return /* Initial */0;
                    }));
              return Promise.reject({
                          RE_EXN_ID: RemoveChannelFailed
                        });
            }));
      return ;
    }
    
  };
  return React.createElement("div", {
              className: connectedBoxStyles
            }, React.createElement(IconSlackEmblem.make, {
                  size: 24
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(16)),
                        tl: {
                          hd: Css.maxWidth(Css.px(300)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: "Connected to Slack"
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: null
                    }, "You are receiving notifications on ", React.createElement($$Text.make, {
                          element: "Span",
                          size: "Small",
                          weight: "Semi",
                          children: channel.metadata.channelName
                        }))), React.createElement(Spacer.make, {
                  width: 8,
                  grow: 1.0
                }), viewerRole === "Admin" || viewerRole === "Editor" ? React.createElement(Button.make, {
                    disabled: match[0] === /* Loading */1,
                    label: "Disconnect",
                    onClick: handleRemoveEventType
                  }) : null);
}

var ConnectedToSlack = {
  RemoveChannelFailed: RemoveChannelFailed,
  removeEventType: removeEventType,
  connectedBoxStyles: connectedBoxStyles,
  make: ConnectToSlackButton$ConnectedToSlack
};

var buttonStyles = Curry._1(Css.style, Belt_List.concat(Styles.buttonReset, {
          hd: Css.padding4(Css.px(12), Css.px(20), Css.px(12), Css.px(12)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.borderRadius(Css.px(10)),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light02),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "all"),
                      tl: {
                        hd: Css.disabled({
                              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                              tl: {
                                hd: Css.opacity(0.6),
                                tl: {
                                  hd: Css.cursor("default"),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: {
                          hd: Css.focus({
                                hd: Css.outlineStyle("none"),
                                tl: {
                                  hd: Css.boxShadows({
                                        hd: Css_Legacy_Core.Shadow.box(undefined, Css.px(5), Css.px(10), undefined, undefined, Css.rgba(0, 0, 0, {
                                                  NAME: "num",
                                                  VAL: 0.03
                                                })),
                                        tl: {
                                          hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), true, Styles.Color.darkBlue),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }),
                          tl: {
                            hd: Css.hover({
                                  hd: Css.focus({
                                        hd: Css.boxShadows({
                                              hd: Css_Legacy_Core.Shadow.box(undefined, Css.px(20), Css.px(40), undefined, undefined, Css.rgba(0, 0, 0, {
                                                        NAME: "num",
                                                        VAL: 0.1
                                                      })),
                                              tl: {
                                                hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), true, Styles.Color.darkBlue),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(20), Css.px(40), undefined, undefined, Css.rgba(0, 0, 0, {
                                                  NAME: "num",
                                                  VAL: 0.1
                                                }))),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: /* [] */0
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }));

var iconStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.width(Css.px(40)),
            tl: {
              hd: Css.height(Css.px(40)),
              tl: {
                hd: Css.marginRight(Css.px(12)),
                tl: {
                  hd: Css.borderRadius(Css.px(20)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function connectChannel(schemaId, redirectPath, eventType) {
  var requestRef = Firebase.app(undefined).firestore().collection("oauthRequests").doc();
  window.open("https://slack.com/oauth/v2/authorize?client_id=77143576753.1410806608161&scope=incoming-webhook&state=" + requestRef.id, "_blank", "width=600,height=750");
  var viewerId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  var oAuthRequest = {
    id: requestRef.id,
    createdAt: App.default.firestore.FieldValue.serverTimestamp(),
    createdBy: viewerId,
    service: "slack",
    schemaId: schemaId,
    redirectPath: redirectPath,
    eventTypes: [Models.NotificationChannel.printEventType(eventType)]
  };
  return requestRef.set(oAuthRequest);
}

function ConnectToSlackButton$ConnectToSlack(Props) {
  var schemaId = Props.schemaId;
  var redirectPath = Props.redirectPath;
  var eventType = Props.eventType;
  var onConnectOpt = Props.onConnect;
  var onConnect = onConnectOpt !== undefined ? onConnectOpt : (function (param) {
        
      });
  var match = React.useState(function () {
        return /* Initial */0;
      });
  var setState = match[1];
  var state = match[0];
  var handleConnectClick = function (param) {
    Curry._1(setState, (function (param) {
            return /* Loading */1;
          }));
    $$Promise.$$catch(connectChannel(schemaId, redirectPath, eventType).then(function (param) {
              Curry._1(onConnect, undefined);
              Curry._1(setState, (function (param) {
                      return /* Initial */0;
                    }));
              return Promise.resolve(undefined);
            }), (function (param) {
            return Promise.resolve(Curry._1(setState, (function (param) {
                              return /* Error */2;
                            })));
          }));
    
  };
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  className: buttonStyles,
                  disabled: state === /* Loading */1,
                  onClick: handleConnectClick
                }, React.createElement("div", {
                      className: iconStyles
                    }, React.createElement(IconSlackEmblem.make, {})), React.createElement($$Text.make, {
                      element: "Span",
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Connect to Slack"
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.px(12)),
                        tl: /* [] */0
                      })
                }), state === /* Error */2 ? React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.red,
                    children: "Something went wrong when connecting to Slack. Please try again."
                  }) : null);
}

var ConnectToSlack = {
  buttonStyles: buttonStyles,
  iconStyles: iconStyles,
  connectChannel: connectChannel,
  make: ConnectToSlackButton$ConnectToSlack
};

var loaderBoxStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(20)),
      tl: {
        hd: Css.marginLeft(Css.px(20)),
        tl: /* [] */0
      }
    });

var noAccessStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingTop(Css.px(12)),
          tl: {
            hd: Css.paddingRight(Css.px(20)),
            tl: {
              hd: Css.paddingBottom(Css.px(12)),
              tl: {
                hd: Css.paddingLeft(Css.px(12)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: {
                    hd: Css.borderRadius(Css.px(10)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var iconStyles$1 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.width(Css.px(40)),
            tl: {
              hd: Css.height(Css.px(40)),
              tl: {
                hd: Css.marginRight(Css.px(12)),
                tl: {
                  hd: Css.borderRadius(Css.px(20)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function ConnectToSlackButton(Props) {
  var schemaId = Props.schemaId;
  var eventType = Props.eventType;
  var onSuccessRedirectPath = Props.onSuccessRedirectPath;
  var onConnect = Props.onConnect;
  var onDisconnect = Props.onDisconnect;
  var disconnectWarningMessage = Props.disconnectWarningMessage;
  var channels = FirebaseFetcherHooks.useNotificationChannels(undefined, schemaId, eventType);
  var viewerRole = FirebaseFetcherHooks.useViewerRole(schemaId);
  var exit = 0;
  var exit$1 = 0;
  if (typeof channels === "object") {
    if (channels.NAME === "Loaded") {
      var match = channels.VAL;
      if (match) {
        if (typeof viewerRole === "object") {
          if (viewerRole.NAME === "Success") {
            var tmp = {
              channel: match.hd,
              viewerRole: viewerRole.VAL,
              disconnectWarningMessage: disconnectWarningMessage,
              eventType: eventType
            };
            if (onDisconnect !== undefined) {
              tmp.onDisconnect = Caml_option.valFromOption(onDisconnect);
            }
            return React.createElement(ConnectToSlackButton$ConnectedToSlack, tmp);
          }
          exit$1 = 3;
        } else {
          exit$1 = 3;
        }
      } else if (typeof viewerRole === "object") {
        if (viewerRole.NAME === "Success") {
          var match$1 = viewerRole.VAL;
          if (!(match$1 === "Admin" || match$1 === "Editor")) {
            return React.createElement("div", {
                        className: noAccessStyles
                      }, React.createElement("div", {
                            className: iconStyles$1
                          }, React.createElement(IconSlackEmblem.make, {})), React.createElement("div", undefined, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                color: Styles.Color.light08,
                                children: "Connect to Slack"
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.light10,
                                children: "Contact an Admin or Editor to get started"
                              })));
          }
          var tmp$1 = {
            schemaId: schemaId,
            redirectPath: onSuccessRedirectPath,
            eventType: eventType
          };
          if (onConnect !== undefined) {
            tmp$1.onConnect = Caml_option.valFromOption(onConnect);
          }
          return React.createElement(ConnectToSlackButton$ConnectToSlack, tmp$1);
        }
        exit$1 = 3;
      } else {
        exit$1 = 3;
      }
    } else {
      exit$1 = 3;
    }
  } else if (channels === "Initial") {
    exit = 1;
  } else {
    exit$1 = 3;
  }
  if (exit$1 === 3) {
    exit = viewerRole === "Loading" ? 1 : 2;
  }
  switch (exit) {
    case 1 :
        return React.createElement("div", {
                    className: loaderBoxStyles
                  }, React.createElement(LoadingCircle.make, {
                        color: Styles.Color.light07
                      }));
    case 2 :
        return React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.red,
                    children: "Something went wrong when fetching your connections. Try again."
                  });
    
  }
}

var make = ConnectToSlackButton;

export {
  ConnectedToSlack ,
  ConnectToSlack ,
  loaderBoxStyles ,
  noAccessStyles ,
  iconStyles$1 as iconStyles,
  make ,
  
}
/* connectedBoxStyles Not a pure module */
