// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as $$Text from "./Text.mjs";
import * as Asset from "./Asset.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Lodash from "./externals/lodash.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import Shortid from "shortid";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as TextInput from "./TextInput.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as PortalMenu from "./PortalMenu.mjs";
import * as TextButton from "./TextButton.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as KeyListener from "./KeyListener.mjs";
import * as Belt_SetDict from "rescript/lib/es6/belt_SetDict.js";
import * as FramerMotion from "framer-motion";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import LodashThrottle from "lodash.throttle";

function MakeEditableCell(ListItem) {
  var getNextItemInCycle = function (currentIndex, cycleLength) {
    if (cycleLength > 0) {
      return Caml_int32.mod_(Caml_int32.mod_(currentIndex + 1 | 0, cycleLength) + cycleLength | 0, cycleLength);
    } else {
      return 0;
    }
  };
  var getPreviousItemInCycle = function (currentIndex, cycleLength) {
    if (cycleLength > 0) {
      return Caml_int32.mod_(Caml_int32.mod_(currentIndex - 1 | 0, cycleLength) + cycleLength | 0, cycleLength);
    } else {
      return 0;
    }
  };
  var focusChildAtIndex = function (childIndex, parentRef) {
    return Belt_Option.forEach(Belt_Option.flatMap(Caml_option.nullable_to_opt(parentRef.current), (function (el) {
                      return Belt_Array.get(el.children, childIndex);
                    })), (function (prim) {
                  prim.focus();
                  
                }));
  };
  var sortCellItems = function (items) {
    return Belt_SortArray.stableSortByU(items, (function (a, b) {
                  return Caml.caml_string_compare(Curry._1(ListItem.Cell.getName, a), Curry._1(ListItem.Cell.getName, b));
                }));
  };
  var Utils = {
    getNextItemInCycle: getNextItemInCycle,
    getPreviousItemInCycle: getPreviousItemInCycle,
    focusChildAtIndex: focusChildAtIndex,
    sortCellItems: sortCellItems
  };
  var outerContainerStyles = function (isActivated, canClick, className) {
    return Curry._1(Css.style, {
                hd: Css.height(Css.pct(100)),
                tl: {
                  hd: Css.position("relative"),
                  tl: {
                    hd: Css.after(isActivated ? ({
                              hd: Css.display("block"),
                              tl: {
                                hd: Css.position("absolute"),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: {
                                    hd: Css.right(Css.px(0)),
                                    tl: {
                                      hd: Css.top(Css.px(0)),
                                      tl: {
                                        hd: Css.bottom(Css.px(0)),
                                        tl: {
                                          hd: Css.unsafe("content", "' '"),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.darkBlue, 0.1)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }) : /* [] */0),
                    tl: {
                      hd: Css.hover({
                            hd: Css.after(canClick ? ({
                                      hd: Css.unsafe("content", "'Click to edit'"),
                                      tl: {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.justifyContent("center"),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.8)),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.darkBlue),
                                                    tl: {
                                                      hd: Css.position("absolute"),
                                                      tl: {
                                                        hd: Css.left(Css.px(0)),
                                                        tl: {
                                                          hd: Css.right(Css.px(0)),
                                                          tl: {
                                                            hd: Css.top(Css.px(0)),
                                                            tl: {
                                                              hd: Css.bottom(Css.px(0)),
                                                              tl: {
                                                                hd: Css.border(Css.px(2), "solid", Styles.Color.darkBlue),
                                                                tl: {
                                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }) : /* [] */0),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }) + (" " + className);
  };
  var CellEditPopup$MakeEditableCell$PopupCell = function (Props) {
    var canEdit = Props.canEdit;
    var classNameOpt = Props.className;
    var item = Props.item;
    var disabledOpt = Props.disabled;
    var onHover = Props.onHover;
    var onLeave = Props.onLeave;
    var onClick = Props.onClick;
    var onActivation = Props.onActivation;
    var parentActivatedState = Props.activated;
    var initialStateOpt = Props.initialState;
    var children = Props.children;
    var className = classNameOpt !== undefined ? classNameOpt : "";
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var initialState = initialStateOpt !== undefined ? initialStateOpt : false;
    var spanRef = React.useRef(null);
    var match = React.useState(function () {
          return false;
        });
    var setIsVisible = match[1];
    var isVisible = match[0];
    var match$1 = React.useState(function () {
          return initialState;
        });
    var setIsActivated = match$1[1];
    var isActivated = match$1[0];
    var onHoverTimeoutId = React.useRef(undefined);
    var canHover = !disabled;
    var canClick = canEdit && canHover;
    var deactivate = React.useCallback((function (param) {
            Curry._1(setIsVisible, (function (param) {
                    return false;
                  }));
            return Belt_Option.forEach(onLeave, (function (cb) {
                          return Curry._1(cb, undefined);
                        }));
          }), []);
    React.useEffect((function () {
            return (function (param) {
                      return Belt_Option.forEach(onHoverTimeoutId.current, (function (prim) {
                                    clearTimeout(prim);
                                    
                                  }));
                    });
          }), []);
    React.useEffect((function () {
            if (disabled) {
              Curry._1(deactivate, undefined);
            }
            
          }), [disabled]);
    React.useEffect((function () {
            if (isVisible) {
              
            } else {
              Belt_Option.forEach(onHoverTimeoutId.current, (function (prim) {
                      clearTimeout(prim);
                      
                    }));
            }
            return (function (param) {
                      return Belt_Option.forEach(onHoverTimeoutId.current, (function (prim) {
                                    clearTimeout(prim);
                                    
                                  }));
                    });
          }), [
          isVisible,
          onHover
        ]);
    React.useEffect((function () {
            Belt_Option.forEach(onActivation, (function (cb) {
                    return Curry._1(cb, isActivated);
                  }));
            
          }), [isActivated]);
    React.useEffect((function () {
            Belt_Option.forEach(parentActivatedState, (function (value) {
                    if (value !== isActivated) {
                      return Curry._1(setIsActivated, (function (param) {
                                    return value;
                                  }));
                    }
                    
                  }));
            
          }), [parentActivatedState]);
    var onClick$1 = React.useCallback((function (e) {
            if (canClick) {
              Curry._1(setIsActivated, (function (state) {
                      return !state;
                    }));
              e.stopPropagation();
              return Belt_Option.forEach(onClick, (function (cb) {
                            return Curry._1(cb, undefined);
                          }));
            }
            
          }), [
          canClick,
          onClick
        ]);
    var onMouseEnter = React.useCallback((function (_e) {
            if (canHover) {
              onHoverTimeoutId.current = Caml_option.some(setTimeout((function (param) {
                          Curry._1(setIsVisible, (function (param) {
                                  return true;
                                }));
                          return Belt_Option.forEach(onHover, (function (cb) {
                                        return Curry._1(cb, undefined);
                                      }));
                        }), 250));
              return ;
            }
            
          }), [
          canHover,
          onHover
        ]);
    var onMouseLeave = React.useCallback((function (param) {
            Belt_Option.forEach(onHoverTimeoutId.current, (function (prim) {
                    clearTimeout(prim);
                    
                  }));
            return Curry._1(deactivate, undefined);
          }), []);
    var onClose = React.useCallback((function (param) {
            Curry._1(setIsActivated, (function (param) {
                    return false;
                  }));
            return Curry._1(deactivate, undefined);
          }), []);
    React.useEffect((function () {
            if (!isVisible) {
              Belt_Option.forEach(onLeave, (function (cb) {
                      return Curry._1(cb, undefined);
                    }));
            }
            
          }), [isVisible]);
    return React.createElement("div", {
                ref: spanRef,
                className: outerContainerStyles(isActivated, canClick, className),
                onClick: onClick$1,
                onMouseEnter: onMouseEnter,
                onMouseLeave: onMouseLeave
              }, item, isVisible || isActivated ? React.createElement(FramerMotion.AnimatePresence, {
                      exitBeforeEnter: true,
                      initial: true,
                      children: React.createElement(PortalMenu.make, {
                            position: "Right",
                            placement: "Start",
                            distance: 0,
                            clickOutsideToDismiss: isActivated,
                            visible: isVisible || isActivated,
                            onClose: onClose,
                            recalculateOnBoundingRectChanges: true,
                            referenceItemRef: spanRef,
                            children: React.createElement(FramerMotion.motion.div, {
                                  animate: {
                                    opacity: 1,
                                    scale: 1
                                  },
                                  transition: {
                                    duration: 0.2
                                  },
                                  initial: {
                                    opacity: 0,
                                    scale: 0.95
                                  },
                                  exit: {
                                    opacity: 0,
                                    scale: 0.95
                                  },
                                  className: Curry._1(Css.style, {
                                        hd: Css.boxShadow(Styles.Shadow.subtle),
                                        tl: /* [] */0
                                      }),
                                  key: "implementation-status-popup",
                                  children: Curry._1(children, onClose)
                                })
                          })
                    }) : null);
  };
  var PopupCell = {
    make: CellEditPopup$MakeEditableCell$PopupCell
  };
  var container = function (elements) {
    return Curry._1(Css.style, {
                hd: Css.width(Css.px(300)),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.maxHeight(Css.vh(50)),
                      tl: {
                        hd: Css.height(Css.px(Math.imul(elements + 1 | 0, 48) + 1 | 0)),
                        tl: {
                          hd: Css.unsafe("overflow", "clip"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              });
  };
  var header = Curry._1(Css.style, {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: {
                hd: Css.padding2("zero", Css.px(16)),
                tl: {
                  hd: Css.height(Css.px(48)),
                  tl: {
                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.setAlpha(Css.white, 0.5)),
                      tl: {
                        hd: Css.backdropFilter({
                              hd: {
                                NAME: "blur",
                                VAL: Css.px(10)
                              },
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.zIndex(3),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
  var listWrapper = Curry._1(Css.style, {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.height({
                NAME: "subtract",
                VAL: [
                  Css.pct(100),
                  Css.px(49)
                ]
              }),
          tl: /* [] */0
        }
      });
  var list = function (length, isEditing) {
    return Curry._1(Css.style, {
                hd: Css.padding("zero"),
                tl: {
                  hd: Css.margin("zero"),
                  tl: {
                    hd: Css.listStyleType("none"),
                    tl: {
                      hd: Css.textIndent("zero"),
                      tl: {
                        hd: Css.minHeight({
                              NAME: "subtract",
                              VAL: [
                                Css.pct(100),
                                Css.px(49)
                              ]
                            }),
                        tl: {
                          hd: Css.height(Css.px(Math.imul(length, 48))),
                          tl: {
                            hd: Css.position("relative"),
                            tl: {
                              hd: Css.before({
                                    hd: Css.unsafe("content", "''"),
                                    tl: {
                                      hd: Css.display("block"),
                                      tl: {
                                        hd: Css.position("absolute"),
                                        tl: {
                                          hd: Css.top(Css.px(0)),
                                          tl: {
                                            hd: Css.left(Css.px(0)),
                                            tl: {
                                              hd: Css.width(Css.pct(100.0)),
                                              tl: {
                                                hd: Css.height(Css.px(Math.imul(length, 48))),
                                                tl: {
                                                  hd: Css.opacity(0.2),
                                                  tl: {
                                                    hd: Css.backgroundImage({
                                                          NAME: "url",
                                                          VAL: isEditing ? Asset.getPath("cellEditPopupPlaceholder_editing.png") : Asset.getPath("cellEditPopupPlaceholder.png")
                                                        }),
                                                    tl: {
                                                      hd: Css.backgroundSize({
                                                            NAME: "size",
                                                            VAL: [
                                                              Css.px(300),
                                                              Css.px(48)
                                                            ]
                                                          }),
                                                      tl: {
                                                        hd: Css.backgroundRepeat("repeatY"),
                                                        tl: {
                                                          hd: Css.backgroundAttachment("scroll"),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }),
                              tl: {
                                hd: Css.empty({
                                      hd: Css.after({
                                            hd: Css.unsafe("content", "'No results!'"),
                                            tl: {
                                              hd: Css.display("block"),
                                              tl: {
                                                hd: Css.color(Styles.Color.light09),
                                                tl: {
                                                  hd: Css.position("absolute"),
                                                  tl: {
                                                    hd: Css.top(Css.px(0)),
                                                    tl: {
                                                      hd: Css.left(Css.px(0)),
                                                      tl: {
                                                        hd: Css.padding2(Css.px(8), Css.px(16)),
                                                        tl: {
                                                          hd: Css.height(Css.px(48)),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
  };
  var listItem = function (isPointable, index) {
    return Curry._1(Css.style, {
                hd: Css.transitions({
                      hd: Css_Legacy_Core.Transition.shorthand({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "background-color"),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.userSelect("none"),
                      tl: {
                        hd: Css.paddingLeft(Css.px(16)),
                        tl: {
                          hd: Css.paddingRight(Css.px(16)),
                          tl: {
                            hd: Css.paddingTop(Css.px(8)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(8)),
                              tl: {
                                hd: Css.cursor(isPointable ? "pointer" : "default"),
                                tl: {
                                  hd: Css.height(Css.px(48)),
                                  tl: {
                                    hd: Css.position("absolute"),
                                    tl: {
                                      hd: Css.transform(Css.translateY(Css.px(Math.imul(index, 48)))),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.white),
                                        tl: {
                                          hd: Css.width(Css.pct(100.0)),
                                          tl: {
                                            hd: Css.focus({
                                                  hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.darkBlue, 0.15)),
                                                  tl: {
                                                    hd: Css.outlineStyle("none"),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            tl: {
                                              hd: Css.hover({
                                                    hd: Css.backgroundColor(Styles.Color.light04),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
  };
  var Style = {
    elementHeight: 48,
    container: container,
    header: header,
    listWrapper: listWrapper,
    list: list,
    listItem: listItem
  };
  var throttledFunction = LodashThrottle((function (fn) {
          return Curry._1(fn, undefined);
        }), 150, {
        leading: true,
        trailing: false
      });
  var cmp = function (a, b) {
    return Caml.caml_string_compare(Curry._1(ListItem.Cell.getId, a), Curry._1(ListItem.Cell.getId, b));
  };
  var ListItemCompare = Belt_Id.MakeComparable({
        cmp: cmp
      });
  var getFilteredItems = function (allItems, searchTerm) {
    return Belt_Array.mapU(Belt_Array.keepU(Fuzzaldrin.filter(Belt_Array.mapU(allItems, (function (item) {
                              return {
                                      name: Curry._1(ListItem.Cell.getName, item),
                                      item: item
                                    };
                            })), searchTerm, {
                        key: "name",
                        maxResults: allItems.length
                      }), (function (fuzzItem) {
                      return Belt_Array.someU(allItems, (function (item) {
                                    return Curry._1(ListItem.Cell.getId, fuzzItem.item) === Curry._1(ListItem.Cell.getId, item);
                                  }));
                    })), (function (fuzzItem) {
                  return fuzzItem.item;
                }));
  };
  var CellEditPopup$MakeEditableCell$ListItemWrapper = function (Props) {
    var activeIndex = Props.activeIndex;
    var index = Props.index;
    var isChecked = Props.isChecked;
    var isEditing = Props.isEditing;
    var item = Props.item;
    var onListItemClick = Props.onListItemClick;
    var onToggle = Props.onToggle;
    var onViewItem = Props.onViewItem;
    var searchTerm = Props.searchTerm;
    var shouldRender = Props.shouldRender;
    if (shouldRender) {
      return React.createElement("li", {
                  className: listItem(isEditing || Belt_Option.isSome(onViewItem), index),
                  tabIndex: -1
                }, React.createElement(ListItem.make, {
                      active: Belt_Option.mapWithDefault(activeIndex, false, (function (api) {
                              return api === index;
                            })),
                      checked: Curry._1(isChecked, item),
                      item: item,
                      mode: isEditing ? /* Editing */1 : /* Viewing */0,
                      onClick: (function (param) {
                          return Curry._1(onListItemClick, Curry._1(ListItem.Cell.getId, item));
                        }),
                      onToggle: (function (id, becameToggled) {
                          return Curry._3(onToggle, searchTerm, id, becameToggled);
                        })
                    }));
    } else {
      return null;
    }
  };
  var ListItemWrapper = {
    make: CellEditPopup$MakeEditableCell$ListItemWrapper
  };
  var CellEditPopup$MakeEditableCell$Popup = function (Props) {
    var visibilityTop = Props.visibilityTop;
    var setVisibilityTop = Props.setVisibilityTop;
    var selectedItems = Props.selectedItems;
    var isEditing = Props.isEditing;
    var onViewItem = Props.onViewItem;
    var onItemClicked = Props.onItemClicked;
    var setIsEditing = Props.setIsEditing;
    var allItems = Props.allItems;
    var onSearch = Props.onSearch;
    var onCreates = Props.onCreates;
    var hidePopup = Props.hidePopup;
    var onCreateInitiated = Props.onCreateInitiated;
    var onToggle = Props.onToggle;
    var windowHeight = Props.windowHeight;
    var setWindowHeight = Props.setWindowHeight;
    var scrollTimeoutId = React.useRef(undefined);
    var getShouldRender = React.useCallback((function (index) {
            var visibilityRangeStart = Math.max(Belt_Option.getWithDefault(visibilityTop, 0) - 1000 | 0, 0);
            var visibilityRangeEnd = (Belt_Option.getWithDefault(visibilityTop, 0) + Belt_Option.getWithDefault(windowHeight, 0) | 0) + 1000 | 0;
            var itemTop = Math.imul(index, 48);
            if (itemTop >= visibilityRangeStart) {
              return itemTop <= visibilityRangeEnd;
            } else {
              return false;
            }
          }), [
          visibilityTop,
          windowHeight
        ]);
    var onScrollThrottled = function (e) {
      var el = e.target;
      return throttledFunction(function (param) {
                  Belt_Option.forEach(scrollTimeoutId.current, (function (prim) {
                          clearTimeout(prim);
                          
                        }));
                  Curry._1(setWindowHeight, (function (param) {
                          return el.offsetHeight;
                        }));
                  Curry._1(setVisibilityTop, (function (param) {
                          return el.scrollTop;
                        }));
                  scrollTimeoutId.current = Caml_option.some(setTimeout((function (param) {
                              Curry._1(setWindowHeight, (function (param) {
                                      return el.offsetHeight;
                                    }));
                              return Curry._1(setVisibilityTop, (function (param) {
                                            return el.scrollTop;
                                          }));
                            }), 150));
                  
                });
    };
    var searchTermRef = React.useRef(null);
    var focusSearchBox = React.useCallback((function (param) {
            return Belt_Option.forEach(Caml_option.nullable_to_opt(searchTermRef.current), (function (prim) {
                          prim.focus();
                          
                        }));
          }), [searchTermRef]);
    var blurSearchBox = React.useCallback((function (param) {
            return Belt_Option.forEach(Caml_option.nullable_to_opt(searchTermRef.current), (function (prim) {
                          prim.blur();
                          
                        }));
          }), [searchTermRef]);
    React.useEffect((function () {
            if (isEditing) {
              Belt_Option.forEach(Caml_option.nullable_to_opt(searchTermRef.current), (function (prim) {
                      prim.focus();
                      
                    }));
            }
            
          }), [isEditing]);
    var match = React.useState(function () {
          return sortCellItems(selectedItems);
        });
    var setItemsDuringSession = match[1];
    var itemsDuringSession = match[0];
    var match$1 = React.useState(function () {
          
        });
    var setActiveIndex = match$1[1];
    var activeIndex = match$1[0];
    React.useEffect((function () {
            if (!isEditing) {
              Curry._1(setItemsDuringSession, (function (oldItems) {
                      return sortCellItems(Belt_SetDict.toArray(Belt_SetDict.mergeMany(Belt_SetDict.fromArray(oldItems, ListItemCompare.cmp), selectedItems, ListItemCompare.cmp)));
                    }));
            }
            
          }), [
          selectedItems,
          isEditing
        ]);
    React.useEffect((function () {
            if (activeIndex !== undefined) {
              Curry._1(blurSearchBox, undefined);
            }
            
          }), [activeIndex !== undefined]);
    var onEscape = React.useCallback((function (e) {
            if (isEditing) {
              if (activeIndex !== undefined) {
                Curry._1(focusSearchBox, undefined);
              } else {
                Curry._1(setIsEditing, (function (param) {
                        return false;
                      }));
              }
            }
            e.preventDefault();
            e.stopPropagation();
            
          }), [
          activeIndex,
          isEditing
        ]);
    var isChecked = React.useCallback((function (itemToCheck) {
            return Belt_Option.isSome(Belt_Array.getByU(selectedItems, (function (item) {
                              return Caml_obj.caml_equal(item, itemToCheck);
                            })));
          }), [selectedItems]);
    var match$2 = React.useState(function () {
          return itemsDuringSession;
        });
    var setItemsShown = match$2[1];
    var itemsShown = match$2[0];
    var match$3 = React.useState(function () {
          return "";
        });
    var setSearchTerm = match$3[1];
    var searchTerm = match$3[0];
    var listRef = React.useRef(null);
    React.useEffect((function () {
            if (isEditing) {
              Curry._1(setItemsShown, (function (param) {
                      if (searchTerm !== "") {
                        return getFilteredItems(allItems, searchTerm);
                      }
                      var sessionItemsSet = Belt_SetDict.fromArray(itemsDuringSession, ListItemCompare.cmp);
                      var filteredItemsSet = Belt_SetDict.fromArray(getFilteredItems(allItems, searchTerm), ListItemCompare.cmp);
                      var diff = sortCellItems(Belt_SetDict.toArray(Belt_SetDict.diff(filteredItemsSet, sessionItemsSet, ListItemCompare.cmp)));
                      return Belt_Array.concat(sortCellItems(Belt_SetDict.toArray(sessionItemsSet)), diff);
                    }));
            } else {
              Curry._1(setActiveIndex, (function (param) {
                      
                    }));
              Curry._1(setSearchTerm, (function (param) {
                      return "";
                    }));
              Curry._1(setItemsShown, (function (param) {
                      return sortCellItems(selectedItems);
                    }));
            }
            
          }), [
          isEditing,
          searchTerm,
          selectedItems,
          allItems
        ]);
    var onArrowDown = React.useCallback((function (e) {
            Curry._1(setActiveIndex, (function (index) {
                    var newIndex = Belt_Option.mapWithDefault(index, 0, (function (index) {
                            return getNextItemInCycle(index, itemsShown.length);
                          }));
                    focusChildAtIndex(newIndex, listRef);
                    return newIndex;
                  }));
            e.preventDefault();
            e.stopPropagation();
            
          }), [
          itemsShown.length,
          listRef
        ]);
    var onArrowUp = React.useCallback((function (e) {
            Curry._1(setActiveIndex, (function (index) {
                    var itemCount = itemsShown.length;
                    var newIndex = Belt_Option.mapWithDefault(index, itemCount - 1 | 0, (function (index) {
                            return getPreviousItemInCycle(index, itemCount);
                          }));
                    focusChildAtIndex(newIndex, listRef);
                    return newIndex;
                  }));
            e.preventDefault();
            e.stopPropagation();
            
          }), [
          itemsShown.length,
          listRef
        ]);
    var randomStableKey = React.useMemo((function () {
            return "cell-edit-popup-" + Shortid();
          }), [isEditing]);
    var tmp;
    var exit = 0;
    if (onCreates !== undefined && itemsShown.length === 0 && isEditing) {
      tmp = Belt_Array.mapWithIndexU(Curry._1(onCreates, hidePopup), (function (index, param) {
              var onCreate = param[0];
              return React.createElement("li", {
                          key: "onCreate-" + String(index),
                          className: listItem(true, index),
                          role: "button",
                          onClick: (function (param) {
                              Curry._1(onCreateInitiated, searchTerm);
                              return Curry._1(onCreate, searchTerm);
                            })
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Medium",
                              color: Styles.Color.darkBlue,
                              children: Curry._1(param[1], searchTerm)
                            }));
            }));
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = itemsShown.length !== 0 ? Belt_Array.mapWithIndexU(itemsShown, (function (index, item) {
                var partial_arg = hidePopup;
                return React.createElement(CellEditPopup$MakeEditableCell$ListItemWrapper, {
                            activeIndex: activeIndex,
                            index: index,
                            isChecked: isChecked,
                            isEditing: isEditing,
                            item: item,
                            onListItemClick: (function (param) {
                                return Belt_Option.forEach(onViewItem, (function (onViewItem) {
                                              Curry._1(onViewItem, param);
                                              Belt_Option.forEach(partial_arg, (function (cb) {
                                                      return Curry._1(cb, undefined);
                                                    }));
                                              return Curry._1(onItemClicked, undefined);
                                            }));
                              }),
                            onToggle: onToggle,
                            onViewItem: onViewItem,
                            searchTerm: searchTerm,
                            shouldRender: Curry._1(getShouldRender, index + 1 | 0),
                            key: Curry._1(ListItem.Cell.getId, item)
                          });
              })) : React.createElement("li", {
              className: listItem(true, 0),
              role: "button",
              onClick: (function (param) {
                  return Curry._1(setIsEditing, (function (param) {
                                return true;
                              }));
                })
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Medium",
                  color: Styles.Color.light10,
                  children: "+ Add " + ListItem.Cell.popupTitle
                }));
    }
    return React.createElement("aside", {
                className: container(Caml.caml_int_max(1, itemsShown.length))
              }, React.createElement("header", {
                    className: header
                  }, React.createElement(FramerMotion.AnimatePresence, {
                        exitBeforeEnter: false,
                        initial: false,
                        children: React.createElement(FramerMotion.motion.div, {
                              animate: {
                                opacity: 1,
                                width: "100%"
                              },
                              transition: {
                                duration: 0.15
                              },
                              initial: {
                                opacity: 0,
                                width: "0px"
                              },
                              exit: {
                                opacity: 0,
                                width: "0px"
                              },
                              className: Curry._1(Css.style, {
                                    hd: Css.selector(" input", {
                                          hd: Css.width("inherit_"),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }),
                              key: isEditing ? "input" : "title",
                              children: isEditing ? React.createElement(TextInput.make, {
                                      onChange: (function (value) {
                                          Lodash.debouncedFunction(2000)(function (param) {
                                                return Curry._1(onSearch, value);
                                              });
                                          return Curry._1(setSearchTerm, (function (param) {
                                                        return value;
                                                      }));
                                        }),
                                      onFocus: (function (param) {
                                          return Curry._1(setActiveIndex, (function (param) {
                                                        
                                                      }));
                                        }),
                                      noBorder: true,
                                      placeholder: "Search " + ListItem.Cell.popupTitle + "...",
                                      forwardRef: searchTermRef,
                                      value: searchTerm
                                    }) : React.createElement($$Text.make, {
                                      element: "H1",
                                      size: "Medium",
                                      weight: "Medium",
                                      singleLine: true,
                                      children: ListItem.Cell.popupTitle
                                    })
                            })
                      }), React.createElement(Spacer.make, {
                        width: 8,
                        grow: 1.0
                      }), React.createElement(TextButton.make, {
                        onClick: (function (param) {
                            return Curry._1(setIsEditing, (function (isEditing) {
                                          return !isEditing;
                                        }));
                          }),
                        style: "Blue",
                        size: "Small",
                        children: isEditing ? "DONE" : "EDIT"
                      })), isEditing ? React.createElement(React.Fragment, undefined, React.createElement(KeyListener.make, {
                          keyName: "Escape",
                          onPress: onEscape
                        }), React.createElement(KeyListener.make, {
                          keyName: "ArrowDown",
                          onPress: onArrowDown
                        }), React.createElement(KeyListener.make, {
                          keyName: "ArrowUp",
                          onPress: onArrowUp
                        })) : null, React.createElement("div", {
                    className: listWrapper,
                    id: randomStableKey,
                    onScroll: onScrollThrottled
                  }, React.createElement("ul", {
                        ref: listRef,
                        className: list(itemsShown.length, isEditing)
                      }, tmp)));
  };
  var Popup = {
    make: CellEditPopup$MakeEditableCell$Popup
  };
  var CellEditPopup$MakeEditableCell = function (Props) {
    var allItems = Props.allItems;
    var children = Props.children;
    var className = Props.className;
    var onCreates = Props.onCreates;
    var disabled = Props.disabled;
    var onViewItem = Props.onViewItem;
    var onToggle = Props.onToggle;
    var viewerCanEdit = Props.viewerCanEdit;
    var selectedItems = Props.selectedItems;
    var onItemClicked = Props.onItemClicked;
    var onHover = Props.onHover;
    var onSearch = Props.onSearch;
    var onCreateInitiated = Props.onCreateInitiated;
    var match = React.useState(function () {
          return false;
        });
    var setIsEditing = match[1];
    var isEditing = match[0];
    var match$1 = React.useState(function () {
          
        });
    var setVisibilityTop = match$1[1];
    var visibilityTop = match$1[0];
    var match$2 = React.useState(function () {
          
        });
    var setWindowHeight = match$2[1];
    var windowHeight = match$2[0];
    return React.createElement(CellEditPopup$MakeEditableCell$PopupCell, {
                canEdit: viewerCanEdit,
                className: className,
                item: children,
                disabled: disabled,
                onHover: onHover,
                onLeave: (function (param) {
                    Curry._1(setWindowHeight, (function (param) {
                            
                          }));
                    return Curry._1(setVisibilityTop, (function (param) {
                                  
                                }));
                  }),
                onClick: (function (param) {
                    return Curry._1(onItemClicked, undefined);
                  }),
                onActivation: (function (isEditing) {
                    Curry._1(setIsEditing, (function (param) {
                            return isEditing;
                          }));
                    return Curry._1(setVisibilityTop, (function (param) {
                                  return 0;
                                }));
                  }),
                activated: isEditing,
                children: (function (hidePopup) {
                    return React.createElement(CellEditPopup$MakeEditableCell$Popup, {
                                visibilityTop: visibilityTop,
                                setVisibilityTop: setVisibilityTop,
                                selectedItems: selectedItems,
                                isEditing: isEditing,
                                onViewItem: onViewItem,
                                onItemClicked: onItemClicked,
                                setIsEditing: setIsEditing,
                                allItems: allItems,
                                onSearch: onSearch,
                                onCreates: onCreates,
                                hidePopup: hidePopup,
                                onCreateInitiated: onCreateInitiated,
                                onToggle: onToggle,
                                windowHeight: windowHeight,
                                setWindowHeight: setWindowHeight
                              });
                  })
              });
  };
  return {
          Utils: Utils,
          outerContainerStyles: outerContainerStyles,
          PopupCell: PopupCell,
          Style: Style,
          throttledFunction: throttledFunction,
          ListItemCompare: ListItemCompare,
          getFilteredItems: getFilteredItems,
          ListItemWrapper: ListItemWrapper,
          Popup: Popup,
          make: CellEditPopup$MakeEditableCell
        };
}

export {
  MakeEditableCell ,
  
}
/* Css Not a pure module */
