// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";

function IconEllipsis(Props) {
  var sizeOpt = Props.size;
  var verticalOpt = Props.vertical;
  var size = sizeOpt !== undefined ? sizeOpt : 14;
  var vertical = verticalOpt !== undefined ? verticalOpt : false;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css.transform({
                          NAME: "rotate",
                          VAL: vertical ? ({
                                NAME: "deg",
                                VAL: 90.0
                              }) : ({
                                NAME: "deg",
                                VAL: 0.0
                              })
                        }),
                    tl: /* [] */0
                  }),
              width: String(size),
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", undefined, React.createElement("circle", {
                      cx: "12",
                      cy: "13",
                      r: "2"
                    }), React.createElement("circle", {
                      cx: "3",
                      cy: "13",
                      r: "2"
                    }), React.createElement("circle", {
                      cx: "21",
                      cy: "13",
                      r: "2"
                    })));
}

var make = IconEllipsis;

export {
  make ,
  
}
/* Css Not a pure module */
