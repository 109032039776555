// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as AvoState from "./AvoState.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as TextInput from "./TextInput.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@mantine/core";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as InspectorIssuesFilterHooks from "./inspector/InspectorIssuesFilterHooks.mjs";
import * as InspectorIssuesFilterUtils from "../../shared/utils/InspectorIssuesFilterUtils.mjs";

function InspectorIssuesView__SavedViewName(Props) {
  var view = Props.view;
  var filteredIssuesCount = Props.filteredIssuesCount;
  var sendActions = SendActionsContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setIsSaving = match[1];
  var match$1 = React.useState(function () {
        return view.name;
      });
  var setNameInput = match$1[1];
  var nameInput = match$1[0];
  var match$2 = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match$2.orderedBy;
  var filters = match$2.filters;
  var match$3 = Hooks.useDisclosure(false);
  var actions = match$3[1];
  var addToast = Toast.useAddToast(undefined);
  var match$4 = WorkspaceContext.use(undefined);
  var schemaId = match$4.id;
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var match$5 = InspectorIssuesFilterHooks.useDraftState(undefined);
  var isDraft = match$5.isDraft;
  var match$6 = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var status = match$6[0];
  React.useEffect((function () {
          Curry._1(setNameInput, (function (param) {
                  return view.name;
                }));
          
        }), [view]);
  var handleSave = function (param) {
    Curry._1(setIsSaving, (function (param) {
            return true;
          }));
    return FirebaseUtils.updateSavedView(sendActions, schemaId, {
                id: view.id,
                name: nameInput,
                createdBy: view.createdBy,
                createdAt: view.createdAt,
                lastModifiedBy: view.lastModifiedBy,
                lastModifiedAt: view.lastModifiedAt,
                filters: view.filters,
                order: view.order,
                isArchived: view.isArchived
              }, Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid, (function (param) {
                  Curry._1(actions.close, undefined);
                  Curry._1(addToast, {
                        message: "Name Updated",
                        toastType: /* Success */0
                      });
                  Curry._1(setIsSaving, (function (param) {
                          return false;
                        }));
                  return AnalyticsRe.inspectorSavedViewInteraction(schemaBundle, AnalyticsRe.Bundle.inspectorSavedViews(nameInput, view.id, "Creator", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) : -1), InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), filteredIssuesCount, "SavedView", InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), "Rename", schemaBundle.schemaId);
                }), (function (err) {
                  Curry._1(addToast, {
                        message: err.RE_EXN_ID === AvoState.PermissionsException ? "Permissions needed to save view..." : "Failed to save view...",
                        toastType: /* Error */1
                      });
                  return Curry._1(setIsSaving, (function (param) {
                                return false;
                              }));
                }));
  };
  var disabled = nameInput === "" || nameInput === view.name;
  var tmp = {
    size: "Large",
    weight: "Semi",
    color: isDraft ? Styles.Color.light10 : Styles.Color.light12,
    children: view.name
  };
  var tmp$1 = isDraft ? "Em" : undefined;
  if (tmp$1 !== undefined) {
    tmp.element = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Mantine.Menu.make, {
              onChange: (function (param) {
                  return Curry._1(actions.close, undefined);
                }),
              opened: match$3[0],
              position: "bottom-start",
              children: null
            }, React.createElement(Core.Menu.Target, {
                  children: React.createElement("span", {
                        role: "button",
                        onClick: (function (param) {
                            return Curry._1(actions.toggle, undefined);
                          })
                      }, React.createElement($$Text.make, tmp))
                }), React.createElement(Mantine.Menu.Dropdown.make, {
                  additionalStyles: {
                    hd: Css.padding(Css.px(20)),
                    tl: {
                      hd: Css.maxWidth(Css.px(300)),
                      tl: /* [] */0
                    }
                  },
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Rename filtered view"
                    }), React.createElement(Spacer.make, {
                      height: 20
                    }), React.createElement(TextInput.make, {
                      onChange: (function (newName) {
                          return Curry._1(setNameInput, (function (param) {
                                        return newName;
                                      }));
                        }),
                      stretch: true,
                      onEnter: (function (param) {
                          if (disabled) {
                            return ;
                          } else {
                            return handleSave(undefined);
                          }
                        }),
                      value: nameInput
                    }), React.createElement(Spacer.make, {
                      height: 12
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("column"),
                              tl: {
                                hd: Css.justifyContent("stretch"),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement(Button.make, {
                          disabled: disabled,
                          label: "Rename filtered view",
                          loading: match[0],
                          onClick: (function (param) {
                              return handleSave(undefined);
                            })
                        }))));
}

var make = InspectorIssuesView__SavedViewName;

export {
  make ,
  
}
/* Css Not a pure module */
