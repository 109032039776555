// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as IconX from "./IconX.mjs";
import * as Input from "./Input.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import Shortid from "shortid";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as IconCopy from "./IconCopy.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as BinOpUtils from "./binOpUtils.mjs";
import * as ContextBox from "./ContextBox.mjs";
import * as CopyButton from "./CopyButton.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_format from "rescript/lib/es6/caml_format.js";
import * as OpsWithState from "./OpsWithState.mjs";
import * as IconCheckmark from "./IconCheckmark.mjs";
import * as PropertyInput from "./PropertyInput.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as GetEventSpecificPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/GetEventSpecificPropertyValuesUseCase.mjs";

function MetricItemCondition(Props) {
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var $$event = Props.event;
  var index = Props.index;
  var metric = Props.metric;
  var metricEvent = Props.metricEvent;
  var metricWhere = Props.metricWhere;
  var model = Props.model;
  var onSelectProperty = Props.onSelectProperty;
  var options = Props.options;
  var allPropertiesAvailableOnEventForMetricView = Props.allPropertiesAvailableOnEventForMetricView;
  var sendActions = SendActionsContext.use(undefined);
  var maybeProperty = ModelUtils.getPropertyById(metricWhere.propertyId, model);
  var maybeArchivedProperty = ModelUtils.getArchivedPropertyById(metricWhere.propertyId, model);
  var maybeEnrichedProperty = Belt_List.getBy(allPropertiesAvailableOnEventForMetricView, (function (property) {
          return property.id === metricWhere.propertyId;
        }));
  var property;
  var exit = 0;
  if (maybeProperty !== undefined && maybeProperty.TAG !== /* PropertyRef */0) {
    property = maybeProperty._0;
  } else {
    exit = 2;
  }
  if (exit === 2) {
    if (maybeEnrichedProperty !== undefined) {
      property = maybeEnrichedProperty;
    } else {
      if (maybeProperty !== undefined) {
        return "not found";
      }
      if (maybeArchivedProperty === undefined) {
        return "not found";
      }
      if (maybeArchivedProperty.TAG === /* PropertyRef */0) {
        return "not found";
      }
      var property$1 = maybeArchivedProperty._0;
      return React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.orange,
                      children: "Archived property: " + property$1.name
                    }), React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement("button", {
                      className: Curry._1(Css.merge, {
                            hd: Curry._1(Css.style, Styles.button),
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
                                        tl: {
                                          hd: Css.padding2(Css.px(5), Css.px(5)),
                                          tl: {
                                            hd: Css.marginLeft(Css.px(6)),
                                            tl: {
                                              hd: Css.hover({
                                                    hd: Css_Legacy_Core.SVG.stroke(Styles.Color.red),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      onClick: (function (param) {
                          return Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "RemoveMetricWhereV2",
                                            VAL: [
                                              metric.id,
                                              metricEvent.id,
                                              $$event.id,
                                              metricWhere.id
                                            ]
                                          },
                                          {
                                            eventId: $$event.id,
                                            propertyId: property$1.id,
                                            propertyQuery: property$1.id,
                                            metricId: metric.id,
                                            metricQuery: metric.id
                                          }
                                        ]]
                                    ]);
                        })
                    }, React.createElement(IconX.make, {
                          size: 12
                        })));
    }
  }
  var context = {
    eventId: $$event.id,
    propertyId: property.id,
    propertyQuery: property.id,
    metricId: metric.id,
    metricQuery: metric.id
  };
  var match = property.type_;
  var tmp = match === "bool" ? React.createElement(Select.make, {
          onSelect: (function (value) {
              return Curry.app(sendActions, [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          [[
                              {
                                NAME: "SetMetricWhereV2",
                                VAL: [
                                  metric.id,
                                  metricEvent.id,
                                  $$event.id,
                                  metricWhere.id,
                                  property.id,
                                  metricWhere.binOp,
                                  {
                                    hd: {
                                      NAME: "BooleanLit",
                                      VAL: Pervasives.bool_of_string(value)
                                    },
                                    tl: /* [] */0
                                  }
                                ]
                              },
                              {
                                eventId: $$event.id,
                                propertyId: property.id,
                                metricId: metric.id,
                                metricQuery: metric.id
                              }
                            ]]
                        ]);
            }),
          options: Belt_Array.concat(metricWhere.literals === /* [] */0 ? [[
                    {
                      NAME: "Label",
                      VAL: "Select value..."
                    },
                    ""
                  ]] : [], [
                [
                  {
                    NAME: "Label",
                    VAL: "true"
                  },
                  "true"
                ],
                [
                  {
                    NAME: "Label",
                    VAL: "false"
                  },
                  "false"
                ]
              ]),
          value: Belt_Option.getWithDefault(Belt_Option.map(Belt_List.head(metricWhere.literals), (function (literal) {
                      if (typeof literal === "object" && literal.NAME === "BooleanLit") {
                        return Pervasives.string_of_bool(literal.VAL);
                      } else {
                        return "";
                      }
                    })), "")
        }) : React.createElement(ContextBox.make, {
          children: (function (param, param$1, param$2, buttonRef) {
              var literals = metricWhere.literals;
              var allowedValues = GetEventSpecificPropertyValuesUseCase.getWithLegacyFallback(ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"), property, $$event);
              return [
                      React.createElement("button", {
                            ref: buttonRef,
                            className: Curry._1(Css.merge, {
                                  hd: Curry._1(Css.style, Styles.button),
                                  tl: {
                                    hd: Curry._1(Css.style, {
                                          hd: Css.border(Css.px(1), "solid", Styles.Color.darkBlue),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.lightBlue),
                                            tl: {
                                              hd: Css.color(Styles.Color.darkBlue),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                  tl: {
                                                    hd: Css.overflow("hidden"),
                                                    tl: {
                                                      hd: Css.textOverflow("ellipsis"),
                                                      tl: {
                                                        hd: Css.whiteSpace("nowrap"),
                                                        tl: {
                                                          hd: Css.maxWidth(Css.px(300)),
                                                          tl: {
                                                            hd: Css.textAlign("left"),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                })
                          }, literals ? Belt_List.toArray(Belt_List.map(literals, PropertyUtils.printPinnedValue)).join(", ") : "Select values..."),
                      React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.position("absolute"),
                                  tl: {
                                    hd: Css.top(Css.pct(100)),
                                    tl: {
                                      hd: Css.bottom(Css.px(0)),
                                      tl: {
                                        hd: Css.left(Css.px(10)),
                                        tl: {
                                          hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                          tl: {
                                            hd: Css.width(Css.px(200)),
                                            tl: {
                                              hd: Css.height(Css.px(200)),
                                              tl: {
                                                hd: Css.overflow("auto"),
                                                tl: {
                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                  tl: {
                                                    hd: Css.backgroundColor(Styles.Color.white),
                                                    tl: {
                                                      hd: Css.boxSizing("borderBox"),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, allowedValues.length !== 0 ? React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.height(Css.pct(100)),
                                        tl: /* [] */0
                                      })
                                }, React.createElement("div", undefined, Belt_Array.map(allowedValues, (function (param) {
                                            var value = param[0];
                                            var literal = {
                                              NAME: "StringLit",
                                              VAL: value
                                            };
                                            var isActive = Belt_List.some(metricWhere.literals, (function (activeLiteral) {
                                                    return Caml_obj.caml_equal({
                                                                NAME: "Literal",
                                                                VAL: activeLiteral
                                                              }, {
                                                                NAME: "Literal",
                                                                VAL: literal
                                                              });
                                                  }));
                                            return React.createElement("button", {
                                                        key: "stringlit-" + value,
                                                        className: Curry._1(Css.merge, {
                                                              hd: Curry._1(Css.style, Styles.button),
                                                              tl: {
                                                                hd: Curry._1(Css.style, {
                                                                      hd: Css.display("flex"),
                                                                      tl: {
                                                                        hd: Css.alignItems("center"),
                                                                        tl: {
                                                                          hd: Css.padding2(Css.px(5), Css.px(10)),
                                                                          tl: {
                                                                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                                                                            tl: {
                                                                              hd: Css.fontSize(Styles.FontSize.small),
                                                                              tl: {
                                                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                                tl: {
                                                                                  hd: Css.cursor("pointer"),
                                                                                  tl: {
                                                                                    hd: Css.hover({
                                                                                          hd: Css.backgroundColor(Styles.Color.light02),
                                                                                          tl: /* [] */0
                                                                                        }),
                                                                                    tl: {
                                                                                      hd: Css.width(Css.pct(100)),
                                                                                      tl: /* [] */0
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }),
                                                                tl: /* [] */0
                                                              }
                                                            }),
                                                        onClick: (function (param) {
                                                            if (isActive) {
                                                              return Curry.app(sendActions, [
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          [[
                                                                              {
                                                                                NAME: "SetMetricWhereV2",
                                                                                VAL: [
                                                                                  metric.id,
                                                                                  metricEvent.id,
                                                                                  $$event.id,
                                                                                  metricWhere.id,
                                                                                  metricWhere.propertyId,
                                                                                  metricWhere.binOp,
                                                                                  Belt_List.keep(metricWhere.literals, (function (existingLiteral) {
                                                                                          return Caml_obj.caml_notequal({
                                                                                                      NAME: "Literal",
                                                                                                      VAL: existingLiteral
                                                                                                    }, {
                                                                                                      NAME: "Literal",
                                                                                                      VAL: literal
                                                                                                    });
                                                                                        }))
                                                                                ]
                                                                              },
                                                                              context
                                                                            ]]
                                                                        ]);
                                                            } else {
                                                              return Curry.app(sendActions, [
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          [[
                                                                              {
                                                                                NAME: "SetMetricWhereV2",
                                                                                VAL: [
                                                                                  metric.id,
                                                                                  metricEvent.id,
                                                                                  $$event.id,
                                                                                  metricWhere.id,
                                                                                  metricWhere.propertyId,
                                                                                  metricWhere.binOp,
                                                                                  Belt_List.concat(metricWhere.literals, {
                                                                                        hd: literal,
                                                                                        tl: /* [] */0
                                                                                      })
                                                                                ]
                                                                              },
                                                                              context
                                                                            ]]
                                                                        ]);
                                                            }
                                                          })
                                                      }, React.createElement("div", {
                                                            className: Curry._1(Css.style, {
                                                                  hd: Css.display("flex"),
                                                                  tl: {
                                                                    hd: Css.alignItems("center"),
                                                                    tl: {
                                                                      hd: Css.justifyContent("center"),
                                                                      tl: {
                                                                        hd: Css.width(Css.px(16)),
                                                                        tl: {
                                                                          hd: Css.height(Css.px(16)),
                                                                          tl: {
                                                                            hd: Css.borderRadius(Styles.Border.radius),
                                                                            tl: {
                                                                              hd: Css.backgroundColor(isActive ? Styles.Color.darkBlue : "transparent"),
                                                                              tl: {
                                                                                hd: Css.border(Css.px(1), "solid", isActive ? Styles.Color.deepBlue : Styles.Color.light04),
                                                                                tl: {
                                                                                  hd: Css.marginRight(Css.px(10)),
                                                                                  tl: /* [] */0
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                })
                                                          }, isActive ? React.createElement(IconCheckmark.make, {
                                                                  size: 8,
                                                                  color: Styles.Color.white
                                                                }) : null), PropertyUtils.printPinnedValue(literal));
                                          }))), React.createElement(Link.make, {
                                      path: Router.Link.addDrawerItem(undefined, {
                                            NAME: "property",
                                            VAL: [
                                              property.id,
                                              undefined
                                            ]
                                          }),
                                      className: Curry._1(Css.style, {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.fontSize(Styles.FontSize.small),
                                              tl: {
                                                hd: Css.color(Styles.Color.darkBlue),
                                                tl: {
                                                  hd: Css.padding2(Css.px(12), Css.px(4)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }),
                                      children: "Missing a value? Edit the property"
                                    })) : React.createElement("div", undefined, React.createElement(OpsWithState.make, {
                                      value: "",
                                      onFlush: (function (param) {
                                          
                                        }),
                                      children: (function (value, onChange, onBlur, onFocus) {
                                          return React.createElement("form", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.display("flex"),
                                                            tl: {
                                                              hd: Css.alignItems("stretch"),
                                                              tl: /* [] */0
                                                            }
                                                          }),
                                                      onSubmit: (function (formEvent) {
                                                          formEvent.preventDefault();
                                                          Curry._1(onChange, "");
                                                          var match = property.type_;
                                                          var tmp;
                                                          switch (match) {
                                                            case "bool" :
                                                                tmp = {
                                                                  NAME: "BooleanLit",
                                                                  VAL: Pervasives.bool_of_string(value)
                                                                };
                                                                break;
                                                            case "float" :
                                                                tmp = {
                                                                  NAME: "FloatLit",
                                                                  VAL: Caml_format.caml_float_of_string(value)
                                                                };
                                                                break;
                                                            case "int" :
                                                                tmp = {
                                                                  NAME: "IntLit",
                                                                  VAL: Caml_format.caml_int_of_string(value)
                                                                };
                                                                break;
                                                            case "string" :
                                                                tmp = {
                                                                  NAME: "StringLit",
                                                                  VAL: value
                                                                };
                                                                break;
                                                            default:
                                                              tmp = {
                                                                NAME: "StringLit",
                                                                VAL: value
                                                              };
                                                          }
                                                          return Curry.app(sendActions, [
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [[
                                                                          {
                                                                            NAME: "SetMetricWhereV2",
                                                                            VAL: [
                                                                              metric.id,
                                                                              metricEvent.id,
                                                                              $$event.id,
                                                                              metricWhere.id,
                                                                              property.id,
                                                                              metricWhere.binOp,
                                                                              Belt_List.concat({
                                                                                    hd: tmp,
                                                                                    tl: /* [] */0
                                                                                  }, metricWhere.literals)
                                                                            ]
                                                                          },
                                                                          {
                                                                            eventId: $$event.id,
                                                                            propertyId: property.id,
                                                                            metricId: metric.id,
                                                                            metricQuery: metric.id
                                                                          }
                                                                        ]]
                                                                    ]);
                                                        })
                                                    }, React.createElement("div", {
                                                          className: Curry._1(Css.style, {
                                                                hd: Css.flex({
                                                                      NAME: "num",
                                                                      VAL: 3.0
                                                                    }),
                                                                tl: /* [] */0
                                                              })
                                                        }, React.createElement(Input.make, {
                                                              value: value,
                                                              onChange: onChange,
                                                              placeholder: "Add value...",
                                                              onBlur: onBlur,
                                                              onFocus: onFocus,
                                                              className: {
                                                                hd: Css.borderRadius(Css.px(0)),
                                                                tl: {
                                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                                  tl: {
                                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                    tl: {
                                                                      hd: Css.border(Css.px(0), "solid", Styles.Color.light02),
                                                                      tl: {
                                                                        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                                                                        tl: {
                                                                          hd: Css.padding2(Css.px(10), Css.px(15)),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              },
                                                              containerClassName: {
                                                                hd: Css.margin(Css.px(0)),
                                                                tl: /* [] */0
                                                              }
                                                            })), React.createElement("button", {
                                                          className: Curry._1(Css.merge, {
                                                                hd: Curry._1(Css.style, Styles.buttonReset),
                                                                tl: {
                                                                  hd: Curry._1(Css.style, {
                                                                        hd: Css.backgroundColor(Styles.Color.darkBlue),
                                                                        tl: {
                                                                          hd: Css.color(Styles.Color.white),
                                                                          tl: {
                                                                            hd: Css.padding2(Css.px(8), Css.px(8)),
                                                                            tl: {
                                                                              hd: Css.margin(Css.px(0)),
                                                                              tl: {
                                                                                hd: Css.hover({
                                                                                      hd: Css.backgroundColor(Styles.Color.deepBlue),
                                                                                      tl: /* [] */0
                                                                                    }),
                                                                                tl: {
                                                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                                  tl: /* [] */0
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }),
                                                                  tl: /* [] */0
                                                                }
                                                              })
                                                        }, "Add"));
                                        })
                                    }), React.createElement("div", undefined, Belt_List.toArray(Belt_List.map(metricWhere.literals, (function (literal) {
                                                var variant = literal.NAME;
                                                return React.createElement("div", {
                                                            key: variant === "BooleanLit" ? "booleanlit-" + Pervasives.string_of_bool(literal.VAL) : (
                                                                variant === "StringLit" ? "stringlit-" + literal.VAL : (
                                                                    variant === "FloatLit" ? "floatlit-" + Pervasives.string_of_float(literal.VAL) : "intlit-" + String(literal.VAL)
                                                                  )
                                                              ),
                                                            className: Curry._1(Css.style, {
                                                                  hd: Css.display("flex"),
                                                                  tl: {
                                                                    hd: Css.alignItems("center"),
                                                                    tl: {
                                                                      hd: Css.justifyContent("spaceBetween"),
                                                                      tl: {
                                                                        hd: Css.padding2(Css.px(5), Css.px(15)),
                                                                        tl: {
                                                                          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                                                                          tl: {
                                                                            hd: Css.fontSize(Styles.FontSize.small),
                                                                            tl: {
                                                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                              tl: /* [] */0
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                })
                                                          }, React.createElement("div", undefined, PropertyUtils.printPinnedValue(literal)), React.createElement("button", {
                                                                className: Curry._1(Css.merge, {
                                                                      hd: Curry._1(Css.style, Styles.button),
                                                                      tl: {
                                                                        hd: Curry._1(Css.style, {
                                                                              hd: Css.display("flex"),
                                                                              tl: {
                                                                                hd: Css.alignItems("center"),
                                                                                tl: {
                                                                                  hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
                                                                                  tl: {
                                                                                    hd: Css.padding4(Css.px(5), Css.px(0), Css.px(5), Css.px(15)),
                                                                                    tl: {
                                                                                      hd: Css.hover({
                                                                                            hd: Css_Legacy_Core.SVG.stroke(Styles.Color.red),
                                                                                            tl: /* [] */0
                                                                                          }),
                                                                                      tl: /* [] */0
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }),
                                                                onClick: (function (param) {
                                                                    return Curry.app(sendActions, [
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                [[
                                                                                    {
                                                                                      NAME: "SetMetricWhereV2",
                                                                                      VAL: [
                                                                                        metric.id,
                                                                                        metricEvent.id,
                                                                                        $$event.id,
                                                                                        metricWhere.id,
                                                                                        property.id,
                                                                                        metricWhere.binOp,
                                                                                        Belt_List.keep(metricWhere.literals, (function (item) {
                                                                                                return Caml_obj.caml_notequal(item, literal);
                                                                                              }))
                                                                                      ]
                                                                                    },
                                                                                    context
                                                                                  ]]
                                                                              ]);
                                                                  })
                                                              }, React.createElement(IconX.make, {
                                                                    size: 12
                                                                  })));
                                              }))))))
                    ];
            })
        });
  var literals = metricWhere.literals;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.margin2(Css.px(5), Css.px(0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontSize(Styles.FontSize.small),
                        tl: {
                          hd: Css.color(Styles.Color.light10),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: /* [] */0
                          }
                        }
                      })
                }, index > 0 ? "and where" : "where"), React.createElement(PropertyInput.make, {
                  options: options,
                  onSelect: (function (item, param) {
                      if (item.NAME === "Group") {
                        return Pervasives.failwith("Property bundles cannot be added to other property bundles.");
                      }
                      var property = item.VAL;
                      Curry._1(onSelectProperty, undefined);
                      if (Belt_List.some(allPropertiesAvailableOnEventForMetricView, (function (prop) {
                                return prop.id === property.id;
                              }))) {
                        return Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    [[
                                        {
                                          NAME: "SetMetricWhereV2",
                                          VAL: [
                                            metric.id,
                                            metricEvent.id,
                                            $$event.id,
                                            metricWhere.id,
                                            property.id,
                                            "EQ",
                                            /* [] */0
                                          ]
                                        },
                                        context
                                      ]]
                                  ]);
                      } else if (window.confirm("The property " + property.name + " is not attached to the " + $$event.name + " event. Would you like to add it?")) {
                        return Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    [
                                      [
                                        {
                                          NAME: "AddPropertyRef",
                                          VAL: [
                                            $$event.id,
                                            property.id
                                          ]
                                        },
                                        {
                                          eventId: $$event.id,
                                          eventQuery: $$event.id,
                                          propertyId: property.id,
                                          propertyQuery: property.id,
                                          metricId: metric.id
                                        }
                                      ],
                                      [
                                        {
                                          NAME: "SetMetricWhereV2",
                                          VAL: [
                                            metric.id,
                                            metricEvent.id,
                                            $$event.id,
                                            metricWhere.id,
                                            property.id,
                                            "EQ",
                                            /* [] */0
                                          ]
                                        },
                                        context
                                      ]
                                    ]
                                  ]);
                      } else {
                        return ;
                      }
                    }),
                  canCreate: true,
                  extraCreateActions: (function (propertyId) {
                      return [[
                                {
                                  NAME: "SetMetricWhereV2",
                                  VAL: [
                                    metric.id,
                                    metricEvent.id,
                                    $$event.id,
                                    Shortid(),
                                    propertyId,
                                    "EQ",
                                    /* [] */0
                                  ]
                                },
                                context
                              ]];
                    }),
                  currentFilters: currentFilters,
                  getIndexOfValue: (function (options) {
                      return Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.mapWithIndex(options, (function (index, option) {
                                                return [
                                                        index,
                                                        option
                                                      ];
                                              })), (function (x) {
                                            var match = x[1];
                                            if (typeof match !== "object") {
                                              return ;
                                            }
                                            if (match.NAME !== "Item") {
                                              return ;
                                            }
                                            var match$1 = match.VAL;
                                            if (typeof match$1 === "object" && match$1.NAME === "Property" && match$1.VAL.id === property.id) {
                                              return x[0];
                                            }
                                            
                                          })), 0), 0);
                    }),
                  renderButton: (function (onClick, buttonRef) {
                      return React.createElement("button", {
                                  ref: buttonRef,
                                  className: Curry._1(Css.merge, {
                                        hd: Curry._1(Css.style, Styles.button),
                                        tl: {
                                          hd: Curry._1(Css.style, {
                                                hd: Css.border(Css.px(1), "solid", Styles.Color.darkBlue),
                                                tl: {
                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.darkBlue),
                                                    tl: {
                                                      hd: Css.backgroundColor(Styles.Color.lightBlue),
                                                      tl: {
                                                        hd: Css.fontSize(Styles.FontSize.small),
                                                        tl: {
                                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                                          tl: {
                                                            hd: Css.marginLeft(Css.px(6)),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }),
                                  onClick: onClick
                                }, property.name);
                    }),
                  parent: {
                    NAME: "Event",
                    VAL: $$event.id
                  },
                  sendActions: sendActions,
                  filters: filters,
                  propertyLocation: "MetricDetails"
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(6)),
                        tl: /* [] */0
                      })
                }, React.createElement(Select.make, {
                      onSelect: (function (value) {
                          var binOp = Belt_Option.getWithDefault(TrackingPlanModel.binOpFromJs(value), "EQ");
                          return Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "SetMetricWhereV2",
                                            VAL: [
                                              metric.id,
                                              metricEvent.id,
                                              $$event.id,
                                              metricWhere.id,
                                              property.id,
                                              binOp,
                                              metricWhere.literals
                                            ]
                                          },
                                          {
                                            eventId: $$event.id,
                                            propertyId: property.id,
                                            metricId: metric.id,
                                            metricQuery: metric.id
                                          }
                                        ]]
                                    ]);
                        }),
                      options: Belt_Array.map(BinOpUtils.binOps, (function (binOp) {
                              return [
                                      {
                                        NAME: "Label",
                                        VAL: BinOpUtils.printBinOp(binOp)
                                      },
                                      TrackingPlanModel.binOpToJs(binOp)
                                    ];
                            })),
                      value: TrackingPlanModel.binOpToJs(metricWhere.binOp)
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(6)),
                        tl: {
                          hd: Css.marginTop(Css.px(2)),
                          tl: /* [] */0
                        }
                      })
                }, tmp), literals ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginTop(Css.px(2)),
                          tl: /* [] */0
                        })
                  }, React.createElement(CopyButton.make, {
                        copyString: Belt_List.toArray(Belt_List.map(literals, (function (x) {
                                      var variant = x.NAME;
                                      if (variant === "BooleanLit") {
                                        return Pervasives.string_of_bool(x.VAL);
                                      } else if (variant === "StringLit") {
                                        return x.VAL;
                                      } else {
                                        return x.VAL.toString();
                                      }
                                    }))).join(", "),
                        children: React.createElement(IconCopy.make, {
                              size: 12
                            })
                      })) : null, React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.button),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
                                    tl: {
                                      hd: Css.padding2(Css.px(5), Css.px(5)),
                                      tl: {
                                        hd: Css.marginLeft(Css.px(6)),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css_Legacy_Core.SVG.stroke(Styles.Color.red),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (param) {
                      return Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  [[
                                      {
                                        NAME: "RemoveMetricWhereV2",
                                        VAL: [
                                          metric.id,
                                          metricEvent.id,
                                          $$event.id,
                                          metricWhere.id
                                        ]
                                      },
                                      context
                                    ]]
                                ]);
                    })
                }, React.createElement(IconX.make, {
                      size: 12
                    })));
}

var make = MetricItemCondition;

export {
  make ,
  
}
/* Css Not a pure module */
