// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ContextMenu from "../ContextMenu.mjs";
import * as RouterStore from "../RouterStore.mjs";
import * as FramerMotion from "framer-motion";
import CopyTextToClipboard from "copy-text-to-clipboard";

var heightInPixels = Css.px(64);

function root(withBorder) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.flexShrink(0.0),
                    tl: {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.height(heightInPixels),
                        tl: {
                          hd: Css.borderBottom(Css.px(withBorder ? 1 : 0), "solid", Styles.Color.light04),
                          tl: {
                            hd: Css.paddingLeft(Css.px(16)),
                            tl: {
                              hd: Css.paddingRight(Css.px(20)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var backPlaceholder = Curry._1(Css.style, {
      hd: Css.width(Css.px(24)),
      tl: /* [] */0
    });

var info = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.justifyContent("stretch"),
          tl: {
            hd: Css.flexGrow(1.0),
            tl: {
              hd: Css.position("relative"),
              tl: {
                hd: Css.marginLeft(Css.px(20)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var Style = {
  root: root,
  backPlaceholder: backPlaceholder,
  info: info
};

function DrawerHeader(Props) {
  var kind = Props.kind;
  var linkToCopy = Props.linkToCopy;
  var onBack = Props.onBack;
  var onExit = Props.onExit;
  var onLinkCopy = Props.onLinkCopy;
  var options = Props.options;
  var withBorderOpt = Props.withBorder;
  var children = Props.children;
  var withBorder = withBorderOpt !== undefined ? withBorderOpt : true;
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  var addToast = Toast.useAddToast(undefined);
  return React.createElement("header", {
              className: root(withBorder)
            }, React.createElement("div", {
                  className: backPlaceholder
                }, React.createElement(FramerMotion.AnimatePresence, {
                      children: Belt_List.length(drawerItems) > 1 ? React.createElement(FramerMotion.motion.div, {
                              animate: {
                                opacity: 1,
                                x: 0
                              },
                              transition: {
                                duration: 0.2
                              },
                              initial: {
                                opacity: 0,
                                x: 10
                              },
                              exit: {
                                opacity: 0,
                                x: 10
                              },
                              key: "slideOver",
                              children: React.createElement(Button.make, {
                                    icon: "arrowLeft",
                                    onClick: (function (param) {
                                        Router.Schema.popDrawerItem(undefined, undefined);
                                        return Belt_Option.forEach(onBack, (function (cb) {
                                                      return Curry._1(cb, undefined);
                                                    }));
                                      }),
                                    size: "tiny",
                                    style: "outline"
                                  })
                            }) : null
                    })), React.createElement("div", {
                  className: info
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light09,
                      children: kind
                    }), children), options !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(ContextMenu.make, {
                        options: options
                      }), React.createElement(Spacer.make, {
                        width: 8
                      })) : null, linkToCopy !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                        icon: "link",
                        onClick: (function (param) {
                            CopyTextToClipboard(linkToCopy);
                            Curry._1(addToast, {
                                  message: "Link copied!",
                                  toastType: /* Success */0
                                });
                            return Belt_Option.forEach(onLinkCopy, (function (cb) {
                                          return Curry._1(cb, undefined);
                                        }));
                          }),
                        size: "tiny",
                        style: "ghost"
                      }), React.createElement(Spacer.make, {
                        width: 8
                      })) : null, React.createElement(Button.make, {
                  icon: "close",
                  id: "drawer-container-close",
                  onClick: (function ($$event) {
                      var match = Belt_List.head(drawerItems);
                      if (match !== undefined && typeof match === "object" && match.NAME === "event" && match.VAL[3]) {
                        return Router.Schema.popDrawerItem(undefined, undefined);
                      }
                      Router.Schema.clearDrawerItems(undefined, undefined);
                      return Belt_Option.forEach(onExit, (function (cb) {
                                    return Curry._1(cb, undefined);
                                  }));
                    }),
                  size: "tiny",
                  style: "outline"
                }));
}

var make = DrawerHeader;

export {
  heightInPixels ,
  Style ,
  make ,
  
}
/* heightInPixels Not a pure module */
