// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as TextInput from "./TextInput.mjs";
import * as Timestamp from "./Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as CreateServiceAccountEndpoint from "../../shared/endpoints/CreateServiceAccountEndpoint.mjs";
import * as DeleteServiceAccountEndpoint from "../../shared/endpoints/DeleteServiceAccountEndpoint.mjs";

function createServiceAccount(workspaceId, name) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                  return fetch("/api/serviceaccount/create", Fetch.RequestInit.make(/* Post */2, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + token
                                    ]
                                  ], Caml_option.some(JSON.stringify(CreateServiceAccountEndpoint.$$Request.encode({
                                                schemaId: workspaceId,
                                                serviceAccountName: name
                                              }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                }).then(function (prim) {
                return prim.json();
              }).then(function (json) {
              return Promise.resolve(CreateServiceAccountEndpoint.$$Response.decode(json));
            });
}

var rootStyles = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(24)),
      tl: {
        hd: Css.paddingRight(Css.px(32)),
        tl: {
          hd: Css.paddingBottom(Css.px(24)),
          tl: {
            hd: Css.paddingLeft(Css.px(32)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.borderTop(Css.px(1), Css.solid, Styles.Color.light04),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var inputBoxStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.marginTop(Css.px(8)),
        tl: {
          hd: Css.marginBottom(Css.px(16)),
          tl: {
            hd: Css.marginLeft(Css.px(-9)),
            tl: /* [] */0
          }
        }
      }
    });

var docsLinkStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.deepBlue),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.medium),
        tl: {
          hd: Css.textDecoration("none"),
          tl: {
            hd: Css.hover({
                  hd: Css.textDecoration("underline"),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

function ServiceAccounts$Create(Props) {
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setPending = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setName = match$1[1];
  var name = match$1[0];
  var handleCreateToken = function (param) {
    Curry._1(setPending, (function (param) {
            return true;
          }));
    $$Promise.$$catch(createServiceAccount(workspace.id, name).then(function (serviceAccount) {
              Curry._1(globalSend, {
                    TAG: /* OpenModal */4,
                    _0: {
                      NAME: "ServiceAccountSecretCreated",
                      VAL: serviceAccount
                    }
                  });
              Curry._1(setPending, (function (param) {
                      return false;
                    }));
              Curry._1(setName, (function (param) {
                      return "";
                    }));
              Curry.app(sendActions, [
                    "Master",
                    false,
                    undefined,
                    undefined,
                    true,
                    undefined,
                    undefined,
                    (function (_branch) {
                        return AnalyticsRe.serviceAccountCreated(schemaBundle, name, workspace.id, workspace.id, workspace.id, workspace.serviceAccounts.length + 1 | 0);
                      }),
                    undefined,
                    [[
                        {
                          NAME: "CreateServiceAccount",
                          VAL: serviceAccount.name
                        },
                        {
                          serviceAccountName: serviceAccount.name
                        }
                      ]]
                  ]);
              return Promise.resolve(undefined);
            }), (function (error) {
            Sentry.captureException(error, {});
            Curry._1(addToast, {
                  message: "Could not create Service Account",
                  toastType: /* Error */1
                });
            Curry._1(setPending, (function (param) {
                    return false;
                  }));
            return Promise.resolve(undefined);
          }));
    
  };
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: "Add Service Account"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement($$Text.make, {
                  element: "Label",
                  size: "Small",
                  color: Styles.Color.light10,
                  children: "Service Account Name"
                }), React.createElement("div", {
                  className: inputBoxStyles
                }, React.createElement(TextInput.make, {
                      onChange: (function (newName) {
                          return Curry._1(setName, (function (param) {
                                        return newName.replace(/[^A-Za-z0-9]+/g, "_");
                                      }));
                        }),
                      placeholder: "Ingestion_time_validation",
                      value: name
                    }), React.createElement(Spacer.make, {
                      width: 12
                    }), React.createElement(Button.make, {
                      disabled: name === "",
                      icon: "plus",
                      label: "Generate Secret",
                      loading: match[0],
                      onClick: handleCreateToken
                    })), React.createElement($$Text.make, {
                  element: "Label",
                  size: "Small",
                  color: Styles.Color.light10,
                  children: null
                }, "Learn more about Service Accounts in the ", React.createElement("a", {
                      className: docsLinkStyles,
                      href: "https://www.avo.app/docs/public-api/overview",
                      rel: "noopener noreferrer",
                      target: "_blank"
                    }, "Avo docs"), "."));
}

var Create = {
  createServiceAccount: createServiceAccount,
  rootStyles: rootStyles,
  inputBoxStyles: inputBoxStyles,
  docsLinkStyles: docsLinkStyles,
  make: ServiceAccounts$Create
};

function deleteServiceAccountRequest(schemaId, serviceAccountName) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
              return fetch("/api/serviceaccount/delete", Fetch.RequestInit.make(/* Post */2, [
                                [
                                  "Accept",
                                  "application/json"
                                ],
                                [
                                  "Content-Type",
                                  "application/json"
                                ],
                                [
                                  "Authorization",
                                  "Bearer " + token
                                ]
                              ], Caml_option.some(JSON.stringify(DeleteServiceAccountEndpoint.$$Request.encode({
                                            schemaId: schemaId,
                                            serviceAccountName: serviceAccountName
                                          }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
            });
}

var rootStyles$1 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingTop(Css.px(12)),
          tl: {
            hd: Css.paddingRight(Css.px(32)),
            tl: {
              hd: Css.paddingBottom(Css.px(12)),
              tl: {
                hd: Css.paddingLeft(Css.px(32)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function ServiceAccounts$ServiceAccountRow(Props) {
  var workspace = Props.workspace;
  var viewerhasEditorPermission = Props.viewerhasEditorPermission;
  var serviceAccount = Props.serviceAccount;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var creatorState = FirebaseFetcherHooks.useUser(serviceAccount.createdBy);
  var addToast = Toast.useAddToast(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setPendingDeletion = match[1];
  var pendingDeletion = match[0];
  var tmp;
  if (typeof creatorState === "object") {
    var timestamp = serviceAccount.lastUsedAt;
    tmp = React.createElement(React.Fragment, undefined, "Created by ", AvoConfig.getUserDisplayName(creatorState.VAL), " ", React.createElement(Timestamp.NonNull.make, {
              date: serviceAccount.createdAt
            }), " · ", timestamp !== undefined ? React.createElement(React.Fragment, undefined, "Last used ", React.createElement(Timestamp.NonNull.make, {
                    date: Caml_option.valFromOption(timestamp)
                  })) : "Never used");
  } else {
    tmp = " ";
  }
  return React.createElement("div", {
              className: rootStyles$1
            }, React.createElement("div", {
                  className: Curry._1(Css.style, /* [] */0)
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: serviceAccount.name
                    }), React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: tmp
                    })), React.createElement(Spacer.make, {
                  width: 8,
                  grow: 1.0
                }), viewerhasEditorPermission ? React.createElement(ContextMenu.make, {
                    button: React.createElement(Button.make, {
                          disabled: pendingDeletion,
                          icon: "more",
                          loading: pendingDeletion,
                          size: "tiny",
                          style: "ghost"
                        }),
                    options: [{
                        NAME: "WarningOption",
                        VAL: {
                          label: "Delete Service Account",
                          onClick: (function (param) {
                              return Curry._1(globalSend, {
                                          TAG: /* OpenModal */4,
                                          _0: {
                                            NAME: "ConfirmModal",
                                            VAL: [
                                              "Delete Service Account",
                                              "Are you sure you want to delete " + serviceAccount.name + "? It can not be recovered.",
                                              "Delete",
                                              (function (param) {
                                                  var serviceAccountName = serviceAccount.name;
                                                  Curry._1(setPendingDeletion, (function (param) {
                                                          return true;
                                                        }));
                                                  $$Promise.$$catch(deleteServiceAccountRequest(workspace.id, serviceAccountName).then(function (param) {
                                                            Curry._1(setPendingDeletion, (function (param) {
                                                                    return false;
                                                                  }));
                                                            Curry.app(sendActions, [
                                                                  "Master",
                                                                  false,
                                                                  undefined,
                                                                  undefined,
                                                                  true,
                                                                  undefined,
                                                                  undefined,
                                                                  (function (_branch) {
                                                                      return AnalyticsRe.serviceAccountDeleted(schemaBundle, serviceAccountName, workspace.id, workspace.id, workspace.id, workspace.serviceAccounts.length - 1 | 0);
                                                                    }),
                                                                  undefined,
                                                                  [[
                                                                      {
                                                                        NAME: "DeleteServiceAccount",
                                                                        VAL: serviceAccount.name
                                                                      },
                                                                      {
                                                                        serviceAccountName: serviceAccount.name
                                                                      }
                                                                    ]]
                                                                ]);
                                                            return Promise.resolve(undefined);
                                                          }), (function (error) {
                                                          Curry._1(setPendingDeletion, (function (param) {
                                                                  return false;
                                                                }));
                                                          Sentry.captureException(error, {});
                                                          Curry._1(addToast, {
                                                                message: "Could not delete Service Account",
                                                                toastType: /* Error */1
                                                              });
                                                          return Promise.resolve(undefined);
                                                        }));
                                                  
                                                }),
                                              (function (param) {
                                                  
                                                })
                                            ]
                                          }
                                        });
                            })
                        }
                      }]
                  }) : null);
}

var ServiceAccountRow = {
  deleteServiceAccountRequest: deleteServiceAccountRequest,
  rootStyles: rootStyles$1,
  make: ServiceAccounts$ServiceAccountRow
};

function ServiceAccounts(Props) {
  var viewerhasEditorPermission = Props.viewerhasEditorPermission;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingTop(Css.px(16)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(16)),
                      tl: {
                        hd: Css.maxWidth(Css.px(600)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, Belt_Array.map(workspace.serviceAccounts, (function (serviceAccount) {
                    return React.createElement(ServiceAccounts$ServiceAccountRow, {
                                workspace: workspace,
                                viewerhasEditorPermission: viewerhasEditorPermission,
                                serviceAccount: serviceAccount,
                                key: serviceAccount.name
                              });
                  })));
}

var make = ServiceAccounts;

export {
  Create ,
  ServiceAccountRow ,
  make ,
  
}
/* rootStyles Not a pure module */
