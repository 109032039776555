// Generated by ReScript, PLEASE EDIT WITH CARE


var instance = new ComlinkWorker(new URL("./ChangesWorker.mjs", import.meta.url), {});

function getModelChanges(fromModelWithFlattenedEventVariantsJson, toModelWithFlattenedEventVariantsJson) {
  return instance.getChanges(fromModelWithFlattenedEventVariantsJson, toModelWithFlattenedEventVariantsJson);
}

export {
  getModelChanges ,
  
}
/* instance Not a pure module */
