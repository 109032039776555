// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as BranchStateFirebaseModel from "../../shared/models/BranchStateFirebaseModel.mjs";

function enrichStatusWithApprovals(status, approvalsCount, workspace) {
  var match = AvoLimits.ApprovalWorkflow.computeAvailability(workspace);
  var approvalWorkflow = match === "AvailableDuringTrial" || match === "Available";
  if (status === "Closed") {
    return "Closed";
  } else if (status === "ChangesRequested") {
    if (approvalWorkflow) {
      return "ChangesRequested";
    } else {
      return "Open";
    }
  } else if (status === "Approved") {
    if (approvalWorkflow) {
      if (workspace.settings.numRequiredApprovals <= approvalsCount) {
        return "Approved";
      } else {
        return "ReadyForReview";
      }
    } else {
      return "Open";
    }
  } else if (status === "Draft") {
    if (approvalWorkflow) {
      return "Draft";
    } else {
      return "Open";
    }
  } else if (status === "Unknown") {
    return "Unknown";
  } else if (status === "Open") {
    return "Open";
  } else if (status === "Merged") {
    return "Merged";
  } else if (approvalWorkflow) {
    return "ReadyForReview";
  } else {
    return "Open";
  }
}

function getBackgroundColor(status) {
  if (status === "Closed") {
    return Styles.Color.paleRed;
  } else if (status === "ChangesRequested") {
    return Styles.Color.paleOrange;
  } else if (status === "Approved") {
    return Styles.Color.paleGreen;
  } else if (status === "Open" || status === "Unknown" || status === "Draft") {
    return Styles.Color.light03;
  } else if (status === "Merged") {
    return Styles.Color.paleBlue;
  } else {
    return Styles.Color.palePurple;
  }
}

function getColor(status) {
  if (status === "Closed") {
    return Styles.Color.deepRed;
  } else if (status === "ChangesRequested") {
    return Styles.Color.deepOrange;
  } else if (status === "Approved") {
    return Styles.Color.deepGreen;
  } else if (status === "Open" || status === "Unknown" || status === "Draft") {
    return Styles.Color.light12;
  } else if (status === "Merged") {
    return Styles.Color.deepBlue;
  } else {
    return Styles.Color.deepPurple;
  }
}

function BranchStatusPill(Props) {
  var status = Props.status;
  var approvalsCount = Props.approvalsCount;
  var workspace = Props.workspace;
  var finalStatus = enrichStatusWithApprovals(status, approvalsCount, workspace);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width("fitContent"),
                    tl: {
                      hd: Css.marginRight(Css.px(4)),
                      tl: {
                        hd: Css.borderRadius(Css.px(32)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(8)),
                          tl: {
                            hd: Css.paddingRight(Css.px(8)),
                            tl: {
                              hd: Css.paddingTop(Css.px(4)),
                              tl: {
                                hd: Css.paddingBottom(Css.px(3)),
                                tl: {
                                  hd: Css.backgroundColor(getBackgroundColor(finalStatus)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Medium",
                  singleLine: true,
                  color: getColor(finalStatus),
                  children: BranchStateFirebaseModel.branchStatusToJs(finalStatus)
                }));
}

var make = BranchStatusPill;

export {
  enrichStatusWithApprovals ,
  getBackgroundColor ,
  getColor ,
  make ,
  
}
/* Css Not a pure module */
