// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactWindow from "react-window";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Css_AtomicTypes from "bs-css/src/Css_AtomicTypes.mjs";
import LodashDebounce from "lodash.debounce";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";

var sidePadding = Css.px(16);

function root(customMinWidth) {
  return Curry._1(Css.style, {
              hd: Css.borderRadius(Styles.Border.radius),
              tl: {
                hd: Belt_Option.mapWithDefault(customMinWidth, Styles.emptyStyle, (function (customMinWidth) {
                        return Css.minWidth(Css.px(customMinWidth));
                      })),
                tl: /* [] */0
              }
            });
}

var header = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.zIndex(1),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.width(Css.pct(100.0)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.light01),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                  tl: {
                    hd: Css.borderTopLeftRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.borderTopRightRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.paddingLeft(sidePadding),
                        tl: {
                          hd: Css.paddingRight(sidePadding),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var headerItemContainer = Curry._1(Css.style, {
      hd: Css.flexBasis("zero"),
      tl: {
        hd: Css.flexGrow(1.0),
        tl: {
          hd: Css.flexShrink(1.0),
          tl: {
            hd: Css.paddingTop(Css.px(12)),
            tl: {
              hd: Css.paddingBottom(Css.px(12)),
              tl: {
                hd: Css.paddingRight(Css.px(6)),
                tl: {
                  hd: Css.color(Styles.Color.light10),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function headerItem(canOrder, isOrderedBy) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.gap(Css.px(4)),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.borderRadius(Css.px(12)),
                          tl: {
                            hd: Css.padding2(Css.px(4), Css.px(8)),
                            tl: {
                              hd: Css.margin2(Css.px(-4), Css.px(-8)),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$short
                                    }, undefined, undefined, "all"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: canOrder ? Curry._1(Css.style, {
                        hd: Css.hover({
                              hd: isOrderedBy ? Css.backgroundColor(Styles.Color.light07) : Css.backgroundColor(Styles.Color.light04),
                              tl: {
                                hd: Css.color(Styles.Color.light12),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      }) : "",
                tl: {
                  hd: isOrderedBy ? Curry._1(Css.style, {
                          hd: Css.backgroundColor(Styles.Color.light04),
                          tl: /* [] */0
                        }) : "",
                  tl: {
                    hd: isOrderedBy ? Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.bold),
                            tl: /* [] */0
                          }) : "",
                    tl: {
                      hd: isOrderedBy ? Curry._1(Css.style, {
                              hd: Css.color(Styles.Color.light12),
                              tl: /* [] */0
                            }) : "",
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function row(active) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.width(Css.pct(100.0)),
                tl: {
                  hd: Css.paddingLeft(sidePadding),
                  tl: {
                    hd: Css.paddingRight(sidePadding),
                    tl: {
                      hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                      tl: {
                        hd: Css.borderTopWidth("zero"),
                        tl: {
                          hd: Css.lastChild({
                                hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.borderBottomRightRadius(Styles.Border.radius),
                                  tl: /* [] */0
                                }
                              }),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.backgroundColor(active ? Styles.Color.light02 : Styles.Color.white),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$short
                                    }, undefined, undefined, "background-color"),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.backgroundColor(active ? Styles.Color.light04 : Styles.Color.light02),
                                        tl: {
                                          hd: Css.cursor("pointer"),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var rowItem = Curry._1(Css.style, {
      hd: Css.flexBasis("zero"),
      tl: {
        hd: Css.flexGrow(1.0),
        tl: {
          hd: Css.flexShrink(1.0),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.gap(Css.px(2)),
              tl: {
                hd: Css.flexWrap("wrap"),
                tl: {
                  hd: Css.paddingRight(Css.px(6)),
                  tl: {
                    hd: Css.lastChild({
                          hd: Css.justifyContent("flexEnd"),
                          tl: {
                            hd: Css.paddingRight("zero"),
                            tl: /* [] */0
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var Style = {
  sidePadding: sidePadding,
  root: root,
  header: header,
  headerItemContainer: headerItemContainer,
  headerItem: headerItem,
  row: row,
  rowItem: rowItem
};

function Make(Config) {
  var getColumnName = function (column) {
    return column.VAL[0].name;
  };
  var getColumnId = function (column) {
    return column.VAL[0].id;
  };
  var getColumnWidth = function (column) {
    return column.VAL[0].width;
  };
  var getColumnCompare = function (column) {
    return column.VAL[0].compare;
  };
  var getColumnDefaultOrder = function (column) {
    return column.VAL[0].defaultOrder;
  };
  var getColumnAlignment = function (column) {
    return column.VAL[0].alignment;
  };
  var getColumnCompareFromId = function (columns, id) {
    return Belt_Option.flatMap(Belt_Array.getBy(columns, (function (column) {
                      return column.VAL[0].id === id;
                    })), getColumnCompare);
  };
  var getDefaultOrder = function (param) {
    return Config.defaultOrder;
  };
  var isOrderedBy = function (orderBy, column) {
    if (orderBy !== undefined) {
      return column.VAL[0].id === orderBy[0];
    } else {
      return false;
    }
  };
  var getStyles = function (styles, columnWidth, columnGap, alignment) {
    return Curry._1(Css.merge, {
                hd: styles,
                tl: {
                  hd: Curry._1(Css.style, {
                        hd: columnWidth,
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Curry._1(Css.style, {
                          hd: Css.paddingRight(Css.px(columnGap)),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Curry._1(Css.style, {
                            hd: Css.justifyContent(alignment === "right" ? "flexEnd" : (
                                    alignment === "center" ? "center" : "flexStart"
                                  )),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              });
  };
  var addCentered = function (styles) {
    return Curry._1(Css.merge, {
                hd: styles,
                tl: {
                  hd: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("row"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.gap(Css.px(4)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              });
  };
  var addHoverable = function (styles, hoverable) {
    return Curry._1(Css.merge, {
                hd: styles,
                tl: {
                  hd: hoverable ? Curry._1(Css.style, {
                          hd: Css.cursor("pointer"),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: Styles.Duration.$$short
                                }, undefined, undefined, "color"),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.color(Styles.Color.light11),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }) : "",
                  tl: /* [] */0
                }
              });
  };
  var context = React.createContext({
        columns: [],
        columnGap: 0,
        fixedWidth: "",
        headerItemContainerStyles: "",
        headerItemStyles: (function (param, param$1) {
            return "";
          }),
        height: 50,
        maxHeight: "zero",
        onRowClick: (function (param) {
            
          }),
        rowItemStyles: "",
        rowSize: 50,
        rowStyles: (function (param) {
            return "";
          }),
        scrollLock: false
      });
  var provider = context.Provider;
  var SimpleTable$Make$TableContext = function (Props) {
    var value = Props.value;
    var children = Props.children;
    var colsSettings = React.useMemo((function () {
            return Belt_Array.reduce(value.columns, {
                        fixedTotal: /* [] */0,
                        colsUnfixed: 0
                      }, (function (totals, column) {
                          var match = column.VAL[0].width;
                          if (typeof match === "object") {
                            return {
                                    fixedTotal: {
                                      hd: Css_AtomicTypes.Length.toString(match.VAL),
                                      tl: totals.fixedTotal
                                    },
                                    colsUnfixed: totals.colsUnfixed
                                  };
                          } else {
                            return {
                                    fixedTotal: totals.fixedTotal,
                                    colsUnfixed: totals.colsUnfixed + 1 | 0
                                  };
                          }
                        }));
          }), [value.columns]);
    var fixedWidth = React.useMemo((function () {
            return "calc((100% - " + Belt_List.toArray(colsSettings.fixedTotal).join(" - ") + ") / " + String(colsSettings.colsUnfixed) + ")";
          }), [colsSettings]);
    return React.createElement(provider, {
                value: {
                  columns: value.columns,
                  columnGap: value.columnGap,
                  fixedWidth: fixedWidth,
                  headerItemContainerStyles: value.headerItemContainerStyles,
                  headerItemStyles: value.headerItemStyles,
                  height: value.height,
                  maxHeight: value.maxHeight,
                  onRowClick: value.onRowClick,
                  rowItemStyles: value.rowItemStyles,
                  rowSize: value.rowSize,
                  rowStyles: value.rowStyles,
                  scrollLock: value.scrollLock
                },
                children: children
              });
  };
  var use = function (param) {
    return React.useContext(context);
  };
  var TableContext = {
    context: context,
    provider: provider,
    make: SimpleTable$Make$TableContext,
    use: use
  };
  var context$1 = React.createContext(undefined);
  var provider$1 = context$1.Provider;
  var use$1 = function (param) {
    return React.useContext(context$1);
  };
  var SimpleTable$Make$ActiveRowIdContext = function (Props) {
    var activeRowId = Props.activeRowId;
    var children = Props.children;
    return React.createElement(provider$1, {
                value: activeRowId,
                children: children
              });
  };
  var ActiveRowIdContext = {
    context: context$1,
    provider: provider$1,
    use: use$1,
    make: SimpleTable$Make$ActiveRowIdContext
  };
  var SimpleTable$Make$VirtualizedTable$ReactWindowRow = function (Props) {
    var index = Props.index;
    var style = Props.style;
    var data = Props.data;
    var item = Belt_Array.getExn(data, index);
    var match = React.useContext(context);
    var rowSize = match.rowSize;
    var rowItemStyles = match.rowItemStyles;
    var onRowClick = match.onRowClick;
    var fixedWidth = match.fixedWidth;
    var columnGap = match.columnGap;
    var activeRowId = React.useContext(context$1);
    var rowIsActive = Caml_obj.caml_equal(activeRowId, Curry._1(Config.getRowId, item));
    return React.createElement("div", {
                className: Curry._1(Css.merge, {
                      hd: Curry._1(match.rowStyles, rowIsActive),
                      tl: {
                        hd: Curry._1(Css.style, {
                              hd: Css.minHeight(Css.px(rowSize)),
                              tl: {
                                hd: Css.maxHeight(Css.px(rowSize)),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      }
                    }),
                role: "button",
                style: {
                  height: style.height,
                  left: style.left,
                  position: style.position,
                  top: String(style.top) + "px",
                  width: style.width
                },
                onClick: (function (param) {
                    if (Caml_obj.caml_notequal(activeRowId, Curry._1(Config.getRowId, item))) {
                      return Curry._1(onRowClick, Curry._1(Config.getRowId, item));
                    }
                    
                  })
              }, Belt_Array.map(match.columns, (function (column) {
                      var key = column.VAL[0].id + Curry._1(Config.getRowId, item);
                      var match = column.VAL[0].width;
                      var variant = column.NAME;
                      var tmp;
                      if (variant === "component") {
                        tmp = Curry._1(column.VAL[1], item);
                      } else if (variant === "string") {
                        var getValue = column.VAL[1];
                        var match$1 = column.VAL[0].alignment;
                        tmp = React.createElement($$Text.make, {
                              size: "Small",
                              textAlign: match$1 === "right" ? "right" : (
                                  match$1 === "center" ? "center" : "left"
                                ),
                              singleLine: true,
                              color: Styles.Color.light11,
                              title: Curry._1(getValue, item),
                              children: Curry._1(getValue, item)
                            });
                      } else {
                        var getValue$1 = column.VAL[1];
                        var match$2 = column.VAL[0].alignment;
                        tmp = React.createElement($$Text.make, {
                              size: "Small",
                              textAlign: match$2 === "right" ? "right" : (
                                  match$2 === "center" ? "center" : "left"
                                ),
                              singleLine: true,
                              color: Styles.Color.light11,
                              title: Curry._1(getValue$1, item),
                              disambiguate: true,
                              children: Curry._1(getValue$1, item)
                            });
                      }
                      return React.createElement("div", {
                                  key: key,
                                  className: getStyles(rowItemStyles, typeof match === "object" ? Css.maxWidth(match.VAL) : Css.unsafe("width", fixedWidth), columnGap, column.VAL[0].alignment)
                                }, tmp);
                    })));
  };
  var ReactWindowRow = {
    make: SimpleTable$Make$VirtualizedTable$ReactWindowRow
  };
  var context$2 = React.createContext(false);
  var provider$2 = context$2.Provider;
  var use$2 = function (param) {
    return React.useContext(context$2);
  };
  var SimpleTable$Make$VirtualizedTable$BottomMarginContext = function (Props) {
    var show = Props.show;
    var children = Props.children;
    return React.createElement(provider$2, {
                value: show,
                children: children
              });
  };
  var BottomMarginContext = {
    context: context$2,
    provider: provider$2,
    use: use$2,
    make: SimpleTable$Make$VirtualizedTable$BottomMarginContext
  };
  var make = React.forwardRef(function (Props, ref_) {
        var style = Props.style;
        var className = Props.className;
        var onScroll = Props.onScroll;
        var children = Props.children;
        var match = React.useContext(context);
        var showBottomMargin = React.useContext(context$2) && !match.scrollLock;
        var tmp = {
          style: style,
          onScroll: onScroll
        };
        var tmp$1 = Belt_Option.map((ref_ == null) ? undefined : Caml_option.some(ref_), (function (prim) {
                return prim;
              }));
        if (tmp$1 !== undefined) {
          tmp.ref = Caml_option.valFromOption(tmp$1);
        }
        if (className !== undefined) {
          tmp.className = Caml_option.valFromOption(className);
        }
        return React.createElement(React.Fragment, undefined, React.createElement("div", tmp, children), showBottomMargin ? React.createElement(Spacer.make, {
                          height: 80
                        }) : null);
      });
  var Container = {
    make: make
  };
  var SimpleTable$Make$VirtualizedTable = function (Props) {
    var data = Props.data;
    var reactWindowOuterRef = Props.reactWindowOuterRef;
    var reactWindowRef = Props.reactWindowRef;
    var match = React.useContext(context);
    var rowSize = match.rowSize;
    var tableHeight = match.height;
    var tmp = {
      className: Curry._1(Css.style, {
            hd: Css.display("flex"),
            tl: {
              hd: Css.height(Css.px(tableHeight)),
              tl: {
                hd: Css.maxHeight(match.maxHeight),
                tl: /* [] */0
              }
            }
          })
    };
    var tmp$1 = data.length === 0 ? undefined : Config.tableName + "-not-empty";
    if (tmp$1 !== undefined) {
      tmp.id = Caml_option.valFromOption(tmp$1);
    }
    return React.createElement("div", tmp, React.createElement(ReactVirtualizedAutoSizer, {
                    children: (function (size) {
                        return React.createElement(SimpleTable$Make$VirtualizedTable$BottomMarginContext, {
                                    show: tableHeight > size.height,
                                    children: React.createElement(ReactWindow.VariableSizeList, {
                                          estimatedItemSize: rowSize,
                                          height: size.height,
                                          innerElementType: make,
                                          itemCount: data.length,
                                          itemData: data,
                                          itemKey: (function (index) {
                                              return Curry._1(Config.getRowId, Belt_Array.getExn(data, index));
                                            }),
                                          itemSize: (function (param) {
                                              return rowSize;
                                            }),
                                          outerRef: reactWindowOuterRef,
                                          overscanCount: 30,
                                          ref: reactWindowRef,
                                          width: size.width,
                                          children: SimpleTable$Make$VirtualizedTable$ReactWindowRow
                                        })
                                  });
                      })
                  }));
  };
  var VirtualizedTable = {
    ReactWindowRow: ReactWindowRow,
    BottomMarginContext: BottomMarginContext,
    Container: Container,
    make: SimpleTable$Make$VirtualizedTable
  };
  var SimpleTable$Make$ColumnHeader = function (Props) {
    var column = Props.column;
    var orderBy = Props.orderBy;
    var setOrderBy = Props.setOrderBy;
    var match = React.useContext(context);
    var match$1 = column.VAL[0].width;
    var tmp = {
      key: column.VAL[0].id,
      className: addHoverable(addCentered(getStyles(match.headerItemContainerStyles, typeof match$1 === "object" ? Css.maxWidth(match$1.VAL) : Css.unsafe("width", match.fixedWidth), match.columnGap, column.VAL[0].alignment)), column.VAL[0].compare !== undefined),
      onClick: (function (param) {
          var match = column.VAL[0].compare;
          if (match === undefined) {
            return ;
          }
          if (orderBy !== undefined) {
            var direction = orderBy[1];
            if (orderBy[0] === column.VAL[0].id) {
              return Curry._1(setOrderBy, (function (param) {
                            return [
                                    column.VAL[0].id,
                                    direction === "asc" ? "desc" : "asc"
                                  ];
                          }));
            }
            
          }
          return Curry._1(setOrderBy, (function (param) {
                        return [
                                column.VAL[0].id,
                                column.VAL[0].defaultOrder
                              ];
                      }));
        })
    };
    var tmp$1 = column.VAL[0].compare === undefined ? undefined : "button";
    if (tmp$1 !== undefined) {
      tmp.role = Caml_option.valFromOption(tmp$1);
    }
    var match$2 = column.VAL[0].alignment;
    var tmp$2;
    var exit = 0;
    if (orderBy !== undefined) {
      var direction = orderBy[1];
      var id = orderBy[0];
      if (match$2 === "right") {
        if (id === column.VAL[0].id) {
          tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(Icon.make, {
                    type_: direction === "asc" ? "arrowTop" : "arrowDown",
                    size: {
                      NAME: "int",
                      VAL: 12
                    },
                    color: Styles.Color.light10
                  }), React.createElement($$Text.make, {
                    size: "Tiny",
                    weight: "Semi",
                    children: column.VAL[0].name
                  }));
        } else {
          exit = 1;
        }
      } else if (id === column.VAL[0].id) {
        tmp$2 = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  size: "Tiny",
                  weight: "Semi",
                  children: column.VAL[0].name
                }), React.createElement(Icon.make, {
                  type_: direction === "asc" ? "arrowTop" : "arrowDown",
                  size: {
                    NAME: "int",
                    VAL: 12
                  },
                  color: Styles.Color.light10
                }));
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp$2 = React.createElement($$Text.make, {
            size: "Tiny",
            weight: "Semi",
            children: column.VAL[0].name
          });
    }
    return React.createElement("div", tmp, React.createElement("div", {
                    className: Curry._2(match.headerItemStyles, column.VAL[0].compare !== undefined, isOrderedBy(orderBy, column))
                  }, tmp$2));
  };
  var ColumnHeader = {
    make: SimpleTable$Make$ColumnHeader
  };
  var debounceFunction = LodashDebounce((function (fn) {
          return Curry._1(fn, undefined);
        }), 150, {
        leading: false,
        trailing: true
      });
  var SimpleTable$Make = function (Props) {
    var activeRowId = Props.activeRowId;
    var columns = Props.columns;
    var columnGapOpt = Props.columnGap;
    var headerStylesOpt = Props.headerStyles;
    var headerItemContainerStylesOpt = Props.headerItemContainerStyles;
    var headerItemStylesOpt = Props.headerItemStyles;
    var id = Props.id;
    var maxHeight = Props.maxHeight;
    var minWidth = Props.minWidth;
    var onRowClick = Props.onRowClick;
    var rowSize = Props.rowSize;
    var rowStylesOpt = Props.rowStyles;
    var rowItemStylesOpt = Props.rowItemStyles;
    var data = Props.data;
    var controlledOrderBy = Props.orderBy;
    var onOrderByChangeOpt = Props.onOrderByChange;
    var emptyState = Props.emptyState;
    var scrollLockOpt = Props.scrollLock;
    var showScrollToTopButtonOpt = Props.showScrollToTopButton;
    var columnGap = columnGapOpt !== undefined ? columnGapOpt : 4;
    var headerStyles = headerStylesOpt !== undefined ? headerStylesOpt : header;
    var headerItemContainerStyles = headerItemContainerStylesOpt !== undefined ? headerItemContainerStylesOpt : headerItemContainer;
    var headerItemStyles = headerItemStylesOpt !== undefined ? headerItemStylesOpt : headerItem;
    var rowStyles = rowStylesOpt !== undefined ? rowStylesOpt : row;
    var rowItemStyles = rowItemStylesOpt !== undefined ? rowItemStylesOpt : rowItem;
    var onOrderByChange = onOrderByChangeOpt !== undefined ? onOrderByChangeOpt : (function (param) {
          
        });
    var scrollLock = scrollLockOpt !== undefined ? scrollLockOpt : false;
    var showScrollToTopButton = showScrollToTopButtonOpt !== undefined ? showScrollToTopButtonOpt : true;
    var match = React.useState(function () {
          return Config.defaultOrder;
        });
    var setOrderBy = match[1];
    var orderBy = match[0];
    var previousOrderBy = Hooks.usePrevious1(orderBy);
    var simpleTableRef = React.useRef(null);
    var reactWindowOuterRef = React.useRef(null);
    var reactWindowRef = React.useRef(null);
    React.useEffect((function () {
            if (Caml_obj.caml_notequal(previousOrderBy, orderBy)) {
              if (orderBy !== undefined && !Caml_obj.caml_equal(orderBy, Config.defaultOrder)) {
                Curry._1(onOrderByChange, orderBy);
              } else {
                Curry._1(onOrderByChange, undefined);
              }
            }
            
          }), [orderBy]);
    React.useEffect((function () {
            if (Caml_obj.caml_notequal(Caml_option.some(orderBy), controlledOrderBy)) {
              var exit = 0;
              if (controlledOrderBy !== undefined) {
                var newOrderBy = Caml_option.valFromOption(controlledOrderBy);
                if (newOrderBy !== undefined) {
                  Curry._1(setOrderBy, (function (param) {
                          return newOrderBy;
                        }));
                } else {
                  exit = 1;
                }
              } else {
                exit = 1;
              }
              if (exit === 1) {
                Curry._1(setOrderBy, (function (param) {
                        return Config.defaultOrder;
                      }));
              }
              
            }
            
          }), [controlledOrderBy]);
    var orderedData;
    if (orderBy !== undefined) {
      var compare = getColumnCompareFromId(columns, orderBy[0]);
      if (compare !== undefined) {
        var compare$1 = orderBy[1] === "asc" ? Curry.__2(compare) : (function (a, b) {
              return Curry._2(compare, b, a);
            });
        orderedData = Belt_SortArray.stableSortBy(data, compare$1);
      } else {
        orderedData = data;
      }
    } else {
      orderedData = data;
    }
    var match$1 = React.useState(function () {
          return false;
        });
    var setAffixMounted = match$1[1];
    React.useEffect((function () {
            var match = reactWindowOuterRef.current;
            var exit = 0;
            if (showScrollToTopButton && !(match == null)) {
              Curry._1(setAffixMounted, (function (param) {
                      return match.scrollTop > 200;
                    }));
            } else {
              exit = 1;
            }
            if (exit === 1) {
              Curry._1(setAffixMounted, (function (param) {
                      return false;
                    }));
            }
            var onScroll = function (param) {
              return Belt_Option.forEach(Caml_option.nullable_to_opt(reactWindowOuterRef.current), (function (el) {
                            return Curry._1(setAffixMounted, (function (param) {
                                          if (el.scrollTop > 200) {
                                            return showScrollToTopButton;
                                          } else {
                                            return false;
                                          }
                                        }));
                          }));
            };
            var debouncedOnScroll = function (param) {
              return debounceFunction(onScroll);
            };
            var el = reactWindowOuterRef.current;
            if (!(el == null)) {
              el.addEventListener("scroll", debouncedOnScroll);
            }
            return (function (param) {
                      return Belt_Option.forEach(Caml_option.nullable_to_opt(reactWindowOuterRef.current), (function (el) {
                                    el.removeEventListener("scroll", debouncedOnScroll);
                                    
                                  }));
                    });
          }), [
          orderedData,
          showScrollToTopButton
        ]);
    var tmp = {
      ref: simpleTableRef,
      className: Curry._1(Css.style, {
            hd: Css.overflowX("scroll"),
            tl: /* [] */0
          })
    };
    if (id !== undefined) {
      tmp.id = Caml_option.valFromOption(id);
    }
    return React.createElement(SimpleTable$Make$TableContext, {
                value: {
                  columns: columns,
                  columnGap: columnGap,
                  headerItemContainerStyles: headerItemContainerStyles,
                  headerItemStyles: headerItemStyles,
                  height: Math.imul(rowSize, orderedData.length),
                  maxHeight: maxHeight,
                  onRowClick: onRowClick,
                  rowItemStyles: rowItemStyles,
                  rowSize: rowSize,
                  rowStyles: rowStyles,
                  scrollLock: scrollLock
                },
                children: React.createElement(SimpleTable$Make$ActiveRowIdContext, {
                      activeRowId: activeRowId,
                      children: React.createElement("div", tmp, React.createElement("div", {
                                className: root(minWidth)
                              }, React.createElement("header", {
                                    className: headerStyles,
                                    id: Config.tableName + "-header"
                                  }, Belt_Array.map(columns, (function (column) {
                                          return React.createElement(SimpleTable$Make$ColumnHeader, {
                                                      column: column,
                                                      orderBy: orderBy,
                                                      setOrderBy: setOrderBy,
                                                      key: column.VAL[0].id
                                                    });
                                        }))), Caml_obj.caml_equal(orderedData, []) && emptyState !== undefined && orderedData.length === 0 ? React.createElement("div", {
                                      className: Curry._1(rowStyles, false) + Curry._1(Css.style, {
                                            hd: Css.height("unset"),
                                            tl: /* [] */0
                                          })
                                    }, Caml_option.valFromOption(emptyState)) : null, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.position("relative"),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(SimpleTable$Make$VirtualizedTable, {
                                        data: orderedData,
                                        reactWindowOuterRef: reactWindowOuterRef,
                                        reactWindowRef: reactWindowRef
                                      }), React.createElement(Mantine.Transition.make, {
                                        transition: "slide-up",
                                        mounted: match$1[0],
                                        children: (function (style) {
                                            return React.createElement("div", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.position("absolute"),
                                                              tl: {
                                                                hd: Css.bottom(Css.px(32)),
                                                                tl: {
                                                                  hd: Css.width(Css.pct(100.0)),
                                                                  tl: {
                                                                    hd: Css.display("flex"),
                                                                    tl: {
                                                                      hd: Css.justifyContent("center"),
                                                                      tl: {
                                                                        hd: Css.filter({
                                                                              hd: {
                                                                                NAME: "dropShadow",
                                                                                VAL: [
                                                                                  Css.px(3),
                                                                                  Css.px(3),
                                                                                  Css.px(3),
                                                                                  Styles.Color.light07
                                                                                ]
                                                                              },
                                                                              tl: /* [] */0
                                                                            }),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }),
                                                        style: style
                                                      }, React.createElement(Button.make, {
                                                            icon: "arrowTop",
                                                            label: "Scroll to top",
                                                            onClick: (function (param) {
                                                                return Belt_Option.forEach(Caml_option.nullable_to_opt(reactWindowRef.current), (function (el) {
                                                                              Curry._1(setAffixMounted, (function (param) {
                                                                                      return false;
                                                                                    }));
                                                                              el.scrollToItem(0, "start");
                                                                              
                                                                            }));
                                                              })
                                                          }));
                                          })
                                      }))))
                    })
              });
  };
  return {
          getColumnName: getColumnName,
          getColumnId: getColumnId,
          getColumnWidth: getColumnWidth,
          getColumnCompare: getColumnCompare,
          getColumnDefaultOrder: getColumnDefaultOrder,
          getColumnAlignment: getColumnAlignment,
          getColumnCompareFromId: getColumnCompareFromId,
          getDefaultOrder: getDefaultOrder,
          isOrderedBy: isOrderedBy,
          getStyles: getStyles,
          addCentered: addCentered,
          addHoverable: addHoverable,
          TableContext: TableContext,
          ActiveRowIdContext: ActiveRowIdContext,
          VirtualizedTable: VirtualizedTable,
          ColumnHeader: ColumnHeader,
          debounceFunction: debounceFunction,
          make: SimpleTable$Make
        };
}

export {
  Style ,
  Make ,
  
}
/* sidePadding Not a pure module */
