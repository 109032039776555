// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "../Icons.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Timestamp from "../Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as GlobalPill from "../GlobalPill.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProfilePhoto from "../ProfilePhoto.mjs";
import * as App from "firebase/app";
import * as Collaborators from "../Collaborators.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as BranchStatusPill from "../BranchStatusPill.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import * as GlobalRequirementsUtils from "../../../model/src/GlobalRequirementsUtils.mjs";
import * as BranchOverviewRow__Extras from "./BranchOverviewRow__Extras.mjs";
import * as BranchOverviewRow__Styles from "./BranchOverviewRow__Styles.mjs";

function BranchOverviewRow__Unmerged(Props) {
  var branchId = Props.branchId;
  var branchState = Props.branchState;
  var model = Props.model;
  var name = Props.name;
  var schemaBundle = Props.schemaBundle;
  var selected = Props.selected;
  var userId = Props.userId;
  var workspace = Props.workspace;
  var schemaId = workspace.id;
  var creationDate = App.default.firestore.Timestamp.fromDate(branchState.creationDate);
  var creatorUserId = branchState.creatorUserId;
  var branchStatus = branchState.branchStatus;
  var approvalsCount = Belt_Option.getWithDefault(branchState.approvalsCount, 0);
  var collaboratorIds = Collaborators.useCollaborators(branchId);
  var collaboratorIds$1 = branchState.isShallow ? collaboratorIds : Belt_Option.mapWithDefault(branchState.collaboratorIds, /* [] */0, Belt_List.fromArray);
  var lastAction = FirebaseFetcherHooks.useActionLastBranchAction("SecurityAuditLog", schemaId, branchId);
  var match;
  if (branchState.isShallow) {
    var lastActionTimestamp;
    if (typeof lastAction === "object" && lastAction.NAME === "Loaded") {
      var lastAction$1 = lastAction.VAL;
      lastActionTimestamp = lastAction$1 !== undefined ? ({
            NAME: "Loaded",
            VAL: Caml_option.valFromOption(lastAction$1).createdAt
          }) : "Initial";
    } else {
      lastActionTimestamp = "Initial";
    }
    var lastActiveUserId;
    if (typeof lastAction === "object" && lastAction.NAME === "Loaded") {
      var lastAction$2 = lastAction.VAL;
      lastActiveUserId = lastAction$2 !== undefined ? ({
            NAME: "Loaded",
            VAL: Caml_option.valFromOption(lastAction$2).createdBy
          }) : "Initial";
    } else {
      lastActiveUserId = "Initial";
    }
    match = [
      lastActionTimestamp,
      lastActiveUserId
    ];
  } else {
    var lastActivityDate = branchState.lastActivityDate;
    var lastActionTimestamp$1 = lastActivityDate !== undefined ? ({
          NAME: "Loaded",
          VAL: App.default.firestore.Timestamp.fromDate(Caml_option.valFromOption(lastActivityDate))
        }) : "Initial";
    var lastActiveUserId$1 = branchState.lastActiveUserId;
    var lastActiveUserId$2 = lastActiveUserId$1 !== undefined ? ({
          NAME: "Loaded",
          VAL: lastActiveUserId$1
        }) : "Initial";
    match = [
      lastActionTimestamp$1,
      lastActiveUserId$2
    ];
  }
  var lastActiveUserId$3 = match[1];
  var lastActionTimestamp$2 = match[0];
  var excessCount = Belt_List.length(collaboratorIds$1) - 5 | 0;
  var match$1 = excessCount <= 0 ? [
      collaboratorIds$1,
      0
    ] : [
      Belt_Option.getWithDefault(Belt_List.take(collaboratorIds$1, 4), collaboratorIds$1),
      excessCount + 1 | 0
    ];
  var notDisplayedCount = match$1[1];
  var handleClick = function ($$event) {
    AnalyticsRe.branchLinkClicked(schemaBundle, branchId, "BranchesOverviewRow");
    var uri = Curry._4(Router.Link.get, {
          NAME: "schema",
          VAL: schemaId
        }, {
          NAME: "branch",
          VAL: branchId
        }, {
          NAME: "diff",
          VAL: "index"
        }, /* [] */0);
    if ($$event.metaKey) {
      window.open(uri, "blank_");
      return ;
    } else {
      return Router.push(undefined, uri);
    }
  };
  var demoId = branchId === "demo-" + userId ? "new-workspace-demo-start" : undefined;
  var sourceNames = Belt_Option.map(branchState.impactedSourceIds, (function (sourceIds) {
          return Belt_Array.mapU(sourceIds, (function (sourceId) {
                          return Belt_Option.getWithDefault(Belt_Option.flatMapU(ModelUtils.getSourceByIdWithArchive(sourceId, model), (function (source) {
                                            return source.name;
                                          })), "New Source");
                        })).join(", ");
        }));
  var sourceNamesCount = Belt_Option.flatMap(branchState.impactedSourceIds, (function (sourceIds) {
          if (sourceIds.length !== 0) {
            return sourceIds.length;
          }
          
        }));
  var isGlobalRequirementsBranch = GlobalRequirementsUtils.isGlobalBranch(branchId);
  var branchName = isGlobalRequirementsBranch ? GlobalRequirementsUtils.getNormalizedName(branchId) : name;
  var tmp = {
    className: BranchOverviewRow__Styles.rootStyle(selected),
    onClick: handleClick
  };
  if (demoId !== undefined) {
    tmp.id = Caml_option.valFromOption(demoId);
  }
  var tmp$1 = {
    className: BranchOverviewRow__Styles.createdCellStyle
  };
  if (sourceNames !== undefined) {
    tmp$1.title = Caml_option.valFromOption(sourceNames);
  }
  var commentsCount = branchState.commentsCount;
  var lastCodegenBySource = branchState.lastCodegenBySource;
  var tmp$2;
  if (branchState.isShallow) {
    tmp$2 = React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {});
  } else if (lastCodegenBySource !== undefined) {
    var lastCodegenBySource$1 = Caml_option.valFromOption(lastCodegenBySource);
    tmp$2 = Belt_MapString.size(lastCodegenBySource$1) === 0 ? React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {}) : React.createElement(BranchOverviewRow__Extras.LastCodegens.make, {
            lastCodegenBySource: lastCodegenBySource$1
          });
  } else {
    tmp$2 = React.createElement($$Text.make, {
          element: "Em",
          size: "Small",
          color: Styles.Color.light10,
          children: "Never"
        });
  }
  var lastPublishedAt = branchState.lastPublishedAt;
  return React.createElement("tr", tmp, React.createElement("td", {
                  className: BranchOverviewRow__Styles.nameCellStyle
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(4)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          lines: 1,
                          color: Styles.Color.light11,
                          children: branchName
                        }), isGlobalRequirementsBranch ? React.createElement(GlobalPill.make, {}) : null)), React.createElement("td", {
                  className: BranchOverviewRow__Styles.statusCellStyle
                }, React.createElement(BranchStatusPill.make, {
                      status: branchStatus,
                      approvalsCount: approvalsCount,
                      workspace: workspace
                    })), React.createElement("td", {
                  className: Curry._1(Css.merge, {
                        hd: BranchOverviewRow__Styles.createdCellStyle,
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.whiteSpace("nowrap"),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light11,
                      children: React.createElement(Timestamp.make, {
                            date: creationDate
                          })
                    })), React.createElement("td", {
                  className: BranchOverviewRow__Styles.creatorCellStyle
                }, React.createElement(BranchOverviewRow__Extras.Creator.make, {
                      id: creatorUserId
                    })), React.createElement("td", undefined, React.createElement("div", {
                      className: BranchOverviewRow__Styles.collaboratorsCellStyle
                    }, Belt_List.toArray(Belt_List.map(match$1[0], (function (id) {
                                return React.createElement("div", {
                                            key: id,
                                            className: BranchOverviewRow__Styles.collaboratorStyle
                                          }, React.createElement(ProfilePhoto.FromUserId.make, {
                                                id: id,
                                                size: 24
                                              }));
                              }))), notDisplayedCount !== 0 ? React.createElement("div", {
                            className: BranchOverviewRow__Styles.collaboratorStyle
                          }, React.createElement(ProfilePhoto.Base.make, {
                                email: "+" + String(notDisplayedCount),
                                size: 24
                              })) : null)), React.createElement("td", {
                  className: Curry._1(Css.merge, {
                        hd: BranchOverviewRow__Styles.createdCellStyle,
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.whiteSpace("nowrap"),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      })
                }, typeof lastActionTimestamp$2 === "object" && lastActionTimestamp$2.NAME === "Loaded" ? React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light11,
                        children: React.createElement(Timestamp.make, {
                              date: lastActionTimestamp$2.VAL
                            })
                      }) : null), React.createElement("td", {
                  className: BranchOverviewRow__Styles.creatorCellStyle
                }, typeof lastActiveUserId$3 === "object" ? React.createElement(BranchOverviewRow__Extras.Creator.make, {
                        id: lastActiveUserId$3.VAL
                      }) : null), React.createElement("td", tmp$1, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("row"),
                              tl: {
                                hd: Css.flexWrap("wrap"),
                                tl: {
                                  hd: Css.gap(Css.px(2)),
                                  tl: {
                                    hd: Css.maxHeight(Css.pct(100.0)),
                                    tl: {
                                      hd: Css.overflow("hidden"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, branchState.isShallow ? React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {}) : (
                        sourceNames !== undefined ? React.createElement($$Text.make, {
                                size: "Small",
                                lines: 2,
                                color: Styles.Color.light11,
                                children: null
                              }, Belt_Option.mapWithDefault(sourceNamesCount, React.createElement($$Text.make, {
                                        element: "Em",
                                        size: "Small",
                                        color: Styles.Color.light10,
                                        children: "None"
                                      }), (function (sourceNamesCount) {
                                      return React.createElement($$Text.make, {
                                                  element: "Span",
                                                  weight: "Semi",
                                                  children: String(sourceNamesCount) + ": "
                                                });
                                    })), sourceNames) : null
                      ))), React.createElement("td", {
                  className: BranchOverviewRow__Styles.createdCellStyle
                }, branchState.isShallow ? React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {}) : (
                    commentsCount !== undefined ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("row"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(Icons.$$Comment.make, {}), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.light11,
                                children: String(commentsCount)
                              })) : React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {})
                  )), React.createElement("td", {
                  className: BranchOverviewRow__Styles.createdCellStyle
                }, tmp$2), React.createElement("td", {
                  className: BranchOverviewRow__Styles.createdCellStyle
                }, branchState.isShallow ? React.createElement(BranchOverviewRow__Extras.InformationMissing.make, {}) : (
                    lastPublishedAt !== undefined ? React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light11,
                            children: React.createElement(Timestamp.make, {
                                  date: App.default.firestore.Timestamp.fromDate(Caml_option.valFromOption(lastPublishedAt))
                                })
                          }) : React.createElement($$Text.make, {
                            element: "Em",
                            size: "Small",
                            color: Styles.Color.light10,
                            children: "Never"
                          })
                  )));
}

var maxCollaboratorSlots = 5;

var make = BranchOverviewRow__Unmerged;

export {
  maxCollaboratorSlots ,
  make ,
  
}
/* Css Not a pure module */
