// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function encode(t) {
  return Json_encode.object_({
              hd: [
                "ok",
                t.ok
              ],
              tl: {
                hd: [
                  "currentEditorSeats",
                  t.currentEditorSeats
                ],
                tl: {
                  hd: [
                    "planEditorSeats",
                    t.planEditorSeats
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode(json) {
  return {
          ok: Json_decode.field("ok", Json_decode.bool, json),
          currentEditorSeats: Json_decode.field("currentEditorSeats", Json_decode.$$int, json),
          planEditorSeats: Json_decode.field("planEditorSeats", Json_decode.$$int, json)
        };
}

export {
  encode ,
  decode ,
  
}
/* No side effect */
