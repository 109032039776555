// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as ImplementCLI from "./ImplementCLI.mjs";
import * as ImplementFile from "./ImplementFile.mjs";
import * as ImplementInit from "./ImplementInit.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as AvoConfigTypes from "../../shared/models/AvoConfigTypes.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as MembersInviteForm from "./MembersInviteForm.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as SourceConfigOverview from "./SourceConfigOverview.mjs";
import * as ImplementationStatusContext from "./ImplementationStatusContext.mjs";
import * as SourceConfigCodegenDestinations from "./SourceConfigCodegenDestinations.mjs";

var root = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(40)),
      tl: {
        hd: Css.paddingRight(Css.px(40)),
        tl: {
          hd: Css.paddingBottom(Css.px(40)),
          tl: {
            hd: Css.paddingLeft(Css.px(60)),
            tl: /* [] */0
          }
        }
      }
    });

var link = Curry._1(Css.style, {
      hd: Css.fontWeight("semiBold"),
      tl: {
        hd: Css.color(Styles.Color.primaryPurpleShade1),
        tl: {
          hd: Css.textDecoration("none"),
          tl: {
            hd: Css.hover({
                  hd: Css.textDecoration("underline"),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var inviteBox = Curry._1(Css.style, {
      hd: Css.borderRadius(Styles.Border.radius),
      tl: {
        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
        tl: {
          hd: Css.padding2(Css.px(12), Css.px(12)),
          tl: {
            hd: Css.marginLeft(Css.px(-13)),
            tl: {
              hd: Css.marginBottom(Css.px(24)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var statusLoading = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.height(Css.px(26)),
            tl: {
              hd: Css.width(Css.px(190)),
              tl: {
                hd: Css.borderRadius(Css.px(99)),
                tl: {
                  hd: Css.marginLeft(Css.px(-14)),
                  tl: /* [] */0
                }
              }
            }
          }),
      tl: {
        hd: Curry._1(Css.style, Styles.shimmer),
        tl: /* [] */0
      }
    });

var initializedPill = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("baseline"),
          tl: {
            hd: Css.width("fitContent"),
            tl: {
              hd: Css.background(Styles.Color.paleGreen),
              tl: {
                hd: Css.borderRadius(Css.px(99)),
                tl: {
                  hd: Css.paddingTop(Css.px(4)),
                  tl: {
                    hd: Css.paddingRight(Css.px(12)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(5)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(8)),
                        tl: {
                          hd: Css.marginLeft(Css.px(-14)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var waitingPill = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.width("fitContent"),
            tl: {
              hd: Css.background(Styles.Color.light04),
              tl: {
                hd: Css.borderRadius(Css.px(99)),
                tl: {
                  hd: Css.paddingTop(Css.px(4)),
                  tl: {
                    hd: Css.paddingRight(Css.px(12)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(5)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(6)),
                        tl: {
                          hd: Css.marginLeft(Css.px(-14)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Style = {
  root: root,
  link: link,
  inviteBox: inviteBox,
  statusLoading: statusLoading,
  initializedPill: initializedPill,
  waitingPill: waitingPill
};

function SourceConfigCodegenSetup(Props) {
  var currentBranch = Props.currentBranch;
  var handleTabChange = Props.handleTabChange;
  var model = Props.model;
  var sendActions = Props.sendActions;
  var source = Props.source;
  var viewerRole = Props.viewerRole;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var maybeValidSource = AvoConfig.validateSource(source, model);
  var hasIncludeLegacyJsAndReFeatureFlag = AppFeatureFlag.useFeatureFlag("IncludeLegacyJsAndRe");
  var workspace = WorkspaceContext.use(undefined);
  var member = WorkspaceContext.useMember(undefined);
  var match = React.useState(function () {
        
      });
  var setCodegenAccessError = match[1];
  var codegenAccessError = match[0];
  React.useEffect((function () {
          if (member !== undefined) {
            var codegenAvailability = AvoLimits.CodegenAvailability.canCodegen(workspace, Caml_option.valFromOption(member));
            var codegenAccessError = typeof codegenAvailability === "object" || codegenAvailability !== "Available" ? AvoLimits.CodegenAvailability.getCanCodegenErrorMessage(workspace, codegenAvailability) : undefined;
            Curry._1(setCodegenAccessError, (function (param) {
                    return codegenAccessError;
                  }));
          }
          
        }), [member]);
  var match$1 = React.useContext(ImplementationStatusContext.context).codegen;
  var handleSelectLanguage = function (value) {
    return Belt_Option.forEach(AvoConfigTypes.languageFromJs(value), (function (language) {
                  return Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              (function (branch) {
                                  return AnalyticsRe.dataSourceUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), AvoConfig.getSourceName(source), undefined, source.platform, undefined, AnalyticsUtils.analyticsLanguage(language), AnalyticsUtils.analyticsLanguage(source.language), source.filename, undefined, source.path, undefined, "ProgrammingLanguage", undefined, SourceConfigOverview.modelSourceConfigToAnalyticsSourceConfig(source.config), undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle.schemaId);
                                }),
                              undefined,
                              [[
                                  {
                                    NAME: "UpdateProgrammingLanguage",
                                    VAL: [
                                      source.id,
                                      language
                                    ]
                                  },
                                  {
                                    sourceId: source.id,
                                    sourceQuery: source.id
                                  }
                                ]]
                            ]);
                }));
  };
  var shouldShowDestinationsInterfaceConfig = React.useMemo((function () {
          return Belt_List.someU(model.sources, (function (param) {
                        return Belt_List.someU(param.destinations, (function (param) {
                                      return param.destinationMode !== /* DestinationInterface */1;
                                    }));
                      }));
        }), []);
  var platform = source.platform;
  var tmp;
  if (platform !== undefined) {
    if (platform === "Other") {
      tmp = React.createElement($$Text.make, {
            size: "Medium",
            weight: "Medium",
            color: Styles.Color.light12,
            children: null
          }, "Codegen is not supported for Sources with Development Platform set to 'Other'. Head to the ", React.createElement(TextButton.make, {
                onClick: (function (param) {
                    return Curry._1(handleTabChange, "overview");
                  }),
                style: "Blue",
                children: "Overview tab"
              }), " to change the Development Platform.");
    } else if (platform === "Go") {
      tmp = React.createElement($$Text.make, {
            size: "Medium",
            weight: "Medium",
            color: Styles.Color.light12,
            children: "Codegen is not supported for Go yet."
          });
    } else if (platform === "Flutter") {
      tmp = React.createElement($$Text.make, {
            size: "Medium",
            weight: "Medium",
            color: Styles.Color.light12,
            children: "Codegen is not supported for Flutter yet."
          });
    } else {
      var match$2 = source.language;
      var tmp$1;
      if (match$2 !== undefined) {
        var match$3 = AvoConfig.supportsInvocations(source);
        var tmp$2;
        if (match$3 && maybeValidSource.TAG === /* Ok */0) {
          var tmp$3;
          if (match$1[0] === "Ready") {
            var initialized = Belt_Option.mapWithDefault(Js_dict.get(match$1[2], source.id), false, (function (invocations) {
                    return !Belt_MapString.isEmpty(invocations);
                  }));
            tmp$3 = initialized ? React.createElement("div", {
                    className: initializedPill
                  }, React.createElement(Icons.CheckmarkRounded.make, {
                        size: 11,
                        color: Styles.Color.deepGreen
                      }), React.createElement(Spacer.make, {
                        width: 4
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.deepGreen,
                        children: "Successfully Initialized"
                      })) : React.createElement("div", {
                    className: waitingPill
                  }, React.createElement(LoadingCircle.make, {
                        size: 18,
                        color: Styles.Color.light08
                      }), React.createElement(Spacer.make, {
                        width: 6
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.light11,
                        children: "Listening for the first initialization…"
                      }));
          } else {
            tmp$3 = React.createElement("div", {
                  className: statusLoading
                });
          }
          tmp$2 = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.light10,
                    children: "Setup Status"
                  }), React.createElement(Spacer.make, {
                    height: 8
                  }), tmp$3, React.createElement(Spacer.make, {
                    height: 24
                  }));
        } else {
          tmp$2 = null;
        }
        var tmp$4;
        tmp$4 = maybeValidSource.TAG === /* Ok */0 ? React.createElement(React.Fragment, undefined, viewerRole === "Admin" ? React.createElement("div", {
                      className: inviteBox
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          children: "Send these instructions to a developer"
                        }), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement(MembersInviteForm.make, {
                          redirectUrl: Router.Link.addDrawerItem(undefined, {
                                NAME: "codegenSource",
                                VAL: source.id
                              }),
                          model: model
                        })) : null, platform === "Gtm" ? null : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                          size: "Large",
                          weight: "Semi",
                          children: "1. Get the Avo file"
                        }), React.createElement(Spacer.make, {
                          height: 12
                        }), codegenAccessError !== undefined ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                color: Styles.Color.red,
                                children: codegenAccessError
                              }), React.createElement(Spacer.make, {
                                height: 12
                              })) : null, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: "You can think about the Avo file like a utility file for your analytics. It should be placed somewhere in your source code directory and checked into git. The Avo file is code generated based on your Avo workspace, with function per each event that validates the properties before passing the event forward to your analytics SDK. So every time the tracking plan in Avo is updated, the Avo file has to be updated. To make that as easy as possible we made the Avo CLI, by running `avo pull` the CLI will update the Avo file for you."
                        }), React.createElement(Spacer.make, {
                          height: 12
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginLeft(Css.px(-13)),
                                tl: /* [] */0
                              })
                        }, React.createElement(ImplementFile.make, {
                              currentBranch: currentBranch,
                              source: maybeValidSource._0
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          element: "P",
                          size: "Medium",
                          weight: "Regular",
                          children: "or"
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginLeft(Css.px(-13)),
                                tl: /* [] */0
                              })
                        }, React.createElement(ImplementCLI.make, {})), React.createElement(Spacer.make, {
                          height: 24
                        }), React.createElement($$Text.make, {
                          size: "Large",
                          weight: "Semi",
                          children: "2. Initialize Avo"
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginLeft(Css.px(-13)),
                                tl: /* [] */0
                              })
                        }, React.createElement(ImplementInit.make, {
                              model: model,
                              source: source
                            })), React.createElement(Spacer.make, {
                          height: 24
                        }), React.createElement($$Text.make, {
                          size: "Large",
                          weight: "Semi",
                          children: "3. Implement Your Events"
                        }), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.light12,
                          children: null
                        }, "Take a look at the ", React.createElement(Link.make, {
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "codegenSource",
                                    VAL: source.id
                                  }),
                              className: link,
                              children: "Implementation documentation"
                            }), " to start implementing your first event."))) : React.createElement("div", undefined, React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    color: Styles.Color.light10,
                    children: "This source has not been setup properly."
                  }), React.createElement(Spacer.make, {
                    height: 12
                  }), React.createElement(Button.make, {
                    label: "Go to Source Settings",
                    onClick: (function (param) {
                        return Router.Schema.swapTopDrawerItem({
                                    NAME: "source",
                                    VAL: [
                                      source.id,
                                      "overview"
                                    ]
                                  });
                      })
                  }));
        tmp$1 = React.createElement(React.Fragment, undefined, tmp$2, shouldShowDestinationsInterfaceConfig ? React.createElement(SourceConfigCodegenDestinations.make, {
                    currentBranch: currentBranch,
                    model: model,
                    sendActions: sendActions,
                    source: source
                  }) : null, tmp$4);
      } else {
        tmp$1 = null;
      }
      tmp = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                size: "Small",
                weight: "Semi",
                color: Styles.Color.light10,
                children: "Programming Language"
              }), React.createElement(Spacer.make, {
                height: 8
              }), React.createElement(Select.make, {
                onSelect: handleSelectLanguage,
                options: Belt_Array.concatMany([
                      Belt_Option.isNone(source.language) ? [[
                            {
                              NAME: "Label",
                              VAL: "Select Programming Language"
                            },
                            ""
                          ]] : [],
                      Belt_Array.keepMap(AvoConfig.getSupportedLanguages(source.platform), (function (language) {
                              if (!hasIncludeLegacyJsAndReFeatureFlag && (language === "re" || language === "js")) {
                                return ;
                              }
                              return [
                                      {
                                        NAME: "Label",
                                        VAL: AvoConfig.languageLabel(language)
                                      },
                                      AvoConfigTypes.languageToJs(language)
                                    ];
                            }))
                    ]),
                value: Belt_Option.mapWithDefault(source.language, "", AvoConfigTypes.languageToJs)
              }), React.createElement(Spacer.make, {
                height: 24
              }), tmp$1);
    }
  } else {
    tmp = React.createElement($$Text.make, {
          size: "Medium",
          weight: "Medium",
          color: Styles.Color.light12,
          children: null
        }, "Select a Development Platform from the ", React.createElement(TextButton.make, {
              onClick: (function (param) {
                  return Curry._1(handleTabChange, "overview");
                }),
              style: "Blue",
              children: "Overview tab"
            }), " to get started.");
  }
  return React.createElement("div", {
              className: root
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light10,
                  maxWidth: Css.px(500),
                  children: null
                }, "Avo Codegen allows your team to seamlessly translate your tracking plan to code so you get your analytics implementation right the first time. Use the built-in debugger to see how your events are sent as you’re developing. See our ", React.createElement("a", {
                      className: link,
                      href: "https://www.avo.app/docs/implementation/avo-functions-overview",
                      rel: "noopener noreferrer",
                      target: "_blank"
                    }, "docs"), " for more info."), React.createElement(Spacer.make, {
                  height: 24
                }), tmp);
}

var make = SourceConfigCodegenSetup;

export {
  Style ,
  make ,
  
}
/* root Not a pure module */
