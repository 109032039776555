// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as AvoModel from "../avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ModelUtils_mapped from "../ModelUtils_mapped.mjs";
import * as InspectorIssueType from "../../../shared/models/InspectorIssueType.mjs";
import * as InspectorPropertyType from "../../../shared/models/InspectorPropertyType.mjs";
import * as InspectorEventViewModel from "./InspectorEventViewModel.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";
import * as InspectorIssuesGenerator from "../../../api/functions/src/inspectorPipeline/utils/InspectorIssuesGenerator.mjs";
import * as InspectorTrackingPlanUtils from "../../../shared/InspectorTrackingPlanUtils.mjs";

function useSource(sourceIdSet, sourceId) {
  if (sourceIdSet !== undefined) {
    return Belt_SetString.has(Caml_option.valFromOption(sourceIdSet), sourceId);
  } else {
    return true;
  }
}

function getPresence(param) {
  return param.sourcePresence;
}

function getIssues(param) {
  return param.issues;
}

var PropertySource = {
  getPresence: getPresence,
  getIssues: getIssues
};

function getTotalVolume(param) {
  return param.totalVolume;
}

function getIssues$1(param) {
  return param.issues;
}

var $$EventSource = {
  getTotalVolume: getTotalVolume,
  getIssues: getIssues$1
};

function getName(param) {
  return param.propertyName;
}

function getSources(param) {
  return Js_dict.entries(param.propertyShape.presence.presenceBySource);
}

function getSource(t, sourceId) {
  return Js_dict.get(t.propertyShape.presence.presenceBySource, sourceId);
}

function getSourceIds(param) {
  return Object.keys(param.propertyShape.presence.presenceBySource);
}

function getPresencePercentage(sourceId, t) {
  return Belt_Option.mapWithDefaultU(sourceId, t.propertyShape.presence.percentagePresent, (function (sourceId) {
                return Belt_Option.mapU(Js_dict.get(t.propertyShape.presence.presenceBySource, sourceId), (function (param) {
                              return param.percentagePresent;
                            }));
              }));
}

function getTotalCount(sourceIds, t) {
  var getSourceCount = function (param) {
    return param.totalVolume;
  };
  var getTotalCount$1 = function (param) {
    return param.propertyShape.presence.totalVolume;
  };
  return Belt_Option.mapWithDefaultU(sourceIds, getTotalCount$1(t), (function (sourceIds) {
                var sourceIdsSet = Belt_SetString.fromArray(sourceIds);
                return Belt_Array.reduceU(getSources(t), 0.0, (function (total, param) {
                              if (Belt_SetString.has(sourceIdsSet, param[0])) {
                                return total + getSourceCount(param[1]);
                              } else {
                                return total;
                              }
                            }));
              }));
}

function getTotalCountInt(sourceIds, t) {
  return getTotalCount(sourceIds, t) | 0;
}

function getIssues$2(sourceIds, t) {
  var sourceIdSet = Belt_Option.map(sourceIds, Belt_SetString.fromArray);
  return Belt_Array.concatMany(Belt_MapString.valuesToArray(Belt_MapString.mapWithKeyU(t.issues, (function (sourceId, sourceIssues) {
                        if (useSource(sourceIdSet, sourceId)) {
                          return getIssues(sourceIssues);
                        } else {
                          return [];
                        }
                      }))));
}

function getIssuesGroupedBySource(sourceIds, t) {
  var sourceIdSet = Belt_Option.map(sourceIds, Belt_SetString.fromArray);
  return Belt_Array.concatMany(Belt_MapString.valuesToArray(Belt_MapString.mapWithKeyU(t.issues, (function (sourceId, sourceIssues) {
                        if (useSource(sourceIdSet, sourceId)) {
                          return Belt_Array.mapU(getIssues(sourceIssues), (function (issue) {
                                        return [
                                                sourceId,
                                                t,
                                                issue
                                              ];
                                      }));
                        } else {
                          return [];
                        }
                      }))));
}

function getIssueTypeCount(sourceId, t) {
  return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_MapString.valuesToArray(Belt_MapString.mapU(Belt_MapString.keepU(t.issues, (function (sId, _issues) {
                                    return Belt_Option.mapWithDefaultU(sourceId, true, (function (sourceId) {
                                                  return sourceId === sId;
                                                }));
                                  })), (function (param) {
                                return Belt_Array.mapU(param.issues, InspectorIssueType.getIssueTypeAsText);
                              })))))).length;
}

var Property = {
  getName: getName,
  getSources: getSources,
  getSource: getSource,
  getSourceIds: getSourceIds,
  getPresencePercentage: getPresencePercentage,
  getTotalCount: getTotalCount,
  getTotalCountInt: getTotalCountInt,
  getIssues: getIssues$2,
  getIssuesGroupedBySource: getIssuesGroupedBySource,
  getIssueTypeCount: getIssueTypeCount
};

function getName$1(t) {
  return t.eventName;
}

function getSources$1(t) {
  return Js_dict.entries(t.eventShape.presenceBySource);
}

function getSource$1(t, sourceId) {
  return Js_dict.get(t.eventShape.presenceBySource, sourceId);
}

function getSourceIds$1(t) {
  return Object.keys(t.eventShape.presenceBySource);
}

function getTotalCount$1(sourceIds, t) {
  return Belt_Option.mapWithDefaultU(sourceIds, t.totalVolume, (function (sourceIds) {
                var sourceIdsSet = Belt_SetString.fromArray(sourceIds);
                return Belt_Array.reduceU(Js_dict.entries(t.eventShape.presenceBySource), 0.0, (function (total, param) {
                              if (Belt_SetString.has(sourceIdsSet, param[0])) {
                                return total + param[1];
                              } else {
                                return total;
                              }
                            }));
              }));
}

function getTotalCountInt$1(sourceIds, t) {
  return getTotalCount$1(sourceIds, t) | 0;
}

function getIssues$3(sourceIds, t) {
  var sourceIds$1 = Belt_Option.getWithDefault(sourceIds, Object.keys(t.eventShape.presenceBySource));
  return Belt_Array.concatMany(Belt_Array.mapU(t.properties, (function (property) {
                    return getIssues$2(sourceIds$1, property);
                  })));
}

function getPropertyIssuesGroupedBySource(sourceIds, t) {
  var sourceIdSet = Belt_Option.map(sourceIds, Belt_SetString.fromArray);
  return Belt_Array.concatMany(Belt_Array.mapU(t.properties, (function (property) {
                    return Belt_Array.concatMany(Belt_MapString.valuesToArray(Belt_MapString.mapWithKeyU(property.issues, (function (sourceId, sourceIssues) {
                                          if (useSource(sourceIdSet, sourceId)) {
                                            return Belt_Array.mapU(getIssues(sourceIssues), (function (issue) {
                                                          return [
                                                                  sourceId,
                                                                  property,
                                                                  issue
                                                                ];
                                                        }));
                                          } else {
                                            return [];
                                          }
                                        }))));
                  })));
}

function getIssueTypesAsText(sourceId, t) {
  return Belt_MapString.mapU(Belt_Array.reduceU(t.properties, undefined, (function (m, property) {
                    return Belt_MapString.reduceU(property.issues, m, (function (m, sId, param) {
                                  var issues = param.issues;
                                  if (Belt_Option.mapWithDefault(sourceId, true, (function (id) {
                                            return id === sId;
                                          }))) {
                                    return Belt_MapString.updateU(m, sId, (function (maybeSourceIssues) {
                                                  return Belt_Option.mapWithDefaultU(maybeSourceIssues, Belt_Array.mapU(issues, InspectorIssueType.getIssueTypeAsText), (function (existingIssues) {
                                                                return Belt_Array.concat(existingIssues, Belt_Array.mapU(issues, InspectorIssueType.getIssueTypeAsText));
                                                              }));
                                                }));
                                  } else {
                                    return m;
                                  }
                                }));
                  })), (function (issues) {
                return Belt_SetString.toArray(Belt_SetString.fromArray(issues));
              }));
}

function getIssueTypeCount$1(sourceId, t) {
  return Belt_Array.reduceU(t.properties, 0, (function (count, property) {
                return count + getIssueTypeCount(sourceId, property) | 0;
              }));
}

function isUnexpectedOrMissingInTrackingPlan(sourceIds, t) {
  return Caml_obj.caml_notequal(getIssues$3(sourceIds, t), []);
}

function getPropertyProportionTotalCount(sourceIds, e, p) {
  return getTotalCount(sourceIds, p) / getTotalCount$1(sourceIds, e);
}

var $$Event = {
  getName: getName$1,
  getSources: getSources$1,
  getSource: getSource$1,
  getSourceIds: getSourceIds$1,
  getTotalCount: getTotalCount$1,
  getTotalCountInt: getTotalCountInt$1,
  getIssues: getIssues$3,
  getPropertyIssuesGroupedBySource: getPropertyIssuesGroupedBySource,
  getIssueTypesAsText: getIssueTypesAsText,
  getIssueTypeCount: getIssueTypeCount$1,
  isUnexpectedOrMissingInTrackingPlan: isUnexpectedOrMissingInTrackingPlan,
  getPropertyProportionTotalCount: getPropertyProportionTotalCount
};

function makePresentable(map) {
  return Belt_Array.mapU(Curry._1(InspectorEventViewModel.toArray, map), (function ($$event) {
                return [
                        $$event.eventName,
                        InspectorEventViewModel.$$Event.makeEventPresentable($$event)
                      ];
              }));
}

function convertPropertiesWithoutIssues(properties) {
  return Belt_Array.mapU(properties, (function (property) {
                return {
                        propertyName: property.propertyName,
                        propertyShape: property,
                        issues: undefined
                      };
              }));
}

function getUnexpectedEventIssues(events, eventShape, modelEvent, issues) {
  return Belt_Array.reduceU(Js_dict.entries(eventShape.presenceBySource), issues, (function (issues, param) {
                var totalVolume = param[1];
                var sourceId = param[0];
                var issue = InspectorIssuesGenerator.checkUnexpectedEventIssue_mappedModel(sourceId, modelEvent, events);
                if (issue !== undefined) {
                  return Curry._3(InspectorEventViewModel.update, issues, eventShape.eventName, (function (maybeEvent) {
                                if (maybeEvent !== undefined) {
                                  return {
                                          eventName: maybeEvent.eventName,
                                          totalVolume: maybeEvent.totalVolume,
                                          eventShape: maybeEvent.eventShape,
                                          properties: maybeEvent.properties,
                                          issues: Belt_MapString.update(maybeEvent.issues, sourceId, (function (maybeSource) {
                                                  if (maybeSource !== undefined) {
                                                    return {
                                                            sourceId: maybeSource.sourceId,
                                                            totalVolume: maybeSource.totalVolume,
                                                            issues: Belt_Array.concat(maybeSource.issues, [issue])
                                                          };
                                                  } else {
                                                    return {
                                                            sourceId: sourceId,
                                                            totalVolume: totalVolume,
                                                            issues: [issue]
                                                          };
                                                  }
                                                }))
                                        };
                                }
                                var source_issues = [issue];
                                var source = {
                                  sourceId: sourceId,
                                  totalVolume: totalVolume,
                                  issues: source_issues
                                };
                                return {
                                        eventName: eventShape.eventName,
                                        totalVolume: eventShape.totalVolume,
                                        eventShape: eventShape,
                                        properties: [],
                                        issues: Belt_MapString.fromArray([[
                                                sourceId,
                                                source
                                              ]])
                                      };
                              }));
                } else {
                  return Curry._3(InspectorEventViewModel.update, issues, eventShape.eventName, (function (maybeEvent) {
                                if (maybeEvent !== undefined) {
                                  return maybeEvent;
                                } else {
                                  return {
                                          eventName: eventShape.eventName,
                                          totalVolume: eventShape.totalVolume,
                                          eventShape: eventShape,
                                          properties: [],
                                          issues: undefined
                                        };
                                }
                              }));
                }
              }));
}

function getEventNotInTrackingPlanIssues(eventShape, issues) {
  return Curry._3(InspectorEventViewModel.update, issues, eventShape.eventName, (function (maybeEvent) {
                if (maybeEvent !== undefined) {
                  return maybeEvent;
                } else {
                  return {
                          eventName: eventShape.eventName,
                          totalVolume: eventShape.totalVolume,
                          eventShape: eventShape,
                          properties: [],
                          issues: Belt_MapString.fromArray(Belt_Array.mapU(Js_dict.entries(eventShape.presenceBySource), (function (param) {
                                      var sourceId = param[0];
                                      var source_totalVolume = param[1];
                                      var source_issues = [/* EventNotInTrackingPlan */0];
                                      var source = {
                                        sourceId: sourceId,
                                        totalVolume: source_totalVolume,
                                        issues: source_issues
                                      };
                                      return [
                                              sourceId,
                                              source
                                            ];
                                    })))
                        };
                }
              }));
}

function getMissingExpectedPropertyForSourceId(events, propertyBundles, sourceId, eventId, eventProperty, shapeProperty) {
  if (!ModelUtils_mapped.isPropertyIncludedOnEventAndSource(true, events, sourceId, eventId, eventProperty)(propertyBundles)) {
    return ;
  }
  var sourcePresence = Belt_Option.flatMap(shapeProperty, (function (param) {
          return Js_dict.get(param[1].presence.presenceBySource, sourceId);
        }));
  if (sourcePresence !== undefined) {
    var propertyOptional = ModelUtils_mapped.isPropertyOptionalOnEventAndSource(eventId, sourceId, events, eventProperty);
    if (!propertyOptional && sourcePresence.percentagePresent < 1.0) {
      return [
              sourceId,
              {
                sourceId: sourceId,
                sourcePresence: sourcePresence,
                issues: [{
                    TAG: /* MissingExpectedProperty */0,
                    _0: {
                      eventId: eventId,
                      propertyId: eventProperty.id,
                      propertyName: eventProperty.name
                    }
                  }]
              }
            ];
    } else {
      return ;
    }
  }
  var source_sourcePresence = {
    totalVolume: 0,
    percentagePresent: 0
  };
  var source_issues = [{
      TAG: /* MissingExpectedProperty */0,
      _0: {
        eventId: eventId,
        propertyId: eventProperty.id,
        propertyName: eventProperty.name
      }
    }];
  var source = {
    sourceId: sourceId,
    sourcePresence: source_sourcePresence,
    issues: source_issues
  };
  return [
          sourceId,
          source
        ];
}

function getMissingExpectedPropertyIssues(shapeProperties, modelEvent, mappedModel, eventShape) {
  var eventProperties = AvoModel.getResolvedPropertiesForEvent_mappedModel(mappedModel.propertyBundles, mappedModel.properties, modelEvent);
  var existingShapeProperties = Belt_Array.keepMap(shapeProperties, (function (shapeProperty) {
          var property = ModelUtils_mapped.getPropertyByNameOrNameMapping(mappedModel.rules, mappedModel.destinations, mappedModel.properties, shapeProperty.propertyName, modelEvent.id);
          if (property !== undefined) {
            return [
                    property.id,
                    shapeProperty
                  ];
          }
          
        }));
  return Belt_Array.mapU(eventProperties, (function (eventProperty) {
                var shapeProperty = Belt_Array.getByU(existingShapeProperties, (function (param) {
                        return param[0] === eventProperty.id;
                      }));
                var sourcesWithIssues = Belt_Array.keepMapU(Object.keys(eventShape.presenceBySource), (function (sourceId) {
                        if (Curry._2(TrackingPlanMappedModel.Sources.has, mappedModel.sources, sourceId)) {
                          return getMissingExpectedPropertyForSourceId(mappedModel.events, mappedModel.propertyBundles, sourceId, modelEvent.id, eventProperty, shapeProperty);
                        }
                        
                      }));
                var sourcesPresence = Belt_Array.mapU(sourcesWithIssues, (function (param) {
                        return [
                                param[0],
                                param[1].sourcePresence
                              ];
                      }));
                if (shapeProperty === undefined) {
                  return {
                          propertyName: eventProperty.name,
                          propertyShape: {
                            propertyName: eventProperty.name,
                            propertyTypes: [],
                            presence: {
                              totalVolume: 0,
                              percentagePresent: 0,
                              presenceBySource: Js_dict.fromArray(sourcesPresence)
                            }
                          },
                          issues: Belt_MapString.fromArray(sourcesWithIssues)
                        };
                }
                var shapeProperty$1 = shapeProperty[1];
                return {
                        propertyName: shapeProperty$1.propertyName,
                        propertyShape: {
                          propertyName: shapeProperty$1.propertyName,
                          propertyTypes: shapeProperty$1.propertyTypes,
                          presence: shapeProperty$1.presence
                        },
                        issues: Belt_MapString.fromArray(sourcesWithIssues)
                      };
              }));
}

function getUnexpectedPropertyIssue(events, properties, propertyBundles, sources, modelEvent, maybeModelProperty, propertyShape, property) {
  var updateIssuesForTypeAndSource = function (property, propertyType, sourceId, sourcePresence) {
    return Belt_MapString.update(property.issues, sourceId, (function (maybeSource) {
                  var newIssue = {
                    TAG: /* UnexpectedProperty */2,
                    _0: {
                      eventId: modelEvent.id,
                      propertyName: propertyShape.propertyName,
                      propertyType: propertyType
                    }
                  };
                  if (maybeSource !== undefined) {
                    return {
                            sourceId: maybeSource.sourceId,
                            sourcePresence: maybeSource.sourcePresence,
                            issues: Belt_Array.concat(maybeSource.issues, [newIssue])
                          };
                  } else {
                    return {
                            sourceId: sourceId,
                            sourcePresence: sourcePresence,
                            issues: [newIssue]
                          };
                  }
                }));
  };
  return Belt_Array.reduceU(propertyShape.propertyTypes, property, (function (property, propertyType) {
                if (propertyType.percentagePresent <= 0.0) {
                  return property;
                } else {
                  return Belt_Array.reduceU(Js_dict.entries(propertyType.presenceBySource), property, (function (property, param) {
                                var sourcePresence = param[1];
                                var sourceId = param[0];
                                if (maybeModelProperty !== undefined) {
                                  if (Curry._2(TrackingPlanMappedModel.Sources.has, sources, sourceId)) {
                                    if (sourcePresence.percentagePresent > 0.0 && !ModelUtils_mapped.isPropertyIncludedOnEventAndSource(true, events, sourceId, modelEvent.id, maybeModelProperty)(propertyBundles)) {
                                      return {
                                              propertyName: property.propertyName,
                                              propertyShape: property.propertyShape,
                                              issues: updateIssuesForTypeAndSource(property, InspectorPropertyType.toString(propertyType.type_), sourceId, sourcePresence)
                                            };
                                    } else {
                                      return property;
                                    }
                                  } else if (Belt_Array.everyU(AvoModel.getResolvedPropertiesForEvent_mappedModel(propertyBundles, properties, modelEvent), (function (p) {
                                            return p.id !== maybeModelProperty.id;
                                          })) && sourcePresence.percentagePresent > 0.0) {
                                    return {
                                            propertyName: property.propertyName,
                                            propertyShape: property.propertyShape,
                                            issues: updateIssuesForTypeAndSource(property, InspectorPropertyType.toString(propertyType.type_), sourceId, sourcePresence)
                                          };
                                  } else {
                                    return property;
                                  }
                                } else {
                                  return {
                                          propertyName: property.propertyName,
                                          propertyShape: property.propertyShape,
                                          issues: updateIssuesForTypeAndSource(property, InspectorPropertyType.toString(propertyType.type_), sourceId, sourcePresence)
                                        };
                                }
                              }));
                }
              }));
}

function getPropertyTypeInconsistentWithTrackingPlanIssue(eventId, maybeModelProperty, propertyShape, property) {
  if (maybeModelProperty === undefined) {
    return property;
  }
  var list = maybeModelProperty.list;
  var type_ = maybeModelProperty.type_;
  var propertyId = maybeModelProperty.id;
  return Belt_Array.reduceU(propertyShape.propertyTypes, property, (function (property, propertyType) {
                if (type_ === "any" || Caml_obj.caml_equal(InspectorPropertyType.fromString(type_), propertyType.type_) || InspectorTrackingPlanUtils.isIntWhenFloatExpected(maybeModelProperty, InspectorPropertyType.toString(propertyType.type_)) && propertyType.isList === list) {
                  return property;
                } else {
                  return Belt_Array.reduceU(Js_dict.entries(propertyType.presenceBySource), property, (function (property, param) {
                                var sourcePresence = param[1];
                                if (sourcePresence.percentagePresent <= 0.0) {
                                  return property;
                                }
                                var sourceId = param[0];
                                return {
                                        propertyName: property.propertyName,
                                        propertyShape: property.propertyShape,
                                        issues: Belt_MapString.update(property.issues, sourceId, (function (maybeSource) {
                                                var newIssue = {
                                                  TAG: /* PropertyTypeInconsistentWithTrackingPlan */1,
                                                  _0: {
                                                    eventId: eventId,
                                                    propertyId: propertyId,
                                                    propertyName: propertyShape.propertyName,
                                                    expectedPropertyType: type_,
                                                    actualPropertyType: InspectorPropertyType.toString(propertyType.type_)
                                                  }
                                                };
                                                if (maybeSource !== undefined) {
                                                  return {
                                                          sourceId: maybeSource.sourceId,
                                                          sourcePresence: maybeSource.sourcePresence,
                                                          issues: Belt_Array.concat(maybeSource.issues, [newIssue])
                                                        };
                                                } else {
                                                  return {
                                                          sourceId: sourceId,
                                                          sourcePresence: sourcePresence,
                                                          issues: [newIssue]
                                                        };
                                                }
                                              }))
                                      };
                              }));
                }
              }));
}

function removeIntTypeIfFloatOrLongIsPresent(consolidatedTypes) {
  var match = Belt_SetString.get(consolidatedTypes, InspectorPropertyType.toString(/* Int */1));
  var match$1 = Belt_SetString.get(consolidatedTypes, InspectorPropertyType.toString(/* Float */2));
  if (match !== undefined && match$1 !== undefined) {
    return Belt_SetString.remove(consolidatedTypes, InspectorPropertyType.toString(/* Int */1));
  } else {
    return consolidatedTypes;
  }
}

function getInconsistentTypeIssue(maybeModelProperty, propertyShape, property) {
  var consolidatedTypes = removeIntTypeIfFloatOrLongIsPresent(Belt_SetString.fromArray(Belt_Array.mapU(propertyShape.propertyTypes, (function (param) {
                  return InspectorPropertyType.toString(param.type_);
                }))));
  if (Belt_Option.isSome(maybeModelProperty) || Belt_SetString.size(consolidatedTypes) === 1) {
    return property;
  }
  var typesPerSourceId = Belt_MapString.keepU(Belt_Array.reduceU(propertyShape.propertyTypes, undefined, (function (sourceMap, propertyType) {
              return Belt_Array.reduceU(Js_dict.entries(propertyType.presenceBySource), sourceMap, (function (sourceMap, param) {
                            var sourcePresence = param[1];
                            return Belt_MapString.update(sourceMap, param[0], (function (maybeTypes) {
                                          return Belt_Option.mapWithDefault(maybeTypes, [
                                                      sourcePresence,
                                                      Belt_SetString.fromArray([InspectorPropertyType.toString(propertyType.type_)])
                                                    ], (function (param) {
                                                        return [
                                                                param[0],
                                                                Belt_SetString.add(param[1], InspectorPropertyType.toString(propertyType.type_))
                                                              ];
                                                      }));
                                        }));
                          }));
            })), (function (_key, param) {
          return Belt_SetString.size(param[1]) > 1;
        }));
  return Belt_Array.reduceU(Belt_MapString.toArray(typesPerSourceId), property, (function (property, param) {
                var match = param[1];
                var types = match[1];
                var sourcePresence = match[0];
                var sourceId = param[0];
                return {
                        propertyName: property.propertyName,
                        propertyShape: property.propertyShape,
                        issues: Belt_MapString.update(property.issues, sourceId, (function (maybeSource) {
                                if (maybeSource !== undefined) {
                                  return {
                                          sourceId: maybeSource.sourceId,
                                          sourcePresence: maybeSource.sourcePresence,
                                          issues: Belt_Array.concat(maybeSource.issues, [{
                                                  TAG: /* InconsistentType */3,
                                                  _0: {
                                                    propertyName: property.propertyName,
                                                    propertyTypes: Belt_SetString.toArray(types)
                                                  }
                                                }])
                                        };
                                } else {
                                  return {
                                          sourceId: sourceId,
                                          sourcePresence: sourcePresence,
                                          issues: [{
                                              TAG: /* InconsistentType */3,
                                              _0: {
                                                propertyName: property.propertyName,
                                                propertyTypes: Belt_SetString.toArray(types)
                                              }
                                            }]
                                        };
                                }
                              }))
                      };
              }));
}

function dedupeProperties(propertiesA, propertiesB) {
  var propertiesMap = Belt_MapString.fromArray(Belt_Array.mapU(propertiesA, (function (property) {
              return [
                      property.propertyName,
                      property
                    ];
            })));
  return Belt_MapString.valuesToArray(Belt_Array.reduceU(propertiesB, propertiesMap, (function (map, property) {
                    var existingProperty = Belt_MapString.get(map, property.propertyName);
                    if (existingProperty === undefined) {
                      return Belt_MapString.set(map, property.propertyName, property);
                    }
                    var updatedPropertyIssues = Belt_Array.reduceU(Belt_MapString.toArray(property.issues), existingProperty.issues, (function (existingIssues, param) {
                            var source = param[1];
                            return Belt_MapString.update(existingIssues, param[0], (function (existingSource) {
                                          if (existingSource !== undefined) {
                                            return {
                                                    sourceId: existingSource.sourceId,
                                                    sourcePresence: existingSource.sourcePresence,
                                                    issues: Belt_Array.concat(existingSource.issues, source.issues)
                                                  };
                                          } else {
                                            return source;
                                          }
                                        }));
                          }));
                    var updatedProperty_propertyName = existingProperty.propertyName;
                    var updatedProperty_propertyShape = existingProperty.propertyShape;
                    var updatedProperty = {
                      propertyName: updatedProperty_propertyName,
                      propertyShape: updatedProperty_propertyShape,
                      issues: updatedPropertyIssues
                    };
                    return Belt_MapString.set(map, property.propertyName, updatedProperty);
                  })));
}

function getPropertyIssues(events, properties, propertyBundles, sources, modelEvent, propertyShape, maybeModelProperty, property) {
  return getInconsistentTypeIssue(maybeModelProperty, propertyShape, getUnexpectedPropertyIssue(events, properties, propertyBundles, sources, modelEvent, maybeModelProperty, propertyShape, getPropertyTypeInconsistentWithTrackingPlanIssue(modelEvent.id, maybeModelProperty, propertyShape, property)));
}

function getPropertiesIssues(eventShape, mappedModel, modelEvent) {
  var missingExpectedPropertyIssues = getMissingExpectedPropertyIssues(eventShape.properties, modelEvent, mappedModel, eventShape);
  var otherPropertyIssues = Belt_Array.mapU(eventShape.properties, (function (property) {
          var maybeModelProperty = ModelUtils_mapped.getPropertyByNameOrNameMapping(mappedModel.rules, mappedModel.destinations, mappedModel.properties, property.propertyName, modelEvent.id);
          return getPropertyIssues(mappedModel.events, mappedModel.properties, mappedModel.propertyBundles, mappedModel.sources, modelEvent, property, maybeModelProperty, {
                      propertyName: property.propertyName,
                      propertyShape: property,
                      issues: Belt_MapString.fromArray(Belt_Array.mapU(Js_dict.entries(property.presence.presenceBySource), (function (param) {
                                  var sourceId = param[0];
                                  return [
                                          sourceId,
                                          {
                                            sourceId: sourceId,
                                            sourcePresence: param[1],
                                            issues: []
                                          }
                                        ];
                                })))
                    });
        }));
  return dedupeProperties(missingExpectedPropertyIssues, otherPropertyIssues);
}

function getPropertiesIssuesForEvent(eventShape, mappedModel, modelEvent, issues) {
  return Curry._3(InspectorEventViewModel.update, issues, eventShape.eventName, (function (maybeEvent) {
                if (maybeEvent !== undefined) {
                  return {
                          eventName: maybeEvent.eventName,
                          totalVolume: maybeEvent.totalVolume,
                          eventShape: maybeEvent.eventShape,
                          properties: getPropertiesIssues(eventShape, mappedModel, modelEvent),
                          issues: maybeEvent.issues
                        };
                } else {
                  return {
                          eventName: eventShape.eventName,
                          totalVolume: eventShape.totalVolume,
                          eventShape: eventShape,
                          properties: getPropertiesIssues(eventShape, mappedModel, modelEvent),
                          issues: Belt_MapString.fromArray(Belt_Array.mapU(Js_dict.entries(eventShape.presenceBySource), (function (param) {
                                      var sourceId = param[0];
                                      return [
                                              sourceId,
                                              {
                                                sourceId: sourceId,
                                                totalVolume: param[1],
                                                issues: []
                                              }
                                            ];
                                    })))
                        };
                }
              }));
}

function getGlobalPropertyTypeIssues(maybeProperty, property, issues) {
  var tmp;
  if (maybeProperty !== undefined) {
    var type_ = maybeProperty.type_;
    var l = Belt_List.fromArray(property.propertyTypes);
    var exit = 0;
    if (l && !l.tl) {
      var propertyType = l.hd;
      tmp = InspectorPropertyType.toString(propertyType.type_) !== type_ ? [{
            TAG: /* PropertyTypeInconsistentWithTrackingPlan */1,
            _0: {
              eventId: undefined,
              propertyId: maybeProperty.id,
              propertyName: property.propertyName,
              expectedPropertyType: type_,
              actualPropertyType: InspectorPropertyType.toString(propertyType.type_)
            }
          }] : [];
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = Belt_List.size(l) > 1 ? [{
            TAG: /* InconsistentType */3,
            _0: {
              propertyName: property.propertyName,
              propertyTypes: Belt_Array.mapU(property.propertyTypes, (function (param) {
                      return InspectorPropertyType.toString(param.type_);
                    }))
            }
          }] : [];
    }
    
  } else {
    tmp = [];
  }
  return Belt_Array.concat(issues, tmp);
}

function getGlobalPropertiesIssues(properties, propertiesByName) {
  return Belt_Array.mapU(properties, (function (property) {
                return {
                        propertyName: property.propertyName,
                        propertyShape: property,
                        issues: Belt_MapString.fromArray(Belt_Array.mapU(Js_dict.entries(property.presence.presenceBySource), (function (param) {
                                    var sourceId = param[0];
                                    var issues = [];
                                    var maybeProperty = Curry._2(TrackingPlanMappedModel.Utils.PropertiesByName.get, propertiesByName, property.propertyName);
                                    return [
                                            sourceId,
                                            {
                                              sourceId: sourceId,
                                              sourcePresence: param[1],
                                              issues: getGlobalPropertyTypeIssues(maybeProperty, property, issues)
                                            }
                                          ];
                                  })))
                      };
              }));
}

function getPropertiesIssuesForEventNotInTrackingPlan(eventShape, mappedProperties, issues) {
  var propertiesByName = Curry._1(TrackingPlanMappedModel.Utils.PropertiesByName.fromArray, Curry._1(TrackingPlanMappedModel.Properties.toArray, mappedProperties));
  return Curry._3(InspectorEventViewModel.update, issues, eventShape.eventName, (function (maybeEvent) {
                if (maybeEvent !== undefined) {
                  return {
                          eventName: maybeEvent.eventName,
                          totalVolume: maybeEvent.totalVolume,
                          eventShape: maybeEvent.eventShape,
                          properties: getGlobalPropertiesIssues(eventShape.properties, propertiesByName),
                          issues: maybeEvent.issues
                        };
                } else {
                  return {
                          eventName: eventShape.eventName,
                          totalVolume: eventShape.totalVolume,
                          eventShape: eventShape,
                          properties: getGlobalPropertiesIssues(eventShape.properties, propertiesByName),
                          issues: Belt_MapString.fromArray(Belt_Array.mapU(Js_dict.entries(eventShape.presenceBySource), (function (param) {
                                      var sourceId = param[0];
                                      return [
                                              sourceId,
                                              {
                                                sourceId: sourceId,
                                                totalVolume: param[1],
                                                issues: []
                                              }
                                            ];
                                    })))
                        };
                }
              }));
}

function convertShapesToEventViewModel(mappedModel, eventShapes) {
  return Belt_Array.reduceU(eventShapes, InspectorEventViewModel.empty, (function (issues, eventShape) {
                var modelEvent = InspectorIssuesGenerator.getTrackingPlanEvent_mappedModel(mappedModel.events, mappedModel.rules, eventShape.eventName);
                if (modelEvent !== undefined) {
                  return getPropertiesIssuesForEvent(eventShape, mappedModel, modelEvent, getUnexpectedEventIssues(mappedModel.events, eventShape, modelEvent, issues));
                } else {
                  return getPropertiesIssuesForEventNotInTrackingPlan(eventShape, mappedModel.properties, getEventNotInTrackingPlanIssues(eventShape, issues));
                }
              }));
}

var T;

export {
  T ,
  PropertySource ,
  $$EventSource ,
  Property ,
  $$Event ,
  makePresentable ,
  convertPropertiesWithoutIssues ,
  getUnexpectedEventIssues ,
  getEventNotInTrackingPlanIssues ,
  getMissingExpectedPropertyForSourceId ,
  getMissingExpectedPropertyIssues ,
  getUnexpectedPropertyIssue ,
  getPropertyTypeInconsistentWithTrackingPlanIssue ,
  removeIntTypeIfFloatOrLongIsPresent ,
  getInconsistentTypeIssue ,
  dedupeProperties ,
  getPropertyIssues ,
  getPropertiesIssues ,
  getPropertiesIssuesForEvent ,
  getGlobalPropertyTypeIssues ,
  getGlobalPropertiesIssues ,
  getPropertiesIssuesForEventNotInTrackingPlan ,
  convertShapesToEventViewModel ,
  
}
/* AvoModel Not a pure module */
