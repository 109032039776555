// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Modal from "./Modal.mjs";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as MembersRow from "./MembersRow.mjs";
import * as TextButton from "./TextButton.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SchemaInvites from "./SchemaInvites.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as ServiceAccounts from "./ServiceAccounts.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as MembersInviteForm from "./MembersInviteForm.mjs";
import * as CloudFunctionHooks from "./CloudFunctionHooks.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as WorkspaceDangerZone from "./WorkspaceDangerZone.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as WorkspacePermissions from "./WorkspacePermissions.mjs";
import * as WorkspaceNotifications from "./WorkspaceNotifications.mjs";

var tabsStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("flexStart"),
          tl: {
            hd: Css.minWidth(Css.px(500)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light01),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("flexStart"),
                  tl: {
                    hd: Css.flexShrink(0.0),
                    tl: {
                      hd: Css.padding2(Css.px(8), "zero"),
                      tl: {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                          tl: {
                            hd: Css.after({
                                  hd: Css.display("block"),
                                  tl: {
                                    hd: Css.position("absolute"),
                                    tl: {
                                      hd: Css.unsafe("content", "' '"),
                                      tl: {
                                        hd: Css.width(Css.pct(100)),
                                        tl: {
                                          hd: Css.height(Css.px(16)),
                                          tl: {
                                            hd: Css.bottom(Css.px(-16)),
                                            tl: {
                                              hd: Css.pointerEvents("none"),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.avoPink),
                                                tl: {
                                                  hd: Css.background({
                                                        NAME: "linearGradient",
                                                        VAL: [
                                                          Css.deg(0.0),
                                                          [
                                                            [
                                                              Css.pct(0.0),
                                                              Styles.Color.setAlpha(Styles.Color.light12, 0.0)
                                                            ],
                                                            [
                                                              Css.pct(100.0),
                                                              Styles.Color.setAlpha(Styles.Color.light12, 0.07)
                                                            ]
                                                          ]
                                                        ]
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var tabItem = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.backgroundColor("transparent"),
              tl: {
                hd: Css.height(Css.pct(100)),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.justifyContent("flexStart"),
                      tl: {
                        hd: Css.paddingLeft(Css.px(16)),
                        tl: {
                          hd: Css.paddingRight(Css.px(16)),
                          tl: {
                            hd: Css.margin(Css.px(0)),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.medium),
                                tl: {
                                  hd: Css.color(Styles.Color.light10),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.color(Styles.Color.avoPink),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$short
                                          }, undefined, undefined, "color"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var selectedTabItem = Curry._1(Css.merge, {
      hd: tabItem,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.color(Styles.Color.avoPink),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var selectedInnerItem = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.after({
              hd: Css.display("block"),
              tl: {
                hd: Css.position("absolute"),
                tl: {
                  hd: Css.unsafe("content", "' '"),
                  tl: {
                    hd: Css.top(Css.px(24)),
                    tl: {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.height(Css.px(2)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.avoPink),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function tabName(tab) {
  switch (tab) {
    case /* Members */0 :
        return "Members";
    case /* Permissions */1 :
        return "General";
    case /* Notifications */2 :
        return "Slack Integration";
    case /* DangerZone */3 :
        return "Danger Zone";
    case /* ServiceAccounts */4 :
        return "Service Accounts";
    
  }
}

var filtersContainer = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(24), Css.px(0)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.gap(Css.px(8)),
            tl: {
              hd: Css.alignItems("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var searchInput = Curry._1(Css.style, {
      hd: Css.unsafe("all", "unset"),
      tl: {
        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
        tl: {
          hd: Css.fontSize(Styles.FontSize.regular),
          tl: {
            hd: Css.borderRadius(Styles.Border.radius),
            tl: {
              hd: Css.padding2(Css.px(9), Css.px(8)),
              tl: {
                hd: Css.width(Css.px(200)),
                tl: {
                  hd: Css.focus({
                        hd: Css.borderColor(Styles.Color.light08),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.placeholder({
                          hd: Css.color(Styles.Color.light08),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var dropdownInputStyles_0 = Css.borderColor(Styles.Color.light04);

var dropdownInputStyles_1 = {
  hd: Css.borderRadius(Styles.Border.radius),
  tl: {
    hd: Css.display("flex"),
    tl: {
      hd: Css.alignItems("center"),
      tl: {
        hd: Css.flexWrap("wrap"),
        tl: {
          hd: Css.paddingLeft(Css.px(8)),
          tl: {
            hd: Css.active({
                  hd: Css.borderColor(Styles.Color.light08),
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.focusWithin({
                    hd: Css.borderColor(Styles.Color.light08),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.height(Css.px(37)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    }
  }
};

var dropdownInputStyles = {
  hd: dropdownInputStyles_0,
  tl: dropdownInputStyles_1
};

var dropdownSearchInput_0 = Css.fontSize(Styles.FontSize.regular);

var dropdownSearchInput_1 = {
  hd: Css.marginLeft("zero"),
  tl: /* [] */0
};

var dropdownSearchInput = {
  hd: dropdownSearchInput_0,
  tl: dropdownSearchInput_1
};

var dropdownWrapper_0 = Icon.color(Styles.Color.light08);

var dropdownWrapper_1 = {
  hd: Css.hover({
        hd: Icon.color(Styles.Color.light10),
        tl: /* [] */0
      }),
  tl: /* [] */0
};

var dropdownWrapper = {
  hd: dropdownWrapper_0,
  tl: dropdownWrapper_1
};

var dropdownValues_0 = Css.marginLeft(Css.px(0));

var dropdownValues_1 = {
  hd: Css.minHeight(Css.pct(100.0)),
  tl: /* [] */0
};

var dropdownValues = {
  hd: dropdownValues_0,
  tl: dropdownValues_1
};

var dropdownItem_0 = Css.display("flex");

var dropdownItem_1 = {
  hd: Css.alignItems("center"),
  tl: {
    hd: Css.margin("zero"),
    tl: {
      hd: Css.marginBottom(Css.px(1)),
      tl: {
        hd: Css.borderRadius("zero"),
        tl: {
          hd: Css.fontSize(Styles.FontSize.small),
          tl: {
            hd: Css.transitions({
                  hd: Css_Legacy_Core.Transition.shorthand({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "background-color"),
                  tl: {
                    hd: Css_Legacy_Core.Transition.shorthand({
                          NAME: "ms",
                          VAL: Styles.Duration.$$default
                        }, undefined, undefined, "color"),
                    tl: /* [] */0
                  }
                }),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: {
                      hd: Css.selector(" .avo-select-item-icon", {
                            hd: Icon.color(Styles.Color.light10),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    }
  }
};

var dropdownItem = {
  hd: dropdownItem_0,
  tl: dropdownItem_1
};

var dropdown_0 = Css.border(Css.px(1), "solid", Styles.Color.light04);

var dropdown_1 = {
  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                NAME: "num",
                VAL: 0.10
              }))),
  tl: {
    hd: Css.overflow("hidden"),
    tl: /* [] */0
  }
};

var dropdown = {
  hd: dropdown_0,
  tl: dropdown_1
};

var dropdownItemsWrapper_0 = Css.margin("zero");

var dropdownItemsWrapper_1 = {
  hd: Css.padding("zero"),
  tl: /* [] */0
};

var dropdownItemsWrapper = {
  hd: dropdownItemsWrapper_0,
  tl: dropdownItemsWrapper_1
};

function SettingsModal$Filters(Props) {
  var availableRoles = Props.availableRoles;
  var roles = Props.roles;
  var searchString = Props.searchString;
  var setRoles = Props.setRoles;
  var setSearchString = Props.setSearchString;
  var hasCodegenAccessRole = AppFeatureFlag.useFeatureFlag("CodegenAccessRole");
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  return React.createElement("div", {
              className: filtersContainer
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: /* [] */0
                          })
                    }, React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement("label", {
                          htmlFor: "nameFilter"
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.light10,
                              children: "Search by name or email"
                            }))), React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement("input", {
                      className: searchInput,
                      id: "nameFilter",
                      placeholder: "Type name or email...",
                      type: "text",
                      value: searchString,
                      onChange: (function (e) {
                          var value = e.target.value;
                          return Curry._1(setSearchString, (function (param) {
                                        return value;
                                      }));
                        })
                    })), React.createElement("div", undefined, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: /* [] */0
                          })
                    }, React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement("label", undefined, React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.light10,
                              children: "Filter by role"
                            }))), React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement(Mantine.MultiSelect.make, {
                      data: Belt_Array.map(RoleUtils.availableRoles(hasCodegenAccessRole, workspace), (function (role) {
                              var workspaceHasRole = Belt_Array.some(availableRoles, (function (availableRole) {
                                      return Models.Role.tToJs(role) === availableRole;
                                    }));
                              return {
                                      value: Models.Role.tToJs(role),
                                      label: RoleUtils.getLabel(role, AvoLimits.ViewersCanComment.isAvailable(workspace)),
                                      disabled: !workspaceHasRole
                                    };
                            })),
                      dropdownStyles: dropdown,
                      inputStyles: dropdownInputStyles,
                      itemStyles: dropdownItem,
                      itemsWrapperStyles: dropdownItemsWrapper,
                      onChange: (function (roles) {
                          return Curry._1(setRoles, (function (param) {
                                        return roles;
                                      }));
                        }),
                      placeholder: "Choose roles...",
                      rightSection: React.createElement(Icon.make, {
                            type_: "chevronDown",
                            size: "small"
                          }),
                      rightSectionWidth: 36,
                      searchInputStyles: dropdownSearchInput,
                      value: roles,
                      valuesStyles: dropdownValues,
                      wrapperStyles: dropdownWrapper
                    })));
}

function SettingsModal(Props) {
  var onClose = Props.onClose;
  var openOnTabOpt = Props.openOnTab;
  var role = Props.role;
  var model = Props.model;
  var openBranches = Props.openBranches;
  var openOnTab = openOnTabOpt !== undefined ? openOnTabOpt : /* Members */0;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = React.useState(function () {
        return openOnTab;
      });
  var setCurrentTab = match[1];
  var currentTab = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchString = match$1[1];
  var searchString = match$1[0];
  var match$2 = React.useState(function () {
        return [];
      });
  var setRoles = match$2[1];
  var roles = match$2[0];
  var availableRoles = React.useMemo((function () {
          return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(workspace.members, (function (prim) {
                                    return prim.role;
                                  })))));
        }), [Belt_List.toArray(Belt_List.map(workspace.members, (function (prim) {
                      return prim.id;
                    }))).join(",")]);
  var users = FirebaseFetcherHooks.useUsersWithKey(Belt_List.toArray(Belt_List.map(workspace.members, (function (prim) {
                  return prim.id;
                }))), Belt_List.toArray(Belt_List.map(workspace.members, (function (member) {
                    return member.id + "-" + member.role;
                  }))).join(","));
  var numCommentOnly = Belt_List.length(Belt_List.keep(workspace.members, (function (member) {
              return Caml_obj.caml_equal(Models.Role.tFromJs(member.role), "CommentOnly");
            })));
  var filteredMembersAndUsers = React.useMemo((function () {
          if (typeof users !== "object") {
            return Belt_List.toArray(Belt_List.map(workspace.members, (function (member) {
                              return [
                                      member,
                                      "Loading"
                                    ];
                            })));
          }
          if (users.NAME === "Errored") {
            return Belt_List.toArray(Belt_List.map(workspace.members, (function (member) {
                              return [
                                      member,
                                      "NotFound"
                                    ];
                            })));
          }
          var users$1 = users.VAL;
          if (searchString.trim() !== "" || Caml_obj.caml_notequal(roles, [])) {
            return Belt_List.toArray(Belt_List.keepMap(workspace.members, (function (member) {
                              var user = Belt_Array.getBy(users$1, (function (param) {
                                      return param.id === member.id;
                                    }));
                              if (user !== undefined && (Belt_Array.some(roles, (function (role) {
                                          return role === member.role;
                                        })) || roles.length === 0) && (Fuzzaldrin.score(Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), ""), searchString.trim()) > 0.05 || Fuzzaldrin.score(Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.email), AvoConfig.anonymousUserDisplayName), searchString.trim()) > 0.05 || searchString.trim() === "")) {
                                return [
                                        member,
                                        {
                                          NAME: "Success",
                                          VAL: user
                                        }
                                      ];
                              }
                              
                            })));
          } else {
            return Belt_List.toArray(Belt_List.keepMap(workspace.members, (function (member) {
                              var user = Belt_Array.getBy(users$1, (function (param) {
                                      return param.id === member.id;
                                    }));
                              if (user !== undefined) {
                                return [
                                        member,
                                        {
                                          NAME: "Success",
                                          VAL: user
                                        }
                                      ];
                              }
                              
                            })));
          }
        }), [
        users,
        Belt_List.toArray(Belt_List.map(workspace.members, (function (prim) {
                      return prim.id;
                    }))).join(","),
        roles,
        searchString
      ]);
  React.useEffect((function () {
          AnalyticsRe.membersModalOpened(schemaBundle, schemaBundle.branchId, schemaBundle.schemaId);
          
        }), []);
  var billingStats = CloudFunctionHooks.useBillingStats(workspace.id, workspace.members);
  var handleClose = function (param) {
    AnalyticsRe.membersModalClosed(schemaBundle, schemaBundle.branchId, schemaBundle.schemaId);
    return Curry._1(onClose, undefined);
  };
  var tmp;
  if (role === "NoAccess" || role === "CommentOnly" || role === "Viewer" || role === "CodegenAccess" || role === "Editor") {
    tmp = undefined;
  } else if (typeof billingStats === "object") {
    var match$3 = billingStats.VAL;
    var limit = workspace.plan.commentOnlyMembers;
    var tmp$1;
    if (typeof limit === "number") {
      tmp$1 = limit === /* Unlimited */2 ? " with unlimited comment only seats" : "";
    } else if (limit.TAG === /* Limited */1) {
      var limit$1 = limit._0;
      tmp$1 = "and " + String(numCommentOnly > limit$1 ? limit$1 : numCommentOnly) + " out of " + (String(limit$1) + " comment only seats");
    } else {
      tmp$1 = "";
    }
    tmp = Caml_option.some(React.createElement("span", undefined, "Using " + String(match$3.currentEditorSeats) + " out of " + String(match$3.planEditorSeats) + " editor seats " + tmp$1));
  } else {
    tmp = billingStats === "Error" ? "Could not fetch plan details" : "...";
  }
  var tabs = role === "Admin" ? [
      /* Members */0,
      /* Permissions */1,
      /* Notifications */2,
      /* DangerZone */3,
      /* ServiceAccounts */4
    ] : [/* Members */0];
  var tmp$2;
  switch (currentTab) {
    case /* Members */0 :
        var workspaceLimits = AvoLimits.computeLimits(workspace, model);
        var currentUserId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
        var tmp$3;
        if (Belt_List.length(workspace.members) > 10) {
          var tmp$4;
          if (searchString !== "" || roles.length !== 0) {
            var match$4 = filteredMembersAndUsers.length;
            tmp$4 = React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light10,
                      children: match$4 !== 0 ? (
                          match$4 !== 1 ? String(filteredMembersAndUsers.length) + " members found" : "1 member found"
                        ) : "No members found. You can add them here 👇"
                    }), React.createElement(Spacer.make, {
                      width: 16
                    }), React.createElement(TextButton.make, {
                      onClick: (function (param) {
                          Curry._1(setSearchString, (function (param) {
                                  return "";
                                }));
                          return Curry._1(setRoles, (function (param) {
                                        return [];
                                      }));
                        }),
                      style: "Blue",
                      children: React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            children: "Clear filters"
                          })
                    }));
          } else {
            tmp$4 = null;
          }
          tmp$3 = React.createElement(React.Fragment, undefined, React.createElement(SettingsModal$Filters, {
                    availableRoles: availableRoles,
                    roles: roles,
                    searchString: searchString,
                    setRoles: setRoles,
                    setSearchString: setSearchString
                  }), tmp$4);
        } else {
          tmp$3 = null;
        }
        tmp$2 = React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingLeft(Css.px(16)),
                    tl: {
                      hd: Css.paddingRight(Css.px(16)),
                      tl: /* [] */0
                    }
                  })
            }, tmp$3, Belt_Array.map(filteredMembersAndUsers, (function (param) {
                    var member = param[0];
                    return React.createElement(MembersRow.make, {
                                model: model,
                                member: member,
                                workspaceLimits: workspaceLimits,
                                user: param[1],
                                viewerRole: role,
                                viewerId: currentUserId,
                                billingStats: billingStats,
                                key: member.id
                              });
                  })), React.createElement(SchemaInvites.make, {
                  viewerId: currentUserId,
                  viewerRole: role
                }));
        break;
    case /* Permissions */1 :
        tmp$2 = React.createElement(WorkspacePermissions.make, {
              onClose: onClose
            });
        break;
    case /* Notifications */2 :
        tmp$2 = React.createElement(WorkspaceNotifications.make, {
              onClose: onClose
            });
        break;
    case /* DangerZone */3 :
        tmp$2 = React.createElement(WorkspaceDangerZone.make, {
              openBranches: openBranches,
              onClose: handleClose
            });
        break;
    case /* ServiceAccounts */4 :
        tmp$2 = React.createElement(ServiceAccounts.make, {
              viewerhasEditorPermission: role === "Admin" || role === "Editor"
            });
        break;
    
  }
  return React.createElement(Modal.make, {
              title: "Workspace settings",
              description: tmp,
              background: "White",
              headerBackground: "OffWhite",
              onClose: handleClose,
              toolbar: React.createElement("div", {
                    className: tabsStyles
                  }, Belt_Array.map(tabs, (function (thisTab) {
                          return React.createElement("button", {
                                      key: tabName(thisTab),
                                      className: currentTab === thisTab ? selectedTabItem : tabItem,
                                      onClick: (function (param) {
                                          return Curry._1(setCurrentTab, (function (param) {
                                                        return thisTab;
                                                      }));
                                        })
                                    }, React.createElement("div", {
                                          className: currentTab === thisTab ? selectedInnerItem : Curry._1(Css.style, /* [] */0)
                                        }, tabName(thisTab)));
                        }))),
              footer: role === "Editor" ? (
                  currentTab >= 4 ? React.createElement(ServiceAccounts.Create.make, {}) : null
                ) : (
                  role === "Admin" ? (
                      currentTab !== 0 ? (
                          currentTab >= 4 ? React.createElement(ServiceAccounts.Create.make, {}) : null
                        ) : React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.backgroundColor(Styles.Color.light01),
                                    tl: {
                                      hd: Css.padding(Css.px(16)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.light10,
                                  children: "Add member"
                                }), React.createElement(Spacer.make, {
                                  height: 4
                                }), React.createElement(MembersInviteForm.make, {
                                  model: model
                                }))
                    ) : null
                ),
              children: tmp$2
            });
}

var make = SettingsModal;

export {
  make ,
  
}
/* tabsStyles Not a pure module */
