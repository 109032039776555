// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Parser from "../../../model/src/Parser.mjs";
import * as DiscrepancyUtils from "../DiscrepancyUtils.mjs";

var empty_branchAuditChecks = [];

var empty_existingEventNames = {};

var empty_existingPropertyNames = {};

var empty = {
  branchAuditChecks: empty_branchAuditChecks,
  branchAuditChecksCount: 0,
  branchAuditErrorCount: 0,
  existingEventNames: empty_existingEventNames,
  existingPropertyNames: empty_existingPropertyNames
};

function getBranchDiscrepancies(hasGuardrailsForAll, eventComponentsWithNames, config, mainModelToCompareTo, branchModelToCompareTo) {
  var mainModelToCompareTo$1 = Parser.parseSchemaExn(mainModelToCompareTo);
  var branchModelToCompareTo$1 = Parser.parseSchemaExn(branchModelToCompareTo);
  return DiscrepancyUtils.getBranchDiscrepancies_forWorker(hasGuardrailsForAll, eventComponentsWithNames, config, mainModelToCompareTo$1, branchModelToCompareTo$1);
}

export {
  empty ,
  getBranchDiscrepancies ,
  
}
/* Parser Not a pure module */
