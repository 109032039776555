// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Sidebar from "./Sidebar.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Timestamp from "./Timestamp.mjs";
import * as GlobalPill from "./GlobalPill.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as ContextMenu from "./ContextMenu.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as SandboxBanner from "./SandboxBanner.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as BranchStateWriter from "./BranchStateWriter.mjs";
import * as CollaboratorsView from "./CollaboratorsView.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";
import * as BranchImplementationUtils from "./branchImplementation/BranchImplementationUtils.mjs";

function containerStyle(withSandboxBanner) {
  return Curry._1(Css.style, {
              hd: Css.position("fixed"),
              tl: {
                hd: Css.top(Css.px(SandboxBanner.getHeight(withSandboxBanner))),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(0)),
                    tl: {
                      hd: Css.left(Css.px(Sidebar.sidebarWidth)),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light02),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.height(Css.px(80)),
                              tl: {
                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(20), undefined, undefined, Css.rgba(0, 0, 0, {
                                              NAME: "num",
                                              VAL: 0.05
                                            }))),
                                tl: {
                                  hd: Css.zIndex(1),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var mainInfoStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: {
            hd: Css.paddingLeft(Css.px(20)),
            tl: {
              hd: Css.minWidth(Css.px(0)),
              tl: {
                hd: Css.flexShrink(10000.0),
                tl: {
                  hd: Css.marginTop(Css.px(20)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function statusPillStyles(status) {
  var tmp;
  if (typeof status === "number") {
    tmp = Styles.Color.light03;
  } else {
    switch (status.TAG | 0) {
      case /* Merged */1 :
          tmp = Styles.Color.paleBlue;
          break;
      case /* Closed */2 :
          tmp = Styles.Color.paleRed;
          break;
      case /* ReadyForReview */4 :
          tmp = Styles.Color.palePurple;
          break;
      case /* ChangesRequested */5 :
          tmp = Styles.Color.paleOrange;
          break;
      case /* Approved */6 :
          tmp = Styles.Color.paleGreen;
          break;
      default:
        tmp = Styles.Color.light03;
    }
  }
  var tmp$1;
  if (typeof status === "number") {
    tmp$1 = Styles.Color.light11;
  } else {
    switch (status.TAG | 0) {
      case /* Merged */1 :
          tmp$1 = Styles.Color.deepBlue;
          break;
      case /* Closed */2 :
          tmp$1 = Styles.Color.deepRed;
          break;
      case /* ReadyForReview */4 :
          tmp$1 = Styles.Color.deepPurple;
          break;
      case /* ChangesRequested */5 :
          tmp$1 = Styles.Color.deepOrange;
          break;
      case /* Approved */6 :
          tmp$1 = Styles.Color.deepGreen;
          break;
      default:
        tmp$1 = Styles.Color.light11;
    }
  }
  return Curry._1(Css.style, {
              hd: Css.marginRight(Css.px(4)),
              tl: {
                hd: Css.borderRadius(Css.px(32)),
                tl: {
                  hd: Css.paddingLeft(Css.px(8)),
                  tl: {
                    hd: Css.paddingRight(Css.px(8)),
                    tl: {
                      hd: Css.paddingTop(Css.px(4)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(4)),
                        tl: {
                          hd: Css.backgroundColor(tmp),
                          tl: {
                            hd: Css.color(tmp$1),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var tabs = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: /* [] */0
    });

function tab(isCurrent) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.paddingBottom(Css.px(5)),
                            tl: {
                              hd: Css.color(isCurrent ? Styles.Color.avoPink : Styles.Color.light10),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$short
                                    }, undefined, undefined, "color"),
                                tl: {
                                  hd: Css.marginRight(Css.px(16)),
                                  tl: {
                                    hd: Css.lastChild({
                                          hd: Css.marginRight(Css.px(0)),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.color(Styles.Color.avoPink),
                                            tl: {
                                              hd: Css.before({
                                                    hd: Css.color(Styles.Color.light10),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.selector(":hover path", {
                                              hd: Css_Legacy_Core.SVG.fill(Styles.Color.avoPink),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.backgroundColor("transparent"),
                                          tl: {
                                            hd: Css.after({
                                                  hd: Css.unsafe("content", "' '"),
                                                  tl: {
                                                    hd: Css.position("absolute"),
                                                    tl: {
                                                      hd: Css.bottom(Css.px(-1)),
                                                      tl: {
                                                        hd: Css.right(Css.px(0)),
                                                        tl: {
                                                          hd: Css.left(Css.px(0)),
                                                          tl: {
                                                            hd: Css.height(Css.px(2)),
                                                            tl: {
                                                              hd: Css.backgroundColor(isCurrent ? Styles.Color.avoPink : "transparent"),
                                                              tl: {
                                                                hd: Css.transition({
                                                                      NAME: "ms",
                                                                      VAL: Styles.Duration.$$short
                                                                    }, undefined, undefined, "background-color"),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var contextMenu = Curry._1(Css.style, {
      hd: Css.height(Css.pct(100.0)),
      tl: {
        hd: Css.padding(Css.px(16)),
        tl: {
          hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light02),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function BranchHeader(Props) {
  var branchId = Props.branchId;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var branchModel = Props.branchModel;
  var changeStatus = Props.changeStatus;
  var masterModel = Props.masterModel;
  var branchOpenAction = Props.branchOpenAction;
  var sourceUpdates = Props.sourceUpdates;
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var hasRefreshBranchMenuFeatureFlag = AppFeatureFlag.useFeatureFlag("RefreshBranchStateMenuOption");
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var sendActions = SendActionsContext.use(undefined);
  var isGlobalRequirementsBranch = GlobalRequirementsUtils.isGlobalBranch(branchId);
  var branchName$1 = isGlobalRequirementsBranch ? GlobalRequirementsUtils.getNormalizedName(branchId) : branchName;
  var totalCodeChanges = Belt_List.length(Belt_List.flatten(Belt_List.map(sourceUpdates.sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("any", undefined, undefined, param[0].id, param[1]);
                }))));
  var lastLifecycleAction;
  if (typeof branchStatus === "number") {
    lastLifecycleAction = branchOpenAction;
  } else {
    switch (branchStatus.TAG | 0) {
      case /* Merged */1 :
      case /* Closed */2 :
          lastLifecycleAction = branchStatus._0;
          break;
      default:
        lastLifecycleAction = branchOpenAction;
    }
  }
  var schemaBundle = SchemaBundleContext.use(undefined);
  var userId = lastLifecycleAction.createdBy;
  var user = FirebaseFetcherHooks.useUser(userId);
  var match = AvoLimits.ApprovalWorkflow.computeAvailability(workspace);
  var approvalWorkflow = match === "AvailableDuringTrial" || match === "Available";
  var handleDelete = function (param) {
    if (window.confirm("Are you sure you want to delete the " + branchName$1 + " branch?")) {
      Router.Schema.pushSchemaRoute("master", undefined, "dashboard");
      return Curry.app(sendActions, [
                  "Master",
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      return AnalyticsRe.branchDeleted(schemaBundle, NamedBranch.getId(branch), schemaBundle.schemaId);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "CloseBranch",
                        VAL: [
                          branchId,
                          branchName$1
                        ]
                      },
                      {
                        branchId: branchId,
                        branchQuery: branchId
                      }
                    ]]
                ]);
    }
    
  };
  var handleSyncBranchState = function (param) {
    BranchStateWriter.refreshFullBranchState(schemaBundle.schemaId, branchId, branchModel, masterModel);
    
  };
  var branchStatus$1;
  if (typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6) {
    branchStatus$1 = branchStatus;
  } else {
    var approvals = branchStatus._0;
    branchStatus$1 = Belt_List.length(approvals) < workspace.settings.numRequiredApprovals ? ({
          TAG: /* ReadyForReview */4,
          _0: Belt_List.headExn(approvals)
        }) : branchStatus;
  }
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var tmp;
  if (typeof branchStatus$1 === "number") {
    tmp = branchStatus$1 === /* Master */0 ? null : "Loading";
  } else {
    switch (branchStatus$1.TAG | 0) {
      case /* Merged */1 :
          tmp = "Merged";
          break;
      case /* Closed */2 :
          tmp = "Deleted";
          break;
      case /* Open */0 :
      case /* Draft */3 :
          tmp = approvalWorkflow ? "Draft" : "Open";
          break;
      case /* ReadyForReview */4 :
          tmp = "Ready for review";
          break;
      case /* ChangesRequested */5 :
          tmp = "Changes requested";
          break;
      case /* Approved */6 :
          tmp = "Approved";
          break;
      
    }
  }
  var tmp$1;
  if (typeof branchStatus$1 === "number") {
    tmp$1 = branchStatus$1 === /* Master */0 ? null : "Loading...";
  } else {
    switch (branchStatus$1.TAG | 0) {
      case /* Merged */1 :
          tmp$1 = "Merged by ";
          break;
      case /* Closed */2 :
          tmp$1 = "Deleted by ";
          break;
      case /* Open */0 :
      case /* Draft */3 :
      case /* ReadyForReview */4 :
      case /* ChangesRequested */5 :
      case /* Approved */6 :
          tmp$1 = "Opened by ";
          break;
      
    }
  }
  var tmp$2;
  if (isGlobalWorkspace) {
    tmp$2 = null;
  } else {
    var tmp$3;
    if (typeof schemaRoute === "object" && schemaRoute.NAME === "diff") {
      var match$1 = schemaRoute.VAL;
      tmp$3 = typeof match$1 === "object" ? match$1.NAME === "implementation" : false;
    } else {
      tmp$3 = false;
    }
    var tmp$4 = false;
    if (totalCodeChanges > 0) {
      var tmp$5;
      if (typeof schemaRoute === "object" && schemaRoute.NAME === "diff") {
        var match$2 = schemaRoute.VAL;
        tmp$5 = typeof match$2 === "object" ? match$2.NAME === "implementation" : false;
      } else {
        tmp$5 = false;
      }
      tmp$4 = tmp$5;
    }
    tmp$2 = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: /* [] */0
                }
              })
        }, React.createElement("button", {
              className: tab(tmp$3),
              id: "code-changes-tab",
              onClick: (function (param) {
                  return Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                                  NAME: "diff",
                                  VAL: {
                                    NAME: "implementation",
                                    VAL: "overview"
                                  }
                                }));
                })
            }, React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  weight: "Semi",
                  children: "Code Changes"
                }), React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(4)),
                        tl: {
                          hd: Css.padding2(Css.px(1), Css.px(4)),
                          tl: {
                            hd: Css.backgroundColor(tmp$4 ? Styles.Color.avoPink : Styles.Color.pink),
                            tl: {
                              hd: Css.borderRadius(Css.px(16)),
                              tl: {
                                hd: Css.color(Styles.Color.white),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Tiny",
                      weight: "Semi",
                      singleLine: true,
                      children: String(totalCodeChanges)
                    }))), changeStatus === "loading" ? React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.marginLeft(Css.px(-8)),
                      tl: {
                        hd: Css.marginTop(Css.px(-6)),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement(Mantine.Loader.make, {
                    variant: "oval",
                    size: 10,
                    color: Styles.Color.avoPink
                  })) : null);
  }
  var isClosedWithRefreshFlag;
  if (typeof branchStatus$1 === "number") {
    isClosedWithRefreshFlag = branchStatus$1 === /* Master */0 ? hasRefreshBranchMenuFeatureFlag : false;
  } else {
    switch (branchStatus$1.TAG | 0) {
      case /* Merged */1 :
      case /* Closed */2 :
          isClosedWithRefreshFlag = hasRefreshBranchMenuFeatureFlag;
          break;
      default:
        isClosedWithRefreshFlag = false;
    }
  }
  var isOpen;
  if (typeof branchStatus$1 === "number") {
    isOpen = false;
  } else {
    switch (branchStatus$1.TAG | 0) {
      case /* Merged */1 :
      case /* Closed */2 :
          isOpen = false;
          break;
      default:
        isOpen = true;
    }
  }
  return React.createElement("header", {
              className: containerStyle(workspace.isSandbox)
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.flexGrow(1.0),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: mainInfoStyles
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.gap(Css.px(6)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, React.createElement(Icon.make, {
                                  type_: "branch",
                                  size: "small",
                                  color: Styles.Color.light09
                                }), React.createElement($$Text.make, {
                                  element: "Span",
                                  size: "Large",
                                  weight: "Semi",
                                  singleLine: true,
                                  color: Styles.Color.light12,
                                  children: branchName$1
                                }), isGlobalRequirementsBranch ? React.createElement(GlobalPill.make, {}) : null, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.gap(Css.px(4)),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, React.createElement("div", {
                                      className: statusPillStyles(branchStatus$1)
                                    }, React.createElement($$Text.make, {
                                          size: "Tiny",
                                          weight: "Medium",
                                          lines: 1,
                                          children: tmp
                                        })), React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.flexShrink(0.0),
                                            tl: /* [] */0
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.light10,
                                          children: null
                                        }, tmp$1, React.createElement($$Text.make, {
                                              element: "Span",
                                              size: "Small",
                                              weight: "Semi",
                                              children: typeof user === "object" && user.NAME === "Success" ? AvoConfig.getUserDisplayName(user.VAL) : "Member"
                                            }), " ", React.createElement(Timestamp.make, {
                                              date: lastLifecycleAction.createdAt
                                            }))))), React.createElement(Spacer.make, {
                              height: 12
                            }), React.createElement("div", {
                              className: tabs
                            }, React.createElement("button", {
                                  className: tab(Caml_obj.caml_equal(schemaRoute, {
                                            NAME: "diff",
                                            VAL: "index"
                                          })),
                                  onClick: (function (param) {
                                      return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                                  NAME: "diff",
                                                  VAL: "index"
                                                });
                                    })
                                }, React.createElement($$Text.make, {
                                      element: "Span",
                                      size: "Small",
                                      weight: "Semi",
                                      children: "Tracking Plan Changes"
                                    })), tmp$2)), React.createElement(Spacer.make, {
                          width: 8,
                          grow: 1.0
                        }), React.createElement(CollaboratorsView.make, {
                          userId: userId,
                          branchId: branchId,
                          sendActions: sendActions
                        }), isOpen || isClosedWithRefreshFlag ? React.createElement("div", {
                            className: contextMenu
                          }, React.createElement(ContextMenu.V2.make, {
                                withBorder: true,
                                position: "bottom-end",
                                children: null
                              }, isClosedWithRefreshFlag ? React.createElement(ContextMenu.V2.Item.make, {
                                      label: "Sync Branch State",
                                      onClick: handleSyncBranchState
                                    }) : null, isOpen ? React.createElement(ContextMenu.V2.Item.make, {
                                      label: "Delete Branch",
                                      onClick: handleDelete,
                                      style: "Warning"
                                    }) : null)) : null)));
}

var branchHeaderHeight = 80;

var make = BranchHeader;

export {
  branchHeaderHeight ,
  make ,
  
}
/* mainInfoStyles Not a pure module */
