// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Issues from "../../shared/models/Issues.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as AvoState from "./AvoState.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as TextInput from "./TextInput.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as InputLabel from "./InputLabel.mjs";
import Downloadjs from "downloadjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IssuesTable from "./inspector/IssuesTable.mjs";
import * as ExportFormat from "../../shared/models/ExportFormat.mjs";
import * as Core from "@mantine/core";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as ServerJs from "react-dom/server.js";
import * as InspectorIssuesStore from "./inspector/InspectorIssuesStore.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";
import * as InspectorIssuesFilterHooks from "./inspector/InspectorIssuesFilterHooks.mjs";
import * as InspectorIssuesFilterUtils from "../../shared/utils/InspectorIssuesFilterUtils.mjs";
import * as InspectorIssuesView__Table from "./InspectorIssuesView__Table.mjs";

function InspectorIssuesView__Buttons$DuplicateButton(Props) {
  var filteredIssuesCount = Props.filteredIssuesCount;
  var sendActions = SendActionsContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match.orderedBy;
  var filters = match.filters;
  var addToast = Toast.useAddToast(undefined);
  var match$1 = Hooks.useDisclosure(false);
  var actions = match$1[1];
  var opened = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setNameInput = match$2[1];
  var nameInput = match$2[0];
  var duplicate = RescriptReactRouter.useUrl(undefined, undefined).search === "";
  var match$3 = React.useState(function () {
        return false;
      });
  var setIsSaving = match$3[1];
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var match$4 = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var status = match$4[0];
  var handleSave = function (param) {
    Curry._1(setIsSaving, (function (param) {
            return true;
          }));
    return FirebaseUtils.createSavedView(sendActions, workspace.id, nameInput, orderedBy, filters, (function (id) {
                  Curry._1(setIsSaving, (function (param) {
                          return false;
                        }));
                  Curry._1(setNameInput, (function (param) {
                          return "";
                        }));
                  Curry._1(actions.close, undefined);
                  Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                            NAME: "inspector",
                            VAL: {
                              NAME: "savedView",
                              VAL: id
                            }
                          }));
                  Curry._1(addToast, {
                        message: duplicate ? "View Duplicated!" : "View Saved!",
                        toastType: /* Success */0
                      });
                  return AnalyticsRe.inspectorSavedViewInteraction(schemaBundle, AnalyticsRe.Bundle.inspectorSavedViews(nameInput, id, "Creator", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) : -1), InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), filteredIssuesCount, "SavedView", InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), duplicate ? "Duplicate" : "SaveViewAs", schemaBundle.schemaId);
                }), (function (err) {
                  Curry._1(setIsSaving, (function (param) {
                          return false;
                        }));
                  Curry._1(setNameInput, (function (param) {
                          return "";
                        }));
                  return Curry._1(addToast, {
                              message: err.RE_EXN_ID === AvoState.PermissionsException ? (
                                  duplicate ? "Permissions needed to duplicate view..." : "Permissions needed to save view..."
                                ) : (
                                  duplicate ? "Failed to duplicate view..." : "Failed to save view..."
                                ),
                              toastType: /* Error */1
                            });
                }));
  };
  var disabled = nameInput === "";
  var tmp = {
    disabled: Caml_obj.caml_equal(filters, []),
    onClick: (function (param) {
        return Curry._1(actions.toggle, undefined);
      }),
    style: "outline"
  };
  var tmp$1 = duplicate ? "copy" : undefined;
  if (tmp$1 !== undefined) {
    tmp.icon = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = duplicate ? undefined : "Save As...";
  if (tmp$2 !== undefined) {
    tmp.label = tmp$2;
  }
  return React.createElement(SimpleTooltip.make, {
              disabled: opened,
              tooltip: "Duplicate view",
              children: React.createElement(Mantine.Menu.make, {
                    onChange: (function (param) {
                        Curry._1(setNameInput, (function (param) {
                                return "";
                              }));
                        return Curry._1(actions.close, undefined);
                      }),
                    opened: opened,
                    position: "bottom-end",
                    children: null
                  }, React.createElement(Core.Menu.Target, {
                        children: React.createElement(Button.make, tmp)
                      }), React.createElement(Mantine.Menu.Dropdown.make, {
                        additionalStyles: {
                          hd: Css.padding(Css.px(20)),
                          tl: {
                            hd: Css.maxWidth(Css.px(300)),
                            tl: /* [] */0
                          }
                        },
                        children: null
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: duplicate ? "Duplicate filtered view" : "Save filtered view"
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light10,
                            children: duplicate ? "Duplicate this filtered view and make changes based on the existing filters." : "Save this filtered view to quickly access it when you need it."
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), React.createElement(InputLabel.make, {
                            htmlFor: "name",
                            children: "Saved view name"
                          }), React.createElement(Spacer.make, {
                            height: 4
                          }), React.createElement(TextInput.make, {
                            autoFocus: true,
                            id: "name",
                            onChange: (function (name) {
                                return Curry._1(setNameInput, (function (param) {
                                              return name;
                                            }));
                              }),
                            placeholder: "My saved view",
                            stretch: true,
                            onEnter: (function (param) {
                                if (disabled) {
                                  return ;
                                } else {
                                  return handleSave(undefined);
                                }
                              }),
                            value: nameInput
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("column"),
                                    tl: {
                                      hd: Css.justifyContent("stretch"),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(Button.make, {
                                disabled: disabled,
                                label: duplicate ? "Duplicate filtered view" : "Save filtered view",
                                loading: match$3[0],
                                onClick: (function (param) {
                                    return handleSave(undefined);
                                  })
                              }))))
            });
}

var DuplicateButton = {
  make: InspectorIssuesView__Buttons$DuplicateButton
};

function InspectorIssuesView__Buttons$SaveButton(Props) {
  var target = Props.target;
  var filteredIssuesCount = Props.filteredIssuesCount;
  var positionOpt = Props.position;
  var currentSavedView = Props.currentSavedView;
  var position = positionOpt !== undefined ? positionOpt : "bottom-end";
  var sendActions = SendActionsContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match.orderedBy;
  var filters = match.filters;
  var addToast = Toast.useAddToast(undefined);
  var match$1 = Hooks.useDisclosure(false);
  var actions = match$1[1];
  var opened = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setNameInput = match$2[1];
  var nameInput = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setIsSaving = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setIsResaving = match$4[1];
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var match$5 = InspectorIssuesFilterHooks.useDraftState(undefined);
  var isDraft = match$5.isDraft;
  var match$6 = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var status = match$6[0];
  React.useEffect((function () {
          if (opened) {
            AnalyticsRe.inspectorSavedViewInitiated(schemaBundle, InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), filteredIssuesCount, "SavedView", InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), schemaBundle.schemaId);
          }
          
        }), [opened]);
  var handleSave = function (param) {
    if (currentSavedView !== undefined) {
      Curry._1(setIsSaving, (function (param) {
              return true;
            }));
      var viewerId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
      return FirebaseUtils.updateSavedView(sendActions, workspace.id, {
                  id: currentSavedView.id,
                  name: currentSavedView.name,
                  createdBy: currentSavedView.createdBy,
                  createdAt: currentSavedView.createdAt,
                  lastModifiedBy: currentSavedView.lastModifiedBy,
                  lastModifiedAt: currentSavedView.lastModifiedAt,
                  filters: filters,
                  order: orderedBy,
                  isArchived: currentSavedView.isArchived
                }, viewerId, (function (param) {
                    Curry._1(actions.close, undefined);
                    Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                              NAME: "inspector",
                              VAL: {
                                NAME: "savedView",
                                VAL: currentSavedView.id
                              }
                            }));
                    Curry._1(addToast, {
                          message: "View Saved",
                          toastType: /* Success */0
                        });
                    Curry._1(setIsResaving, (function (param) {
                            return false;
                          }));
                    Curry._1(setIsSaving, (function (param) {
                            return false;
                          }));
                    Curry._1(setNameInput, (function (param) {
                            return "";
                          }));
                    return AnalyticsRe.inspectorSavedViewInteraction(schemaBundle, AnalyticsRe.Bundle.inspectorSavedViews(currentSavedView.name, currentSavedView.id, viewerId === currentSavedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) : -1), InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), filteredIssuesCount, "SavedView", InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), "Save", schemaBundle.schemaId);
                  }), (function (err) {
                    Curry._1(addToast, {
                          message: err.RE_EXN_ID === AvoState.PermissionsException ? "Permissions needed to save view..." : "Failed to save view...",
                          toastType: /* Error */1
                        });
                    Curry._1(setIsResaving, (function (param) {
                            return false;
                          }));
                    Curry._1(setIsSaving, (function (param) {
                            return false;
                          }));
                    return Curry._1(setNameInput, (function (param) {
                                  return "";
                                }));
                  }));
    }
    Curry._1(setIsSaving, (function (param) {
            return true;
          }));
    return FirebaseUtils.createSavedView(sendActions, workspace.id, nameInput, orderedBy, filters, (function (id) {
                  Curry._1(actions.close, undefined);
                  Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                            NAME: "inspector",
                            VAL: {
                              NAME: "savedView",
                              VAL: id
                            }
                          }));
                  Curry._1(addToast, {
                        message: "View Saved",
                        toastType: /* Success */0
                      });
                  AnalyticsRe.inspectorSavedViewCreated(schemaBundle, AnalyticsRe.Bundle.inspectorSavedViews(nameInput, id, "Creator", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) + 1 | 0 : -1), InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), filteredIssuesCount, "SavedView", InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), schemaBundle.schemaId, schemaBundle.schemaId, schemaBundle.schemaId, typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) + 1 | 0 : -1);
                  Curry._1(setIsResaving, (function (param) {
                          return false;
                        }));
                  Curry._1(setIsSaving, (function (param) {
                          return false;
                        }));
                  return Curry._1(setNameInput, (function (param) {
                                return "";
                              }));
                }), (function (param) {
                  Curry._1(addToast, {
                        message: "Failed to save view...",
                        toastType: /* Error */1
                      });
                  Curry._1(setIsResaving, (function (param) {
                          return false;
                        }));
                  Curry._1(setIsSaving, (function (param) {
                          return false;
                        }));
                  return Curry._1(setNameInput, (function (param) {
                                return "";
                              }));
                }));
  };
  var disabled = nameInput === "";
  return React.createElement(SimpleTooltip.make, {
              disabled: isDraft,
              tooltip: "No changes to save",
              children: React.createElement(Mantine.Menu.make, {
                    onChange: (function (param) {
                        Curry._1(setNameInput, (function (param) {
                                return "";
                              }));
                        return Curry._1(actions.close, undefined);
                      }),
                    opened: opened,
                    position: position,
                    children: null
                  }, React.createElement(Core.Menu.Target, {
                        children: target !== undefined ? React.cloneElement(Caml_option.valFromOption(target), {
                                onClick: (function (param) {
                                    if (currentSavedView !== undefined) {
                                      Curry._1(setIsResaving, (function (param) {
                                              return true;
                                            }));
                                      return handleSave(undefined);
                                    } else {
                                      return Curry._1(actions.toggle, undefined);
                                    }
                                  })
                              }) : React.createElement("div", undefined, React.createElement(Button.make, {
                                    disabled: !isDraft,
                                    id: "save-view-for-issues",
                                    label: "Save View",
                                    loading: match$4[0],
                                    onClick: (function (param) {
                                        if (currentSavedView !== undefined) {
                                          Curry._1(setIsResaving, (function (param) {
                                                  return true;
                                                }));
                                          return handleSave(undefined);
                                        } else {
                                          return Curry._1(actions.toggle, undefined);
                                        }
                                      })
                                  }))
                      }), React.createElement(Mantine.Menu.Dropdown.make, {
                        additionalStyles: {
                          hd: Css.maxWidth(Css.px(300)),
                          tl: /* [] */0
                        },
                        children: React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding(Css.px(20)),
                                    tl: /* [] */0
                                  }),
                              id: "save-filtered-view-dropdown"
                            }, React.createElement($$Text.make, {
                                  size: "Medium",
                                  weight: "Semi",
                                  color: Styles.Color.light11,
                                  children: "Save filtered view"
                                }), React.createElement(Spacer.make, {
                                  height: 12
                                }), React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light10,
                                  children: "Save this filtered view to quickly access it when you need it."
                                }), React.createElement(Spacer.make, {
                                  height: 20
                                }), React.createElement(TextInput.make, {
                                  autoFocus: true,
                                  onChange: (function (name) {
                                      return Curry._1(setNameInput, (function (param) {
                                                    return name;
                                                  }));
                                    }),
                                  placeholder: "My New View",
                                  stretch: true,
                                  onEnter: (function (param) {
                                      if (disabled) {
                                        return ;
                                      } else {
                                        return handleSave(undefined);
                                      }
                                    }),
                                  value: nameInput
                                }), React.createElement(Spacer.make, {
                                  height: 12
                                }), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.flexDirection("column"),
                                          tl: {
                                            hd: Css.justifyContent("stretch"),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, React.createElement(Button.make, {
                                      disabled: disabled,
                                      id: "save-filtered-view-confirm-button",
                                      label: "Save filtered view",
                                      loading: match$3[0],
                                      onClick: handleSave
                                    })))
                      }))
            });
}

var SaveButton = {
  make: InspectorIssuesView__Buttons$SaveButton
};

function getColumnName(column) {
  return column.VAL[0].name;
}

function extractText(node) {
  var extractText$1 = function (output, node) {
    var match = node.children.length;
    if (match !== 0) {
      var textContent = node.textContent.trim();
      var childText = Belt_Array.map(node.children, (function (childNode) {
                return childNode.textContent.trim();
              })).join("");
      if (textContent === childText) {
        return Belt_Array.concatMany([
                    output,
                    Belt_Array.concatMany(Belt_Array.map(node.children, (function (childNode) {
                                return extractText$1([], childNode);
                              })))
                  ]);
      } else {
        return Belt_Array.concatMany([
                    output,
                    [textContent.replace(childText, "")],
                    Belt_Array.concatMany(Belt_Array.map(node.children, (function (childNode) {
                                return extractText$1([], childNode);
                              })))
                  ]);
      }
    }
    var textContent$1 = node.textContent.trim();
    if (textContent$1 === "") {
      return output;
    } else {
      return Belt_Array.concat(output, [textContent$1]);
    }
  };
  return extractText$1([], node);
}

function getCell(column, issue) {
  var re = /\n+/g;
  var tmp;
  if (column.NAME === "component") {
    var html = ServerJs.renderToString(Curry._1(column.VAL[1], issue));
    var element = document.createElement("div");
    element.innerHTML = html.trim();
    var element$1 = Belt_Array.get(element.children, 0);
    tmp = element$1 !== undefined ? extractText(Caml_option.valFromOption(element$1)).join(",") : "";
  } else {
    tmp = Curry._1(column.VAL[1], issue);
  }
  return tmp.replace(re, ",");
}

function getIssue(issue, columns) {
  return Belt_Array.map(columns, (function (column) {
                  return "\"" + getCell(column, issue) + "\"";
                })).join(",");
}

function getCsv(issues, columns) {
  var header = Belt_Array.map(columns, (function (column) {
            return "\"" + column.VAL[0].name + "\"";
          })).join(",") + "\n";
  var issues$1 = Belt_Array.map(issues, (function (issue) {
            return getIssue(issue, columns);
          })).join("\n");
  return header + issues$1;
}

function handleExport(columns, issues, orderBy, viewName) {
  var orderedIssues;
  if (orderBy !== undefined) {
    var compare = Curry._2(IssuesTable.getColumnCompareFromId, columns, orderBy[0]);
    if (compare !== undefined) {
      var compare$1 = orderBy[1] === "asc" ? Curry.__2(compare) : (function (a, b) {
            return Curry._2(compare, b, a);
          });
      orderedIssues = Belt_SortArray.stableSortBy(issues, compare$1);
    } else {
      orderedIssues = issues;
    }
  } else {
    orderedIssues = issues;
  }
  var csv = getCsv(orderedIssues, columns);
  var match = ExportFormat.toHeader(/* Csv */2);
  var mimeType = match[0];
  Downloadjs(new Blob([csv], {
            encoding: "UTF-8",
            type: mimeType
          }), viewName + "." + match[1], mimeType);
  
}

function InspectorIssuesView__Buttons$ExportButton(Props) {
  var status = Props.status;
  var match = InspectorIssuesStore.useFilteredIssues(undefined, undefined, undefined);
  var filteredIssues = match.filteredIssues;
  var match$1 = InspectorIssuesFilterHooks.useFilters(undefined);
  var filters = match$1.filters;
  var orderBy = InspectorIssuesFilterHooks.useOrderedBy(undefined);
  var match$2 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var savedView = match$2.savedView;
  var match$3 = InspectorIssuesStore.useAppVersionsInSyncWithIssues(3, undefined, undefined);
  var appVersions = match$3[0];
  var columns = React.useMemo((function () {
          var match = Belt_Array.get(InspectorIssuesFilterUtils.getFilters(filters, "release"), 0);
          return InspectorIssuesView__Table.getColumns(appVersions, match !== undefined && typeof match === "object" && match.NAME === "release" ? match.VAL : undefined, status);
        }), [
        appVersions,
        filters
      ]);
  return React.createElement(SimpleTooltip.make, {
              tooltip: "Use to export the issues below into CSV format",
              children: React.createElement(Button.make, {
                    icon: "share",
                    label: "Export as CSV",
                    onClick: (function (param) {
                        return handleExport(columns, Curry._1(Issues.toArray, filteredIssues), orderBy, Belt_Option.mapWithDefault(savedView, "issues", (function (view) {
                                          return view.name;
                                        })));
                      }),
                    style: "outline"
                  })
            });
}

var ExportButton = {
  getColumnName: getColumnName,
  extractText: extractText,
  getCell: getCell,
  getIssue: getIssue,
  getCsv: getCsv,
  handleExport: handleExport,
  make: InspectorIssuesView__Buttons$ExportButton
};

function InspectorIssuesView__Buttons$CopyButton(Props) {
  var savedView = Props.savedView;
  var issueCount = Props.issueCount;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match.orderedBy;
  var filters = match.filters;
  var addToast = Toast.useAddToast(undefined);
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var match$1 = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var status = match$1[0];
  var handleCopy = function (param) {
    CopyTextToClipboard(window.location.href);
    Curry._1(addToast, {
          message: "Link copied!",
          toastType: /* Success */0
        });
    return Belt_Option.forEach(savedView, (function (savedView) {
                  return AnalyticsRe.inspectorSavedViewInteraction(schemaBundle, AnalyticsRe.Bundle.inspectorSavedViews(savedView.name, savedView.id, Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid === savedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) : -1), InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), issueCount, "SavedView", InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), "CopyShareLink", schemaBundle.schemaId);
                }));
  };
  return React.createElement(SimpleTooltip.make, {
              tooltip: "Copy link to this view",
              children: React.createElement(Button.make, {
                    icon: "link",
                    onClick: handleCopy,
                    size: "small",
                    style: "outline"
                  })
            });
}

var CopyButton = {
  make: InspectorIssuesView__Buttons$CopyButton
};

var Table;

export {
  Table ,
  DuplicateButton ,
  SaveButton ,
  ExportButton ,
  CopyButton ,
  
}
/* Css Not a pure module */
