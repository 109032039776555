// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import Shortid from "shortid";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ContextBox from "./ContextBox.mjs";
import * as MetricsRow from "./MetricsRow.mjs";
import * as StateTypes from "./StateTypes.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as MetricsEmpty from "./emptystates/MetricsEmpty.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var root = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: /* [] */0
        }
      }
    });

function listBox(metricOpen) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems("stretch"),
                  tl: {
                    hd: Css.width(Css.pct(metricOpen ? 40 : 100)),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var header = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.left(Css.px(0)),
            tl: {
              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
              tl: {
                hd: Css.borderTopLeftRadius(Styles.Border.radius),
                tl: {
                  hd: Css.borderTopRightRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var headerTop = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("flexStart"),
          tl: {
            hd: Css.height(Css.px(60)),
            tl: {
              hd: Css.paddingRight(Css.px(16)),
              tl: {
                hd: Css.paddingLeft(Css.px(20)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var headerBottom = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(4)),
      tl: {
        hd: Css.paddingBottom(Css.px(4)),
        tl: {
          hd: Css.paddingLeft(Css.px(20)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light02),
            tl: /* [] */0
          }
        }
      }
    });

var sortingPopup = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(0)),
        tl: {
          hd: Css.bottom(Css.px(0)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.flexDirection("row"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("flexStart"),
                  tl: {
                    hd: Css.width(Css.px(250)),
                    tl: {
                      hd: Css.padding(Css.px(15)),
                      tl: {
                        hd: Css.transform(Css.translateY(Css.pct(100))),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.white),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function listScroller(role) {
  return Curry._1(Css.style, {
              hd: Css.maxHeight({
                    NAME: "subtract",
                    VAL: [
                      Css.vh(100),
                      Css.px(98)
                    ]
                  }),
              tl: {
                hd: Css.marginTop(Css.px(RoleUtils.canEdit(role) ? 86 : 26)),
                tl: {
                  hd: Css.overflow("auto"),
                  tl: {
                    hd: Css.width(Css.pct(100.0)),
                    tl: {
                      hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.borderBottomRightRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.paddingBottom(Css.px(15)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

var list = Curry._1(Css.style, {
      hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
      tl: {
        hd: Css.borderTopWidth(Css.px(0)),
        tl: {
          hd: Css.borderBottomRightRadius(Styles.Border.radius),
          tl: {
            hd: Css.borderBottomLeftRadius(Styles.Border.radius),
            tl: {
              hd: Css.overflow("hidden"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var category = Curry._1(Css.style, {
      hd: Css.overflow("hidden"),
      tl: {
        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: {
            hd: Css.marginTop(Css.px(10)),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  root: root,
  listBox: listBox,
  header: header,
  headerTop: headerTop,
  headerBottom: headerBottom,
  sortingPopup: sortingPopup,
  listScroller: listScroller,
  list: list,
  category: category
};

function Metrics(Props) {
  var model = Props.model;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var globalState = GlobalStateContext.use(undefined);
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  var handleAddMetric = function (param) {
    var metricId = Shortid();
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    Router.Schema.pushDrawerItem(undefined, undefined, {
                          NAME: "metric",
                          VAL: [
                            metricId,
                            undefined
                          ]
                        });
                    return AnalyticsRe.metricInitiated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), metricId, "", NamedBranch.getId(branch), schemaBundle.schemaId);
                  }),
                undefined,
                [[
                    {
                      NAME: "AddMetric",
                      VAL: metricId
                    },
                    {
                      metricId: metricId,
                      metricQuery: metricId
                    }
                  ]]
              ]);
  };
  var handleAddCategory = function (param) {
    Curry._1(globalSend, {
          TAG: /* OpenModal */4,
          _0: {
            NAME: "CreateCategory",
            VAL: [
              undefined,
              undefined
            ]
          }
        });
    return AnalyticsRe.categoryInitiated(schemaBundle, schemaBundle.branchId, schemaBundle.schemaId);
  };
  var categorisedMetricIds = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.mapU(model.goals, (function (goal) {
                      return goal.metrics;
                    })))));
  var uncategorizedMetrics = Belt_List.keepU(model.metrics, (function (param) {
          return !Belt_SetString.has(categorisedMetricIds, param.id);
        }));
  var maybeOpenMetric = Belt_Option.flatMapU(Belt_List.get(drawerItems, 0), (function (item) {
          if (typeof item === "object" && item.NAME === "metric") {
            return item;
          }
          
        }));
  if (model.metrics === /* [] */0) {
    return React.createElement(MetricsEmpty.make, {
                sendActions: sendActions,
                role: role
              });
  }
  var match = globalState.metricsSort;
  return React.createElement("div", {
              className: root
            }, React.createElement("div", {
                  className: listBox(Belt_List.length(drawerItems) > 0)
                }, React.createElement("div", {
                      className: listScroller(role)
                    }, match === "Alphabetical" ? React.createElement("div", {
                            className: list
                          }, Belt_List.toArray(Belt_List.map(Belt_List.sort(model.metrics, (function (a, b) {
                                          if (a.name > b.name) {
                                            return 1;
                                          } else if (a.name < b.name) {
                                            return -1;
                                          } else {
                                            return 0;
                                          }
                                        })), (function (metric) {
                                      return React.createElement(MetricsRow.make, {
                                                  metric: metric,
                                                  maybeOpenMetric: maybeOpenMetric,
                                                  key: metric.id
                                                });
                                    })))) : React.createElement("div", undefined, uncategorizedMetrics === /* [] */0 ? null : React.createElement("div", {
                                  className: list
                                }, Belt_List.toArray(Belt_List.mapU(uncategorizedMetrics, (function (metric) {
                                            return React.createElement(MetricsRow.make, {
                                                        metric: metric,
                                                        maybeOpenMetric: maybeOpenMetric,
                                                        key: metric.id
                                                      });
                                          })))), React.createElement("div", undefined, Belt_List.toArray(Belt_List.mapU(Belt_List.keepU(model.goals, (function (param) {
                                              return param.metrics !== /* [] */0;
                                            })), (function (goal) {
                                          var drawerItem_1 = [
                                            goal.id,
                                            undefined
                                          ];
                                          var drawerItem = {
                                            NAME: "category",
                                            VAL: drawerItem_1
                                          };
                                          return React.createElement("div", {
                                                      key: goal.id,
                                                      className: category
                                                    }, React.createElement("div", {
                                                          className: Curry._1(Css.style, {
                                                                hd: Css.backgroundColor(Styles.Color.light02),
                                                                tl: {
                                                                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                                                  tl: {
                                                                    hd: Css.color(Styles.Color.light12),
                                                                    tl: {
                                                                      hd: Css.hover({
                                                                            hd: Css.color(Styles.Color.darkBlue),
                                                                            tl: /* [] */0
                                                                          }),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }
                                                              })
                                                        }, React.createElement("div", {
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.display("flex"),
                                                                    tl: {
                                                                      hd: Css.alignItems("center"),
                                                                      tl: {
                                                                        hd: Css.justifyContent("spaceBetween"),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  })
                                                            }, React.createElement("div", {
                                                                  className: Curry._1(Css.style, {
                                                                        hd: Css.width(Css.pct(100)),
                                                                        tl: {
                                                                          hd: Css.padding2(Css.px(10), Css.px(25)),
                                                                          tl: /* [] */0
                                                                        }
                                                                      })
                                                                }, React.createElement("div", {
                                                                      className: Curry._1(Css.style, {
                                                                            hd: Css.fontSize(Styles.FontSize.tiny),
                                                                            tl: {
                                                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                                                              tl: {
                                                                                hd: Css.color(Styles.Color.light08),
                                                                                tl: {
                                                                                  hd: Css.textTransform("uppercase"),
                                                                                  tl: /* [] */0
                                                                                }
                                                                              }
                                                                            }
                                                                          })
                                                                    }, "Category"), React.createElement(Link.make, {
                                                                      path: Router.Link.addDrawerItem(undefined, drawerItem),
                                                                      className: Curry._1(Css.style, {
                                                                            hd: Css.fontSize(Styles.FontSize.medium),
                                                                            tl: {
                                                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                              tl: /* [] */0
                                                                            }
                                                                          }),
                                                                      children: goal.name
                                                                    })))), Belt_List.toArray(Belt_List.mapU(Belt_List.keepMapU(goal.metrics, (function (metricId) {
                                                                    return Belt_List.getByU(model.metrics, (function (param) {
                                                                                  return param.id === metricId;
                                                                                }));
                                                                  })), (function (metric) {
                                                                return React.createElement(MetricsRow.make, {
                                                                            metric: metric,
                                                                            maybeOpenMetric: maybeOpenMetric,
                                                                            key: metric.id
                                                                          });
                                                              }))));
                                        })))))), React.createElement("div", {
                      className: header
                    }, React.createElement("div", undefined, RoleUtils.canEdit(role) ? React.createElement("div", {
                                className: headerTop
                              }, React.createElement(Button.make, {
                                    icon: "plus",
                                    label: "New Metric",
                                    onClick: handleAddMetric
                                  }), React.createElement(Spacer.make, {
                                    width: 16
                                  }), React.createElement(Button.make, {
                                    icon: "plus",
                                    label: "New Category",
                                    onClick: handleAddCategory,
                                    style: "outline"
                                  }), React.createElement(Spacer.make, {
                                    width: 8,
                                    grow: 1.0
                                  }), React.createElement(ContextBox.make, {
                                    children: (function (param, param$1, close, buttonRef) {
                                        return [
                                                React.createElement(Button.make, {
                                                      icon: "equalizer",
                                                      style: "ghost",
                                                      ref: buttonRef
                                                    }),
                                                React.createElement("div", {
                                                      className: sortingPopup
                                                    }, React.createElement($$Text.make, {
                                                          size: "Small",
                                                          weight: "Semi",
                                                          color: Styles.Color.light12,
                                                          children: "Sort"
                                                        }), React.createElement(Spacer.make, {
                                                          width: 8
                                                        }), React.createElement(Select.make, {
                                                          onSelect: (function (value) {
                                                              return Belt_Option.mapWithDefault(StateTypes.metricsSortFromJs(value), undefined, (function (sort) {
                                                                            Curry._1(globalSend, {
                                                                                  TAG: /* SortMetrics */3,
                                                                                  _0: sort
                                                                                });
                                                                            return Curry._1(close, undefined);
                                                                          }));
                                                            }),
                                                          options: Belt_Array.map(StateTypes.metricsSortOptions, (function (sortOption) {
                                                                  return [
                                                                          {
                                                                            NAME: "Label",
                                                                            VAL: StateTypes.metricsSortToJs(sortOption)
                                                                          },
                                                                          StateTypes.metricsSortToJs(sortOption)
                                                                        ];
                                                                })),
                                                          value: StateTypes.metricsSortToJs(globalState.metricsSort)
                                                        }))
                                              ];
                                      })
                                  })) : null), React.createElement("div", {
                          className: headerBottom
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light10,
                              children: "Metric"
                            })))));
}

var make = Metrics;

export {
  Style ,
  make ,
  
}
/* root Not a pure module */
