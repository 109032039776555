// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function encode(t) {
  return Json_encode.object_({
              hd: [
                "first",
                t.first
              ],
              tl: {
                hd: [
                  "last",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), t.last)
                ],
                tl: {
                  hd: [
                    "amount",
                    t.amount
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode(json) {
  return {
          first: Json_decode.field("first", Json_decode.$$int, json),
          last: Json_decode.field("last", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          amount: Json_decode.field("amount", Json_decode.$$float, json)
        };
}

var Tier = {
  encode: encode,
  decode: decode
};

function encode$1(t) {
  return Json_encode.object_({
              hd: [
                "editors",
                Json_encode.array(encode, t.editors)
              ],
              tl: {
                hd: [
                  "implementers",
                  Json_encode.array(encode, t.implementers)
                ],
                tl: {
                  hd: [
                    "inspectorEvents",
                    Json_encode.array(encode, t.inspectorEvents)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode$1(json) {
  return {
          editors: Json_decode.field("editors", (function (param) {
                  return Json_decode.array(decode, param);
                }), json),
          implementers: Json_decode.field("implementers", (function (param) {
                  return Json_decode.array(decode, param);
                }), json),
          inspectorEvents: Json_decode.field("inspectorEvents", (function (param) {
                  return Json_decode.array(decode, param);
                }), json)
        };
}

var PlanTiers = {
  encode: encode$1,
  decode: decode$1
};

function encode$2(t) {
  return Json_encode.object_({
              hd: [
                "team",
                encode$1(t.team)
              ],
              tl: {
                hd: [
                  "teamAnnual",
                  encode$1(t.teamAnnual)
                ],
                tl: /* [] */0
              }
            });
}

function decode$2(json) {
  return {
          team: Json_decode.field("team", decode$1, json),
          teamAnnual: Json_decode.field("teamAnnual", decode$1, json)
        };
}

var Plans = {
  encode: encode$2,
  decode: decode$2
};

function calculatePrice(tiers, quantity) {
  var walkTiers = function (_tiers, _quantity, _total, _baseUnitPrice) {
    while(true) {
      var baseUnitPrice = _baseUnitPrice;
      var total = _total;
      var quantity = _quantity;
      var tiers = _tiers;
      var updateBaseUnitPrice = function (baseUnitPrice, tier) {
        if (baseUnitPrice !== undefined) {
          return baseUnitPrice;
        }
        var amount = tier.amount;
        if (amount > 0) {
          return amount;
        }
        
      };
      if (tiers.length === 0) {
        return [
                total,
                baseUnitPrice
              ];
      }
      if (quantity === 0 && baseUnitPrice !== undefined) {
        return [
                total,
                baseUnitPrice
              ];
      }
      if (tiers.length !== 1) {
        var tier = Belt_Array.getExn(tiers, 0);
        var tierRange = Belt_Option.mapWithDefault(tier.last, quantity, (function(tier){
            return function (last) {
              return last - tier.first | 0;
            }
            }(tier)));
        var tierQuantity = tierRange < quantity ? tierRange : quantity;
        var tierTotal = tier.amount * tierQuantity;
        var remainingTiers = Belt_Array.sliceToEnd(tiers, 1);
        var baseUnitPrice$1 = updateBaseUnitPrice(baseUnitPrice, tier);
        _baseUnitPrice = baseUnitPrice$1;
        _total = total + tierTotal;
        _quantity = quantity - tierQuantity | 0;
        _tiers = remainingTiers;
        continue ;
      }
      var tier$1 = tiers[0];
      var total$1 = total + tier$1.amount * quantity;
      var baseUnitPrice$2 = updateBaseUnitPrice(baseUnitPrice, tier$1);
      return [
              total$1,
              baseUnitPrice$2
            ];
    };
  };
  var match = walkTiers(tiers, quantity, 0, undefined);
  var total = match[0];
  var includedUnits = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(tiers, 0), (function (tier) {
              if (tier.amount === 0) {
                return tier.last;
              }
              
            })), 0);
  var paidUnits = Math.max(0, quantity - includedUnits | 0);
  var unitPrice = paidUnits !== 0 ? total / paidUnits : Belt_Option.getWithDefault(match[1], 0);
  var approximatedUnitPrice = unitPrice < 1.0 ? Number(unitPrice.toPrecision(2)) : (
      unitPrice < 10.0 ? Number(unitPrice.toPrecision(3)) : Number(unitPrice.toPrecision(4))
    );
  return {
          total: total,
          unitPrice: approximatedUnitPrice
        };
}

function getTiersFromOrbTieredConfig(tiered_config) {
  return Belt_Array.map(tiered_config.tiers, (function (tier) {
                return {
                        first: tier.first_unit,
                        last: tier.last_unit,
                        amount: Number(tier.unit_amount)
                      };
              }));
}

export {
  Tier ,
  PlanTiers ,
  Plans ,
  calculatePrice ,
  getTiersFromOrbTieredConfig ,
  
}
/* No side effect */
