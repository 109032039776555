// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Styles from "./styles.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as DateFns from "date-fns";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BillingPlans from "./BillingPlans.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as BillingPlansOrb from "./BillingPlansOrb.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function Billing(Props) {
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var paymentState = FirebaseFetcherHooks.usePayment(workspace.id);
  var match = workspace.plan.name;
  if (typeof paymentState === "object") {
    var payment = paymentState.VAL;
    if (payment.TAG === /* Orb */0) {
      if (match >= 10) {
        return React.createElement(BillingPlansOrb.make, {
                    currentPlanName: match,
                    payment: payment._0
                  });
      }
      
    } else {
      var payment$1 = payment._0;
      var exit = 0;
      if (match >= 9) {
        exit = 2;
      } else {
        switch (match) {
          case /* Empty */0 :
          case /* Scholarship */3 :
          case /* Growth */4 :
              exit = 2;
              break;
          case /* Free */1 :
          case /* FreeV2 */2 :
          case /* GrowthV2 */5 :
          case /* Enterprise */6 :
          case /* Starter */7 :
          case /* TeamV2 */8 :
              exit = 3;
              break;
          
        }
      }
      switch (exit) {
        case 2 :
            if (payment$1.active) {
              return React.createElement(BillingPlans.make, {
                          currentPlanName: match,
                          payment: payment$1
                        });
            }
            break;
        case 3 :
            return React.createElement(BillingPlans.make, {
                        currentPlanName: match,
                        payment: payment$1
                      });
        
      }
    }
  } else {
    if (paymentState !== "NotFound") {
      return React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100.0)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: {
                                hd: Css.height(Css.vh(100.0)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(LoadingCircle.make, {
                      color: Styles.Color.light07
                    }));
    }
    var exit$1 = 0;
    if (match < 10) {
      switch (match) {
        case /* Empty */0 :
        case /* Scholarship */3 :
        case /* Growth */4 :
            break;
        case /* Free */1 :
        case /* FreeV2 */2 :
        case /* GrowthV2 */5 :
        case /* Enterprise */6 :
        case /* Starter */7 :
        case /* TeamV2 */8 :
        case /* TeamV2Annual */9 :
            exit$1 = 2;
            break;
        
      }
    }
    if (exit$1 === 2) {
      return React.createElement(BillingPlans.make, {
                  currentPlanName: match
                });
    }
    
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding4(Css.px(15), Css.px(15), Css.px(0), Css.px(15)),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.overflow("hidden"),
                          tl: {
                            hd: Css.margin4(Css.px(10), "auto", Css.px(10), "auto"),
                            tl: {
                              hd: Css.maxWidth(Css.px(500)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(25)),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.bold),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.huge),
                            tl: {
                              hd: Css.color(Styles.Color.light12),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: {
                                  hd: Css.textAlign("center"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, "Billing"), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(25), Css.px(5)),
                        tl: {
                          hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
                          tl: {
                            hd: Css.textAlign("center"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, "Get in touch so we can find the perfect plan for you!"), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.margin(Css.px(15)),
                        tl: /* [] */0
                      })
                }, React.createElement(Button.make, {
                      label: "Upgrade",
                      onClick: (function (param) {
                          window.open("https://avo.app/request-a-demo?email=" + Belt_Option.getWithDefault(Caml_option.nullable_to_opt(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).email), ""), "_blank");
                          return AnalyticsRe.subscriptionInterestIndicated(schemaBundle, DateFns.differenceInCalendarDays(new Date(), Belt_Option.getWithDefault(workspace.createdAt, new Date())) | 0, 14, schemaBundle.branchId, schemaBundle.schemaId);
                        })
                    })));
}

var make = Billing;

export {
  make ,
  
}
/* Css Not a pure module */
