// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as List from "rescript/lib/es6/list.js";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Accordion from "./Accordion.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as SourceRow from "./SourceRow.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ContextBox from "./ContextBox.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as SourceIcon from "./SourceIcon.mjs";
import * as SourcePill from "./SourcePill.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as ImplementationStatusContext from "./ImplementationStatusContext.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.fontWeight(Styles.FontWeight.bold),
      tl: {
        hd: Css.color(Styles.Color.light10),
        tl: {
          hd: Css.fontSize(Styles.FontSize.small),
          tl: {
            hd: Css.width(Css.pct(100)),
            tl: {
              hd: Css.padding2(Css.px(0), Css.px(10)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var emptyStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.padding4(Css.px(10), Css.px(5), Css.px(10), Css.px(15)),
            tl: {
              hd: Css.margin2(Css.px(5), Css.px(0)),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.orange),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var addSourcePopupStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.bottom(Css.px(0)),
          tl: {
            hd: Css.transform(Css.translateY(Css.pct(100))),
            tl: {
              hd: Css.padding(Css.px(4)),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                    tl: {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.minWidth(Css.px(300)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var addSourcePopupItemStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.padding2(Css.px(10), Css.px(15)),
                  tl: {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.textAlign("left"),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: {
                              hd: Css.color(Styles.Color.light10),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.backgroundColor(Styles.Color.light02),
                                      tl: {
                                        hd: Css.color(Styles.Color.darkBlue),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var newSourceButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.padding2(Css.px(10), Css.px(18)),
                  tl: {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.textAlign("left"),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.color(Styles.Color.darkBlue),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.color(Styles.Color.deepBlue),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function EventSources$AccordionSource(Props) {
  var disabledOpt = Props.disabled;
  var source = Props.source;
  var $$event = Props.event;
  var model = Props.model;
  var sendActions = Props.sendActions;
  var schemaBundle = Props.schemaBundle;
  var viewerCanEdit = Props.viewerCanEdit;
  var eventSourcesAndDestinationsConfigAvailability = Props.eventSourcesAndDestinationsConfigAvailability;
  var isEventModified = Props.isEventModified;
  var eventInvocations = Props.eventInvocations;
  var codegenInvocationsFetcherState = Props.codegenInvocationsFetcherState;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var hasBasicNameCasing = ValidationConfigV2.EventsV2.hasBasicNameCasing(match.config.events);
  var correctCase = hasBasicNameCasing ? NameUtils.isCorrectCase(expectedEventCase, $$event.name) : undefined;
  var namingConvention = hasBasicNameCasing ? Case.toAnalytics(expectedEventCase) : "Advanced";
  var includedDestinations = Belt_List.toArray(Belt_List.flatten(Belt_List.keepMap($$event.includeDestinations, (function (x) {
                  var match = x.VAL;
                  if (match[0] === source.id) {
                    return match[1];
                  }
                  
                }))));
  var sourceDestinations = Belt_List.toArray(BeltListExtensions.dedupeOrdered(Belt_List.keepMapU(source.destinations, (function (param) {
                  return ModelUtils.getDestinationById(param.destinationId, model);
                })), (function (destination) {
              return destination.id;
            })));
  var disabledDestinationTuples = Belt_Array.map(Belt_Array.keep(sourceDestinations, (function (param) {
              var id = param.id;
              return !Belt_Array.some(includedDestinations, (function (includedDestinationId) {
                            return includedDestinationId === id;
                          }));
            })), (function (destination) {
          return [
                  destination.id,
                  ModelUtils.getDestinationName(destination)
                ];
        }));
  var subtitle = Caml_obj.caml_equal(disabledDestinationTuples, []) ? undefined : ({
        NAME: "Component",
        VAL: React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("row"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.gap(Css.px(4)),
                          tl: {
                            hd: Css.flexWrap("wrap"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Regular",
                  color: Styles.Color.light11,
                  children: "Disabled for"
                }), Belt_Array.map(disabledDestinationTuples, (function (param) {
                    return React.createElement("div", {
                                key: param[0],
                                className: Curry._1(Css.style, {
                                      hd: Css.paddingTop(Css.px(4)),
                                      tl: {
                                        hd: Css.paddingBottom(Css.px(4)),
                                        tl: {
                                          hd: Css.paddingLeft(Css.px(8)),
                                          tl: {
                                            hd: Css.paddingRight(Css.px(8)),
                                            tl: {
                                              hd: Css.borderRadius(Css.px(20)),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.light04),
                                                tl: {
                                                  hd: Css.border(Css.px(1), "solid", Styles.Color.light02),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    size: "Tiny",
                                    weight: "Semi",
                                    lines: 1,
                                    color: Styles.Color.light09,
                                    children: param[1]
                                  }));
                  })))
      });
  var tmp = {
    title: {
      NAME: "String",
      VAL: AvoConfig.getSourceName(source)
    },
    menuItems: viewerCanEdit ? [
        {
          NAME: "Option",
          VAL: {
            label: "View " + (
              isGlobalWorkspace ? "Workspace" : "Source"
            ) + " details",
            onClick: (function (param) {
                return Router.Schema.pushDrawerItem(undefined, undefined, {
                            NAME: "source",
                            VAL: [
                              source.id,
                              "overview"
                            ]
                          });
              })
          }
        },
        {
          NAME: "Option",
          VAL: {
            label: eventSourcesAndDestinationsConfigAvailability === "Available" ? "Remove " + (
                isGlobalWorkspace ? "Workspace" : "Source"
              ) : "⚡ Remove " + (
                isGlobalWorkspace ? "Workspace" : "Source"
              ),
            onClick: (function (param) {
                if (eventSourcesAndDestinationsConfigAvailability === "AvailableDuringTrial" || eventSourcesAndDestinationsConfigAvailability === "Available") {
                  return Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              (function (branch) {
                                  return AnalyticsRe.eventUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), AnalyticsRe.Bundle.source(source.id, AvoConfig.getSourceName(source)), $$event.id, $$event.name, "RemoveSource", undefined, undefined, Belt_List.toArray(Belt_List.keepU(ModelUtils.getIncludedSourceIds($$event.includeSources), (function (id) {
                                                        return id !== source.id;
                                                      }))), Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), undefined, undefined, undefined, undefined, Belt_List.length(model.events), undefined, undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, namingConvention, $$event.id, schemaBundle.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                                }),
                              undefined,
                              [[
                                  {
                                    NAME: "ExcludeEventFromSourceV2",
                                    VAL: [
                                      $$event.id,
                                      source.id
                                    ]
                                  },
                                  {
                                    eventId: $$event.id,
                                    eventQuery: $$event.id,
                                    sourceId: source.id,
                                    sourceQuery: source.id
                                  }
                                ]]
                            ]);
                } else {
                  return Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "BillingPrompt",
                                VAL: "ConfigureEventsPerPlatform"
                              }
                            });
                }
              })
          }
        }
      ] : [],
    extra: React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.height(Css.px(16)),
                tl: /* [] */0
              })
        }, isGlobalWorkspace ? null : React.createElement(SourcePill.Simple.make, {
                event: $$event,
                isEventModified: isEventModified,
                eventInvocations: eventInvocations,
                codegenInvocationsFetcherState: codegenInvocationsFetcherState,
                source: source,
                size: 16,
                _timeWindowStart: undefined
              }))
  };
  if (subtitle !== undefined) {
    tmp.subtitle = Caml_option.valFromOption(subtitle);
  }
  return React.createElement(Accordion.Item.make, {
              disabled: isGlobalWorkspace,
              children: null
            }, React.createElement(Accordion.ItemHeader.make, tmp), React.createElement(Accordion.ItemCollapsible.make, {
                  disabled: disabled,
                  onChange: (function (isOpen) {
                      return AnalyticsRe.collapsibleItemInteracted(schemaBundle, isOpen ? "Expanded" : "Collapsed", "Source", "Event");
                    }),
                  children: React.createElement(SourceRow.make, {
                        event: $$event,
                        model: model,
                        viewerCanEdit: viewerCanEdit,
                        schemaBundle: schemaBundle,
                        sendActions: sendActions,
                        source: source,
                        isEventModified: isEventModified
                      })
                }));
}

var AccordionSource = {
  make: EventSources$AccordionSource
};

function EventSources(Props) {
  var $$event = Props.event;
  var model = Props.model;
  var variant = Props.variant;
  var viewerCanEdit = Props.viewerCanEdit;
  var isEventModified = Props.isEventModified;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var workspaceLimits = AvoLimits.computeLimits(workspace, model);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var hasBasicNameCasing = ValidationConfigV2.EventsV2.hasBasicNameCasing(match.config.events);
  var correctCase = hasBasicNameCasing ? NameUtils.isCorrectCase(expectedEventCase, $$event.name) : undefined;
  var namingConvention = hasBasicNameCasing ? Case.toAnalytics(expectedEventCase) : "Advanced";
  var match$1 = AvoLimits.CodegenEvents.entityStatus(workspaceLimits, $$event.id);
  var codegenAvailability = match$1[0];
  var addSourcesDomRef = React.useRef(null);
  var match$2 = ImplementationStatusContext.useEventWithRefetchStatusSubscription($$event.id);
  var eventInvocations = match$2[1];
  var codegenInvocationsFetcherState = match$2[0];
  var eventSourcesAndDestinationsConfigAvailability = AvoLimits.ConfigEventSourcesAndDestinations.computeAvailability(workspace);
  var sourcesWithCodegenSeen = WorkspaceContext.useLastSeenCodegenForSourceIds(undefined);
  var includeCodegenForAddedSource = function (sourceId) {
    if (codegenAvailability !== "Unavailable") {
      return Belt_MapString.has(sourcesWithCodegenSeen, sourceId);
    } else {
      return false;
    }
  };
  var clickAddSource = function (param) {
    var el = addSourcesDomRef.current;
    if (!(el == null)) {
      return Curry._1(el.click, undefined);
    }
    
  };
  var sending = AvoConfig.determineSendingToSources(model.sources, $$event);
  var sendingSources = typeof sending === "object" ? sending.VAL : (
      sending === "None" ? /* [] */0 : model.sources
    );
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  return React.createElement("div", {
              className: rootStyles
            }, sendingSources === /* [] */0 ? React.createElement("div", {
                    className: emptyStyles,
                    onClick: clickAddSource
                  }, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Semi",
                        color: Styles.Color.orange,
                        children: "This event " + (
                          variant === undefined ? "" : "variant "
                        ) + " is not sent from any source yet"
                      })) : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                        height: 5
                      }), React.createElement(Accordion.make, {
                        allowMultipleOpens: true,
                        children: Belt_Array.map(Belt_List.toArray(sendingSources), (function (source) {
                                return React.createElement(EventSources$AccordionSource, {
                                            disabled: isGlobalWorkspace,
                                            source: source,
                                            event: $$event,
                                            model: model,
                                            sendActions: sendActions,
                                            schemaBundle: schemaBundle,
                                            viewerCanEdit: viewerCanEdit && variant === undefined,
                                            eventSourcesAndDestinationsConfigAvailability: eventSourcesAndDestinationsConfigAvailability,
                                            isEventModified: isEventModified,
                                            eventInvocations: eventInvocations,
                                            codegenInvocationsFetcherState: codegenInvocationsFetcherState,
                                            key: source.id
                                          });
                              }))
                      }), React.createElement(Spacer.make, {
                        height: 8
                      })), !viewerCanEdit || variant !== undefined ? null : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(ContextBox.make, {
                        children: (function (param, param$1, close, buttonRef) {
                            return [
                                    React.createElement(TextButton.make, {
                                          style: "Blue",
                                          size: "Medium",
                                          disabled: Belt_List.size(sendingSources) === Belt_List.size(model.sources) && isGlobalWorkspace,
                                          children: "+ Add " + (
                                            isGlobalWorkspace ? "Workspace" : "Source"
                                          ),
                                          ref: buttonRef
                                        }),
                                    React.createElement("div", {
                                          className: addSourcePopupStyles
                                        }, Belt_List.toArray(Belt_List.map(Belt_List.keep(model.sources, (function (source) {
                                                        return !AvoConfig.isEventIncludedInSource(source.id, $$event);
                                                      })), (function (source) {
                                                    return React.createElement("button", {
                                                                key: source.id,
                                                                className: addSourcePopupItemStyles,
                                                                onClick: (function (param) {
                                                                    var implementWithAvo = includeCodegenForAddedSource(source.id);
                                                                    var eventsImplementedWithAvoOnSource = List.length(ModelUtils.getSourceEvents(true, model, source.id));
                                                                    var eventsNotImplementedWithAvoOnSource = List.length(ModelUtils.getSourceEvents(undefined, model, source.id)) - eventsImplementedWithAvoOnSource | 0;
                                                                    return Curry.app(sendActions, [
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                (function (branch) {
                                                                                    AnalyticsRe.eventUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), AnalyticsRe.Bundle.source(source.id, AvoConfig.getSourceName(source)), $$event.id, $$event.name, "AddSource", undefined, undefined, Belt_List.toArray(Belt_List.add(ModelUtils.getIncludedSourceIds($$event.includeSources), source.id)), Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), undefined, undefined, undefined, undefined, Belt_List.length(model.events), undefined, implementWithAvo, eventsNotImplementedWithAvoOnSource + (
                                                                                          implementWithAvo ? 0 : 1
                                                                                        ) | 0, eventsImplementedWithAvoOnSource + (
                                                                                          implementWithAvo ? 1 : 0
                                                                                        ) | 0, "Event", $$event.triggers.length, correctCase, namingConvention, $$event.id, schemaBundle.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                                                                                    return Curry._1(close, undefined);
                                                                                  }),
                                                                                undefined,
                                                                                [[
                                                                                    {
                                                                                      NAME: "IncludeEventInSourceV2",
                                                                                      VAL: [
                                                                                        $$event.id,
                                                                                        source.id,
                                                                                        ModelUtils.getDefaultDestinationsForSource(source, model),
                                                                                        implementWithAvo
                                                                                      ]
                                                                                    },
                                                                                    {
                                                                                      eventId: $$event.id,
                                                                                      eventQuery: $$event.id,
                                                                                      sourceId: source.id,
                                                                                      sourceQuery: source.id
                                                                                    }
                                                                                  ]]
                                                                              ]);
                                                                  })
                                                              }, React.createElement("div", {
                                                                    className: Curry._1(Css.style, {
                                                                          hd: Css.marginRight(Css.px(10)),
                                                                          tl: /* [] */0
                                                                        })
                                                                  }, React.createElement(SourceIcon.make, {
                                                                        source: source
                                                                      })), AvoConfig.getSourceName(source));
                                                  }))), isGlobalWorkspace ? null : React.createElement("button", {
                                                className: newSourceButtonStyles,
                                                onClick: (function (param) {
                                                    return Router.Schema.pushSchemaRoute(undefined, undefined, "settings");
                                                  })
                                              }, "+ Set Up New Source"))
                                  ];
                          }),
                        ref: addSourcesDomRef
                      })));
}

var make = EventSources;

export {
  rootStyles ,
  emptyStyles ,
  addSourcePopupStyles ,
  addSourcePopupItemStyles ,
  newSourceButtonStyles ,
  AccordionSource ,
  make ,
  
}
/* rootStyles Not a pure module */
