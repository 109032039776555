// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as SandboxBanner from "../SandboxBanner.mjs";
import * as AppFeatureFlag from "../AppFeatureFlag.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as DiscrepancyContext from "../DiscrepancyContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as InspectorViewSelectors from "./InspectorViewSelectors.mjs";
import * as InspectorToTrackingPlanHelpers from "./InspectorToTrackingPlanHelpers.mjs";
import * as InspectorViewContextSidebarIssues from "./InspectorViewContextSidebarIssues.mjs";

function containerStyle(withSandboxBanner) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.px(280)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexShrink(0.0),
                  tl: {
                    hd: Css.flexDirection("column"),
                    tl: {
                      hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light02),
                      tl: {
                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(3), Css.px(10), Css.px(0), undefined, Css.rgba(0, 0, 0, {
                                      NAME: "num",
                                      VAL: 0.1
                                    }))),
                        tl: {
                          hd: Css.zIndex(1),
                          tl: {
                            hd: Css.overflowY("auto"),
                            tl: {
                              hd: Css.height({
                                    NAME: "subtract",
                                    VAL: [
                                      Css.vh(100),
                                      Css.px(67 + SandboxBanner.getHeight(withSandboxBanner) | 0)
                                    ]
                                  }),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var emptyStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.flexGrow(1.0),
                tl: {
                  hd: Css.padding(Css.px(20)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var emptyTitleStyle = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.large),
      tl: {
        hd: Css.color(Styles.Color.light10),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.semi),
          tl: {
            hd: Css.textAlign("center"),
            tl: {
              hd: Css.lineHeight(Css.pct(140.0)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var emptySubtitleStyle = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.medium),
      tl: {
        hd: Css.color(Styles.Color.light08),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.semi),
          tl: {
            hd: Css.textAlign("center"),
            tl: {
              hd: Css.paddingTop(Css.px(5)),
              tl: {
                hd: Css.lineHeight(Css.pct(140.0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var addEventBackdrop = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.backgroundColor(Css.rgba(0, 0, 0, {
                          NAME: "num",
                          VAL: 0.2
                        })),
                tl: {
                  hd: Css.zIndex(Styles.ZIndex.sidebar + 10 | 0),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var addEvent = Curry._1(Css.style, {
      hd: Css.width(Css.px(280)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light02),
            tl: {
              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(3), Css.px(10), Css.px(0), undefined, Css.rgba(0, 0, 0, {
                            NAME: "num",
                            VAL: 0.1
                          }))),
              tl: {
                hd: Css.zIndex(Styles.ZIndex.sidebar + 11 | 0),
                tl: {
                  hd: Css.overflowY("auto"),
                  tl: {
                    hd: Css.height(Css.vh(100)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function InspectorViewContextSidebar(Props) {
  var cell = Props.cell;
  var model = Props.model;
  var viewerRole = Props.viewerRole;
  var match = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match.expectedPropertyCase;
  var expectedEventCase = match.expectedEventCase;
  var config = match.config;
  var addToast = Toast.useAddToast(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var shouldCreateAsSometimesSent = AppFeatureFlag.useFeatureFlag("InspectorAddToTrackingPlanAsSometimesSent");
  var tmp;
  var exit = 0;
  if (cell !== undefined && typeof cell === "object") {
    var variant = cell.NAME;
    if (variant === "PropertyVolume" || variant === "PropertyName" || variant === "PropertyIssues") {
      var match$1 = cell.VAL;
      var property = match$1[1];
      var $$event = match$1[0];
      tmp = React.createElement(InspectorViewContextSidebarIssues.make, {
            cell: cell,
            eventName: $$event.eventName,
            onAddEvent: (function (param) {
                return InspectorToTrackingPlanHelpers.$$Event.onCreateEvent(workspace, $$event, model, sendActions, schemaBundle, expectedEventCase, expectedPropertyCase, addToast, globalSend, "InspectorSidebar", shouldCreateAsSometimesSent, config);
              }),
            onAddProperty: (function (inspectorProperty) {
                return InspectorToTrackingPlanHelpers.Property.onCreateProperty($$event, inspectorProperty, model, sendActions, schemaBundle, expectedPropertyCase, addToast, globalSend, "InspectorSidebar", shouldCreateAsSometimesSent);
              }),
            sourceIds: Belt_List.fromArray(Belt_Array.map(property.sources, (function (source) {
                        return source.sourceId;
                      }))),
            sourceIssues: Belt_Array.concatMany(Belt_Array.mapU(property.sources, (function (source) {
                        return Belt_Array.mapU(source.issues, (function (issue) {
                                      return [
                                              source.sourceId,
                                              issue
                                            ];
                                    }));
                      }))),
            model: model,
            viewerRole: viewerRole
          });
    } else if (variant === "PropertySourceVolume" || variant === "PropertySourceIssues") {
      var match$2 = cell.VAL;
      var propertySource = match$2[2];
      var $$event$1 = match$2[0];
      tmp = React.createElement(InspectorViewContextSidebarIssues.make, {
            cell: cell,
            eventName: $$event$1.eventName,
            onAddEvent: (function (param) {
                return InspectorToTrackingPlanHelpers.$$Event.onCreateEvent(workspace, $$event$1, model, sendActions, schemaBundle, expectedEventCase, expectedPropertyCase, addToast, globalSend, "InspectorSidebar", shouldCreateAsSometimesSent, config);
              }),
            onAddProperty: (function (inspectorProperty) {
                console.log("Inspector PRoperty", inspectorProperty);
                return InspectorToTrackingPlanHelpers.Property.onCreateProperty($$event$1, inspectorProperty, model, sendActions, schemaBundle, expectedPropertyCase, addToast, globalSend, "InspectorSidebar", shouldCreateAsSometimesSent);
              }),
            sourceIds: Belt_Option.mapWithDefault(propertySource, /* [] */0, (function (source) {
                    return {
                            hd: source.sourceId,
                            tl: /* [] */0
                          };
                  })),
            sourceIssues: Belt_Option.mapWithDefault(propertySource, [], (function (source) {
                    return Belt_Array.mapU(source.issues, (function (issue) {
                                  return [
                                          source.sourceId,
                                          issue
                                        ];
                                }));
                  })),
            model: model,
            viewerRole: viewerRole
          });
    } else if (variant === "Error") {
      exit = 1;
    } else if (variant === "EventIssues" || variant === "EventName" || variant === "EventVolume") {
      var $$event$2 = cell.VAL;
      tmp = React.createElement(InspectorViewContextSidebarIssues.make, {
            cell: cell,
            eventName: $$event$2.eventName,
            onAddEvent: (function (param) {
                return InspectorToTrackingPlanHelpers.$$Event.onCreateEvent(workspace, $$event$2, model, sendActions, schemaBundle, expectedEventCase, expectedPropertyCase, addToast, globalSend, "InspectorSidebar", shouldCreateAsSometimesSent, config);
              }),
            onAddProperty: (function (inspectorProperty) {
                return InspectorToTrackingPlanHelpers.Property.onCreateProperty($$event$2, inspectorProperty, model, sendActions, schemaBundle, expectedPropertyCase, addToast, globalSend, "InspectorSidebar", shouldCreateAsSometimesSent);
              }),
            sourceIds: Belt_List.fromArray(Belt_Array.mapU($$event$2.sources, (function (source) {
                        return source.sourceId;
                      }))),
            sourceIssues: Belt_Array.concatMany(Belt_Array.mapU($$event$2.properties, (function (property) {
                        return Belt_Array.concatMany(Belt_Array.mapU(property.sources, (function (source) {
                                          return Belt_Array.mapU(source.issues, (function (issue) {
                                                        return [
                                                                source.sourceId,
                                                                issue
                                                              ];
                                                      }));
                                        })));
                      }))),
            model: model,
            viewerRole: viewerRole
          });
    } else {
      var match$3 = cell.VAL;
      var eventSource = match$3[1];
      var $$event$3 = match$3[0];
      tmp = React.createElement(InspectorViewContextSidebarIssues.make, {
            cell: cell,
            eventName: $$event$3.eventName,
            onAddEvent: (function (param) {
                return InspectorToTrackingPlanHelpers.$$Event.onCreateEvent(workspace, $$event$3, model, sendActions, schemaBundle, expectedEventCase, expectedPropertyCase, addToast, globalSend, "InspectorSidebar", shouldCreateAsSometimesSent, config);
              }),
            onAddProperty: (function (inspectorProperty) {
                return InspectorToTrackingPlanHelpers.Property.onCreateProperty($$event$3, inspectorProperty, model, sendActions, schemaBundle, expectedPropertyCase, addToast, globalSend, "InspectorSidebar", shouldCreateAsSometimesSent);
              }),
            sourceIds: Belt_Option.mapWithDefault(eventSource, /* [] */0, (function (source) {
                    return {
                            hd: source.sourceId,
                            tl: /* [] */0
                          };
                  })),
            sourceIssues: Belt_Option.mapWithDefault(eventSource, [], (function (source) {
                    return InspectorViewSelectors.getEventIssues([source], $$event$3);
                  })),
            model: model,
            viewerRole: viewerRole
          });
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: emptyStyle
        }, React.createElement("div", {
              className: emptyTitleStyle
            }, "Issues Inspector"), React.createElement("div", {
              className: emptySubtitleStyle
            }, "Select an issue in the table to see more info"));
  }
  return React.createElement(React.Fragment, undefined, React.createElement("section", {
                  className: containerStyle(workspace.isSandbox)
                }, tmp));
}

var make = InspectorViewContextSidebar;

export {
  containerStyle ,
  emptyStyle ,
  emptyTitleStyle ,
  emptySubtitleStyle ,
  addEventBackdrop ,
  addEvent ,
  make ,
  
}
/* emptyStyle Not a pure module */
