// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Guides from "./markdown/guides.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Markdown from "./Markdown.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as IconChevronLight from "./IconChevronLight.mjs";
import * as SetupInstructions from "./SetupInstructions.mjs";
import * as SourceSetupDestinations from "./SourceSetupDestinations.mjs";

function renderWarning(message) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.backgroundColor(Styles.Color.red),
                    tl: {
                      hd: Css.border(Css.px(1), "solid", Styles.Color.darkRed),
                      tl: {
                        hd: Css.color(Styles.Color.white),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.margin2(Css.px(20), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, message);
}

var docsLink = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.linkReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.padding(Css.px(12)),
              tl: {
                hd: Css.display("grid"),
                tl: {
                  hd: Css.gridTemplateColumns({
                        hd: Css.px(26),
                        tl: {
                          hd: "auto",
                          tl: {
                            hd: Css.px(16),
                            tl: /* [] */0
                          }
                        }
                      }),
                  tl: {
                    hd: Css.gridGap(Css.px(8)),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                        tl: {
                          hd: Css.cursor("pointer"),
                          tl: {
                            hd: Css.textAlign("left"),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.backgroundColor(Styles.Color.light02),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.marginBottom(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function renderDocsLink(label, path) {
  return React.createElement("a", {
              className: docsLink,
              href: "https://www.avo.app/docs/" + path,
              rel: "noopener",
              target: "_blank"
            }, React.createElement(Icons.Code.make, {}), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  children: label
                }), React.createElement(IconChevronLight.make, {
                  size: 12,
                  color: Styles.Color.light07,
                  direction: "Right"
                }));
}

function SourceSetup(Props) {
  var model = Props.model;
  var source = Props.source;
  var hasUseConstructorBasedAnalyticsFeatureFlag = AppFeatureFlag.useFeatureFlag("UseConstructorBasedAnalytics");
  var match = source.platform;
  var tmp;
  if (match !== undefined) {
    var match$1 = source.platform;
    var match$2 = source.language;
    var tmp$1;
    var exit = 0;
    if (match$2 !== undefined) {
      if (match$2 === "dart") {
        tmp$1 = React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.color(Styles.Color.red),
                      tl: /* [] */0
                    }
                  })
            }, renderWarning("Codegen is not supported for Flutter yet."));
      } else if (match$2 === "json") {
        tmp$1 = null;
      } else if (match$2 === "objc") {
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Markdown.make, {
                  source: Guides.objcHowTo
                }), renderDocsLink("Docs: Initialize Codegen in Objective-C", "implementation/reference/objc#reference"));
      } else if (match$2 === "re_v2") {
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Markdown.make, {
                  source: Guides.reV2HowTo
                }), renderDocsLink("Docs: Initialize Codegen in Rescript", "implementation/reference/rescript#reference"));
      } else if (match$2 === "cs") {
        tmp$1 = renderDocsLink("Docs: Initialize Codegen in C#", "implementation/reference/csharp");
      } else if (match$2 === "go") {
        tmp$1 = React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.color(Styles.Color.red),
                      tl: /* [] */0
                    }
                  })
            }, renderWarning("Codegen is not supported for Go yet."));
      } else if (match$2 === "js") {
        tmp$1 = React.createElement(Markdown.make, {
              source: Guides.jsHowTo
            });
      } else if (match$2 === "kt") {
        tmp$1 = hasUseConstructorBasedAnalyticsFeatureFlag ? React.createElement(React.Fragment, undefined, React.createElement(Markdown.make, {
                    source: Guides.kotlinConstructorHowTo
                  }), renderDocsLink("Docs: Initialize Codegen in Kotlin", "implementation/reference/kotlin#reference")) : React.createElement(React.Fragment, undefined, React.createElement(Markdown.make, {
                    source: Guides.kotlinHowTo
                  }), renderDocsLink("Docs: Initialize Codegen in Kotlin", "implementation/reference/kotlin#reference"));
      } else if (match$2 === "py3" || match$2 === "py") {
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Markdown.make, {
                  source: Guides.pythonHowTo
                }), renderDocsLink("Docs: Initialize Codegen in Python", "implementation/reference/python#reference"));
      } else if (match$2 === "rb") {
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Markdown.make, {
                  source: Guides.rubyHowTo
                }), renderDocsLink("Docs: Initialize Codegen in Ruby", "implementation/reference/ruby#reference"));
      } else if (match$2 === "re") {
        tmp$1 = React.createElement(Markdown.make, {
              source: Guides.reHowTo
            });
      } else if (match$2 === "ts") {
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Markdown.make, {
                  source: Guides.tsHowTo
                }), renderDocsLink("Docs: Initialize Codegen in Typescript", "implementation/reference/typescript#reference"));
      } else if (match$2 === "php") {
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Markdown.make, {
                  source: Guides.phpHowTo
                }), renderDocsLink("Docs: Initialize Codegen in PHP", "implementation/reference/php#reference"));
      } else if (match$2 === "swift") {
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Markdown.make, {
                  source: Guides.swiftHowTo
                }), renderDocsLink("Docs: Initialize Codegen in Swift", "implementation/reference/swift#reference"));
      } else if (match$1 !== undefined) {
        if (match$1 === "Android") {
          if (match$2 === "java") {
            tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Markdown.make, {
                      source: Guides.javaAndroidHowTo
                    }), renderDocsLink("Docs: Initialize Codegen in Java", "implementation/reference/java#reference"));
          } else {
            exit = 1;
          }
        } else if (match$1 === "Gtm" && match$2 === "js_v2") {
          tmp$1 = React.createElement(SetupInstructions.Gtm.make, {
                sourceId: source.id,
                actionId: "???"
              });
        } else {
          exit = 1;
        }
      } else {
        exit = 1;
      }
    } else {
      tmp$1 = React.createElement("span", {
            className: Curry._1(Css.style, {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: {
                    hd: Css.color(Styles.Color.red),
                    tl: /* [] */0
                  }
                })
          }, renderWarning("Please choose Programming Language above to get relevant setup instructions for your project."));
    }
    if (exit === 1) {
      tmp$1 = match$2 === "js_v2" ? React.createElement(React.Fragment, undefined, React.createElement(Markdown.make, {
                  source: Guides.jsV2HowTo
                }), renderDocsLink("Docs: Initialize Codegen in JavaScript", "implementation/reference/javascript#reference")) : React.createElement(React.Fragment, undefined, React.createElement(Markdown.make, {
                  source: Guides.javaHowTo
                }), renderDocsLink("Docs: Initialize Codegen in Java", "implementation/reference/java#reference"));
    }
    tmp = React.createElement("div", undefined, React.createElement($$Text.make, {
              size: "Large",
              weight: "Semi",
              children: "1. Prepare your destinations"
            }), React.createElement(Spacer.make, {
              height: 2
            }), React.createElement(SourceSetupDestinations.make, {
              model: model,
              source: source
            }), React.createElement(Spacer.make, {
              height: 16
            }), React.createElement($$Text.make, {
              size: "Large",
              weight: "Semi",
              children: "2. Initialize Avo"
            }), React.createElement(Spacer.make, {
              height: 8
            }), tmp$1);
  } else {
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.fontWeight(Styles.FontWeight.semi),
                tl: {
                  hd: Css.color(Styles.Color.light07),
                  tl: {
                    hd: Css.padding(Css.px(20)),
                    tl: {
                      hd: Css.textAlign("center"),
                      tl: /* [] */0
                    }
                  }
                }
              })
        }, "Please choose development platform in the Overview tab to get started.");
  }
  return React.createElement("div", undefined, tmp);
}

var make = SourceSetup;

export {
  renderWarning ,
  docsLink ,
  renderDocsLink ,
  make ,
  
}
/* docsLink Not a pure module */
