// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Router from "./Router.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Actions from "./actions.mjs";
import * as Changes from "../../shared/Changes.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as DiffView from "./DiffView.mjs";
import * as Redirect from "./Redirect.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as DiffUtils from "./DiffUtils.mjs";
import * as FetchModel from "./FetchModel.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RouterStore from "./RouterStore.mjs";
import * as BranchHeader from "./BranchHeader.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as ActionDiffMemo from "./ActionDiffMemo.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as BranchStatusModel from "../../shared/models/BranchStatusModel.mjs";
import * as LoadingFullscreen from "./LoadingFullscreen.mjs";
import * as BranchDiscrepancies from "./BranchDiscrepancies.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as BranchImplementation from "./branchImplementation/BranchImplementation.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as ChangesWorkerInstance from "./workers/ChangesWorkerInstance.mjs";
import * as FlattenVariantsUseCase from "../../model/src/variants/flattenVariantsUseCase.mjs";
import * as BranchImplementationHooks from "./branchImplementation/BranchImplementationHooks.mjs";

function BranchView$BranchViewTab(Props) {
  var actions = Props.actions;
  var branchStatus = Props.branchStatus;
  var fromModel = Props.fromModel;
  var fromModelWithFlattenedEventVariants = Props.fromModelWithFlattenedEventVariants;
  var toModel = Props.toModel;
  var branchName = Props.branchName;
  var mergeWarnings = Props.mergeWarnings;
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchId = Props.branchId;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var masterSinceOpenedDiff = Props.masterSinceOpenedDiff;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var mergeConflicts = Props.mergeConflicts;
  var branchOpenAction = Props.branchOpenAction;
  var role = Props.role;
  var actionDiff = Props.actionDiff;
  var openBranches = Props.openBranches;
  var sourceUpdates = Props.sourceUpdates;
  var changes = Props.changes;
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var exit = 0;
  if (typeof schemaRoute === "object" && schemaRoute.NAME === "diff") {
    var match = schemaRoute.VAL;
    if (typeof match === "object") {
      if (match.NAME !== "comment") {
        return React.createElement(BranchImplementation.make, {
                    branchStatus: branchStatus,
                    fromModel: fromModel,
                    fromModelWithFlattenedEventVariants: fromModelWithFlattenedEventVariants,
                    subRoute: match.VAL,
                    toModel: toModel,
                    sourceUpdates: sourceUpdates
                  });
      }
      exit = 2;
    } else {
      exit = 2;
    }
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        return React.createElement(Redirect.make, {
                    path: Router.Link.getSchemaRouteLink(undefined, "dashboard")
                  });
    case 2 :
        return React.createElement(DiffView.make, {
                    branchStatus: branchStatus,
                    branchName: branchName,
                    mergeWarnings: mergeWarnings,
                    masterModel: masterModel,
                    branchModel: branchModel,
                    branchModelWithPulledMaster: branchModelWithPulledMaster,
                    branchId: branchId,
                    masterModelBeforeBranch: masterModelBeforeBranch,
                    masterSinceOpenedDiff: masterSinceOpenedDiff,
                    latestMasterAction: masterModelLatestAction,
                    actions: actions,
                    mergeConflicts: mergeConflicts,
                    branchOpenAction: branchOpenAction,
                    role: role,
                    actionDiff: actionDiff,
                    openBranches: openBranches,
                    sourceUpdates: sourceUpdates,
                    changes: changes
                  });
    
  }
}

var BranchViewTab = {
  make: BranchView$BranchViewTab
};

function BranchView$WithCurrentMasterOrMasterAtBranchMerge(Props) {
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchOpenAction = Props.branchOpenAction;
  var branchStatus = Props.branchStatus;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var currentMasterOrMasterAtBranchMergeOrClose = Props.currentMasterOrMasterAtBranchMergeOrClose;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = React.useReducer((function (state, action) {
          if (typeof action === "object") {
            return {
                    changes: action.VAL,
                    status: "loaded"
                  };
          } else {
            return {
                    changes: state.changes,
                    status: "loading"
                  };
          }
        }), {
        changes: Changes.empty,
        status: "loading"
      });
  var reduceChangeState = match[1];
  var changeState = match[0];
  var masterSinceOpenedDiff = ActionDiffMemo.useDiff(masterModelBeforeBranch, currentMasterOrMasterAtBranchMergeOrClose);
  var branchModelWithPulledMaster = React.useMemo((function () {
          return Belt_List.reduceU(masterSinceOpenedDiff, branchModel, Actions.reduce);
        }), [
        masterSinceOpenedDiff,
        branchModel
      ]);
  var match$1;
  match$1 = typeof branchStatus === "number" || branchStatus.TAG !== /* Merged */1 ? [
      masterModel,
      branchModelWithPulledMaster
    ] : [
      masterModelBeforeBranch,
      branchModel
    ];
  var toModel = match$1[1];
  var fromModel = match$1[0];
  var actionDiff = ActionDiffMemo.useDiff(fromModel, toModel);
  var actions = AvoUtils.actionByObject(actionDiff);
  var actions$1 = AvoUtils.groupActionsByObjectId(actions);
  var onBranchActions = AvoUtils.groupActionsByObjectId(AvoUtils.actionByObject(ActionDiffMemo.useDiff(masterModelBeforeBranch, branchModel)));
  var mergeWarnings = DiffUtils.getMergeWarnings(onBranchActions, masterModel);
  var branchActions = Belt_List.flatten(onBranchActions);
  var mergeConflicts = React.useMemo((function () {
          return DiffUtils.getMergeConflicts(branchActions, branchModel, masterModel);
        }), [
        branchModel,
        masterModel
      ]);
  var match$2 = branchOpenAction.contents;
  var branchName = typeof match$2 === "object" && match$2.NAME === "OpenBranch" ? match$2.VAL[1] : "N/A";
  var fromModelWithFlattenedEventVariants = React.useMemo((function () {
          return FlattenVariantsUseCase.flatten(fromModel);
        }), [fromModel]);
  var toModelWithFlattenedEventVariants = React.useMemo((function () {
          return FlattenVariantsUseCase.flatten(toModel);
        }), [toModel]);
  var fromModelWithFlattenedEventVariantsJson = React.useMemo((function () {
          return Printer.printModel(fromModelWithFlattenedEventVariants);
        }), [fromModelWithFlattenedEventVariants]);
  var toModelWithFlattenedEventVariantsJson = React.useMemo((function () {
          return Printer.printModel(toModelWithFlattenedEventVariants);
        }), [toModelWithFlattenedEventVariants]);
  React.useEffect((function () {
          Curry._1(reduceChangeState, "loading");
          $$Promise.$$catch(ChangesWorkerInstance.getModelChanges(fromModelWithFlattenedEventVariantsJson, toModelWithFlattenedEventVariantsJson).then(function (changes) {
                    return Curry._1(reduceChangeState, {
                                NAME: "loaded",
                                VAL: changes
                              });
                  }), (function (e) {
                  Sentry.captureException(e, {
                        message: "Failed to resolve changes from Worker",
                        schemaId: schemaBundle.schemaId,
                        branchId: schemaBundle.branchId
                      });
                  Curry._1(reduceChangeState, {
                        NAME: "loaded",
                        VAL: Changes.getChanges(fromModelWithFlattenedEventVariants, toModelWithFlattenedEventVariants)
                      });
                  return Promise.resolve(undefined);
                }));
          
        }), [
        fromModelWithFlattenedEventVariants,
        toModelWithFlattenedEventVariants
      ]);
  var sourceUpdates = BranchImplementationHooks.useSourceUpdates(toModelWithFlattenedEventVariants.sources, false, changeState.changes);
  return React.createElement(BranchDiscrepancies.Provider.make, {
              mainModelToCompareTo: fromModelWithFlattenedEventVariants,
              mainModelToCompareToJson: fromModelWithFlattenedEventVariantsJson,
              branchModelToCompareTo: toModelWithFlattenedEventVariants,
              branchModelToCompareToJson: toModelWithFlattenedEventVariantsJson,
              children: null
            }, React.createElement(BranchView$BranchViewTab, {
                  actions: actions$1,
                  branchStatus: branchStatus,
                  fromModel: fromModel,
                  fromModelWithFlattenedEventVariants: fromModelWithFlattenedEventVariants,
                  toModel: toModel,
                  branchName: branchName,
                  mergeWarnings: mergeWarnings,
                  masterModel: masterModel,
                  branchModel: branchModel,
                  branchModelWithPulledMaster: branchModelWithPulledMaster,
                  branchId: branchId,
                  masterModelBeforeBranch: masterModelBeforeBranch,
                  masterSinceOpenedDiff: masterSinceOpenedDiff,
                  masterModelLatestAction: masterModelLatestAction,
                  mergeConflicts: mergeConflicts,
                  branchOpenAction: branchOpenAction,
                  role: role,
                  actionDiff: actionDiff,
                  openBranches: openBranches,
                  sourceUpdates: sourceUpdates,
                  changes: changeState.changes
                }), React.createElement(BranchHeader.make, {
                  branchId: branchId,
                  branchName: branchName,
                  branchStatus: branchStatus,
                  branchModel: branchModel,
                  changeStatus: changeState.status,
                  masterModel: masterModel,
                  branchOpenAction: branchOpenAction,
                  sourceUpdates: sourceUpdates
                }));
}

var WithCurrentMasterOrMasterAtBranchMerge = {
  make: BranchView$WithCurrentMasterOrMasterAtBranchMerge
};

function BranchView$WithMasterModel(Props) {
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchOpenAction = Props.branchOpenAction;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var masterModel = Props.masterModel;
  var maybeCloseAction = Props.maybeCloseAction;
  var maybeMergeAction = Props.maybeMergeAction;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var setStateActions = Props.setStateActions;
  var branchStatus = BranchStatusModel.get(branchOpenAction, maybeMergeAction, maybeCloseAction, setStateActions);
  var match = branchModel.branchPointer;
  var match$1 = branchModel.branchPointer;
  var fetchStateMainBeforeBranch = FetchModel.use(match !== undefined ? undefined : Models.toDateOption(Models.Action.getCreatedAt(branchOpenAction)), match$1 !== undefined ? Caml_option.some(new Date(match$1[1])) : undefined, "master");
  var branchMergeOrCloseTimestamp = maybeMergeAction !== undefined ? Models.toDateOption(Models.Action.getCreatedAt(Caml_option.valFromOption(maybeMergeAction))) : (
      maybeCloseAction !== undefined ? Models.toDateOption(Models.Action.getCreatedAt(Caml_option.valFromOption(maybeCloseAction))) : undefined
    );
  var fetchStateMainAtMergeOrClose = FetchModel.use(undefined, branchMergeOrCloseTimestamp, "master");
  var message;
  var exit = 0;
  switch (fetchStateMainBeforeBranch.TAG | 0) {
    case /* Loading */0 :
        return React.createElement(LoadingFullscreen.make, {
                    message: fetchStateMainBeforeBranch._0
                  });
    case /* Error */1 :
        exit = 2;
        break;
    case /* Result */2 :
        var masterModelBeforeBranch = fetchStateMainBeforeBranch._0;
        if (branchMergeOrCloseTimestamp === undefined) {
          return React.createElement(BranchView$WithCurrentMasterOrMasterAtBranchMerge, {
                      branchId: branchId,
                      branchModel: branchModel,
                      branchOpenAction: branchOpenAction,
                      branchStatus: branchStatus,
                      masterModelLatestAction: masterModelLatestAction,
                      masterModel: masterModel,
                      masterModelBeforeBranch: masterModelBeforeBranch,
                      currentMasterOrMasterAtBranchMergeOrClose: masterModel,
                      openBranches: openBranches,
                      role: role
                    });
        }
        switch (fetchStateMainAtMergeOrClose.TAG | 0) {
          case /* Loading */0 :
              exit = 2;
              break;
          case /* Error */1 :
              message = fetchStateMainAtMergeOrClose._0;
              break;
          case /* Result */2 :
              return React.createElement(BranchView$WithCurrentMasterOrMasterAtBranchMerge, {
                          branchId: branchId,
                          branchModel: branchModel,
                          branchOpenAction: branchOpenAction,
                          branchStatus: branchStatus,
                          masterModelLatestAction: masterModelLatestAction,
                          masterModel: masterModel,
                          masterModelBeforeBranch: masterModelBeforeBranch,
                          currentMasterOrMasterAtBranchMergeOrClose: fetchStateMainAtMergeOrClose._0,
                          openBranches: openBranches,
                          role: role
                        });
          
        }
        break;
    
  }
  if (exit === 2) {
    if (branchMergeOrCloseTimestamp !== undefined) {
      switch (fetchStateMainAtMergeOrClose.TAG | 0) {
        case /* Loading */0 :
            return React.createElement(LoadingFullscreen.make, {
                        message: fetchStateMainAtMergeOrClose._0
                      });
        case /* Error */1 :
        case /* Result */2 :
            message = fetchStateMainBeforeBranch._0;
            break;
        
      }
    } else {
      message = fetchStateMainBeforeBranch._0;
    }
  }
  return React.createElement(FetchModel.$$Error.make, {
              message: message
            });
}

var WithMasterModel = {
  make: BranchView$WithMasterModel
};

function BranchView(Props) {
  var masterModel = Props.masterModel;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var branchModel = Props.branchModel;
  var branchId = Props.branchId;
  var role = Props.role;
  var openBranches = Props.openBranches;
  var workspace = WorkspaceContext.use(undefined);
  var branchLifecycleState = FirebaseFetcherHooks.useBranchLifecycleActions(workspace.id, branchId, undefined, undefined);
  var branchOpenAction = branchLifecycleState[0];
  if (branchOpenAction === undefined) {
    return React.createElement(LoadingCircle.make, {});
  }
  var branchOpenAction$1 = Caml_option.valFromOption(branchOpenAction);
  var match = branchModel.branchPointer;
  return React.createElement(BranchView$WithMasterModel, {
              branchId: branchId,
              branchModel: branchModel,
              branchOpenAction: branchOpenAction$1,
              masterModelLatestAction: masterModelLatestAction,
              masterModel: masterModel,
              maybeCloseAction: branchLifecycleState[2],
              maybeMergeAction: branchLifecycleState[1],
              openBranches: openBranches,
              role: role,
              setStateActions: branchLifecycleState[3],
              key: "masterModelRefetchKey-" + (
                match !== undefined ? match[1] : Belt_Option.getWithDefault(Belt_Option.map(Caml_option.nullable_to_opt(Models.Action.getCreatedAt(branchOpenAction$1)), (function (date) {
                              return date.toDate().toString();
                            })), "")
              )
            });
}

var make = BranchView;

export {
  BranchViewTab ,
  WithCurrentMasterOrMasterAtBranchMerge ,
  WithMasterModel ,
  make ,
  
}
/* react Not a pure module */
