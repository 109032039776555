// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as DiffComments from "./DiffComments.mjs";
import * as DiffEventTags from "./DiffEventTags.mjs";
import * as DiffComponents from "./DiffComponents.mjs";
import * as DiffEventGroups from "./DiffEventGroups.mjs";
import * as DiffEventActions from "./DiffEventActions.mjs";
import * as DiffEventMetrics from "./DiffEventMetrics.mjs";
import * as DiffEventSources from "./DiffEventSources.mjs";
import * as DiffEventTriggers from "./DiffEventTriggers.mjs";
import * as DiffEventVariants from "./DiffEventVariants.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as DiffEventGlobalPill from "./DiffEventGlobalPill.mjs";
import * as DiffEventProperties from "./DiffEventProperties.mjs";
import * as DiffItemAuditIssues from "./DiffItemAuditIssues.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as DiffEventDescription from "./DiffEventDescription.mjs";
import * as DiffEventNameMapping from "./DiffEventNameMapping.mjs";

function unarchiveEvent($$event, branchModel, sendActions, branchId, schemaBundle) {
  var archivedName = $$event.name;
  var conflictingEvents = Belt_List.keepMapU(branchModel.events, (function ($$event) {
          if (archivedName === $$event.name || archivedName.toLowerCase() === $$event.name.toLowerCase() || Case.camel(archivedName) === Case.camel($$event.name) || Case.snake(archivedName) === Case.snake($$event.name)) {
            return $$event.name;
          }
          
        }));
  if (conflictingEvents) {
    if (conflictingEvents.tl) {
      window.alert("Can't unarchive event because events with conflicting names already exist: " + Belt_List.toArray(conflictingEvents).join(", "));
    } else {
      window.alert("Can't unarchive event because an event with a conflicting name already exists: " + conflictingEvents.hd);
    }
  } else {
    Curry.app(sendActions, [
          undefined,
          false,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (_branch) {
              Router.Schema.pushDrawerItem({
                    NAME: "branch",
                    VAL: branchId
                  }, undefined, {
                    NAME: "event",
                    VAL: [
                      $$event.id,
                      undefined,
                      undefined,
                      false
                    ]
                  });
              return AnalyticsRe.itemUnarchived(schemaBundle, $$event.id, $$event.name, "Event", "BranchReview", schemaBundle.schemaId, schemaBundle.branchId);
            }),
          undefined,
          [[
              {
                NAME: "Unarchive",
                VAL: {
                  NAME: "Event",
                  VAL: $$event.id
                }
              },
              {
                eventId: $$event.id,
                eventQuery: $$event.id
              }
            ]]
        ]);
  }
  
}

function DiffEvent(Props) {
  var branchStatus = Props.branchStatus;
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var branchId = Props.branchId;
  var goToCommentId = Props.goToCommentId;
  var events = Props.events;
  var withComments = Props.withComments;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  if (match.NAME !== "Event") {
    return null;
  }
  var eventId = match.VAL;
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  var hasCreate = Belt_List.getBy(actions$1, (function (action) {
          if (typeof action === "object") {
            return action.NAME === "AddEvent";
          } else {
            return false;
          }
        }));
  var hasArchive = Belt_List.getBy(actions$1, (function (action) {
          if (typeof action !== "object") {
            return false;
          }
          if (action.NAME !== "Archive") {
            return false;
          }
          var match = action.VAL;
          if (typeof match === "object") {
            return match.NAME === "Event";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasArchive !== undefined ? "Archive" : "Update"
    );
  var $$event = Belt_Option.getWithDefault(Belt_List.getBy(masterModel.events, (function ($$event) {
              return $$event.id === eventId;
            })), TrackingPlanModel.emptyEvent(eventId, ""));
  var nextEvent = Belt_Option.getWithDefault(Belt_List.getBy(branchModel.events, (function ($$event) {
              return $$event.id === eventId;
            })), TrackingPlanModel.emptyEvent(eventId, ""));
  var tmp;
  if (withComments) {
    var tmp$1 = {
      branchId: branchId,
      threadId: $$event.id,
      itemType: "Event",
      itemName: $$event.name,
      events: events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp = React.createElement(DiffComments.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: /* [] */0
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.alignSelf("flexStart"),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: {
                            hd: Css.maxWidth(DiffComponents.maxWidthPx),
                            tl: {
                              hd: Css.cursor("pointer"),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.backgroundColor(Styles.Color.light01),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }),
                  onClick: (function (param) {
                      return Router.Schema.pushDrawerItem(undefined, undefined, {
                                  NAME: "event",
                                  VAL: [
                                    $$event.id,
                                    undefined,
                                    undefined,
                                    false
                                  ]
                                });
                    })
                }, changeType === "Update" || changeType === "Create" ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("relative"),
                              tl: {
                                hd: Css.width(Css.pct(100)),
                                tl: {
                                  hd: Css.borderRadius(Styles.Border.radius),
                                  tl: changeType === "Create" ? ({
                                        hd: Css.border(Css.px(1), "solid", Styles.Color.darkGreen),
                                        tl: /* [] */0
                                      }) : (
                                      changeType === "Update" ? ({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.light06),
                                            tl: /* [] */0
                                          }) : ({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.darkRed),
                                            tl: /* [] */0
                                          })
                                    )
                                }
                              }
                            })
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.borderTopLeftRadius(Styles.Border.radius),
                                  tl: {
                                    hd: Css.borderTopRightRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.regular),
                                        tl: {
                                          hd: Css.padding(Css.px(20)),
                                          tl: {
                                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light06),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement(DiffItemAuditIssues.make, {
                                branchStatus: branchStatus,
                                itemId: $$event.id,
                                children: null
                              }, JsDiff.renderStringDiff(undefined, true, undefined, $$event.name, nextEvent.name), React.createElement(DiffEventGlobalPill.make, {
                                    eventId: nextEvent.id,
                                    globalRequirements: branchModel.globalRequirements
                                  }))), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(10), Css.px(20)),
                                  tl: /* [] */0
                                })
                          }, React.createElement(DiffEventDescription.make, {
                                event: $$event,
                                actions: actions$1
                              }), React.createElement(DiffEventTriggers.make, {
                                branchModel: branchModel,
                                masterModel: masterModel,
                                triggers: $$event.triggers,
                                nextTriggers: nextEvent.triggers,
                                actions: actions$1
                              }), React.createElement(DiffEventNameMapping.make, {
                                actions: actions$1,
                                branchModel: branchModel,
                                masterModel: masterModel
                              }), React.createElement(DiffEventGroups.make, {
                                branchModel: branchModel,
                                actions: actions$1
                              }), React.createElement(DiffEventActions.make, {
                                actions: actions$1
                              }), React.createElement(DiffEventTags.make, {
                                event: $$event,
                                actions: actions$1
                              }), React.createElement(DiffEventSources.make, {
                                branchModel: branchModel,
                                masterModel: masterModel,
                                actions: actions$1
                              }), React.createElement(DiffEventMetrics.make, {
                                branchModel: branchModel,
                                masterModel: masterModel,
                                actions: actions$1
                              }), React.createElement(DiffEventProperties.make, {
                                branchModel: branchModel,
                                masterModel: masterModel,
                                nextEvent: nextEvent,
                                actions: actions$1
                              }), React.createElement(DiffEventVariants.make, {
                                branchModel: branchModel,
                                masterModel: masterModel,
                                event: $$event,
                                nextEvent: nextEvent,
                                actions: Belt_List.toArray(actions$1)
                              }))) : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("spaceBetween"),
                                  tl: {
                                    hd: Css.width(Css.pct(100)),
                                    tl: {
                                      hd: Css.borderRadius(Styles.Border.radius),
                                      tl: {
                                        hd: Css.border(Css.px(1), "solid", Styles.Color.darkRed),
                                        tl: {
                                          hd: Css.padding(Css.px(20)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            element: "Div",
                            size: "Medium",
                            weight: "Semi",
                            children: JsDiff.renderStringDiff(undefined, true, undefined, $$event.name, nextEvent.name)
                          }), React.createElement(ContextMenu.make, {
                            options: [{
                                NAME: "Option",
                                VAL: {
                                  label: "Restore Event",
                                  onClick: (function (param) {
                                      return unarchiveEvent($$event, branchModel, sendActions, branchId, schemaBundle);
                                    })
                                }
                              }]
                          }))), tmp);
}

var make = DiffEvent;

export {
  unarchiveEvent ,
  make ,
  
}
/* Css Not a pure module */
