// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "../Hover.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as $$Number from "../Number.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as StringExt from "../StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as IconArrowUp from "../IconArrowUp.mjs";
import * as IconArrowDown from "../IconArrowDown.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as IconTrackingPlan from "../IconTrackingPlan.mjs";
import * as ModelUtils_mapped from "../ModelUtils_mapped.mjs";
import * as InspectorIssueType from "../../../shared/models/InspectorIssueType.mjs";
import * as IconCheckmarkCircle from "../IconCheckmarkCircle.mjs";
import * as InspectorViewConfig from "./InspectorViewConfig.mjs";
import * as InspectorPropertyType from "../../../shared/models/InspectorPropertyType.mjs";
import * as InspectorViewSelectors from "./InspectorViewSelectors.mjs";
import * as InspectorIssuesGenerator from "../../../api/functions/src/inspectorPipeline/utils/InspectorIssuesGenerator.mjs";
import * as InspectorEventViewModelUtils from "./InspectorEventViewModelUtils.mjs";
import * as InspectorViewTrackingPlanUtils from "./InspectorViewTrackingPlanUtils.mjs";

function countIssueTypes(issues) {
  return Belt_MapString.toArray(Belt_Array.reduceU(issues, undefined, (function (groupedIssues, issue) {
                    return Belt_MapString.update(groupedIssues, InspectorIssueType.getIssueTypeAsText(issue), (function (count) {
                                  return Belt_Option.mapWithDefault(count, 1, (function (c) {
                                                return c + 1 | 0;
                                              }));
                                }));
                  })));
}

function getSummary(withCountOpt, issues) {
  var withCount = withCountOpt !== undefined ? withCountOpt : true;
  return Belt_Array.mapU(countIssueTypes(issues), (function (param) {
                return React.createElement($$Text.make, {
                            size: "Tiny",
                            children: null
                          }, param[0], withCount ? React.createElement($$Text.make, {
                                  element: "Span",
                                  weight: "Semi",
                                  children: " (" + String(param[1]) + ")"
                                }) : null);
              }));
}

var containerStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var newStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(4)),
      tl: {
        hd: Css.paddingTop(Css.px(2)),
        tl: {
          hd: Css.paddingBottom(Css.px(1)),
          tl: {
            hd: Css.paddingLeft(Css.px(4)),
            tl: {
              hd: Css.paddingRight(Css.px(4)),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.light02),
                  tl: {
                    hd: Css.borderRadius(Css.px(3)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function InspectorViewCells$Volume(Props) {
  var value = Props.value;
  var colorOpt = Props.color;
  var isNewOpt = Props.isNew;
  var percentageOpt = Props.percentage;
  var showDashForZeroOpt = Props.showDashForZero;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light12;
  var isNew = isNewOpt !== undefined ? isNewOpt : false;
  var percentage = percentageOpt !== undefined ? percentageOpt : false;
  var showDashForZero = showDashForZeroOpt !== undefined ? showDashForZeroOpt : false;
  return React.createElement("div", {
              className: containerStyle
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: color,
                  children: showDashForZero && value === 0 ? React.createElement($$Text.make, {
                          element: "Span",
                          color: Styles.Color.light08,
                          children: "—"
                        }) : $$Number.shortFormat(value) + (
                      percentage ? "%" : ""
                    )
                }), isNew ? React.createElement("div", {
                    className: newStyles
                  }, React.createElement($$Text.make, {
                        size: "Tiny",
                        weight: "Semi",
                        color: Styles.Color.light08,
                        children: "NEW"
                      })) : null);
}

var Volume = {
  containerStyle: containerStyle,
  newStyles: newStyles,
  make: InspectorViewCells$Volume
};

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.height(Css.px(14)),
            tl: {
              hd: Css.minWidth(Css.px(14)),
              tl: {
                hd: Css.paddingLeft(Css.px(3)),
                tl: {
                  hd: Css.paddingRight(Css.px(3)),
                  tl: {
                    hd: Css.borderRadius(Css.px(7)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.red),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function InspectorViewCells$IssueCount(Props) {
  var value = Props.value;
  var showDashForZeroOpt = Props.showDashForZero;
  var showDashForZero = showDashForZeroOpt !== undefined ? showDashForZeroOpt : false;
  if (showDashForZero) {
    if (value === 0) {
      return React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light08,
                  children: "—"
                });
    }
    
  } else if (value === 0) {
    return React.createElement(IconCheckmarkCircle.make, {
                size: 14,
                color: Styles.Color.light08
              });
  }
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement($$Text.make, {
                  size: "Tiny",
                  weight: "Bold",
                  color: Styles.Color.white,
                  children: String(value)
                }));
}

var IssueCount = {
  rootStyles: rootStyles,
  make: InspectorViewCells$IssueCount
};

function InspectorViewCells$CellContainer(Props) {
  var isActiveCellOpt = Props.isActiveCell;
  var activeBorderOpenRightOpt = Props.activeBorderOpenRight;
  var activeBorderOpenLeftOpt = Props.activeBorderOpenLeft;
  var leftPadding = Props.leftPadding;
  var onClick = Props.onClick;
  var leftBorderOpt = Props.leftBorder;
  var propertyOpt = Props.property;
  var children = Props.children;
  var isActiveCell = isActiveCellOpt !== undefined ? isActiveCellOpt : false;
  var activeBorderOpenRight = activeBorderOpenRightOpt !== undefined ? activeBorderOpenRightOpt : false;
  var activeBorderOpenLeft = activeBorderOpenLeftOpt !== undefined ? activeBorderOpenLeftOpt : false;
  var leftBorder = leftBorderOpt !== undefined ? leftBorderOpt : false;
  var property = propertyOpt !== undefined ? propertyOpt : false;
  return React.createElement("button", {
              className: Curry._1(Css.style, Belt_List.concatMany([
                        Styles.button,
                        {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: {
                                hd: Css.borderBottomColor(property ? Styles.Color.light04 : Styles.Color.light02),
                                tl: {
                                  hd: Css.borderLeftColor(leftBorder ? (
                                          property ? Styles.Color.light04 : Styles.Color.light02
                                        ) : "transparent"),
                                  tl: {
                                    hd: Css.border(Css.px(1), "solid", "transparent"),
                                    tl: {
                                      hd: Css.paddingTop(Css.px(0)),
                                      tl: {
                                        hd: Css.paddingRight(Css.px(7)),
                                        tl: {
                                          hd: Css.paddingBottom(Css.px(0)),
                                          tl: {
                                            hd: Css.paddingLeft(Css.px(Belt_Option.getWithDefault(leftPadding, 8))),
                                            tl: {
                                              hd: Css.margin(Css.px(0)),
                                              tl: {
                                                hd: Css.height(Css.px(InspectorViewConfig.eventRowHeight)),
                                                tl: {
                                                  hd: Css.backgroundColor(property ? Styles.Color.light02 : Styles.Color.white),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        },
                        isActiveCell ? (
                            activeBorderOpenLeft ? (
                                activeBorderOpenRight ? ({
                                      hd: Css.paddingLeft(Css.px(Belt_Option.getWithDefault(leftPadding, 8))),
                                      tl: {
                                        hd: Css.borderBottomColor(Styles.Color.darkBlue),
                                        tl: {
                                          hd: Css.borderLeftColor(Styles.Color.darkBlue),
                                          tl: {
                                            hd: Css.border(Css.px(2), "solid", Styles.Color.darkBlue),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }) : ({
                                      hd: Css.borderLeftWidth(Css.px(0)),
                                      tl: {
                                        hd: Css.paddingRight(Css.px(7)),
                                        tl: {
                                          hd: Css.paddingLeft(Css.px(9)),
                                          tl: {
                                            hd: Css.borderBottomColor(Styles.Color.darkBlue),
                                            tl: {
                                              hd: Css.border(Css.px(2), "solid", Styles.Color.darkBlue),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    })
                              ) : (
                                activeBorderOpenRight ? ({
                                      hd: Css.borderRightWidth(Css.px(0)),
                                      tl: {
                                        hd: Css.paddingLeft(Css.px(Belt_Option.getWithDefault(leftPadding, 8) - 1 | 0)),
                                        tl: {
                                          hd: Css.borderBottomColor(Styles.Color.darkBlue),
                                          tl: {
                                            hd: Css.borderLeftColor(Styles.Color.darkBlue),
                                            tl: {
                                              hd: Css.border(Css.px(2), "solid", Styles.Color.darkBlue),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }) : ({
                                      hd: Css.paddingLeft(Css.px(Belt_Option.getWithDefault(leftPadding, 8) - 1 | 0)),
                                      tl: {
                                        hd: Css.paddingRight(Css.px(7)),
                                        tl: {
                                          hd: Css.borderBottomColor(Styles.Color.darkBlue),
                                          tl: {
                                            hd: Css.border(Css.px(2), "solid", Styles.Color.darkBlue),
                                            tl: {
                                              hd: Css.borderLeftColor(Styles.Color.darkBlue),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    })
                              )
                          ) : /* [] */0
                      ])),
              onClick: onClick
            }, children);
}

var CellContainer = {
  make: InspectorViewCells$CellContainer
};

function tooltipStyles(color) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.position("absolute"),
                  tl: {
                    hd: Css.top(Css.px(-10)),
                    tl: {
                      hd: Css.right(Css.pct(100.0)),
                      tl: {
                        hd: Css.marginRight(Css.px(8)),
                        tl: {
                          hd: Css.backgroundColor(color),
                          tl: {
                            hd: Css.padding2(Css.px(2), Css.px(5)),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.width(Css.px(160)),
                                tl: {
                                  hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                  tl: {
                                    hd: Css.textAlign("left"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function tooltipArrowStyles(color) {
  return Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.top(Css.px(0)),
                tl: {
                  hd: Css.right(Css.px(-8)),
                  tl: {
                    hd: Css.width(Css.px(0)),
                    tl: {
                      hd: Css.height(Css.px(0)),
                      tl: {
                        hd: Css.borderRightColor(color),
                        tl: {
                          hd: Css.border(Css.px(5), "solid", "transparent"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function InspectorViewCells$TrackingPlanStatus(Props) {
  var text = Props.text;
  var color = Props.color;
  return React.createElement(Hover.make, {
              children: (function (isHover) {
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.position("relative"),
                                    tl: {
                                      hd: Css.paddingTop(Css.px(2)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement(IconTrackingPlan.make, {
                                  color: color
                                }), isHover ? React.createElement("div", {
                                    className: tooltipStyles(color)
                                  }, React.createElement("div", {
                                        className: tooltipArrowStyles(color)
                                      }), React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.white,
                                        children: text
                                      })) : null);
                })
            });
}

var TrackingPlanStatus = {
  tooltipStyles: tooltipStyles,
  tooltipArrowStyles: tooltipArrowStyles,
  make: InspectorViewCells$TrackingPlanStatus
};

function tooltipStyles$1(color) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.position("absolute"),
                  tl: {
                    hd: Css.top(Css.px(5)),
                    tl: {
                      hd: Css.left(Css.pct(100.0)),
                      tl: {
                        hd: Css.marginLeft(Css.px(8)),
                        tl: {
                          hd: Css.backgroundColor(color),
                          tl: {
                            hd: Css.padding(Css.px(8)),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.width(Css.px(160)),
                                tl: {
                                  hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                  tl: {
                                    hd: Css.textAlign("left"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function tooltipArrowStyles$1(color) {
  return Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.top(Css.px(3)),
                tl: {
                  hd: Css.left(Css.px(-8)),
                  tl: {
                    hd: Css.width(Css.px(0)),
                    tl: {
                      hd: Css.height(Css.px(0)),
                      tl: {
                        hd: Css.borderRightColor(color),
                        tl: {
                          hd: Css.border(Css.px(5), "solid", "transparent"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function InspectorViewCells$CellButtonWithTooltip(Props) {
  var tooltipText = Props.tooltipText;
  var buttonLabel = Props.buttonLabel;
  var onClick = Props.onClick;
  return React.createElement(Hover.make, {
              children: (function (isHover) {
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.position("relative"),
                                    tl: /* [] */0
                                  })
                            }, React.createElement(Button.make, {
                                  label: buttonLabel,
                                  onClick: Curry.__1(onClick),
                                  size: "tiny",
                                  style: "ghost",
                                  minWidth: 48
                                }), isHover ? React.createElement("div", {
                                    className: tooltipStyles$1(Styles.Color.light08)
                                  }, React.createElement("div", {
                                        className: tooltipArrowStyles$1(Styles.Color.light08)
                                      }), React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.white,
                                        children: StringExt.widowless(tooltipText)
                                      })) : null);
                })
            });
}

var CellButtonWithTooltip = {
  tooltipStyles: tooltipStyles$1,
  tooltipArrowStyles: tooltipArrowStyles$1,
  make: InspectorViewCells$CellButtonWithTooltip
};

function InspectorViewCells$PropertyNameCell(Props) {
  var $$event = Props.event;
  var property = Props.property;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  var onAddToTrackingPlan = Props.onAddToTrackingPlan;
  var model = Props.model;
  var worstIssue = InspectorViewTrackingPlanUtils.getWorstPropertyIssue(property);
  var worstIssue$1 = worstIssue !== undefined && typeof worstIssue === "object" && worstIssue.NAME === "UnexpectedProperty" && !InspectorViewTrackingPlanUtils.isUnexpectedPropertyOnAllSources(property) ? undefined : worstIssue;
  var types = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.mapU(property.sources, (function (source) {
                            return source.propertyTypes;
                          }))), (function (propertyType) {
                    return propertyType.type_;
                  })))).join(", ");
  var match = InspectorViewSelectors.getEventTrackingPlanStatus(undefined, $$event);
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  if (match === "EventNotInTrackingPlan") {
    tmp = null;
  } else {
    exit$1 = 2;
  }
  if (exit$1 === 2) {
    if (worstIssue$1 !== undefined) {
      if (typeof worstIssue$1 === "object") {
        var variant = worstIssue$1.NAME;
        if (variant === "PropertyNotInTrackingPlan") {
          tmp = React.createElement(InspectorViewCells$TrackingPlanStatus, {
                text: "Property Not Found in Tracking Plan",
                color: Styles.Color.red
              });
        } else if (variant === "UnexpectedProperty") {
          tmp = React.createElement(InspectorViewCells$TrackingPlanStatus, {
                text: "Property Not Found on Event in Tracking Plan",
                color: Styles.Color.red
              });
        } else if (variant === "PropertyTypeInconsistentWithTrackingPlan") {
          exit = 1;
        } else {
          tmp = null;
        }
      } else {
        tmp = null;
      }
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tmp = React.createElement(InspectorViewCells$TrackingPlanStatus, {
          text: "Property found on Event in Tracking Plan",
          color: Styles.Color.light08
        });
  }
  var match$1 = InspectorViewSelectors.getEventTrackingPlanStatus(undefined, $$event);
  var tmp$1;
  var exit$2 = 0;
  var exit$3 = 0;
  if (match$1 === "EventNotInTrackingPlan") {
    tmp$1 = null;
  } else {
    exit$3 = 2;
  }
  if (exit$3 === 2) {
    if (worstIssue$1 !== undefined) {
      if (typeof worstIssue$1 === "object") {
        var variant$1 = worstIssue$1.NAME;
        if (variant$1 === "UnexpectedProperty" || variant$1 === "PropertyNotInTrackingPlan") {
          tmp$1 = React.createElement(InspectorViewCells$CellButtonWithTooltip, {
                tooltipText: "Add Property to tracking plan",
                buttonLabel: "Add",
                onClick: (function (e) {
                    e.stopPropagation();
                    return Curry._1(onAddToTrackingPlan, undefined);
                  })
              });
        } else if (variant$1 === "PropertyTypeInconsistentWithTrackingPlan") {
          exit$2 = 1;
        } else {
          tmp$1 = null;
        }
      } else {
        tmp$1 = null;
      }
    } else {
      exit$2 = 1;
    }
  }
  if (exit$2 === 1) {
    tmp$1 = React.createElement(InspectorViewCells$CellButtonWithTooltip, {
          tooltipText: "Edit Property in Tracking Plan",
          buttonLabel: "Edit",
          onClick: (function (e) {
              e.stopPropagation();
              var modelProperty = ModelUtils.getPropertyByName(property.propertyName, model);
              if (modelProperty !== undefined) {
                return Router.Schema.pushDrawerItem(undefined, undefined, {
                            NAME: "property",
                            VAL: [
                              modelProperty.id,
                              undefined
                            ]
                          });
              }
              
            })
        });
  }
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              leftPadding: 48,
              onClick: onClick,
              property: true,
              children: null
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.color(Styles.Color.light10),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.textOverflow("ellipsis"),
                            tl: {
                              hd: Css.whiteSpace("nowrap"),
                              tl: {
                                hd: Css.overflow("hidden"),
                                tl: {
                                  hd: Css.fontSize(Css.px(12)),
                                  tl: {
                                    hd: Css.flexShrink(0.0),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, property.propertyName), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.color(Styles.Color.light10),
                        tl: {
                          hd: Css.marginLeft(Css.px(10)),
                          tl: {
                            hd: Css.textOverflow("ellipsis"),
                            tl: {
                              hd: Css.whiteSpace("nowrap"),
                              tl: {
                                hd: Css.overflow("hidden"),
                                tl: {
                                  hd: Css.fontSize(Css.px(12)),
                                  tl: {
                                    hd: Css.flexShrink(1.0),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, types), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flex("auto"),
                          tl: {
                            hd: Css.flexDirection("row"),
                            tl: {
                              hd: Css.justifyContent("flexEnd"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.height(Css.pct(50)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.alignSelf("flexEnd"),
                            tl: {
                              hd: Css.paddingRight(Css.px(4)),
                              tl: /* [] */0
                            }
                          })
                    }, tmp), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.alignSelf("flexStart"),
                            tl: /* [] */0
                          })
                    }, tmp$1)));
}

var PropertyNameCell = {
  make: InspectorViewCells$PropertyNameCell
};

function InspectorViewCells$PropertyNameCell_(Props) {
  var $$event = Props.event;
  var property = Props.property;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  var rules = Props.rules;
  var mappedDestinations = Props.mappedDestinations;
  var mappedEvents = Props.mappedEvents;
  var mappedProperties = Props.mappedProperties;
  var worstIssue = InspectorViewTrackingPlanUtils.getWorstPropertyIssue_(property);
  var modelEvent = InspectorIssuesGenerator.getTrackingPlanEvent_mappedModel(mappedEvents, rules, $$event.eventName);
  var modelProperty = ModelUtils_mapped.getPropertyByNameOrNameMapping(rules, mappedDestinations, mappedProperties, property.propertyName, Belt_Option.map(modelEvent, (function ($$event) {
              return $$event.id;
            })));
  var propertyExists = Belt_Option.isSome(modelProperty);
  var worstIssue$1 = worstIssue !== undefined && !(typeof worstIssue === "number" || !(worstIssue.TAG === /* UnexpectedProperty */2 && !InspectorViewTrackingPlanUtils.isUnexpectedPropertyOnAllSources_(property))) ? undefined : worstIssue;
  var types = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(property.propertyShape.propertyTypes, (function (param) {
                    return InspectorPropertyType.toString(param.type_);
                  })))).join(", ");
  var tmp;
  var exit = 0;
  if (propertyExists) {
    if (worstIssue$1 !== undefined && typeof worstIssue$1 !== "number") {
      switch (worstIssue$1.TAG | 0) {
        case /* PropertyTypeInconsistentWithTrackingPlan */1 :
            exit = 1;
            break;
        case /* UnexpectedProperty */2 :
            tmp = React.createElement(React.Fragment, undefined, React.createElement(InspectorViewCells$TrackingPlanStatus, {
                      text: "Property not found on event in Tracking Plan",
                      color: Styles.Color.red
                    }), React.createElement(InspectorViewCells$CellButtonWithTooltip, {
                      tooltipText: "Edit Property in Tracking Plan",
                      buttonLabel: "Edit",
                      onClick: (function (e) {
                          e.stopPropagation();
                          if (modelProperty !== undefined) {
                            return Router.Schema.pushDrawerItem(undefined, undefined, {
                                        NAME: "property",
                                        VAL: [
                                          modelProperty.id,
                                          undefined
                                        ]
                                      });
                          }
                          
                        })
                    }));
            break;
        default:
          exit = 1;
      }
    } else {
      exit = 1;
    }
  } else {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(InspectorViewCells$TrackingPlanStatus, {
              text: "Property Not Found in Tracking Plan",
              color: Styles.Color.red
            }), React.createElement(Spacer.make, {
              width: 48
            }));
  }
  if (exit === 1) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(InspectorViewCells$TrackingPlanStatus, {
              text: "Property found on Event in Tracking Plan",
              color: Styles.Color.light08
            }), React.createElement(InspectorViewCells$CellButtonWithTooltip, {
              tooltipText: "Edit Property in Tracking Plan",
              buttonLabel: "Edit",
              onClick: (function (e) {
                  e.stopPropagation();
                  if (modelProperty !== undefined) {
                    return Router.Schema.pushDrawerItem(undefined, undefined, {
                                NAME: "property",
                                VAL: [
                                  modelProperty.id,
                                  undefined
                                ]
                              });
                  }
                  
                })
            }));
  }
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              leftPadding: 48,
              onClick: onClick,
              property: true,
              children: null
            }, React.createElement("div", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.maxLines(1)),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.gap(Css.px(10)),
                                    tl: /* [] */0
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.color(Styles.Color.light10),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.textOverflow("ellipsis"),
                                tl: {
                                  hd: Css.whiteSpace("nowrap"),
                                  tl: {
                                    hd: Css.overflow("hidden"),
                                    tl: {
                                      hd: Css.fontSize(Css.px(12)),
                                      tl: {
                                        hd: Css.flexShrink(0.0),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          singleLine: true,
                          maxWidth: Css.px(276),
                          children: property.propertyName
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.color(Styles.Color.light10),
                            tl: {
                              hd: Css.textOverflow("ellipsis"),
                              tl: {
                                hd: Css.whiteSpace("nowrap"),
                                tl: {
                                  hd: Css.overflow("hidden"),
                                  tl: {
                                    hd: Css.fontSize(Css.px(12)),
                                    tl: {
                                      hd: Css.flexShrink(1.0),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, types)), React.createElement(Spacer.make, {
                  width: 8,
                  grow: 1.0
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.gap(Css.px(4)),
                            tl: {
                              hd: Css.height(Css.pct(50)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, tmp));
}

var PropertyNameCell_ = {
  make: InspectorViewCells$PropertyNameCell_
};

function InspectorViewCells$PropertyIssuesCell(Props) {
  var propertyIssueCount = Props.propertyIssueCount;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              activeBorderOpenLeft: true,
              onClick: onClick,
              property: true,
              children: React.createElement(InspectorViewCells$IssueCount, {
                    value: propertyIssueCount
                  })
            });
}

var PropertyIssuesCell = {
  make: InspectorViewCells$PropertyIssuesCell
};

function InspectorViewCells$PropertyIssuesCell_(Props) {
  var propertyIssueTypeCount = Props.propertyIssueTypeCount;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              activeBorderOpenLeft: true,
              onClick: onClick,
              property: true,
              children: React.createElement(InspectorViewCells$IssueCount, {
                    value: propertyIssueTypeCount
                  })
            });
}

var PropertyIssuesCell_ = {
  make: InspectorViewCells$PropertyIssuesCell_
};

function InspectorViewCells$PropertyVolumeCell(Props) {
  var eventCount = Props.eventCount;
  var currentPropertyVolume = Props.currentPropertyVolume;
  var hasNoIssues = Props.hasNoIssues;
  var isNew = Props.isNew;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  var coverage = currentPropertyVolume / eventCount * 100;
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              activeBorderOpenRight: true,
              onClick: onClick,
              property: true,
              children: React.createElement(InspectorViewCells$Volume, {
                    value: coverage | 0,
                    color: hasNoIssues || coverage === 100.0 ? Styles.Color.light10 : (
                        coverage > 50.0 ? Styles.Color.orange : Styles.Color.red
                      ),
                    isNew: isNew,
                    percentage: true
                  })
            });
}

var PropertyVolumeCell = {
  make: InspectorViewCells$PropertyVolumeCell
};

function InspectorViewCells$PropertySourceVolumeCell(Props) {
  var currentPropertyVolume = Props.currentPropertyVolume;
  var eventCount = Props.eventCount;
  var showDashForZero = Props.showDashForZero;
  var hasNoIssues = Props.hasNoIssues;
  var isActiveCell = Props.isActiveCell;
  var isNew = Props.isNew;
  var onClick = Props.onClick;
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              activeBorderOpenRight: true,
              onClick: onClick,
              leftBorder: true,
              property: true,
              children: Belt_Option.mapWithDefault(currentPropertyVolume, React.createElement(InspectorViewCells$Volume, {
                        value: 0,
                        isNew: isNew,
                        showDashForZero: true
                      }), (function (currentPropertyVolume) {
                      var coverage = currentPropertyVolume / eventCount * 100;
                      return React.createElement(InspectorViewCells$Volume, {
                                  value: coverage | 0,
                                  color: hasNoIssues || coverage === 100.0 ? Styles.Color.light10 : (
                                      coverage > 50.0 ? Styles.Color.orange : Styles.Color.red
                                    ),
                                  isNew: isNew,
                                  percentage: true,
                                  showDashForZero: showDashForZero
                                });
                    }))
            });
}

var PropertySourceVolumeCell = {
  make: InspectorViewCells$PropertySourceVolumeCell
};

function InspectorViewCells$PropertySourceIssuesCell(Props) {
  var $$event = Props.event;
  var isActiveCell = Props.isActiveCell;
  var source = Props.source;
  var onClick = Props.onClick;
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              activeBorderOpenLeft: true,
              onClick: onClick,
              property: true,
              children: source !== undefined ? React.createElement(InspectorViewCells$IssueCount, {
                      value: InspectorViewSelectors.countPropertyIssuesTypes([source]),
                      showDashForZero: Belt_Option.mapWithDefault(InspectorViewSelectors.getEventSourceBySourceId($$event.sources, source.sourceId), true, (function (source) {
                              return source.count.history.total === 0;
                            }))
                    }) : null
            });
}

var PropertySourceIssuesCell = {
  make: InspectorViewCells$PropertySourceIssuesCell
};

function InspectorViewCells$PropertySourceIssuesCell_(Props) {
  var propertyIssueTypeCount = Props.propertyIssueTypeCount;
  var showDashForZero = Props.showDashForZero;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              activeBorderOpenLeft: true,
              onClick: onClick,
              property: true,
              children: React.createElement(InspectorViewCells$IssueCount, {
                    value: propertyIssueTypeCount,
                    showDashForZero: showDashForZero
                  })
            });
}

var PropertySourceIssuesCell_ = {
  make: InspectorViewCells$PropertySourceIssuesCell_
};

function InspectorViewCells$EventNameCell(Props) {
  var eventName = Props.eventName;
  var isExpanded = Props.isExpanded;
  var isActive = Props.isActive;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  var onAddToTrackingPlan = Props.onAddToTrackingPlan;
  var model = Props.model;
  var modelEvent = ModelUtils.getEventByName(eventName, model);
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              onClick: onClick,
              children: null
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.px(InspectorViewConfig.eventRowHeight)),
                        tl: {
                          hd: Css.width(Css.px(2)),
                          tl: {
                            hd: Css.margin4(Css.px(-2), Css.px(0), Css.px(-1), Css.px(-9)),
                            tl: {
                              hd: Css.backgroundColor(isActive ? Styles.Color.avoPink : "transparent"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(0), Css.px(15)),
                        tl: /* [] */0
                      })
                }, isExpanded ? React.createElement(IconArrowUp.make, {
                        size: 8
                      }) : React.createElement(IconArrowDown.make, {
                        size: 8
                      })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.textAlign("left"),
                                tl: {
                                  hd: Css.marginRight(Css.px(8)),
                                  tl: {
                                    hd: Css.color(Styles.Color.light11),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      singleLine: true,
                      children: eventName
                    })), React.createElement(Spacer.make, {
                  width: 8,
                  grow: 1.0
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.height(Css.pct(50)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, modelEvent !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(InspectorViewCells$TrackingPlanStatus, {
                            text: "Event was found in Tracking Plan",
                            color: Styles.Color.light08
                          }), React.createElement(Spacer.make, {
                            width: 4
                          }), React.createElement(InspectorViewCells$CellButtonWithTooltip, {
                            tooltipText: "Edit Event in tracking plan",
                            buttonLabel: "Edit",
                            onClick: (function (e) {
                                e.stopPropagation();
                                return Router.Schema.pushDrawerItem(undefined, undefined, {
                                            NAME: "event",
                                            VAL: [
                                              modelEvent.id,
                                              undefined,
                                              undefined,
                                              false
                                            ]
                                          });
                              })
                          })) : React.createElement(React.Fragment, undefined, React.createElement(InspectorViewCells$TrackingPlanStatus, {
                            text: "Event not found in Tracking Plan",
                            color: Styles.Color.red
                          }), React.createElement(Spacer.make, {
                            width: 4
                          }), React.createElement(InspectorViewCells$CellButtonWithTooltip, {
                            tooltipText: "Add Event to tracking plan",
                            buttonLabel: "Add",
                            onClick: (function (e) {
                                e.stopPropagation();
                                return Curry._1(onAddToTrackingPlan, undefined);
                              })
                          }))));
}

var EventNameCell = {
  make: InspectorViewCells$EventNameCell
};

function InspectorViewCells$EventNameCell_(Props) {
  var eventName = Props.eventName;
  var isExpanded = Props.isExpanded;
  var isActive = Props.isActive;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  var onAddToTrackingPlan = Props.onAddToTrackingPlan;
  var mappedEvents = Props.mappedEvents;
  var rules = Props.rules;
  var modelEvent = InspectorIssuesGenerator.getTrackingPlanEvent_mappedModel(mappedEvents, rules, eventName);
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              onClick: onClick,
              children: null
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.px(InspectorViewConfig.eventRowHeight)),
                        tl: {
                          hd: Css.width(Css.px(2)),
                          tl: {
                            hd: Css.margin4(Css.px(-2), Css.px(0), Css.px(-1), Css.px(-9)),
                            tl: {
                              hd: Css.backgroundColor(isActive ? Styles.Color.avoPink : "transparent"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(0), Css.px(15)),
                        tl: /* [] */0
                      })
                }, isExpanded ? React.createElement(IconArrowUp.make, {
                        size: 8
                      }) : React.createElement(IconArrowDown.make, {
                        size: 8
                      })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.textAlign("left"),
                                tl: {
                                  hd: Css.marginRight(Css.px(8)),
                                  tl: {
                                    hd: Css.color(Styles.Color.light11),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      singleLine: true,
                      maxWidth: Css.px(278),
                      children: eventName
                    })), React.createElement(Spacer.make, {
                  width: 2,
                  grow: 1.0
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.gap(Css.px(4)),
                            tl: {
                              hd: Css.height(Css.pct(50)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, modelEvent !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(InspectorViewCells$TrackingPlanStatus, {
                            text: "Event was found in Tracking Plan",
                            color: Styles.Color.light08
                          }), React.createElement(InspectorViewCells$CellButtonWithTooltip, {
                            tooltipText: "Edit Event in tracking plan",
                            buttonLabel: "Edit",
                            onClick: (function (e) {
                                e.stopPropagation();
                                return Router.Schema.pushDrawerItem(undefined, undefined, {
                                            NAME: "event",
                                            VAL: [
                                              modelEvent.id,
                                              undefined,
                                              undefined,
                                              false
                                            ]
                                          });
                              })
                          })) : React.createElement(React.Fragment, undefined, React.createElement(InspectorViewCells$TrackingPlanStatus, {
                            text: "Event not found in Tracking Plan",
                            color: Styles.Color.red
                          }), React.createElement(InspectorViewCells$CellButtonWithTooltip, {
                            tooltipText: "Add Event to tracking plan",
                            buttonLabel: "Add",
                            onClick: (function (e) {
                                e.stopPropagation();
                                return Curry._1(onAddToTrackingPlan, undefined);
                              })
                          }))));
}

var EventNameCell_ = {
  make: InspectorViewCells$EventNameCell_
};

function isNew($$event) {
  if ($$event.count.history.total === $$event.count.current.total) {
    return $$event.count.history.total !== 0;
  } else {
    return false;
  }
}

function InspectorViewCells$EventVolumeCell(Props) {
  var eventTotalCount = Props.eventTotalCount;
  var isNew = Props.isNew;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              activeBorderOpenRight: true,
              onClick: onClick,
              children: React.createElement(InspectorViewCells$Volume, {
                    value: eventTotalCount,
                    isNew: isNew
                  })
            });
}

var EventVolumeCell = {
  isNew: isNew,
  make: InspectorViewCells$EventVolumeCell
};

function InspectorViewCells$EventIssuesCell(Props) {
  var issueCount = Props.issueCount;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              activeBorderOpenLeft: true,
              onClick: onClick,
              children: React.createElement(InspectorViewCells$IssueCount, {
                    value: issueCount
                  })
            });
}

var EventIssuesCell = {
  make: InspectorViewCells$EventIssuesCell
};

function InspectorViewCells$EventIssuesCell_(Props) {
  var $$event = Props.event;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              activeBorderOpenLeft: true,
              onClick: onClick,
              children: React.createElement(InspectorViewCells$IssueCount, {
                    value: InspectorEventViewModelUtils.$$Event.getIssueTypeCount(undefined, $$event)
                  })
            });
}

var EventIssuesCell_ = {
  make: InspectorViewCells$EventIssuesCell_
};

function InspectorViewCells$EventSourceVolumeCell(Props) {
  var source = Props.source;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              activeBorderOpenRight: true,
              onClick: onClick,
              leftBorder: true,
              children: Belt_Option.mapWithDefault(source, null, (function (source) {
                      return React.createElement(InspectorViewCells$Volume, {
                                  value: source.count.current.total,
                                  isNew: source.count.history.total === source.count.current.total && source.count.history.total !== 0,
                                  showDashForZero: source.count.history.total === 0
                                });
                    }))
            });
}

var EventSourceVolumeCell = {
  make: InspectorViewCells$EventSourceVolumeCell
};

function InspectorViewCells$EventSourceVolumeCell_(Props) {
  var totalSourceVolume = Props.totalSourceVolume;
  var isActiveCell = Props.isActiveCell;
  var isNew = Props.isNew;
  var onClick = Props.onClick;
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              activeBorderOpenRight: true,
              onClick: onClick,
              leftBorder: true,
              children: Belt_Option.mapWithDefault(totalSourceVolume, React.createElement(InspectorViewCells$Volume, {
                        value: 0,
                        isNew: false,
                        showDashForZero: true
                      }), (function (totalSourceVolume) {
                      return React.createElement(InspectorViewCells$Volume, {
                                  value: totalSourceVolume,
                                  isNew: isNew,
                                  showDashForZero: true
                                });
                    }))
            });
}

var EventSourceVolumeCell_ = {
  make: InspectorViewCells$EventSourceVolumeCell_
};

function InspectorViewCells$EventSourceIssuesCell(Props) {
  var $$event = Props.event;
  var source = Props.source;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              activeBorderOpenLeft: true,
              onClick: onClick,
              children: Belt_Option.mapWithDefault(source, null, (function (source) {
                      return React.createElement(InspectorViewCells$IssueCount, {
                                  value: InspectorViewSelectors.countEventIssueTypes([source], $$event),
                                  showDashForZero: source.count.history.total === 0
                                });
                    }))
            });
}

var EventSourceIssuesCell = {
  make: InspectorViewCells$EventSourceIssuesCell
};

function InspectorViewCells$EventSourceIssuesCell_(Props) {
  var $$event = Props.event;
  var sourceId = Props.sourceId;
  var isActiveCell = Props.isActiveCell;
  var onClick = Props.onClick;
  return React.createElement(InspectorViewCells$CellContainer, {
              isActiveCell: isActiveCell,
              activeBorderOpenLeft: true,
              onClick: onClick,
              children: Belt_Option.mapWithDefault(sourceId, React.createElement(InspectorViewCells$IssueCount, {
                        value: 0,
                        showDashForZero: true
                      }), (function (sourceId) {
                      var showDashForZero = InspectorEventViewModelUtils.$$Event.getTotalCountInt([sourceId], $$event) === 0;
                      return React.createElement(InspectorViewCells$IssueCount, {
                                  value: InspectorEventViewModelUtils.$$Event.getIssueTypeCount(sourceId, $$event),
                                  showDashForZero: showDashForZero
                                });
                    }))
            });
}

var EventSourceIssuesCell_ = {
  make: InspectorViewCells$EventSourceIssuesCell_
};

var cellPadding = 8;

export {
  countIssueTypes ,
  getSummary ,
  Volume ,
  IssueCount ,
  cellPadding ,
  CellContainer ,
  TrackingPlanStatus ,
  CellButtonWithTooltip ,
  PropertyNameCell ,
  PropertyNameCell_ ,
  PropertyIssuesCell ,
  PropertyIssuesCell_ ,
  PropertyVolumeCell ,
  PropertySourceVolumeCell ,
  PropertySourceIssuesCell ,
  PropertySourceIssuesCell_ ,
  EventNameCell ,
  EventNameCell_ ,
  EventVolumeCell ,
  EventIssuesCell ,
  EventIssuesCell_ ,
  EventSourceVolumeCell ,
  EventSourceVolumeCell_ ,
  EventSourceIssuesCell ,
  EventSourceIssuesCell_ ,
  
}
/* containerStyle Not a pure module */
