// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as IconX from "./IconX.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App from "firebase/app";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function Invite$InvitedBy(Props) {
  var userId = Props.userId;
  var userState = FirebaseFetcherHooks.useUser(userId);
  if (typeof userState === "object") {
    return React.createElement($$Text.make, {
                size: "Small",
                weight: "Medium",
                color: Styles.Color.light08,
                children: "Invited by " + AvoConfig.getUserDisplayName(userState.VAL)
              });
  } else {
    return null;
  }
}

var InvitedBy = {
  make: Invite$InvitedBy
};

function createInviteDeleteBatch(invite, schemaId) {
  var inviteRef = Firebase.app(undefined).firestore().collection("invites").doc(invite.id);
  var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId);
  var schemaInviteRef = schemaRef.collection("invites").doc(invite.externalId);
  var batch = Firebase.app(undefined).firestore().batch();
  batch.delete(inviteRef);
  batch.delete(schemaInviteRef);
  return batch;
}

function removeInvite(invite, schemaBundle) {
  var schemaId = invite.schemaId;
  return createInviteDeleteBatch(invite, schemaId).commit().then(function (param) {
              Router.Schema.pushSchemaRoute(undefined, undefined, "dashboard");
              AnalyticsRe.inviteRemoved(schemaBundle, invite.createdBy, schemaBundle.branchId, schemaBundle.schemaId);
              return Promise.resolve(undefined);
            });
}

function acceptInvite(invite, userId, schemaBundle) {
  var schemaId = invite.schemaId;
  var schemaAclRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("acls").doc(userId);
  var newUserSchemaRef = Firebase.app(undefined).firestore().collection("users").doc(userId).collection("schemas").doc(schemaId);
  var batchWithInviteDeletes = createInviteDeleteBatch(invite, schemaId);
  var tmp = {
    id: userId,
    role: invite.role,
    type_: "user",
    createdAt: App.default.firestore.FieldValue.serverTimestamp()
  };
  var tmp$1 = Caml_option.undefined_to_opt(invite.filter);
  if (tmp$1 !== undefined) {
    tmp.filter = Caml_option.valFromOption(tmp$1);
  }
  batchWithInviteDeletes.set(schemaAclRef, tmp);
  batchWithInviteDeletes.set(newUserSchemaRef, {
        id: schemaId,
        lastUsedAt: App.default.firestore.FieldValue.serverTimestamp()
      });
  return batchWithInviteDeletes.commit().then(function (param) {
              Router.push(undefined, Curry._4(Router.Link.get, {
                        NAME: "schema",
                        VAL: schemaId
                      }, "master", "dashboard", /* [] */0));
              AnalyticsRe.inviteAccepted(schemaBundle, userId, invite.createdBy, Belt_Option.getWithDefault(AnalyticsRe.userRoleFromJs(invite.role), "Viewer"), schemaBundle.branchId, schemaBundle.schemaId);
              return Promise.resolve(undefined);
            });
}

var headerClose = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(6)),
        tl: {
          hd: Css.right(Css.px(6)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: {
                  hd: Css.width(Css.px(32)),
                  tl: {
                    hd: Css.height(Css.px(32)),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.backgroundColor("transparent"),
                        tl: {
                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light08),
                          tl: {
                            hd: Css.padding(Css.px(0)),
                            tl: {
                              hd: Css.cursor("pointer"),
                              tl: {
                                hd: Css.borderRadius(Css.px(100)),
                                tl: {
                                  hd: Css.unsafe("appearance", "none"),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$short
                                        }, undefined, undefined, "all"),
                                    tl: {
                                      hd: Css.focus({
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.red)),
                                            tl: {
                                              hd: Css.outlineStyle("none"),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor(Styles.Color.lightRed),
                                              tl: {
                                                hd: Css_Legacy_Core.SVG.stroke(Styles.Color.red),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function Invite$InviteContent(Props) {
  var schema = Props.schema;
  var invite = Props.invite;
  var userId = Props.userId;
  var workspace = WorkspaceContext.use(undefined);
  var schemaBundle = AnalyticsUtils.makeSchemaBundle(workspace, "master", "main");
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.margin(Css.px(0)),
                            tl: {
                              hd: Css.marginBottom(Css.px(10)),
                              tl: {
                                hd: Css.marginRight(Css.px(10)),
                                tl: {
                                  hd: Css.width(Css.px(250)),
                                  tl: {
                                    hd: Css.height(Css.px(150)),
                                    tl: {
                                      hd: Css.padding(Css.px(0)),
                                      tl: {
                                        hd: Css.padding(Css.px(8)),
                                        tl: {
                                          hd: Css.paddingTop(Css.px(32)),
                                          tl: {
                                            hd: Css.borderRadius(Styles.Border.radius),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.white),
                                              tl: {
                                                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                tl: {
                                                  hd: Css.position("relative"),
                                                  tl: {
                                                    hd: Css.overflow("hidden"),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.buttonReset),
                        tl: {
                          hd: headerClose,
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (e) {
                      e.preventDefault();
                      e.stopPropagation();
                      if (window.confirm("Are you sure you want to remove this invite?")) {
                        removeInvite(invite, schemaBundle);
                        return ;
                      }
                      
                    })
                }, React.createElement(IconX.make, {})), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  textAlign: "center",
                  lines: 1,
                  color: Styles.Color.light11,
                  children: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(schema.name), "Untitled")
                }), React.createElement(Invite$InvitedBy, {
                  userId: invite.createdBy
                }), React.createElement(Spacer.make, {
                  height: 18
                }), React.createElement(Button.make, {
                  label: "Join Workspace",
                  onClick: (function (param) {
                      acceptInvite(invite, userId, schemaBundle);
                      
                    }),
                  size: "large"
                }));
}

var InviteContent = {
  make: Invite$InviteContent
};

function Invite(Props) {
  var invite = Props.invite;
  var userId = Props.userId;
  var schemaState = FirebaseFetcherHooks.useSchema(invite.schemaId, false);
  if (typeof schemaState === "object" && schemaState.NAME !== "Errored") {
    return React.createElement(Invite$InviteContent, {
                schema: schemaState.VAL,
                invite: invite,
                userId: userId
              });
  } else {
    return null;
  }
}

var make = Invite;

export {
  InvitedBy ,
  createInviteDeleteBatch ,
  removeInvite ,
  acceptInvite ,
  headerClose ,
  InviteContent ,
  make ,
  
}
/* headerClose Not a pure module */
