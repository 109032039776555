// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as IconX from "./IconX.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Tooltip from "./Tooltip.mjs";
import * as Markdown from "./Markdown.mjs";
import * as Tooltips from "./markdown/tooltips/tooltips.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as IconLightning from "./IconLightning.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as PropertyValidationUtils from "./PropertyValidationUtils.mjs";

function ModalConstraintEditorMatches(Props) {
  var property = Props.property;
  var sendActions = Props.sendActions;
  var role = Props.role;
  var model = Props.model;
  var addToast = Props.addToast;
  var globalSend = GlobalSendContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var hasPropertySourcesFeatureFlag = AppFeatureFlag.useFeatureFlag("PropertySources");
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = React.useState(function () {
        return "CreatedAt";
      });
  var updateSorting = match[1];
  var sorting = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var updateValue = match$1[1];
  var value = match$1[0];
  var sources = Belt_List.keepMap(Belt_List.reverse(BeltListExtensions.dedupeString(ModelUtils.getIncludedSourceIds(BeltListExtensions.flatMap(ModelUtils.eventsSendingProperty(model, undefined, property.id), (function ($$event) {
                          return $$event.includeSources;
                        }))))), (function (sourceId) {
          return ModelUtils.getSourceById(sourceId, model);
        }));
  var matches = Belt_List.keepMap(ModelUtils.getMatchesFromPropertyLegacy(property), (function (param) {
          var match = param[0].VAL;
          if (typeof match === "object" && match.NAME === "StringLit") {
            return [
                    match.VAL,
                    param[1]
                  ];
          }
          
        }));
  var matches$1 = sorting === "CreatedAt" ? matches : Belt_List.sort(matches, (function (param, param$1) {
            var b = param$1[0];
            var a = param[0];
            if (a.toLowerCase() > b.toLowerCase()) {
              return 1;
            } else if (a.toLowerCase() < b.toLowerCase()) {
              return -1;
            } else {
              return 0;
            }
          }));
  var context = {
    propertyId: property.id,
    propertyQuery: property.id
  };
  var hasSourceSpecificMatches = Belt_List.some(matches$1, (function (param) {
          return Belt_List.length(param[1]) > 0;
        }));
  var availability = AvoLimits.PropertyMatchesBySource.computeAvailability(workspace);
  var hasConstraintsBySource = availability === "AvailableDuringTrial" || availability === "Available" ? true : hasPropertySourcesFeatureFlag || hasSourceSpecificMatches;
  var handleAddValue = function (param) {
    var newMatch = value.trim();
    if (Belt_List.some(matches$1, (function (param) {
              return param[0] === newMatch;
            }))) {
      return Curry._1(addToast, {
                  message: "Match \"" + newMatch + "\" already exists",
                  toastType: /* Info */2,
                  persist: false
                });
    } else {
      Curry._1(updateValue, (function (param) {
              return "";
            }));
      Curry.app(sendActions, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            (function (branch) {
                AnalyticsRe.matchUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), AnalyticsUtils.getAnalyticsPropertyGroup(model, property), newMatch, Belt_List.length(matches$1) + 1 | 0, Belt_Array.concat(Belt_List.toArray(PropertyValidationUtils.getPropertyMatchesLegacy(property)), [newMatch]), "Add", Belt_List.length(sources), Belt_List.length(sources), "Property", NamedBranch.getId(branch), schemaBundle.schemaId);
                
              }),
            undefined,
            [[
                {
                  NAME: "AddPropertyValidation",
                  VAL: [
                    property.id,
                    {
                      NAME: "Matches",
                      VAL: {
                        hd: [
                          {
                            NAME: "Literal",
                            VAL: {
                              NAME: "StringLit",
                              VAL: newMatch
                            }
                          },
                          /* [] */0
                        ],
                        tl: /* [] */0
                      }
                    }
                  ]
                },
                context
              ]]
          ]);
      return ;
    }
  };
  var containerStyle = Curry._1(Css.style, {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("flexStart"),
          tl: {
            hd: Css.justifyContent("flexStart"),
            tl: {
              hd: Css.width(Css.pct(100)),
              tl: /* [] */0
            }
          }
        }
      });
  var sidebarContainerStyle = Curry._1(Css.style, {
        hd: Css.flexGrow(1.0),
        tl: /* [] */0
      });
  var matchLabelStyle = Curry._1(Css.style, {
        hd: Css.padding4(Css.px(4), Css.px(20), Css.px(4), Css.px(20)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.light02),
          tl: {
            hd: Css.color(Styles.Color.light10),
            tl: {
              hd: Css.fontSize(Styles.FontSize.tiny),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.semi),
                tl: /* [] */0
              }
            }
          }
        }
      });
  var matchTitleCellStyle = Curry._1(Css.style, {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.height(Css.px(40)),
            tl: {
              hd: Css.fontSize(Styles.FontSize.small),
              tl: {
                hd: Css.paddingLeft(Css.px(20)),
                tl: {
                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      });
  var contentContainerStyle = Curry._1(Css.style, {
        hd: Css.display("flex"),
        tl: {
          hd: Css.overflowX("auto"),
          tl: /* [] */0
        }
      });
  var sourceLabelStyle = Curry._1(Css.style, {
        hd: Css.padding4(Css.px(4), Css.px(10), Css.px(4), Css.px(0)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.light02),
          tl: {
            hd: Css.color(Styles.Color.light10),
            tl: {
              hd: Css.fontSize(Styles.FontSize.tiny),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.semi),
                tl: {
                  hd: Css.overflow("hidden"),
                  tl: {
                    hd: Css.textOverflow("ellipsis"),
                    tl: {
                      hd: Css.whiteSpace("nowrap"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      });
  var sourceColumnStyle = Curry._1(Css.style, {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.width(Css.px(75)),
          tl: /* [] */0
        }
      });
  var matchSourceCellStyle = Curry._1(Css.style, {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.height(Css.px(40)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
              tl: {
                hd: Css.selector(" #plan-lightning", {
                      hd: Css.opacity(0),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$default
                            }, undefined, undefined, "opacity"),
                        tl: {
                          hd: Css_Legacy_Core.SVG.fill(Styles.Color.light07),
                          tl: /* [] */0
                        }
                      }
                    }),
                tl: {
                  hd: Css.hover({
                        hd: Css.selector(" #plan-lightning", {
                              hd: Css.opacity(1),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      });
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.marginLeft(Css.px(-29)),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginTop(Css.px(3)),
                            tl: {
                              hd: Css.marginRight(Css.px(5)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(Icon.make, {
                          type_: "equalizer",
                          color: Styles.Color.light07
                        })), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: "Property value constraints"
                    }), React.createElement(Tooltip.WithAnalytics.make, {
                      fromItem: {
                        itemId: property.id,
                        itemName: property.name,
                        itemType: "Property"
                      },
                      title: "Property value constraints",
                      children: React.createElement(Tooltip.Content.make, {
                            children: React.createElement(Markdown.make, {
                                  source: Tooltips.propertyValueRules
                                })
                          })
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                          tl: {
                            hd: Css.marginTop(Css.px(12)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("spaceBetween"),
                                tl: {
                                  hd: Css.padding2(Css.px(10), Css.px(20)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: /* [] */0
                                }
                              })
                        }, "Property value should match one of..."), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.padding2(Css.px(5), Css.px(10)),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.small),
                                                tl: {
                                                  hd: Css.color(Styles.Color.darkBlue),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.deepBlue),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  return Curry._1(updateSorting, (function (currentSorting) {
                                                if (currentSorting === "CreatedAt") {
                                                  return "Alphabetical";
                                                } else {
                                                  return "CreatedAt";
                                                }
                                              }));
                                })
                            }, sorting === "CreatedAt" ? "Sort Alphabetically" : "Sort by Date"), RoleUtils.canEdit(role) ? React.createElement("button", {
                                className: Curry._1(Css.merge, {
                                      hd: Curry._1(Css.style, Styles.button),
                                      tl: {
                                        hd: Curry._1(Css.style, {
                                              hd: Css.padding2(Css.px(5), Css.px(10)),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.red),
                                                    tl: {
                                                      hd: Css.hover({
                                                            hd: Css.color(Styles.Color.darkRed),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                onClick: (function (param) {
                                    if (window.confirm("Are you sure you would like to remove all constraints from this property?")) {
                                      return Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  (function (branch) {
                                                      return AnalyticsRe.constraintTypeUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "None", "Remove", "Matches", property.name, NamedBranch.getId(branch), schemaBundle.schemaId);
                                                    }),
                                                  undefined,
                                                  [[
                                                      {
                                                        NAME: "RemovePropertyValidation",
                                                        VAL: [
                                                          property.id,
                                                          {
                                                            NAME: "Matches",
                                                            VAL: /* [] */0
                                                          }
                                                        ]
                                                      },
                                                      context
                                                    ]]
                                                ]);
                                    }
                                    
                                  })
                              }, "Remove") : null)), React.createElement("div", {
                      className: containerStyle,
                      id: "product-tour-property-matches"
                    }, React.createElement("div", {
                          className: sidebarContainerStyle
                        }, React.createElement("div", {
                              className: matchLabelStyle
                            }, "Allowed value"), Belt_List.toArray(Belt_List.map(matches$1, (function (param) {
                                    var excludedSources = param[1];
                                    var match_ = param[0];
                                    return React.createElement("div", {
                                                key: match_,
                                                className: matchTitleCellStyle
                                              }, React.createElement("span", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.fontSize(Styles.FontSize.regular),
                                                          tl: {
                                                            hd: Css.padding2(Css.px(5), Css.px(0)),
                                                            tl: /* [] */0
                                                          }
                                                        })
                                                  }, match_), React.createElement("button", {
                                                    className: Curry._1(Css.merge, {
                                                          hd: Curry._1(Css.style, Styles.button),
                                                          tl: {
                                                            hd: Curry._1(Css.style, {
                                                                  hd: Css.padding2(Css.px(5), Css.px(5)),
                                                                  tl: {
                                                                    hd: Css.marginTop(Css.px(5)),
                                                                    tl: {
                                                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                      tl: {
                                                                        hd: Css.fontSize(Styles.FontSize.tiny),
                                                                        tl: {
                                                                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light10),
                                                                          tl: {
                                                                            hd: Css.hover({
                                                                                  hd: Css_Legacy_Core.SVG.stroke(Styles.Color.red),
                                                                                  tl: /* [] */0
                                                                                }),
                                                                            tl: /* [] */0
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }),
                                                            tl: /* [] */0
                                                          }
                                                        }),
                                                    onClick: (function (param) {
                                                        return Curry.app(sendActions, [
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    (function (branch) {
                                                                        AnalyticsRe.constraintValueUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "N/A", "Matches", property.name, NamedBranch.getId(branch), schemaBundle.schemaId);
                                                                        return AnalyticsRe.matchUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), AnalyticsUtils.getAnalyticsPropertyGroup(model, property), match_, Belt_List.length(matches$1) - 1 | 0, Belt_List.toArray(Belt_List.keepMap(matches$1, (function (param) {
                                                                                              var item = param[0];
                                                                                              if (match_ !== item) {
                                                                                                return item;
                                                                                              }
                                                                                              
                                                                                            }))), "Remove", Belt_List.length(sources), Belt_List.length(Belt_List.keep(sources, (function (source) {
                                                                                              return !Belt_List.some(excludedSources, (function (sourceId) {
                                                                                                            return source.id === sourceId;
                                                                                                          }));
                                                                                            }))), "Property", NamedBranch.getId(branch), schemaBundle.schemaId);
                                                                      }),
                                                                    undefined,
                                                                    [[
                                                                        {
                                                                          NAME: "RemovePropertyValidation",
                                                                          VAL: [
                                                                            property.id,
                                                                            {
                                                                              NAME: "Matches",
                                                                              VAL: {
                                                                                hd: [
                                                                                  {
                                                                                    NAME: "Literal",
                                                                                    VAL: {
                                                                                      NAME: "StringLit",
                                                                                      VAL: match_
                                                                                    }
                                                                                  },
                                                                                  excludedSources
                                                                                ],
                                                                                tl: /* [] */0
                                                                              }
                                                                            }
                                                                          ]
                                                                        },
                                                                        context
                                                                      ]]
                                                                  ]);
                                                      })
                                                  }, React.createElement(IconX.make, {
                                                        size: 10
                                                      })));
                                  })))), React.createElement("div", {
                          className: contentContainerStyle
                        }, Belt_List.toArray(Belt_List.map(sources, (function (source) {
                                    return React.createElement("div", {
                                                key: source.id,
                                                className: sourceColumnStyle
                                              }, React.createElement("div", {
                                                    className: sourceLabelStyle,
                                                    title: AvoConfig.getSourceName(source)
                                                  }, AvoConfig.getSourceName(source)), Belt_List.toArray(Belt_List.map(matches$1, (function (param) {
                                                          var excludedSources = param[1];
                                                          var match_ = param[0];
                                                          return React.createElement("div", {
                                                                      key: match_,
                                                                      className: matchSourceCellStyle
                                                                    }, React.createElement("input", {
                                                                          checked: !Belt_List.some(excludedSources, (function (sourceId) {
                                                                                  return sourceId === source.id;
                                                                                })),
                                                                          type: "checkbox",
                                                                          onChange: (function ($$event) {
                                                                              if (!hasConstraintsBySource) {
                                                                                return Curry._1(globalSend, {
                                                                                            TAG: /* OpenModal */4,
                                                                                            _0: {
                                                                                              NAME: "BillingPrompt",
                                                                                              VAL: "PropertyMatchesBySource"
                                                                                            }
                                                                                          });
                                                                              }
                                                                              var isChecked = $$event.target.checked;
                                                                              return Curry.app(sendActions, [
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          (function (branch) {
                                                                                              return AnalyticsRe.matchUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), AnalyticsUtils.getAnalyticsPropertyGroup(model, property), match_, Belt_List.length(matches$1), Belt_List.toArray(Belt_List.map(matches$1, (function (param) {
                                                                                                                    return param[0];
                                                                                                                  }))), isChecked ? "EnableSource" : "DisableSource", Belt_List.length(sources), Belt_List.length(Belt_List.keep(sources, (function (source) {
                                                                                                                    return !Belt_List.some(excludedSources, (function (sourceId) {
                                                                                                                                  return source.id === sourceId;
                                                                                                                                }));
                                                                                                                  }))) + (
                                                                                                          isChecked ? 1 : -1
                                                                                                        ) | 0, "Property", NamedBranch.getId(branch), schemaBundle.schemaId);
                                                                                            }),
                                                                                          undefined,
                                                                                          [[
                                                                                              {
                                                                                                NAME: "TogglePropertyValidationMatchSource",
                                                                                                VAL: [
                                                                                                  property.id,
                                                                                                  match_,
                                                                                                  source.id,
                                                                                                  isChecked
                                                                                                ]
                                                                                              },
                                                                                              context
                                                                                            ]]
                                                                                        ]);
                                                                            })
                                                                        }), React.createElement("span", {
                                                                          id: "plan-lightning",
                                                                          onClick: (function (param) {
                                                                              return Curry._1(globalSend, {
                                                                                          TAG: /* OpenModal */4,
                                                                                          _0: {
                                                                                            NAME: "BillingPrompt",
                                                                                            VAL: "PropertyMatchesBySource"
                                                                                          }
                                                                                        });
                                                                            })
                                                                        }, hasConstraintsBySource ? null : React.createElement(IconLightning.make, {
                                                                                size: 12
                                                                              })));
                                                        }))));
                                  }))))), RoleUtils.canEdit(role) ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(20)),
                                  tl: {
                                    hd: Css.paddingRight(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement("input", {
                            className: Curry._1(Css.merge, {
                                  hd: Curry._1(Css.style, Styles.input),
                                  tl: {
                                    hd: Curry._1(Css.style, {
                                          hd: Css.padding2(Css.px(10), Css.px(0)),
                                          tl: {
                                            hd: Css.fontSize(Styles.FontSize.regular),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }),
                            placeholder: "Property should match...",
                            value: value,
                            onKeyDown: (function ($$event) {
                                var match = $$event.key;
                                if (match === "Enter" && value !== "") {
                                  return handleAddValue(undefined);
                                }
                                
                              }),
                            onChange: (function ($$event) {
                                var value = $$event.target.value;
                                return Curry._1(updateValue, (function (param) {
                                              return value;
                                            }));
                              })
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(Button.make, {
                            disabled: value === "",
                            label: "Add",
                            onClick: (function (param) {
                                return handleAddValue(undefined);
                              })
                          })) : null));
}

var make = ModalConstraintEditorMatches;

export {
  make ,
  
}
/* Css Not a pure module */
