// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Import from "./import/Import.mjs";
import * as Issues from "../../shared/models/Issues.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Styles from "./styles.mjs";
import * as Actions from "./actions.mjs";
import * as DateFns from "./DateFns.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as PromiseExt from "./externals/PromiseExt.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as ImportAction from "./import/ImportAction.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as NewBranchModal from "./NewBranchModal.mjs";
import * as ImportReview__V2 from "./import/ImportReview__V2.mjs";
import * as InspectorViewApi from "./inspector/InspectorViewApi.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as ImportReview__V2__Utils from "./import/ImportReview__V2__Utils.mjs";

var contentStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light02),
        tl: {
          hd: Css.width(Css.vw(90)),
          tl: {
            hd: Css.maxHeight(Css.vh(80)),
            tl: {
              hd: Css.boxShadow(Styles.Shadow.standard),
              tl: {
                hd: Css.borderRadius(Css.px(10)),
                tl: {
                  hd: Css.cursor("default"),
                  tl: {
                    hd: Css.overflow("auto"),
                    tl: {
                      hd: Css.marginLeft("auto"),
                      tl: {
                        hd: Css.marginRight("auto"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var closeButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("sticky"),
              tl: {
                hd: Css.top(Css.px(16)),
                tl: {
                  hd: Css.right(Css.px(16)),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.marginLeft("auto"),
                      tl: {
                        hd: Css.marginRight(Css.px(16)),
                        tl: {
                          hd: Css.width(Css.px(32)),
                          tl: {
                            hd: Css.height(Css.px(32)),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: {
                                  hd: Css.borderRadius(Css.px(16)),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.light07),
                                    tl: {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$short
                                          }, undefined, undefined, "background-color"),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor(Styles.Color.light08),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.zIndex(2),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function ImportReviewModal(Props) {
  var currentBranch = Props.currentBranch;
  var importModel = Props.importModel;
  var importStartedLocation = Props.importStartedLocation;
  var model = Props.model;
  var onClose = Props.onClose;
  var openBranches = Props.openBranches;
  var viewerRole = Props.viewerRole;
  var importMethod = Props.importMethod;
  var workspace = WorkspaceContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  React.useEffect((function () {
          if (ImportReview__V2__Utils.importModelIsEmpty(importModel)) {
            ImportReview__V2__Utils.handleExceptions({
                  RE_EXN_ID: ImportReview__V2__Utils.EmptyImportException
                }, (function (message, severity) {
                    return Curry._1(addToast, {
                                message: message,
                                toastType: severity === "error" ? /* Error */1 : /* Info */2
                              });
                  }));
            Curry._1(onClose, undefined);
          } else {
            AnalyticsRe.importStarted(schemaBundle, Belt_List.length(model.events), Belt_List.length(model.properties), importStartedLocation, schemaBundle.branchId, schemaBundle.schemaId);
          }
          
        }), []);
  var onReviewAccepted = function (onSuccess, issues, selectedBranch, parsedTrackingPlan) {
    var newAndUpdatedProperties = Belt_Array.concat(parsedTrackingPlan.newProperties, parsedTrackingPlan.updatedProperties);
    var createBranchAsPromise = function (branchName, sendActions, schemaBundle) {
      return new Promise((function (resolve, reject) {
                    return NewBranchModal.createBranch(branchName, "Import", sendActions, schemaBundle, (function (error) {
                                  return reject(error);
                                }), (function (branchId) {
                                  return resolve(branchId);
                                }), undefined);
                  }));
    };
    $$Promise.$$catch((
                    typeof selectedBranch === "object" ? (
                        selectedBranch.NAME === "Create" ? createBranchAsPromise(selectedBranch.VAL, sendActions, schemaBundle).then(function (branchId) {
                                return Promise.resolve({
                                            NAME: "Branch",
                                            VAL: branchId
                                          });
                              }) : Promise.resolve({
                                NAME: "Branch",
                                VAL: selectedBranch.VAL.id
                              })
                      ) : Promise.resolve("Master")
                  ).then(function (writeToBranch) {
                      return new Promise((function (resolve, reject) {
                                    return Curry.app(sendActions, [
                                                writeToBranch,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (branch) {
                                                    return resolve(branch);
                                                  }),
                                                (function (error) {
                                                    return reject(error);
                                                  }),
                                                [[
                                                    "StartedImport",
                                                    {}
                                                  ]]
                                              ]);
                                  }));
                    }).then(function (branch) {
                    return Import.lastActionOnBranchQuery(workspace.id, NamedBranch.getId(branch)).get().then(function (snapshot) {
                                var lastAction = Belt_Option.mapU(Belt_Array.get(snapshot.docs, 0), (function (doc) {
                                        return FirebaseFetcherHooks.transformAction(doc.data());
                                      }));
                                return [
                                        branch,
                                        lastAction
                                      ];
                              });
                  }).then(function (param) {
                  var branch = param[0];
                  var importActions = Belt_List.toArray(ImportAction.buildActions(parsedTrackingPlan, model, importMethod));
                  var modelWithImport = Belt_Array.reduceU(importActions, model, Actions.reduce);
                  return Actions.snapshot(workspace.id, NamedBranch.getId(branch), modelWithImport, Belt_Option.getExn(param[1])).then(function (param) {
                              var allEvents = Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents);
                              var eventIds = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.map(allEvents, (function (param) {
                                              return param.id;
                                            }))));
                              var eventNames = Belt_MapString.fromArray(Belt_Array.map(allEvents, (function ($$event) {
                                          return [
                                                  $$event.name,
                                                  $$event
                                                ];
                                        })));
                              var propertyIds = Belt_Array.map(newAndUpdatedProperties, (function (property) {
                                      return property.id;
                                    }));
                              var propertyNames = Belt_MapString.fromArray(Belt_Array.map(newAndUpdatedProperties, (function (property) {
                                          return [
                                                  property.name,
                                                  property
                                                ];
                                        })));
                              var issuesToResolve = branch === /* Master */0 ? ImportReview__V2__Utils.getRelatedIssues(eventNames, propertyNames, issues) : Issues.empty;
                              var sourceIds = parsedTrackingPlan.sources;
                              var propertyGroupIds = Belt_Array.map(parsedTrackingPlan.propertyBundles, (function (propertyGroup) {
                                      return propertyGroup.id;
                                    }));
                              var numNewEvents = eventIds.length;
                              var numNewProperties = propertyIds.length;
                              var numNewSources = Belt_Array.keepU(importActions, (function (importAction) {
                                      if (typeof importAction === "object") {
                                        return importAction.NAME === "CreateSource";
                                      } else {
                                        return false;
                                      }
                                    })).length;
                              return new Promise((function (resolve, reject) {
                                            return Curry.app(sendActions, [
                                                        NamedBranch.toUnnamed(branch),
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        (function (_branch) {
                                                            if (branch === /* Master */0 && Curry._1(Issues.size, issuesToResolve) > 0 && !workspace.isSandbox) {
                                                              var resolvedUntil = DateFns.addHours(2.0, new Date());
                                                              $$Promise.$$catch(InspectorViewApi.multiUpdateIssueStatus({
                                                                          TAG: /* Resolved */1,
                                                                          _0: {
                                                                            TAG: /* Date */3,
                                                                            _0: resolvedUntil
                                                                          }
                                                                        }, "importSideEffect", Curry._2(Issues.mapToArray, issuesToResolve, (function (param) {
                                                                                return param.id;
                                                                              }))).then(function (resolvedTimescaleIssues) {
                                                                        var resolvedIssues = Belt_Array.keepMapU(resolvedTimescaleIssues, (function (param) {
                                                                                var match = param.issueStatus.status;
                                                                                if (typeof match === "number" || match.TAG === /* Ignored */0) {
                                                                                  return ;
                                                                                } else {
                                                                                  return Curry._2(Issues.get, issuesToResolve, param.issueId);
                                                                                }
                                                                              }));
                                                                        Curry._5(onSuccess, numNewEvents, numNewProperties, numNewSources, resolvedIssues, branch);
                                                                        return resolve(branch);
                                                                      }), (function (e) {
                                                                      Sentry.captureException(e, {
                                                                            message: "Failed to resolve issues after inspector import",
                                                                            schemaId: schemaBundle.schemaId,
                                                                            branchId: NamedBranch.getId(branch),
                                                                            importStartedLocation: AnalyticsRe.importStartedLocationToJs(importStartedLocation),
                                                                            eventNames: eventNames,
                                                                            propertyNames: propertyNames,
                                                                            issueIdsToResolve: Curry._1(Issues.size, issuesToResolve)
                                                                          });
                                                                      Curry._1(addToast, {
                                                                            message: "Failed to auto resolve issues",
                                                                            toastType: /* Error */1,
                                                                            persist: true
                                                                          });
                                                                      resolve(branch);
                                                                      return Promise.resolve(undefined);
                                                                    }));
                                                              return ;
                                                            }
                                                            Curry._5(onSuccess, numNewEvents, numNewProperties, numNewSources, [], branch);
                                                            return resolve(branch);
                                                          }),
                                                        (function (error) {
                                                            Sentry.captureException(error, {
                                                                  message: "Failed to import events and properties",
                                                                  schemaId: schemaBundle.schemaId,
                                                                  branchId: NamedBranch.getId(branch),
                                                                  importStartedLocation: AnalyticsRe.importStartedLocationToJs(importStartedLocation),
                                                                  eventNames: eventNames,
                                                                  propertyNames: propertyNames,
                                                                  issueIdsToResolve: Curry._1(Issues.size, issuesToResolve)
                                                                });
                                                            Curry._1(addToast, {
                                                                  message: "Failed to import",
                                                                  toastType: /* Error */1,
                                                                  persist: true
                                                                });
                                                            return reject(error);
                                                          }),
                                                        [[
                                                            {
                                                              NAME: "CompletedImport",
                                                              VAL: [
                                                                numNewEvents,
                                                                numNewProperties,
                                                                numNewSources,
                                                                importStartedLocation === "OnboardingChecklist" ? "onboardingChecklist" : (
                                                                    importStartedLocation === "TrackingPlanEventsEmptyState" ? "trackingPlanEventsEmptyState" : (
                                                                        importStartedLocation === "InspectorIssuesHeader" ? "inspectorIssuesHeader" : (
                                                                            importStartedLocation === "DirectLink" ? "directLink" : (
                                                                                importStartedLocation === "InspectorEventsHeader" ? "inspectorEventsHeader" : (
                                                                                    importStartedLocation === "InspectorIssueDetails" ? "inspectorIssueDetails" : (
                                                                                        importStartedLocation === "InspectorEventsSidebar" ? "inspectorEventsSidebar" : (
                                                                                            importStartedLocation === "InspectorEventsTableRow" ? "inspectorEventsTableRow" : (
                                                                                                importStartedLocation === "CmdPalette" ? "cmdPalette" : "sidebarSettingsCog"
                                                                                              )
                                                                                          )
                                                                                      )
                                                                                  )
                                                                              )
                                                                          )
                                                                      )
                                                                  )
                                                              ]
                                                            },
                                                            {
                                                              eventIds: eventIds,
                                                              eventsQuery: eventIds,
                                                              propertyIds: propertyIds,
                                                              propertiesQuery: propertyIds,
                                                              sourceIds: sourceIds,
                                                              sourcesQuery: sourceIds,
                                                              propertyGroupIds: propertyGroupIds,
                                                              propertyGroupsQuery: propertyGroupIds
                                                            }
                                                          ]]
                                                      ]);
                                          }));
                            });
                }).then(function (branch) {
                AnalyticsRe.importCompleted(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), Belt_List.length(model.events) + parsedTrackingPlan.newEvents.length | 0, Belt_List.length(model.properties) + newAndUpdatedProperties.length | 0, newAndUpdatedProperties.length, Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents).length, parsedTrackingPlan.newEvents.length, parsedTrackingPlan.updatedEvents.length, importStartedLocation, NamedBranch.getId(branch), schemaBundle.schemaId);
                return Promise.resolve(undefined);
              }), (function (error) {
              console.error(error);
              return Promise.resolve(Curry._1(addToast, {
                              message: "Something went wrong when importing the tracking plan.\n" + PromiseExt.getErrorMessage(error),
                              toastType: /* Error */1,
                              persist: true
                            }));
            })).finally(function (param) {
          return Curry._1(onClose, undefined);
        });
    
  };
  var containerRef = React.useRef(null);
  var match = React.useState(function () {
        
      });
  var setInitialHeight = match[1];
  React.useEffect(function () {
        var el = containerRef.current;
        if (!(el == null)) {
          Curry._1(setInitialHeight, (function (param) {
                  return el.clientHeight;
                }));
        }
        
      });
  return React.createElement("div", {
              ref: containerRef,
              className: Curry._1(Css.merge, {
                    hd: contentStyles,
                    tl: {
                      hd: Belt_Option.mapWithDefault(match[0], "", (function (height) {
                              return Curry._1(Css.style, {
                                          hd: Css.minHeight(Css.px(height)),
                                          tl: /* [] */0
                                        });
                            })),
                      tl: /* [] */0
                    }
                  }),
              onClick: (function (prim) {
                  prim.stopPropagation();
                  
                })
            }, React.createElement("button", {
                  "aria-label": "Close",
                  className: closeButtonStyles,
                  onClick: (function (param) {
                      return Curry._1(onClose, undefined);
                    })
                }, React.createElement(Icon.make, {
                      type_: "close",
                      size: "large",
                      color: Styles.Color.white
                    })), React.createElement(ImportReview__V2.make, {
                  currentBranch: currentBranch,
                  importStartedLocation: importStartedLocation,
                  onContinue: onReviewAccepted,
                  openBranches: openBranches,
                  parsedTrackingPlan: importModel,
                  viewerRole: viewerRole,
                  importMethod: importMethod
                }));
}

var make = ImportReviewModal;

export {
  contentStyles ,
  closeButtonStyles ,
  make ,
  
}
/* contentStyles Not a pure module */
