// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Tag from "./Tag.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AutofillInput from "./AutofillInput.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var TagsAutofillInput = AutofillInput.Component({});

function TagsInput(Props) {
  var $$event = Props.event;
  var events = Props.events;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var context = {
    eventId: $$event.id,
    eventQuery: $$event.id
  };
  var tags = Belt_List.keep(ModelUtils.getAllTags(events), (function (tag) {
          return !Belt_List.has($$event.tags, tag, (function (a, b) {
                        return a === b;
                      }));
        }));
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.flexWrap("wrap"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, Belt_List.toArray(Belt_List.map($$event.tags, (function (tag) {
                        var tmp = {
                          tag: tag,
                          key: tag
                        };
                        var tmp$1 = RoleUtils.canEdit(role) ? (function (param) {
                              return Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (branch) {
                                              return AnalyticsRe.eventTagsUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "Remove", tag, Belt_Array.keep(Belt_List.toArray($$event.tags), (function (existingTag) {
                                                                return existingTag !== tag;
                                                              })), $$event.id, $$event.name, NamedBranch.getId(branch), schemaBundle.schemaId);
                                            }),
                                          undefined,
                                          [[
                                              {
                                                NAME: "RemoveTag",
                                                VAL: [
                                                  $$event.id,
                                                  tag
                                                ]
                                              },
                                              context
                                            ]]
                                        ]);
                            }) : undefined;
                        if (tmp$1 !== undefined) {
                          tmp.onRemove = Caml_option.valFromOption(tmp$1);
                        }
                        return React.createElement(Tag.make, tmp);
                      }))), RoleUtils.canEdit(role) ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.flexGrow(1.0),
                          tl: {
                            hd: Css.maxWidth(Css.px(250)),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(TagsAutofillInput.make, {
                        onSelect: (function (tag, param, param$1) {
                            return Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        (function (branch) {
                                            return AnalyticsRe.eventTagsUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "Add", tag, Belt_Array.concat(Belt_List.toArray($$event.tags), [tag]), $$event.id, $$event.name, NamedBranch.getId(branch), schemaBundle.schemaId);
                                          }),
                                        undefined,
                                        [[
                                            {
                                              NAME: "AddTag",
                                              VAL: [
                                                $$event.id,
                                                tag
                                              ]
                                            },
                                            context
                                          ]]
                                      ]);
                          }),
                        onCreate: (function (tag) {
                            return Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        (function (branch) {
                                            return AnalyticsRe.eventTagsUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "Create", tag, Belt_Array.concat(Belt_List.toArray($$event.tags), [tag]), $$event.id, $$event.name, NamedBranch.getId(branch), schemaBundle.schemaId);
                                          }),
                                        undefined,
                                        [[
                                            {
                                              NAME: "AddTag",
                                              VAL: [
                                                $$event.id,
                                                tag
                                              ]
                                            },
                                            context
                                          ]]
                                      ]);
                          }),
                        placeholder: "Add tag...",
                        search: (function (query) {
                            var results = Belt_List.map(Belt_List.fromArray(Fuzzaldrin.filter(Belt_List.toArray(tags), query, {
                                          maxResults: 5
                                        })), (function (tag) {
                                    return {
                                            NAME: "Item",
                                            VAL: tag
                                          };
                                  }));
                            var tagExists = Belt_List.has(tags, query, (function (a, b) {
                                    return a === b;
                                  }));
                            var tagIsEmpty = query.length === 0;
                            var tagAlreadyAdded = Belt_List.has($$event.tags, query, (function (a, b) {
                                    return a === b;
                                  }));
                            if (tagAlreadyAdded) {
                              return Belt_List.concat(results, {
                                          hd: {
                                            NAME: "Message",
                                            VAL: query + " already added"
                                          },
                                          tl: /* [] */0
                                        });
                            } else if (tagExists || tagIsEmpty) {
                              return results;
                            } else {
                              return Belt_List.concat(results, {
                                          hd: {
                                            NAME: "CreateNew",
                                            VAL: query
                                          },
                                          tl: /* [] */0
                                        });
                            }
                          }),
                        renderItem: (function (item, isActive) {
                            var variant = item.NAME;
                            return React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.padding(Css.px(10)),
                                              tl: {
                                                hd: Css.backgroundColor(isActive ? Styles.Color.lightBlue : Styles.Color.white),
                                                tl: {
                                                  hd: Css.hover({
                                                        hd: Css.backgroundColor(Styles.Color.light02),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, variant === "CreateNew" ? React.createElement("div", undefined, React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.fontWeight(Styles.FontWeight.regular),
                                                        tl: {
                                                          hd: Css.fontStyle("italic"),
                                                          tl: {
                                                            hd: Css.color(Styles.Color.light10),
                                                            tl: {
                                                              hd: Css.fontSize(Css.px(10)),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      })
                                                }, "New Tag"), React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                        tl: /* [] */0
                                                      })
                                                }, item.VAL)) : (
                                          variant === "Item" ? React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.display("inlineBlock"),
                                                        tl: /* [] */0
                                                      })
                                                }, React.createElement(Tag.make, {
                                                      tag: item.VAL
                                                    })) : React.createElement("div", undefined, item.VAL)
                                        ));
                          }),
                        refreshKey: [
                          $$event.tags,
                          tags
                        ]
                      })) : null);
}

var make = TagsInput;

export {
  TagsAutofillInput ,
  make ,
  
}
/* TagsAutofillInput Not a pure module */
