// Generated by ReScript, PLEASE EDIT WITH CARE


var instance = new ComlinkWorker(new URL("./BranchesDiscrepanciesWorker.mjs", import.meta.url), {});

function getBranchDiscrepancies(hasGuardrailsForAll, config, mainModelToCompareToJson, branchModelToCompareToJson) {
  return instance.getBranchDiscrepancies(hasGuardrailsForAll, config, mainModelToCompareToJson, branchModelToCompareToJson);
}

export {
  getBranchDiscrepancies ,
  
}
/* instance Not a pure module */
