// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as SandboxBanner from "./SandboxBanner.mjs";
import * as ComposableModal from "./ComposableModal.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function SandboxLimitationModal(Props) {
  var onClose = Props.onClose;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var isAnonymousUser = SandboxBanner.isAnonymousUser(undefined);
  React.useEffect((function () {
          AnalyticsRe.sandboxModalSeen(schemaBundle, "LimitationModal", isAnonymousUser ? "Anonymous" : "Authenticated");
          
        }), []);
  return React.createElement(ComposableModal.make, {
              children: null,
              width: 520,
              onClose: onClose
            }, React.createElement(ComposableModal.Title.make, {
                  children: {
                    NAME: "string",
                    VAL: "You have reached the sandbox’s edge!"
                  }
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement(ComposableModal.Body.make, {
                  children: {
                    NAME: "component",
                    VAL: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                              children: "The feature you are interacting with isn't fully available in the sandbox environment."
                            }), React.createElement(Spacer.make, {
                              height: 4
                            }), React.createElement($$Text.make, {
                              children: isAnonymousUser ? "Create your own Avo workspace to explore beyond the sandbox limits and begin your journey towards good data." : "Head to your Avo workspace to explore beyond the sandbox limits and begin your journey towards good data."
                            }))
                  }
                }), React.createElement(ComposableModal.Buttons.make, {
                  children: [
                    {
                      NAME: "secondary",
                      VAL: {
                        label: "Keep exploring",
                        onClick: (function (closeModal) {
                            AnalyticsRe.sandboxInteraction(schemaBundle, "KeepExploring", "LimitationModal", isAnonymousUser ? "Anonymous" : "Authenticated", schemaBundle.schemaId);
                            return Curry._1(closeModal, undefined);
                          })
                      }
                    },
                    {
                      NAME: "primary",
                      VAL: {
                        label: isAnonymousUser ? "Create Avo workspace" : "Go to my workspace",
                        onClick: (function (closeModal) {
                            if (isAnonymousUser) {
                              AnalyticsRe.sandboxInteraction(schemaBundle, "CreateWorkspace", "LimitationModal", isAnonymousUser ? "Anonymous" : "Authenticated", schemaBundle.schemaId);
                              Router.push(undefined, "/onboarding");
                            } else {
                              AnalyticsRe.sandboxInteraction(schemaBundle, "GoToMyWorkspace", "LimitationModal", isAnonymousUser ? "Anonymous" : "Authenticated", schemaBundle.schemaId);
                              Router.push(undefined, "/schemas/default");
                            }
                            return Curry._1(closeModal, undefined);
                          })
                      }
                    }
                  ]
                }));
}

var make = SandboxLimitationModal;

export {
  make ,
  
}
/* Text Not a pure module */
