// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DateFns from "./DateFns.mjs";
import * as Mantine from "./Mantine.mjs";
import Shortid from "shortid";
import * as DateFns$1 from "date-fns";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as PortalMenu from "./PortalMenu.mjs";
import * as SourcePill from "./SourcePill.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as Core from "@mantine/core";
import * as PlanLightning from "./PlanLightning.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as IconQuestionMark from "./IconQuestionMark.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as ImplementationStatusRow from "./implementationStatus/ImplementationStatusRow.mjs";
import * as ImplementationStatusContext from "./ImplementationStatusContext.mjs";
import * as ImplementationStatusPriority from "./implementationStatus/ImplementationStatusPriority.mjs";
import * as FetchImplementationStatusUtils from "../../shared/utils/FetchImplementationStatusUtils.mjs";
import * as SourceRowEditDestinationsPopup from "./SourceRowEditDestinationsPopup.mjs";
import * as ExpandImplementationStatusListButton from "./implementationStatus/ExpandImplementationStatusListButton.mjs";
import * as InspectorImplementationStatusDisclaimer from "./implementationStatus/InspectorImplementationStatusDisclaimer.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.paddingBottom(Css.px(16)),
      tl: /* [] */0
    });

var destinationsStyles = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(26)),
      tl: {
        hd: Css.paddingRight(Css.px(24)),
        tl: /* [] */0
      }
    });

var sectionStyles = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(8), Css.px(0)),
      tl: {
        hd: Css.paddingLeft(Css.px(32)),
        tl: {
          hd: Css.paddingRight(Css.px(24)),
          tl: /* [] */0
        }
      }
    });

var destinationHeaderStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginLeft(Css.px(6)),
            tl: {
              hd: Css.marginTop(Css.px(12)),
              tl: {
                hd: Css.marginBottom(Css.px(2)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var destinationListStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.flexWrap("wrap"),
          tl: /* [] */0
        }
      }
    });

function destinationStyles(enabled) {
  return Curry._1(Css.style, {
              hd: Css.padding2(Css.px(2), Css.px(8)),
              tl: {
                hd: Css.borderRadius(Css.px(100)),
                tl: {
                  hd: Css.marginTop(Css.px(6)),
                  tl: {
                    hd: Css.marginRight(Css.px(8)),
                    tl: {
                      hd: Css.opacity(enabled ? 1.0 : 0.4),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.light04),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function SourceRow$InspectorValidationInformation(Props) {
  var inspectorValidation = Props.inspectorValidation;
  var match = React.useState(function () {
        return false;
      });
  var setPopoverVisible = match[1];
  var match$1 = React.useState(function () {
        var tmp;
        if (inspectorValidation !== undefined) {
          var variant = inspectorValidation.NAME;
          tmp = variant === "oneHourAfterMerge" ? Caml_option.some(DateFns.addHours(1.0, inspectorValidation.VAL)) : (
              variant === "twentyFourHoursAfterMerge" ? Caml_option.some(DateFns.addHours(24.0, inspectorValidation.VAL)) : (
                  variant === "customTime" ? Caml_option.some(inspectorValidation.VAL) : undefined
                )
            );
        } else {
          tmp = undefined;
        }
        return Belt_Option.map(tmp, (function (date) {
                      return [
                              DateFns.formatDistanceToNow(undefined, undefined, date),
                              date,
                              DateFns$1.isFuture(date)
                            ];
                    }));
      });
  var setTimedValidation = match$1[1];
  var timedValidation = match$1[0];
  React.useEffect((function () {
          var timeoutId = {
            contents: undefined
          };
          var tmp;
          if (inspectorValidation !== undefined) {
            var variant = inspectorValidation.NAME;
            tmp = variant === "oneHourAfterMerge" ? Caml_option.some(DateFns.addHours(1.0, inspectorValidation.VAL)) : (
                variant === "twentyFourHoursAfterMerge" ? Caml_option.some(DateFns.addHours(24.0, inspectorValidation.VAL)) : (
                    variant === "customTime" ? Caml_option.some(inspectorValidation.VAL) : undefined
                  )
              );
          } else {
            tmp = undefined;
          }
          Belt_Option.forEach(tmp, (function (date) {
                  var minute = 60 * 1000;
                  var hour = 60 * minute;
                  var day = 24 * hour;
                  timeoutId.contents = Caml_option.some(setTimeout((function (param) {
                              return Curry._1(setTimedValidation, (function (param) {
                                            return [
                                                    DateFns.formatDistanceToNow(undefined, undefined, date),
                                                    date,
                                                    DateFns$1.isFuture(date)
                                                  ];
                                          }));
                            }), DateFns$1.differenceInMilliseconds(new Date(), date) < hour ? minute | 0 : (
                              DateFns$1.differenceInMilliseconds(new Date(), date) < day ? 3600000 : (day | 0) / 2 | 0
                            )));
                  
                }));
          return Belt_Option.map(timeoutId.contents, (function (timeoutId, param) {
                        clearTimeout(timeoutId);
                        
                      }));
        }), [
        inspectorValidation,
        timedValidation
      ]);
  var prefix = "In production, this event is set to be validated";
  var tmp;
  var exit = 0;
  if (inspectorValidation !== undefined) {
    if (typeof inspectorValidation === "object") {
      var variant = inspectorValidation.NAME;
      if (variant === "nextVersion") {
        tmp = React.createElement($$Text.make, {
              weight: "Regular",
              children: prefix + " against versions after " + inspectorValidation.VAL + "."
            });
      } else if (variant === "customVersion" || variant === "currentVersion") {
        tmp = React.createElement($$Text.make, {
              weight: "Regular",
              children: prefix + " against version " + inspectorValidation.VAL + " and later."
            });
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
  } else {
    tmp = null;
  }
  if (exit === 1) {
    if (timedValidation !== undefined) {
      var inFuture = timedValidation[2];
      var date = timedValidation[1];
      tmp = React.createElement($$Text.make, {
            weight: "Regular",
            children: null
          }, prefix + " " + (
            inFuture ? "in" : "since"
          ) + " ", React.createElement(Mantine.Popover.make, {
                opened: match[0],
                position: "bottom",
                children: null
              }, React.createElement(Core.Popover.Target, {
                    children: React.createElement("span", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("inline"),
                                tl: {
                                  hd: Css.position("relative"),
                                  tl: /* [] */0
                                }
                              }),
                          onMouseEnter: (function (param) {
                              return Curry._1(setPopoverVisible, (function (param) {
                                            return true;
                                          }));
                            }),
                          onMouseLeave: (function (param) {
                              return Curry._1(setPopoverVisible, (function (param) {
                                            return false;
                                          }));
                            })
                        }, timedValidation[0])
                  }), React.createElement(Mantine.Popover.Dropdown.make, {
                    children: React.createElement("span", {
                          className: Curry._1(Css.style, {
                                hd: Css.width("auto"),
                                tl: {
                                  hd: Css.whiteSpace("nowrap"),
                                  tl: {
                                    hd: Css.padding2(Css.px(3), Css.px(6)),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.light11),
                                      tl: {
                                        hd: Css.color(Styles.Color.white),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.small),
                                          tl: {
                                            hd: Css.borderRadius(Styles.Border.radius),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.zIndex(3),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              })
                        }, DateFns.format("d MMM, yyyy", date) + " at " + DateFns.format("HH:mm", date))
                  })), (
            inFuture ? "" : " ago"
          ) + ".");
    } else {
      tmp = null;
    }
  }
  return React.createElement(React.Fragment, undefined, tmp, React.createElement(Spacer.make, {
                  height: 16
                }));
}

var InspectorValidationInformation = {
  make: SourceRow$InspectorValidationInformation
};

function SourceRow(Props) {
  var $$event = Props.event;
  var model = Props.model;
  var viewerCanEdit = Props.viewerCanEdit;
  var schemaBundle = Props.schemaBundle;
  var sendActions = Props.sendActions;
  var source = Props.source;
  var isEventModified = Props.isEventModified;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var hasBasicNameCasing = ValidationConfigV2.EventsV2.hasBasicNameCasing(match.config.events);
  var correctCase = hasBasicNameCasing ? NameUtils.isCorrectCase(expectedEventCase, $$event.name) : undefined;
  var namingConvention = hasBasicNameCasing ? Case.toAnalytics(Case.getCasingWithWorkspaceCase($$event.name, expectedEventCase)) : "Advanced";
  var isOnMaster = RouterStore.Schema.useBranch(undefined) === "master";
  var globalSend = GlobalSendContext.use(undefined);
  var eventSourcesAndDestinationsConfigAvailability = AvoLimits.ConfigEventSourcesAndDestinations.computeAvailability(workspace);
  var includedDestinations = Belt_List.flatten(Belt_List.keepMap($$event.includeDestinations, (function (x) {
              var match = x.VAL;
              if (match[0] === source.id) {
                return match[1];
              }
              
            })));
  var rules = Belt_List.keepMap(model.rules, (function (rule) {
          var match = rule.item;
          var match$1 = rule.definition;
          if (typeof match !== "object") {
            return ;
          }
          if (match.NAME !== "Event") {
            return ;
          }
          if (typeof match$1 !== "object") {
            return ;
          }
          if (match$1.NAME !== "SegmentIntegrationOption") {
            return ;
          }
          var match$2 = match$1.VAL;
          var key = match$2[0];
          if ($$event.id === match.VAL && Belt_List.some(source.segmentDestinationOptions, (function (param) {
                    return param[0] === key;
                  }))) {
            return [
                    key,
                    match$2[1]
                  ];
          }
          
        }));
  var segmentDestinationOptions = Belt_List.map(source.segmentDestinationOptions, (function (param) {
          var integration = param[0];
          var rule = Belt_List.getBy(rules, (function (param) {
                  return param[0] === integration;
                }));
          if (rule !== undefined) {
            return rule;
          } else {
            return [
                    integration,
                    param[1]
                  ];
          }
        }));
  var supportedDestinations = BeltListExtensions.dedupeOrdered(Belt_List.keepMapU(source.destinations, (function (param) {
              return ModelUtils.getDestinationById(param.destinationId, model);
            })), (function (destination) {
          return destination.id;
        }));
  var match$1 = ImplementationStatusContext.useEventWithRefetchStatusSubscription($$event.id);
  var eventInvocations = match$1[1];
  var match$2 = Belt_Option.mapWithDefault(Belt_List.getByU($$event.includeSources, (function (param) {
              return param.id === source.id;
            })), [
        false,
        undefined
      ], (function (param) {
          return [
                  param.includeInCodegen,
                  param.inspectorValidation
                ];
        }));
  var isIncludedInCodegen = match$2[0];
  var validInvocations = FetchImplementationStatusUtils.getInvocationsFromList(source.id, true, undefined, undefined, eventInvocations);
  var invalidInvocations = FetchImplementationStatusUtils.getInvocationsFromList(source.id, false, undefined, undefined, eventInvocations);
  var match$3 = React.useState(function () {
        return false;
      });
  var implementationStatusExpanded = match$3[0];
  var avoCodegenImplementationStatus = SourcePill.avoCodegenImplementationStatus(isIncludedInCodegen, match$1[0], validInvocations, invalidInvocations);
  var inspectorImplementationData = ImplementationStatusContext.useEventSourceInspectorImplementationStatusSubscription($$event.name, source.id);
  var inspectorEventSourceImplementationProdStatus = inspectorImplementationData.implementationProdStatus;
  var inspectorEventSourceImplementationDevStatus = inspectorImplementationData.implementationDevStatus;
  var inspectorEventSourceImplementationStagingStatus = inspectorImplementationData.implementationStagingStatus;
  var inspectorImplementationLoadProdStatus = ImplementationStatusContext.inspectorStatusFromFetchedInspectorStatus(inspectorEventSourceImplementationProdStatus, /* Prod */0, /* Version */0);
  var inspectorImplementationLoadDevStatus = ImplementationStatusContext.inspectorStatusFromFetchedInspectorStatus(inspectorEventSourceImplementationDevStatus, /* Dev */1, /* TimeWindow */1);
  var inspectorImplementationLoadStagingStatus = ImplementationStatusContext.inspectorStatusFromFetchedInspectorStatus(inspectorEventSourceImplementationStagingStatus, /* Staging */2, /* TimeWindow */1);
  var inspectorImplementationStatuses = ImplementationStatusPriority.orderInspectorStatusesBasedOnIsEventModified(isEventModified, [
        inspectorImplementationLoadProdStatus,
        inspectorImplementationLoadDevStatus,
        inspectorImplementationLoadStagingStatus
      ]);
  var alwaysVisibleInspectorImplementationStatuses = Belt_Array.slice(inspectorImplementationStatuses, 0, 1);
  var expandedInspectorImplementationStatuses = Belt_Array.sliceToEnd(inspectorImplementationStatuses, 1);
  var workspaceLimits = AvoLimits.computeLimits(workspace, model);
  var match$4 = AvoLimits.CodegenEvents.entityStatus(workspaceLimits, $$event.id);
  var codegenAvailability = match$4[0];
  var editDestinationsButtonRef = React.useRef(null);
  var match$5 = React.useState(function () {
        return false;
      });
  var setEditingDestinationsVisible = match$5[1];
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("div", {
                  className: sectionStyles
                }, React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Implementation"
                    }), React.createElement(Spacer.make, {
                      height: 8
                    })), React.createElement("div", {
                  className: sectionStyles
                }, React.createElement($$Text.make, {
                      size: "Tiny",
                      weight: "Semi",
                      color: Styles.Color.light09,
                      children: "INSPECTOR"
                    }), Belt_Array.mapWithIndexU(alwaysVisibleInspectorImplementationStatuses, (function (index, status) {
                        return React.createElement(ImplementationStatusRow.make, {
                                    implementationStatus: status,
                                    eventName: $$event.name,
                                    longDescription: false,
                                    key: "implementation-status-" + String(index)
                                  });
                      })), isOnMaster ? React.createElement(SourceRow$InspectorValidationInformation, {
                        inspectorValidation: match$2[1]
                      }) : null, inspectorImplementationStatuses.length > 1 ? React.createElement(ExpandImplementationStatusListButton.make, {
                        expanded: implementationStatusExpanded,
                        setExpanded: match$3[1]
                      }) : null, implementationStatusExpanded ? Belt_Array.mapWithIndexU(expandedInspectorImplementationStatuses, (function (index, status) {
                          return React.createElement(ImplementationStatusRow.make, {
                                      implementationStatus: status,
                                      eventName: $$event.name,
                                      longDescription: true,
                                      key: "implementation-status-" + String(index)
                                    });
                        })) : null, React.createElement(InspectorImplementationStatusDisclaimer.make, {
                      expanded: implementationStatusExpanded,
                      inspectorImplementationStatuses: inspectorImplementationStatuses,
                      collapsedVisibleImplementationStatuses: alwaysVisibleInspectorImplementationStatuses
                    })), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: sectionStyles
                }, React.createElement($$Text.make, {
                      size: "Tiny",
                      weight: "Semi",
                      color: Styles.Color.light09,
                      children: "CODEGEN"
                    }), React.createElement(Spacer.make, {
                      height: 2
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.marginLeft(Css.px(-4)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, codegenAvailability === "Available" ? null : (
                        codegenAvailability === "AvailableDuringTrial" ? React.createElement("a", {
                                onClick: (function (param) {
                                    return Curry._1(globalSend, {
                                                TAG: /* OpenModal */4,
                                                _0: {
                                                  NAME: "BillingPrompt",
                                                  VAL: "ImplementWithAvo"
                                                }
                                              });
                                  })
                              }, React.createElement(PlanLightning.make, {
                                    tooltipText: "Available During Trial"
                                  }), React.createElement(Spacer.make, {
                                    width: 4
                                  })) : React.createElement("a", {
                                onClick: (function (param) {
                                    return Curry._1(globalSend, {
                                                TAG: /* OpenModal */4,
                                                _0: {
                                                  NAME: "BillingPrompt",
                                                  VAL: "ImplementWithAvo"
                                                }
                                              });
                                  })
                              }, React.createElement(PlanLightning.make, {
                                    tooltipText: "Requires Plan Upgrade"
                                  }), React.createElement(Spacer.make, {
                                    width: 4
                                  }))
                      ), React.createElement("input", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginRight(Css.px(6)),
                                tl: /* [] */0
                              }),
                          id: "include-in-codegen-" + $$event.id + "-" + source.id,
                          checked: isIncludedInCodegen,
                          disabled: !viewerCanEdit,
                          type: "checkbox",
                          onChange: (function (inputEvent) {
                              if (!isIncludedInCodegen && codegenAvailability === "Unavailable") {
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "BillingPrompt",
                                              VAL: "ImplementWithAvo"
                                            }
                                          });
                              } else {
                                var includeInCodegen = inputEvent.target.checked;
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (branch) {
                                                var match = AnalyticsUtils.countSourceEventsImplementWithAvoAfterCurrentChange(model, source.id, includeInCodegen);
                                                AnalyticsRe.eventUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), AnalyticsRe.Bundle.source(source.id, AvoConfig.getSourceName(source)), $$event.id, $$event.name, "ToggleImplementSourceWithAvo", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Belt_List.length(model.events), undefined, includeInCodegen, match[1], match[0], "Event", $$event.triggers.length, correctCase, namingConvention, $$event.id, schemaBundle.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                                                
                                              }),
                                            undefined,
                                            [[
                                                {
                                                  NAME: "ToggleIncludeEventInCodegenForSource",
                                                  VAL: [
                                                    $$event.id,
                                                    source.id,
                                                    includeInCodegen
                                                  ]
                                                },
                                                {
                                                  eventId: $$event.id,
                                                  eventQuery: $$event.id,
                                                  sourceId: source.id,
                                                  sourceQuery: source.id
                                                }
                                              ]]
                                          ]);
                              }
                            })
                        }), React.createElement("label", {
                          className: Curry._1(Css.style, {
                                hd: Css.cursor("pointer"),
                                tl: {
                                  hd: Css.marginRight(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }),
                          htmlFor: "include-in-codegen-" + $$event.id + "-" + source.id
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              children: "Include in Codegen"
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement("a", {
                              className: Curry._1(Css.style, {
                                    hd: Css.height(Css.px(16)),
                                    tl: /* [] */0
                                  }),
                              href: "https://www.avo.app/docs/implementation/devs-101#a-nameavo-generated-filea-avo-generated-file-containing-avo-functions",
                              rel: "noopener",
                              target: "_blank"
                            }, React.createElement(IconQuestionMark.make, {})))), isIncludedInCodegen ? React.createElement(ImplementationStatusRow.make, {
                        implementationStatus: avoCodegenImplementationStatus,
                        eventName: $$event.name,
                        longDescription: false,
                        key: "implementation-status-codegen"
                      }) : null), React.createElement("div", {
                  className: destinationsStyles
                }, React.createElement("div", {
                      className: destinationHeaderStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: "Destinations"
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.width(Css.px(8)),
                                tl: /* [] */0
                              })
                        }), viewerCanEdit ? React.createElement(React.Fragment, undefined, React.createElement(TextButton.make, {
                                onClick: (function (param) {
                                    return Curry._1(setEditingDestinationsVisible, (function (state) {
                                                  return !state;
                                                }));
                                  }),
                                style: "Blue",
                                size: "Small",
                                children: null,
                                ref: editDestinationsButtonRef
                              }, eventSourcesAndDestinationsConfigAvailability === "Available" ? null : React.createElement("span", {
                                      className: Curry._1(Css.style, {
                                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.darkBlue),
                                            tl: {
                                              hd: Css.marginRight(Css.px(4)),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, React.createElement(PlanLightning.make, {
                                          tooltipText: eventSourcesAndDestinationsConfigAvailability === "Unavailable" ? "Upgrade Plan to unlock" : "Available during trial"
                                        })), "Edit"), React.createElement(PortalMenu.make, {
                                distance: 4,
                                visible: match$5[0],
                                onClose: (function (param) {
                                    return Curry._1(setEditingDestinationsVisible, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                referenceItemRef: editDestinationsButtonRef,
                                children: React.createElement(SourceRowEditDestinationsPopup.make, {
                                      availability: eventSourcesAndDestinationsConfigAvailability,
                                      destinations: supportedDestinations,
                                      globalSend: globalSend,
                                      includedDestinations: includedDestinations,
                                      onClose: (function (param) {
                                          return Curry._1(setEditingDestinationsVisible, (function (param) {
                                                        return false;
                                                      }));
                                        }),
                                      onEnableAll: (function (destinations) {
                                          if (!window.confirm("Enabling all destinations for this source on this event cannot be undone unless you upgrade your plan. Are you sure that you want to enable all destinations?")) {
                                            return ;
                                          }
                                          var eventIds = Belt_List.map(model.events, (function ($$event) {
                                                  return $$event.id;
                                                }));
                                          return Curry.app(sendActions, [
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      Belt_Array.map(Belt_List.toArray(destinations), (function (destination) {
                                                              return [
                                                                      {
                                                                        NAME: "IncludeDestinationInEventSource",
                                                                        VAL: [
                                                                          $$event.id,
                                                                          source.id,
                                                                          destination.id
                                                                        ]
                                                                      },
                                                                      {
                                                                        eventIds: Belt_List.toArray(eventIds),
                                                                        eventsQuery: Belt_List.toArray(eventIds),
                                                                        sourceId: source.id,
                                                                        sourceQuery: source.id,
                                                                        destinationId: destination.id,
                                                                        destinationQuery: destination.id
                                                                      }
                                                                    ];
                                                            }))
                                                    ]);
                                        }),
                                      onToggle: (function (destination, isSelected) {
                                          return Curry.app(sendActions, [
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      (function (branch) {
                                                          return AnalyticsRe.eventUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), AnalyticsRe.Bundle.source(source.id, AvoConfig.getSourceName(source)), $$event.id, $$event.name, isSelected ? "AddDestinationToSource" : "RemoveDestinationFromSource", undefined, undefined, Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), destination.type_, undefined, undefined, undefined, Belt_List.length(model.events), undefined, undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, namingConvention, $$event.id, schemaBundle.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                                                        }),
                                                      undefined,
                                                      [[
                                                          isSelected ? ({
                                                                NAME: "IncludeDestinationInEventSource",
                                                                VAL: [
                                                                  $$event.id,
                                                                  source.id,
                                                                  destination.id
                                                                ]
                                                              }) : ({
                                                                NAME: "ExcludeDestinationFromEventSource",
                                                                VAL: [
                                                                  $$event.id,
                                                                  source.id,
                                                                  destination.id
                                                                ]
                                                              }),
                                                          {
                                                            eventId: $$event.id,
                                                            eventQuery: $$event.id,
                                                            sourceId: source.id,
                                                            sourceQuery: source.id,
                                                            destinationId: destination.id,
                                                            destinationQuery: destination.id
                                                          }
                                                        ]]
                                                    ]);
                                        }),
                                      onToggleSegmentIntegration: (function (destination, integration, isActive) {
                                          var rule = Belt_List.getBy(model.rules, (function (rule) {
                                                  var match = rule.item;
                                                  var match$1 = rule.definition;
                                                  if (typeof match === "object" && match.NAME === "Event" && typeof match$1 === "object" && match$1.NAME === "SegmentIntegrationOption" && match.VAL === $$event.id) {
                                                    return match$1.VAL[0] === integration;
                                                  } else {
                                                    return false;
                                                  }
                                                }));
                                          return Curry.app(sendActions, [
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      [[
                                                          rule !== undefined ? ({
                                                                NAME: "UpdateRule",
                                                                VAL: [
                                                                  rule.id,
                                                                  rule.item,
                                                                  {
                                                                    NAME: "SegmentIntegrationOption",
                                                                    VAL: [
                                                                      integration,
                                                                      isActive
                                                                    ]
                                                                  }
                                                                ]
                                                              }) : ({
                                                                NAME: "AddRule",
                                                                VAL: [
                                                                  Shortid(),
                                                                  {
                                                                    NAME: "Event",
                                                                    VAL: $$event.id
                                                                  },
                                                                  {
                                                                    NAME: "SegmentIntegrationOption",
                                                                    VAL: [
                                                                      integration,
                                                                      isActive
                                                                    ]
                                                                  }
                                                                ]
                                                              }),
                                                          {
                                                            eventId: $$event.id,
                                                            eventQuery: $$event.id,
                                                            sourceId: source.id,
                                                            sourceQuery: source.id,
                                                            destinationId: destination.id,
                                                            destinationQuery: destination.id
                                                          }
                                                        ]]
                                                    ]);
                                        }),
                                      segmentDestinationOptions: segmentDestinationOptions
                                    })
                              })) : null), React.createElement("div", {
                      className: destinationListStyles
                    }, Belt_List.toArray(Belt_List.mapU(supportedDestinations, (function (destination) {
                                var match = destination.type_;
                                var match$1 = Belt_List.length(source.segmentDestinationOptions) > 0;
                                var tmp;
                                if (match !== undefined && match === "Segment" && match$1) {
                                  var activeCount = Belt_List.length(Belt_List.keep(segmentDestinationOptions, (function (param) {
                                              return param[1];
                                            })));
                                  tmp = ModelUtils.getDestinationName(destination) + " (" + String(activeCount) + "/" + String(Belt_List.length(source.segmentDestinationOptions)) + ")";
                                } else {
                                  tmp = ModelUtils.getDestinationName(destination);
                                }
                                return React.createElement("div", {
                                            key: destination.id,
                                            className: destinationStyles(Belt_List.someU(includedDestinations, (function (destinationId) {
                                                        return destinationId === destination.id;
                                                      })))
                                          }, React.createElement(TextButton.make, {
                                                onClick: (function (param) {
                                                    return Router.Schema.pushDrawerItem(undefined, undefined, {
                                                                NAME: "destination",
                                                                VAL: destination.id
                                                              });
                                                  }),
                                                style: "Black",
                                                size: "Small",
                                                children: tmp
                                              }));
                              }))))));
}

var make = SourceRow;

export {
  rootStyles ,
  destinationsStyles ,
  sectionStyles ,
  destinationHeaderStyles ,
  destinationListStyles ,
  destinationStyles ,
  InspectorValidationInformation ,
  make ,
  
}
/* rootStyles Not a pure module */
