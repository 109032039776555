// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Input from "../Input.mjs";
import * as Title from "../Title.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Portal from "../Portal.mjs";
import * as Select from "../Select.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Sidebar from "../Sidebar.mjs";
import * as AvoUtils from "../../../shared/utils/AvoUtils.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LoadingCircle from "../LoadingCircle.mjs";
import * as SandboxBanner from "../SandboxBanner.mjs";
import * as SimpleTooltip from "../SimpleTooltip.mjs";
import * as OnChangeDebounce from "../OnChangeDebounce.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as InspectorViewModel from "./InspectorViewModel.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as ImportReview__V2__Utils from "../import/ImportReview__V2__Utils.mjs";
import * as InspectorViewWindowPicker from "./InspectorViewWindowPicker.mjs";

function InspectorViewHeader$AddNewEventsButton(Props) {
  var eventNamesNotInTrackingPlan = Props.eventNamesNotInTrackingPlan;
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var addToast = Toast.useAddToastWithDestroy(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var onClick = function (_e) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    var destroyToast = Curry._1(addToast, {
          message: "Fetching event information",
          toastType: /* Loader */3,
          persist: true
        });
    if (eventNamesNotInTrackingPlan.length !== 0) {
      $$Promise.$$catch(ImportReview__V2__Utils.handlePrepareImport(eventNamesNotInTrackingPlan).then(function (importModel) {
                  Curry._1(destroyToast, undefined);
                  return Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "ImportReview",
                                VAL: [
                                  importModel,
                                  "AddOnly",
                                  "InspectorEventsHeader"
                                ]
                              }
                            });
                }), (function (e) {
                Curry._1(destroyToast, undefined);
                ImportReview__V2__Utils.handleExceptions(e, (function (message, severity) {
                        Curry._1(addToast, {
                              message: message,
                              toastType: severity === "error" ? /* Error */1 : /* Info */2
                            });
                        
                      }));
                return Promise.resolve(undefined);
              })).finally(function (param) {
            Curry._1(destroyToast, undefined);
            return Curry._1(setLoading, (function (param) {
                          return false;
                        }));
          });
      return ;
    } else {
      Curry._1(destroyToast, undefined);
      Curry._1(addToast, {
            message: "No events to import",
            toastType: /* Info */2
          });
      return Curry._1(setLoading, (function (param) {
                    return false;
                  }));
    }
  };
  return React.createElement(SimpleTooltip.make, {
              arrowOffset: 16,
              maxWidth: 220,
              position: "bottom",
              tooltip: eventNamesNotInTrackingPlan.length !== 0 ? "Review and add " + AvoUtils.plural(undefined, undefined, eventNamesNotInTrackingPlan.length, "unexpected event") + " to Tracking plan" : "No unexpected events to import",
              children: React.createElement(Button.make, {
                    disabled: eventNamesNotInTrackingPlan.length === 0,
                    icon: "addToTrackingPlan",
                    label: eventNamesNotInTrackingPlan.length === 0 ? "No new events" : "Add " + AvoUtils.plural(undefined, undefined, eventNamesNotInTrackingPlan.length, "event") + " to tracking plan",
                    loading: match[0],
                    onClick: onClick,
                    style: "outline"
                  })
            });
}

var AddNewEventsButton = {
  make: InspectorViewHeader$AddNewEventsButton
};

function headerStyles(withSandboxBanner) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.paddingRight(Css.px(16)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(40)),
                    tl: {
                      hd: Css.paddingTop(Css.px(8)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(8)),
                        tl: {
                          hd: Css.minHeight(Css.px(63)),
                          tl: {
                            hd: Css.position("fixed"),
                            tl: {
                              hd: Css.width({
                                    NAME: "subtract",
                                    VAL: [
                                      {
                                        NAME: "vw",
                                        VAL: 100.0
                                      },
                                      Css.px(Sidebar.sidebarWidth)
                                    ]
                                  }),
                              tl: {
                                hd: Css.left(Css.px(Sidebar.sidebarWidth)),
                                tl: {
                                  hd: Css.top(Css.px(SandboxBanner.getHeight(withSandboxBanner))),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.white),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var environmentSelection_0 = Css.height(Css.px(32));

var environmentSelection_1 = {
  hd: Css.paddingLeft(Css.px(16)),
  tl: {
    hd: Css.paddingRight(Css.px(24)),
    tl: {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.color(Styles.Color.light11),
          tl: /* [] */0
        }
      }
    }
  }
};

var environmentSelection = {
  hd: environmentSelection_0,
  tl: environmentSelection_1
};

var searchInput_0 = Css.fontSize(Styles.FontSize.small);

var searchInput_1 = {
  hd: Css.fontWeight(Styles.FontWeight.medium),
  tl: {
    hd: Css.color(Styles.Color.light10),
    tl: {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.lineHeight(Css.pct(140.0)),
        tl: {
          hd: Css.borderRadius(Css.px(30)),
          tl: {
            hd: Css.height(Css.px(32)),
            tl: {
              hd: Css.paddingLeft(Css.px(16)),
              tl: {
                hd: Css.paddingRight(Css.px(16)),
                tl: {
                  hd: Css.disabled({
                        hd: Css.placeholder({
                              hd: Css.color(Styles.Color.light08),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

var searchInput = {
  hd: searchInput_0,
  tl: searchInput_1
};

var inputContainer = Curry._1(Css.style, {
      hd: Css.width(Css.px(180)),
      tl: /* [] */0
    });

var Style = {
  headerStyles: headerStyles,
  environmentSelection: environmentSelection,
  searchInput: searchInput,
  inputContainer: inputContainer
};

function InspectorViewHeader$Header(Props) {
  var eventNamesNotInTrackingPlanOpt = Props.eventNamesNotInTrackingPlan;
  var hasSourcesWithoutInspector = Props.hasSourcesWithoutInspector;
  var headerProps = Props.headerProps;
  var inspectorView = Props.inspectorView;
  var newerVersionAvailableOpt = Props.newerVersionAvailable;
  var onRefreshOpt = Props.onRefresh;
  var eventNamesNotInTrackingPlan = eventNamesNotInTrackingPlanOpt !== undefined ? eventNamesNotInTrackingPlanOpt : [];
  var newerVersionAvailable = newerVersionAvailableOpt !== undefined ? newerVersionAvailableOpt : false;
  var onRefresh = onRefreshOpt !== undefined ? onRefreshOpt : (function (param) {
        
      });
  var schemaBundle = SchemaBundleContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var environment = Belt_Option.map(headerProps, (function (param) {
          return param.environment;
        }));
  var lastUpdated = Belt_Option.mapWithDefault(headerProps, undefined, (function (param) {
          return param.lastUpdated;
        }));
  var fetchEvents = Belt_Option.map(headerProps, (function (param) {
          return param.fetchEvents;
        }));
  var filterByEventName = Belt_Option.mapWithDefault(headerProps, "", (function (param) {
          return param.filterByEventName;
        }));
  var loadingStatus = Belt_Option.map(headerProps, (function (param) {
          return param.loadingStatus;
        }));
  var setEnvironment = Belt_Option.map(headerProps, (function (param) {
          return param.setEnvironment;
        }));
  var setFiltering = Belt_Option.map(headerProps, (function (param) {
          return param.setFiltering;
        }));
  var setTimeWindow = Belt_Option.map(headerProps, (function (param) {
          return param.setTimeWindow;
        }));
  var timeWindow = Belt_Option.map(headerProps, (function (param) {
          return param.timeWindow;
        }));
  var eventFilterInputRef = React.useRef(null);
  var tmp;
  if (inspectorView === "events") {
    var tmp$1;
    if (newerVersionAvailable) {
      tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(SimpleTooltip.make, {
                position: "bottom",
                tooltip: "New data available",
                children: React.createElement(Button.make, {
                      label: "Refresh",
                      onClick: (function (param) {
                          return Curry._1(onRefresh, undefined);
                        })
                    })
              }), React.createElement(Spacer.make, {
                width: 8
              }));
    } else if (environment !== undefined && environment !== "Production") {
      var tmp$2 = {
        disabled: Belt_Option.mapWithDefault(loadingStatus, true, (function (loadingStatus) {
                if (typeof loadingStatus === "object") {
                  return false;
                } else {
                  return true;
                }
              })),
        label: "Reload",
        onClick: (function (param) {
            return Belt_Option.forEach(fetchEvents, (function (fetchEvents) {
                          return Curry._1(fetchEvents, undefined);
                        }));
          }),
        style: "ghost"
      };
      var tmp$3 = Belt_Option.map(loadingStatus, (function (loadingStatus) {
              if (typeof loadingStatus === "object" && loadingStatus.NAME === "success") {
                return "Last updated " + new Date(loadingStatus.VAL.timestamp).toLocaleTimeString();
              } else {
                return "";
              }
            }));
      if (tmp$3 !== undefined) {
        tmp$2.title = Caml_option.valFromOption(tmp$3);
      }
      tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Button.make, tmp$2), React.createElement(Spacer.make, {
                width: 8
              }));
    } else {
      tmp$1 = null;
    }
    var tmp$4;
    var exit = 0;
    if (environment !== undefined && timeWindow !== undefined && setTimeWindow !== undefined) {
      tmp$4 = React.createElement(InspectorViewWindowPicker.make, {
            timeWindow: timeWindow,
            setTimeWindow: setTimeWindow,
            environment: environment,
            lastUpdated: lastUpdated,
            globalSend: globalSend
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp$4 = React.createElement(InspectorViewWindowPicker.MainButton.make, {
            disabled: true,
            label: "Last 24 hours"
          });
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
              width: 16,
              grow: 1.0
            }), Belt_Option.mapWithDefault(loadingStatus, null, (function (loadingStatus) {
                if (typeof loadingStatus === "object") {
                  var variant = loadingStatus.NAME;
                  if (variant === "success" || variant === "error") {
                    return null;
                  }
                  
                }
                return React.createElement(React.Fragment, undefined, React.createElement(LoadingCircle.make, {
                                color: Styles.Color.light04
                              }), React.createElement(Spacer.make, {
                                width: 8
                              }));
              })), tmp$1, tmp$4, React.createElement(Spacer.make, {
              width: 8
            }), React.createElement(Select.make, {
              additionalStyles: environmentSelection,
              disabled: Belt_Option.isNone(environment),
              onSelect: (function (env) {
                  return Belt_Option.forEach(setEnvironment, (function (setEnvironment) {
                                return Curry._1(setEnvironment, (function (param) {
                                              return Belt_Option.getWithDefault(InspectorViewModel.environmentFromJs(env), "Development");
                                            }));
                              }));
                }),
              options: [
                [
                  {
                    NAME: "Label",
                    VAL: "Development"
                  },
                  InspectorViewModel.environmentToJs("Development")
                ],
                [
                  {
                    NAME: "Label",
                    VAL: "Staging"
                  },
                  InspectorViewModel.environmentToJs("Staging")
                ],
                [
                  {
                    NAME: "Label",
                    VAL: "Production"
                  },
                  InspectorViewModel.environmentToJs("Production")
                ]
              ],
              value: Belt_Option.mapWithDefault(environment, "Production", InspectorViewModel.environmentToJs)
            }), React.createElement(Spacer.make, {
              width: 8
            }), React.createElement("div", {
              className: inputContainer
            }, React.createElement(OnChangeDebounce.make, {
                  initialValue: filterByEventName,
                  onChange: (function (value) {
                      return Belt_Option.forEach(setFiltering, (function (setFiltering) {
                                    return Curry._1(setFiltering, (function (param) {
                                                  return value;
                                                }));
                                  }));
                    }),
                  children: (function (value, onChange) {
                      return React.createElement(Input.make, {
                                  value: value,
                                  onChange: onChange,
                                  id: "filter",
                                  placeholder: "Search for event...",
                                  className: searchInput,
                                  disabled: Belt_Option.isNone(environment),
                                  inputRef: eventFilterInputRef,
                                  icon: React.createElement(Icon.make, {
                                        type_: "search",
                                        color: Belt_Option.isSome(environment) ? Styles.Color.light11 : Styles.Color.light07
                                      })
                                });
                    })
                })));
  } else {
    tmp = null;
  }
  return React.createElement(Portal.make, {
              children: React.createElement("div", {
                    className: headerStyles(workspace.isSandbox)
                  }, React.createElement(Title.make, {
                        children: inspectorView === "issues" ? "Inspector Issues" : (
                            inspectorView === "events" ? "Inspector Events" : "Saved Views"
                          ),
                        size: "Tiny"
                      }), React.createElement(Spacer.make, {
                        width: 16
                      }), React.createElement(Button.make, {
                        icon: "plus",
                        label: "Connect Source",
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: hasSourcesWithoutInspector ? ({
                                        NAME: "SourceSetup",
                                        VAL: [
                                          "inspectorSetup",
                                          {
                                            NAME: "inspector",
                                            VAL: "events"
                                          }
                                        ]
                                      }) : ({
                                        NAME: "CreateSource",
                                        VAL: "inspectorSetup"
                                      })
                                });
                            return AnalyticsRe.emptyStateInteracted(schemaBundle, inspectorView === "events" ? "InspectorEvents" : "InspectorIssues", "Setup", undefined);
                          }),
                        style: "outline"
                      }), React.createElement(Spacer.make, {
                        width: 16
                      }), React.createElement(InspectorViewHeader$AddNewEventsButton, {
                        eventNamesNotInTrackingPlan: eventNamesNotInTrackingPlan
                      }), tmp)
            });
}

var Header = {
  make: InspectorViewHeader$Header
};

function InspectorViewHeader$Disabled(Props) {
  var hasSourcesWithoutInspector = Props.hasSourcesWithoutInspector;
  var inspectorView = Props.inspectorView;
  return React.createElement(InspectorViewHeader$Header, {
              hasSourcesWithoutInspector: hasSourcesWithoutInspector,
              inspectorView: inspectorView
            });
}

var Disabled = {
  make: InspectorViewHeader$Disabled
};

function InspectorViewHeader(Props) {
  var environment = Props.environment;
  var eventNamesNotInTrackingPlan = Props.eventNamesNotInTrackingPlan;
  var lastUpdated = Props.lastUpdated;
  var fetchEvents = Props.fetchEvents;
  var filterByEventName = Props.filterByEventName;
  var loadingStatus = Props.loadingStatus;
  var newerVersionAvailable = Props.newerVersionAvailable;
  var onRefresh = Props.onRefresh;
  var setEnvironment = Props.setEnvironment;
  var setFiltering = Props.setFiltering;
  var setTimeWindow = Props.setTimeWindow;
  var timeWindow = Props.timeWindow;
  var hasSourcesWithoutInspector = Props.hasSourcesWithoutInspector;
  var inspectorView = Props.inspectorView;
  var tmp = {
    hasSourcesWithoutInspector: hasSourcesWithoutInspector,
    headerProps: {
      environment: environment,
      lastUpdated: lastUpdated,
      fetchEvents: fetchEvents,
      filterByEventName: filterByEventName,
      loadingStatus: loadingStatus,
      setEnvironment: setEnvironment,
      setFiltering: setFiltering,
      setTimeWindow: setTimeWindow,
      timeWindow: timeWindow
    },
    inspectorView: inspectorView
  };
  if (eventNamesNotInTrackingPlan !== undefined) {
    tmp.eventNamesNotInTrackingPlan = Caml_option.valFromOption(eventNamesNotInTrackingPlan);
  }
  if (newerVersionAvailable !== undefined) {
    tmp.newerVersionAvailable = Caml_option.valFromOption(newerVersionAvailable);
  }
  if (onRefresh !== undefined) {
    tmp.onRefresh = Caml_option.valFromOption(onRefresh);
  }
  return React.createElement(InspectorViewHeader$Header, tmp);
}

var headerHeight = 63;

var make = InspectorViewHeader;

export {
  AddNewEventsButton ,
  headerHeight ,
  Style ,
  Header ,
  Disabled ,
  make ,
  
}
/* environmentSelection Not a pure module */
