// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as IconTags from "./IconTags.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as SourceIcon from "./SourceIcon.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DiffComments from "./DiffComments.mjs";
import * as IconDestinations from "./IconDestinations.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as WebhookPayloadFormat from "../../shared/models/WebhookPayloadFormat.mjs";

function DiffIntegration$Row(Props) {
  var label = Props.label;
  var wordDiffOpt = Props.wordDiff;
  var before = Props.before;
  var after = Props.after;
  var wordDiff = wordDiffOpt !== undefined ? wordDiffOpt : true;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginTop(Css.px(16)),
                    tl: /* [] */0
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light10,
                  children: label
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.px(4)),
                        tl: /* [] */0
                      })
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  children: JsDiff.renderStringDiff(undefined, true, wordDiff, before, after)
                }));
}

var Row = {
  make: DiffIntegration$Row
};

function getEnrichedFilters(model, filters) {
  return Belt_List.keepMapU(filters, (function (filter) {
                var variant = filter.NAME;
                if (variant === "Destination") {
                  return Belt_Option.map(ModelUtils.getDestinationById(filter.VAL, model), (function (source) {
                                return {
                                        NAME: "Destination",
                                        VAL: source
                                      };
                              }));
                } else if (variant === "Tag") {
                  return {
                          NAME: "Tag",
                          VAL: filter.VAL
                        };
                } else {
                  return Belt_Option.map(ModelUtils.getSourceById(filter.VAL, model), (function (source) {
                                return {
                                        NAME: "Source",
                                        VAL: source
                                      };
                              }));
                }
              }));
}

function rootStyles(customColor) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.paddingTop(Css.px(2)),
                  tl: {
                    hd: Css.paddingRight(Css.px(6)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(2)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(6)),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", customColor),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.marginRight(Css.px(6)),
                              tl: {
                                hd: Css.marginBottom(Css.px(6)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var iconStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.marginRight(Css.px(6)),
        tl: /* [] */0
      }
    });

function DiffIntegration$Filter(Props) {
  var filter = Props.filter;
  var diff = Props.diff;
  var color = diff ? Styles.Color.darkRed : Styles.Color.darkGreen;
  var variant = filter.NAME;
  var variant$1 = filter.NAME;
  return React.createElement("div", {
              className: rootStyles(color)
            }, React.createElement("div", {
                  className: iconStyles
                }, variant === "Destination" ? Belt_Option.mapWithDefault(filter.VAL.type_, React.createElement(IconDestinations.make, {
                            color: color
                          }), (function (destination) {
                          return React.createElement(Icons.Destinations.make, {
                                      destination: destination,
                                      size: 16
                                    });
                        })) : (
                    variant === "Tag" ? React.createElement(IconTags.make, {
                            color: color
                          }) : React.createElement(SourceIcon.make, {
                            source: filter.VAL,
                            color: color
                          })
                  )), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: color,
                  children: variant$1 === "Destination" ? ModelUtils.getDestinationName(filter.VAL) : (
                      variant$1 === "Tag" ? filter.VAL : AvoConfig.getSourceName(filter.VAL)
                    )
                }));
}

var Filter = {
  rootStyles: rootStyles,
  iconStyles: iconStyles,
  make: DiffIntegration$Filter
};

var maxWidthPx = Css.px(736);

function frameStyles(customColor) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems("stretch"),
                  tl: {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.maxWidth(maxWidthPx),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.cursor("pointer"),
                          tl: {
                            hd: Css.hover({
                                  hd: Css.backgroundColor(Styles.Color.light02),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", customColor),
                                tl: {
                                  hd: Css.padding(Css.px(20)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var contentStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(16)),
      tl: {
        hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
        tl: /* [] */0
      }
    });

function DiffIntegration(Props) {
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var branchId = Props.branchId;
  var goToCommentId = Props.goToCommentId;
  var withComments = Props.withComments;
  var events = Props.events;
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  if (match.NAME !== "Integration") {
    return null;
  }
  var integrationId = match.VAL;
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  var hasCreate = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "CreateIntegration";
          } else {
            return false;
          }
        }));
  var hasRemoved = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "RemoveIntegration";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasRemoved !== undefined ? "Remove" : "Update"
    );
  var current = Belt_Option.getWithDefault(Belt_List.getBy(masterModel.integrations, (function (integration) {
              return integration.id === integrationId;
            })), TrackingPlanModel.emptyIntegration(integrationId));
  var next = Belt_Option.getWithDefault(Belt_List.getBy(branchModel.integrations, (function (integration) {
              return integration.id === integrationId;
            })), TrackingPlanModel.emptyIntegration(integrationId));
  var tmp;
  if (changeType === "Remove") {
    tmp = React.createElement("div", {
          className: frameStyles(Styles.Color.darkRed)
        }, React.createElement($$Text.make, {
              size: "Medium",
              weight: "Semi",
              children: JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.getWithDefault(current.name, ""), Belt_Option.getWithDefault(next.name, ""))
            }));
  } else if (changeType === "Update") {
    var match$1 = current.config;
    var match$2 = next.config;
    var tmp$1;
    if (match$1 !== undefined) {
      switch (match$1.TAG | 0) {
        case /* Protocols */0 :
            if (match$2 !== undefined && match$2.TAG === /* Protocols */0) {
              var nextConfig = match$2._0;
              var currentConfig = match$1._0;
              tmp$1 = React.createElement(React.Fragment, undefined, Caml_obj.caml_notequal(currentConfig.workspace, nextConfig.workspace) ? React.createElement(DiffIntegration$Row, {
                          label: "Segment Workspace Name",
                          before: Belt_Option.getWithDefault(currentConfig.workspace, ""),
                          after: Belt_Option.getWithDefault(nextConfig.workspace, "")
                        }) : null, Caml_obj.caml_notequal(currentConfig.trackingPlanId, nextConfig.trackingPlanId) ? React.createElement(DiffIntegration$Row, {
                          label: "Protocols Tracking Plan ID",
                          before: Belt_Option.getWithDefault(currentConfig.trackingPlanId, ""),
                          after: Belt_Option.getWithDefault(nextConfig.trackingPlanId, "")
                        }) : null, Caml_obj.caml_notequal(currentConfig.accessKey, nextConfig.accessKey) ? React.createElement(DiffIntegration$Row, {
                          label: "Segment Access Token",
                          before: Belt_Option.isSome(currentConfig.accessKey) ? "XXXXXXXXX" : "",
                          after: Belt_Option.isSome(nextConfig.accessKey) ? "•••••••••" : ""
                        }) : null, currentConfig.euDataResidency !== nextConfig.euDataResidency ? React.createElement(DiffIntegration$Row, {
                          label: "EU Data Residency",
                          before: currentConfig.euDataResidency ? "true" : "false",
                          after: nextConfig.euDataResidency ? "true" : "false"
                        }) : null);
            } else {
              tmp$1 = null;
            }
            break;
        case /* Taxonomy */1 :
            if (match$2 !== undefined && match$2.TAG === /* Taxonomy */1) {
              var nextConfig$1 = match$2._0;
              var currentConfig$1 = match$1._0;
              tmp$1 = React.createElement(React.Fragment, undefined, Caml_obj.caml_notequal(currentConfig$1.apiKey, nextConfig$1.apiKey) ? React.createElement(DiffIntegration$Row, {
                          label: "Amplitude API Key",
                          before: Belt_Option.getWithDefault(currentConfig$1.apiKey, ""),
                          after: Belt_Option.getWithDefault(nextConfig$1.apiKey, "")
                        }) : null, Caml_obj.caml_notequal(currentConfig$1.secretKey, nextConfig$1.secretKey) ? React.createElement(DiffIntegration$Row, {
                          label: "Amplitude Secret Key",
                          before: Belt_Option.isSome(currentConfig$1.secretKey) ? "XXXXXXXXX" : "",
                          after: Belt_Option.isSome(nextConfig$1.secretKey) ? "•••••••••" : ""
                        }) : null, currentConfig$1.euDataResidency !== nextConfig$1.euDataResidency ? React.createElement(DiffIntegration$Row, {
                          label: "EU Data Residency",
                          before: currentConfig$1.euDataResidency ? "true" : "false",
                          after: nextConfig$1.euDataResidency ? "true" : "false"
                        }) : null);
            } else {
              tmp$1 = null;
            }
            break;
        case /* Lexicon */2 :
            if (match$2 !== undefined && match$2.TAG === /* Lexicon */2) {
              var nextConfig$2 = match$2._0;
              var currentConfig$2 = match$1._0;
              tmp$1 = React.createElement(React.Fragment, undefined, Caml_obj.caml_notequal(currentConfig$2.projectId, nextConfig$2.projectId) ? React.createElement(DiffIntegration$Row, {
                          label: "Mixpanel Project Id",
                          before: Belt_Option.getWithDefault(currentConfig$2.projectId, ""),
                          after: Belt_Option.getWithDefault(nextConfig$2.projectId, "")
                        }) : null, Caml_obj.caml_notequal(currentConfig$2.userName, nextConfig$2.userName) ? React.createElement(DiffIntegration$Row, {
                          label: "Mixpanel Service Account Username",
                          before: Belt_Option.getWithDefault(currentConfig$2.userName, ""),
                          after: Belt_Option.getWithDefault(nextConfig$2.userName, "")
                        }) : null, Caml_obj.caml_notequal(currentConfig$2.secretKey, nextConfig$2.secretKey) ? React.createElement(DiffIntegration$Row, {
                          label: "Mixpanel Service Account Secret",
                          before: Belt_Option.isSome(currentConfig$2.secretKey) ? "XXXXXXXXX" : "",
                          after: Belt_Option.isSome(nextConfig$2.secretKey) ? "•••••••••" : ""
                        }) : null, currentConfig$2.euDataResidency !== nextConfig$2.euDataResidency ? React.createElement(DiffIntegration$Row, {
                          label: "EU Data Residency",
                          before: currentConfig$2.euDataResidency ? "true" : "false",
                          after: nextConfig$2.euDataResidency ? "true" : "false"
                        }) : null, currentConfig$2.categoriesAsTags !== nextConfig$2.categoriesAsTags ? React.createElement(DiffIntegration$Row, {
                          label: "Categories as tags",
                          before: currentConfig$2.categoriesAsTags ? "true" : "false",
                          after: nextConfig$2.categoriesAsTags ? "true" : "false"
                        }) : null);
            } else {
              tmp$1 = null;
            }
            break;
        case /* Webhook */3 :
            if (match$2 !== undefined && match$2.TAG === /* Webhook */3) {
              var nextConfig$3 = match$2._0;
              var currentConfig$3 = match$1._0;
              if (Caml_obj.caml_notequal(currentConfig$3.webhookUrl, nextConfig$3.webhookUrl) || Caml_obj.caml_notequal(currentConfig$3.payloadFormat, nextConfig$3.payloadFormat)) {
                var match$3 = currentConfig$3.payloadFormat;
                var match$4 = nextConfig$3.payloadFormat;
                var tmp$2;
                if (typeof match$3 === "number") {
                  tmp$2 = typeof match$4 === "number" ? null : React.createElement(DiffIntegration$Row, {
                          label: "Vendor",
                          wordDiff: false,
                          before: "",
                          after: Belt_Option.getWithDefault(match$4._0.vendor, "")
                        });
                } else {
                  var vendorBefore = match$3._0.vendor;
                  tmp$2 = typeof match$4 === "number" ? React.createElement(DiffIntegration$Row, {
                          label: "Vendor",
                          wordDiff: false,
                          before: Belt_Option.getWithDefault(vendorBefore, ""),
                          after: ""
                        }) : React.createElement(DiffIntegration$Row, {
                          label: "Vendor",
                          wordDiff: false,
                          before: Belt_Option.getWithDefault(vendorBefore, ""),
                          after: Belt_Option.getWithDefault(match$4._0.vendor, "")
                        });
                }
                tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(DiffIntegration$Row, {
                          label: "Webhook URL",
                          before: Belt_Option.getWithDefault(currentConfig$3.webhookUrl, ""),
                          after: Belt_Option.getWithDefault(nextConfig$3.webhookUrl, "")
                        }), React.createElement(DiffIntegration$Row, {
                          label: "Webhook Payload Format",
                          wordDiff: false,
                          before: WebhookPayloadFormat.prettyPrint(currentConfig$3.payloadFormat),
                          after: WebhookPayloadFormat.prettyPrint(nextConfig$3.payloadFormat)
                        }), tmp$2);
              } else {
                tmp$1 = null;
              }
            } else {
              tmp$1 = null;
            }
            break;
        case /* SnowplowDataStructures */4 :
            tmp$1 = null;
            break;
        case /* MParticleDataMaster */5 :
            if (match$2 !== undefined && match$2.TAG === /* MParticleDataMaster */5) {
              var nextConfig$4 = match$2._0;
              var currentConfig$4 = match$1._0;
              tmp$1 = React.createElement(React.Fragment, undefined, Caml_obj.caml_notequal(currentConfig$4.clientId, nextConfig$4.clientId) ? React.createElement(DiffIntegration$Row, {
                          label: "mParticle Client ID",
                          before: Belt_Option.getWithDefault(currentConfig$4.clientId, ""),
                          after: Belt_Option.getWithDefault(nextConfig$4.clientId, "")
                        }) : null, Caml_obj.caml_notequal(currentConfig$4.clientSecret, nextConfig$4.clientSecret) ? React.createElement(DiffIntegration$Row, {
                          label: "mParticle Client Secret",
                          before: Belt_Option.isSome(currentConfig$4.clientSecret) ? "XXXXXXXXX" : "",
                          after: Belt_Option.isSome(nextConfig$4.clientSecret) ? "•••••••••" : ""
                        }) : null, Caml_obj.caml_notequal(currentConfig$4.workspaceId, nextConfig$4.workspaceId) ? React.createElement(DiffIntegration$Row, {
                          label: "mParticle Workspace ID",
                          before: Belt_Option.getWithDefault(currentConfig$4.workspaceId, ""),
                          after: Belt_Option.getWithDefault(nextConfig$4.workspaceId, "")
                        }) : null, Caml_obj.caml_notequal(currentConfig$4.planId, nextConfig$4.planId) ? React.createElement(DiffIntegration$Row, {
                          label: "mParticle Plan ID",
                          before: Belt_Option.getWithDefault(currentConfig$4.planId, ""),
                          after: Belt_Option.getWithDefault(nextConfig$4.planId, "")
                        }) : null);
            } else {
              tmp$1 = null;
            }
            break;
        case /* RudderstackTrackingPlans */6 :
            if (match$2 !== undefined && match$2.TAG === /* RudderstackTrackingPlans */6) {
              var nextConfig$5 = match$2._0;
              var currentConfig$5 = match$1._0;
              tmp$1 = React.createElement(React.Fragment, undefined, Caml_obj.caml_notequal(currentConfig$5.emailAddress, nextConfig$5.emailAddress) ? React.createElement(DiffIntegration$Row, {
                          label: "Rudderstack Account Enamil Address",
                          before: Belt_Option.getWithDefault(currentConfig$5.emailAddress, ""),
                          after: Belt_Option.getWithDefault(nextConfig$5.emailAddress, "")
                        }) : null, Caml_obj.caml_notequal(currentConfig$5.trackingPlanDisplayName, nextConfig$5.trackingPlanDisplayName) ? React.createElement(DiffIntegration$Row, {
                          label: "Rudderstack Tracking Plan Name",
                          before: Belt_Option.getWithDefault(currentConfig$5.trackingPlanDisplayName, ""),
                          after: Belt_Option.getWithDefault(nextConfig$5.trackingPlanDisplayName, "")
                        }) : null, Caml_obj.caml_notequal(currentConfig$5.accessToken, nextConfig$5.accessToken) ? React.createElement(DiffIntegration$Row, {
                          label: "Rudderstack Access Token",
                          before: Belt_Option.isSome(currentConfig$5.accessToken) ? "XXXXXXXXX" : "",
                          after: Belt_Option.isSome(nextConfig$5.accessToken) ? "•••••••••" : ""
                        }) : null);
            } else {
              tmp$1 = null;
            }
            break;
        
      }
    } else {
      tmp$1 = null;
    }
    var removedFilters = Belt_List.keepU(current.filters, (function (currentFilter) {
            return Belt_Option.isNone(Belt_List.getByU(next.filters, (function (nextFilter) {
                              return Caml_obj.caml_equal(currentFilter, nextFilter);
                            })));
          }));
    var addedFilters = Belt_List.keepU(next.filters, (function (currentFilter) {
            return Belt_Option.isNone(Belt_List.getByU(current.filters, (function (nextFilter) {
                              return Caml_obj.caml_equal(currentFilter, nextFilter);
                            })));
          }));
    var match$5 = current.autoPublish;
    var match$6 = next.autoPublish;
    var tmp$3;
    var exit = 0;
    var exit$1 = 0;
    if (changeType === "Create" && match$5) {
      if (match$6) {
        exit = 1;
      } else {
        tmp$3 = React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginTop(Css.px(16)),
                    tl: /* [] */0
                  })
            }, JsDiff.renderStringDiff(undefined, undefined, undefined, "", "Auto publish disabled"));
      }
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (match$5) {
        tmp$3 = match$6 ? null : React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.marginTop(Css.px(16)),
                      tl: /* [] */0
                    })
              }, JsDiff.renderStringDiff(undefined, undefined, undefined, "Auto publish disabled", ""));
      } else if (match$6) {
        exit = 1;
      } else {
        tmp$3 = null;
      }
    }
    if (exit === 1) {
      tmp$3 = React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.marginTop(Css.px(16)),
                  tl: /* [] */0
                })
          }, JsDiff.renderStringDiff(undefined, undefined, undefined, "", "Auto publish enabled"));
    }
    tmp = React.createElement("div", {
          className: frameStyles(Styles.Color.light04)
        }, React.createElement($$Text.make, {
              size: "Medium",
              weight: "Semi",
              children: JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.getWithDefault(current.name, ""), Belt_Option.getWithDefault(next.name, ""))
            }), React.createElement("div", {
              className: contentStyles
            }, tmp$1, removedFilters !== /* [] */0 || addedFilters !== /* [] */0 ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginTop(Css.px(16)),
                          tl: /* [] */0
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.light10,
                        children: "Filters"
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.flexWrap("wrap"),
                                tl: {
                                  hd: Css.marginTop(Css.px(4)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, Belt_List.toArray(Belt_List.mapU(getEnrichedFilters(branchModel, removedFilters), (function (filter) {
                                  return React.createElement(DiffIntegration$Filter, {
                                              filter: filter,
                                              diff: /* Remove */1,
                                              key: filter.NAME === "Tag" ? filter.VAL : filter.VAL.id
                                            });
                                }))), Belt_List.toArray(Belt_List.mapU(getEnrichedFilters(branchModel, addedFilters), (function (filter) {
                                  return React.createElement(DiffIntegration$Filter, {
                                              filter: filter,
                                              diff: /* Add */0,
                                              key: filter.NAME === "Tag" ? filter.VAL : filter.VAL.id
                                            });
                                }))))) : null, tmp$3));
  } else {
    var match$7 = next.config;
    var tmp$4;
    if (match$7 !== undefined) {
      switch (match$7.TAG | 0) {
        case /* Protocols */0 :
            var match$8 = match$7._0;
            var workspace = match$8.workspace;
            var trackingPlanId = match$8.trackingPlanId;
            var tmp$5;
            tmp$5 = workspace !== undefined && workspace !== "" ? React.createElement(DiffIntegration$Row, {
                    label: "Segment Workspace Name",
                    before: "",
                    after: workspace
                  }) : null;
            var tmp$6;
            tmp$6 = trackingPlanId !== undefined && trackingPlanId !== "" ? React.createElement(DiffIntegration$Row, {
                    label: "Protocols Tracking Plan ID",
                    before: "",
                    after: trackingPlanId
                  }) : null;
            tmp$4 = React.createElement(React.Fragment, undefined, tmp$5, tmp$6, Belt_Option.mapWithDefault(match$8.accessKey, null, (function (_accessKey) {
                        return React.createElement(DiffIntegration$Row, {
                                    label: "Segment Access Token",
                                    before: "",
                                    after: "•••••••••"
                                  });
                      })), match$8.euDataResidency ? React.createElement(DiffIntegration$Row, {
                        label: "Eu Data Residency",
                        before: "",
                        after: "true"
                      }) : null);
            break;
        case /* Taxonomy */1 :
            var match$9 = match$7._0;
            var apiKey = match$9.apiKey;
            var tmp$7;
            tmp$7 = apiKey !== undefined && apiKey !== "" ? React.createElement(DiffIntegration$Row, {
                    label: "Amplitude API Key",
                    before: "",
                    after: apiKey
                  }) : null;
            tmp$4 = React.createElement(React.Fragment, undefined, tmp$7, Belt_Option.mapWithDefault(match$9.secretKey, null, (function (_secretKey) {
                        return React.createElement(DiffIntegration$Row, {
                                    label: "Amplitude Secret Key",
                                    before: "",
                                    after: "•••••••••"
                                  });
                      })), match$9.euDataResidency ? React.createElement(DiffIntegration$Row, {
                        label: "Eu Data Residency",
                        before: "",
                        after: "true"
                      }) : null);
            break;
        case /* Lexicon */2 :
            var match$10 = match$7._0;
            var projectId = match$10.projectId;
            var userName = match$10.userName;
            var tmp$8;
            tmp$8 = projectId !== undefined && projectId !== "" ? React.createElement(DiffIntegration$Row, {
                    label: "Mixpanel Project Id",
                    before: "",
                    after: projectId
                  }) : null;
            var tmp$9;
            tmp$9 = userName !== undefined && userName !== "" ? React.createElement(DiffIntegration$Row, {
                    label: "Mixpanel Service Account Username",
                    before: "",
                    after: userName
                  }) : null;
            tmp$4 = React.createElement(React.Fragment, undefined, tmp$8, tmp$9, Belt_Option.mapWithDefault(match$10.secretKey, null, (function (_secretKey) {
                        return React.createElement(DiffIntegration$Row, {
                                    label: "Mixpanel Service Account Secret",
                                    before: "",
                                    after: "•••••••••"
                                  });
                      })), match$10.euDataResidency ? React.createElement(DiffIntegration$Row, {
                        label: "Eu Data Residency",
                        before: "",
                        after: "true"
                      }) : null, match$10.categoriesAsTags ? React.createElement(DiffIntegration$Row, {
                        label: "Categories as tags",
                        before: "",
                        after: "true"
                      }) : null);
            break;
        case /* Webhook */3 :
            tmp$4 = Belt_Option.mapWithDefault(match$7._0.webhookUrl, null, (function (webhookUrl) {
                    return React.createElement(DiffIntegration$Row, {
                                label: "Webhook URL",
                                before: "",
                                after: webhookUrl
                              });
                  }));
            break;
        case /* SnowplowDataStructures */4 :
            var match$11 = match$7._0;
            var vendor = match$11.vendor;
            var organizationId = match$11.organizationId;
            var tmp$10;
            tmp$10 = organizationId !== undefined && organizationId !== "" ? React.createElement(DiffIntegration$Row, {
                    label: "Snowplow Organization Id",
                    before: "",
                    after: organizationId
                  }) : null;
            var tmp$11;
            tmp$11 = vendor !== undefined && vendor !== "" ? React.createElement(DiffIntegration$Row, {
                    label: "Snowplow Vendor",
                    before: "",
                    after: vendor
                  }) : null;
            tmp$4 = React.createElement(React.Fragment, undefined, tmp$10, tmp$11, Belt_Option.mapWithDefault(match$11.accessToken, null, (function (_accessKey) {
                        return React.createElement(DiffIntegration$Row, {
                                    label: "Snowplow Access Token",
                                    before: "",
                                    after: "•••••••••"
                                  });
                      })));
            break;
        case /* MParticleDataMaster */5 :
            var match$12 = match$7._0;
            var planId = match$12.planId;
            var workspaceId = match$12.workspaceId;
            var clientId = match$12.clientId;
            var tmp$12;
            tmp$12 = clientId !== undefined && clientId !== "" ? React.createElement(DiffIntegration$Row, {
                    label: "mParticle Client ID",
                    before: "",
                    after: clientId
                  }) : null;
            var tmp$13;
            tmp$13 = workspaceId !== undefined && workspaceId !== "" ? React.createElement(DiffIntegration$Row, {
                    label: "mParticle Workspace ID",
                    before: "",
                    after: workspaceId
                  }) : null;
            var tmp$14;
            tmp$14 = planId !== undefined && planId !== "" ? React.createElement(DiffIntegration$Row, {
                    label: "mParticle Plan ID",
                    before: "",
                    after: planId
                  }) : null;
            tmp$4 = React.createElement(React.Fragment, undefined, tmp$12, Belt_Option.mapWithDefault(match$12.clientSecret, null, (function (_accessKey) {
                        return React.createElement(DiffIntegration$Row, {
                                    label: "mParticle Client Secret",
                                    before: "",
                                    after: "•••••••••"
                                  });
                      })), tmp$13, tmp$14);
            break;
        case /* RudderstackTrackingPlans */6 :
            var match$13 = match$7._0;
            var emailAddress = match$13.emailAddress;
            var trackingPlanDisplayName = match$13.trackingPlanDisplayName;
            var tmp$15;
            tmp$15 = emailAddress !== undefined && emailAddress !== "" ? React.createElement(DiffIntegration$Row, {
                    label: "Rudderstack Account Email Address",
                    before: "",
                    after: emailAddress
                  }) : null;
            var tmp$16;
            tmp$16 = trackingPlanDisplayName !== undefined && trackingPlanDisplayName !== "" ? React.createElement(DiffIntegration$Row, {
                    label: "Rudderstack Tracking Plan Name",
                    before: "",
                    after: trackingPlanDisplayName
                  }) : null;
            tmp$4 = React.createElement(React.Fragment, undefined, tmp$15, tmp$16, Belt_Option.mapWithDefault(match$13.accessToken, null, (function (_accessKey) {
                        return React.createElement(DiffIntegration$Row, {
                                    label: "Rudderstack Access Token",
                                    before: "",
                                    after: "•••••••••"
                                  });
                      })));
            break;
        
      }
    } else {
      tmp$4 = null;
    }
    tmp = React.createElement("div", {
          className: frameStyles(Styles.Color.darkGreen)
        }, React.createElement($$Text.make, {
              size: "Medium",
              weight: "Semi",
              children: JsDiff.renderStringDiff(undefined, undefined, undefined, "", Belt_Option.getWithDefault(next.name, ""))
            }), React.createElement("div", {
              className: contentStyles
            }, tmp$4, next.filters === /* [] */0 ? null : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginTop(Css.px(16)),
                          tl: /* [] */0
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.light10,
                        children: "Filters"
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.flexWrap("wrap"),
                                tl: {
                                  hd: Css.marginTop(Css.px(4)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, Belt_List.toArray(Belt_List.mapU(getEnrichedFilters(branchModel, next.filters), (function (filter) {
                                  return React.createElement(DiffIntegration$Filter, {
                                              filter: filter,
                                              diff: /* Add */0,
                                              key: filter.NAME === "Tag" ? filter.VAL : filter.VAL.id
                                            });
                                }))))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(16)),
                        tl: /* [] */0
                      })
                }, JsDiff.renderStringDiff(undefined, undefined, undefined, "", "Auto publish " + (
                      next.autoPublish ? "enabled" : "disabled"
                    )))));
  }
  var tmp$17;
  if (withComments) {
    var tmp$18 = {
      branchId: branchId,
      threadId: current.id,
      itemType: "Integration",
      itemName: AvoConfig.getIntegrationName(current),
      events: events
    };
    if (goToCommentId !== undefined) {
      tmp$18.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp$17 = React.createElement(DiffComments.make, tmp$18);
  } else {
    tmp$17 = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.regular),
                        tl: /* [] */0
                      }
                    }
                  }),
              onClick: (function (param) {
                  return Router.Schema.pushDrawerItem(undefined, undefined, {
                              NAME: "integration",
                              VAL: [
                                integrationId,
                                undefined
                              ]
                            });
                })
            }, tmp, tmp$17);
}

var make = DiffIntegration;

export {
  Row ,
  getEnrichedFilters ,
  Filter ,
  maxWidthPx ,
  frameStyles ,
  contentStyles ,
  make ,
  
}
/* iconStyles Not a pure module */
