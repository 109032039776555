// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as PropertyType from "./PropertyType.mjs";
import * as PropertyInput from "./PropertyInput.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function MergePropertiesModal(Props) {
  var propertyId = Props.propertyId;
  var onClose = Props.onClose;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var properties = Curry._1(TrackingPlanMappedModel.Properties.toArray, ModelStore.Mapped.useProperties(undefined));
  var events = Curry._1(TrackingPlanMappedModel.Events.toArray, ModelStore.Mapped.useEvents(undefined));
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        
      });
  var setReplacementProperty = match[1];
  var replacementProperty = match[0];
  var property = Belt_Array.getBy(properties, (function (property) {
          return property.id === propertyId;
        }));
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(500)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.padding4(Css.px(30), Css.px(30), Css.px(30), Css.px(30)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.cursor("default"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontWeight(Styles.FontWeight.semi),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.large),
                          tl: {
                            hd: Css.paddingBottom(Css.px(15)),
                            tl: {
                              hd: Css.textAlign("center"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, "Archive and Replace Property"), property !== undefined ? React.createElement("div", undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding2(Css.px(10), Css.px(0)),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.regular),
                                tl: /* [] */0
                              }
                            })
                      }, "This will archive the property and replace it on all events its part of with the property you select below."), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding4(Css.px(15), Css.px(0), Css.px(10), Css.px(0)),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, "Archive..."), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.medium),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.padding2(Css.px(10), Css.px(15)),
                                      tl: {
                                        hd: Css.borderRadius(Styles.Border.radius),
                                        tl: {
                                          hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }, property.name, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexWrap("wrap"),
                                    tl: {
                                      hd: Css.alignItems("baseline"),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(PropertyType.make, {
                                property: property
                              }))), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding4(Css.px(15), Css.px(0), Css.px(10), Css.px(0)),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, "and replace with..."), React.createElement(PropertyInput.make, {
                        propertyType: property.sendAs,
                        options: [[
                            undefined,
                            Belt_Array.mapU(Belt_Array.keep(properties, (function (param) {
                                        if (Caml_obj.caml_equal(param.sendAs, property.sendAs)) {
                                          return param.id !== property.id;
                                        } else {
                                          return false;
                                        }
                                      })), (function (property) {
                                    return {
                                            NAME: "Property",
                                            VAL: property
                                          };
                                  }))
                          ]],
                        onSelect: (function (item, param) {
                            if (item.NAME === "Group") {
                              return ;
                            }
                            var property = item.VAL;
                            return Curry._1(setReplacementProperty, (function (param) {
                                          return property;
                                        }));
                          }),
                        currentFilters: currentFilters,
                        renderButton: (function (onClick, buttonRef) {
                            return React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.textAlign("left"),
                                              tl: /* [] */0
                                            })
                                      }, React.createElement("button", {
                                            ref: buttonRef,
                                            className: Curry._1(Css.style, {
                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                  tl: {
                                                    hd: Css.fontSize(Styles.FontSize.medium),
                                                    tl: {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.alignItems("center"),
                                                        tl: {
                                                          hd: Css.padding2(Css.px(10), Css.px(15)),
                                                          tl: {
                                                            hd: Css.borderRadius(Styles.Border.radius),
                                                            tl: {
                                                              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                              tl: {
                                                                hd: Css.width(Css.pct(100)),
                                                                tl: {
                                                                  hd: Css.cursor("pointer"),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }),
                                            onClick: onClick
                                          }, replacementProperty !== undefined ? React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.display("flex"),
                                                        tl: /* [] */0
                                                      })
                                                }, replacementProperty.name, React.createElement("div", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.display("flex"),
                                                            tl: {
                                                              hd: Css.flexWrap("wrap"),
                                                              tl: {
                                                                hd: Css.alignItems("baseline"),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          })
                                                    }, React.createElement(PropertyType.make, {
                                                          property: replacementProperty
                                                        }))) : React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.color(Styles.Color.darkBlue),
                                                        tl: {
                                                          hd: Css.fontSize(Styles.FontSize.regular),
                                                          tl: {
                                                            hd: Css.textAlign("center"),
                                                            tl: {
                                                              hd: Css.hover({
                                                                    hd: Css.color(Styles.Color.deepBlue),
                                                                    tl: /* [] */0
                                                                  }),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      })
                                                }, "Select property...")));
                          }),
                        sendActions: sendActions,
                        filters: filters,
                        propertyLocation: "PropertyDetails"
                      }), React.createElement(Spacer.make, {
                        height: 24
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement(Button.make, {
                            label: "Cancel",
                            onClick: (function (param) {
                                return Curry._1(onClose, undefined);
                              }),
                            style: "ghost"
                          }), React.createElement(Spacer.make, {
                            width: 16
                          }), React.createElement(Button.make, {
                            disabled: Belt_Option.isNone(replacementProperty),
                            label: "Archive and Replace",
                            onClick: (function (param) {
                                if (replacementProperty === undefined) {
                                  return ;
                                }
                                if (!window.confirm("Are you sure you would like to archive " + property.name + " and replace it with " + replacementProperty.name)) {
                                  return ;
                                }
                                var eventsWithProperty = Belt_Array.keepU(events, (function ($$event) {
                                        return Belt_List.someU($$event.properties, (function (item) {
                                                      if (item.TAG === /* PropertyRef */0) {
                                                        return item._0.id === property.id;
                                                      } else {
                                                        return false;
                                                      }
                                                    }));
                                      }));
                                var replaceActions = Belt_Array.concatMany(Belt_Array.mapU(Belt_Array.keepU(eventsWithProperty, (function ($$event) {
                                                return !Belt_List.someU($$event.properties, (function (item) {
                                                              if (item.TAG === /* PropertyRef */0) {
                                                                return item._0.id === replacementProperty.id;
                                                              } else {
                                                                return false;
                                                              }
                                                            }));
                                              })), (function ($$event) {
                                            return [
                                                    [
                                                      {
                                                        NAME: "RemovePropertyRefV3",
                                                        VAL: [
                                                          $$event.id,
                                                          property.id
                                                        ]
                                                      },
                                                      {
                                                        eventId: $$event.id,
                                                        eventQuery: $$event.id,
                                                        propertyId: property.id,
                                                        propertyQuery: property.id
                                                      }
                                                    ],
                                                    [
                                                      {
                                                        NAME: "AddPropertyRef",
                                                        VAL: [
                                                          $$event.id,
                                                          replacementProperty.id
                                                        ]
                                                      },
                                                      {
                                                        eventId: $$event.id,
                                                        eventQuery: $$event.id,
                                                        propertyId: replacementProperty.id,
                                                        propertyQuery: replacementProperty.id
                                                      }
                                                    ]
                                                  ];
                                          })));
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      Belt_Array.concat([[
                                              {
                                                NAME: "Archive",
                                                VAL: {
                                                  NAME: "Property",
                                                  VAL: property.id
                                                }
                                              },
                                              {
                                                propertyId: property.id,
                                                propertyQuery: property.id
                                              }
                                            ]], replaceActions)
                                    ]);
                                Router.Schema.pushDrawerItem(undefined, undefined, {
                                      NAME: "property",
                                      VAL: [
                                        replacementProperty.id,
                                        undefined
                                      ]
                                    });
                                return Curry._1(onClose, undefined);
                              })
                          }))) : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding(Css.px(25)),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.color(Styles.Color.red),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, "Property not found."));
}

var make = MergePropertiesModal;

export {
  make ,
  
}
/* Css Not a pure module */
