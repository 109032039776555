// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Tooltip from "./Tooltip.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as IconErase from "./IconErase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@mantine/core";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as InspectorIssuesFilter from "../../shared/models/InspectorIssuesFilter.mjs";
import * as InspectorIssuesFilterHooks from "./inspector/InspectorIssuesFilterHooks.mjs";
import * as InspectorIssuesFilterUtils from "../../shared/utils/InspectorIssuesFilterUtils.mjs";

var targetBunchContainer = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: /* [] */0
    });

function targetContainer(hasFilters) {
  return Curry._1(Css.style, {
              hd: Css.cursor("pointer"),
              tl: {
                hd: Css.borderRadius(Css.px(5)),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "all"),
                  tl: {
                    hd: Css.backgroundColor(hasFilters ? Styles.Color.green : Styles.Color.paleGreen),
                    tl: {
                      hd: Css.hover({
                            hd: Css.backgroundColor(Styles.Color.green),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.overflow("hidden"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var targetContent = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(4), Css.px(8)),
      tl: {
        hd: Css.paddingRight(Css.px(26)),
        tl: {
          hd: Css.color(Styles.Color.white),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.gap(Css.px(4)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var removeFilterContainer = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.margin(Css.px(2)),
        tl: {
          hd: Css.right(Css.px(1)),
          tl: {
            hd: Css.top(Css.px(0)),
            tl: {
              hd: Css.height({
                    NAME: "subtract",
                    VAL: [
                      Css.pct(100.0),
                      Css.px(4)
                    ]
                  }),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "all"),
                  tl: {
                    hd: Css.borderRadius(Css.pct(50.0)),
                    tl: {
                      hd: Css.cursor("pointer"),
                      tl: {
                        hd: Css.hover({
                              hd: Icon.color(Styles.Color.white),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.green),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var removeFilterButton = Curry._1(Css.style, {
      hd: Css.padding(Css.px(2)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Icon.color(Styles.Color.white),
              tl: /* [] */0
            }
          }
        }
      }
    });

var dropdown_0 = Css.minWidth(Css.px(200));

var dropdown_1 = {
  hd: Css.paddingTop(Css.px(8)),
  tl: {
    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                  NAME: "num",
                  VAL: 0.15
                }))),
    tl: {
      hd: Css.transform(Css.translateY(Css.px(-6))),
      tl: {
        hd: Css.maxHeight(Css.vh(40.0)),
        tl: {
          hd: Css.overflow("auto"),
          tl: {
            hd: Css.borderColor(Styles.Color.light04),
            tl: /* [] */0
          }
        }
      }
    }
  }
};

var dropdown = {
  hd: dropdown_0,
  tl: dropdown_1
};

var dropdownItemContainer_0 = Css.padding2(Css.px(8), Css.px(16));

var dropdownItemContainer_1 = {
  hd: Css.position("relative"),
  tl: {
    hd: Css.borderRadius("zero"),
    tl: /* [] */0
  }
};

var dropdownItemContainer = {
  hd: dropdownItemContainer_0,
  tl: dropdownItemContainer_1
};

var dropdownItem = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gap(Css.px(4)),
          tl: /* [] */0
        }
      }
    });

var dropdownDoneContainer_0 = Css.position("sticky");

var dropdownDoneContainer_1 = {
  hd: Css.left(Css.px(0)),
  tl: {
    hd: Css.bottom(Css.px(0)),
    tl: {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.borderRadius("zero"),
        tl: {
          hd: Css.borderTop(Css.px(1), Css.solid, Styles.Color.light07),
          tl: {
            hd: Css.backgroundColor(Styles.Color.lightBlue),
            tl: {
              hd: Css.color(Styles.Color.darkBlue),
              tl: {
                hd: Icon.color(Styles.Color.darkBlue),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "all"),
                  tl: {
                    hd: Css.hover({
                          hd: Styles.backgroundColorGuarantee(Styles.Color.setAlpha(Styles.Color.paleBlue, 0.3)),
                          tl: {
                            hd: Css.color(Styles.Color.deepBlue),
                            tl: {
                              hd: Icon.color(Styles.Color.deepBlue),
                              tl: /* [] */0
                            }
                          }
                        }),
                    tl: {
                      hd: Css.padding(Css.px(16)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(20)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var dropdownDoneContainer = {
  hd: dropdownDoneContainer_0,
  tl: dropdownDoneContainer_1
};

var dropdownDoneButton = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.gap(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

function searchContainer(obtainedWidth) {
  return Curry._1(Css.style, {
              hd: Css.marginTop(Css.px(-8)),
              tl: {
                hd: Css.padding2(Css.px(0), Css.px(16)),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.gap(Css.px(8)),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light07),
                          tl: {
                            hd: obtainedWidth !== undefined ? Css.width(Css.px(obtainedWidth)) : Css.width(Css.pct(100.0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var searchInput = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100)),
      tl: {
        hd: Css.padding(Css.px(16)),
        tl: {
          hd: Css.paddingLeft(Css.px(0)),
          tl: {
            hd: Css.fontSize(Css.px(14)),
            tl: {
              hd: Css.fontWeight({
                    NAME: "num",
                    VAL: 500
                  }),
              tl: {
                hd: Css.borderWidth(Css.px(0)),
                tl: {
                  hd: Css.outlineStyle("none"),
                  tl: {
                    hd: Css.outlineWidth(Css.px(0)),
                    tl: {
                      hd: Css.color(Styles.Color.light10),
                      tl: {
                        hd: Css.placeholder({
                              hd: Css.color(Styles.Color.light08),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function eraseButton(disabled) {
  return Curry._1(Css.style, {
              hd: Css.padding(Css.px(0)),
              tl: {
                hd: Css.marginTop(Css.px(0)),
                tl: {
                  hd: Css.marginBottom(Css.px(0)),
                  tl: {
                    hd: Css.marginLeft(Css.px(0)),
                    tl: {
                      hd: Css.margin(Css.px(0)),
                      tl: {
                        hd: Css.borderWidth(Css.px(0)),
                        tl: {
                          hd: Css.borderRadius(Css.px(2)),
                          tl: {
                            hd: Css.background("transparent"),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "opacity"),
                              tl: {
                                hd: Css.opacity(disabled ? 0 : 1),
                                tl: {
                                  hd: Css.unsafe("appearance", "none"),
                                  tl: {
                                    hd: Css.outlineStyle("none"),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.active({
                                              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.focus({
                                                hd: Css.outlineStyle("none"),
                                                tl: {
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function InspectorIssuesFilters$FilterMultiSelect(Props) {
  var filterType = Props.filterType;
  var getCountedAvailableFilters = Props.getCountedAvailableFilters;
  var setFilterPlaceholder = Props.setFilterPlaceholder;
  var model = Props.model;
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var filters = match.filters;
  var setFilters = InspectorIssuesFilterHooks.useSetFilters(undefined);
  var match$1 = React.useState(function () {
        return Belt_Set.fromArray(InspectorIssuesFilterUtils.getFilters(filters, filterType), InspectorIssuesFilter.Filter.Comparator);
      });
  var setSelectedValues = match$1[1];
  var selectedValues = match$1[0];
  React.useEffect((function () {
          Curry._1(setSelectedValues, (function (param) {
                  return Belt_Set.fromArray(InspectorIssuesFilterUtils.getFilters(filters, filterType), InspectorIssuesFilter.Filter.Comparator);
                }));
          
        }), [filters]);
  var match$2 = React.useState(function () {
        return Belt_Map.getExn(Curry._1(getCountedAvailableFilters, filterType), filterType);
      });
  var setCountedAvailableFilters = match$2[1];
  var countedAvailableFilters = match$2[0];
  var match$3 = Hooks.useDisclosure(Belt_Set.size(selectedValues) === 0);
  var actions = match$3[1];
  var opened = match$3[0];
  var arbitraryFilters = Belt_Array.mapU(Belt_Array.keepU(Belt_Set.toArray(selectedValues), (function (filter) {
              var variant = countedAvailableFilters.NAME;
              if (variant === "propertyNames") {
                if (typeof filter !== "object") {
                  return false;
                }
                if (filter.NAME !== "propertyName") {
                  return false;
                }
                var propertyName = filter.VAL;
                return Belt_Array.everyU(countedAvailableFilters.VAL, (function (param) {
                              return param[0] !== propertyName;
                            }));
              }
              if (variant !== "eventNames") {
                return false;
              }
              if (typeof filter !== "object") {
                return false;
              }
              if (filter.NAME !== "eventName") {
                return false;
              }
              var eventName = filter.VAL;
              return Belt_Array.everyU(countedAvailableFilters.VAL, (function (param) {
                            return param[0] !== eventName;
                          }));
            })), (function (filter) {
          return [
                  filter,
                  "\"" + InspectorIssuesFilter.Filter.toValue(filter) + "\"",
                  -1
                ];
        }));
  React.useEffect((function () {
          if (opened) {
            Curry._1(setCountedAvailableFilters, (function (param) {
                    return Belt_Map.getExn(Curry._1(getCountedAvailableFilters, filterType), filterType);
                  }));
          }
          
        }), [opened]);
  var groupedFilter = Belt_Option.getWithDefault(Belt_Array.getBy(InspectorIssuesFilterUtils.groupFilters(filters), (function (groupedFilter) {
              return InspectorIssuesFilter.GroupedFilter.toFilterType(groupedFilter) === filterType;
            })), InspectorIssuesFilter.GroupedFilter.fromFilterType(filterType));
  var toggleValue = function (value) {
    return Curry._1(setSelectedValues, (function (existingValues) {
                  if (Belt_Set.has(existingValues, value)) {
                    return Belt_Set.remove(existingValues, value);
                  } else {
                    return Belt_Set.add(existingValues, value);
                  }
                }));
  };
  Hooks.useDidUpdate1((function (param) {
          Curry._1(setFilters, (function (existingFilters) {
                  var existingSet = Belt_Set.keep(Belt_Set.fromArray(existingFilters, InspectorIssuesFilter.Filter.Comparator), (function (filter) {
                          return InspectorIssuesFilter.Filter.toFilterType(filter) === filterType;
                        }));
                  var removeSet = Belt_Set.diff(existingSet, selectedValues);
                  var addSet = Belt_Set.diff(selectedValues, existingSet);
                  return Belt_Array.concat(Belt_Array.keep(existingFilters, (function (filter) {
                                    if (Belt_Set.has(removeSet, filter)) {
                                      return InspectorIssuesFilter.Filter.toFilterType(filter) !== filterType;
                                    } else {
                                      return true;
                                    }
                                  })), Belt_Set.toArray(addSet));
                }));
          if (Belt_Set.size(selectedValues) > 0) {
            Curry._1(setFilterPlaceholder, (function (param) {
                    
                  }));
          } else {
            Curry._1(setFilterPlaceholder, (function (param) {
                    return groupedFilter;
                  }));
          }
          
        }), [selectedValues]);
  var variant = countedAvailableFilters.NAME;
  var filterCount = variant === "eventNames" || variant === "releases" || variant === "issueTypes" || variant === "propertyNames" ? countedAvailableFilters.VAL.length : countedAvailableFilters.VAL.length;
  var searchContainerRef = React.useRef(null);
  var match$4 = React.useState(function () {
        return "";
      });
  var setSearchInput = match$4[1];
  var searchInput$1 = match$4[0];
  var match$5 = React.useState(function () {
        
      });
  var setObtainedWidth = match$5[1];
  var variant$1 = countedAvailableFilters.NAME;
  var filteredValues = Belt_SortArray.stableSortByU(variant$1 === "propertyNames" ? Belt_Array.map(Fuzzaldrin.filter(Belt_Array.map(countedAvailableFilters.VAL, (function (param) {
                        return {
                                key: param[0],
                                count: param[1]
                              };
                      })), searchInput$1, {
                  key: "key",
                  maxResults: 500
                }), (function (item) {
                return [
                        {
                          NAME: "propertyName",
                          VAL: item.key
                        },
                        item.key,
                        item.count
                      ];
              })) : (
          variant$1 === "issueTypes" ? Belt_Array.map(countedAvailableFilters.VAL, (function (param) {
                    var item = param[0];
                    return [
                            {
                              NAME: "issueType",
                              VAL: item
                            },
                            InspectorIssuesFilter.FilterIssueType.toDisplayString(item),
                            param[1]
                          ];
                  })) : (
              variant$1 === "releases" ? Belt_Array.map(Fuzzaldrin.filter(Belt_Array.map(countedAvailableFilters.VAL, (function (param) {
                                var item = param[0];
                                return {
                                        key: Curry._1(InspectorIssuesFilter.Filter.Release.toString, item),
                                        item: item,
                                        count: param[1]
                                      };
                              })), searchInput$1, {
                          key: "key",
                          maxResults: 500
                        }), (function (item) {
                        return [
                                {
                                  NAME: "release",
                                  VAL: item.item
                                },
                                Curry._1(InspectorIssuesFilter.Filter.Release.toString, item.item),
                                item.count
                              ];
                      })) : (
                  variant$1 === "categories" ? Belt_Array.map(Fuzzaldrin.filter(Belt_Array.map(countedAvailableFilters.VAL, (function (param) {
                                    var item = param[0];
                                    return {
                                            key: InspectorIssuesFilterUtils.getCategoryNameById(item, model),
                                            item: item,
                                            count: param[1]
                                          };
                                  })), searchInput$1, {
                              key: "key",
                              maxResults: 500
                            }), (function (item) {
                            return [
                                    {
                                      NAME: "category",
                                      VAL: item.item
                                    },
                                    InspectorIssuesFilterUtils.getCategoryNameById(item.item, model),
                                    item.count
                                  ];
                          })) : (
                      variant$1 === "eventNames" ? Belt_Array.map(Fuzzaldrin.filter(Belt_Array.map(countedAvailableFilters.VAL, (function (param) {
                                        return {
                                                key: param[0],
                                                count: param[1]
                                              };
                                      })), searchInput$1, {
                                  key: "key",
                                  maxResults: 500
                                }), (function (item) {
                                return [
                                        {
                                          NAME: "eventName",
                                          VAL: item.key
                                        },
                                        item.key,
                                        item.count
                                      ];
                              })) : (
                          variant$1 === "sources" ? Belt_Array.map(Fuzzaldrin.filter(Belt_Array.map(countedAvailableFilters.VAL, (function (param) {
                                            var item = param[0];
                                            return {
                                                    key: InspectorIssuesFilterUtils.getIssueSourceNameById(item, model),
                                                    item: item,
                                                    count: param[1]
                                                  };
                                          })), searchInput$1, {
                                      key: "key",
                                      maxResults: 500
                                    }), (function (item) {
                                    return [
                                            {
                                              NAME: "source",
                                              VAL: item.item
                                            },
                                            InspectorIssuesFilterUtils.getIssueSourceNameById(item.item, model),
                                            item.count
                                          ];
                                  })) : Belt_Array.map(Fuzzaldrin.filter(Belt_Array.map(countedAvailableFilters.VAL, (function (param) {
                                            return {
                                                    key: param[0],
                                                    count: param[1]
                                                  };
                                          })), searchInput$1, {
                                      key: "key",
                                      maxResults: 500
                                    }), (function (item) {
                                    return [
                                            {
                                              NAME: "tag",
                                              VAL: item.key
                                            },
                                            item.key,
                                            item.count
                                          ];
                                  }))
                        )
                    )
                )
            )
        ), (function (param, param$1) {
          return Caml.caml_string_compare(param[1], param$1[1]);
        }));
  var hasNoValues = filteredValues.length === 0;
  var displayedValues = filterType === "propertyName" || filterType === "eventName" ? Belt_Array.concat(arbitraryFilters, filteredValues) : filteredValues;
  var mayAddArbitraryChoice = filterType === "propertyName" || filterType === "eventName";
  var values = InspectorIssuesFilterUtils.groupFilterValuesToArray(groupedFilter, model);
  var valueLength = values.join(", ").length;
  return React.createElement(Mantine.Menu.make, {
              onClose: (function (param) {
                  Curry._1(setFilterPlaceholder, (function (param) {
                          
                        }));
                  Curry._1(setSearchInput, (function (param) {
                          return "";
                        }));
                  return Curry._1(actions.close, undefined);
                }),
              opened: opened,
              position: "bottom-start",
              children: null
            }, React.createElement(Core.Menu.Target, {
                  children: React.createElement("div", {
                        className: targetBunchContainer
                      }, React.createElement("div", {
                            className: targetContainer(InspectorIssuesFilterUtils.hasFilters(filters, filterType))
                          }, React.createElement("div", {
                                className: targetContent,
                                role: "button",
                                onClick: (function (param) {
                                    return Curry._1(actions.toggle, undefined);
                                  })
                              }, React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Semi",
                                    children: InspectorIssuesFilter.GroupedFilter.typeToString(groupedFilter) + ":"
                                  }), valueLength > 40 ? React.createElement(Tooltip.make, {
                                      disabled: opened,
                                      title: "",
                                      tooltipPadding: 8,
                                      withAskUsAnything: false,
                                      withDismiss: false,
                                      withTitle: false,
                                      children: null
                                    }, React.createElement(Tooltip.Target.make, {
                                          children: React.createElement("span", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("flex"),
                                                      tl: /* [] */0
                                                    })
                                              }, React.createElement($$Text.make, {
                                                    element: "Em",
                                                    size: "Small",
                                                    lines: 1,
                                                    children: AvoUtils.plural(undefined, undefined, values.length, InspectorIssuesFilter.GroupedFilter.typeToString(groupedFilter))
                                                  }))
                                        }), React.createElement(Tooltip.Content.make, {
                                          children: React.createElement($$Text.make, {
                                                size: "Small",
                                                children: InspectorIssuesFilter.GroupedFilter.toString(groupedFilter)
                                              })
                                        })) : Belt_Array.mapWithIndex(values, (function (index, value) {
                                        return React.createElement("span", {
                                                    key: value,
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.display("flex"),
                                                          tl: /* [] */0
                                                        })
                                                  }, React.createElement($$Text.make, {
                                                        size: "Small",
                                                        children: value
                                                      }), index < (values.length - 1 | 0) ? React.createElement($$Text.make, {
                                                          size: "Small",
                                                          children: ", "
                                                        }) : null);
                                      })))), React.createElement("div", {
                            className: removeFilterContainer
                          }, React.createElement("div", {
                                className: removeFilterButton,
                                role: "button",
                                onClick: (function (param) {
                                    return Curry._1(setFilters, (function (existingFilters) {
                                                  return InspectorIssuesFilterUtils.removeFilters(existingFilters, filterType);
                                                }));
                                  })
                              }, React.createElement(Icon.make, {
                                    type_: "close",
                                    size: "small"
                                  }))))
                }), React.createElement(Mantine.Menu.Dropdown.make, {
                  additionalStyles: dropdown,
                  children: null
                }, filterCount > 15 || mayAddArbitraryChoice ? React.createElement(Mantine.Menu.Item.make, {
                        closeMenuOnClick: false,
                        children: React.createElement("div", {
                              ref: searchContainerRef,
                              className: searchContainer(match$5[0])
                            }, React.createElement("input", {
                                  className: searchInput,
                                  autoFocus: true,
                                  placeholder: "Search filter...",
                                  type: "text",
                                  value: searchInput$1,
                                  onFocus: (function (param) {
                                      var el = searchContainerRef.current;
                                      if (!(el == null) && searchInput$1 === "") {
                                        return Curry._1(setObtainedWidth, (function (param) {
                                                      return el.offsetWidth;
                                                    }));
                                      }
                                      
                                    }),
                                  onChange: (function (e) {
                                      var value = e.target.value;
                                      return Curry._1(setSearchInput, (function (param) {
                                                    return value;
                                                  }));
                                    })
                                }), React.createElement("button", {
                                  className: eraseButton(searchInput$1 === ""),
                                  disabled: searchInput$1 === "",
                                  onClick: (function (param) {
                                      return Curry._1(setSearchInput, (function (param) {
                                                    return "";
                                                  }));
                                    })
                                }, React.createElement(IconErase.make, {
                                      color: Styles.Color.light07,
                                      hoverColor: Styles.Color.red
                                    }))),
                        key: "search"
                      }) : null, hasNoValues ? React.createElement(Mantine.Menu.Item.make, {
                        closeMenuOnClick: false,
                        additionalStyles: dropdownItemContainer,
                        children: React.createElement("div", {
                              className: dropdownItem
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  children: "No results"
                                })),
                        key: "no-results"
                      }) : null, mayAddArbitraryChoice && searchInput$1.trim() !== "" ? React.createElement(Mantine.Menu.Item.make, {
                        closeMenuOnClick: false,
                        additionalStyles: dropdownItemContainer,
                        onClick: (function (param) {
                            Curry._1(setSearchInput, (function (param) {
                                    return "";
                                  }));
                            return toggleValue(filterType === "eventName" ? ({
                                            NAME: "eventName",
                                            VAL: searchInput$1
                                          }) : (
                                          filterType === "tag" ? ({
                                                NAME: "tag",
                                                VAL: searchInput$1
                                              }) : (
                                              filterType === "issueType" ? Pervasives.failwith("Cannot add arbitrary choice for issue type") : (
                                                  filterType === "propertyName" ? ({
                                                        NAME: "propertyName",
                                                        VAL: searchInput$1
                                                      }) : (
                                                      filterType === "category" ? ({
                                                            NAME: "category",
                                                            VAL: searchInput$1
                                                          }) : (
                                                          filterType === "source" ? ({
                                                                NAME: "source",
                                                                VAL: searchInput$1
                                                              }) : Pervasives.failwith("Cannot add arbitrary choice for release")
                                                        )
                                                    )
                                                )
                                            )
                                        ));
                          }),
                        children: React.createElement("div", {
                              className: dropdownItem
                            }, React.createElement("input", {
                                  checked: false,
                                  readOnly: true,
                                  type: "checkbox"
                                }), React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  children: React.createElement("i", undefined, "\"" + searchInput$1 + "\"")
                                })),
                        key: "add-arbitrary-choice"
                      }) : null, Belt_Array.map(displayedValues, (function (param) {
                        var count = param[2];
                        var label = param[1];
                        var value = param[0];
                        return React.createElement(Mantine.Menu.Item.make, {
                                    closeMenuOnClick: false,
                                    additionalStyles: dropdownItemContainer,
                                    onClick: (function (param) {
                                        return toggleValue(value);
                                      }),
                                    children: React.createElement("div", {
                                          className: dropdownItem
                                        }, React.createElement("input", {
                                              checked: Belt_Set.has(selectedValues, value),
                                              readOnly: true,
                                              type: "checkbox"
                                            }), React.createElement($$Text.make, {
                                              size: "Small",
                                              weight: "Semi",
                                              children: null
                                            }, count < 0 ? React.createElement("i", undefined, label) : label, React.createElement($$Text.make, {
                                                  element: "Span",
                                                  size: "Tiny",
                                                  weight: "Semi",
                                                  color: Styles.Color.light10,
                                                  children: count < 0 ? "" : " (" + String(count) + ")"
                                                }))),
                                    key: InspectorIssuesFilter.Filter.toValue(value)
                                  });
                      })), React.createElement(Mantine.Menu.Item.make, {
                      additionalStyles: dropdownDoneContainer,
                      onClick: (function (param) {
                          return Curry._1(actions.close, undefined);
                        }),
                      children: React.createElement("div", {
                            className: dropdownDoneButton
                          }, React.createElement(Icon.make, {
                                type_: "simpleCheckmark",
                                size: "small"
                              }), React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                children: "Done"
                              }))
                    })));
}

var targetBunchContainer$1 = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: /* [] */0
    });

function targetContainer$1(hasFilters) {
  return Curry._1(Css.style, {
              hd: Css.cursor("pointer"),
              tl: {
                hd: Css.borderRadius(Css.px(5)),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "all"),
                  tl: {
                    hd: Css.backgroundColor(hasFilters ? Styles.Color.green : Styles.Color.paleGreen),
                    tl: {
                      hd: Css.hover({
                            hd: Css.backgroundColor(Styles.Color.green),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.overflow("hidden"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var targetContent$1 = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(4), Css.px(8)),
      tl: {
        hd: Css.paddingRight(Css.px(26)),
        tl: {
          hd: Css.color(Styles.Color.white),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.gap(Css.px(4)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var removeFilterContainer$1 = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.margin(Css.px(2)),
        tl: {
          hd: Css.right(Css.px(1)),
          tl: {
            hd: Css.top(Css.px(0)),
            tl: {
              hd: Css.height({
                    NAME: "subtract",
                    VAL: [
                      Css.pct(100.0),
                      Css.px(4)
                    ]
                  }),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "all"),
                  tl: {
                    hd: Css.borderRadius(Css.pct(50.0)),
                    tl: {
                      hd: Css.cursor("pointer"),
                      tl: {
                        hd: Css.hover({
                              hd: Icon.color(Styles.Color.white),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.green),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var removeFilterButton$1 = Curry._1(Css.style, {
      hd: Css.padding(Css.px(2)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Icon.color(Styles.Color.white),
              tl: /* [] */0
            }
          }
        }
      }
    });

var dropdown_0$1 = Css.minWidth(Css.px(200));

var dropdown_1$1 = {
  hd: Css.paddingTop(Css.px(8)),
  tl: {
    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                  NAME: "num",
                  VAL: 0.15
                }))),
    tl: {
      hd: Css.transform(Css.translateY(Css.px(-6))),
      tl: {
        hd: Css.maxHeight(Css.vh(40.0)),
        tl: {
          hd: Css.overflow("auto"),
          tl: {
            hd: Css.borderColor(Styles.Color.light04),
            tl: /* [] */0
          }
        }
      }
    }
  }
};

var dropdown$1 = {
  hd: dropdown_0$1,
  tl: dropdown_1$1
};

var dropdownItemContainer_0$1 = Css.padding2(Css.px(8), Css.px(16));

var dropdownItemContainer_1$1 = {
  hd: Css.position("relative"),
  tl: {
    hd: Css.borderRadius("zero"),
    tl: /* [] */0
  }
};

var dropdownItemContainer$1 = {
  hd: dropdownItemContainer_0$1,
  tl: dropdownItemContainer_1$1
};

var dropdownItem$1 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gap(Css.px(4)),
          tl: /* [] */0
        }
      }
    });

var dropdownDoneContainer_0$1 = Css.position("sticky");

var dropdownDoneContainer_1$1 = {
  hd: Css.left(Css.px(0)),
  tl: {
    hd: Css.bottom(Css.px(0)),
    tl: {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.borderRadius("zero"),
        tl: {
          hd: Css.borderTop(Css.px(1), Css.solid, Styles.Color.light07),
          tl: {
            hd: Css.backgroundColor(Styles.Color.lightBlue),
            tl: {
              hd: Css.color(Styles.Color.darkBlue),
              tl: {
                hd: Icon.color(Styles.Color.darkBlue),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "all"),
                  tl: {
                    hd: Css.hover({
                          hd: Styles.backgroundColorGuarantee(Styles.Color.setAlpha(Styles.Color.paleBlue, 0.3)),
                          tl: {
                            hd: Css.color(Styles.Color.deepBlue),
                            tl: {
                              hd: Icon.color(Styles.Color.deepBlue),
                              tl: /* [] */0
                            }
                          }
                        }),
                    tl: {
                      hd: Css.padding(Css.px(16)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(20)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var dropdownDoneContainer$1 = {
  hd: dropdownDoneContainer_0$1,
  tl: dropdownDoneContainer_1$1
};

var dropdownDoneButton$1 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.gap(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

function searchContainer$1(obtainedWidth) {
  return Curry._1(Css.style, {
              hd: Css.marginTop(Css.px(-8)),
              tl: {
                hd: Css.padding2(Css.px(0), Css.px(16)),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.gap(Css.px(8)),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light07),
                          tl: {
                            hd: obtainedWidth !== undefined ? Css.width(Css.px(obtainedWidth)) : Css.width(Css.pct(100.0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var searchInput$1 = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100)),
      tl: {
        hd: Css.padding(Css.px(16)),
        tl: {
          hd: Css.paddingLeft(Css.px(0)),
          tl: {
            hd: Css.fontSize(Css.px(14)),
            tl: {
              hd: Css.fontWeight({
                    NAME: "num",
                    VAL: 500
                  }),
              tl: {
                hd: Css.borderWidth(Css.px(0)),
                tl: {
                  hd: Css.outlineStyle("none"),
                  tl: {
                    hd: Css.outlineWidth(Css.px(0)),
                    tl: {
                      hd: Css.color(Styles.Color.light10),
                      tl: {
                        hd: Css.placeholder({
                              hd: Css.color(Styles.Color.light08),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function eraseButton$1(disabled) {
  return Curry._1(Css.style, {
              hd: Css.padding(Css.px(0)),
              tl: {
                hd: Css.marginTop(Css.px(0)),
                tl: {
                  hd: Css.marginBottom(Css.px(0)),
                  tl: {
                    hd: Css.marginLeft(Css.px(0)),
                    tl: {
                      hd: Css.margin(Css.px(0)),
                      tl: {
                        hd: Css.borderWidth(Css.px(0)),
                        tl: {
                          hd: Css.borderRadius(Css.px(2)),
                          tl: {
                            hd: Css.background("transparent"),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "opacity"),
                              tl: {
                                hd: Css.opacity(disabled ? 0 : 1),
                                tl: {
                                  hd: Css.unsafe("appearance", "none"),
                                  tl: {
                                    hd: Css.outlineStyle("none"),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.active({
                                              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.focus({
                                                hd: Css.outlineStyle("none"),
                                                tl: {
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function InspectorIssuesFilters$FilterRadioSelect(Props) {
  var filterType = Props.filterType;
  var getCountedAvailableFilters = Props.getCountedAvailableFilters;
  var setFilterPlaceholder = Props.setFilterPlaceholder;
  var clearValueOpt = Props.clearValue;
  var model = Props.model;
  var clearValue = clearValueOpt !== undefined ? clearValueOpt : "Deselect all";
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var filters = match.filters;
  var setFilters = InspectorIssuesFilterHooks.useSetFilters(undefined);
  var match$1 = React.useState(function () {
        return Belt_Set.fromArray(InspectorIssuesFilterUtils.getFilters(filters, filterType), InspectorIssuesFilter.Filter.Comparator);
      });
  var setSelectedValues = match$1[1];
  var selectedValues = match$1[0];
  React.useEffect((function () {
          Curry._1(setSelectedValues, (function (param) {
                  return Belt_Set.fromArray(InspectorIssuesFilterUtils.getFilters(filters, filterType), InspectorIssuesFilter.Filter.Comparator);
                }));
          
        }), [filters]);
  var match$2 = React.useState(function () {
        return Belt_Map.getExn(Curry._1(getCountedAvailableFilters, filterType), filterType);
      });
  var setCountedAvailableFilters = match$2[1];
  var countedAvailableFilters = match$2[0];
  var match$3 = Hooks.useDisclosure(Belt_Set.size(selectedValues) === 0);
  var actions = match$3[1];
  var opened = match$3[0];
  React.useEffect((function () {
          if (opened) {
            Curry._1(setCountedAvailableFilters, (function (param) {
                    return Belt_Map.getExn(Curry._1(getCountedAvailableFilters, filterType), filterType);
                  }));
          }
          
        }), [opened]);
  var groupedFilter = Belt_Option.getWithDefault(Belt_Array.getBy(InspectorIssuesFilterUtils.groupFilters(filters), (function (groupedFilter) {
              return InspectorIssuesFilter.GroupedFilter.toFilterType(groupedFilter) === filterType;
            })), InspectorIssuesFilter.GroupedFilter.fromFilterType(filterType));
  var dropSelection = function (param) {
    return Curry._1(setSelectedValues, (function (existingValues) {
                  return Belt_Set.keep(existingValues, (function (filter) {
                                return InspectorIssuesFilter.Filter.toFilterType(filter) !== filterType;
                              }));
                }));
  };
  Hooks.useDidUpdate1((function (param) {
          Curry._1(setFilters, (function (existingFilters) {
                  var existingSet = Belt_Set.keep(Belt_Set.fromArray(existingFilters, InspectorIssuesFilter.Filter.Comparator), (function (filter) {
                          return InspectorIssuesFilter.Filter.toFilterType(filter) === filterType;
                        }));
                  var removeSet = Belt_Set.diff(existingSet, selectedValues);
                  var addSet = Belt_Set.diff(selectedValues, existingSet);
                  return Belt_Array.concat(Belt_Array.keep(existingFilters, (function (filter) {
                                    if (Belt_Set.has(removeSet, filter)) {
                                      return InspectorIssuesFilter.Filter.toFilterType(filter) !== filterType;
                                    } else {
                                      return true;
                                    }
                                  })), Belt_Set.toArray(addSet));
                }));
          if (Belt_Set.size(selectedValues) > 0) {
            Curry._1(setFilterPlaceholder, (function (param) {
                    
                  }));
          } else {
            Curry._1(setFilterPlaceholder, (function (param) {
                    return groupedFilter;
                  }));
          }
          
        }), [selectedValues]);
  var variant = countedAvailableFilters.NAME;
  var filterCount = variant === "eventNames" || variant === "releases" || variant === "issueTypes" || variant === "propertyNames" ? countedAvailableFilters.VAL.length : countedAvailableFilters.VAL.length;
  var searchContainerRef = React.useRef(null);
  var match$4 = React.useState(function () {
        return "";
      });
  var setSearchInput = match$4[1];
  var searchInput$2 = match$4[0];
  var match$5 = React.useState(function () {
        
      });
  var setObtainedWidth = match$5[1];
  var variant$1 = countedAvailableFilters.NAME;
  var filteredValues = variant$1 === "propertyNames" ? ({
        NAME: "propertyNames",
        VAL: Belt_Array.map(Fuzzaldrin.filter(Belt_Array.map(countedAvailableFilters.VAL, (function (param) {
                        return {
                                key: param[0],
                                count: param[1]
                              };
                      })), searchInput$2, {
                  key: "key",
                  maxResults: 500
                }), (function (item) {
                return [
                        item.key,
                        item.count
                      ];
              }))
      }) : (
      variant$1 === "issueTypes" ? ({
            NAME: "issueTypes",
            VAL: countedAvailableFilters.VAL
          }) : (
          variant$1 === "releases" ? ({
                NAME: "releases",
                VAL: Belt_Array.map(Fuzzaldrin.filter(Belt_Array.map(countedAvailableFilters.VAL, (function (param) {
                                var item = param[0];
                                return {
                                        key: Curry._1(InspectorIssuesFilter.Filter.Release.toString, item),
                                        item: item,
                                        count: param[1]
                                      };
                              })), searchInput$2, {
                          key: "key",
                          maxResults: 500
                        }), (function (item) {
                        return [
                                item.item,
                                item.count
                              ];
                      }))
              }) : (
              variant$1 === "categories" ? ({
                    NAME: "categories",
                    VAL: Belt_Array.map(Fuzzaldrin.filter(Belt_Array.map(countedAvailableFilters.VAL, (function (param) {
                                    var item = param[0];
                                    return {
                                            key: InspectorIssuesFilterUtils.getCategoryNameById(item, model),
                                            item: item,
                                            count: param[1]
                                          };
                                  })), searchInput$2, {
                              key: "key",
                              maxResults: 500
                            }), (function (item) {
                            return [
                                    item.item,
                                    item.count
                                  ];
                          }))
                  }) : (
                  variant$1 === "eventNames" ? ({
                        NAME: "eventNames",
                        VAL: Belt_Array.map(Fuzzaldrin.filter(Belt_Array.map(countedAvailableFilters.VAL, (function (param) {
                                        return {
                                                key: param[0],
                                                count: param[1]
                                              };
                                      })), searchInput$2, {
                                  key: "key",
                                  maxResults: 500
                                }), (function (item) {
                                return [
                                        item.key,
                                        item.count
                                      ];
                              }))
                      }) : (
                      variant$1 === "sources" ? ({
                            NAME: "sources",
                            VAL: Belt_Array.map(Fuzzaldrin.filter(Belt_Array.map(countedAvailableFilters.VAL, (function (param) {
                                            var item = param[0];
                                            return {
                                                    key: InspectorIssuesFilterUtils.getIssueSourceNameById(item, model),
                                                    item: item,
                                                    count: param[1]
                                                  };
                                          })), searchInput$2, {
                                      key: "key",
                                      maxResults: 500
                                    }), (function (item) {
                                    return [
                                            item.item,
                                            item.count
                                          ];
                                  }))
                          }) : ({
                            NAME: "tags",
                            VAL: Belt_Array.map(Fuzzaldrin.filter(Belt_Array.map(countedAvailableFilters.VAL, (function (param) {
                                            return {
                                                    key: param[0],
                                                    count: param[1]
                                                  };
                                          })), searchInput$2, {
                                      key: "key",
                                      maxResults: 500
                                    }), (function (item) {
                                    return [
                                            item.key,
                                            item.count
                                          ];
                                  }))
                          })
                    )
                )
            )
        )
    );
  var values = InspectorIssuesFilterUtils.groupFilterValuesToArray(groupedFilter, model);
  var valueLength = values.join(", ").length;
  var variant$2 = filteredValues.NAME;
  return React.createElement(Mantine.Menu.make, {
              onClose: (function (param) {
                  Curry._1(setFilterPlaceholder, (function (param) {
                          
                        }));
                  Curry._1(setSearchInput, (function (param) {
                          return "";
                        }));
                  return Curry._1(actions.close, undefined);
                }),
              opened: opened,
              position: "bottom-start",
              children: null
            }, React.createElement(Core.Menu.Target, {
                  children: React.createElement("div", {
                        className: targetBunchContainer$1
                      }, React.createElement("div", {
                            className: targetContainer$1(InspectorIssuesFilterUtils.hasFilters(filters, filterType))
                          }, React.createElement("div", {
                                className: targetContent$1,
                                role: "button",
                                onClick: (function (param) {
                                    return Curry._1(actions.toggle, undefined);
                                  })
                              }, React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Semi",
                                    children: InspectorIssuesFilter.GroupedFilter.typeToString(groupedFilter) + ":"
                                  }), valueLength > 40 ? React.createElement(Tooltip.make, {
                                      disabled: opened,
                                      title: "",
                                      tooltipPadding: 8,
                                      withAskUsAnything: false,
                                      withDismiss: false,
                                      withTitle: false,
                                      children: null
                                    }, React.createElement(Tooltip.Target.make, {
                                          children: React.createElement("span", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("flex"),
                                                      tl: /* [] */0
                                                    })
                                              }, React.createElement($$Text.make, {
                                                    element: "Em",
                                                    size: "Small",
                                                    lines: 1,
                                                    children: AvoUtils.plural(undefined, undefined, values.length, InspectorIssuesFilter.GroupedFilter.typeToString(groupedFilter))
                                                  }))
                                        }), React.createElement(Tooltip.Content.make, {
                                          children: React.createElement($$Text.make, {
                                                size: "Small",
                                                children: InspectorIssuesFilter.GroupedFilter.toString(groupedFilter)
                                              })
                                        })) : Belt_Array.mapWithIndex(values, (function (index, value) {
                                        return React.createElement("span", {
                                                    key: value,
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.display("flex"),
                                                          tl: /* [] */0
                                                        })
                                                  }, React.createElement($$Text.make, {
                                                        size: "Small",
                                                        children: value
                                                      }), index < (values.length - 1 | 0) ? React.createElement($$Text.make, {
                                                          size: "Small",
                                                          children: ", "
                                                        }) : null);
                                      })))), React.createElement("div", {
                            className: removeFilterContainer$1
                          }, React.createElement("div", {
                                className: removeFilterButton$1,
                                role: "button",
                                onClick: (function (param) {
                                    return Curry._1(setFilters, (function (existingFilters) {
                                                  return InspectorIssuesFilterUtils.removeFilters(existingFilters, filterType);
                                                }));
                                  })
                              }, React.createElement(Icon.make, {
                                    type_: "close",
                                    size: "small"
                                  }))))
                }), React.createElement(Mantine.Menu.Dropdown.make, {
                  additionalStyles: dropdown$1,
                  children: null
                }, filterCount > 15 ? React.createElement(Mantine.Menu.Item.make, {
                        closeMenuOnClick: false,
                        children: React.createElement("div", {
                              ref: searchContainerRef,
                              className: searchContainer$1(match$5[0])
                            }, React.createElement("input", {
                                  className: searchInput$1,
                                  autoFocus: true,
                                  placeholder: "Search filter...",
                                  type: "text",
                                  value: searchInput$2,
                                  onFocus: (function (param) {
                                      var el = searchContainerRef.current;
                                      if (!(el == null) && searchInput$2 === "") {
                                        return Curry._1(setObtainedWidth, (function (param) {
                                                      return el.offsetWidth;
                                                    }));
                                      }
                                      
                                    }),
                                  onChange: (function (e) {
                                      var value = e.target.value;
                                      return Curry._1(setSearchInput, (function (param) {
                                                    return value;
                                                  }));
                                    })
                                }), React.createElement("button", {
                                  className: eraseButton$1(searchInput$2 === ""),
                                  disabled: searchInput$2 === "",
                                  onClick: (function (param) {
                                      return Curry._1(setSearchInput, (function (param) {
                                                    return "";
                                                  }));
                                    })
                                }, React.createElement(IconErase.make, {
                                      color: Styles.Color.light07,
                                      hoverColor: Styles.Color.red
                                    }))),
                        key: "search"
                      }) : null, (
                  variant$2 === "releases" || variant$2 === "issueTypes" ? filteredValues.VAL.length : filteredValues.VAL.length
                ) === 0 ? React.createElement(Mantine.Menu.Item.make, {
                        closeMenuOnClick: false,
                        additionalStyles: dropdownItemContainer$1,
                        children: React.createElement("div", {
                              className: dropdownItem$1
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  children: "No results"
                                })),
                        key: "no-results"
                      }) : React.createElement(Mantine.Menu.Item.make, {
                        closeMenuOnClick: false,
                        additionalStyles: dropdownItemContainer$1,
                        onClick: dropSelection,
                        children: React.createElement("div", {
                              className: dropdownItem$1
                            }, React.createElement("input", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.marginTop(Css.px(0)),
                                        tl: /* [] */0
                                      }),
                                  checked: Belt_Set.every(selectedValues, (function (filter) {
                                          return InspectorIssuesFilter.Filter.toFilterType(filter) !== filterType;
                                        })),
                                  readOnly: true,
                                  type: "radio"
                                }), React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  children: clearValue
                                }))
                      }), filteredValues.NAME === "releases" ? Belt_Array.mapU(filteredValues.VAL, (function (param) {
                          var release = param[0];
                          return React.createElement(Mantine.Menu.Item.make, {
                                      closeMenuOnClick: false,
                                      additionalStyles: dropdownItemContainer$1,
                                      onClick: (function (param) {
                                          var value = {
                                            NAME: "release",
                                            VAL: release
                                          };
                                          return Curry._1(setSelectedValues, (function (existingValues) {
                                                        var noFiltersOfFilterType = Belt_Set.keep(existingValues, (function (filter) {
                                                                return InspectorIssuesFilter.Filter.toFilterType(filter) !== filterType;
                                                              }));
                                                        return Belt_Set.add(noFiltersOfFilterType, value);
                                                      }));
                                        }),
                                      children: React.createElement("div", {
                                            className: dropdownItem$1
                                          }, React.createElement("input", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.marginTop(Css.px(0)),
                                                      tl: /* [] */0
                                                    }),
                                                checked: Belt_Set.has(selectedValues, {
                                                      NAME: "release",
                                                      VAL: release
                                                    }),
                                                readOnly: true,
                                                type: "radio"
                                              }), React.createElement($$Text.make, {
                                                size: "Small",
                                                weight: "Semi",
                                                children: null
                                              }, Curry._1(InspectorIssuesFilter.Filter.Release.toDisplayString, release), React.createElement($$Text.make, {
                                                    element: "Span",
                                                    size: "Tiny",
                                                    weight: "Semi",
                                                    color: Styles.Color.light10,
                                                    children: " (" + String(param[1]) + ")"
                                                  }))),
                                      key: Curry._1(InspectorIssuesFilter.Filter.Release.toString, release)
                                    });
                        })) : [], React.createElement(Mantine.Menu.Item.make, {
                      additionalStyles: dropdownDoneContainer$1,
                      onClick: (function (param) {
                          return Curry._1(actions.close, undefined);
                        }),
                      children: React.createElement("div", {
                            className: dropdownDoneButton$1
                          }, React.createElement(Icon.make, {
                                type_: "simpleCheckmark",
                                size: "small"
                              }), React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                children: "Done"
                              }))
                    })));
}

function InspectorIssuesFilters$QuickFilter(Props) {
  var filter = Props.filter;
  var model = Props.model;
  var setFilters = InspectorIssuesFilterHooks.useSetFilters(undefined);
  return React.createElement("button", {
              key: InspectorIssuesFilterUtils.filterToString(filter, model),
              className: Curry._1(Css.merge, {
                    hd: Curry._1(Css.style, Styles.buttonReset),
                    tl: {
                      hd: Curry._1(Css.style, {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: Styles.Duration.$$short
                                }, undefined, undefined, "all"),
                            tl: {
                              hd: Css.padding2(Css.px(4), Css.px(8)),
                              tl: {
                                hd: Css.borderRadius(Css.px(4)),
                                tl: {
                                  hd: Css.border(Css.px(1), Css.solid, Styles.Color.light04),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.borderColor(Styles.Color.light07),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }),
                      tl: /* [] */0
                    }
                  }),
              onClick: (function (param) {
                  return Curry._1(setFilters, (function (existingFilters) {
                                return InspectorIssuesFilterUtils.toggleFilters(existingFilters, filter);
                              }));
                })
            }, InspectorIssuesFilterUtils.filterToValue(filter, model));
}

function InspectorIssuesFilters$Filter(Props) {
  var getCountedAvailableFilters = Props.getCountedAvailableFilters;
  var groupedFilter = Props.groupedFilter;
  var setFilterPlaceholder = Props.setFilterPlaceholder;
  var model = Props.model;
  var match = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var savedView = match.savedView;
  var filterType = InspectorIssuesFilter.GroupedFilter.toFilterType(groupedFilter);
  if (filterType === "source" || filterType === "category" || filterType === "propertyName" || filterType === "issueType" || filterType === "tag" || filterType === "eventName") {
    return React.createElement(InspectorIssuesFilters$FilterMultiSelect, {
                filterType: filterType,
                getCountedAvailableFilters: getCountedAvailableFilters,
                setFilterPlaceholder: setFilterPlaceholder,
                model: model,
                key: Belt_Option.mapWithDefault(savedView, "", (function (param) {
                        return param.id;
                      })) + InspectorIssuesFilter.FilterType.toString(filterType)
              });
  } else {
    return React.createElement(InspectorIssuesFilters$FilterRadioSelect, {
                filterType: filterType,
                getCountedAvailableFilters: getCountedAvailableFilters,
                setFilterPlaceholder: setFilterPlaceholder,
                clearValue: "(All)",
                model: model,
                key: Belt_Option.mapWithDefault(savedView, "", (function (param) {
                        return param.id;
                      })) + InspectorIssuesFilter.FilterType.toString(filterType)
              });
  }
}

var dropdownContainer_0 = Css.minWidth(Css.px(200));

var dropdownContainer_1 = {
  hd: Css.paddingTop(Css.px(8)),
  tl: {
    hd: Css.paddingBottom(Css.px(8)),
    tl: {
      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                    NAME: "num",
                    VAL: 0.15
                  }))),
      tl: {
        hd: Css.transform(Css.translateY(Css.px(-6))),
        tl: {
          hd: Css.borderColor(Styles.Color.light04),
          tl: /* [] */0
        }
      }
    }
  }
};

var dropdownContainer = {
  hd: dropdownContainer_0,
  tl: dropdownContainer_1
};

function InspectorIssuesFilters$AddFilterButton(Props) {
  var availableFilters = Props.availableFilters;
  var setFilterPlaceholder = Props.setFilterPlaceholder;
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var filters = match.filters;
  return React.createElement(Mantine.Menu.make, {
              position: "bottom-start",
              withinPortal: true,
              children: null
            }, React.createElement(Core.Menu.Target, {
                  children: React.createElement("div", undefined, React.createElement(Button.make, {
                            icon: "filter",
                            id: "add-filter-for-issues",
                            label: "Add filter",
                            size: "tiny",
                            style: "ghost"
                          }))
                }), React.createElement(Mantine.Menu.Dropdown.make, {
                  additionalStyles: dropdownContainer,
                  children: Belt_Array.map(InspectorIssuesFilter.FilterType.availableFilterTypes, (function (filterType) {
                          var isUnavailable = Belt_Option.isNone(Belt_Map.get(availableFilters, filterType));
                          var isApplied = InspectorIssuesFilterUtils.hasFilters(filters, filterType);
                          var isQueryFilter = filterType === "eventName" || filterType === "propertyName";
                          var disabled = isUnavailable && !isQueryFilter || isApplied;
                          return React.createElement(Mantine.Menu.Item.make, {
                                      additionalStyles: {
                                        hd: Css.padding2(Css.px(8), Css.px(16)),
                                        tl: /* [] */0
                                      },
                                      onClick: (function (param) {
                                          return Curry._1(setFilterPlaceholder, (function (param) {
                                                        return InspectorIssuesFilter.GroupedFilter.fromFilterType(filterType);
                                                      }));
                                        }),
                                      disabled: disabled,
                                      children: React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            children: InspectorIssuesFilter.FilterType.toString(filterType)
                                          }),
                                      key: InspectorIssuesFilter.FilterType.toString(filterType)
                                    });
                        }))
                }));
}

var root = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.padding(Css.px(16)),
        tl: {
          hd: Css.margin(Css.px(24)),
          tl: {
            hd: Css.marginTop(Css.px(0)),
            tl: {
              hd: Css.marginBottom(Css.px(0)),
              tl: {
                hd: Css.borderRadius(Css.px(5)),
                tl: {
                  hd: Css.border(Css.px(1), Css.solid, Styles.Color.light04),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function InspectorIssuesFilters(Props) {
  var countedAvailableFilters = Props.countedAvailableFilters;
  var getCountedAvailableFilters = Props.getCountedAvailableFilters;
  var inspectorDisplayedIssueCount = Props.inspectorDisplayedIssueCount;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var filters = match.filters;
  var match$1 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var savedViewStatus = match$1.savedViewStatus;
  var orderedBy = InspectorIssuesFilterHooks.useOrderedBy(undefined);
  var model = ModelStore.useModel(undefined);
  var match$2 = RescriptReactRouter.useUrl(undefined, undefined);
  var queryString = match$2.search;
  var match$3 = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var status = match$3[0];
  var mostCommonSource = Belt_Option.flatMap(Belt_Map.get(countedAvailableFilters, "source"), (function (item) {
          if (typeof item === "object" && item.NAME === "sources") {
            return Belt_Option.map(Belt_Array.get(item.VAL.slice(0).sort(function (b, a) {
                                return Caml_obj.caml_compare(a[1], b[1]);
                              }), 0), (function (param) {
                          return {
                                  NAME: "source",
                                  VAL: param[0]
                                };
                        }));
          }
          
        }));
  var mostCommonFilterIssueTypes = Belt_Option.mapWithDefault(Belt_Map.get(countedAvailableFilters, "issueType"), [], (function (item) {
          if (typeof item === "object") {
            if (item.NAME === "issueTypes") {
              return Belt_Array.slice(Belt_Array.map(item.VAL.slice(0).sort(function (a, b) {
                                  return Caml_obj.caml_compare(b[1], a[1]);
                                }), (function (param) {
                                return {
                                        NAME: "issueType",
                                        VAL: param[0]
                                      };
                              })), 0, 1);
            } else {
              return [];
            }
          } else {
            return [];
          }
        }));
  var quickFilters = Belt_Array.concatMany([
        [{
            NAME: "release",
            VAL: "latestRelease"
          }],
        Belt_Option.mapWithDefault(mostCommonSource, [], (function (sourceFilter) {
                return [sourceFilter];
              })),
        mostCommonFilterIssueTypes
      ]);
  var previousFilters = Hooks.usePrevious1(filters);
  var previousOrderedBy = Hooks.usePrevious1(orderedBy);
  var onFilterChanged = React.useCallback((function (filter) {
          return function (inspectorIssuesConfigureAction) {
            var match = InspectorIssuesFilter.Filter.toFilterType(filter);
            return AnalyticsRe.inspectorIssuesViewConfigured(schemaBundle, InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), inspectorDisplayedIssueCount, InspectorIssuesFilterUtils.savedViewStatusToViewTypeAnalytics(savedViewStatus), InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), inspectorIssuesConfigureAction, match === "eventName" ? "EventName" : (
                          match === "tag" ? "Tag" : (
                              match === "issueType" ? "IssueType" : (
                                  match === "propertyName" ? "PropertyName" : (
                                      match === "category" ? "Category" : (
                                          match === "source" ? "Source" : "Release"
                                        )
                                    )
                                )
                            )
                        ), schemaBundle.schemaId);
          };
        }), [
        schemaBundle,
        savedViewStatus,
        filters,
        orderedBy,
        inspectorDisplayedIssueCount
      ]);
  var onOrderChanged = React.useCallback((function (orderedBy) {
          return AnalyticsRe.inspectorIssuesViewConfigured(schemaBundle, InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), inspectorDisplayedIssueCount, InspectorIssuesFilterUtils.savedViewStatusToViewTypeAnalytics(savedViewStatus), InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), "OrderView", InspectorIssuesFilterUtils.orderedByToConfigureItemAnalytics(orderedBy), schemaBundle.schemaId);
        }), [
        schemaBundle,
        savedViewStatus,
        filters,
        inspectorDisplayedIssueCount,
        status
      ]);
  Hooks.useDidUpdate1((function (param) {
          var previousFiltersSet = Belt_Set.fromArray(previousFilters, InspectorIssuesFilter.Filter.Comparator);
          var currentFiltersSet = Belt_Set.fromArray(filters, InspectorIssuesFilter.Filter.Comparator);
          var removeSet = Belt_Set.diff(previousFiltersSet, currentFiltersSet);
          var addSet = Belt_Set.diff(currentFiltersSet, previousFiltersSet);
          Belt_Set.forEach(removeSet, (function (removedFilter) {
                  return Curry._2(onFilterChanged, removedFilter, "RemoveFilter");
                }));
          Belt_Set.forEach(addSet, (function (addedFilter) {
                  return Curry._2(onFilterChanged, addedFilter, "FilterView");
                }));
          if (Caml_obj.caml_notequal(previousOrderedBy, orderedBy)) {
            Curry._1(onOrderChanged, orderedBy);
          }
          
        }), [queryString]);
  var match$4 = React.useState(function () {
        
      });
  var setFilterPlaceholder = match$4[1];
  return React.createElement("div", {
              className: root
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.flexWrap("wrap"),
                            tl: {
                              hd: Css.gap(Css.px(4)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Quick filters:"
                    }), Belt_Array.map(quickFilters, (function (filter) {
                        return React.createElement(InspectorIssuesFilters$QuickFilter, {
                                    filter: filter,
                                    model: model,
                                    key: InspectorIssuesFilterUtils.filterToString(filter, model)
                                  });
                      }))), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.gap(Css.px(4)),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.flexWrap("wrap"),
                              tl: {
                                hd: Css.width("maxContent"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }),
                  id: "issues-filters-group"
                }, Belt_Array.map(Belt_Array.concat(InspectorIssuesFilterUtils.groupFilters(filters), Belt_Option.mapWithDefault(match$4[0], [], (function (groupedFilter) {
                                return [groupedFilter];
                              }))), (function (groupedFilter) {
                        return React.createElement(InspectorIssuesFilters$Filter, {
                                    getCountedAvailableFilters: getCountedAvailableFilters,
                                    groupedFilter: groupedFilter,
                                    setFilterPlaceholder: setFilterPlaceholder,
                                    model: model,
                                    key: InspectorIssuesFilter.GroupedFilter.typeToString(groupedFilter)
                                  });
                      })), React.createElement(InspectorIssuesFilters$AddFilterButton, {
                      availableFilters: countedAvailableFilters,
                      setFilterPlaceholder: setFilterPlaceholder
                    }), React.createElement(Button.make, {
                      disabled: InspectorIssuesFilterHooks.getFilterQueryString(queryString) === "",
                      label: Belt_Option.isSome(match$1.savedView) ? "Reset saved view" : "Reset filters",
                      onClick: (function (param) {
                          return InspectorIssuesFilterHooks.resetFilters(undefined);
                        }),
                      size: "tiny",
                      style: "ghost"
                    })));
}

var make = InspectorIssuesFilters;

export {
  make ,
  
}
/* targetBunchContainer Not a pure module */
