// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Sentry from "./externals/Sentry.mjs";
import * as ActionDiff from "../../shared/ActionDiff.mjs";

function useDiff(fromModel, toModel) {
  return React.useMemo((function () {
                return ActionDiff.diff((function (error) {
                              return Sentry.captureExceptionString("Illegal state in ActionDiff", {
                                          errorMessage: error,
                                          origin: "ActionDiffMemo.res"
                                        });
                            }), fromModel, toModel, undefined);
              }), [
              fromModel,
              toModel
            ]);
}

export {
  useDiff ,
  
}
/* react Not a pure module */
