// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Dopt from "./externals/Dopt.mjs";
import * as Icon from "./Icon.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Asset from "./Asset.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Title from "./Title.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Issues from "../../shared/models/Issues.mjs";
import * as $$Number from "./Number.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DateFns from "./DateFns.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Redirect from "./Redirect.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Monospace from "./Monospace.mjs";
import * as StringExt from "./StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Collapsible from "./Collapsible.mjs";
import * as CommentInput from "./CommentInput.mjs";
import * as DrawerHeader from "./detailviews/DrawerHeader.mjs";
import * as PropertyType from "./PropertyType.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as FramerMotion from "framer-motion";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as AppVersionsTypes from "../../shared/models/AppVersionsTypes.mjs";
import * as InspectorViewApi from "./inspector/InspectorViewApi.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as IssueStatusSelect from "./IssueStatusSelect.mjs";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as ObjectActivityLog from "./ObjectActivityLog.mjs";
import * as ProductTour__Root from "./productTour/ProductTour__Root.mjs";
import * as ProductTour__Step from "./productTour/ProductTour__Step.mjs";
import * as InspectorIssueType from "../../shared/models/InspectorIssueType.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as InspectorIssuesStore from "./inspector/InspectorIssuesStore.mjs";
import * as InspectorIssuesHelpers from "./inspector/InspectorIssuesHelpers.mjs";
import * as ImportReview__V2__Utils from "./import/ImportReview__V2__Utils.mjs";
import * as InspectorIssueViewModel from "./inspector/InspectorIssueViewModel.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as ProductTour__GenericStep from "./productTour/ProductTour__GenericStep.mjs";
import * as InspectorIssuesFilterHooks from "./inspector/InspectorIssuesFilterHooks.mjs";
import * as InspectorIssuesFilterUtils from "../../shared/utils/InspectorIssuesFilterUtils.mjs";
import * as PropertyAbsenceViewHelpers from "./PropertyAbsenceViewHelpers.mjs";
import * as InspectorIssuesOnboardingFlow from "./InspectorIssuesOnboardingFlow.mjs";

function getSimplestAbsence(maybeProperty, maybeEvent, modelSources) {
  return PropertyAbsenceViewHelpers.getSimplestModeForStateMatrix(maybeProperty !== undefined && maybeEvent !== undefined ? PropertyAbsenceViewHelpers.getFullStateMatrix({
                    hd: maybeEvent,
                    tl: /* [] */0
                  }, modelSources, maybeProperty.absence) : undefined);
}

function getSharedIssueSourceIds(sharedIssues) {
  return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.map(sharedIssues, (function (sharedIssue) {
                        return sharedIssue.sourceId;
                      }))));
}

function useLatestIssueStatusFromActivityLog(limitOpt, issueId) {
  var limit = limitOpt !== undefined ? limitOpt : 1;
  var match = SchemaBundleContext.use(undefined);
  var match$1 = FirebaseFetcherHooks.useActionWithContentsQueryMatch(match.schemaId, limit, "UpdateInspectorIssueStatus.issueId", issueId);
  if (typeof match$1 === "object" && match$1.NAME === "Loaded") {
    return Belt_List.keepMapU(match$1.VAL, (function (action) {
                  var match = action.contents;
                  if (typeof match !== "object") {
                    return ;
                  }
                  if (match.NAME !== "UpdateInspectorIssueStatus") {
                    return ;
                  }
                  var match$1 = match.VAL;
                  if (issueId === match$1.issueId) {
                    return match$1.issueStatus;
                  }
                  
                }));
  } else {
    return /* [] */0;
  }
}

function InspectorIssueDetailsV2$LilSpyRegression(Props) {
  var issue = Props.issue;
  var latestIssueStatuses = useLatestIssueStatusFromActivityLog(5, issue.id);
  var resolvedRelease = Belt_Option.mapWithDefault(Belt_List.getBy(latestIssueStatuses, (function (param) {
              var tmp = param.status;
              if (typeof tmp === "number" || tmp.TAG === /* Ignored */0) {
                return false;
              } else {
                return true;
              }
            })), "", (function (param) {
          var status = param.status;
          if (typeof status === "number") {
            return "";
          }
          if (status.TAG === /* Ignored */0) {
            return "";
          }
          var release = status._0;
          if (typeof release === "number") {
            return "";
          }
          switch (release.TAG | 0) {
            case /* NextAppVersion */1 :
                return "after release " + release._0;
            case /* CurrentAppVersion */0 :
            case /* CustomAppVersion */2 :
                return "in release " + release._0;
            case /* Date */3 :
                return "";
            
          }
        }));
  return React.createElement(SimpleTooltip.make, {
              maxWidth: 220,
              position: "bottom",
              tooltip: "This issue was marked as Resolved " + resolvedRelease + " but has since regressed",
              children: React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.width(Css.px(24)),
                            tl: {
                              hd: Css.height(Css.px(24)),
                              tl: {
                                hd: Css.borderRadius(Css.px(12)),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.justifyContent("center"),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.lightRed),
                                      tl: {
                                        hd: Css.transition({
                                              NAME: "ms",
                                              VAL: 100.0
                                            }, undefined, undefined, "all"),
                                        tl: {
                                          hd: Icon.styles({
                                                hd: Css_Legacy_Core.SVG.fill(Styles.Color.red),
                                                tl: {
                                                  hd: Css.transition({
                                                        NAME: "ms",
                                                        VAL: 100.0
                                                      }, undefined, undefined, "all"),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.backgroundColor(Styles.Color.paleRed),
                                                  tl: {
                                                    hd: Icon.color(Styles.Color.white),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(Icon.make, {
                        type_: "inspector"
                      }))
            });
}

var LilSpyRegression = {
  useLatestIssueStatusFromActivityLog: useLatestIssueStatusFromActivityLog,
  make: InspectorIssueDetailsV2$LilSpyRegression
};

function InspectorIssueDetailsV2$LilSpyRegressionWarning(Props) {
  return React.createElement(SimpleTooltip.make, {
              maxWidth: 240,
              position: "bottom",
              tooltip: "Inspector will monitor this issue and resurface if it comes up again unexpectedly.",
              children: React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.width(Css.px(24)),
                            tl: {
                              hd: Css.height(Css.px(24)),
                              tl: {
                                hd: Css.borderRadius(Css.px(12)),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.justifyContent("center"),
                                    tl: {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: 100.0
                                          }, undefined, undefined, "all"),
                                      tl: {
                                        hd: Icon.styles({
                                              hd: Css_Legacy_Core.SVG.fill(Styles.Color.light10),
                                              tl: {
                                                hd: Css.transition({
                                                      NAME: "ms",
                                                      VAL: 100.0
                                                    }, undefined, undefined, "all"),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.backgroundColor(Styles.Color.light04),
                                                tl: {
                                                  hd: Icon.color(Styles.Color.light11),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(Icon.make, {
                        type_: "inspector"
                      }))
            });
}

var LilSpyRegressionWarning = {
  make: InspectorIssueDetailsV2$LilSpyRegressionWarning
};

function InspectorIssueDetailsV2$Section(Props) {
  var id = Props.id;
  var label = Props.label;
  var labelTopOffsetOpt = Props.labelTopOffset;
  var children = Props.children;
  var labelTopOffset = labelTopOffsetOpt !== undefined ? labelTopOffsetOpt : 0;
  var tmp = {
    className: Curry._1(Css.style, {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("row"),
            tl: {
              hd: Css.marginBottom(Css.px(16)),
              tl: /* [] */0
            }
          }
        })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("div", tmp, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.px(84)),
                        tl: {
                          hd: Css.marginRight(Css.px(8)),
                          tl: {
                            hd: Css.marginTop(Css.px(labelTopOffset)),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light10,
                      children: label
                    })), React.createElement("div", undefined, children));
}

var Section = {
  make: InspectorIssueDetailsV2$Section
};

var chevronContainer = Curry._1(Css.style, {
      hd: Css.hover({
            hd: Css.cursor("pointer"),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

function InspectorIssueDetailsV2$DescriptionContainer(Props) {
  var children = Props.children;
  var linesToShowOpt = Props.linesToShow;
  var linesToShow = linesToShowOpt !== undefined ? linesToShowOpt : 3;
  var ref = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setDisplayShowMore = match[1];
  var match$1 = Hooks.useDisclosure(false);
  var actions = match$1[1];
  var opened = match$1[0];
  React.useEffect(function () {
        var el = Belt_Option.flatMap(Caml_option.nullable_to_opt(ref.current), (function (el) {
                return Caml_option.nullable_to_opt(el.firstChild);
              }));
        if (el !== undefined) {
          var el$1 = Caml_option.valFromOption(el);
          if (el$1.clientHeight < el$1.scrollHeight) {
            Curry._1(setDisplayShowMore, (function (param) {
                    return true;
                  }));
          }
          
        }
        
      });
  var tmp = {
    size: "Small",
    color: Styles.Color.light12,
    children: children
  };
  var tmp$1 = opened ? undefined : linesToShow;
  if (tmp$1 !== undefined) {
    tmp.lines = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  ref: ref
                }, React.createElement($$Text.make, tmp)), match[0] ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.marginTop(Css.px(8)),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement(TextButton.make, {
                        onClick: (function (param) {
                            return Curry._1(actions.toggle, undefined);
                          }),
                        style: "Grey",
                        children: null
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            children: opened ? "Show less" : "Show full description"
                          }), React.createElement(Spacer.make, {
                            width: 4
                          }), React.createElement("div", {
                            className: chevronContainer
                          }, React.createElement(Icon.make, {
                                type_: opened ? "chevronUp" : "chevronDown",
                                size: "small",
                                color: Styles.Color.light09
                              })))) : null);
}

var DescriptionContainer = {
  chevronContainer: chevronContainer,
  make: InspectorIssueDetailsV2$DescriptionContainer
};

var dataTable = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: /* [] */0
    });

function dataTableHeader(cols) {
  return Curry._1(Css.style, {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.gridTemplateColumns({
                      hd: {
                        NAME: "repeat",
                        VAL: [
                          {
                            NAME: "num",
                            VAL: cols
                          },
                          {
                            NAME: "minmax",
                            VAL: [
                              "zero",
                              Css.fr(1.0)
                            ]
                          }
                        ]
                      },
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.height(Css.px(38)),
                  tl: /* [] */0
                }
              }
            });
}

function dataTableRow(cols) {
  return Curry._1(Css.style, {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.gridTemplateColumns({
                      hd: {
                        NAME: "repeat",
                        VAL: [
                          {
                            NAME: "num",
                            VAL: cols
                          },
                          {
                            NAME: "minmax",
                            VAL: [
                              "zero",
                              Css.fr(1.0)
                            ]
                          }
                        ]
                      },
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.height(Css.px(38)),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.marginLeft(Css.px(8)),
                      tl: {
                        hd: Css.marginRight(Css.px(8)),
                        tl: {
                          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                          tl: {
                            hd: Css.selector(":last-child", {
                                  hd: Css.borderBottomWidth(Css.px(0)),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.selector(" > div", {
                                    hd: Css.overflow("hidden"),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var dataTableRowCurrent = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.light02),
      tl: {
        hd: Css.borderRadius(Css.px(100)),
        tl: {
          hd: Css.paddingLeft(Css.px(8)),
          tl: {
            hd: Css.paddingRight(Css.px(8)),
            tl: {
              hd: Css.margin(Css.px(0)),
              tl: {
                hd: Css.borderBottomWidth(Css.px(0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function percentageBar(total, fraction) {
  var pctWidth = total === 0 ? 0 : Caml_int32.div(Math.imul(fraction, 60), total);
  return Curry._1(Css.style, {
              hd: Css.height(Css.px(8)),
              tl: {
                hd: Css.width(Css.px(60)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.light04),
                  tl: {
                    hd: Css.borderRadius(Css.px(4)),
                    tl: {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.after({
                              hd: Css.position("absolute"),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.top(Css.px(0)),
                                  tl: {
                                    hd: Css.height(Css.px(8)),
                                    tl: {
                                      hd: Css.minWidth(Css.px(fraction === 0 ? 0 : 3)),
                                      tl: {
                                        hd: Css.maxWidth(Css.px(fraction === total ? 60 : 57)),
                                        tl: {
                                          hd: Css.width(Css.px(pctWidth)),
                                          tl: {
                                            hd: Css.unsafe("content", "''"),
                                            tl: {
                                              hd: Css.display("block"),
                                              tl: {
                                                hd: Css.borderRadius(Css.px(4)),
                                                tl: {
                                                  hd: Css.backgroundColor(Styles.Color.orange),
                                                  tl: {
                                                    hd: pctWidth < 60 ? Css.borderTopRightRadius("zero") : Styles.emptyStyle,
                                                    tl: {
                                                      hd: pctWidth < 60 ? Css.borderBottomRightRadius("zero") : Styles.emptyStyle,
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function pill(impacted) {
  return Curry._1(Css.style, {
              hd: Css.borderRadius(Css.px(100)),
              tl: {
                hd: Css.border(Css.px(1), "solid", impacted ? Styles.Color.red : Styles.Color.light07),
                tl: {
                  hd: Css.padding2(Css.px(4), Css.px(8)),
                  tl: {
                    hd: Css.backgroundColor(impacted ? Styles.Color.lightRed : Styles.Color.white),
                    tl: {
                      hd: Css.display("inlineFlex"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var clickablePill = Curry._1(Css.merge, {
      hd: pill(false),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.color(Styles.Color.light11),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "all"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.color(Styles.Color.light12),
                              tl: {
                                hd: Css.borderColor(Styles.Color.light09),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var tableButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: $$Text.sizeStyles("Tiny"),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.color(Styles.Color.deepBlue),
                tl: {
                  hd: Css.padding2(Css.px(4), Css.px(8)),
                  tl: {
                    hd: Css.borderRadius(Css.px(100)),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "all"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.color(Styles.Color.white),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.darkBlue),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var DataTableStyles = {
  dataTable: dataTable,
  dataTableHeader: dataTableHeader,
  dataTableRow: dataTableRow,
  dataTableRowCurrent: dataTableRowCurrent,
  percentageBar: percentageBar,
  pill: pill,
  clickablePill: clickablePill,
  tableButton: tableButton
};

var collapser = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.marginTop(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

var chevronContainer$1 = Curry._1(Css.style, {
      hd: Css.hover({
            hd: Css.cursor("pointer"),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var Style = {
  collapser: collapser,
  chevronContainer: chevronContainer$1
};

function InspectorIssueDetailsV2$Releases(Props) {
  var issue = Props.issue;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = InspectorIssuesStore.useAppVersionsInSyncWithIssues(undefined, undefined, undefined);
  var appVersions = match[0];
  var match$1 = Hooks.useDisclosure(issue.source.versions.length <= 3);
  var openedActions = match$1[1];
  var opened = match$1[0];
  React.useEffect((function () {
          if (issue.source.versions.length <= 3) {
            Curry._1(openedActions.open_, undefined);
          } else {
            Curry._1(openedActions.close, undefined);
          }
          
        }), [issue]);
  var match$2 = issue.source.versions;
  var match$3 = issue.source.impactedVersions;
  var tmp;
  var exit = 0;
  var len = match$2.length;
  if (len !== 1) {
    if (len !== 0) {
      exit = 1;
    } else {
      tmp = "not been seen in any release";
    }
  } else if (match$3.length !== 1) {
    exit = 1;
  } else {
    tmp = React.createElement(React.Fragment, undefined, "impacted ", React.createElement($$Text.make, {
              element: "Span",
              weight: "Semi",
              children: "1 release"
            }), " where this event was seen");
  }
  if (exit === 1) {
    if (match$3.length !== 0) {
      var impactedReleasesCount = match$3.length;
      tmp = impactedReleasesCount === match$2.length ? React.createElement(React.Fragment, undefined, "impacted ", React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: "all releases"
                }), " where this event was seen") : React.createElement(React.Fragment, undefined, "impacted ", React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: String(impactedReleasesCount) + " out of " + AvoUtils.plural(undefined, undefined, match$2.length, "release")
                }), " where this event was seen");
    } else {
      tmp = "not impacted any release where this event was seen";
    }
  }
  var match$4 = issue.source.versions;
  return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: "RELEASES"
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(2)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light04),
                            tl: {
                              hd: Css.marginBottom(Css.px(16)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  color: Styles.Color.light11,
                  children: null
                }, "In the past 24 hours, this issue has ", tmp), match$4.length !== 0 ? React.createElement(Collapsible.make, {
                    collapsed: !opened,
                    collapsedHeight: 189,
                    onChange: (function (param) {
                        return AnalyticsRe.collapsibleItemInteracted(schemaBundle, opened ? "Expanded" : "Collapsed", "Releases", "InspectorIssue");
                      }),
                    withFade: false,
                    children: React.createElement("div", {
                          className: dataTable
                        }, React.createElement("div", {
                              className: dataTableHeader(4)
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.justifyContent("flexStart"),
                                          tl: {
                                            hd: Css.alignItems("flexEnd"),
                                            tl: {
                                              hd: Css.marginBottom(Css.px(6)),
                                              tl: {
                                                hd: Css.paddingLeft(Css.px(16)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Tiny",
                                      weight: "Semi",
                                      color: Styles.Color.light10,
                                      children: "Release"
                                    })), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.justifyContent("center"),
                                          tl: {
                                            hd: Css.alignItems("flexEnd"),
                                            tl: {
                                              hd: Css.marginBottom(Css.px(6)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Tiny",
                                      weight: "Semi",
                                      color: Styles.Color.light10,
                                      children: "Event volume"
                                    })), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.justifyContent("center"),
                                          tl: {
                                            hd: Css.alignItems("flexEnd"),
                                            tl: {
                                              hd: Css.marginBottom(Css.px(6)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Tiny",
                                      weight: "Semi",
                                      color: Styles.Color.light10,
                                      children: "Issue volume"
                                    })), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.justifyContent("flexEnd"),
                                          tl: {
                                            hd: Css.alignItems("flexEnd"),
                                            tl: {
                                              hd: Css.marginBottom(Css.px(6)),
                                              tl: {
                                                hd: Css.paddingRight(Css.px(52)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Tiny",
                                      weight: "Semi",
                                      color: Styles.Color.light10,
                                      children: "Issue %"
                                    }))), issue.source.versions.length > 1 ? React.createElement("div", {
                                className: Curry._1(Css.merge, {
                                      hd: dataTableRow(4),
                                      tl: {
                                        hd: dataTableRowCurrent,
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.marginLeft(Css.px(8)),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Small",
                                        weight: "Semi",
                                        color: Styles.Color.light11,
                                        children: "All (" + String(issue.source.versions.length) + ")"
                                      })), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.justifyContent("center"),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Small",
                                        weight: "Semi",
                                        color: Styles.Color.light11,
                                        children: $$Number.toLocaleString(issue.eventCount)
                                      })), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.justifyContent("center"),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Small",
                                        weight: "Semi",
                                        color: Styles.Color.light11,
                                        children: $$Number.toLocaleString(issue.issueCount)
                                      })), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.justifyContent("flexEnd"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.paddingRight(Css.px(12)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Small",
                                        weight: "Semi",
                                        color: Styles.Color.light11,
                                        children: InspectorIssueViewModel.Utils.getDisplayPercentageString(issue.eventCount, issue.issueCount) + "%"
                                      }), React.createElement(Spacer.make, {
                                        width: 4
                                      }), React.createElement("div", {
                                        className: percentageBar(issue.eventCount, issue.issueCount)
                                      }))) : null, Belt_Array.mapU(issue.source.versions, (function (version) {
                                var isLatest = Caml_obj.caml_equal(Belt_Option.map(AppVersionsTypes.getLatestVersion(undefined, appVersions, issue.source.id), AppVersionsTypes.print), version.release);
                                var isImpacted = version.issueCount > 0;
                                var match = Belt_Option.flatMapU(Belt_MapString.get(appVersions, issue.source.id), (function (appVersions) {
                                        return Belt_Array.getByU(appVersions, (function (param) {
                                                      return AppVersionsTypes.print(param.appVersion) === version.release;
                                                    }));
                                      }));
                                return React.createElement("div", {
                                            key: version.release,
                                            className: Curry._1(Css.merge, {
                                                  hd: dataTableRow(4),
                                                  tl: {
                                                    hd: issue.source.versions.length === 1 ? dataTableRowCurrent : "",
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.alignItems("center"),
                                                        tl: {
                                                          hd: Icon.styles({
                                                                hd: Css_Legacy_Core.SVG.fill(Styles.Color.light09),
                                                                tl: {
                                                                  hd: Css.transition({
                                                                        NAME: "ms",
                                                                        VAL: Styles.Duration.$$short
                                                                      }, undefined, undefined, "all"),
                                                                  tl: /* [] */0
                                                                }
                                                              }),
                                                          tl: {
                                                            hd: Css.selector(" span", {
                                                                  hd: Css.height(Css.px(16)),
                                                                  tl: {
                                                                    hd: Css.hover({
                                                                          hd: Icon.color(Styles.Color.light11),
                                                                          tl: /* [] */0
                                                                        }),
                                                                    tl: /* [] */0
                                                                  }
                                                                }),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    })
                                              }, match !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(SimpleTooltip.WithComponent.make, {
                                                          arrowSize: 8,
                                                          arrowOffset: 12,
                                                          offset: 4,
                                                          position: "bottom-start",
                                                          tooltip: React.createElement("div", undefined, React.createElement($$Text.make, {
                                                                    size: "Tiny",
                                                                    weight: "Semi",
                                                                    children: "Release: " + version.release + (
                                                                      isLatest ? " (latest)" : ""
                                                                    )
                                                                  }), React.createElement($$Text.make, {
                                                                    element: "Span",
                                                                    size: "Tiny",
                                                                    children: null
                                                                  }, "First seen ", React.createElement($$Text.make, {
                                                                        element: "Span",
                                                                        weight: "Semi",
                                                                        children: DateFns.formatDistanceToNow(false, true, match.firstSeen)
                                                                      }))),
                                                          children: React.createElement(Icon.make, {
                                                                type_: "time",
                                                                size: "small"
                                                              })
                                                        }), React.createElement(Spacer.make, {
                                                          width: 4
                                                        })) : null, React.createElement("div", {
                                                    className: pill(isImpacted)
                                                  }, React.createElement($$Text.make, {
                                                        size: "Tiny",
                                                        lines: 1,
                                                        color: Styles.Color.light11,
                                                        title: version.release,
                                                        children: version.release + (
                                                          isLatest ? " (latest)" : ""
                                                        )
                                                      }))), React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.justifyContent("center"),
                                                        tl: /* [] */0
                                                      }
                                                    })
                                              }, React.createElement($$Text.make, {
                                                    size: "Small",
                                                    color: Styles.Color.light11,
                                                    children: $$Number.toLocaleString(version.eventCount)
                                                  })), React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.justifyContent("center"),
                                                        tl: /* [] */0
                                                      }
                                                    })
                                              }, React.createElement($$Text.make, {
                                                    size: "Small",
                                                    color: Styles.Color.light11,
                                                    children: $$Number.toLocaleString(version.issueCount)
                                                  })), React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.justifyContent("flexEnd"),
                                                        tl: {
                                                          hd: Css.alignItems("center"),
                                                          tl: {
                                                            hd: Css.paddingRight(Css.px(12)),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    })
                                              }, React.createElement($$Text.make, {
                                                    size: "Small",
                                                    color: Styles.Color.light11,
                                                    children: InspectorIssueViewModel.Utils.getDisplayPercentageString(version.eventCount, version.issueCount) + "%"
                                                  }), React.createElement(Spacer.make, {
                                                    width: 4
                                                  }), React.createElement("div", {
                                                    className: percentageBar(version.eventCount, version.issueCount)
                                                  })));
                              }))),
                    key: issue.id
                  }) : null, issue.source.versions.length > 3 ? React.createElement("div", {
                    className: collapser
                  }, React.createElement(TextButton.make, {
                        onClick: (function (param) {
                            return Curry._1(openedActions.toggle, undefined);
                          }),
                        children: null
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light10,
                            children: opened ? "Collapse table to show less" : "Expand table to show " + String(issue.source.versions.length - 3 | 0) + " more"
                          }), React.createElement(Spacer.make, {
                            width: 4
                          }), React.createElement(FramerMotion.AnimatePresence, {
                            initial: false,
                            children: React.createElement(FramerMotion.motion.div, {
                                  animate: opened ? "expanded" : "collapsed",
                                  transition: {
                                    duration: Styles.Duration.$$default / 1000.0
                                  },
                                  initial: "collapsed",
                                  variants: {
                                    expanded: {
                                      rotateZ: -180
                                    },
                                    collapsed: {
                                      rotateZ: 0
                                    }
                                  },
                                  className: chevronContainer$1,
                                  key: "openingIcon",
                                  children: React.createElement(Icon.make, {
                                        type_: "chevronDown",
                                        size: "small",
                                        color: Styles.Color.light09
                                      })
                                })
                          }))) : React.createElement(Spacer.make, {
                    height: 16
                  }), React.createElement(Spacer.make, {
                  height: 16
                }));
}

var Releases = {
  Style: Style,
  make: InspectorIssueDetailsV2$Releases
};

function InspectorIssueDetailsV2$IssueAcrossSources(Props) {
  var issue = Props.issue;
  var relatedIssues = Props.relatedIssues;
  var onIssueInteracted = Props.onIssueInteracted;
  var mappedSources = ModelStore.Mapped.useSources(undefined);
  var tmp;
  if (typeof relatedIssues === "object") {
    var otherSourceCount = getSharedIssueSourceIds(relatedIssues.VAL).length - 1 | 0;
    tmp = otherSourceCount === 0 ? "This issue has only been seen on " + issue.source.name : "In addition to " + issue.source.name + ", this issue was seen on " + AvoUtils.plural(undefined, undefined, otherSourceCount, "other source") + ".";
  } else {
    tmp = relatedIssues === "error" ? "Error finding other sources, please contact support if this error persists." : "Finding other sources...";
  }
  var match = Curry._2(TrackingPlanMappedModel.Sources.get, mappedSources, issue.source.id);
  var tmp$1;
  if (typeof relatedIssues === "object") {
    var sharedIssues = relatedIssues.VAL;
    tmp$1 = getSharedIssueSourceIds(sharedIssues).length > 1 ? Belt_Array.map(Belt_Array.keep(sharedIssues, (function (sharedIssue) {
                  return sharedIssue.sourceId !== issue.source.id;
                })), (function (sharedIssue) {
              var source = Curry._2(TrackingPlanMappedModel.Sources.get, mappedSources, sharedIssue.sourceId);
              return React.createElement("div", {
                          key: sharedIssue.issueId,
                          className: dataTableRow(5)
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: /* [] */0
                                  })
                            }, source !== undefined ? React.createElement(Link.make, {
                                    path: Router.Link.addDrawerItem(undefined, {
                                          NAME: "source",
                                          VAL: [
                                            source.id,
                                            "overview"
                                          ]
                                        }),
                                    className: clickablePill,
                                    onClick: (function (param) {
                                        return Curry._2(onIssueInteracted, issue, "ClickViewSourceInTrackingPlan");
                                      }),
                                    children: React.createElement($$Text.make, {
                                          size: "Tiny",
                                          singleLine: true,
                                          children: Curry._1(TrackingPlanMappedModel.Sources.Utils.resolveName, source)
                                        })
                                  }) : React.createElement("div", {
                                    className: pill(false)
                                  }, React.createElement($$Text.make, {
                                        size: "Tiny",
                                        color: Styles.Color.light11,
                                        children: Belt_Option.getWithDefault(TrackingPlanMappedModel.Sources.getName(mappedSources, sharedIssue.sourceId), "Untitled Source")
                                      }))), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light11,
                                  children: $$Number.toLocaleString(sharedIssue.eventCount)
                                })), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light11,
                                  children: $$Number.toLocaleString(sharedIssue.issueCount)
                                })), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light11,
                                  children: InspectorIssueViewModel.Utils.getDisplayPercentageString(sharedIssue.eventCount | 0, sharedIssue.issueCount | 0) + "%"
                                })), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement("button", {
                                  className: tableButton,
                                  onClick: (function (param) {
                                      Curry._2(onIssueInteracted, issue, "ClickRelevantIssue");
                                      return Router.Schema.pushDrawerItem(undefined, undefined, {
                                                  NAME: "inspectorIssue",
                                                  VAL: [
                                                    sharedIssue.issueId,
                                                    undefined
                                                  ]
                                                });
                                    })
                                }, "View issue")));
            })) : null;
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: "ISSUE ACROSS SOURCES"
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(2)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light04),
                            tl: {
                              hd: Css.marginBottom(Css.px(16)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light11,
                  children: tmp
                }), React.createElement("div", {
                  className: dataTable
                }, React.createElement("div", {
                      className: dataTableHeader(5)
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("flexStart"),
                                  tl: {
                                    hd: Css.alignItems("flexEnd"),
                                    tl: {
                                      hd: Css.marginBottom(Css.px(6)),
                                      tl: {
                                        hd: Css.paddingLeft(Css.px(16)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Tiny",
                              weight: "Semi",
                              color: Styles.Color.light10,
                              children: "Source"
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.alignItems("flexEnd"),
                                    tl: {
                                      hd: Css.marginBottom(Css.px(6)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Tiny",
                              weight: "Semi",
                              color: Styles.Color.light10,
                              children: "Event volume"
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.alignItems("flexEnd"),
                                    tl: {
                                      hd: Css.marginBottom(Css.px(6)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Tiny",
                              weight: "Semi",
                              color: Styles.Color.light10,
                              children: "Issue volume"
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.alignItems("flexEnd"),
                                    tl: {
                                      hd: Css.marginBottom(Css.px(6)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Tiny",
                              weight: "Semi",
                              color: Styles.Color.light10,
                              children: "Issue %"
                            })), React.createElement("div", undefined)), React.createElement("div", {
                      className: Curry._1(Css.merge, {
                            hd: dataTableRow(5),
                            tl: {
                              hd: dataTableRowCurrent,
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: /* [] */0
                              })
                        }, match !== undefined ? React.createElement(Link.make, {
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "source",
                                      VAL: [
                                        match.id,
                                        "overview"
                                      ]
                                    }),
                                className: Curry._1(Css.merge, {
                                      hd: clickablePill,
                                      tl: {
                                        hd: Curry._1(Css.style, Styles.maxLines(1)),
                                        tl: /* [] */0
                                      }
                                    }),
                                onClick: (function (param) {
                                    return Curry._2(onIssueInteracted, issue, "ClickViewSourceInTrackingPlan");
                                  }),
                                children: React.createElement($$Text.make, {
                                      size: "Tiny",
                                      singleLine: true,
                                      title: issue.source.name,
                                      children: issue.source.name
                                    })
                              }) : React.createElement("div", {
                                className: Curry._1(Css.merge, {
                                      hd: pill(false),
                                      tl: {
                                        hd: Curry._1(Css.style, Styles.maxLines(1)),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    size: "Tiny",
                                    singleLine: true,
                                    color: Styles.Color.light11,
                                    title: issue.source.name,
                                    children: issue.source.name
                                  }))), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.light11,
                              children: $$Number.toLocaleString(issue.eventCount)
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.light11,
                              children: $$Number.toLocaleString(issue.issueCount)
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.light11,
                              children: InspectorIssueViewModel.Utils.getDisplayPercentageString(issue.eventCount, issue.issueCount) + "%"
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Tiny",
                              color: Styles.Color.light10,
                              children: "Current issue"
                            }))), tmp$1));
}

var IssueAcrossSources = {
  make: InspectorIssueDetailsV2$IssueAcrossSources
};

var content = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.padding(Css.px(32)),
        tl: {
          hd: Css.overflowY("auto"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: /* [] */0
          }
        }
      }
    });

var simpleButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: $$Text.sizeStyles("Small"),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.color(Styles.Color.darkBlue),
                tl: {
                  hd: Css.textDecoration("underline"),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: Styles.Duration.$$short
                        }, undefined, undefined, "all"),
                    tl: {
                      hd: Css.hover({
                            hd: Css.color(Styles.Color.deepBlue),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var tags = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexWrap("wrap"),
        tl: {
          hd: Css.gap(Css.px(8)),
          tl: {
            hd: Css.marginBottom(Css.px(8)),
            tl: {
              hd: Css.marginTop(Css.px(8)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var category = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(4), Css.px(8)),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
          tl: {
            hd: Css.color(Styles.Color.light11),
            tl: {
              hd: Css.transition({
                    NAME: "ms",
                    VAL: Styles.Duration.$$short
                  }, undefined, undefined, "all"),
              tl: {
                hd: Css.hover({
                      hd: Css.color(Styles.Color.light12),
                      tl: {
                        hd: Css.borderColor(Styles.Color.light09),
                        tl: /* [] */0
                      }
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function tag(hue) {
  return Curry._1(Css.style, {
              hd: Css.paddingTop(Css.px(3)),
              tl: {
                hd: Css.paddingRight(Css.px(8)),
                tl: {
                  hd: Css.paddingBottom(Css.px(5)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(8)),
                    tl: {
                      hd: Css.borderRadius(Css.px(99)),
                      tl: {
                        hd: Css.color(Styles.Color.light11),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.getSecondaryColor(hue, "pale")),
                          tl: {
                            hd: Css.color(Styles.Color.getSecondaryColor(hue, "deep")),
                            tl: {
                              hd: Css.fontFamily(Styles.FontFamily.polar),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.medium),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.small),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var largePill = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(100)),
      tl: {
        hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
        tl: {
          hd: Css.padding2(Css.px(5), Css.px(10)),
          tl: /* [] */0
        }
      }
    });

var clickableLargePill = Curry._1(Css.merge, {
      hd: largePill,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.color(Styles.Color.light11),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "all"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.color(Styles.Color.light12),
                              tl: {
                                hd: Css.borderColor(Styles.Color.light09),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function textHover(from, to_) {
  return Curry._1(Css.style, {
              hd: Css.color(from),
              tl: {
                hd: Css.hover({
                      hd: Css.color(to_),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "all"),
                  tl: /* [] */0
                }
              }
            });
}

var seenContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.gap(Css.px(6)),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginTop(Css.px(-4)),
            tl: /* [] */0
          }
        }
      }
    });

var Style$1 = {
  content: content,
  simpleButton: simpleButton,
  tags: tags,
  category: category,
  tag: tag,
  largePill: largePill,
  clickableLargePill: clickableLargePill,
  textHover: textHover,
  seenContainer: seenContainer
};

function InspectorIssueDetailsV2$Content(Props) {
  var issue = Props.issue;
  var onIssueInteracted = Props.onIssueInteracted;
  var maybeEvent = Props.maybeEvent;
  var maybeProperty = Props.maybeProperty;
  var openBranches = Props.openBranches;
  var relatedIssues = Props.relatedIssues;
  var goToCommentId = Props.goToCommentId;
  var viewerRole = Props.viewerRole;
  var match = ViewerContext.use(undefined);
  var userId = match.id;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var model = ModelStore.useModel(undefined);
  var mappedModel = ModelStore.Mapped.use(undefined);
  var match$1 = InspectorIssuesStore.useAppVersionsInSyncWithIssues(undefined, undefined, undefined);
  var appVersions = match$1[0];
  var updateIssue = InspectorIssuesStore.useUpdateIssue(undefined);
  var addToast = Toast.useAddToast(undefined);
  var eventId = Belt_Option.map(maybeEvent, (function ($$event) {
          return $$event.id;
        }));
  var match$2 = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match$2.orderedBy;
  var filters = match$2.filters;
  var match$3 = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var status = match$3[0];
  var match$4 = InspectorIssuesStore.useFilteredIssues(status, undefined, undefined);
  var filteredIssues = match$4.filteredIssues;
  var match$5 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var savedViewStatus = match$5.savedViewStatus;
  var openModal = GlobalSendContext.useOpenModal(undefined);
  var mappedSources = ModelStore.Mapped.useSources(undefined);
  var match$6 = React.useState(function () {
        return false;
      });
  var setImporting = match$6[1];
  var importing = match$6[0];
  var workspace = WorkspaceContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var regression = issue.regression && issue.issueStatus.status === /* Unresolved */0;
  var onUpdate = function (value) {
    if (workspace.isSandbox) {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: "SandboxLimitation"
                });
    }
    var match = AppVersionsTypes.getLatestVersion(undefined, appVersions, issue.source.id);
    var newStatus;
    if (value !== undefined) {
      if (typeof value === "object") {
        var match$1 = value.VAL;
        newStatus = typeof match$1 === "object" ? ({
              TAG: /* Resolved */1,
              _0: {
                TAG: /* CustomAppVersion */2,
                _0: match$1.VAL
              }
            }) : (
            match$1 === "currentAppVersion" ? (
                match !== undefined ? ({
                      TAG: /* Resolved */1,
                      _0: {
                        TAG: /* CurrentAppVersion */0,
                        _0: AppVersionsTypes.print(match)
                      }
                    }) : undefined
              ) : (
                match$1 === "nextAppVersion" ? (
                    match !== undefined ? ({
                          TAG: /* Resolved */1,
                          _0: {
                            TAG: /* NextAppVersion */1,
                            _0: AppVersionsTypes.print(match)
                          }
                        }) : undefined
                  ) : (
                    match$1 === "in1Hour" ? ({
                          TAG: /* Resolved */1,
                          _0: {
                            TAG: /* Date */3,
                            _0: DateFns.addHours(1.0, new Date())
                          }
                        }) : ({
                          TAG: /* Resolved */1,
                          _0: {
                            TAG: /* Date */3,
                            _0: DateFns.addHours(24.0, new Date())
                          }
                        })
                  )
              )
          );
      } else {
        newStatus = value === "unresolved" ? /* Unresolved */0 : ({
              TAG: /* Ignored */0,
              _0: /* Never */0
            });
      }
    } else {
      newStatus = undefined;
    }
    var newIssueStatus = Belt_Option.mapU(newStatus, (function (status) {
            return {
                    status: status,
                    updatedAt: Caml_option.some(new Date()),
                    updatedBy: userId
                  };
          }));
    return Belt_Option.forEachU(newIssueStatus, (function (issueStatus) {
                  var optimisticallyUpdatedIssue_id = issue.id;
                  var optimisticallyUpdatedIssue_sharedId = issue.sharedId;
                  var optimisticallyUpdatedIssue_issueType = issue.issueType;
                  var optimisticallyUpdatedIssue_eventName = issue.eventName;
                  var optimisticallyUpdatedIssue_propertyName = issue.propertyName;
                  var optimisticallyUpdatedIssue_source = issue.source;
                  var optimisticallyUpdatedIssue_categories = issue.categories;
                  var optimisticallyUpdatedIssue_tags = issue.tags;
                  var optimisticallyUpdatedIssue_firstSeen = issue.firstSeen;
                  var optimisticallyUpdatedIssue_lastSeen = issue.lastSeen;
                  var optimisticallyUpdatedIssue_eventCount = issue.eventCount;
                  var optimisticallyUpdatedIssue_issueCount = issue.issueCount;
                  var optimisticallyUpdatedIssue_newestAppVersion = issue.newestAppVersion;
                  var optimisticallyUpdatedIssue_oldestAppVersion = issue.oldestAppVersion;
                  var optimisticallyUpdatedIssue_regression = issue.regression;
                  var optimisticallyUpdatedIssue = {
                    id: optimisticallyUpdatedIssue_id,
                    sharedId: optimisticallyUpdatedIssue_sharedId,
                    issueType: optimisticallyUpdatedIssue_issueType,
                    issueStatus: issueStatus,
                    eventName: optimisticallyUpdatedIssue_eventName,
                    propertyName: optimisticallyUpdatedIssue_propertyName,
                    source: optimisticallyUpdatedIssue_source,
                    categories: optimisticallyUpdatedIssue_categories,
                    tags: optimisticallyUpdatedIssue_tags,
                    firstSeen: optimisticallyUpdatedIssue_firstSeen,
                    lastSeen: optimisticallyUpdatedIssue_lastSeen,
                    eventCount: optimisticallyUpdatedIssue_eventCount,
                    issueCount: optimisticallyUpdatedIssue_issueCount,
                    newestAppVersion: optimisticallyUpdatedIssue_newestAppVersion,
                    oldestAppVersion: optimisticallyUpdatedIssue_oldestAppVersion,
                    regression: optimisticallyUpdatedIssue_regression
                  };
                  Curry._1(updateIssue, optimisticallyUpdatedIssue);
                  $$Promise.$$catch(InspectorViewApi.updateIssueStatus(issueStatus.status, "user", issue.id).then(function (param) {
                            var issueStatus$1 = param.issueStatus;
                            var newIssue_id = issue.id;
                            var newIssue_sharedId = issue.sharedId;
                            var newIssue_issueType = issue.issueType;
                            var newIssue_eventName = issue.eventName;
                            var newIssue_propertyName = issue.propertyName;
                            var newIssue_source = issue.source;
                            var newIssue_categories = issue.categories;
                            var newIssue_tags = issue.tags;
                            var newIssue_firstSeen = issue.firstSeen;
                            var newIssue_lastSeen = issue.lastSeen;
                            var newIssue_eventCount = issue.eventCount;
                            var newIssue_issueCount = issue.issueCount;
                            var newIssue_newestAppVersion = issue.newestAppVersion;
                            var newIssue_oldestAppVersion = issue.oldestAppVersion;
                            var newIssue_regression = issue.regression;
                            var newIssue = {
                              id: newIssue_id,
                              sharedId: newIssue_sharedId,
                              issueType: newIssue_issueType,
                              issueStatus: issueStatus$1,
                              eventName: newIssue_eventName,
                              propertyName: newIssue_propertyName,
                              source: newIssue_source,
                              categories: newIssue_categories,
                              tags: newIssue_tags,
                              firstSeen: newIssue_firstSeen,
                              lastSeen: newIssue_lastSeen,
                              eventCount: newIssue_eventCount,
                              issueCount: newIssue_issueCount,
                              newestAppVersion: newIssue_newestAppVersion,
                              oldestAppVersion: newIssue_oldestAppVersion,
                              regression: newIssue_regression
                            };
                            var tmp;
                            if (value !== undefined && typeof value === "object" && value.NAME === "resolved") {
                              var match = value.VAL;
                              tmp = typeof match === "object" ? "SpecificRelease" : (
                                  match === "currentAppVersion" ? "LatestRelease" : (
                                      match === "nextAppVersion" ? "NextRelease" : (
                                          match === "in1Hour" ? "OneHour" : "_24Hours"
                                        )
                                    )
                                );
                            } else {
                              tmp = undefined;
                            }
                            AnalyticsRe.inspectorIssueStatusUpdated(schemaBundle, InspectorIssuesHelpers.createInspectorIssueDetailsGroup("UpdateIssueStatus", typeof relatedIssues === "object" && relatedIssues.NAME === "success" ? getSharedIssueSourceIds(relatedIssues.VAL).length : -1, newIssue), InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), Curry._1(Issues.size, filteredIssues), InspectorIssuesFilterUtils.savedViewStatusToViewTypeAnalytics(savedViewStatus), InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), tmp, schemaBundle.schemaId);
                            if (Caml_obj.caml_notequal(issueStatus$1.status, issueStatus.status)) {
                              return Curry._1(updateIssue, newIssue);
                            }
                            
                          }), (function (param) {
                          Curry._1(addToast, {
                                message: "Failed to update Issue Status",
                                toastType: /* Error */1
                              });
                          Curry._1(updateIssue, issue);
                          return Promise.resolve(undefined);
                        }));
                  
                }));
  };
  var workspace$1 = WorkspaceContext.use(undefined);
  var tmp;
  if (regression) {
    tmp = React.createElement(InspectorIssueDetailsV2$LilSpyRegression, {
          issue: issue
        });
  } else {
    var match$7 = issue.issueStatus.status;
    tmp = typeof match$7 === "number" || match$7.TAG === /* Ignored */0 ? null : React.createElement(InspectorIssueDetailsV2$LilSpyRegressionWarning, {});
  }
  var tmp$1;
  if (maybeEvent !== undefined) {
    var categories = ModelUtils_mapped.getGoalsWithEvent(maybeEvent, mappedModel);
    tmp$1 = React.createElement(InspectorIssueDetailsV2$Section, {
          label: "Event",
          labelTopOffset: 2,
          children: null
        }, React.createElement(Link.make, {
              path: Router.Link.addDrawerItem(undefined, {
                    NAME: "event",
                    VAL: [
                      maybeEvent.id,
                      undefined,
                      undefined,
                      false
                    ]
                  }),
              className: Curry._1(Css.merge, {
                    hd: textHover(Styles.Color.light12, Styles.Color.darkBlue),
                    tl: {
                      hd: Curry._1(Css.style, {
                            hd: Css.display("block"),
                            tl: {
                              hd: Css.width("maxContent"),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }),
              onClick: (function (param) {
                  return Curry._2(onIssueInteracted, issue, "ClickViewEventInTrackingPlan");
                }),
              children: React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    singleLine: true,
                    children: maybeEvent.name
                  })
            }), React.createElement(Spacer.make, {
              height: 2
            }), React.createElement(InspectorIssueDetailsV2$DescriptionContainer, {
              children: maybeEvent.description,
              key: issue.id
            }), Caml_obj.caml_notequal(maybeEvent.variants, []) && Caml_obj.caml_notequal(categories, []) && maybeEvent.tags !== /* [] */0 ? React.createElement(Spacer.make, {
                height: 4
              }) : null, Caml_obj.caml_notequal(maybeEvent.variants, []) ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                    height: 8
                  }), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(Icon.make, {
                        type_: "warning",
                        size: "small",
                        color: Styles.Color.orange
                      }), React.createElement(Spacer.make, {
                        width: 4
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.darkOrange,
                        children: "This issue could have originated from an event variant."
                      })), React.createElement(Spacer.make, {
                    height: 8
                  }), React.createElement($$Text.make, {
                    size: "Tiny",
                    weight: "Semi",
                    color: Styles.Color.light10,
                    children: "Event Variants"
                  }), React.createElement("div", {
                    className: tags
                  }, Belt_Array.mapU(maybeEvent.variants, (function (variant) {
                          return React.createElement(Link.make, {
                                      path: Router.Link.addDrawerItem(undefined, {
                                            NAME: "eventVariant",
                                            VAL: [
                                              {
                                                baseEventId: variant.baseEventId,
                                                variantId: variant.id
                                              },
                                              undefined
                                            ]
                                          }),
                                      className: category,
                                      onClick: (function (param) {
                                          return Curry._2(onIssueInteracted, issue, "ClickViewEventVariantInTrackingPlan");
                                        }),
                                      children: React.createElement($$Text.make, {
                                            size: "Tiny",
                                            children: variant.nameSuffix === "" ? "Untitled Variant" : variant.nameSuffix
                                          }),
                                      key: variant.id
                                    });
                        })))) : null, Caml_obj.caml_notequal(categories, []) ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                    height: 4
                  }), React.createElement($$Text.make, {
                    size: "Tiny",
                    weight: "Semi",
                    color: Styles.Color.light10,
                    children: "Event Categories"
                  }), React.createElement("div", {
                    className: tags
                  }, Belt_Array.mapU(categories, (function (category$1) {
                          return React.createElement(Link.make, {
                                      path: Router.Link.addDrawerItem(undefined, {
                                            NAME: "category",
                                            VAL: [
                                              category$1.id,
                                              undefined
                                            ]
                                          }),
                                      className: category,
                                      onClick: (function (param) {
                                          return Curry._2(onIssueInteracted, issue, "ClickViewCategoryInTrackingPlan");
                                        }),
                                      children: React.createElement($$Text.make, {
                                            size: "Tiny",
                                            children: category$1.name === "" ? "Untitled Category" : category$1.name
                                          }),
                                      key: category$1.id
                                    });
                        })))) : null, maybeEvent.tags !== /* [] */0 ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                    height: 4
                  }), React.createElement($$Text.make, {
                    size: "Tiny",
                    weight: "Semi",
                    color: Styles.Color.light10,
                    children: "Event Tags"
                  }), React.createElement("div", {
                    className: tags
                  }, Belt_List.toArray(Belt_List.mapU(maybeEvent.tags, (function (tag$1) {
                              return React.createElement("div", {
                                          key: tag$1,
                                          className: tag(Styles.Color.getHueFromString(tag$1))
                                        }, tag$1);
                            }))))) : null, React.createElement("button", {
              className: simpleButton,
              onClick: (function (param) {
                  Router.Schema.pushDrawerItem(undefined, undefined, {
                        NAME: "event",
                        VAL: [
                          maybeEvent.id,
                          undefined,
                          undefined,
                          false
                        ]
                      });
                  return Curry._2(onIssueInteracted, issue, "ClickViewEventInTrackingPlan");
                })
            }, "View event in tracking plan"));
  } else {
    tmp$1 = React.createElement(InspectorIssueDetailsV2$Section, {
          label: "Event",
          labelTopOffset: 2,
          children: React.createElement($$Text.make, {
                size: "Medium",
                weight: "Semi",
                color: Styles.Color.light12,
                children: issue.eventName
              })
        });
  }
  var match$8 = issue.propertyName;
  var tmp$2;
  if (maybeProperty !== undefined) {
    var tmp$3 = {
      property: maybeProperty,
      skipConstraints: true
    };
    if (maybeEvent !== undefined) {
      tmp$3.event = Caml_option.valFromOption(maybeEvent);
    }
    tmp$2 = React.createElement(InspectorIssueDetailsV2$Section, {
          label: "Property",
          labelTopOffset: 3,
          children: null
        }, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.gap(Css.px(4)),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement(Link.make, {
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          maybeProperty.id,
                          undefined
                        ]
                      }),
                  className: textHover(Styles.Color.light12, Styles.Color.darkBlue),
                  onClick: (function (param) {
                      return Curry._2(onIssueInteracted, issue, "ClickViewPropertyInTrackingPlan");
                    }),
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Semi",
                        singleLine: true,
                        children: maybeProperty.name
                      })
                }), React.createElement(PropertyType.make, tmp$3)), React.createElement(Spacer.make, {
              height: 2
            }), React.createElement(InspectorIssueDetailsV2$DescriptionContainer, {
              children: maybeProperty.description,
              key: issue.id
            }), React.createElement("button", {
              className: simpleButton,
              onClick: (function (param) {
                  Router.Schema.pushDrawerItem(undefined, undefined, {
                        NAME: "property",
                        VAL: [
                          maybeProperty.id,
                          undefined
                        ]
                      });
                  return Curry._2(onIssueInteracted, issue, "ClickViewPropertyInTrackingPlan");
                })
            }, "View property in tracking plan"));
  } else {
    tmp$2 = match$8 !== undefined ? React.createElement(InspectorIssueDetailsV2$Section, {
            label: "Property",
            labelTopOffset: 2,
            children: React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: match$8
                })
          }) : null;
  }
  var match$9 = issue.issueType;
  var tmp$4;
  if (typeof match$9 === "number") {
    tmp$4 = match$9 === /* EventNotInTrackingPlan */0 ? React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.marginTop(Css.px(-6)),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  }
                })
          }, "Unexpected event", React.createElement(SimpleTooltip.make, {
                tooltip: maybeEvent === undefined ? "Review and add event to your Tracking plan" : "Event is already in Tracking plan",
                children: React.createElement(Button.make, {
                      disabled: importing || Belt_Option.isSome(maybeEvent),
                      icon: "addToTrackingPlan",
                      label: "Add to Tracking plan",
                      loading: importing,
                      onClick: (function (param) {
                          Curry._1(setImporting, (function (param) {
                                  return true;
                                }));
                          $$Promise.$$catch(ImportReview__V2__Utils.handlePrepareImport(ImportReview__V2__Utils.getEventNames(appVersions, maybeEvent !== undefined ? Belt_MapString.fromArray([[
                                                    maybeEvent.name,
                                                    maybeEvent
                                                  ]]) : undefined, Curry._1(Issues.fromArray, [issue]))).then(function (importModel) {
                                      return Curry._1(openModal, {
                                                  NAME: "ImportReview",
                                                  VAL: [
                                                    importModel,
                                                    "AddOnly",
                                                    "InspectorIssueDetails"
                                                  ]
                                                });
                                    }), (function (e) {
                                    ImportReview__V2__Utils.handleExceptions(e, (function (message, severity) {
                                            return Curry._1(addToast, {
                                                        message: message,
                                                        toastType: severity === "error" ? /* Error */1 : /* Info */2
                                                      });
                                          }));
                                    return Promise.resolve(undefined);
                                  })).finally(function (param) {
                                return Curry._1(setImporting, (function (param) {
                                              return false;
                                            }));
                              });
                          
                        }),
                      size: "tiny"
                    })
              })) : "Event unexpected on " + issue.source.name + " by tracking plan";
  } else {
    switch (match$9.TAG | 0) {
      case /* MissingExpectedProperty */0 :
          var presence = getSimplestAbsence(maybeProperty, maybeEvent, model.sources);
          var tmp$5;
          switch (presence) {
            case /* Always */0 :
                tmp$5 = "always sent";
                break;
            case /* Sometimes */1 :
                tmp$5 = "sometimes sent";
                break;
            case /* BySource */2 :
                tmp$5 = "depending on source";
                break;
            
          }
          tmp$4 = React.createElement(React.Fragment, undefined, "Property set as ", React.createElement(Monospace.make, {
                    weight: "medium",
                    children: tmp$5
                  }), " on \"" + issue.eventName + "\" in tracking plan but missing on event");
          break;
      case /* PropertyTypeInconsistentWithTrackingPlan */1 :
          var match$10 = match$9._0;
          tmp$4 = React.createElement(React.Fragment, undefined, "Property seen as", React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.backgroundColor(Styles.Color.lightRed),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.marginLeft(Css.px(4)),
                              tl: {
                                hd: Css.marginRight(Css.px(4)),
                                tl: {
                                  hd: Css.fontSize(Css.px(11)),
                                  tl: {
                                    hd: Css.padding2(Css.px(4), Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(Monospace.make, {
                        color: Styles.Color.darkRed,
                        children: match$10.actualPropertyType
                      })), "but expected as", React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.backgroundColor(Styles.Color.light02),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.marginLeft(Css.px(4)),
                              tl: {
                                hd: Css.marginRight(Css.px(4)),
                                tl: {
                                  hd: Css.fontSize(Css.px(11)),
                                  tl: {
                                    hd: Css.padding2(Css.px(4), Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(Monospace.make, {
                        color: Styles.Color.light11,
                        children: match$10.expectedPropertyType
                      })));
          break;
      case /* UnexpectedProperty */2 :
          tmp$4 = maybeProperty !== undefined ? "Property found in tracking plan but unexpected on this event" : "Property unexpected on event by tracking plan";
          break;
      case /* InconsistentType */3 :
          var propertyTypes = match$9._0.propertyTypes;
          tmp$4 = React.createElement(React.Fragment, undefined, "Property types seen: ", React.createElement(Monospace.make, {
                    weight: "medium",
                    children: Belt_Array.getExn(propertyTypes, 0)
                  }), ", ", React.createElement(Monospace.make, {
                    weight: "medium",
                    children: Belt_Array.getExn(propertyTypes, 1)
                  }));
          break;
      
    }
  }
  var match$11 = Curry._2(TrackingPlanMappedModel.Sources.get, mappedSources, issue.source.id);
  var tmp$6 = {
    object_: {
      NAME: "InspectorIssue",
      VAL: [
        issue.id,
        issue.id
      ]
    },
    objectId: issue.id,
    currentBranch: "Master",
    openBranches: openBranches
  };
  if (goToCommentId !== undefined) {
    tmp$6.goToCommentId = Caml_option.valFromOption(goToCommentId);
  }
  var tmp$7 = {
    threadId: issue.id,
    itemType: "InspectorIssue",
    itemName: issue.id,
    mini: true,
    currentBranch: "Master",
    role: viewerRole,
    location: "InspectorIssue"
  };
  if (eventId !== undefined) {
    tmp$7.eventId = Caml_option.valFromOption(eventId);
  }
  return React.createElement("div", {
              className: content
            }, React.createElement(InspectorIssueDetailsV2$Section, {
                  id: "issue-status-id-for-flows",
                  label: "Status",
                  labelTopOffset: 4,
                  children: React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.gap(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement(IssueStatusSelect.make, {
                            issue: issue,
                            onUpdate: onUpdate,
                            key: String(Math.random())
                          }), tmp)
                }), tmp$1, tmp$2, React.createElement(InspectorIssueDetailsV2$Section, {
                  label: "Issue",
                  children: React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light12,
                        children: tmp$4
                      })
                }), React.createElement(InspectorIssueDetailsV2$Section, {
                  label: "Source",
                  labelTopOffset: 5,
                  children: match$11 !== undefined ? React.createElement(Link.make, {
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "source",
                                VAL: [
                                  match$11.id,
                                  "overview"
                                ]
                              }),
                          className: clickableLargePill,
                          onClick: (function (param) {
                              return Curry._2(onIssueInteracted, issue, "ClickViewSourceInTrackingPlan");
                            }),
                          children: React.createElement($$Text.make, {
                                element: "Span",
                                size: "Small",
                                children: issue.source.name
                              })
                        }) : React.createElement("div", {
                          className: largePill
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.light11,
                              children: issue.source.name
                            }))
                }), AvoLimits.isInspectorVolumeBlocked(workspace$1) ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginLeft(Css.px(-32)),
                          tl: {
                            hd: Css.marginRight(Css.px(-32)),
                            tl: {
                              hd: Css.marginTop(Css.px(-8)),
                              tl: {
                                hd: Css.marginBottom(Css.px(-16)),
                                tl: /* [] */0
                              }
                            }
                          }
                        })
                  }, React.createElement("img", {
                        alt: "Blurred Issue Details",
                        height: "163",
                        src: Asset.getPath("IssueRedactedDetails.jpg"),
                        width: "597"
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding(Css.px(40)),
                              tl: /* [] */0
                            })
                      }, React.createElement(Title.make, {
                            children: "Upgrade to unlock issue stats",
                            size: "Small"
                          }), React.createElement(Spacer.make, {
                            height: 16
                          }), React.createElement($$Text.make, {
                            size: "Medium",
                            color: Styles.Color.light10,
                            maxWidth: Css.px(540),
                            children: "Issue details are limited as you have exceeded the monthly event volume limit provided on your plan."
                          }), viewerRole === "Admin" ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                  height: 8
                                }), React.createElement($$Text.make, {
                                  size: "Medium",
                                  color: Styles.Color.light10,
                                  maxWidth: Css.px(540),
                                  children: "To unlock these details, upgrade your plan. "
                                }), React.createElement(Spacer.make, {
                                  height: 24
                                }), React.createElement(Button.make, {
                                  label: "Explore plans",
                                  onClick: (function (param) {
                                      return Router.push(undefined, Router.Link.getSchemaRouteLink(undefined, "billing"));
                                    }),
                                  size: "large"
                                })) : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                  height: 24
                                }), React.createElement($$Text.make, {
                                  size: "Medium",
                                  color: Styles.Color.light10,
                                  maxWidth: Css.px(540),
                                  children: "To unlock these details contact your workspace Admin to upgrade your plan."
                                }))), React.createElement("img", {
                        alt: "Blurred Issue Sources",
                        height: "154",
                        src: Asset.getPath("IssueRedacted.jpg"),
                        width: "597"
                      })) : React.createElement(React.Fragment, undefined, React.createElement(InspectorIssueDetailsV2$Section, {
                        label: "First seen",
                        children: React.createElement("span", {
                              className: seenContainer
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light12,
                                  title: issue.firstSeen.toLocaleString(),
                                  children: DateFns.formatDistance(undefined, undefined, new Date(), issue.firstSeen) + " ago "
                                }), React.createElement("span", {
                                  className: pill(false)
                                }, React.createElement($$Text.make, {
                                      size: "Tiny",
                                      singleLine: true,
                                      color: Styles.Color.light11,
                                      children: issue.oldestAppVersion
                                    })))
                      }), React.createElement(InspectorIssueDetailsV2$Section, {
                        label: "Last seen",
                        children: React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.light12,
                              title: issue.lastSeen.toLocaleString(),
                              children: DateFns.formatDistance(undefined, undefined, new Date(), issue.lastSeen) + " ago "
                            })
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(InspectorIssueDetailsV2$Releases, {
                        issue: issue
                      }), React.createElement(InspectorIssueDetailsV2$IssueAcrossSources, {
                        issue: issue,
                        relatedIssues: relatedIssues,
                        onIssueInteracted: onIssueInteracted
                      })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(-32)),
                        tl: {
                          hd: Css.marginRight(Css.px(-32)),
                          tl: {
                            hd: Css.marginBottom(Css.px(-32)),
                            tl: {
                              hd: Css.marginTop(Css.px(24)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement(ObjectActivityLog.make, tmp$6), React.createElement(CommentInput.make, tmp$7)));
}

var Content = {
  Style: Style$1,
  make: InspectorIssueDetailsV2$Content
};

function useRelatedIssues(issue) {
  var match = React.useState(function () {
        return "initial";
      });
  var setRelatedIssues = match[1];
  React.useEffect((function () {
          if (issue !== undefined) {
            var sharedId = issue.sharedId;
            Curry._1(setRelatedIssues, (function (param) {
                    return "loading";
                  }));
            $$Promise.$$catch(InspectorViewApi.getRelatedIssues(sharedId).then(function (sharedIssues) {
                      var isCurrentSharedId = Belt_Option.mapWithDefault(Belt_Array.get(sharedIssues, 0), false, (function (issue) {
                              return issue.sharedIssueId === sharedId;
                            }));
                      if (isCurrentSharedId) {
                        return Curry._1(setRelatedIssues, (function (param) {
                                      return {
                                              NAME: "success",
                                              VAL: sharedIssues
                                            };
                                    }));
                      }
                      
                    }), (function (exn) {
                    Curry._1(setRelatedIssues, (function (param) {
                            return "error";
                          }));
                    return Promise.reject(exn);
                  }));
          } else {
            Curry._1(setRelatedIssues, (function (param) {
                    return "initial";
                  }));
          }
          
        }), [
        Belt_Option.map(issue, (function (param) {
                return param.id;
              })),
        Belt_Option.map(issue, (function (param) {
                return param.sharedId;
              }))
      ]);
  return match[0];
}

function useIssueDetailsOpenedTracking(issue, fetchStatus, relatedIssues, savedViewStatus) {
  var schemaBundle = SchemaBundleContext.use(undefined);
  var hasSentDetailsOpenedEventForIssueId = React.useRef(undefined);
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match.orderedBy;
  var filters = match.filters;
  var match$1 = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var status = match$1[0];
  var match$2 = InspectorIssuesStore.useFilteredIssues(status, undefined, undefined);
  var filteredIssues = match$2.filteredIssues;
  React.useEffect((function () {
          if (issue !== undefined && typeof relatedIssues === "object" && relatedIssues.NAME === "Success" && Caml_obj.caml_notequal(hasSentDetailsOpenedEventForIssueId.current, issue.id)) {
            hasSentDetailsOpenedEventForIssueId.current = issue.id;
            AnalyticsRe.inspectorIssueDetailsOpened(schemaBundle, InspectorIssuesHelpers.createInspectorIssueDetailsGroup("OpenIssueDetails", relatedIssues.VAL.length, issue), InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), Curry._1(Issues.size, filteredIssues), InspectorIssuesFilterUtils.savedViewStatusToViewTypeAnalytics(savedViewStatus), InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), schemaBundle.schemaId);
          }
          
        }), [
        issue,
        fetchStatus,
        relatedIssues
      ]);
  
}

function InspectorIssueDetailsV2$SingleSourceIssueDetailsFlow(Props) {
  var pointerId = Props.pointerId;
  var match = Dopt.Flow.use("single-source-issues");
  var intent = match[1];
  var flow = match[0];
  React.useEffect((function () {
          if (flow.state.finished === false) {
            Dopt.Flow.start(true, intent);
          }
          
        }), [flow]);
  return React.createElement(ProductTour__Root.make, {
              tourId: "single-source-issues",
              children: React.createElement(ProductTour__Step.make, {
                    stepId: "step",
                    stepType: {
                      TAG: /* OnElementId */0,
                      _0: pointerId
                    },
                    delay: 0,
                    position: "left",
                    transitions: [{
                        key: "complete",
                        label: "Complete",
                        icon: undefined
                      }],
                    children: React.createElement(ProductTour__GenericStep.make, {
                          withExit: false
                        })
                  })
            });
}

var SingleSourceIssueDetailsFlow = {
  make: InspectorIssueDetailsV2$SingleSourceIssueDetailsFlow
};

function InspectorIssueDetailsV2(Props) {
  var id = Props.id;
  var goToCommentId = Props.goToCommentId;
  var openBranches = Props.openBranches;
  var viewerRole = Props.viewerRole;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = InspectorIssuesStore.useIssue(id);
  var fetchStatus = match.fetchStatus;
  var issue = match.issue;
  var match$1 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var savedViewStatus = match$1.savedViewStatus;
  var match$2 = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match$2.orderedBy;
  var filters = match$2.filters;
  var match$3 = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var status = match$3[0];
  var match$4 = InspectorIssuesStore.useFilteredIssues(status, undefined, undefined);
  var filteredIssues = match$4.filteredIssues;
  var relatedIssues = useRelatedIssues(issue);
  var model = ModelStore.useModel(undefined);
  useIssueDetailsOpenedTracking(issue, fetchStatus, relatedIssues, savedViewStatus);
  var onIssueInteracted = React.useCallback((function (issue) {
          return function (inspectorIssueDetailsInteractionType) {
            return AnalyticsRe.inspectorIssueDetailsInteraction(schemaBundle, InspectorIssuesHelpers.createInspectorIssueDetailsGroup(inspectorIssueDetailsInteractionType, typeof relatedIssues === "object" && relatedIssues.NAME === "success" ? relatedIssues.VAL.length : -1, issue), InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), Curry._1(Issues.size, filteredIssues), InspectorIssuesFilterUtils.savedViewStatusToViewTypeAnalytics(savedViewStatus), InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), schemaBundle.schemaId);
          };
        }), [
        schemaBundle,
        savedViewStatus
      ]);
  var exit = 0;
  if (typeof fetchStatus === "object") {
    if (fetchStatus.NAME === "replaceSharedId") {
      return React.createElement(Redirect.make, {
                  path: Router.Link.swapTopDrawerItem(undefined, {
                        NAME: "inspectorIssue",
                        VAL: [
                          fetchStatus.VAL,
                          goToCommentId
                        ]
                      })
                });
    }
    exit = 1;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (issue !== undefined) {
      var maybeEvent = ModelUtils.getEventByName(issue.eventName, model);
      var maybeProperty = Belt_Option.flatMap(issue.propertyName, (function (propertyName) {
              return ModelUtils.getPropertyByName(propertyName, model);
            }));
      var tmp = {
        issue: issue,
        onIssueInteracted: onIssueInteracted,
        maybeEvent: maybeEvent,
        maybeProperty: maybeProperty,
        openBranches: openBranches,
        relatedIssues: relatedIssues,
        viewerRole: viewerRole
      };
      if (goToCommentId !== undefined) {
        tmp.goToCommentId = Caml_option.valFromOption(goToCommentId);
      }
      return React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.height(Css.pct(100.0)),
                              tl: {
                                hd: Css.marginRight(Css.px(16)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.white),
                                  tl: {
                                    hd: Css.zIndex(1),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  id: "inspector-issue-details"
                }, React.createElement(InspectorIssuesOnboardingFlow.ActionableIssueDetails.make, {
                      for_: "details"
                    }), React.createElement(DrawerHeader.make, {
                      kind: "Inspector issue",
                      linkToCopy: Router.Link.getSimple(undefined),
                      onBack: (function (param) {
                          return Curry._2(onIssueInteracted, issue, "ClickBackButton");
                        }),
                      onExit: (function (param) {
                          return Curry._2(onIssueInteracted, issue, "CloseIssueDetails");
                        }),
                      onLinkCopy: (function (param) {
                          return Curry._2(onIssueInteracted, issue, "CopyIssueLink");
                        }),
                      children: React.createElement($$Text.make, {
                            size: "Large",
                            weight: "Semi",
                            color: Styles.Color.light12,
                            children: StringExt.widowless(InspectorIssueType.getIssueTypeAsText(issue.issueType))
                          })
                    }), React.createElement(InspectorIssueDetailsV2$Content, tmp), React.createElement(InspectorIssueDetailsV2$SingleSourceIssueDetailsFlow, {
                      pointerId: "inspector-issue-details"
                    }));
    }
    var exit$1 = 0;
    if (typeof fetchStatus === "object") {
      exit$1 = 2;
    } else {
      if (fetchStatus !== "success") {
        return React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
                        kind: "Inspector Issue",
                        children: React.createElement($$Text.make, {
                              size: "Large",
                              weight: "Semi",
                              color: Styles.Color.light12,
                              children: "Fetching issue..."
                            })
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.pct(100.0)),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.justifyContent("center"),
                                    tl: {
                                      hd: Css.flexDirection("column"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Medium",
                            textAlign: "center",
                            color: Styles.Color.light09,
                            children: "Fetching issue..."
                          })));
      }
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      return React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
                      kind: "Inspector issue",
                      children: React.createElement($$Text.make, {
                            size: "Large",
                            weight: "Semi",
                            color: Styles.Color.light12,
                            children: "Not found"
                          })
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.height(Css.pct(100.0)),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.flexDirection("column"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Medium",
                          textAlign: "center",
                          color: Styles.Color.light11,
                          children: "Oh noes, this Inspector issue wasn’t found."
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          textAlign: "center",
                          color: Styles.Color.light11,
                          children: "It might have been resolved already!"
                        })));
    }
    
  }
  
}

var make = InspectorIssueDetailsV2;

export {
  getSimplestAbsence ,
  getSharedIssueSourceIds ,
  LilSpyRegression ,
  LilSpyRegressionWarning ,
  Section ,
  DescriptionContainer ,
  DataTableStyles ,
  Releases ,
  IssueAcrossSources ,
  Content ,
  useRelatedIssues ,
  useIssueDetailsOpenedTracking ,
  SingleSourceIssueDetailsFlow ,
  make ,
  
}
/* chevronContainer Not a pure module */
