// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";

var rootStyles = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.padding2(Css.px(0), Css.px(10)),
        tl: /* [] */0
      }
    });

var linkStyles = Curry._1(Css.style, {
      hd: Css.display("block"),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.padding2(Css.px(10), Css.px(15)),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.backgroundColor("transparent"),
              tl: {
                hd: Css.color(Styles.Color.light12),
                tl: {
                  hd: Css.hover({
                        hd: Css.borderColor(Styles.Color.darkBlue),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.light02),
                          tl: {
                            hd: Css.color(Styles.Color.deepBlue),
                            tl: /* [] */0
                          }
                        }
                      }),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.regular),
                    tl: {
                      hd: Css.margin2(Css.px(5), Css.px(0)),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$default
                            }, undefined, undefined, "all"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function EventMetrics(Props) {
  var metrics = Props.metrics;
  return React.createElement("div", {
              className: rootStyles
            }, Belt_List.toArray(Belt_List.mapU(metrics, (function (metric) {
                        return React.createElement(Link.make, {
                                    path: Router.Link.addDrawerItem(undefined, {
                                          NAME: "metric",
                                          VAL: [
                                            metric.id,
                                            undefined
                                          ]
                                        }),
                                    className: linkStyles,
                                    children: React.createElement($$Text.make, {
                                          size: "Medium",
                                          weight: "Semi",
                                          children: metric.name === "" ? "Untitled Metric" : metric.name
                                        }),
                                    key: metric.id
                                  });
                      }))));
}

var make = EventMetrics;

export {
  rootStyles ,
  linkStyles ,
  make ,
  
}
/* rootStyles Not a pure module */
