// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import Shortid from "shortid";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameInput from "./NameInput.mjs";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as NameInputUtils from "./NameInputUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as AdvancedNameInput from "./AdvancedNameInput.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as GuardRailsConfigUtils from "./guardrails/GuardRailsConfigUtils.mjs";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as GetEventNamespaceUseCase from "./getEventNamespaceUseCase.mjs";

function isValid(name, expectedEventCase, events) {
  return NameInputUtils.isValid(NameInputUtils.validateInput(Belt_List.toArray(GetEventNamespaceUseCase.getEventNamespace(events)), name, name, expectedEventCase));
}

function createEvent(eventId, name, namingConvention, correctCase, workspace, model, sendActions, addToast, currentFilters, onSuccessOpt, extraCreateActionsOpt, schemaBundle, eventOrigin, config, param) {
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (_namedBranch) {
        
      });
  var extraCreateActions = extraCreateActionsOpt !== undefined ? extraCreateActionsOpt : (function (_eventId) {
        return [];
      });
  var areEventSourcesAndDestinationsConfigurable = AvoLimits.ConfigEventSourcesAndDestinations.isAvailable(workspace);
  var expectedEventCase = GuardRailsConfigUtils.nameComponentsToAnalyticsCase(ValidationConfigV2.EventsV2.getNameComponents(config.events));
  var eventNamingConvention = GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(ValidationConfigV2.EventsV2.getNameComponents(config.events));
  var codegenAvailability = Belt_List.head(AvoLimits.CodegenEvents.computeEntityNumericLimits(workspace, model));
  var name$1 = name.trim();
  return Curry.app(sendActions, [
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              (function (branch) {
                  Curry._1(addToast, {
                        message: React.createElement(React.Fragment, undefined, "Event ", React.createElement("em", undefined, name$1), " created 🎉"),
                        toastType: /* Success */0,
                        action: {
                          message: "View",
                          toastCallback: (function (param) {
                              return Router.Schema.pushDrawerItem(undefined, undefined, {
                                          NAME: "event",
                                          VAL: [
                                            eventId,
                                            undefined,
                                            undefined,
                                            false
                                          ]
                                        });
                            })
                        }
                      });
                  var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                  if (namingConvention === "Advanced") {
                    var componentNames = ValidationConfigV2.EventsV2.getNamesAsStructuredString(config.events);
                    var componentCases = ValidationConfigV2.EventsV2.getComponentCasesAsStructuredString(config.events);
                    AnalyticsRe.eventCreatedAdvancedNamingConvention(currentFilters, schema, eventId, name$1, correctCase, namingConvention, eventOrigin, eventNamingConvention, componentNames, componentCases, schema.branchId, schemaBundle.schemaId);
                    AnalyticsRe.eventNameUpdatedAdvancedStructure(schema, currentFilters, eventId, name$1, undefined, name$1.length, Case.to_("Sentence", name$1).split(" ").length, correctCase, componentNames, componentCases, eventId, schema.branchId, schemaBundle.schemaId, eventId, name$1);
                  } else {
                    AnalyticsRe.eventCreated(currentFilters, schema, eventId, name$1, correctCase, namingConvention, eventOrigin, expectedEventCase, eventNamingConvention, schema.branchId, schemaBundle.schemaId);
                    AnalyticsRe.eventNameUpdated(schema, currentFilters, eventId, name$1, namingConvention, undefined, name$1.length, Case.to_("Sentence", name$1).split(" ").length, correctCase, eventId, schema.branchId, schemaBundle.schemaId, eventId, name$1);
                  }
                  if (!areEventSourcesAndDestinationsConfigurable) {
                    AnalyticsRe.eventUpdated(schema, AnalyticsRe.Bundle.source("N/A", "N/A"), eventId, name$1, "AddSources", undefined, undefined, Belt_List.toArray(Belt_List.map(model.sources, (function (source) {
                                    return source.id;
                                  }))), [], undefined, Belt_List.length(BeltListExtensions.dedupeString(ModelUtils.eventsWithNameMapping(model))), Belt_List.length(ModelUtils.eventsWithNameMapping(model)), Belt_List.length(Belt_List.keep(ModelUtils.eventsWithNameMapping(model), (function (id) {
                                    return id === eventId;
                                  }))), Belt_List.length(model.events), undefined, undefined, undefined, undefined, undefined, 0, correctCase, namingConvention, eventId, schema.schemaId, schema.branchId, eventId, undefined, name$1);
                  }
                  return Curry._1(onSuccess, branch);
                }),
              undefined,
              Belt_Array.concatMany([
                    [[
                        {
                          NAME: "AddEvent",
                          VAL: [
                            eventId,
                            name$1
                          ]
                        },
                        {
                          eventId: eventId,
                          eventQuery: eventId
                        }
                      ]],
                    areEventSourcesAndDestinationsConfigurable ? [] : Belt_Array.map(Belt_List.toArray(model.sources), (function (source) {
                              return [
                                      {
                                        NAME: "IncludeEventInSourceV2",
                                        VAL: [
                                          eventId,
                                          source.id,
                                          ModelUtils.getSourceDestinationIds(source.destinations),
                                          codegenAvailability !== undefined ? codegenAvailability.NAME === "AvailableDuringTrial" : true
                                        ]
                                      },
                                      {
                                        eventId: eventId,
                                        eventQuery: eventId,
                                        sourceId: source.id,
                                        sourceQuery: source.id
                                      }
                                    ];
                            })),
                    Curry._1(extraCreateActions, eventId)
                  ])
            ]);
}

function NewEventModal(Props) {
  var currentFilters = Props.currentFilters;
  var events = Props.events;
  var model = Props.model;
  var name = Props.name;
  var onClose = Props.onClose;
  var onCreateOpt = Props.onCreate;
  var onSuccess = Props.onSuccess;
  var extraCreateActions = Props.extraCreateActions;
  var eventOrigin = Props.eventOrigin;
  var role = Props.role;
  var onCreate = onCreateOpt !== undefined ? onCreateOpt : (function (param, param$1) {
        
      });
  var workspace = WorkspaceContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var config = match.config;
  var hasBasicNameCasing = ValidationConfigV2.EventsV2.hasBasicNameCasing(config.events);
  var advancedNamingConventionToggled = Curry._1(ValidationConfigV2.Rule.isChecked, config.events.nameComponents);
  var sendActions = SendActionsContext.use(undefined);
  var advancedNamingConventionAvailable = Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention_deprecated.isAvailable, workspace);
  var hasGuardrailsForAllFeatureFlag = AppFeatureFlag.useFeatureFlag("GuardrailsForAll");
  var showAdvancedEventModal = advancedNamingConventionAvailable || hasGuardrailsForAllFeatureFlag && advancedNamingConventionToggled;
  var eventIdRef = React.useRef(Shortid());
  var workspace$1 = React.useContext(WorkspaceContext.workspaceContext);
  var eventId = eventIdRef.current;
  var correctCase = NameUtils.isCorrectCase(expectedEventCase, name);
  var match$1 = hasBasicNameCasing ? [
      ValidationConfigV2.EventsV2.getNamesAsStructuredString(config.events),
      ValidationConfigV2.EventsV2.getComponentCasesAsStructuredString(config.events)
    ] : [
      undefined,
      undefined
    ];
  var componentStructureCasings = match$1[1];
  var componentStructureNames = match$1[0];
  var expectedEventCaseAnalytics = hasBasicNameCasing ? "Advanced" : Case.toAnalytics(expectedEventCase);
  React.useEffect((function () {
          AnalyticsRe.eventInitiated(currentFilters, schemaBundle, eventId, "", correctCase, expectedEventCaseAnalytics, componentStructureNames, componentStructureCasings, schemaBundle.branchId, schemaBundle.schemaId);
          
        }), []);
  var existingNamesWithIdAndIsVariant = GetEventNamespaceUseCase.getEventNamespaceWithIdAndIsVariant(events);
  var tmp;
  if (!hasBasicNameCasing && showAdvancedEventModal) {
    var tmp$1 = {
      getItemLink: (function (eventName) {
          var match = Belt_List.getByU(existingNamesWithIdAndIsVariant, (function (param) {
                  return param[0] === eventName;
                }));
          if (match === undefined) {
            return ;
          }
          var variantEventId = match[1];
          if (!match[2]) {
            return Belt_Option.map(ModelUtils.getEventByName(eventName, model), (function (param) {
                          return Router.Link.addDrawerItem(undefined, {
                                      NAME: "event",
                                      VAL: [
                                        param.id,
                                        undefined,
                                        undefined,
                                        false
                                      ]
                                    });
                        }));
          }
          var eventId = VariantEventIdUseCase.baseEventIdFromVariantEventId(variantEventId);
          var variantId = VariantEventIdUseCase.variantIdFromVariantEventId(variantEventId);
          return Router.Link.addDrawerItem(undefined, {
                      NAME: "eventVariant",
                      VAL: [
                        {
                          baseEventId: eventId,
                          variantId: variantId
                        },
                        undefined
                      ]
                    });
        }),
      existingNames: Belt_List.map(existingNamesWithIdAndIsVariant, (function (param) {
              return param[0];
            })),
      onClose: onClose,
      onChange: (function (name, _components, _nameComponents) {
          return createEvent(eventId, name, "Advanced", correctCase, workspace$1, model, sendActions, addToast, currentFilters, (function (branch) {
                        Curry._2(onCreate, eventId, name);
                        Belt_Option.forEach(onSuccess, (function (onSuccess) {
                                return Curry._3(onSuccess, eventId, name, branch);
                              }));
                        return Curry._1(onClose, undefined);
                      }), extraCreateActions, schemaBundle, eventOrigin, config, undefined);
        }),
      eventEdit: "Create",
      role: role
    };
    var tmp$2 = name.trim() === "" ? undefined : name;
    if (tmp$2 !== undefined) {
      tmp$1.currentName = Caml_option.valFromOption(tmp$2);
    }
    tmp = React.createElement(AdvancedNameInput.make, tmp$1);
  } else {
    tmp = React.createElement(NameInput.make, {
          name: name,
          existingNames: Belt_Array.map(Belt_List.toArray(existingNamesWithIdAndIsVariant), (function (param) {
                  return param[0];
                })),
          expectedCase: expectedEventCase,
          forceCase: ValidationConfigV2.EventsV2.hasEnforcedNameCasing(config.events),
          autoFocus: true,
          onChange: (function (name, namingConvention, correctCase) {
              return createEvent(eventId, name, Case.toAnalytics(namingConvention), correctCase, workspace$1, model, sendActions, addToast, currentFilters, (function (_branch) {
                            Curry._2(onCreate, eventId, name);
                            return Curry._1(onClose, undefined);
                          }), undefined, schemaBundle, eventOrigin, config, undefined);
            }),
          submitLabel: "Create Event",
          placeholder: "Event Name",
          onClose: (function (param) {
              return Curry._1(onClose, undefined);
            }),
          fullscreen: true,
          dirty: name !== "",
          itemType: "Event",
          actionType: "Create",
          getPossibleItemLink: (function (eventName) {
              var match = Belt_List.getByU(existingNamesWithIdAndIsVariant, (function (param) {
                      return param[0] === eventName;
                    }));
              if (match === undefined) {
                return ;
              }
              var variantEventId = match[1];
              if (!match[2]) {
                return Belt_Option.map(ModelUtils.getEventByName(eventName, model), (function (param) {
                              return Router.Link.addDrawerItem(undefined, {
                                          NAME: "event",
                                          VAL: [
                                            param.id,
                                            undefined,
                                            undefined,
                                            false
                                          ]
                                        });
                            }));
              }
              var eventId = VariantEventIdUseCase.baseEventIdFromVariantEventId(variantEventId);
              var variantId = VariantEventIdUseCase.variantIdFromVariantEventId(variantEventId);
              return Router.Link.addDrawerItem(undefined, {
                          NAME: "eventVariant",
                          VAL: [
                            {
                              baseEventId: eventId,
                              variantId: variantId
                            },
                            undefined
                          ]
                        });
            })
        });
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.minWidth(Css.px(600)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: showAdvancedEventModal && !hasBasicNameCasing ? Styles.emptyStyle : Css.height(Css.px(75)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, tmp);
}

var make = NewEventModal;

export {
  isValid ,
  createEvent ,
  make ,
  
}
/* Css Not a pure module */
