// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Issues from "../../shared/models/Issues.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Intercom from "./externals/intercom.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as TextInput from "./TextInput.mjs";
import * as InputLabel from "./InputLabel.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as ModalWithArtwork from "./ModalWithArtwork.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as InspectorIssueType from "../../shared/models/InspectorIssueType.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as ConnectToSlackButton from "./inspector/ConnectToSlackButton.mjs";
import * as InspectorIssuesStore from "./inspector/InspectorIssuesStore.mjs";
import * as InspectorIssuesFilterHooks from "./inspector/InspectorIssuesFilterHooks.mjs";
import * as InspectorIssuesFilterUtils from "../../shared/utils/InspectorIssuesFilterUtils.mjs";

var container = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: {
        hd: Css.paddingRight(Css.px(30)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var box = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("row"),
            tl: {
              hd: Css.boxShadow(Styles.Shadow.box),
              tl: {
                hd: Css.gap(Css.px(8)),
                tl: {
                  hd: Css.padding(Css.px(10)),
                  tl: {
                    hd: Css.maxWidth(Css.px(220)),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var avoLogo = Curry._1(Css.style, {
      hd: Css.flexShrink(0.0),
      tl: /* [] */0
    });

var slackLogo = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.position("absolute"),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.top({
                  NAME: "subtract",
                  VAL: [
                    Css.pct(100.0),
                    Css.px(44)
                  ]
                }),
            tl: {
              hd: Css.left({
                    NAME: "subtract",
                    VAL: [
                      Css.pct(100.0),
                      Css.px(44)
                    ]
                  }),
              tl: {
                hd: Css.borderRadius(Css.px(12)),
                tl: {
                  hd: Css.padding(Css.px(8)),
                  tl: {
                    hd: Css.boxShadow(Styles.Shadow.box),
                    tl: {
                      hd: Css.width(Css.px(60)),
                      tl: {
                        hd: Css.height(Css.px(60)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function InspectorIssueAlertsSetupModal$InteractiveSlackAlertArt(Props) {
  var name = Props.name;
  var exampleIssue = Props.exampleIssue;
  var tmp;
  if (exampleIssue !== undefined) {
    var propertyName = exampleIssue.propertyName;
    tmp = propertyName !== undefined ? React.createElement($$Text.make, {
            size: "Tiny",
            weight: "Semi",
            color: Styles.Color.light11,
            children: null
          }, "Property: ", React.createElement($$Text.make, {
                element: "Span",
                weight: "Regular",
                children: propertyName
              })) : null;
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: container
            }, React.createElement("div", {
                  className: box
                }, React.createElement("svg", {
                      className: avoLogo,
                      height: "24",
                      width: "24",
                      fill: "none",
                      viewBox: "0 0 480 480",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, React.createElement("rect", {
                          height: "480",
                          width: "480",
                          fill: "#FF0EB4",
                          rx: "56"
                        }), React.createElement("path", {
                          d: "M179 382h109v-70H145V168H72v107c0 63 45 107 107 107Zm215 0h14v-70h-48V205c0-63-44-107-106-107H145v70h143v107c0 63 44 107 106 107Z",
                          fill: "#fff"
                        })), React.createElement("div", undefined, React.createElement($$Text.make, {
                          size: "Tiny",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: null
                        }, "Avo Inspector found a new issue in ", React.createElement($$Text.make, {
                              element: "Span",
                              color: Styles.Color.deepBlue,
                              children: name === "" ? "My saved view" : name
                            })), React.createElement(Spacer.make, {
                          height: 4
                        }), React.createElement($$Text.make, {
                          size: "Tiny",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: null
                        }, "Event: ", React.createElement($$Text.make, {
                              element: "Span",
                              weight: "Regular",
                              children: Belt_Option.mapWithDefault(exampleIssue, "Checkout Started", (function (param) {
                                      return param.eventName;
                                    }))
                            })), tmp, React.createElement($$Text.make, {
                          size: "Tiny",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          maxWidth: Css.pct(80.0),
                          children: null
                        }, "Issue type: ", React.createElement($$Text.make, {
                              element: "Span",
                              weight: "Regular",
                              children: Belt_Option.mapWithDefault(exampleIssue, "Event unexpected on source", (function (param) {
                                      return InspectorIssueType.getIssueTypeAsText(param.issueType);
                                    }))
                            }))), React.createElement("div", {
                      className: slackLogo
                    }, React.createElement("svg", {
                          fill: "none",
                          viewBox: "0 0 51 51",
                          xmlns: "http://www.w3.org/2000/svg"
                        }, React.createElement("path", {
                              d: "M11 32.1A5.2 5.2 0 1 1 5.8 27H11V32Zm2.7 0a5.2 5.2 0 1 1 10.5 0v13.1a5.2 5.2 0 1 1-10.5 0v-13Z",
                              fill: "#E01E5A"
                            }), React.createElement("path", {
                              d: "M19 11a5.2 5.2 0 1 1 5.2-5.2v5.3h-5.3Zm0 2.7a5.2 5.2 0 1 1 0 10.5H5.7a5.2 5.2 0 1 1 0-10.5h13.1Z",
                              fill: "#36C5F0"
                            }), React.createElement("path", {
                              d: "M40 19a5.2 5.2 0 1 1 5.2 5.2h-5.3V19Zm-2.7 0a5.2 5.2 0 1 1-10.5 0V5.8a5.2 5.2 0 1 1 10.5 0V19Z",
                              fill: "#2EB67D"
                            }), React.createElement("path", {
                              d: "M32 40a5.2 5.2 0 1 1-5.2 5.2V40h5.3Zm0-2.6a5.2 5.2 0 1 1 0-10.5h13.2a5.2 5.2 0 1 1 0 10.5H32.1Z",
                              fill: "#ECB22E"
                            })))));
}

function InspectorIssueAlertsSetupModal(Props) {
  var onClose = Props.onClose;
  var savedView = Props.savedView;
  var sendActions = SendActionsContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match.orderedBy;
  var filters = match.filters;
  var match$1 = React.useState(function () {
        return false;
      });
  var setHasClickedOtherSources = match$1[1];
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var match$2 = InspectorIssuesFilterHooks.useDraftState(undefined);
  var isDraft = match$2.isDraft;
  var match$3 = React.useState(function () {
        return Belt_Option.mapWithDefault(savedView, "", (function (param) {
                      return param.name;
                    }));
      });
  var setNameInput = match$3[1];
  var nameInput = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setIsSaving = match$4[1];
  var isSaving = match$4[0];
  var match$5 = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var status = match$5[0];
  var match$6 = InspectorIssuesStore.useFilteredIssues(status, undefined, undefined);
  var filteredIssues = match$6.filteredIssues;
  var addToast = Toast.useAddToast(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match$7 = InspectorIssuesStore.useIssues(undefined, /* Unresolved */0);
  var match$8 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var savedViewStatus = match$8.savedViewStatus;
  var viewerId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  React.useEffect((function () {
          AnalyticsRe.inspectorAlertsInitiated(schemaBundle, AnalyticsRe.Bundle.inspectorSavedViews(Belt_Option.mapWithDefault(savedView, "All issues", (function (param) {
                          return param.name;
                        })), Belt_Option.mapWithDefault(savedView, "all-issues", (function (param) {
                          return param.id;
                        })), savedView !== undefined && viewerId === savedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" ? Belt_List.size(savedViews.VAL) : -1), InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), Curry._1(Issues.size, filteredIssues), InspectorIssuesFilterUtils.savedViewStatusToViewTypeAnalytics(savedViewStatus), InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), schemaBundle.schemaId);
          
        }), []);
  var handleOtherSourcesClick = function (param) {
    AnalyticsRe.inspectorAlertsTypeSelected(schemaBundle, AnalyticsRe.Bundle.inspectorSavedViews(Belt_Option.mapWithDefault(savedView, "All issues", (function (param) {
                    return param.name;
                  })), Belt_Option.mapWithDefault(savedView, "all-issues", (function (param) {
                    return param.id;
                  })), savedView !== undefined && viewerId === savedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" ? Belt_List.size(savedViews.VAL) : -1), InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), Curry._1(Issues.size, filteredIssues), InspectorIssuesFilterUtils.savedViewStatusToViewTypeAnalytics(savedViewStatus), InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), "Other", schemaBundle.schemaId);
    return Curry._1(setHasClickedOtherSources, (function (param) {
                  return true;
                }));
  };
  var handleConnect = function (savedView) {
    if (workspace.isSandbox) {
      Curry._1(globalSend, {
            TAG: /* OpenModal */4,
            _0: "SandboxLimitation"
          });
      return Promise.resolve(undefined);
    } else {
      AnalyticsRe.inspectorAlertsTypeSelected(schemaBundle, AnalyticsRe.Bundle.inspectorSavedViews(Belt_Option.mapWithDefault(savedView, "All issues", (function (param) {
                      return param.name;
                    })), Belt_Option.mapWithDefault(savedView, "all-issues", (function (param) {
                      return param.id;
                    })), savedView !== undefined && viewerId === savedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" ? Belt_List.size(savedViews.VAL) : -1), InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), Curry._1(Issues.size, filteredIssues), InspectorIssuesFilterUtils.savedViewStatusToViewTypeAnalytics(savedViewStatus), InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), "Slack", schemaBundle.schemaId);
      return ConnectToSlackButton.ConnectToSlack.connectChannel(workspace.id, "", Belt_Option.mapWithDefault(savedView, /* InspectorIssues */0, (function (param) {
                          return /* SavedView */{
                                  _0: param.id
                                };
                        }))).then(function (param) {
                  Belt_Option.forEach(savedView, (function (savedView) {
                          return FirebaseUtils.updateSavedView(sendActions, workspace.id, savedView, viewerId, (function (param) {
                                        
                                      }), (function (param) {
                                        
                                      }));
                        }));
                  return Curry._1(onClose, undefined);
                });
    }
  };
  var handleSave = function (param) {
    Curry._1(setIsSaving, (function (param) {
            return true;
          }));
    if (savedView !== undefined) {
      return FirebaseUtils.updateSavedView(sendActions, workspace.id, {
                  id: savedView.id,
                  name: savedView.name,
                  createdBy: savedView.createdBy,
                  createdAt: savedView.createdAt,
                  lastModifiedBy: savedView.lastModifiedBy,
                  lastModifiedAt: savedView.lastModifiedAt,
                  filters: filters,
                  order: orderedBy,
                  isArchived: savedView.isArchived
                }, viewerId, (function (param) {
                    Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                              NAME: "inspector",
                              VAL: {
                                NAME: "savedView",
                                VAL: savedView.id
                              }
                            }));
                    handleConnect(savedView);
                    return AnalyticsRe.inspectorSavedViewInteraction(schemaBundle, AnalyticsRe.Bundle.inspectorSavedViews(savedView.name, savedView.id, viewerId === savedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) : -1), InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), Curry._1(Issues.size, filteredIssues), InspectorIssuesFilterUtils.savedViewStatusToViewTypeAnalytics(savedViewStatus), InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), "Save", schemaBundle.schemaId);
                  }), (function (param) {
                    Curry._1(addToast, {
                          message: "Failed to save view...",
                          toastType: /* Error */1
                        });
                    return Curry._1(setIsSaving, (function (param) {
                                  return false;
                                }));
                  }));
    } else {
      return FirebaseUtils.createSavedView(sendActions, workspace.id, nameInput, orderedBy, filters, (function (id) {
                    handleConnect({
                            id: id,
                            name: nameInput,
                            createdBy: viewerId,
                            createdAt: new Date(),
                            lastModifiedBy: viewerId,
                            lastModifiedAt: Caml_option.some(new Date()),
                            filters: filters,
                            order: orderedBy,
                            isArchived: false
                          }).finally(function (param) {
                          return Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                                          NAME: "inspector",
                                          VAL: {
                                            NAME: "savedView",
                                            VAL: id
                                          }
                                        }));
                        });
                    AnalyticsRe.inspectorSavedViewCreated(schemaBundle, AnalyticsRe.Bundle.inspectorSavedViews(nameInput, id, "Creator", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) + 1 | 0 : -1), InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), Curry._1(Issues.size, filteredIssues), "SavedView", InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), schemaBundle.schemaId, schemaBundle.schemaId, schemaBundle.schemaId, typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) + 1 | 0 : -1);
                    return Curry._1(setIsSaving, (function (param) {
                                  return false;
                                }));
                  }), (function (param) {
                    Curry._1(addToast, {
                          message: "Failed to save view...",
                          toastType: /* Error */1
                        });
                    return Curry._1(setIsSaving, (function (param) {
                                  return false;
                                }));
                  }));
    }
  };
  var saveDisabled = isSaving || nameInput === "";
  return React.createElement(ModalWithArtwork.make, {
              artwork: React.createElement(InspectorIssueAlertsSetupModal$InteractiveSlackAlertArt, {
                    name: nameInput,
                    exampleIssue: Belt_Option.mapWithDefault(Curry._2(Issues.getAtIndex, filteredIssues, 0), Curry._2(Issues.getAtIndex, match$7.issues, 0), (function (i) {
                            return i;
                          }))
                  }),
              id: "setup-alerts-modal",
              onClose: onClose,
              title: isDraft ? "Get alerts for new issues matching your filters" : "Get alerts for " + Belt_Option.mapWithDefault(savedView, "all new issues", (function (savedView) {
                        return "new issues on \"" + savedView.name + "\"";
                      })),
              children: null
            }, isDraft ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light10,
                        children: "Save this view and connect it with a Slack channel to get alerted on all issues Avo detects for your filter criteria."
                      }), savedView === undefined ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                              height: 20
                            }), React.createElement(InputLabel.make, {
                              htmlFor: "name",
                              children: "Saved view name"
                            }), React.createElement(Spacer.make, {
                              height: 4
                            }), React.createElement(TextInput.make, {
                              autoFocus: true,
                              disabled: isSaving,
                              id: "name",
                              onChange: (function (name) {
                                  return Curry._1(setNameInput, (function (param) {
                                                return name;
                                              }));
                                }),
                              placeholder: "My saved view",
                              stretch: true,
                              onEnter: (function (param) {
                                  if (saveDisabled) {
                                    return ;
                                  } else {
                                    return handleSave(undefined);
                                  }
                                }),
                              value: nameInput
                            })) : null, React.createElement(Spacer.make, {
                        height: 24
                      }), React.createElement(Button.make, {
                        disabled: saveDisabled,
                        id: "inspector-issue-alerts-setup-modal-cta",
                        label: "Save and connect Slack",
                        onClick: (function (param) {
                            return handleSave(undefined);
                          }),
                        size: "large"
                      })) : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light10,
                        children: "Connect Inspector with a Slack channel to get alerted on all issues Avo detects for this view."
                      }), React.createElement(Spacer.make, {
                        height: 24
                      }), React.createElement(Button.make, {
                        label: "Connect Slack",
                        onClick: (function (param) {
                            handleConnect(savedView);
                            
                          }),
                        size: "large"
                      })), React.createElement(Spacer.make, {
                  height: 24
                }), match$1[0] ? React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.light10,
                    children: null
                  }, "Sorry, we don't have other option available at this time but we'd love to build it for with you! ", React.createElement(TextButton.make, {
                        onClick: (function (param) {
                            return Intercom.showNewMessage(undefined);
                          }),
                        style: "Magenta",
                        children: "Let us know where you want to get notified"
                      })) : React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.light10,
                    children: null
                  }, "Not using Slack? ", React.createElement(TextButton.make, {
                        onClick: handleOtherSourcesClick,
                        style: "Magenta",
                        children: "Other options"
                      })));
}

var make = InspectorIssueAlertsSetupModal;

export {
  make ,
  
}
/* container Not a pure module */
