// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Quill from "./externals/quill.mjs";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Timestamp from "./Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as QuillEditor from "./QuillEditor.mjs";
import * as CommentInput from "./CommentInput.mjs";
import * as ProfilePhoto from "./ProfilePhoto.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as FramerMotion from "framer-motion";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as IconAddComment from "./IconAddComment.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";

function commentContainerStyles(isActive) {
  return Curry._1(Css.style, {
              hd: Css.border(Css.px(2), "solid", isActive ? Styles.Color.darkBlue : "transparent"),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: /* [] */0
              }
            });
}

function headerStyles(firstComment) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("spaceBetween"),
                  tl: {
                    hd: Css.padding4(Css.px(12), Css.px(12), Css.px(0), Css.px(20)),
                    tl: firstComment ? /* [] */0 : ({
                          hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
                          tl: /* [] */0
                        })
                  }
                }
              }
            });
}

var authorContainerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var authorNameStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(12)),
      tl: /* [] */0
    });

var contentContainerStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(0), Css.px(20), Css.px(4), Css.px(20)),
      tl: {
        hd: Css.marginTop(Css.px(-4)),
        tl: {
          hd: Css.wordWrap("breakWord"),
          tl: /* [] */0
        }
      }
    });

function DiffComments$DiffComment(Props) {
  var comment = Props.comment;
  var firstComment = Props.firstComment;
  var schemaId = Props.schemaId;
  var isFocused = Props.isFocused;
  var userId = Props.userId;
  var threadId = Props.threadId;
  var itemType = Props.itemType;
  var itemName = Props.itemName;
  var events = Props.events;
  var match = React.useState(function () {
        return false;
      });
  var setHighlighted = match[1];
  var highlighted = match[0];
  var author = FirebaseFetcherHooks.useUser(comment.authorId);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var tmp;
  if (typeof author === "object") {
    var user = author.VAL;
    tmp = React.createElement("div", {
          className: authorContainerStyles
        }, React.createElement(ProfilePhoto.make, {
              user: user,
              size: 24
            }), React.createElement("div", {
              className: authorNameStyles
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Medium",
                  children: AvoConfig.getUserDisplayName(user)
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontSize(Styles.FontSize.small),
                        tl: {
                          hd: Css.color(Styles.Color.light08),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.regular),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(Timestamp.make, {
                      date: comment.createdAt
                    }))));
  } else {
    tmp = author === "NotFound" ? React.createElement($$Text.make, {
            size: "Medium",
            weight: "Medium",
            color: Styles.Color.light08,
            children: "Author unknown"
          }) : React.createElement("div", {
            className: Curry._1(Css.merge, {
                  hd: Curry._1(Css.style, Styles.shimmer),
                  tl: {
                    hd: Curry._1(Css.style, {
                          hd: Css.height(Css.px(4)),
                          tl: {
                            hd: Css.width(Css.px(64)),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.margin2(Css.px(16), Css.px(0)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                })
          });
  }
  return React.createElement("div", {
              ref: (function (commentRef) {
                  var match = isFocused && !highlighted;
                  if (match && !(commentRef == null)) {
                    commentRef.scrollIntoView({
                          block: "start"
                        });
                    return Curry._1(setHighlighted, (function (param) {
                                  return true;
                                }));
                  }
                  
                }),
              className: commentContainerStyles(isFocused)
            }, React.createElement("div", {
                  className: headerStyles(firstComment)
                }, tmp, React.createElement(ContextMenu.V2.make, {
                      position: "bottom-end",
                      children: null
                    }, React.createElement(ContextMenu.V2.Item.make, {
                          label: "Copy URL to comment",
                          onClick: (function (param) {
                              var match = Router.getStaticRoute(undefined).schemaRoute;
                              var tmp;
                              var exit = 0;
                              if (match !== undefined && typeof match === "object" && match.NAME === "diff") {
                                var match$1 = match.VAL;
                                if (typeof match$1 === "object" && match$1.NAME === "implementation") {
                                  var match$2 = match$1.VAL;
                                  if (typeof match$2 === "object" && match$2.NAME === "source") {
                                    tmp = {
                                      NAME: "diff",
                                      VAL: {
                                        NAME: "implementation",
                                        VAL: {
                                          NAME: "source",
                                          VAL: [
                                            match$2.VAL[0],
                                            comment.id
                                          ]
                                        }
                                      }
                                    };
                                  } else {
                                    exit = 1;
                                  }
                                } else {
                                  exit = 1;
                                }
                              } else {
                                exit = 1;
                              }
                              if (exit === 1) {
                                tmp = {
                                  NAME: "diff",
                                  VAL: {
                                    NAME: "comment",
                                    VAL: comment.id
                                  }
                                };
                              }
                              CopyTextToClipboard(Router.getOrigin(undefined) + Router.Link.getSchemaRouteLink(undefined, tmp));
                              
                            })
                        }), comment.authorId === userId ? React.createElement(ContextMenu.V2.Item.make, {
                            label: "Delete comment",
                            onClick: (function (param) {
                                var deleteCommentBatch = Firebase.app(undefined).firestore().batch();
                                var commentId = comment.id;
                                var commentRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("comments").doc(threadId).collection("items").doc(commentId);
                                deleteCommentBatch.delete(commentRef);
                                var action_1 = [
                                  threadId,
                                  commentId
                                ];
                                var action = {
                                  NAME: "DeleteComment",
                                  VAL: action_1
                                };
                                var actionContext = {
                                  commentId: commentId,
                                  commentQuery: commentId
                                };
                                return Curry.app(sendActions, [
                                            undefined,
                                            false,
                                            Caml_option.some(deleteCommentBatch),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (branch) {
                                                var userMentions = Belt_Array.keepMap(JSON.parse(comment.content).ops, (function (op) {
                                                        return Belt_Option.flatMap(Caml_option.undefined_to_opt(op.insert), (function (prim) {
                                                                      return Caml_option.undefined_to_opt(prim["user-mention"]);
                                                                    }));
                                                      }));
                                                var eventMentions = Belt_Array.keepMap(JSON.parse(comment.content).ops, (function (op) {
                                                        return Belt_Option.flatMap(Caml_option.undefined_to_opt(op.insert), (function (prim) {
                                                                      return Caml_option.undefined_to_opt(prim["event-mention"]);
                                                                    }));
                                                      }));
                                                return AnalyticsRe.commentDeleted(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), comment.id, Belt_Option.getWithDefault(Caml_option.undefined_to_opt(comment.eventId), "N/A"), itemType === "Property" ? threadId : undefined, itemName, itemType === "Integration" ? "Integration" : (
                                                              itemType === "Property" ? "Property" : (
                                                                  itemType === "Event" ? "Event" : (
                                                                      itemType === "PropertyGroup" ? "PropertyGroup" : (
                                                                          itemType === "Goal" ? "Goal" : (
                                                                              itemType === "InspectorIssue" ? "InspectorIssue" : (
                                                                                  itemType === "Metric" ? "Metric" : "Branch"
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            ), comment.authorId, userMentions.length, eventMentions.length, Belt_Array.map(userMentions, (function (prim) {
                                                                  return prim.id;
                                                                })), Belt_Array.map(eventMentions, (function (prim) {
                                                                  return prim.id;
                                                                })), Belt_Array.map(userMentions, (function (prim) {
                                                                  return prim.value;
                                                                })), Belt_Array.map(eventMentions, (function (prim) {
                                                                  return prim.value;
                                                                })), typeof author === "object" && author.NAME === "Success" ? AvoConfig.getUserDisplayName(author.VAL) : undefined, itemType === "Metric" ? threadId : undefined, itemType === "Integration" ? threadId : undefined, NamedBranch.getId(branch), schemaBundle.schemaId);
                                              }),
                                            undefined,
                                            [[
                                                action,
                                                actionContext
                                              ]]
                                          ]);
                              })
                          }) : null)), React.createElement("div", {
                  className: contentContainerStyles
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      children: React.createElement("span", {
                            dangerouslySetInnerHTML: {
                              __html: Quill.toHTML(JSON.parse(comment.content), events, undefined)
                            }
                          })
                    })));
}

var DiffComment = {
  commentContainerStyles: commentContainerStyles,
  headerStyles: headerStyles,
  authorContainerStyles: authorContainerStyles,
  authorNameStyles: authorNameStyles,
  contentContainerStyles: contentContainerStyles,
  make: DiffComments$DiffComment
};

function containerStyles(stickyTop) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.px(394)),
              tl: {
                hd: Css.flexShrink(0.0),
                tl: {
                  hd: Css.alignSelf("flexStart"),
                  tl: {
                    hd: Css.position("sticky"),
                    tl: {
                      hd: Css.top(Css.px(stickyTop)),
                      tl: {
                        hd: Css.marginLeft(Css.px(20)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var commentBubbleStyles = Curry._1(Css.style, Belt_List.concat(Styles.buttonReset, {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: {
                hd: Css.width(Css.px(40)),
                tl: {
                  hd: Css.height(Css.px(40)),
                  tl: {
                    hd: Css.borderRadius(Css.pct(100)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light08),
                        tl: {
                          hd: Css_Legacy_Core.SVG.fill(Styles.Color.light08),
                          tl: {
                            hd: Css.cursor("pointer"),
                            tl: {
                              hd: Css.outlineStyle("none"),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$default
                                    }, undefined, undefined, "all"),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                  tl: {
                                    hd: Css.focus({
                                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.setAlpha(Styles.Color.avoPink, 0.5))),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.backgroundColor(Styles.Color.light02),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.backgroundColor(Styles.Color.light02),
                                            tl: {
                                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.avoPink),
                                              tl: {
                                                hd: Css_Legacy_Core.SVG.fill(Styles.Color.avoPink),
                                                tl: /* [] */0
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }));

var commentsContainerStyles = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.transition({
                NAME: "ms",
                VAL: Styles.Duration.$$default
              }, undefined, undefined, "box-shadow"),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.hover({
                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(32), undefined, undefined, Css.rgba(0, 0, 0, {
                                  NAME: "num",
                                  VAL: 0.05
                                }))),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

function inputContainerStyles(noComments) {
  return Curry._1(Css.style, {
              hd: Css.backgroundColor(Styles.Color.white),
              tl: {
                hd: Css.paddingTop(Css.px(8)),
                tl: {
                  hd: Css.paddingRight(Css.px(14)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(8)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(10)),
                      tl: {
                        hd: Css.borderTopLeftRadius(noComments ? Styles.Border.radius : Css.px(0)),
                        tl: {
                          hd: Css.borderTopRightRadius(noComments ? Styles.Border.radius : Css.px(0)),
                          tl: {
                            hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.borderBottomRightRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.borderTop(Css.px(noComments ? 0 : 1), "solid", Styles.Color.light02),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function DiffComments(Props) {
  var branchId = Props.branchId;
  var threadId = Props.threadId;
  var itemType = Props.itemType;
  var itemName = Props.itemName;
  var goToCommentId = Props.goToCommentId;
  var stickyTopOpt = Props.stickyTop;
  var events = Props.events;
  var stickyTop = stickyTopOpt !== undefined ? stickyTopOpt : 20;
  var match = WorkspaceContext.use(undefined);
  var schemaId = match.id;
  var comments = FirebaseFetcherHooks.useComments(schemaId, branchId, threadId);
  var userId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  var member = FirebaseFetcherHooks.useMember(schemaId, userId, false);
  var match$1 = React.useState(function () {
        return false;
      });
  var setActive = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setHasInput = match$2[1];
  var hasInput = match$2[0];
  React.useEffect((function () {
          var handleClick = function (param) {
            if (!hasInput) {
              return Curry._1(setActive, (function (param) {
                            return false;
                          }));
            }
            
          };
          window.addEventListener("click", handleClick);
          return (function (param) {
                    window.removeEventListener("click", handleClick);
                    
                  });
        }), [hasInput]);
  var tmp;
  var exit = 0;
  if (comments || match$1[0]) {
    exit = 1;
  } else {
    tmp = React.createElement("button", {
          className: commentBubbleStyles,
          onClick: (function ($$event) {
              $$event.stopPropagation();
              return Curry._1(setActive, (function (param) {
                            return true;
                          }));
            })
        }, React.createElement(IconAddComment.make, {
              size: 16
            }));
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: commentsContainerStyles
        }, Belt_List.toArray(Belt_List.mapWithIndexU(comments, (function (index, comment) {
                    return React.createElement(DiffComments$DiffComment, {
                                comment: comment,
                                firstComment: index === 0,
                                schemaId: schemaId,
                                isFocused: Caml_obj.caml_equal(comment.id, goToCommentId),
                                userId: userId,
                                threadId: threadId,
                                itemType: itemType,
                                itemName: itemName,
                                events: events,
                                key: comment.id
                              });
                  }))), typeof member === "object" ? React.createElement(FramerMotion.AnimatePresence, {
                children: React.createElement("div", {
                      className: inputContainerStyles(comments === /* [] */0),
                      onClick: (function ($$event) {
                          $$event.preventDefault();
                          $$event.stopPropagation();
                          
                        })
                    }, React.createElement(CommentInput.make, {
                          threadId: threadId,
                          itemType: itemType,
                          itemName: itemName,
                          mini: true,
                          currentBranch: {
                            NAME: "Branch",
                            VAL: branchId
                          },
                          role: Belt_Option.getWithDefault(Models.Role.tFromJs(member.VAL.role), "NoAccess"),
                          autoFocus: comments === /* [] */0,
                          onChange: (function (delta) {
                              return Curry._1(setHasInput, (function (param) {
                                            return !QuillEditor.isEmpty(delta);
                                          }));
                            }),
                          location: "BranchDiff"
                        }))
              }) : (
            member === "NotFound" ? null : React.createElement(LoadingCircle.make, {})
          ));
  }
  return React.createElement("div", {
              className: containerStyles(stickyTop)
            }, tmp);
}

var commentsWidth = 394;

var make = DiffComments;

export {
  commentsWidth ,
  DiffComment ,
  containerStyles ,
  commentBubbleStyles ,
  commentsContainerStyles ,
  inputContainerStyles ,
  make ,
  
}
/* authorContainerStyles Not a pure module */
