// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoModel from "./avoModel.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameInput from "./NameInput.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as PortalMenu from "./PortalMenu.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as KeyListener from "./KeyListener.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as PropertyInput from "./PropertyInput.mjs";
import * as FramerMotion from "framer-motion";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as PropertiesEmpty from "./emptystates/PropertiesEmpty.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SheetSectionFooter from "./SheetSectionFooter.mjs";
import * as SheetSectionHeader from "./SheetSectionHeader.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as PropertyGroupsEmpty from "./emptystates/PropertyGroupsEmpty.mjs";
import * as PropertiesSheetPropertyRow from "./PropertiesSheetPropertyRow.mjs";

function PropertiesSheetRow$AddPropertyGroupButton(Props) {
  var model = Props.model;
  var sendActions = Props.sendActions;
  var propertyGroup = Props.propertyGroup;
  var schemaBundle = Props.schemaBundle;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var outerRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var onEscape = React.useCallback((function (param) {
          return Curry._1(setIsOpen, (function (param) {
                        return false;
                      }));
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(KeyListener.make, {
                  keyName: "Escape",
                  onPress: onEscape
                }), React.createElement("div", {
                  ref: outerRef,
                  className: Curry._1(Css.style, {
                        hd: Css.paddingTop(Css.px(3)),
                        tl: {
                          hd: Css.cursor("pointer"),
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (param) {
                      return Curry._1(setIsOpen, (function (isOpen) {
                                    return !isOpen;
                                  }));
                    })
                }, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.darkBlue,
                      children: "+ Add Property"
                    })), React.createElement(PortalMenu.make, {
                  position: "Left",
                  distance: 0,
                  visible: match[0],
                  onClose: (function (param) {
                      return Curry._1(setIsOpen, (function (param) {
                                    return false;
                                  }));
                    }),
                  referenceItemRef: outerRef,
                  children: React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.pct(100)),
                              tl: {
                                hd: Css.marginTop(Css.px(-16)),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement(PropertyInput.make, {
                            propertyType: /* EventProperty */1,
                            currentProperties: Belt_List.flatten(Belt_List.map(model.propertyBundles, (function (group) {
                                        return Belt_List.map(group.properties, (function (propertyId) {
                                                      return {
                                                              TAG: /* PropertyRef */0,
                                                              _0: {
                                                                id: propertyId,
                                                                description: "",
                                                                pinnedValue: undefined
                                                              }
                                                            };
                                                    }));
                                      }))),
                            currentGroups: [],
                            options: [[
                                undefined,
                                Belt_Array.map(Belt_List.toArray(AvoModel.splitProperties(model.properties).props), (function (item) {
                                        return {
                                                NAME: "Property",
                                                VAL: item
                                              };
                                      }))
                              ]],
                            onSelect: (function (item, param) {
                                if (item.NAME === "Group") {
                                  return Pervasives.failwith("Property bundles cannot be added to other property bundles.");
                                }
                                var property = item.VAL;
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (branch) {
                                                return AnalyticsRe.propertyGroupUpdated(AnalyticsRe.Bundle.propertyGroup(propertyGroup.id, propertyGroup.name, Belt_List.length(propertyGroup.properties)), AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "AddProperty", undefined, property.id, NamedBranch.getId(branch), schemaBundle.schemaId);
                                              }),
                                            undefined,
                                            [[
                                                {
                                                  NAME: "AddPropertyToGroup",
                                                  VAL: [
                                                    propertyGroup.id,
                                                    property.id
                                                  ]
                                                },
                                                {
                                                  propertyId: property.id,
                                                  propertyQuery: property.id,
                                                  propertyGroupId: propertyGroup.id,
                                                  propertyGroupQuery: propertyGroup.id
                                                }
                                              ]]
                                          ]);
                              }),
                            canCreate: true,
                            onCreateSuccess: (function (propertyId, _propertyName, _branch) {
                                return Router.Schema.pushDrawerItem(undefined, undefined, {
                                            NAME: "property",
                                            VAL: [
                                              propertyId,
                                              undefined
                                            ]
                                          });
                              }),
                            currentFilters: currentFilters,
                            renderButton: (function (param, buttonRef) {
                                return React.createElement("div", {
                                            ref: buttonRef
                                          });
                              }),
                            keepExpanded: true,
                            parent: {
                              NAME: "PropertyGroup",
                              VAL: propertyGroup.id
                            },
                            sendActions: sendActions,
                            filters: filters,
                            propertyLocation: "PropertiesView"
                          }))
                }));
}

var AddPropertyGroupButton = {
  make: PropertiesSheetRow$AddPropertyGroupButton
};

function PropertiesSheetRow(Props) {
  var index = Props.index;
  var domStyle = Props.style;
  var data = Props.data;
  var match = React.useState(function () {
        return false;
      });
  var setEditTitleOpen = match[1];
  var popupAnchorRef = React.useRef(null);
  var match$1 = React.useContext(DiscrepancyContext.context);
  var match$2 = Belt_Array.getExn(data, index);
  var eventsSendingPropertiesMap = match$2.eventsSendingPropertiesMap;
  var viewerCanEdit = match$2.viewerCanEdit;
  var schemaBundle = match$2.schemaBundle;
  var sendActions = match$2.sendActions;
  var globalSend = match$2.globalSend;
  var model = match$2.model;
  var columns = match$2.columns;
  var item = match$2.item;
  var tmp;
  if (typeof item === "number") {
    switch (item) {
      case /* CategoryFooter */0 :
          tmp = React.createElement(SheetSectionFooter.make, {
                columns: columns,
                key: "footer-" + String(index)
              });
          break;
      case /* EmptyState */1 :
          tmp = React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.overflow("hidden"),
                      tl: /* [] */0
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("relative"),
                          tl: /* [] */0
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(SheetSectionHeader.make, {
                            columns: columns,
                            children: React.createElement($$Text.make, {
                                  size: "Medium",
                                  weight: "Semi",
                                  singleLine: true,
                                  color: Styles.Color.light08,
                                  children: "Property Type"
                                })
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(PropertiesSheetPropertyRow.Empty.make, {
                            propertyName: "Property No. 1",
                            columns: columns
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(PropertiesSheetPropertyRow.Empty.make, {
                            propertyName: "Property No. 2",
                            columns: columns
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(PropertiesSheetPropertyRow.Empty.make, {
                            propertyName: "Property No. 3",
                            columns: columns
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("absolute"),
                              tl: {
                                hd: Css.background({
                                      NAME: "linearGradient",
                                      VAL: [
                                        {
                                          NAME: "deg",
                                          VAL: 180.0
                                        },
                                        [
                                          [
                                            Css.pct(0.0),
                                            Styles.Color.setAlpha(Styles.Color.light02, 0.0)
                                          ],
                                          [
                                            Css.pct(100.0),
                                            Styles.Color.setAlpha(Styles.Color.light02, 1.0)
                                          ]
                                        ]
                                      ]
                                    }),
                                tl: {
                                  hd: Css.bottom(Css.px(0)),
                                  tl: {
                                    hd: Css.width(Css.pct(100.0)),
                                    tl: {
                                      hd: Css.height(Css.px(70)),
                                      tl: {
                                        hd: Css.zIndex(2),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      })), React.createElement(PropertiesEmpty.make, {
                    viewerCanEdit: viewerCanEdit
                  }));
          break;
      case /* PropertyGroupEmptyState */2 :
          tmp = React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.overflow("hidden"),
                      tl: /* [] */0
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("relative"),
                          tl: /* [] */0
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(SheetSectionHeader.make, {
                            columns: columns,
                            children: React.createElement($$Text.make, {
                                  size: "Medium",
                                  weight: "Semi",
                                  singleLine: true,
                                  color: Styles.Color.light08,
                                  children: "Event Property Bundle"
                                })
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(PropertiesSheetPropertyRow.Empty.make, {
                            propertyName: "Property No. 1",
                            columns: columns
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(PropertiesSheetPropertyRow.Empty.make, {
                            propertyName: "Property No. 2",
                            columns: columns
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(PropertiesSheetPropertyRow.Empty.make, {
                            propertyName: "Property No. 3",
                            columns: columns
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("absolute"),
                              tl: {
                                hd: Css.background({
                                      NAME: "linearGradient",
                                      VAL: [
                                        {
                                          NAME: "deg",
                                          VAL: 180.0
                                        },
                                        [
                                          [
                                            Css.pct(0.0),
                                            Styles.Color.setAlpha(Styles.Color.light02, 0.0)
                                          ],
                                          [
                                            Css.pct(100.0),
                                            Styles.Color.setAlpha(Styles.Color.light02, 1.0)
                                          ]
                                        ]
                                      ]
                                    }),
                                tl: {
                                  hd: Css.bottom(Css.px(0)),
                                  tl: {
                                    hd: Css.width(Css.pct(100.0)),
                                    tl: {
                                      hd: Css.height(Css.px(70)),
                                      tl: {
                                        hd: Css.zIndex(2),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      })), React.createElement(PropertyGroupsEmpty.make, {
                    viewerCanEdit: viewerCanEdit
                  }));
          break;
      
    }
  } else {
    switch (item.TAG | 0) {
      case /* CategoryHeader */0 :
          var count = item._3;
          var sendAs = item._1;
          var title = item._0;
          var tmp$1;
          if (typeof sendAs === "number") {
            switch (sendAs) {
              case /* SystemProperty */0 :
                  tmp$1 = "System Property";
                  break;
              case /* EventProperty */1 :
                  tmp$1 = "Event Property";
                  break;
              case /* UserProperty */2 :
                  tmp$1 = "User Property";
                  break;
              
            }
          } else {
            tmp$1 = "Group Property";
          }
          var tmp$2;
          if (typeof sendAs === "number") {
            tmp$2 = null;
          } else {
            var groupTypeId = sendAs._0;
            tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.cursor("pointer"),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.children({
                                          hd: Css.color(Styles.Color.red),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.children({
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$default
                                          }, undefined, undefined, "color"),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }),
                      onClick: (function (param) {
                          if (window.confirm("Are you sure you want to archive the Group Type " + title + " and all it's properties?")) {
                            return Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [[
                                            {
                                              NAME: "Archive",
                                              VAL: {
                                                NAME: "GroupType",
                                                VAL: groupTypeId
                                              }
                                            },
                                            {
                                              groupTypeId: groupTypeId,
                                              groupTypeQuery: groupTypeId
                                            }
                                          ]]
                                      ]);
                          }
                          
                        })
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light08,
                          children: "Archive"
                        })));
          }
          var tmp$3;
          if (typeof sendAs === "number") {
            tmp$3 = React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  singleLine: true,
                  color: Styles.Color.light12,
                  children: title
                });
          } else {
            var groupTypeId$1 = sendAs._0;
            tmp$3 = React.createElement(TextButton.make, {
                  onClick: (function (param) {
                      return Curry._1(setEditTitleOpen, (function (param) {
                                    return true;
                                  }));
                    }),
                  style: "Black",
                  size: "Medium",
                  children: null
                }, React.createElement("span", {
                      ref: popupAnchorRef
                    }), title, React.createElement(PortalMenu.make, {
                      position: "Right",
                      distance: 0,
                      visible: match[0],
                      onClose: (function (param) {
                          return Curry._1(setEditTitleOpen, (function (param) {
                                        return false;
                                      }));
                        }),
                      referenceItemRef: popupAnchorRef,
                      children: React.createElement(FramerMotion.motion.div, {
                            animate: {
                              opacity: 1,
                              scale: 1
                            },
                            transition: {
                              duration: 0.2
                            },
                            initial: {
                              opacity: 0,
                              scale: 0.95
                            },
                            exit: {
                              opacity: 0,
                              scale: 0.95
                            },
                            className: Curry._1(Css.style, {
                                  hd: Css.width(Css.px(600)),
                                  tl: {
                                    hd: Css.height(Css.px(75)),
                                    tl: {
                                      hd: Css.position("relative"),
                                      tl: {
                                        hd: Css.marginLeft(Css.px(-20)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }),
                            key: "implementation-status-popup",
                            children: null
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.position("absolute"),
                                      tl: {
                                        hd: Css.top(Css.px(0)),
                                        tl: {
                                          hd: Css.right(Css.px(0)),
                                          tl: {
                                            hd: Css.bottom(Css.px(-50)),
                                            tl: {
                                              hd: Css.left(Css.px(0)),
                                              tl: {
                                                hd: Css.borderRadius(Styles.Border.radius),
                                                tl: {
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(16), undefined, undefined, Css.rgba(0, 0, 0, {
                                                                NAME: "num",
                                                                VAL: 0.1
                                                              }))),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    })
                              }), React.createElement(NameInput.make, {
                                name: title,
                                existingNames: Belt_Array.map(model.groupTypes, (function (group) {
                                        return group.name;
                                      })),
                                expectedCase: match$1.expectedPropertyCase,
                                forceCase: Curry._1(ValidationConfigV2.Rule.isEnforced, match$1.config.properties.case),
                                autoFocus: true,
                                onChange: (function (name, _namingConvention, _correctCase) {
                                    Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          [[
                                              {
                                                NAME: "UpdateGroupTypeName",
                                                VAL: [
                                                  groupTypeId$1,
                                                  name
                                                ]
                                              },
                                              {
                                                groupTypeId: groupTypeId$1,
                                                groupTypeQuery: groupTypeId$1
                                              }
                                            ]]
                                        ]);
                                    return Curry._1(setEditTitleOpen, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                submitLabel: "Rename Group Type",
                                placeholder: "Group Type",
                                onClose: (function (param) {
                                    return Curry._1(setEditTitleOpen, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                fullscreen: true,
                                itemType: "GroupType",
                                actionType: "Rename"
                              }))
                    }));
          }
          tmp = React.createElement(SheetSectionHeader.make, {
                columns: columns,
                label: item._2,
                pillText: count === 1 ? "1 property" : String(count) + " properties",
                children: null,
                extra: React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingTop(Css.px(3)),
                            tl: {
                              hd: Css.display("flex"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("span", {
                          className: Curry._1(Css.style, {
                                hd: Css.cursor("pointer"),
                                tl: /* [] */0
                              }),
                          onClick: (function (param) {
                              return Curry._1(globalSend, {
                                          TAG: /* OpenModal */4,
                                          _0: {
                                            NAME: "NewProperty",
                                            VAL: [
                                              "",
                                              sendAs,
                                              undefined,
                                              (function (propertyId, _propertyName) {
                                                  return Router.Schema.pushDrawerItem(undefined, undefined, {
                                                              NAME: "property",
                                                              VAL: [
                                                                propertyId,
                                                                undefined
                                                              ]
                                                            });
                                                }),
                                              "PropertiesView"
                                            ]
                                          }
                                        });
                            })
                        }, React.createElement($$Text.make, {
                              element: "Span",
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.darkBlue,
                              children: "+ New " + tmp$1
                            })), tmp$2),
                key: title
              }, tmp$3, React.createElement(Spacer.make, {
                    width: 8
                  }));
          break;
      case /* EventPropertyGroupHeader */1 :
          var count$1 = item._1;
          var propertyGroup = item._0;
          tmp = React.createElement(SheetSectionHeader.make, {
                columns: columns,
                label: "EVENT PROPERTY BUNDLE",
                pillText: count$1 === 1 ? "1 property" : String(count$1) + " properties",
                children: null,
                extra: React.createElement(PropertiesSheetRow$AddPropertyGroupButton, {
                      model: model,
                      sendActions: sendActions,
                      propertyGroup: propertyGroup,
                      schemaBundle: schemaBundle,
                      currentFilters: match$2.currentFilters,
                      filters: match$2.filters
                    }),
                key: propertyGroup.name
              }, React.createElement(Link.make, {
                    path: item._2,
                    children: React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          singleLine: true,
                          color: Styles.Color.light12,
                          children: propertyGroup.name
                        })
                  }), React.createElement(Spacer.make, {
                    width: 8
                  }));
          break;
      case /* Property */2 :
          var property = item._0;
          tmp = React.createElement(PropertiesSheetPropertyRow.make, {
                columns: columns,
                model: model,
                onClick: (function (param) {
                    return Router.Schema.pushDrawerItem(undefined, undefined, {
                                NAME: "property",
                                VAL: [
                                  property.id,
                                  undefined
                                ]
                              });
                  }),
                property: property,
                schemaBundle: schemaBundle,
                selected: false,
                sendActions: sendActions,
                viewerCanEdit: viewerCanEdit,
                withDetails: false,
                eventsSendingPropertiesMap: eventsSendingPropertiesMap,
                key: property.id
              });
          break;
      case /* EventGroupProperty */3 :
          var property$1 = item._0;
          tmp = React.createElement(PropertiesSheetPropertyRow.make, {
                columns: columns,
                model: model,
                onClick: (function (param) {
                    return Router.Schema.pushDrawerItem(undefined, undefined, {
                                NAME: "property",
                                VAL: [
                                  property$1.id,
                                  undefined
                                ]
                              });
                  }),
                property: property$1,
                schemaBundle: schemaBundle,
                selected: false,
                sendActions: sendActions,
                viewerCanEdit: viewerCanEdit,
                withDetails: false,
                eventsSendingPropertiesMap: eventsSendingPropertiesMap,
                key: property$1.id
              });
          break;
      case /* Empty */4 :
          tmp = React.createElement(Spacer.make, {
                height: item._0
              });
          break;
      case /* Info */5 :
          tmp = Curry._1(item._0, Belt_Array.getExn(data, index));
          break;
      
    }
  }
  return React.createElement("div", {
              style: {
                height: domStyle.height,
                left: domStyle.left,
                position: domStyle.position,
                top: (domStyle.top + 16 | 0).toString() + "px",
                width: domStyle.width
              }
            }, tmp);
}

var make = PropertiesSheetRow;

export {
  AddPropertyGroupButton ,
  make ,
  
}
/* Css Not a pure module */
