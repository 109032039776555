// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DocsLink from "./DocsLink.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AvoConfigTypes from "../../shared/models/AvoConfigTypes.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as SourceConfigOverview from "./SourceConfigOverview.mjs";

var listContainer = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.overflow("hidden"),
            tl: {
              hd: Css.marginBottom(Css.px(6)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var rowItem = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.transition({
                    NAME: "ms",
                    VAL: Styles.Duration.$$default
                  }, undefined, undefined, "all"),
              tl: {
                hd: Css.padding2(Css.px(12), Css.px(12)),
                tl: {
                  hd: Css.lastChild({
                        hd: Css.borderBottomWidth(Css.px(0)),
                        tl: {
                          hd: Css.paddingBottom(Css.px(10)),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var iconContainer = Curry._1(Css.style, {
      hd: Css.alignItems("center"),
      tl: {
        hd: Css.justifyContent("center"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.height(Css.px(24)),
            tl: {
              hd: Css.width(Css.px(24)),
              tl: {
                hd: Css.marginRight(Css.px(8)),
                tl: {
                  hd: Css.overflow("hidden"),
                  tl: {
                    hd: Css.borderRadius(Css.px(6)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.light08),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var destinationLink = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.light12),
      tl: {
        hd: Css.hover({
              hd: Css.color(Styles.Color.light12),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.whiteSpace("nowrap"),
            tl: {
              hd: Css.marginRight(Css.px(8)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var left = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: {
            hd: Css.alignItems("center"),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  listContainer: listContainer,
  rowItem: rowItem,
  iconContainer: iconContainer,
  destinationLink: destinationLink,
  left: left
};

function SourceConfigCodegenDestinations(Props) {
  var currentBranch = Props.currentBranch;
  var model = Props.model;
  var sendActions = Props.sendActions;
  var source = Props.source;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var allSupportedModelDestinations = AvoUtils.getDestinationsSupport(source.platform, source.language, model.destinations);
  var destinations = Belt_List.toArray(Belt_List.keepMapU(source.destinations, (function (destinationRef) {
              var destinationId = destinationRef.destinationId;
              var isDestinationSupported = Belt_List.someU(allSupportedModelDestinations, (function (param) {
                      if (param[1].id === destinationId) {
                        return param[2];
                      } else {
                        return false;
                      }
                    }));
              var maybeDestination = ModelUtils.getDestinationById(destinationId, model);
              if (isDestinationSupported) {
                return Belt_Option.map(maybeDestination, (function (destination) {
                              return [
                                      destination,
                                      destinationRef
                                    ];
                            }));
              }
              
            })));
  return React.createElement("div", undefined, React.createElement($$Text.make, {
                  element: "H1",
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: "Destinations Interface"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Regular",
                  color: Styles.Color.light11,
                  maxWidth: Css.px(465),
                  children: React.createElement("span", {
                        className: Curry._1(Css.style, {
                              hd: Css.children({
                                    hd: Css.display("inline"),
                                    tl: {
                                      hd: Css.fontSize(Css.px(11)),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: /* [] */0
                            })
                      }, "Avo can automatically configure the destinations for you. Alternatively, you can manage them yourself with the destination interface (recommended). Read more in the ", React.createElement(DocsLink.make, {
                            path: "/data-design/define-sources-and-destinations#destination-interface",
                            label: "docs"
                          }))
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: listContainer
                }, Belt_Array.mapU(destinations, (function (param) {
                        var destinationMode = param[1].destinationMode;
                        var destination = param[0];
                        var destinationIsAvoManagedSupported = Belt_Array.getBy(AvoConfig.getSupportedAvoManagedDestinations(source.platform, source.language), (function (destinationType) {
                                return Caml_obj.caml_equal(destination.type_, destinationType);
                              })) !== undefined && Caml_obj.caml_notequal(destination.type_, "Custom");
                        var destination$1 = destination.type_;
                        var destinationType = destination.type_;
                        var tmp;
                        if (destinationIsAvoManagedSupported || Caml_obj.caml_equal(destination.type_, "Custom")) {
                          tmp = null;
                        } else {
                          var match = source.language;
                          var match$1 = destination.type_;
                          tmp = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                    size: "Small",
                                    color: Styles.Color.light08,
                                    children: match !== undefined ? (
                                        match$1 !== undefined ? "Avo Managed not supported for " + AvoConfigTypes.languageToJs(match) + " <-> " + AvoConfig.analyticsToolsLabel(match$1) : "Avo Managed not supported " + AvoConfigTypes.languageToJs(match) + " <-> this platform"
                                      ) : (
                                        match$1 !== undefined ? "Avo Managed not supported with this language on " + AvoConfig.analyticsToolsLabel(match$1) : ""
                                      )
                                  }), React.createElement(Spacer.make, {
                                    width: 8
                                  }));
                        }
                        var match$2 = destination.type_;
                        var tmp$1;
                        switch (destinationMode) {
                          case /* AvoManaged */0 :
                              tmp$1 = "AvoManaged";
                              break;
                          case /* DestinationInterface */1 :
                              tmp$1 = "DestinationInterface";
                              break;
                          case /* Legacy */2 :
                              tmp$1 = match$2 !== undefined && !(match$2 === "Snowplow" || match$2 === "Custom") ? "AvoManaged" : "DestinationInterface";
                              break;
                          
                        }
                        return React.createElement("div", {
                                    className: rowItem
                                  }, React.createElement("div", {
                                        className: left
                                      }, React.createElement("div", {
                                            className: iconContainer
                                          }, destination$1 !== undefined ? React.createElement(Icons.Destinations.make, {
                                                  destination: destination$1,
                                                  size: 24
                                                }) : React.createElement($$Text.make, {
                                                  size: "Medium",
                                                  weight: "Semi",
                                                  color: Styles.Color.white,
                                                  children: "?"
                                                })), React.createElement("div", undefined, React.createElement(Link.make, {
                                                path: Router.Link.addDrawerItem(undefined, {
                                                      NAME: "destination",
                                                      VAL: destination.id
                                                    }),
                                                className: destinationLink,
                                                children: React.createElement($$Text.make, {
                                                      size: "Medium",
                                                      weight: "Semi",
                                                      children: ModelUtils.getDestinationName(destination)
                                                    })
                                              }), destinationType !== undefined ? React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.whiteSpace("nowrap"),
                                                        tl: {
                                                          hd: Css.marginRight(Css.px(8)),
                                                          tl: /* [] */0
                                                        }
                                                      })
                                                }, React.createElement($$Text.make, {
                                                      size: "Small",
                                                      color: Styles.Color.light10,
                                                      children: AvoConfig.analyticsToolsLabel(destinationType)
                                                    })) : null)), React.createElement(Spacer.make, {
                                        width: 8
                                      }), tmp, React.createElement(Select.make, {
                                        onSelect: (function (value) {
                                            var newValue;
                                            switch (value) {
                                              case "AvoManaged" :
                                                  newValue = /* AvoManaged */0;
                                                  break;
                                              case "DestinationInterface" :
                                                  newValue = /* DestinationInterface */1;
                                                  break;
                                              default:
                                                newValue = Curry._1(Pervasives.failwith("Unexpected destination mode: "), value);
                                            }
                                            return Curry.app(sendActions, [
                                                        currentBranch,
                                                        false,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        (function (branch) {
                                                            return AnalyticsRe.dataSourceUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), AvoConfig.getSourceName(source), undefined, source.platform, undefined, AnalyticsUtils.analyticsLanguage(source.language), AnalyticsUtils.analyticsLanguage(source.language), source.filename, undefined, source.path, undefined, "DestinationModeUpdated", undefined, SourceConfigOverview.modelSourceConfigToAnalyticsSourceConfig(source.config), destination.id, destination.name, destination.type_, AnalyticsUtils.destinationModeToAnalyticsDestinationMode(destinationMode), AnalyticsUtils.destinationModeToAnalyticsDestinationMode(newValue), NamedBranch.getId(branch), schemaBundle.schemaId);
                                                          }),
                                                        undefined,
                                                        [[
                                                            {
                                                              NAME: "UpdateSourceDestinationMode",
                                                              VAL: [
                                                                source.id,
                                                                destination.id,
                                                                newValue
                                                              ]
                                                            },
                                                            {
                                                              sourceId: source.id,
                                                              sourceQuery: source.id,
                                                              destinationId: destination.id,
                                                              destinationQuery: destination.id
                                                            }
                                                          ]]
                                                      ]);
                                          }),
                                        options: [
                                          [
                                            {
                                              NAME: "Label",
                                              VAL: "Destination Interface"
                                            },
                                            "DestinationInterface"
                                          ],
                                          destinationIsAvoManagedSupported ? [
                                              {
                                                NAME: "Label",
                                                VAL: "Avo Managed (Legacy)"
                                              },
                                              "AvoManaged"
                                            ] : [
                                              {
                                                NAME: "Disabled",
                                                VAL: "Avo Managed (Legacy)"
                                              },
                                              "AvoManaged"
                                            ]
                                        ],
                                        value: tmp$1
                                      }));
                      }))), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Regular",
                  children: null
                }, "Add or remove destinations for this source in the ", React.createElement(Link.make, {
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "source",
                            VAL: [
                              source.id,
                              "overview"
                            ]
                          }),
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.color(Styles.Color.avoPink),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      children: "Overview tab"
                    })), React.createElement(Spacer.make, {
                  height: 16
                }));
}

var make = SourceConfigCodegenDestinations;

export {
  Style ,
  make ,
  
}
/* listContainer Not a pure module */
