// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "../Title.mjs";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as RoleUtils from "../RoleUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as IconLightning from "../IconLightning.mjs";
import * as CreateButtonIcon from "./CreateButtonIcon.mjs";
import * as EmptyStatesStyles from "./EmptyStatesStyles.mjs";
import * as PublishingEmptyIllustration from "./PublishingEmptyIllustration.mjs";

function PublishingEmpty(Props) {
  var onCreate = Props.onCreate;
  var createAvailability = Props.createAvailability;
  var role = Props.role;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingLeft(Css.px(15)),
                    tl: {
                      hd: Css.paddingTop(Css.px(15)),
                      tl: {
                        hd: Css.paddingRight(Css.px(15)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("main", {
                  className: EmptyStatesStyles.rootStyles
                }, React.createElement(PublishingEmptyIllustration.make, {}), React.createElement(Spacer.make, {
                      height: 32
                    }), React.createElement(Title.make, {
                      children: "Integrate your Dev Toolchain with Avo",
                      size: "Medium"
                    }), React.createElement(Spacer.make, {
                      height: 12
                    }), React.createElement($$Text.make, {
                      size: "Large",
                      color: Styles.Color.light10,
                      maxWidth: Css.px(520),
                      children: null
                    }, "Integrations enable you to pipe your data into existing workflows and apps and unlock powerful connections and value for your team. Read the ", React.createElement("a", {
                          className: EmptyStatesStyles.linkStyles,
                          href: "https://www.avo.app/docs/workspace/tracking-plan/publishing",
                          rel: "noopener",
                          target: "_blank"
                        }, React.createElement($$Text.make, {
                              element: "Span",
                              weight: "Semi",
                              children: "Avo docs"
                            })), "."), Belt_Option.mapWithDefault(onCreate, null, (function (onClick) {
                        if (RoleUtils.canEdit(role)) {
                          return React.createElement("div", {
                                      className: EmptyStatesStyles.actionsStyles
                                    }, React.createElement("button", {
                                          className: EmptyStatesStyles.actionStyles,
                                          onClick: onClick
                                        }, React.createElement("div", {
                                              className: EmptyStatesStyles.iconStyles
                                            }, createAvailability !== undefined && createAvailability !== "Available" ? React.createElement(IconLightning.make, {}) : CreateButtonIcon.icon), React.createElement($$Text.make, {
                                              element: "Span",
                                              size: "Medium",
                                              weight: "Semi",
                                              color: Styles.Color.light11,
                                              children: "Create Your First Integration"
                                            })));
                        } else {
                          return null;
                        }
                      }))));
}

var make = PublishingEmpty;

export {
  make ,
  
}
/* Css Not a pure module */
