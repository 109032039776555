// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as IconLock from "./IconLock.mjs";
import * as Intercom from "./externals/intercom.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as CopyButton from "./CopyButton.mjs";
import * as PromiseExt from "./externals/PromiseExt.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as WebhookSecretEndpoint from "../../shared/endpoints/WebhookSecretEndpoint.mjs";

var labelStyle = Curry._1(Css.style, {
      hd: Css.fontWeight(Styles.FontWeight.semi),
      tl: {
        hd: Css.color(Styles.Color.light10),
        tl: {
          hd: Css.fontSize(Styles.FontSize.small),
          tl: /* [] */0
        }
      }
    });

function generateSecret(schemaId, integrationId) {
  var request = {
    schemaId: schemaId,
    integrationId: integrationId
  };
  var body = WebhookSecretEndpoint.$$Request.encode(request);
  return PromiseExt.resultFlatMap($$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                        return fetch("/api/webhook/generate-secret", Fetch.RequestInit.make(/* Post */2, [
                                          [
                                            "Accept",
                                            "application/json"
                                          ],
                                          [
                                            "Content-Type",
                                            "application/json"
                                          ],
                                          [
                                            "Authorization",
                                            "Bearer " + token
                                          ]
                                        ], Caml_option.some(JSON.stringify(body)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                      }).then(function (resp) {
                      return Promise.resolve({
                                  TAG: /* Ok */0,
                                  _0: resp
                                });
                    }), (function (err) {
                    console.error("/https_webhookSecretGenerator fetch error", err);
                    return Promise.resolve({
                                TAG: /* Error */1,
                                _0: "Insufficient permissions"
                              });
                  })), (function (resp) {
                var status = resp.status;
                if (status === 200) {
                  return resp.json().then(function (json) {
                              return Promise.resolve({
                                          TAG: /* Ok */0,
                                          _0: WebhookSecretEndpoint.$$Response.decode(json)
                                        });
                            });
                } else {
                  return Promise.resolve({
                              TAG: /* Error */1,
                              _0: "Insufficient permissions"
                            });
                }
              }));
}

var secretKeyBackground_0 = Css.position("relative");

var secretKeyBackground_1 = {
  hd: Css.fontWeight(Styles.FontWeight.regular),
  tl: {
    hd: Css.backgroundColor(Styles.Color.light02),
    tl: {
      hd: Css.borderRadius(Styles.Border.radius),
      tl: {
        hd: Css.color(Styles.Color.light12),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.regular),
          tl: {
            hd: Css.fontFamily(Styles.FontFamily.polar),
            tl: /* [] */0
          }
        }
      }
    }
  }
};

var secretKeyBackground = {
  hd: secretKeyBackground_0,
  tl: secretKeyBackground_1
};

var rootStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.width(Css.px(300)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.justifyContent("spaceBetween"),
              tl: {
                hd: Css.maxWidth(Css.pct(100)),
                tl: {
                  hd: Css.padding4(Css.px(35), Css.px(30), Css.px(24), Css.px(30)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.cursor("default"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function WebhookSecretModal(Props) {
  var integrationId = Props.integrationId;
  var integrationConfig = Props.integrationConfig;
  var onClose = Props.onClose;
  var onEncryptedToken = Props.onEncryptedToken;
  var hasSecret = Props.hasSecret;
  var userIsEditor = Props.userIsEditor;
  var workspace = WorkspaceContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        if (userIsEditor && hasSecret) {
          return "Confirmation";
        } else {
          return "SecretInput";
        }
      });
  var setStep = match[1];
  var match$1 = React.useState(function () {
        if (userIsEditor) {
          return "Loading";
        } else {
          return "Error";
        }
      });
  var setToken = match$1[1];
  var secretToken = match$1[0];
  var match$2 = React.useState(function () {
        if (userIsEditor && !hasSecret) {
          return /* GeneratingToken */0;
        } else {
          return /* Idle */1;
        }
      });
  var setTokenStatus = match$2[1];
  var tokenStatus = match$2[0];
  React.useEffect((function () {
          if (tokenStatus) {
            
          } else {
            generateSecret(workspace.id, integrationId).then(function (response) {
                  if (response.TAG === /* Ok */0) {
                    var token = Buffer.from(response._0.key, "base64").toString();
                    Curry._1(setToken, (function (param) {
                            return {
                                    NAME: "Loaded",
                                    VAL: token
                                  };
                          }));
                    Curry._1(setTokenStatus, (function (param) {
                            return /* Idle */1;
                          }));
                    return Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                true,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    return Curry._1(setToken, (function (param) {
                                                  return "Error";
                                                }));
                                  }),
                                [[
                                    {
                                      NAME: "GenerateWebhookSecret",
                                      VAL: integrationId
                                    },
                                    {
                                      integrationId: integrationId,
                                      integrationQuery: integrationId
                                    }
                                  ]]
                              ]);
                  }
                  Curry._1(setToken, (function (param) {
                          return "Error";
                        }));
                  return Curry._1(setTokenStatus, (function (param) {
                                return /* Idle */1;
                              }));
                });
          }
          
        }), [tokenStatus]);
  var tmp;
  if (match[0] === "Confirmation") {
    tmp = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
              color: Styles.Color.light11,
              children: "Generating a new secret key will disable your old secret. Are you sure you want to proceed?"
            }), React.createElement(Spacer.make, {
              height: 24
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement(Button.make, {
                  label: "Generate a new secret",
                  onClick: (function (param) {
                      Curry._1(setStep, (function (param) {
                              return "SecretInput";
                            }));
                      return Curry._1(setTokenStatus, (function (param) {
                                    return /* GeneratingToken */0;
                                  }));
                    }),
                  size: "large"
                }), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement(TextButton.make, {
                  onClick: (function (param) {
                      return Curry._1(onClose, undefined);
                    }),
                  size: "Medium",
                  children: "Cancel"
                })));
  } else {
    var tmp$1;
    if (typeof secretToken === "object") {
      var secretToken$1 = secretToken.VAL;
      AnalyticsRe.webhookSecretModalInteraction(schemaBundle, integrationConfig, "NewSecretKeyGenerated", schemaBundle.schemaId);
      tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("label", {
                className: labelStyle,
                htmlFor: "accessToken"
              }, "Webhook Secret Key"), React.createElement(Spacer.make, {
                height: 4
              }), React.createElement("div", {
                className: Curry._1(Css.style, Belt_List.concat(secretKeyBackground, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.textOverflow("ellipsis"),
                            tl: {
                              hd: Css.padding(Css.px(8)),
                              tl: /* [] */0
                            }
                          }
                        }))
              }, React.createElement(CopyButton.make, {
                    copyString: secretToken$1
                  }), React.createElement("pre", {
                    className: Curry._1(Css.style, {
                          hd: Css.overflowX("scroll"),
                          tl: {
                            hd: Css.color(Styles.Color.deepBlue),
                            tl: {
                              hd: Css.fontFamily(Styles.FontFamily.polar),
                              tl: {
                                hd: Css.margin("zero"),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(8)),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, secretToken$1)));
    } else if (secretToken === "Error") {
      if (userIsEditor) {
        AnalyticsRe.webhookSecretModalInteraction(schemaBundle, integrationConfig, "NewSecretKeyGenerationFailed", schemaBundle.schemaId);
      }
      tmp$1 = React.createElement("div", {
            className: Curry._1(Css.style, Belt_List.concat(secretKeyBackground, {
                      hd: Css.padding2(Css.px(18), Css.px(8)),
                      tl: /* [] */0
                    }))
          }, userIsEditor ? "There was a problem generating your secret key" : "Only workspace admins can generate secret");
    } else {
      tmp$1 = null;
    }
    var tmp$2;
    if (typeof secretToken === "object") {
      var secretToken$2 = secretToken.VAL;
      tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                label: "Close, I saved the key",
                onClick: (function (param) {
                    Curry._1(onClose, undefined);
                    return Curry._1(onEncryptedToken, secretToken$2);
                  })
              }));
    } else {
      tmp$2 = secretToken === "Error" ? React.createElement(Button.make, {
              label: "Contact support",
              onClick: (function (param) {
                  Curry._1(onClose, undefined);
                  return Intercom.showNewMessage("Hey! I'm facing problems with generating a webhook secret key");
                })
            }) : React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.height(Css.pct(55.0)),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(LoadingCircle.make, {
                  color: Styles.Color.avoPink
                }));
    }
    tmp = React.createElement(React.Fragment, undefined, tmp$1, typeof secretToken === "object" ? React.createElement($$Text.make, {
                color: Styles.Color.light11,
                children: "Make sure to note down the secret key, you won't be able to see it in Avo again"
              }) : null, React.createElement(Spacer.make, {
              height: 16
            }), tmp$2);
  }
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.margin4(Css.px(-100), "auto", Css.px(5), "auto"),
                        tl: {
                          hd: Css.textAlign("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(IconLock.make, {
                      size: 100
                    })), tmp);
}

var make = WebhookSecretModal;

export {
  labelStyle ,
  generateSecret ,
  secretKeyBackground ,
  rootStyles ,
  make ,
  
}
/* labelStyle Not a pure module */
