// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as DateFns from "date-fns";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconCheckmark from "./IconCheckmark.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import LodashDebounce from "lodash.debounce";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SlideOverContainer from "./SlideOverContainer.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function writeUserRole(schemaId, jobFunction) {
  var viewerId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("acls").doc(viewerId).set({
              jobFunction: jobFunction
            }, {"merge": true});
}

function wait(delayInMs) {
  return new Promise((function (resolve, param) {
                setTimeout((function (param) {
                        return resolve(undefined);
                      }), delayInMs);
                
              }));
}

var root = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.height(Css.vh(100.0)),
          tl: {
            hd: Css.width(Css.px(380)),
            tl: /* [] */0
          }
        }
      }
    });

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: {
            hd: Css.overflowY("auto"),
            tl: {
              hd: Css.padding(Css.px(40)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function button(selected) {
  return Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("row"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.width(Css.px(230)),
                      tl: {
                        hd: Css.marginTop(Css.px(12)),
                        tl: {
                          hd: Css.padding(Css.px(8)),
                          tl: {
                            hd: Css.cursor("pointer"),
                            tl: {
                              hd: Css.backgroundColor(selected ? Styles.Color.avoPink : Styles.Color.white),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", selected ? Styles.Color.avoPink : Styles.Color.light07),
                                tl: {
                                  hd: Css.borderRadius(Css.px(100)),
                                  tl: {
                                    hd: Css.color(selected ? Styles.Color.white : Styles.Color.light10),
                                    tl: {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$short
                                          }, undefined, undefined, "all"),
                                      tl: {
                                        hd: Css.hover(selected ? ({
                                                  hd: Css.backgroundColor(Styles.Color.avoPinkShade2),
                                                  tl: {
                                                    hd: Css.borderColor(Styles.Color.avoPinkShade2),
                                                    tl: /* [] */0
                                                  }
                                                }) : ({
                                                  hd: Css.borderColor(Styles.Color.avoPink),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.avoPink),
                                                    tl: /* [] */0
                                                  }
                                                })),
                                        tl: {
                                          hd: Css.focus({
                                                hd: Css.outlineStyle("none"),
                                                tl: {
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.setAlpha(Styles.Color.avoPink, 0.2))),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function buttonCheck(selected) {
  return Curry._1(Css.style, {
              hd: Css.flexShrink(0.0),
              tl: {
                hd: Css.width(Css.px(24)),
                tl: {
                  hd: Css.height(Css.px(24)),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.marginRight(Css.px(8)),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", selected ? Styles.Color.white : Styles.Color.light07),
                            tl: {
                              hd: Css.borderRadius(Css.px(100)),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var footer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("spaceBetween"),
              tl: {
                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.paddingTop(Css.px(24)),
                  tl: {
                    hd: Css.paddingRight(Css.px(40)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(24)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(40)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var input = Curry._1(Css.merge, {
      hd: $$Text.sizeStyles("Medium"),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.unsafe("appearance", "none"),
              tl: {
                hd: Css.top(Css.px(-1)),
                tl: {
                  hd: Css.right(Css.px(-1)),
                  tl: {
                    hd: Css.position("absolute"),
                    tl: {
                      hd: Css.placeholder({
                            hd: Css.color(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.padding(Css.px(0)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(39)),
                          tl: {
                            hd: Css.left(Css.px(-1)),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.focus({
                                      hd: Css.outlineStyle("none"),
                                      tl: {
                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.setAlpha(Styles.Color.avoPink, 0.2))),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.flexShrink(1.0),
                                  tl: {
                                    hd: Css.flexGrow(1.0),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.color(Styles.Color.white),
                                        tl: {
                                          hd: Css.bottom(Css.px(0)),
                                          tl: {
                                            hd: Css.borderRadius(Css.px(100)),
                                            tl: {
                                              hd: Css.border(Css.px(0), "solid", "transparent"),
                                              tl: {
                                                hd: Css.backgroundColor("transparent"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var Style = {
  root: root,
  content: content,
  button: button,
  buttonCheck: buttonCheck,
  footer: footer,
  input: input
};

function trackOtherUpdate(schemaBundle, jobFunction) {
  return AnalyticsRe.jobFunctionPromptInteraction(schemaBundle, "JobFunctionCustomised", jobFunction, schemaBundle.branchId, schemaBundle.schemaId);
}

var debouncedTrackOtherUpdate = LodashDebounce(trackOtherUpdate, 1000, {
      leading: false,
      trailing: true
    });

function RolePrompt$Content(Props) {
  var schemaBundle = Props.schemaBundle;
  var onDismiss = Props.onDismiss;
  var onFinish = Props.onFinish;
  var selected = Props.selected;
  var setSelected = Props.setSelected;
  var match = React.useState(function () {
        return false;
      });
  var setFinished = match[1];
  var finished = match[0];
  React.useEffect((function () {
          AnalyticsRe.jobFunctionPromptDisplayed(schemaBundle, schemaBundle.branchId, schemaBundle.schemaId);
          
        }), []);
  var handleFinish = function (param) {
    return Belt_Option.mapWithDefault(selected, undefined, (function (jobFunction) {
                  Curry._1(setFinished, (function (param) {
                          return true;
                        }));
                  $$Promise.$$catch(Promise.all([
                              writeUserRole(schemaBundle.schemaId, AnalyticsRe.jobFunctionToJs(jobFunction)),
                              wait(500)
                            ]).then(function (param) {
                            Curry._1(onFinish, undefined);
                            AnalyticsRe.jobFunctionPromptCompleted(schemaBundle, Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid, jobFunction, [jobFunction], schemaBundle.branchId, schemaBundle.schemaId);
                            return Promise.resolve(undefined);
                          }), (function (error) {
                          window.alert("Hmm, it looks like something went wrong. Please try again!");
                          Sentry.captureException(error, {});
                          Curry._1(setFinished, (function (param) {
                                  return false;
                                }));
                          return Promise.resolve(undefined);
                        }));
                  
                }));
  };
  return React.createElement("article", {
              className: root
            }, React.createElement("div", {
                  className: content
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light10,
                      children: "Hi! We’re trying to better understand how different roles are using Avo."
                    }), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement(Title.make, {
                      children: "What best describes your role?",
                      size: "Medium"
                    }), React.createElement(Spacer.make, {
                      height: 20
                    }), Belt_Array.map([
                      "ProductAnalytics",
                      "DataScience",
                      "DataAnalyticsEngineering",
                      "SoftwareEngineering",
                      "ProductManagement",
                      "Marketing",
                      "Other"
                    ], (function (job) {
                        var selected$1 = Caml_obj.caml_equal(selected, job);
                        return React.createElement("div", {
                                    key: AnalyticsRe.jobFunctionToJs(job),
                                    className: button(selected$1),
                                    role: "button",
                                    tabIndex: -1,
                                    onClick: (function (param) {
                                        Curry._1(setSelected, (function (param) {
                                                return job;
                                              }));
                                        return AnalyticsRe.jobFunctionPromptInteraction(schemaBundle, "JobFunctionSelected", job, schemaBundle.branchId, schemaBundle.schemaId);
                                      })
                                  }, React.createElement("div", {
                                        className: buttonCheck(selected$1)
                                      }, React.createElement(IconCheckmark.make, {
                                            size: 14,
                                            color: selected$1 ? Styles.Color.avoPink : "transparent"
                                          })), React.createElement($$Text.make, {
                                        element: "Span",
                                        size: "Medium",
                                        weight: "Semi",
                                        lineHeight: Css.pct(160.0),
                                        children: AnalyticsRe.jobFunctionToJs(job)
                                      }));
                      }))), React.createElement("footer", {
                  className: footer
                }, React.createElement(TextButton.make, {
                      onClick: onDismiss,
                      size: "Medium",
                      children: "Answer later"
                    }), React.createElement(TextButton.make, {
                      onClick: handleFinish,
                      style: "Magenta",
                      size: "Large",
                      disabled: finished || Belt_Option.isNone(selected),
                      children: finished ? "Thanks!" : "Finish"
                    })));
}

var Content = {
  writeUserRole: writeUserRole,
  wait: wait,
  Style: Style,
  trackOtherUpdate: trackOtherUpdate,
  debouncedTrackOtherUpdate: debouncedTrackOtherUpdate,
  make: RolePrompt$Content
};

function setLastDismissedDate(param) {
  var viewerId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  Firebase.app(undefined).firestore().collection("users").doc(viewerId).set({
        lastRoleDismissedDate: new Date()
      }, {"merge": true});
  
}

function useRoleDialog(schemaId) {
  var match = React.useState(function () {
        return false;
      });
  var setShowDialog = match[1];
  var workspace = WorkspaceContext.use(undefined);
  var viewerId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  var user = FirebaseFetcherHooks.useUser(viewerId);
  var member = FirebaseFetcherHooks.useMember(schemaId, viewerId, true);
  React.useEffect((function () {
          if (typeof member === "object" && member.NAME === "Success" && typeof user === "object" && user.NAME === "Success" && Belt_Option.isNone(Caml_option.undefined_to_opt(member.VAL.jobFunction)) && !workspace.isSandbox) {
            var lastDismissedDate = Models.toDateOption(user.VAL.lastRoleDismissedDate);
            if (lastDismissedDate !== undefined) {
              if (DateFns.differenceInHours(new Date(), Caml_option.valFromOption(lastDismissedDate)) > 24.0) {
                Curry._1(setShowDialog, (function (param) {
                        return true;
                      }));
              }
              
            } else {
              Curry._1(setShowDialog, (function (param) {
                      return true;
                    }));
            }
          }
          
        }), [
        member,
        user
      ]);
  var onDismiss = function (param) {
    return Curry._1(setShowDialog, (function (param) {
                  return false;
                }));
  };
  return [
          match[0],
          onDismiss
        ];
}

function RolePrompt(Props) {
  var showDialog = Props.showDialog;
  var closeDialog = Props.closeDialog;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = React.useState(function () {
        
      });
  var selected = match[0];
  Hooks.useIntercomVisibility(!showDialog);
  var onDismiss = function (param) {
    setLastDismissedDate(undefined);
    Curry._1(closeDialog, undefined);
    return AnalyticsRe.jobFunctionPromptInteraction(schemaBundle, "Dismissed", selected, schemaBundle.branchId, schemaBundle.schemaId);
  };
  return React.createElement(SlideOverContainer.make, {
              onClose: onDismiss,
              slideOver: showDialog ? [
                  React.createElement(RolePrompt$Content, {
                        schemaBundle: schemaBundle,
                        onDismiss: onDismiss,
                        onFinish: closeDialog,
                        selected: selected,
                        setSelected: match[1]
                      }),
                  "rolePrompt"
                ] : undefined
            });
}

var make = RolePrompt;

export {
  Content ,
  setLastDismissedDate ,
  useRoleDialog ,
  make ,
  
}
/* root Not a pure module */
