// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Case from "../../bs-case/src/case.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Select from "./Select.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as GlobalPill from "./GlobalPill.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as SheetTypes from "./SheetTypes.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as EditableCell from "./EditableCell.mjs";
import * as CellEditPopup from "./CellEditPopup.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as EditableCellListItem from "./EditableCellListItem.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as PropertyAbsenceViewHelpers from "./PropertyAbsenceViewHelpers.mjs";
import * as GetPropertiesSheetConstraintsUseCase from "./propertiesSheet/GetPropertiesSheetConstraintsUseCase.mjs";

function rootStyles(selected, withDetails, outerBorderColor, disabled) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.borderRight(Css.px(1), "solid", outerBorderColor),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.height(Css.pct(100)),
                          tl: {
                            hd: Css.textAlign("left"),
                            tl: {
                              hd: selected ? Styles.backgroundColorWithOverlayGuarantee("transparent", Styles.Color.setAlpha(Styles.Color.light12, 0.05)) : Styles.backgroundColorGuarantee("transparent"),
                              tl: {
                                hd: Css.minWidth(withDetails ? Css.pct(100.0) : ({
                                          NAME: "subtract",
                                          VAL: [
                                            Css.pct(100),
                                            Css.px(16)
                                          ]
                                        })),
                                tl: {
                                  hd: disabled ? Css.cursor("default") : Styles.emptyStyle,
                                  tl: {
                                    hd: Css.hover({
                                          hd: disabled ? Styles.emptyStyle : Css.selector(" > div", {
                                                  hd: Styles.backgroundColorWithOverlayGuarantee("transparent", Styles.Color.setAlpha(Styles.Color.light12, 0.02)),
                                                  tl: /* [] */0
                                                }),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.position("relative"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function stickyCellStyles(customWidth, selected, innerBorderColor, outerBorderColor) {
  return Curry._1(Css.style, {
              hd: Css.borderLeft(Css.px(1), "solid", outerBorderColor),
              tl: {
                hd: selected ? Styles.backgroundColorWithOverlayGuarantee(Css.transparent, Styles.Color.setAlpha(Styles.Color.light12, 0.06)) : Styles.backgroundColorGuarantee(Css.transparent),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.alignItems("flexStart"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.position("sticky"),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.left(Css.px(0)),
                              tl: {
                                hd: Css.flexShrink(0.0),
                                tl: {
                                  hd: Css.width(Css.px(customWidth)),
                                  tl: {
                                    hd: Css.height(Css.pct(100)),
                                    tl: {
                                      hd: Css.paddingLeft(Css.px(16)),
                                      tl: {
                                        hd: Css.paddingRight(Css.px(16)),
                                        tl: {
                                          hd: Css.borderRight(Css.px(1), "solid", innerBorderColor),
                                          tl: {
                                            hd: Css.borderBottom(Css.px(1), "solid", innerBorderColor),
                                            tl: {
                                              hd: Css.marginRight(Css.px(-1)),
                                              tl: {
                                                hd: Css.zIndex(1),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function cellStyles(withDetails, editable, customWidth, innerBorderColor, outerBorderColor) {
  return Curry._1(Css.style, {
              hd: Css.hover(editable ? ({
                        hd: Css.important(Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.darkBlue, 0.1))),
                        tl: /* [] */0
                      }) : /* [] */0),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("flexStart"),
                  tl: {
                    hd: Css.height(Css.pct(100)),
                    tl: {
                      hd: Css.width(Css.px(customWidth)),
                      tl: {
                        hd: Css.backgroundColor("transparent"),
                        tl: {
                          hd: Css.flexShrink(0.0),
                          tl: {
                            hd: Css.overflow("hidden"),
                            tl: {
                              hd: Css.borderLeft(Css.px(1), "solid", innerBorderColor),
                              tl: {
                                hd: Css.borderBottom(Css.px(1), "solid", innerBorderColor),
                                tl: {
                                  hd: Css.paddingTop(Css.px(2)),
                                  tl: {
                                    hd: Css.paddingRight(Css.px(2)),
                                    tl: {
                                      hd: Css.paddingBottom(Css.px(2)),
                                      tl: {
                                        hd: Css.paddingLeft(Css.px(12)),
                                        tl: {
                                          hd: Css.lastChild(withDetails ? ({
                                                    hd: Css.flexGrow(1.0),
                                                    tl: {
                                                      hd: Css.width(Css.px(customWidth + 1 | 0)),
                                                      tl: {
                                                        hd: Css.marginRight(Css.px(716)),
                                                        tl: {
                                                          hd: Css.borderRight(Css.px(1), "solid", outerBorderColor),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }) : ({
                                                    hd: Css.flexGrow(1.0),
                                                    tl: /* [] */0
                                                  })),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function cellContentStyles(wrapOpt, param) {
  var wrap = wrapOpt !== undefined ? wrapOpt : "wrap";
  return Curry._1(Css.style, {
              hd: Css.flexWrap(wrap),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.minHeight(Css.pct(100.0)),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var Style = {
  rootStyles: rootStyles,
  stickyCellStyles: stickyCellStyles,
  cellStyles: cellStyles,
  cellContentStyles: cellContentStyles
};

function getColumnWidthByType(columns, columnType) {
  return Belt_Option.mapWithDefault(Belt_Array.getByU(columns, (function (column) {
                    return column.columnType === columnType;
                  })), 200, (function (param) {
                return param.width;
              }));
}

function isColumnVisibleByType(columns, columnType) {
  return Belt_Option.mapWithDefault(Belt_Array.getByU(columns, (function (column) {
                    return column.columnType === columnType;
                  })), true, (function (param) {
                return param.visible;
              }));
}

function PropertiesSheetPropertyRow$NameCell(Props) {
  var columns = Props.columns;
  var description = Props.description;
  var isGlobal = Props.isGlobal;
  var name = Props.name;
  var selected = Props.selected;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  return React.createElement("div", {
              className: stickyCellStyles(getColumnWidthByType(columns, "PropertyName"), selected, innerBorderColor, outerBorderColor)
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.gap(Css.px(4)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      lines: 1,
                      color: Styles.Color.light12,
                      maxWidth: Css.pct(100),
                      title: name,
                      children: name
                    }), isGlobal ? React.createElement(GlobalPill.make, {}) : null), description !== "" ? React.createElement($$Text.make, {
                    size: "Small",
                    singleLine: true,
                    color: Styles.Color.light11,
                    maxWidth: Css.pct(100),
                    children: description
                  }) : React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.orange,
                    children: "No description"
                  }));
}

var NameCell = {
  make: PropertiesSheetPropertyRow$NameCell
};

var EditablePropertyEventsCell = CellEditPopup.MakeEditableCell({
      Cell: EditableCell.PropertyEvents,
      make: EditableCellListItem.PropertyEvents.make
    });

function PropertiesSheetPropertyRow$PropertyEvents(Props) {
  var columns = Props.columns;
  var innerBorderColor = Props.innerBorderColor;
  var model = Props.model;
  var outerBorderColor = Props.outerBorderColor;
  var property = Props.property;
  var viewerCanEdit = Props.viewerCanEdit;
  var withDetails = Props.withDetails;
  var eventsSendingPropertiesMap = Props.eventsSendingPropertiesMap;
  var sendActions = SendActionsContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var events = ModelStore.Mapped.useEvents(undefined);
  var match = GlobalStateContext.use(undefined);
  var currentFilters = AnalyticsUtils.currentFilters(model, match.filters, match.eventSort);
  var allItems = React.useMemo((function () {
          return Curry._1(TrackingPlanMappedModel.Events.toArray, events);
        }), [events]);
  var selectedItems = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_MapString.get(eventsSendingPropertiesMap, property.id), []);
        }), [
        eventsSendingPropertiesMap,
        property.id
      ]);
  var onToggle = function (param, eventId, toggled) {
    var context = {
      eventId: eventId,
      eventQuery: eventId,
      propertyId: property.id,
      propertyQuery: property.id
    };
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    if (toggled) {
                      return AnalyticsRe.propertyAdded(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), AnalyticsUtils.getAnalyticsPropertyGroup(model, property), currentFilters, eventId, Belt_Option.getExn(Curry._2(TrackingPlanMappedModel.Events.get, events, eventId)).name, property.name.length, "New", Case.to_("Sentence", property.name).split(" ").length, property.name.length, "PropertyDetails", NamedBranch.getId(branch), schemaBundle.schemaId);
                    } else {
                      return AnalyticsRe.propertyRemoved(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), AnalyticsUtils.getAnalyticsPropertyGroup(model, property), eventId, Belt_Option.getExn(Curry._2(TrackingPlanMappedModel.Events.get, events, eventId)).name, "PropertyDetails", schemaBundle.branchId, schemaBundle.schemaId);
                    }
                  }),
                undefined,
                toggled ? [[
                      {
                        NAME: "AddPropertyRef",
                        VAL: [
                          eventId,
                          property.id
                        ]
                      },
                      context
                    ]] : [[
                      {
                        NAME: "RemovePropertyRefV3",
                        VAL: [
                          eventId,
                          property.id
                        ]
                      },
                      context
                    ]]
              ]);
  };
  var onInteraction = React.useCallback((function (action) {
          return AnalyticsRe.propertiesViewPopupInteraction(schemaBundle, action, "Events", "PropertiesView", "Event", schemaBundle.branchId, schemaBundle.schemaId);
        }), [schemaBundle]);
  var match$1 = property.sendAs;
  if (match$1 === 0) {
    return React.createElement("div", {
                className: cellStyles(withDetails, false, getColumnWidthByType(columns, "PropertyEvents"), innerBorderColor, outerBorderColor)
              }, React.createElement("div", {
                    className: cellContentStyles(undefined, undefined)
                  }, React.createElement($$Text.make, {
                        size: "Tiny",
                        lines: 3,
                        color: Styles.Color.light11,
                        children: "All Events"
                      })));
  } else {
    return React.createElement(EditablePropertyEventsCell.make, {
                allItems: allItems,
                children: React.createElement("div", {
                      className: cellStyles(withDetails, false, getColumnWidthByType(columns, "PropertyEvents"), innerBorderColor, outerBorderColor)
                    }, React.createElement("div", {
                          className: cellContentStyles(undefined, undefined)
                        }, Caml_obj.caml_equal(selectedItems, []) ? React.createElement($$Text.make, {
                                size: "Tiny",
                                weight: "Semi",
                                color: Styles.Color.red,
                                children: "No events"
                              }) : React.createElement($$Text.make, {
                                size: "Tiny",
                                lines: 3,
                                color: Styles.Color.light11,
                                children: null
                              }, React.createElement($$Text.make, {
                                    element: "Span",
                                    weight: "Semi",
                                    children: String(selectedItems.length)
                                  }), ": " + Belt_SortArray.stableSortByU(Belt_Array.mapU(selectedItems, (function (param) {
                                            return param.name;
                                          })), Caml.caml_string_compare).join(", ")))),
                className: "",
                onCreates: (function (param) {
                    return [];
                  }),
                disabled: withDetails,
                onViewItem: (function (eventId) {
                    return Router.Schema.pushDrawerItem(undefined, undefined, {
                                NAME: "event",
                                VAL: [
                                  eventId,
                                  undefined,
                                  undefined,
                                  false
                                ]
                              });
                  }),
                onToggle: onToggle,
                viewerCanEdit: viewerCanEdit,
                selectedItems: selectedItems,
                onItemClicked: (function (param) {
                    return Curry._1(onInteraction, "ItemClicked");
                  }),
                onHover: (function (param) {
                    return AnalyticsRe.propertiesViewInteraction(schemaBundle, "Events", "PropertiesView", schemaBundle.branchId, schemaBundle.schemaId);
                  }),
                onSearch: (function (_value) {
                    return Curry._1(onInteraction, "SearchedForItem");
                  }),
                onCreateInitiated: (function (_value) {
                    return Curry._1(onInteraction, "ItemCreationInitiated");
                  })
              });
  }
}

var PropertyEvents = {
  make: PropertiesSheetPropertyRow$PropertyEvents
};

function PropertiesSheetPropertyRow(Props) {
  var columns = Props.columns;
  var innerBorderColorOpt = Props.innerBorderColor;
  var model = Props.model;
  var onClick = Props.onClick;
  var outerBorderColorOpt = Props.outerBorderColor;
  var property = Props.property;
  var schemaBundle = Props.schemaBundle;
  var selected = Props.selected;
  var sendActions = Props.sendActions;
  var viewerCanEdit = Props.viewerCanEdit;
  var withDetails = Props.withDetails;
  var eventsSendingPropertiesMap = Props.eventsSendingPropertiesMap;
  var innerBorderColor = innerBorderColorOpt !== undefined ? innerBorderColorOpt : Styles.Color.light04;
  var outerBorderColor = outerBorderColorOpt !== undefined ? outerBorderColorOpt : Styles.Color.light07;
  var globalProperty = Curry._1(ModelStore.Mapped.GlobalRequirements.useGlobalProperty, property.id);
  var hasAnyValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("AnyValues");
  var hasLongValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("LongValues");
  var handleSelectType = React.useCallback((function (nextType) {
          if (nextType !== property.type_) {
            return Curry.app(sendActions, [
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        (function (branch) {
                            return AnalyticsRe.typeChanged(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "N/A", Belt_Option.getExn(AnalyticsRe.to_FromJs(nextType)), Belt_Option.getExn(AnalyticsRe.fromFromJs(property.type_)), property.name, NamedBranch.getId(branch), schemaBundle.schemaId);
                          }),
                        undefined,
                        [[
                            {
                              NAME: "UpdatePropertyType",
                              VAL: [
                                property.id,
                                nextType
                              ]
                            },
                            {
                              propertyId: property.id,
                              propertyQuery: property.id
                            }
                          ]]
                      ]);
          }
          
        }), [property.type_]);
  var handleIsListChange = function ($$event) {
    var isList = $$event.target.checked;
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    return AnalyticsRe.listToggled(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), isList, "N/A", property.name, NamedBranch.getId(branch), schemaBundle.schemaId);
                  }),
                undefined,
                [[
                    {
                      NAME: "UpdatePropertyList",
                      VAL: [
                        property.id,
                        isList
                      ]
                    },
                    {
                      propertyId: property.id,
                      propertyQuery: property.id
                    }
                  ]]
              ]);
  };
  return React.createElement("button", {
              className: rootStyles(selected, withDetails, outerBorderColor, false),
              onClick: onClick
            }, Belt_Array.mapU(columns, (function (column) {
                    var match = column.columnType;
                    if (match === "PropertyIsList") {
                      if (isColumnVisibleByType(columns, "PropertyIsList")) {
                        return React.createElement("div", {
                                    key: SheetTypes.columnTypeToJs(column.columnType),
                                    className: cellStyles(withDetails, false, getColumnWidthByType(columns, "PropertyIsList"), innerBorderColor, outerBorderColor)
                                  }, React.createElement("div", {
                                        className: cellContentStyles(undefined, undefined)
                                      }, React.createElement("input", {
                                            checked: property.list,
                                            disabled: !viewerCanEdit,
                                            type: "checkbox",
                                            onChange: handleIsListChange,
                                            onClick: (function ($$event) {
                                                $$event.stopPropagation();
                                                
                                              })
                                          })));
                      } else {
                        return null;
                      }
                    }
                    if (match === "EventPropertyGroup") {
                      if (!isColumnVisibleByType(columns, "EventPropertyGroup")) {
                        return null;
                      }
                      var groupPropertyIsPartOf = Belt_List.getByU(model.propertyBundles, (function (propertyGroup) {
                              return Belt_List.hasU(propertyGroup.properties, property.id, (function (a, b) {
                                            return a === b;
                                          }));
                            }));
                      return React.createElement("div", {
                                  key: SheetTypes.columnTypeToJs(column.columnType),
                                  className: cellStyles(withDetails, false, getColumnWidthByType(columns, "EventPropertyGroup"), innerBorderColor, outerBorderColor)
                                }, React.createElement("div", {
                                      className: cellContentStyles(undefined, undefined)
                                    }, React.createElement($$Text.make, {
                                          size: "Tiny",
                                          lines: 3,
                                          color: Styles.Color.light11,
                                          children: groupPropertyIsPartOf !== undefined ? groupPropertyIsPartOf.name : "–"
                                        })));
                    }
                    if (match === "PropertyConstraints") {
                      if (!isColumnVisibleByType(columns, "PropertyConstraints")) {
                        return null;
                      }
                      var matches = GetPropertiesSheetConstraintsUseCase.get(model, property);
                      return React.createElement("div", {
                                  key: SheetTypes.columnTypeToJs(column.columnType),
                                  className: cellStyles(withDetails, false, getColumnWidthByType(columns, "PropertyConstraints"), innerBorderColor, outerBorderColor)
                                }, React.createElement("div", {
                                      className: cellContentStyles(undefined, undefined)
                                    }, React.createElement($$Text.make, {
                                          size: "Tiny",
                                          lines: 3,
                                          color: Styles.Color.light11,
                                          children: React.createElement("span", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.fontFamily(Styles.FontFamily.polar),
                                                      tl: /* [] */0
                                                    })
                                              }, matches === "" ? "–" : matches)
                                        })));
                    }
                    if (match !== "PropertyNameMapping") {
                      if (match === "PropertyName") {
                        return React.createElement(PropertiesSheetPropertyRow$NameCell, {
                                    columns: columns,
                                    description: globalProperty !== undefined && property.description === "" ? globalProperty.description : property.description,
                                    isGlobal: globalProperty !== undefined,
                                    name: property.name,
                                    selected: selected,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else if (match === "PropertyType") {
                        if (isColumnVisibleByType(columns, "PropertyType")) {
                          return React.createElement("div", {
                                      key: SheetTypes.columnTypeToJs(column.columnType),
                                      className: cellStyles(withDetails, false, getColumnWidthByType(columns, "PropertyType"), innerBorderColor, outerBorderColor)
                                    }, React.createElement("div", {
                                          className: cellContentStyles(undefined, undefined),
                                          onClick: (function ($$event) {
                                              $$event.stopPropagation();
                                              
                                            })
                                        }, React.createElement(Select.make, {
                                              disabled: !viewerCanEdit,
                                              onSelect: handleSelectType,
                                              options: Belt_Array.map(PropertyUtils.getPropertyTypes(hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, property), (function (type_) {
                                                      return [
                                                              {
                                                                NAME: "Label",
                                                                VAL: type_
                                                              },
                                                              type_
                                                            ];
                                                    })),
                                              value: property.type_
                                            })));
                        } else {
                          return null;
                        }
                      } else if (match === "PropertyEvents") {
                        if (isColumnVisibleByType(columns, "PropertyEvents")) {
                          return React.createElement(PropertiesSheetPropertyRow$PropertyEvents, {
                                      columns: columns,
                                      innerBorderColor: innerBorderColor,
                                      model: model,
                                      outerBorderColor: outerBorderColor,
                                      property: property,
                                      viewerCanEdit: viewerCanEdit,
                                      withDetails: withDetails,
                                      eventsSendingPropertiesMap: eventsSendingPropertiesMap,
                                      key: SheetTypes.columnTypeToJs(column.columnType)
                                    });
                        } else {
                          return null;
                        }
                      } else if (match === "PropertyAbsence" && isColumnVisibleByType(columns, "PropertyAbsence")) {
                        return React.createElement("div", {
                                    key: SheetTypes.columnTypeToJs(column.columnType),
                                    className: cellStyles(withDetails, false, getColumnWidthByType(columns, "PropertyAbsence"), innerBorderColor, outerBorderColor)
                                  }, React.createElement("div", {
                                        className: cellContentStyles(undefined, undefined)
                                      }, React.createElement($$Text.make, {
                                            size: "Tiny",
                                            lines: 3,
                                            color: Styles.Color.light11,
                                            children: Belt_Option.getWithDefault(PropertyAbsenceViewHelpers.getPropertyAbsenceString(model, undefined, property, undefined), "Always sent")
                                          })));
                      } else {
                        return null;
                      }
                    }
                    if (!isColumnVisibleByType(columns, "PropertyNameMapping")) {
                      return null;
                    }
                    var propertyNameMappings = Belt_Array.keepMapU(Belt_List.toArray(ModelUtils.getPropertyNameMappingsOnAllEvents(model, property.id, undefined, undefined)), (function (rule) {
                            var match = rule.definition;
                            if (typeof match !== "object") {
                              return ;
                            }
                            if (match.NAME !== "NameMapping") {
                              return ;
                            }
                            var match$1 = match.VAL;
                            var destination = match$1.destination;
                            if (destination === undefined) {
                              return ;
                            }
                            var name = match$1.name;
                            if (name === undefined) {
                              return ;
                            }
                            var destinationName = typeof destination === "object" ? ModelUtils.getDestinationNameById(model, destination.VAL) : "all destinations";
                            return "sent as \"" + name + "\" to " + destinationName;
                          }));
                    return React.createElement("div", {
                                key: SheetTypes.columnTypeToJs(column.columnType),
                                className: cellStyles(withDetails, false, getColumnWidthByType(columns, "PropertyNameMapping"), innerBorderColor, outerBorderColor)
                              }, React.createElement("div", {
                                    className: cellContentStyles(undefined, undefined)
                                  }, React.createElement($$Text.make, {
                                        size: "Tiny",
                                        lines: 3,
                                        color: Styles.Color.light11,
                                        title: propertyNameMappings.join(", "),
                                        children: Caml_obj.caml_equal(propertyNameMappings, []) ? "–" : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                                    element: "Span",
                                                    weight: "Semi",
                                                    children: String(propertyNameMappings.length)
                                                  }), ": " + propertyNameMappings.join(", "))
                                      })));
                  })));
}

function PropertiesSheetPropertyRow$Empty(Props) {
  var propertyNameOpt = Props.propertyName;
  var columns = Props.columns;
  var innerBorderColorOpt = Props.innerBorderColor;
  var outerBorderColorOpt = Props.outerBorderColor;
  var propertyName = propertyNameOpt !== undefined ? propertyNameOpt : "Empty Property";
  var innerBorderColor = innerBorderColorOpt !== undefined ? innerBorderColorOpt : Styles.Color.light04;
  var outerBorderColor = outerBorderColorOpt !== undefined ? outerBorderColorOpt : Styles.Color.light07;
  return React.createElement("button", {
              className: rootStyles(false, false, outerBorderColor, true),
              disabled: true
            }, Belt_Array.mapU(columns, (function (column) {
                    var columnType = column.columnType;
                    if (columnType === "PropertyName") {
                      return React.createElement("div", {
                                  key: "PropertyName",
                                  className: stickyCellStyles(getColumnWidthByType(columns, "PropertyName"), false, innerBorderColor, outerBorderColor)
                                }, React.createElement($$Text.make, {
                                      size: "Medium",
                                      weight: "Medium",
                                      singleLine: true,
                                      color: Styles.Color.light07,
                                      maxWidth: Css.pct(100),
                                      children: propertyName
                                    }));
                    } else {
                      return React.createElement("div", {
                                  key: SheetTypes.columnTypeToJs(columnType),
                                  className: cellStyles(false, false, getColumnWidthByType(columns, columnType), innerBorderColor, outerBorderColor)
                                });
                    }
                  })));
}

var Empty = {
  make: PropertiesSheetPropertyRow$Empty
};

var make = PropertiesSheetPropertyRow;

export {
  Style ,
  getColumnWidthByType ,
  isColumnVisibleByType ,
  NameCell ,
  EditablePropertyEventsCell ,
  PropertyEvents ,
  make ,
  Empty ,
  
}
/* EditablePropertyEventsCell Not a pure module */
