// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as DateFns from "date-fns";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TrialRequest from "./TrialRequest.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as ModalWithArtwork from "./ModalWithArtwork.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as IconCheckmarkCircle from "./IconCheckmarkCircle.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var TrialStartError = /* @__PURE__ */Caml_exceptions.create("BillingPromptModal.TrialStartError");

var artworkListStyles = Curry._1(Css.style, {
      hd: Css.listStyleType("none"),
      tl: {
        hd: Css.padding(Css.px(0)),
        tl: /* [] */0
      }
    });

var artworkListItemStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.marginBottom(Css.px(20)),
          tl: /* [] */0
        }
      }
    });

var artworkMoreStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(16)),
        tl: {
          hd: Css.bottom(Css.px(16)),
          tl: {
            hd: Css.left(Css.px(16)),
            tl: /* [] */0
          }
        }
      }
    });

var listStyles = Curry._1(Css.style, {
      hd: Css.margin(Css.px(0)),
      tl: /* [] */0
    });

var askAdminStyles = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.light04),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.paddingTop(Css.px(10)),
          tl: {
            hd: Css.paddingRight(Css.px(20)),
            tl: {
              hd: Css.paddingBottom(Css.px(10)),
              tl: {
                hd: Css.paddingLeft(Css.px(20)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var buttonsStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: /* [] */0
    });

function isOnTeamPlan(paywallName) {
  return !(paywallName === "OpenAuditLog" || paywallName === "ForceBranchAudit" || paywallName === "AuthenticationMethods" || paywallName === "IssueReportConfigForceCasing");
}

function BillingPromptModal$Artwork(Props) {
  var paywallName = Props.paywallName;
  var trialStatus = Props.trialStatus;
  if (trialStatus === /* Success */5) {
    return React.createElement(Icons.UnlockIllustration.make, {});
  } else if (paywallName === "AddBranchCollaborator" || paywallName === "BranchApprovalWorkflows") {
    return React.createElement(Icons.ApprovalWorkflowIllustration.make, {});
  } else if (paywallName === "InspectorVolume" || paywallName === "InspectorTimeWindow") {
    return React.createElement(Icons.InspectorIllustration.make, {});
  } else if (paywallName === "ImplementWithAvo" || paywallName === "InviteCommentOnly" || paywallName === "ExpandActivityLog" || paywallName === "PinnedProperties" || paywallName === "AddSources" || paywallName === "NameMapping" || paywallName === "AddDestinations" || paywallName === "DuplicateEvents" || paywallName === "IssueReportConfig" || paywallName === "ExpandBranchAudit" || paywallName === "AddServiceAccount" || paywallName === "OpenAuditLog" || paywallName === "ForceBranchAudit" || paywallName === "AuthenticationMethods" || paywallName === "IssueReportConfigForceCasing" || paywallName === "PropertyMatchesBySource" || paywallName === "AddEventVariant") {
    return null;
  } else if (paywallName === "PublishIntegration" || paywallName === "AddIntegration") {
    return React.createElement(Icons.IntegrationsIllustration.make, {});
  } else if (paywallName === "AddComment") {
    return React.createElement(Icons.CommentsIllustration.make, {});
  } else if (paywallName === "AddEventTrigger") {
    return React.createElement(Icons.TriggersIllustration.make, {});
  } else if (paywallName === "TryForFree") {
    return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                    element: "Span",
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: React.createElement("ul", {
                          className: artworkListStyles
                        }, React.createElement("li", {
                              className: artworkListItemStyles
                            }, React.createElement(IconCheckmarkCircle.make, {
                                  size: 28,
                                  color: Styles.Color.darkBlue
                                }), React.createElement(Spacer.make, {
                                  width: 16
                                }), "Event triggers and variants"), React.createElement("li", {
                              className: artworkListItemStyles
                            }, React.createElement(IconCheckmarkCircle.make, {
                                  size: 28,
                                  color: Styles.Color.darkBlue
                                }), React.createElement(Spacer.make, {
                                  width: 16
                                }), "Unlimited editor seats"), React.createElement("li", {
                              className: artworkListItemStyles
                            }, React.createElement(IconCheckmarkCircle.make, {
                                  size: 28,
                                  color: Styles.Color.darkBlue
                                }), React.createElement(Spacer.make, {
                                  width: 16
                                }), "Approval workflows"), React.createElement("li", {
                              className: artworkListItemStyles
                            }, React.createElement(IconCheckmarkCircle.make, {
                                  size: 28,
                                  color: Styles.Color.darkBlue
                                }), React.createElement(Spacer.make, {
                                  width: 16
                                }), "5 schema integrations"))
                  }), React.createElement("div", {
                    className: artworkMoreStyles
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        textAlign: "center",
                        color: Styles.Color.light12,
                        children: "And more…"
                      })));
  } else if (paywallName === "ConfigureEventsPerPlatform") {
    return React.createElement(Icons.SourceDependendPropertiesIllustration.make, {});
  } else {
    return React.createElement(Icons.EditorSeatsIllustration.make, {});
  }
}

var Artwork = {
  make: BillingPromptModal$Artwork
};

function BillingPromptModal(Props) {
  var onClose = Props.onClose;
  var onCloseAll = Props.onCloseAll;
  var paywallName = Props.paywallName;
  var viewerRole = Props.viewerRole;
  var match = ViewerContext.use(undefined);
  var viewerId = match.id;
  var workspace = WorkspaceContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match$1 = TrialRequest.useTrialRequest(undefined);
  var requestTrial = match$1[1];
  var trialStatus = match$1[0];
  var globalSend = GlobalSendContext.use(undefined);
  React.useEffect((function () {
          var match = Router.getStaticRoute(undefined);
          AnalyticsRe.upgradePlanPrompted(schemaBundle, paywallName, "Popup", Router.schemaRouteToAnalyticsLocation(match.schemaRoute, match.drawerItems), schemaBundle.branchId, schemaBundle.schemaId, schemaBundle.schemaId, schemaBundle.schemaBillingStatus, schemaBundle.schemaTrialPlan, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlanSlug, Belt_Option.getWithDefault(schemaBundle.schemaName, schemaBundle.schemaId));
          
        }), []);
  var handleExplorePlans = function (param) {
    var match = Router.getStaticRoute(undefined);
    AnalyticsRe.subscriptionInterestIndicated(schemaBundle, DateFns.differenceInCalendarDays(new Date(), Belt_Option.getWithDefault(Belt_Option.mapWithDefault(workspace.trial, workspace.createdAt, (function (trial) {
                        return Caml_option.some(trial.createdAt);
                      })), new Date())) | 0, 14, schemaBundle.branchId, schemaBundle.schemaId);
    AnalyticsRe.upgradePromptInteraction(schemaBundle, "UpgradeNow", "Popup", AvoLimits.hoursLeftOfTrial(workspace), paywallName, Router.schemaRouteToAnalyticsLocation(match.schemaRoute, match.drawerItems), undefined, undefined, schemaBundle.branchId, schemaBundle.schemaId);
    var match$1 = workspace.plan.name;
    if (match$1 !== 6) {
      if (match$1 >= 13) {
        window.open("https://www.avo.app/pricing", "_blank");
        return ;
      } else {
        Curry._1(onCloseAll, undefined);
        return Router.Schema.pushSchemaRoute(undefined, undefined, "billing");
      }
    } else {
      window.open("https://www.avo.app/pricing", "_blank");
      return ;
    }
  };
  var handleClose = function (param) {
    Curry._1(onClose, undefined);
    var match = Router.getStaticRoute(undefined);
    return AnalyticsRe.upgradePromptInteraction(schemaBundle, "Dismiss", "Popup", AvoLimits.hoursLeftOfTrial(workspace), paywallName, Router.schemaRouteToAnalyticsLocation(match.schemaRoute, match.drawerItems), undefined, undefined, schemaBundle.branchId, schemaBundle.schemaId);
  };
  var handleStartTrial = function (param) {
    $$Promise.$$catch(Curry._1(requestTrial, viewerId), (function (error) {
              Curry._1(globalSend, {
                    TAG: /* OpenModal */4,
                    _0: {
                      NAME: "AlertModal",
                      VAL: [
                        "Could not start trial",
                        "Something went wrong when starting your trial. Please try again or reach out to us.",
                        "Continue",
                        (function (param) {
                            
                          })
                      ]
                    }
                  });
              return Promise.reject({
                          RE_EXN_ID: TrialStartError,
                          _1: error
                        });
            })).then(function (param) {
          var match = Router.getStaticRoute(undefined);
          AnalyticsRe.workspaceTrialStarted(schemaBundle.schemaId, Date.now() / 1000.0, workspace.name);
          AnalyticsRe.upgradePromptInteraction(schemaBundle, "StartTrial", "Popup", AvoLimits.hoursLeftOfTrial(workspace), paywallName, Router.schemaRouteToAnalyticsLocation(match.schemaRoute, match.drawerItems), 14, "Team", schemaBundle.branchId, schemaBundle.schemaId);
          return Promise.resolve(undefined);
        });
    
  };
  var canTrialTeamPlan;
  var exit = 0;
  if (trialStatus !== 2 && trialStatus < 4) {
    canTrialTeamPlan = false;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    canTrialTeamPlan = viewerRole === "Admin" && isOnTeamPlan(paywallName) && (workspace.plan.name === /* Free */1 || workspace.plan.name === /* FreeV2 */2 || workspace.plan.name === /* FreeV3 */10);
  }
  return React.createElement(ModalWithArtwork.make, {
              artwork: React.createElement(BillingPromptModal$Artwork, {
                    paywallName: paywallName,
                    trialStatus: trialStatus
                  }),
              artworkKey: trialStatus === /* Success */5 ? "success" : "image",
              contentKey: trialStatus === /* Success */5 ? "trial" : "noTrial",
              onClose: handleClose,
              title: trialStatus === /* Success */5 ? "Welcome to your trial!" : (
                  paywallName === "AddBranchCollaborator" || paywallName === "BranchApprovalWorkflows" ? "Unlock approval workflows" : (
                      paywallName === "InspectorTimeWindow" ? "Unlock 7 day observability" : (
                          paywallName === "AddEventVariant" ? "Unlock event variants" : (
                              paywallName === "PublishIntegration" || paywallName === "AddIntegration" ? "Unlock more integrations" : (
                                  paywallName === "PropertyMatchesBySource" ? "Unlock source-dependent property values" : (
                                      paywallName === "AddComment" ? "Unlock comments" : (
                                          paywallName === "IssueReportConfigForceCasing" ? "Unlock enforcing event and property casing" : (
                                              paywallName === "InspectorVolume" ? "Unlock more Inspector event volume" : (
                                                  paywallName === "AuthenticationMethods" ? "Unlock SSO authentication methods" : (
                                                      paywallName === "ForceBranchAudit" ? "Unlock enforcing of branch audit" : (
                                                          paywallName === "OpenAuditLog" ? "Unlock the audit log" : (
                                                              paywallName === "AddServiceAccount" ? "Unlock up to " + Workspace.printMaxThreshold(Workspace.Instances.teamV2.maxServiceAccounts) + " service accounts" : (
                                                                  paywallName === "ExpandBranchAudit" ? "Unlock branch audit" : (
                                                                      paywallName === "IssueReportConfig" ? "Unlock configuring your audit" : (
                                                                          paywallName === "DuplicateEvents" ? "Unlock duplicating events" : (
                                                                              paywallName === "AddDestinations" ? "Unlock unlimited destinations" : (
                                                                                  paywallName === "NameMapping" ? "Unlock Name mapping" : (
                                                                                      paywallName === "AddSources" ? "Unlock unlimited destinations" : (
                                                                                          paywallName === "PinnedProperties" ? "Unlock pinning values of properties" : (
                                                                                              paywallName === "AddEventTrigger" ? "Unlock Triggers" : (
                                                                                                  paywallName === "TryForFree" ? "Start your 14 day trial" : (
                                                                                                      paywallName === "ExpandActivityLog" ? "Unlock full activity logs" : (
                                                                                                          paywallName === "InviteCommentOnly" ? "Unlock more granual roles" : (
                                                                                                              paywallName === "ConfigureEventsPerPlatform" ? "Unlock source-dependent properties" : (
                                                                                                                  paywallName === "ImplementWithAvo" ? "Unlock Avo Codegen" : "Unlock more editor seats"
                                                                                                                )
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                ),
              children: trialStatus === /* Success */5 ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: "You are now able to..."
                        }), React.createElement(Spacer.make, {
                          height: 4
                        }), React.createElement($$Text.make, {
                          element: "Span",
                          size: "Medium",
                          color: Styles.Color.light11,
                          children: React.createElement("ul", {
                                className: listStyles
                              }, React.createElement("li", undefined, "Invite the whole team"), React.createElement("li", undefined, "Document screenshots of event triggers"), React.createElement("li", undefined, "Discuss, review and approve changes"), React.createElement("li", undefined, "Publish to five integrations"), React.createElement("li", undefined, "Observe your tracking for the last 7 days"))
                        }), React.createElement(Spacer.make, {
                          height: 24
                        }), React.createElement(Button.make, {
                          label: "Let’s go",
                          onClick: handleClose,
                          size: "large"
                        })) : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                          size: "Medium",
                          children: null
                        }, paywallName === "AddBranchCollaborator" || paywallName === "BranchApprovalWorkflows" ? "Your team can review and approve each others’ branches.\"" : (
                            paywallName === "InspectorVolume" || paywallName === "InspectorTimeWindow" ? "Don’t let event issues go unseen. More observability gives you better insights into your data health." : (
                                paywallName === "AddEventVariant" ? "Event variants allow you to create context specific variations of your event to increase you data definition accuracy and speed up implementation." : (
                                    paywallName === "PublishIntegration" || paywallName === "AddIntegration" ? "Integrate with your favourite downstream tools." : (
                                        paywallName === "PropertyMatchesBySource" ? "Define your tracking plan with higher precision for more granular tracking with source-dependent property values." : (
                                            paywallName === "AddComment" ? "A great Tracking Plan is a team effort and Avo helps you collaborate better." : (
                                                paywallName === "IssueReportConfigForceCasing" ? "Sometimes a hint just isn’t enough. Upgrade your plan to enforce the preferred casing for all new and changed event and property names." : (
                                                    paywallName === "AuthenticationMethods" ? "Link your Avo workspace to your Google organization or SAML SSO provider for better user management and increased security." : (
                                                        paywallName === "ForceBranchAudit" ? "Only allow merging of branches that pass all branch audit checks." : (
                                                            paywallName === "OpenAuditLog" ? "See all access and changes made to your tracking plan using our comprehensive audit log." : (
                                                                paywallName === "AddServiceAccount" ? "Integrate with Avo API in your services" : (
                                                                    paywallName === "ExpandBranchAudit" ? "Evaluate the quality of your branch and quickly make fixes where needed to ensure clean and consistent data" : (
                                                                        paywallName === "IssueReportConfig" ? " Toggle audit rules to your liking and set your desired casing." : (
                                                                            paywallName === "DuplicateEvents" ? "Create an exact replica of an existing event to speed up your workflow when defining similar events." : (
                                                                                paywallName === "AddDestinations" ? "Document where you are sending data to with more destinations." : (
                                                                                    paywallName === "NameMapping" ? "Customize how your events and properties are sent to different destinations." : (
                                                                                        paywallName === "AddSources" ? "Document from where you are sending data with more sources." : (
                                                                                            paywallName === "PinnedProperties" ? "Pin the values of properties on any event." : (
                                                                                                paywallName === "AddEventTrigger" ? "Triggers let you visually communicate when an event is sent." : (
                                                                                                    paywallName === "TryForFree" ? "No strings attached, no credit card, just a week of all our best features!" : (
                                                                                                        paywallName === "ExpandActivityLog" ? "See the full history of your tracking plan and its items from when they were created." : (
                                                                                                            paywallName === "InviteCommentOnly" ? "You don’t need to give everyone admin access to your workspace." : (
                                                                                                                paywallName === "ConfigureEventsPerPlatform" ? "Define if a property is always, sometimes or never sent sent for each data source." : (
                                                                                                                    paywallName === "ImplementWithAvo" ? "Smoothen your tracking implementation with Codegen." : "See what your team can achieve with unlimited editors and up to 20 comment seats."
                                                                                                                  )
                                                                                                              )
                                                                                                          )
                                                                                                      )
                                                                                                  )
                                                                                              )
                                                                                          )
                                                                                      )
                                                                                  )
                                                                              )
                                                                          )
                                                                      )
                                                                  )
                                                              )
                                                          )
                                                      )
                                                  )
                                              )
                                          )
                                      )
                                  )
                              )
                          ), canTrialTeamPlan && paywallName !== "TryForFree" ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    height: 12
                                  }), " Try it out by activating a free Avo Team Plan trial (no credit card needed).") : null), React.createElement(Spacer.make, {
                          height: 28
                        }), viewerRole === "Admin" ? (
                        canTrialTeamPlan && paywallName === "TryForFree" ? React.createElement(Button.make, {
                                disabled: trialStatus === /* Pending */4,
                                label: "Start trial now",
                                loading: trialStatus === /* Pending */4,
                                onClick: handleStartTrial,
                                size: "large"
                              }) : (
                            canTrialTeamPlan ? React.createElement("div", {
                                    className: buttonsStyles
                                  }, React.createElement(Button.make, {
                                        disabled: trialStatus === /* Pending */4,
                                        label: "Start 14 day trial",
                                        loading: trialStatus === /* Pending */4,
                                        onClick: handleStartTrial,
                                        size: "large"
                                      }), React.createElement(Spacer.make, {
                                        width: 16
                                      }), React.createElement(Button.make, {
                                        disabled: trialStatus === /* Pending */4,
                                        label: "Explore plans",
                                        onClick: handleExplorePlans,
                                        size: "large",
                                        style: "outline"
                                      })) : React.createElement(Button.make, {
                                    label: "Explore plans",
                                    onClick: handleExplorePlans,
                                    size: "large"
                                  })
                          )
                      ) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                                className: askAdminStyles
                              }, React.createElement($$Text.make, {
                                    size: "Medium",
                                    weight: "Regular",
                                    color: Styles.Color.light10,
                                    children: "Contact an Admin of your workspace to upgrade"
                                  })), React.createElement(Spacer.make, {
                                height: 24
                              }), React.createElement(Button.make, {
                                label: "Close",
                                onClick: handleClose,
                                size: "large"
                              })))
            });
}

var make = BillingPromptModal;

export {
  TrialStartError ,
  artworkListStyles ,
  artworkListItemStyles ,
  artworkMoreStyles ,
  listStyles ,
  askAdminStyles ,
  buttonsStyles ,
  isOnTeamPlan ,
  Artwork ,
  make ,
  
}
/* artworkListStyles Not a pure module */
