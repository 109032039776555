// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "../Link.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "../Icons.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Styles from "../styles.mjs";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as ImplementationStatusRow from "./ImplementationStatusRow.mjs";
import * as ImplementationStatusPriority from "./ImplementationStatusPriority.mjs";
import * as ExpandImplementationStatusListButton from "./ExpandImplementationStatusListButton.mjs";
import * as InspectorImplementationStatusDisclaimer from "./InspectorImplementationStatusDisclaimer.mjs";

var popup = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                        NAME: "num",
                        VAL: 0.10
                      }))),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.flexDirection("column"),
              tl: {
                hd: Css.overflow("hidden"),
                tl: {
                  hd: Css.width(Css.px(300)),
                  tl: {
                    hd: Css.maxHeight(Css.px(640)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var popupHeader = Curry._1(Css.style, {
      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
      tl: {
        hd: Css.paddingTop(Css.px(12)),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.paddingBottom(Css.px(12)),
            tl: {
              hd: Css.paddingLeft(Css.px(20)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var popupContent = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.flexShrink(1.0),
        tl: {
          hd: Css.overflowY("auto"),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

var popupContentSection = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(16), Css.px(8), Css.px(16), Css.px(20)),
      tl: {
        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
        tl: {
          hd: Css.lastChild({
                hd: Css.borderBottomWidth(Css.px(0)),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var popupSectionHeader = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.backdropFilter({
                  hd: {
                    NAME: "blur",
                    VAL: Css.px(10)
                  },
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
              tl: {
                hd: Css.paddingBottom(Css.px(8)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var Style = {
  popup: popup,
  popupHeader: popupHeader,
  popupContent: popupContent,
  popupContentSection: popupContentSection,
  popupSectionHeader: popupSectionHeader
};

var installLinkStyles = Curry._1(Css.style, {
      hd: Css.display("block"),
      tl: {
        hd: Css.marginTop(Css.px(8)),
        tl: {
          hd: Css.color(Styles.Color.darkBlue),
          tl: {
            hd: Css.textDecoration("none"),
            tl: {
              hd: Css.fontWeight(Styles.FontWeight.semi),
              tl: {
                hd: Css.fontSize(Styles.FontSize.tiny),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: 200.0
                      }, undefined, undefined, "color"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.deepBlue),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function SourcePillImplementationStatusPopup(Props) {
  var source = Props.source;
  var inspectorStatuses = Props.inspectorStatuses;
  var codegenStatus = Props.codegenStatus;
  var $$event = Props.event;
  var isEventModified = Props.isEventModified;
  var sourceName = AvoConfig.getSourceName(source);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var highlightedImplementationStatus = ImplementationStatusPriority.highlightedStatus(isEventModified, codegenStatus, inspectorStatuses);
  React.useEffect((function () {
          var timeoutId = setTimeout((function (param) {
                  var tmp;
                  if (highlightedImplementationStatus.TAG === /* Inspector */0) {
                    switch (highlightedImplementationStatus._0) {
                      case /* Prod */0 :
                          tmp = "InspectorProduction";
                          break;
                      case /* Dev */1 :
                          tmp = "InspectorDevelopment";
                          break;
                      case /* Staging */2 :
                          tmp = "InspectorStaging";
                          break;
                      
                    }
                  } else {
                    tmp = "Functions";
                  }
                  var tmp$1;
                  if (highlightedImplementationStatus.TAG === /* Inspector */0) {
                    var match = highlightedImplementationStatus._1;
                    if (typeof match === "number") {
                      tmp$1 = match !== 0 ? "InspectorNotInstalled" : "Loading";
                    } else {
                      var tmp$2 = match._0.eventStatus;
                      if (typeof tmp$2 === "number") {
                        tmp$1 = "NeverSeen";
                      } else {
                        switch (tmp$2.TAG | 0) {
                          case /* AlwaysSeenCorrectlyLatestVersion */0 :
                              tmp$1 = "AlwaysSentCorrectly";
                              break;
                          case /* SometimesSeenCorrectlyLatestVersion */1 :
                              tmp$1 = "SometimesSentCorrectly";
                              break;
                          case /* NeverSeenCorrectlyLatestVersion */2 :
                              tmp$1 = "NeverSentCorrectly";
                              break;
                          case /* AlwaysSeenCorrectlyInOlderVersion */3 :
                              tmp$1 = "AlwaysSentCorrectlyInPreviousVersion";
                              break;
                          case /* SometimesSeenCorrectlyInOlderVersion */4 :
                              tmp$1 = "SometimesSentCorrectlyInPreviousVersion";
                              break;
                          case /* NeverSeenCorrectlyInOlderVersion */5 :
                              tmp$1 = "NeverSentCorrectlyInPreviousVersion";
                              break;
                          
                        }
                      }
                    }
                  } else {
                    var match$1 = highlightedImplementationStatus._0;
                    if (typeof match$1 === "number") {
                      tmp$1 = match$1 !== 0 ? "NotImplementedWithFunctions" : "Loading";
                    } else {
                      var match$2 = match$1._0;
                      tmp$1 = match$2 ? (
                          match$1._1 ? (
                              match$2 ? "SometimesSentCorrectly" : "NeverSentCorrectly"
                            ) : "AlwaysSentCorrectly"
                        ) : (
                          match$1._1 ? (
                              match$2 ? "SometimesSentCorrectly" : "NeverSentCorrectly"
                            ) : "NeverSeen"
                        );
                    }
                  }
                  return AnalyticsRe.implementationStatusDetailsViewed(schemaBundle, AnalyticsRe.Bundle.source(source.id, sourceName), $$event.id, $$event.name, tmp, tmp$1, schemaBundle.branchId, schemaBundle.schemaId);
                }), 500);
          return (function (param) {
                    clearTimeout(timeoutId);
                    
                  });
        }), []);
  var match = React.useState(function () {
        return false;
      });
  var expanded = match[0];
  var codegenSection = React.createElement("section", {
        key: "codegen",
        className: popupContentSection
      }, React.createElement("header", {
            className: popupSectionHeader
          }, React.createElement($$Text.make, {
                element: "H1",
                size: "Tiny",
                color: Styles.Color.light10,
                children: "CODEGEN"
              })), React.createElement(ImplementationStatusRow.make, {
            implementationStatus: codegenStatus,
            eventName: $$event.name,
            longDescription: false,
            key: "implementation-status-functions"
          }));
  var inspectorSection = React.createElement("section", {
        key: "inspector",
        className: popupContentSection
      }, React.createElement("header", {
            className: popupSectionHeader
          }, React.createElement($$Text.make, {
                element: "H1",
                size: "Tiny",
                color: Styles.Color.light10,
                children: "INSPECTOR"
              })), Belt_Array.mapWithIndexU(Belt_Array.slice(inspectorStatuses, 0, 1), (function (index, status) {
              return React.createElement(ImplementationStatusRow.make, {
                          implementationStatus: status,
                          eventName: $$event.name,
                          longDescription: false,
                          key: "implementation-status-" + String(index)
                        });
            })), inspectorStatuses.length > 1 ? React.createElement(ExpandImplementationStatusListButton.make, {
              expanded: expanded,
              setExpanded: match[1]
            }) : null, expanded ? Belt_Array.mapWithIndexU(Belt_Array.sliceToEnd(inspectorStatuses, 1), (function (index, status) {
                return React.createElement(ImplementationStatusRow.make, {
                            implementationStatus: status,
                            eventName: $$event.name,
                            longDescription: false,
                            key: "implementation-status-" + String(index)
                          });
              })) : null, React.createElement(InspectorImplementationStatusDisclaimer.make, {
            expanded: expanded,
            inspectorImplementationStatuses: inspectorStatuses,
            collapsedVisibleImplementationStatuses: Belt_Array.slice(inspectorStatuses, 0, 1)
          }), Belt_Array.everyU(inspectorStatuses, (function (status) {
              if (status.TAG !== /* Inspector */0) {
                return false;
              }
              var match = status._1;
              if (typeof match === "number") {
                return match !== 0;
              } else {
                return false;
              }
            })) ? React.createElement(Link.make, {
              path: Router.Link.addDrawerItem(undefined, {
                    NAME: "source",
                    VAL: [
                      source.id,
                      "inspectorSetup"
                    ]
                  }),
              className: installLinkStyles,
              children: null
            }, "Install Inspector on " + sourceName + " ", React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("inlineBlock"),
                        tl: {
                          hd: Css.transform(Css.rotate(Css.deg(180.0))),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(Icons.ArrowLeft.make, {
                      size: 8,
                      color: Styles.Color.darkBlue
                    }))) : null);
  return React.createElement("aside", {
              className: popup
            }, React.createElement("header", {
                  className: popupHeader
                }, React.createElement($$Text.make, {
                      element: "H1",
                      size: "Medium",
                      weight: "Medium",
                      color: Styles.Color.light12,
                      children: "Implementation Status"
                    }), React.createElement("div", undefined, React.createElement($$Text.make, {
                          element: "B",
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light10,
                          lineHeight: Css.pct(100.0),
                          children: $$event.name
                        }), React.createElement($$Text.make, {
                          element: "Span",
                          size: "Small",
                          weight: "Regular",
                          color: Styles.Color.light10,
                          lineHeight: Css.pct(100.0),
                          children: " sent from "
                        }), React.createElement($$Text.make, {
                          element: "B",
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light10,
                          lineHeight: Css.pct(100.0),
                          children: sourceName
                        }))), React.createElement("div", {
                  className: popupContent
                }, ImplementationStatusPriority.shouldShowCodegenFirst(isEventModified, inspectorStatuses, codegenStatus) ? React.createElement(React.Fragment, undefined, codegenSection, inspectorSection) : React.createElement(React.Fragment, undefined, inspectorSection, codegenSection)));
}

var make = SourcePillImplementationStatusPopup;

export {
  Style ,
  installLinkStyles ,
  make ,
  
}
/* popup Not a pure module */
