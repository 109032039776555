// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import Shortid from "shortid";
import * as AvoModel from "./avoModel.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameInput from "./NameInput.mjs";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as NameInputUtils from "./NameInputUtils.mjs";
import * as StateFilterUtils from "./StateFilterUtils.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function isValid(name, expectedPropertyCase, model) {
  return NameInputUtils.isValid(NameInputUtils.validateInput(ModelUtils.getPropertyNamespace__mapped(model), name, name, expectedPropertyCase));
}

function getCreatedToast(id, name) {
  return {
          message: "Property \"" + name + "\" created 🎉",
          toastType: /* Success */0,
          action: {
            message: "View",
            toastCallback: (function (param) {
                return Router.Schema.pushDrawerItem(undefined, undefined, {
                            NAME: "property",
                            VAL: [
                              id,
                              undefined
                            ]
                          });
              })
          }
        };
}

function trackPropertyCreated(eventId, name, schemaBundle, sendAs, filters, sourceFilterNames, namingConvention, propertyLocation, correctCase, propertyId) {
  return AnalyticsRe.propertyCreated(schemaBundle, AnalyticsRe.Bundle.property(propertyId, name, Case.toAnalytics(namingConvention), AnalyticsUtils.sendAsToPropertyType(sendAs), correctCase), Belt_List.toArray(AvoModel.getTagFilters(filters)), sourceFilterNames, eventId, propertyLocation, AnalyticsUtils.propertyLocationToOrigin(propertyLocation), schemaBundle.branchId, schemaBundle.schemaId);
}

function createProperty(propertyId, parent, sendActions, sendAs, name, namingConvention, correctCase, addToast, schemaBundle, filters, sourceFilterNames, propertyLocation, model, onSuccessOpt, extraCreateActionsOpt, currentFilters, param) {
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param, param$1, param$2) {
        
      });
  var extraCreateActions = extraCreateActionsOpt !== undefined ? extraCreateActionsOpt : (function (param) {
        return [];
      });
  if (parent !== undefined) {
    var variant = parent.NAME;
    if (variant === "Event") {
      var eventId = parent.VAL;
      Curry.app(sendActions, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            (function (branch) {
                Curry._1(addToast, getCreatedToast(propertyId, name));
                Curry._3(onSuccess, propertyId, name, branch);
                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                trackPropertyCreated(eventId, name, schemaBundle$1, sendAs, filters, sourceFilterNames, namingConvention, propertyLocation, correctCase, propertyId);
                var propertyGroup = AnalyticsRe.Bundle.property(propertyId, name, Case.toAnalytics(Case.getCasingWithWorkspaceCase(name, namingConvention)), AnalyticsUtils.sendAsToPropertyType(sendAs), correctCase);
                AnalyticsRe.propertyAdded(schemaBundle$1, propertyGroup, currentFilters, parent !== undefined && typeof parent === "object" && parent.NAME === "Event" ? parent.VAL : "N/A", parent !== undefined && typeof parent === "object" && parent.NAME === "Event" ? Belt_Option.mapWithDefault(ModelUtils.getEventById(parent.VAL, model), "N/A", (function ($$event) {
                              return $$event.name;
                            })) : "N/A", name.length, "New", Case.to_("Sentence", name).split(" ").length, name.length, propertyLocation, schemaBundle$1.branchId, schemaBundle$1.schemaId);
                
              }),
            undefined,
            Belt_Array.concat([[
                    {
                      NAME: "AddProperty",
                      VAL: [
                        eventId,
                        propertyId,
                        name,
                        sendAs
                      ]
                    },
                    {
                      eventId: eventId,
                      eventQuery: eventId,
                      propertyId: propertyId,
                      propertyQuery: propertyId
                    }
                  ]], Curry._1(extraCreateActions, propertyId))
          ]);
      return ;
    }
    if (variant === "PropertyGroup") {
      var groupId = parent.VAL;
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                      Curry._1(addToast, getCreatedToast(propertyId, name));
                      Curry._3(onSuccess, propertyId, name, branch);
                      return trackPropertyCreated("N/A", name, schemaBundle$1, sendAs, filters, sourceFilterNames, namingConvention, propertyLocation, correctCase, propertyId);
                    }),
                  undefined,
                  Belt_Array.concat([
                        [
                          {
                            NAME: "CreateProperty",
                            VAL: [
                              propertyId,
                              name,
                              sendAs
                            ]
                          },
                          {
                            propertyId: propertyId,
                            propertyQuery: propertyId
                          }
                        ],
                        [
                          {
                            NAME: "AddPropertyToGroup",
                            VAL: [
                              groupId,
                              propertyId
                            ]
                          },
                          {
                            propertyId: propertyId,
                            propertyQuery: propertyId,
                            propertyGroupId: groupId,
                            propertyGroupQuery: groupId
                          }
                        ]
                      ], Curry._1(extraCreateActions, propertyId))
                ]);
    }
    var parentPropertyId = parent.VAL;
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (branch) {
              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
              Curry._1(addToast, getCreatedToast(propertyId, name));
              Curry._3(onSuccess, propertyId, name, branch);
              return trackPropertyCreated("N/A", name, schemaBundle$1, sendAs, filters, sourceFilterNames, namingConvention, propertyLocation, correctCase, propertyId);
            }),
          undefined,
          Belt_Array.concat([
                [
                  {
                    NAME: "CreateProperty",
                    VAL: [
                      propertyId,
                      name,
                      sendAs
                    ]
                  },
                  {
                    propertyId: propertyId,
                    propertyQuery: propertyId
                  }
                ],
                [
                  {
                    NAME: "AddPropertyValidation",
                    VAL: [
                      parentPropertyId,
                      {
                        NAME: "NestedProperty",
                        VAL: {
                          hd: {
                            id: propertyId,
                            description: "",
                            pinnedValue: undefined
                          },
                          tl: /* [] */0
                        }
                      }
                    ]
                  },
                  {
                    propertyId: parentPropertyId,
                    propertyQuery: parentPropertyId
                  }
                ]
              ], Curry._1(extraCreateActions, propertyId))
        ]);
    return ;
  }
  Curry.app(sendActions, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (function (branch) {
            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
            Curry._1(addToast, getCreatedToast(propertyId, name));
            Curry._3(onSuccess, propertyId, name, branch);
            return trackPropertyCreated("N/A", name, schemaBundle$1, sendAs, filters, sourceFilterNames, namingConvention, propertyLocation, correctCase, propertyId);
          }),
        undefined,
        Belt_Array.concat([[
                {
                  NAME: "CreateProperty",
                  VAL: [
                    propertyId,
                    name,
                    sendAs
                  ]
                },
                {
                  propertyId: propertyId,
                  propertyQuery: propertyId
                }
              ]], Curry._1(extraCreateActions, propertyId))
      ]);
  
}

function NewPropertyModal(Props) {
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var model = Props.model;
  var name = Props.name;
  var onClose = Props.onClose;
  var onCreateOpt = Props.onCreate;
  var parent = Props.parent;
  var propertyLocation = Props.propertyLocation;
  var sendAs = Props.sendAs;
  var onCreate = onCreateOpt !== undefined ? onCreateOpt : (function (param, param$1) {
        
      });
  var modelMapped = ModelStore.Mapped.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var propertyId = React.useRef(Shortid());
  var match = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match.expectedPropertyCase;
  var match$1 = GlobalStateContext.use(undefined);
  var sourceFilterNames = StateFilterUtils.getSourceFilterNames(match$1.filters, model);
  React.useEffect((function () {
          AnalyticsRe.propertyInitiated(schemaBundle, propertyId.current, name, Case.toAnalytics(Case.getCasingWithWorkspaceCase(name, expectedPropertyCase)), NameUtils.isCorrectCase(expectedPropertyCase, name), AnalyticsUtils.sendAsToPropertyType(sendAs), sourceFilterNames, Belt_List.toArray(AvoModel.getTagFilters(filters)), parent !== undefined && typeof parent === "object" && parent.NAME === "Event" ? parent.VAL : "N/A", schemaBundle.branchId, schemaBundle.schemaId);
          
        }), []);
  var tmp;
  if (typeof sendAs === "number") {
    switch (sendAs) {
      case /* SystemProperty */0 :
          tmp = "Create System Property";
          break;
      case /* EventProperty */1 :
          tmp = "Create Event Property";
          break;
      case /* UserProperty */2 :
          tmp = "Create User Property";
          break;
      
    }
  } else {
    tmp = "Create Group Property";
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(600)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(75)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(NameInput.make, {
                  name: name,
                  existingNames: ModelUtils.getPropertyNamespace__mapped(modelMapped),
                  expectedCase: expectedPropertyCase,
                  forceCase: Curry._1(ValidationConfigV2.Rule.isEnforced, match.config.properties.case),
                  autoFocus: true,
                  onChange: (function (name, namingConvention, correctCase) {
                      return createProperty(propertyId.current, parent, sendActions, sendAs, name, namingConvention, correctCase, addToast, schemaBundle, filters, sourceFilterNames, propertyLocation, model, (function (param, param$1, param$2) {
                                    Curry._2(onCreate, propertyId.current, name);
                                    return Curry._1(onClose, undefined);
                                  }), undefined, currentFilters, undefined);
                    }),
                  submitLabel: tmp,
                  placeholder: "Property Name",
                  onClose: (function (param) {
                      return Curry._1(onClose, undefined);
                    }),
                  fullscreen: true,
                  dirty: name !== "",
                  itemType: "Property",
                  actionType: "Create",
                  getPossibleItemLink: (function (propertyName) {
                      return Belt_Option.map(ModelUtils.getPropertyByName(propertyName, model), (function (param) {
                                    return Router.Link.addDrawerItem(undefined, {
                                                NAME: "property",
                                                VAL: [
                                                  param.id,
                                                  undefined
                                                ]
                                              });
                                  }));
                    })
                }));
}

var make = NewPropertyModal;

export {
  isValid ,
  getCreatedToast ,
  trackPropertyCreated ,
  createProperty ,
  make ,
  
}
/* Css Not a pure module */
