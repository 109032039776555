// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as CmdK from "./CmdK.mjs";
import * as Kbar from "kbar";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Metric from "./Metric.mjs";
import * as Portal from "./Portal.mjs";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as $$Window from "./externals/window.mjs";
import * as Category from "./Category.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Destination from "./Destination.mjs";
import * as EventsSheet from "./EventsSheet.mjs";
import * as Integration from "./integration/Integration.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as DrawerHeader from "./detailviews/DrawerHeader.mjs";
import * as EventDetails from "./detailviews/EventDetails.mjs";
import * as EventVariant from "./EventVariant.mjs";
import * as SourceConfig from "./SourceConfig.mjs";
import * as SandboxBanner from "./SandboxBanner.mjs";
import * as FramerMotion from "framer-motion";
import * as PropertyDetails from "./detailviews/PropertyDetails.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as CodegenSourceDetails from "./CodegenSourceDetails.mjs";
import * as PropertyBundleDetails from "./detailviews/PropertyBundleDetails.mjs";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as FlattenVariantsUseCase from "../../model/src/variants/flattenVariantsUseCase.mjs";
import * as TriggerFullscreenModal from "./TriggerFullscreenModal.mjs";
import * as InspectorIssueDetailsV2 from "./InspectorIssueDetailsV2.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function DrawerContainer$ItemNotFound(Props) {
  var kind = Props.kind;
  return React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
                  kind: kind,
                  children: "Not Found"
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.pct(100.0)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, "Oh noes, this item wasn’t found"));
}

var ItemNotFound = {
  make: DrawerContainer$ItemNotFound
};

function slideOverStyles(withSandboxBanner) {
  return Curry._1(Css.style, {
              hd: Css.position("fixed"),
              tl: {
                hd: Css.top(Css.px(SandboxBanner.getHeight(withSandboxBanner))),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(0)),
                    tl: {
                      hd: Css.width(Css.px(700)),
                      tl: {
                        hd: Css.zIndex(1),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.white),
                          tl: {
                            hd: Css.boxShadow(Styles.Shadow.standard),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function useAnalyticsForDrawerContainer(topDrawerItem, model) {
  var schemaBundle = SchemaBundleContext.use(undefined);
  React.useEffect((function () {
          var maybeAnalyticsItem;
          if (topDrawerItem !== undefined) {
            var variant = topDrawerItem.NAME;
            if (variant === "codegenSource") {
              var itemId = topDrawerItem.VAL;
              maybeAnalyticsItem = [
                itemId,
                "CodegenSource",
                Belt_Option.map(ModelUtils.getSourceById(itemId, model), AvoConfig.getSourceName)
              ];
            } else if (variant === "eventVariant") {
              var variantIdentifier = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                variantIdentifier.variantId,
                "EventVariant",
                Belt_Option.map(ModelUtils.getEventVariantByIdWithArchive(model, variantIdentifier), (function (param) {
                        return param.nameSuffix;
                      }))
              ];
            } else if (variant === "event") {
              var itemId$1 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$1,
                "Event",
                Belt_Option.map(ModelUtils.getEventById(itemId$1, model), (function (param) {
                        return param.name;
                      }))
              ];
            } else if (variant === "inspectorIssue") {
              maybeAnalyticsItem = [
                topDrawerItem.VAL[0],
                "InspectorIssue",
                undefined
              ];
            } else if (variant === "metric") {
              var itemId$2 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$2,
                "Metric",
                Belt_Option.map(ModelUtils.getMetricById(itemId$2, model), (function (param) {
                        return param.name;
                      }))
              ];
            } else if (variant === "destination") {
              var itemId$3 = topDrawerItem.VAL;
              maybeAnalyticsItem = [
                itemId$3,
                "Destination",
                Belt_Option.map(ModelUtils.getDestinationById(itemId$3, model), ModelUtils.getDestinationName)
              ];
            } else if (variant === "integration") {
              var itemId$4 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$4,
                "Integration",
                Belt_Option.map(ModelUtils.getIntegrationById(itemId$4, model), AvoConfig.getIntegrationName)
              ];
            } else if (variant === "property") {
              var itemId$5 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$5,
                "Property",
                Belt_Option.map(ModelUtils.resolvePropertyById(itemId$5, model), (function (param) {
                        return param.name;
                      }))
              ];
            } else if (variant === "category") {
              var itemId$6 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$6,
                "Goal",
                Belt_Option.map(ModelUtils.getGoalById(itemId$6, model), (function (param) {
                        return param.name;
                      }))
              ];
            } else if (variant === "source") {
              var itemId$7 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$7,
                "Source",
                Belt_Option.map(ModelUtils.getSourceById(itemId$7, model), AvoConfig.getSourceName)
              ];
            } else {
              var itemId$8 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$8,
                "PropertyGroup",
                Belt_Option.map(ModelUtils.getPropertyGroupById(itemId$8, model), (function (param) {
                        return param.name;
                      }))
              ];
            }
          } else {
            maybeAnalyticsItem = undefined;
          }
          if (maybeAnalyticsItem !== undefined) {
            AnalyticsRe.itemDetailsOpened(schemaBundle, maybeAnalyticsItem[0], Belt_Option.getWithDefault(maybeAnalyticsItem[2], ""), maybeAnalyticsItem[1], schemaBundle.schemaId);
          }
          
        }), [topDrawerItem]);
  
}

function DrawerContainer(Props) {
  var branchStatus = Props.branchStatus;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var masterModel = Props.masterModel;
  var member = Props.member;
  var model = Props.model;
  var renderModel = Props.renderModel;
  var role = Props.role;
  var schema = Props.schema;
  var match = RouterStore.Schema.useSlices(function (param) {
        return [
                param.branch,
                param.drawerItems
              ];
      });
  var drawerItems = match[1];
  var branch = match[0];
  var currentBranch = React.useMemo((function () {
          if (typeof branch === "object") {
            return {
                    NAME: "Branch",
                    VAL: branch.VAL
                  };
          } else {
            return "Master";
          }
        }), [branch]);
  var branchModel = typeof currentBranch === "object" ? ({
        TAG: /* Branch */1,
        _0: renderModel,
        _1: masterModel
      }) : ({
        TAG: /* Main */0,
        _0: renderModel
      });
  var model$1 = branchModel._0;
  var toMappedProperties = Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, model$1.properties);
  var toMappedPropertyBundles = Curry._1(TrackingPlanMappedModel.PropertyBundles.fromList, model$1.propertyBundles);
  var match$1;
  if (drawerItems) {
    var otherDrawerItems = drawerItems.tl;
    var topDrawerItem = drawerItems.hd;
    match$1 = otherDrawerItems ? [
        topDrawerItem,
        otherDrawerItems
      ] : [
        topDrawerItem,
        /* [] */0
      ];
  } else {
    match$1 = [
      undefined,
      /* [] */0
    ];
  }
  var otherDrawerItems$1 = match$1[1];
  var topDrawerItem$1 = match$1[0];
  var currentBranch$1;
  if (typeof branch === "object" && branch.NAME === "branch") {
    var branchId = branch.VAL;
    currentBranch$1 = branchId !== "master" ? ({
          NAME: "Branch",
          VAL: branchId
        }) : "Master";
  } else {
    currentBranch$1 = "Master";
  }
  var disableHotKeys = Belt_Option.mapWithDefault(topDrawerItem$1, false, (function (item) {
          if (typeof item !== "object") {
            return false;
          }
          var variant = item.NAME;
          if (variant === "eventVariant") {
            return item.VAL[1] !== undefined;
          } else if (variant === "event") {
            return item.VAL[2] !== undefined;
          } else {
            return false;
          }
        }));
  var workspace = WorkspaceContext.use(undefined);
  useAnalyticsForDrawerContainer(topDrawerItem$1, model);
  Kbar.useRegisterActions(Belt_List.length(drawerItems) > 0 && !disableHotKeys ? [Curry.app(CmdK.Action.t, [
                "drawer_close",
                {
                  TAG: /* RootItem */0,
                  _0: "Close Drawer"
                },
                1.1,
                ["escape"],
                undefined,
                undefined,
                (function (param) {
                    var closeButtonElement = $$Window.$$Document.getElementById("drawer-container-close");
                    return Belt_Option.forEach(closeButtonElement, (function (prim) {
                                  prim.click();
                                  
                                }));
                  }),
                undefined,
                undefined
              ])] : [], [
        drawerItems,
        disableHotKeys
      ]);
  Kbar.useRegisterActions(Belt_List.length(drawerItems) > 1 && !disableHotKeys ? [Curry.app(CmdK.Action.t, [
                "drawer_prev",
                {
                  TAG: /* RootItem */0,
                  _0: "Go to previous drawer item"
                },
                1.1,
                ["arrowleft"],
                undefined,
                undefined,
                (function (param) {
                    return Router.Schema.popDrawerItem(undefined, undefined);
                  }),
                undefined,
                undefined
              ])] : [], [
        drawerItems,
        disableHotKeys
      ]);
  var tmp;
  if (topDrawerItem$1 !== undefined && typeof topDrawerItem$1 === "object") {
    var variant = topDrawerItem$1.NAME;
    if (variant === "eventVariant") {
      var match$2 = topDrawerItem$1.VAL;
      var triggerId = match$2[1];
      if (triggerId !== undefined) {
        var variantIdentifier = match$2[0];
        var baseEvent = ModelUtils.getEventById(variantIdentifier.baseEventId, model);
        var eventVariant = ModelUtils.getEventVariantById(model, variantIdentifier);
        var triggerExists = Belt_Option.flatMap(eventVariant, (function (eventVariant) {
                return Belt_Array.getBy(eventVariant.triggers, (function (param) {
                              return param.id === triggerId;
                            }));
              })) !== undefined || triggerId === "new";
        if (baseEvent !== undefined && eventVariant !== undefined) {
          if (triggerExists) {
            var flattenedVariantModel = FlattenVariantsUseCase.flattenOneVariant(model, baseEvent, eventVariant);
            var flattenedVariantEvent = Belt_Option.getExn(Belt_List.getByU(flattenedVariantModel.events, (function ($$event) {
                        return $$event.id === VariantEventIdUseCase.variantEventId(variantIdentifier);
                      })));
            tmp = React.createElement(TriggerFullscreenModal.make, {
                  currentBranch: currentBranch$1,
                  event: flattenedVariantEvent,
                  variantIdentifier: variantIdentifier,
                  eventVariant: eventVariant,
                  triggerId: triggerId,
                  modelSources: flattenedVariantModel.sources,
                  numEvents: Belt_List.length(flattenedVariantModel.events),
                  onClose: (function (param) {
                      return Router.Schema.swapTopDrawerItem({
                                  NAME: "eventVariant",
                                  VAL: [
                                    variantIdentifier,
                                    undefined
                                  ]
                                });
                    }),
                  viewerCanEdit: role === "Admin" || role === "Editor"
                });
          } else {
            Router.Schema.swapTopDrawerItem({
                  NAME: "eventVariant",
                  VAL: [
                    variantIdentifier,
                    undefined
                  ]
                });
            tmp = null;
          }
        } else {
          Router.Schema.popDrawerItem(undefined, undefined);
          tmp = null;
        }
      } else {
        tmp = null;
      }
    } else if (variant === "event") {
      var match$3 = topDrawerItem$1.VAL;
      var triggerId$1 = match$3[2];
      if (triggerId$1 !== undefined) {
        var commentId = match$3[1];
        var eventId = match$3[0];
        var $$event = ModelUtils.getEventById(eventId, model);
        var triggerExists$1 = Belt_Option.flatMap($$event, (function ($$event) {
                return Belt_Array.getBy($$event.triggers, (function (param) {
                              return param.id === triggerId$1;
                            }));
              })) !== undefined || triggerId$1 === "new";
        if ($$event !== undefined) {
          if (triggerExists$1) {
            tmp = React.createElement(TriggerFullscreenModal.make, {
                  currentBranch: currentBranch$1,
                  event: $$event,
                  triggerId: triggerId$1,
                  modelSources: model.sources,
                  numEvents: Belt_List.length(model.events),
                  onClose: (function (param) {
                      return Router.Schema.swapTopDrawerItem({
                                  NAME: "event",
                                  VAL: [
                                    eventId,
                                    commentId,
                                    undefined,
                                    false
                                  ]
                                });
                    }),
                  viewerCanEdit: role === "Admin" || role === "Editor"
                });
          } else {
            Router.Schema.swapTopDrawerItem({
                  NAME: "event",
                  VAL: [
                    eventId,
                    commentId,
                    undefined,
                    false
                  ]
                });
            tmp = null;
          }
        } else {
          Router.Schema.popDrawerItem(undefined, undefined);
          tmp = null;
        }
      } else {
        tmp = null;
      }
    } else {
      tmp = null;
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Portal.make, {
                  children: React.createElement(FramerMotion.AnimatePresence, {
                        children: Belt_Option.isSome(topDrawerItem$1) ? React.createElement(FramerMotion.motion.div, {
                                id: "drawer-container",
                                animate: {
                                  opacity: 1,
                                  x: 0
                                },
                                transition: {
                                  duration: 0.3
                                },
                                initial: {
                                  opacity: 0,
                                  x: 50
                                },
                                exit: {
                                  opacity: 0,
                                  x: 50
                                },
                                className: slideOverStyles(workspace.isSandbox),
                                key: "slideOver",
                                children: Belt_Option.mapWithDefault(topDrawerItem$1, React.createElement(DrawerContainer$ItemNotFound, {
                                          kind: "Item"
                                        }), (function (drawerItem) {
                                        var variant = drawerItem.NAME;
                                        if (variant === "codegenSource") {
                                          return React.createElement(CodegenSourceDetails.make, {
                                                      model: renderModel,
                                                      sourceId: drawerItem.VAL,
                                                      rawModel: model,
                                                      role: role
                                                    });
                                        }
                                        if (variant === "eventVariant") {
                                          var variantIdentifier = drawerItem.VAL[0];
                                          var $$event = ModelUtils.getEventById(variantIdentifier.baseEventId, model);
                                          if ($$event === undefined) {
                                            return React.createElement(DrawerContainer$ItemNotFound, {
                                                        kind: "Event Variant"
                                                      });
                                          }
                                          var variant$1 = Belt_Array.getBy($$event.variants, (function (variant) {
                                                  return variant.id === variantIdentifier.variantId;
                                                }));
                                          if (variant$1 !== undefined) {
                                            return React.createElement(EventVariant.make, {
                                                        baseEvent: $$event,
                                                        variant: variant$1,
                                                        viewerCanEdit: RoleUtils.canEdit(role),
                                                        model: model,
                                                        isEventModified: EventsSheet.isEventModified(toMappedPropertyBundles, toMappedProperties, branchModel, $$event),
                                                        currentFilters: currentFilters,
                                                        currentBranch: currentBranch$1,
                                                        openBranches: masterModel.openBranches,
                                                        filters: filters,
                                                        branchStatus: branchStatus
                                                      });
                                          } else {
                                            return React.createElement(DrawerContainer$ItemNotFound, {
                                                        kind: "Event Variant"
                                                      });
                                          }
                                        }
                                        if (variant === "event") {
                                          return Belt_Option.mapWithDefault(ModelUtils.getEventById(drawerItem.VAL[0], renderModel), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Event"
                                                        }), (function ($$event) {
                                                        return React.createElement(EventDetails.make, {
                                                                    branchStatus: branchStatus,
                                                                    event: $$event,
                                                                    schema: schema,
                                                                    currentBranch: currentBranch$1,
                                                                    openBranches: masterModel.openBranches,
                                                                    currentFilters: currentFilters,
                                                                    filters: filters,
                                                                    role: role,
                                                                    isEventModified: EventsSheet.isEventModified(toMappedPropertyBundles, toMappedProperties, branchModel, $$event)
                                                                  });
                                                      }));
                                        }
                                        if (variant === "inspectorIssue") {
                                          var match = drawerItem.VAL;
                                          var id = match[0];
                                          var tmp = {
                                            id: id,
                                            openBranches: masterModel.openBranches,
                                            viewerRole: role,
                                            key: id
                                          };
                                          var tmp$1 = match[1];
                                          if (tmp$1 !== undefined) {
                                            tmp.goToCommentId = Caml_option.valFromOption(tmp$1);
                                          }
                                          return React.createElement(InspectorIssueDetailsV2.make, tmp);
                                        }
                                        if (variant === "metric") {
                                          var match$1 = drawerItem.VAL;
                                          var maybeCommentId = match$1[1];
                                          return Belt_Option.mapWithDefault(ModelUtils.getMetricById(match$1[0], model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Metric"
                                                        }), (function (metric) {
                                                        return React.createElement(Metric.make, {
                                                                    currentBranch: currentBranch$1,
                                                                    currentFilters: currentFilters,
                                                                    filters: filters,
                                                                    goToCommentId: maybeCommentId,
                                                                    metric: metric,
                                                                    model: model,
                                                                    openBranches: masterModel.openBranches,
                                                                    role: role,
                                                                    schema: schema,
                                                                    key: metric.id
                                                                  });
                                                      }));
                                        }
                                        if (variant === "destination") {
                                          var destinationId = drawerItem.VAL;
                                          return Belt_Option.mapWithDefault(ModelUtils.getDestinationById(destinationId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Destination"
                                                        }), (function (param) {
                                                        return React.createElement(Destination.make, {
                                                                    destinationId: destinationId,
                                                                    model: model
                                                                  });
                                                      }));
                                        }
                                        if (variant === "integration") {
                                          var match$2 = drawerItem.VAL;
                                          var goToCommentId = match$2[1];
                                          var integrationId = match$2[0];
                                          return Belt_Option.mapWithDefault(ModelUtils.getIntegrationById(integrationId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Integration"
                                                        }), (function (param) {
                                                        return React.createElement(Integration.make, {
                                                                    integrationId: integrationId,
                                                                    goToCommentId: goToCommentId,
                                                                    integrations: renderModel.integrations,
                                                                    openBranches: masterModel.openBranches,
                                                                    currentBranch: currentBranch$1,
                                                                    model: model,
                                                                    role: role,
                                                                    key: integrationId
                                                                  });
                                                      }));
                                        }
                                        if (variant === "property") {
                                          var match$3 = drawerItem.VAL;
                                          var maybeCommentId$1 = match$3[1];
                                          var propertyId = match$3[0];
                                          return Belt_Option.mapWithDefault(ModelUtils.getPropertyById(propertyId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Property"
                                                        }), (function (param) {
                                                        return React.createElement(PropertyDetails.make, {
                                                                    currentBranch: currentBranch$1,
                                                                    currentFilters: currentFilters,
                                                                    filters: filters,
                                                                    eventId: undefined,
                                                                    goToCommentId: maybeCommentId$1,
                                                                    member: member,
                                                                    model: renderModel,
                                                                    openBranches: masterModel.openBranches,
                                                                    propertyId: propertyId,
                                                                    role: role,
                                                                    schema: schema
                                                                  });
                                                      }));
                                        }
                                        if (variant === "category") {
                                          var match$4 = drawerItem.VAL;
                                          var maybeCommentId$2 = match$4[1];
                                          return Belt_Option.mapWithDefault(ModelUtils.getGoalById(match$4[0], model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Category"
                                                        }), (function (category) {
                                                        return React.createElement(Category.make, {
                                                                    category: category,
                                                                    currentBranch: currentBranch$1,
                                                                    goToCommentId: maybeCommentId$2,
                                                                    openBranches: masterModel.openBranches,
                                                                    model: model,
                                                                    role: role,
                                                                    currentFilters: currentFilters,
                                                                    key: category.id
                                                                  });
                                                      }));
                                        }
                                        if (variant === "source") {
                                          var match$5 = drawerItem.VAL;
                                          var tab = match$5[1];
                                          var sourceId = match$5[0];
                                          return Belt_Option.mapWithDefault(ModelUtils.getSourceById(sourceId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Source"
                                                        }), (function (param) {
                                                        return React.createElement(SourceConfig.make, {
                                                                    currentBranch: currentBranch$1,
                                                                    tab: tab,
                                                                    model: renderModel,
                                                                    sourceId: sourceId,
                                                                    viewerRole: role
                                                                  });
                                                      }));
                                        }
                                        var match$6 = drawerItem.VAL;
                                        var goToCommentId$1 = match$6[1];
                                        var bundleId = match$6[0];
                                        return Belt_Option.mapWithDefault(ModelUtils.getPropertyGroupById(bundleId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                        kind: "Property Bundle"
                                                      }), (function (param) {
                                                      return React.createElement(PropertyBundleDetails.make, {
                                                                  currentBranch: currentBranch$1,
                                                                  currentFilters: currentFilters,
                                                                  filters: filters,
                                                                  event: Belt_Option.flatMapU(Belt_List.get(otherDrawerItems$1, 0), (function (nextItem) {
                                                                          if (typeof nextItem === "object" && nextItem.NAME === "event") {
                                                                            return ModelUtils.getEventById(nextItem.VAL[0], model);
                                                                          }
                                                                          
                                                                        })),
                                                                  goToCommentId: goToCommentId$1,
                                                                  bundleId: bundleId,
                                                                  member: member,
                                                                  openBranches: masterModel.openBranches,
                                                                  role: role,
                                                                  schema: schema
                                                                });
                                                    }));
                                      }))
                              }) : null
                      })
                }), tmp);
}

var drawerWidth = 700;

var make = DrawerContainer;

export {
  ItemNotFound ,
  drawerWidth ,
  slideOverStyles ,
  useAnalyticsForDrawerContainer ,
  make ,
  
}
/* Css Not a pure module */
