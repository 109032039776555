// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Input from "./Input.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

var root = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(10)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.padding(Css.px(24)),
          tl: {
            hd: Css.width(Css.px(580)),
            tl: {
              hd: Css.maxHeight({
                    NAME: "subtract",
                    VAL: [
                      Css.vh(100.0),
                      Css.px(32)
                    ]
                  }),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("column"),
                  tl: {
                    hd: Css.alignItems("flexStart"),
                    tl: {
                      hd: Css.boxShadows({
                            hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(8), Css.px(18), Css.px(-6), undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.12)),
                            tl: {
                              hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(12), Css.px(42), Css.px(-4), undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.12)),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var labelRow = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.width(Css.pct(100.0)),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.paddingLeft(Css.px(11)),
            tl: {
              hd: Css.marginTop(Css.px(16)),
              tl: {
                hd: Css.marginBottom(Css.px(2)),
                tl: {
                  hd: Css.paddingRight(Css.px(11)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var buttons = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignSelf("stretch"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.justifyContent("flexEnd"),
            tl: {
              hd: Css.gap(Css.px(16)),
              tl: {
                hd: Css.marginTop(Css.px(16)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function AddRegexValidationModal(Props) {
  var onClose = Props.onClose;
  var propertyId = Props.propertyId;
  var maybeEventId = Props.maybeEventId;
  var sendActions = SendActionsContext.use(undefined);
  var properties = ModelStore.Mapped.useProperties(undefined);
  var events = ModelStore.Mapped.useEvents(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var testValueInputRef = React.useRef(null);
  var property = Belt_Option.getExn(Curry._2(TrackingPlanMappedModel.Properties.get, properties, propertyId));
  var maybeEvent = Belt_Option.flatMap(maybeEventId, (function (eventId) {
          return Curry._2(TrackingPlanMappedModel.Events.get, events, eventId);
        }));
  var match = PropertyUtils.getRegexValidation(property);
  var maybeExisting;
  if (maybeEventId !== undefined) {
    maybeExisting = match !== undefined ? Belt_MapString.get(match.eventOverrides, maybeEventId) : undefined;
  } else if (match !== undefined) {
    var propertyRule = match.propertyRule;
    maybeExisting = propertyRule !== undefined ? propertyRule : undefined;
  } else {
    maybeExisting = undefined;
  }
  var match$1 = React.useState(function () {
        return Belt_Option.mapWithDefault(maybeExisting, "", (function (rule) {
                      return rule.regex;
                    }));
      });
  var setRegex = match$1[1];
  var regex = match$1[0];
  var match$2 = React.useState(function () {
        return Belt_Option.mapWithDefault(maybeExisting, "", (function (rule) {
                      return rule.testValue;
                    }));
      });
  var setSample = match$2[1];
  var sample = match$2[0];
  var handleSave = function (param) {
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (branch) {
              if (maybeEventId !== undefined) {
                return AnalyticsRe.regexInteractionEventSpecific(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), maybeExisting === undefined ? "Created" : "Updated", propertyId, maybeEventId, schemaBundle.schemaId, NamedBranch.getId(branch));
              } else {
                return AnalyticsRe.regexInteraction(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), maybeExisting === undefined ? "Created" : "Updated", propertyId, schemaBundle.schemaId, NamedBranch.getId(branch));
              }
            }),
          undefined,
          [[
              {
                NAME: "UpdatePropertyRegexValidation",
                VAL: [
                  propertyId,
                  maybeEventId,
                  {
                    regex: regex,
                    testValue: sample
                  }
                ]
              },
              {
                propertyId: propertyId,
                propertyQuery: propertyId
              }
            ]]
        ]);
    return Curry._1(onClose, undefined);
  };
  var isValid;
  try {
    new RegExp(regex);
    isValid = true;
  }
  catch (exn){
    isValid = false;
  }
  var sampleIsValid;
  try {
    sampleIsValid = new RegExp(regex).test(sample);
  }
  catch (exn$1){
    sampleIsValid = false;
  }
  var tmp = regex === "" ? null : (
      isValid ? React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.darkGreen,
              children: "Valid regex"
            }) : React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.darkRed,
              children: "Invalid regex"
            })
    );
  var tmp$1;
  tmp$1 = isValid && sample !== "" ? (
      sampleIsValid ? React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.darkGreen,
              children: "Matches regex pattern"
            }) : React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.darkRed,
              children: "Does not match regex pattern"
            })
    ) : null;
  return React.createElement("section", {
              className: root
            }, React.createElement(Title.make, {
                  children: "Add Regex validation",
                  size: "Small"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.light11,
                  children: maybeEventId === undefined ? "Define a custom Regular Expression (Regex) for the property. The validation applies to the property wherever it is added, ensuring consistent validation or pattern matching across all instances where the property is used." : "Define a custom Regular Expression (Regex) for the property when it is sent with this event. This action will not impact any other events to which the property is attached."
                }), React.createElement(Spacer.make, {
                  height: 16
                }), maybeEvent !== undefined ? React.createElement($$Text.make, {
                    size: "Medium",
                    color: Styles.Color.light12,
                    children: null
                  }, "For ", React.createElement($$Text.make, {
                        element: "Span",
                        weight: "Semi",
                        children: maybeEvent.name
                      }), " event the property ", React.createElement($$Text.make, {
                        element: "Span",
                        weight: "Semi",
                        children: property.name
                      }), " should match:") : React.createElement($$Text.make, {
                    size: "Medium",
                    color: Styles.Color.light12,
                    children: null
                  }, "The property ", React.createElement($$Text.make, {
                        element: "Span",
                        weight: "Semi",
                        children: property.name
                      }), " should match:"), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: labelRow
                }, React.createElement($$Text.make, {
                      element: "Label",
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      htmlFor: "regex",
                      children: "Regular Expression"
                    }), tmp), React.createElement(Input.make, {
                  value: regex,
                  onChange: (function (value) {
                      return Curry._1(setRegex, (function (param) {
                                    return value;
                                  }));
                    }),
                  id: "regex",
                  autoFocus: true,
                  onEnter: (function ($$event) {
                      var value = $$event.target.value;
                      if (value === "") {
                        return ;
                      }
                      var dom = testValueInputRef.current;
                      if (!(dom == null)) {
                        dom.focus();
                        return ;
                      }
                      
                    }),
                  placeholder: "Type or paste in your regex..."
                }), React.createElement("div", {
                  className: labelRow
                }, React.createElement($$Text.make, {
                      element: "Label",
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      htmlFor: "regex",
                      children: "Sample string"
                    }), tmp$1), React.createElement(Input.make, {
                  value: sample,
                  onChange: (function (value) {
                      return Curry._1(setSample, (function (param) {
                                    return value;
                                  }));
                    }),
                  id: "testValue",
                  onEnter: (function (param) {
                      if (regex !== "") {
                        return handleSave(undefined);
                      }
                      
                    }),
                  placeholder: "Type in a sample value to test regex against...",
                  inputRef: testValueInputRef
                }), React.createElement("div", {
                  className: buttons
                }, React.createElement(Button.make, {
                      label: "Cancel",
                      onClick: (function (param) {
                          return Curry._1(onClose, undefined);
                        }),
                      size: "large",
                      style: "ghost"
                    }), React.createElement(Button.make, {
                      disabled: regex === "" || !isValid,
                      label: maybeExisting === undefined ? "Add Regex" : "Edit Regex",
                      onClick: handleSave,
                      size: "large"
                    })));
}

var make = AddRegexValidationModal;

export {
  make ,
  
}
/* root Not a pure module */
