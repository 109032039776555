// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Snippet from "./Snippet.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as CodegenCommons from "../../codegen/src/CodegenCommons.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function SourceSnippet(Props) {
  var model = Props.model;
  var source = Props.source;
  var $$event = Props.event;
  var useServerTrackingWithDeviceId = AppFeatureFlag.useFeatureFlag("ServerTrackingWithDeviceId");
  var schemaBundle = SchemaBundleContext.use(undefined);
  var snippet = CodegenCommons.snippet(useServerTrackingWithDeviceId, model, source, $$event);
  return React.createElement("div", undefined, source.platform !== "Gtm" ? React.createElement("pre", {
                    className: Curry._1(Css.style, {
                          hd: Css.fontFamily(Styles.FontFamily.polar),
                          tl: {
                            hd: Css.padding2(Css.px(10), Css.px(20)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.light02),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.regular),
                                  tl: {
                                    hd: Css.color(Styles.Color.light12),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, "avo pull --branch " + schemaBundle.branchName + " \"" + source.name + "\"") : null, React.createElement(Snippet.make, {
                  onClick: (function (param) {
                      return AnalyticsRe.codeSnippetInteracted(schemaBundle, AnalyticsRe.Bundle.source(source.id, AvoConfig.getSourceValidName(source)), $$event.id, $$event.name, "CopyButtonClick", schemaBundle.branchId, schemaBundle.schemaId);
                    }),
                  language: source.language,
                  snippet: snippet
                }));
}

var make = SourceSnippet;

export {
  make ,
  
}
/* Css Not a pure module */
