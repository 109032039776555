// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Asset from "../Asset.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "../Icons.mjs";
import * as Title from "../Title.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Sidebar from "../Sidebar.mjs";
import * as EmptyState from "../EmptyState.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as EmptyStatesStyles from "../emptystates/EmptyStatesStyles.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as InspectorViewHeader from "./InspectorViewHeader.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.minHeight({
                  NAME: "subtract",
                  VAL: [
                    Css.vh(100.0),
                    Css.px(InspectorViewHeader.headerHeight)
                  ]
                }),
            tl: {
              hd: Css.marginTop(Css.px(InspectorViewHeader.headerHeight)),
              tl: {
                hd: Css.padding(Css.px(40)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var Style = {
  content: content
};

function InspectorEmptyState(Props) {
  var hasSourcesWithoutInspector = Props.hasSourcesWithoutInspector;
  var inspectorView = Props.inspectorView;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var emptyStateLocation = inspectorView === "events" ? "InspectorEvents" : "InspectorIssues";
  React.useEffect((function () {
          AnalyticsRe.emptyStateDisplayed(schemaBundle, emptyStateLocation, schemaBundle.schemaId);
          
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(InspectorViewHeader.Disabled.make, {
                  hasSourcesWithoutInspector: hasSourcesWithoutInspector,
                  inspectorView: inspectorView
                }), React.createElement("div", {
                  className: Curry._1(Css.merge, {
                        hd: content,
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.justifyContent("flexStart"),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.position("relative"),
                            tl: {
                              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                              tl: {
                                hd: Css.height(Css.px(164)),
                                tl: {
                                  hd: Css.width({
                                        NAME: "subtract",
                                        VAL: [
                                          Css.vw(100.0),
                                          Css.px(Sidebar.sidebarWidth + 4 | 0)
                                        ]
                                      }),
                                  tl: {
                                    hd: Css.marginLeft(Css.px(-38)),
                                    tl: {
                                      hd: Css.marginRight(Css.px(-38)),
                                      tl: {
                                        hd: Css.marginTop(Css.px(-38)),
                                        tl: {
                                          hd: Css.overflow("hidden"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, inspectorView === "issues" ? React.createElement("img", {
                            className: Curry._1(Css.style, {
                                  hd: Css.margin4(Css.px(40), Css.px(40), Css.px(0), Css.px(40)),
                                  tl: /* [] */0
                                }),
                            src: Asset.getPath("InspectorIssuesEmptyState.svg"),
                            width: "1198"
                          }) : (
                        inspectorView === "events" ? React.createElement("img", {
                                className: Curry._1(Css.style, {
                                      hd: Css.minWidth(Css.px(2456)),
                                      tl: {
                                        hd: Css.maxWidth(Css.px(2456)),
                                        tl: /* [] */0
                                      }
                                    }),
                                height: "264",
                                src: Asset.getPath("InspectorEmptyState.png"),
                                width: "2456"
                              }) : React.createElement("img", {
                                className: Curry._1(Css.style, {
                                      hd: Css.margin4(Css.px(40), Css.px(40), Css.px(0), Css.px(40)),
                                      tl: /* [] */0
                                    }),
                                src: Asset.getPath("InspectorIssuesSavedViewsEmptyState.png"),
                                width: "1198"
                              })
                      ), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.position("absolute"),
                                tl: {
                                  hd: Css.background({
                                        NAME: "linearGradient",
                                        VAL: [
                                          {
                                            NAME: "deg",
                                            VAL: 180.0
                                          },
                                          [
                                            [
                                              Css.pct(0.0),
                                              Styles.Color.setAlpha(Styles.Color.light02, 0.3)
                                            ],
                                            [
                                              Css.pct(100.0),
                                              Styles.Color.setAlpha(Styles.Color.light02, 1.0)
                                            ]
                                          ]
                                        ]
                                      }),
                                  tl: {
                                    hd: Css.top(Css.px(0)),
                                    tl: {
                                      hd: Css.width(Css.pct(100.0)),
                                      tl: {
                                        hd: Css.height(Css.pct(100.0)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        })), React.createElement(EmptyState.Container.make, {
                      artwork: inspectorView === "events" ? "inspector" : "inspectorIssues",
                      children: null
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.maxWidth(Css.px(320)),
                                tl: /* [] */0
                              })
                        }, React.createElement(Title.make, {
                              children: "Verify that your events are sent correctly",
                              size: "Small"
                            })), React.createElement(Spacer.make, {
                          height: 16
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          children: "Avo Inspector analyzes and audits the schema of your current tracking, without collecting any user data."
                        }), React.createElement(Spacer.make, {
                          height: 16
                        }), React.createElement(Button.make, {
                          label: "Set up Inspector",
                          onClick: (function (param) {
                              AnalyticsRe.emptyStateInteracted(schemaBundle, emptyStateLocation, "Setup", undefined);
                              return Curry._1(globalSend, {
                                          TAG: /* OpenModal */4,
                                          _0: hasSourcesWithoutInspector ? ({
                                                NAME: "SourceSetup",
                                                VAL: [
                                                  "inspectorSetup",
                                                  {
                                                    NAME: "inspector",
                                                    VAL: "events"
                                                  }
                                                ]
                                              }) : ({
                                                NAME: "CreateSource",
                                                VAL: "inspectorSetup"
                                              })
                                        });
                            })
                        }), React.createElement(Spacer.make, {
                          height: 32
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          children: "Learn more about setting up Inspector via your Data platform or via SDK:"
                        }), React.createElement(Spacer.make, {
                          height: 16
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.flexWrap("wrap"),
                                  tl: {
                                    hd: Css.gap(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement(EmptyState.LinkButtonWithIcon.make, {
                              url: "https://www.avo.app/docs/workspace/connect-inspector-to-segment",
                              icon: React.createElement(Icons.Destinations.Segment.make, {
                                    size: 16
                                  }),
                              label: "Segment",
                              emptyStateLocation: emptyStateLocation
                            }), React.createElement(EmptyState.LinkButtonWithIcon.make, {
                              url: "https://www.avo.app/docs/workspace/connect-inspector-to-rudderstack",
                              icon: React.createElement(Icons.Destinations.Rudderstack.make, {
                                    size: 16
                                  }),
                              label: "Rudderstack",
                              emptyStateLocation: emptyStateLocation
                            }), React.createElement(EmptyState.LinkButtonWithIcon.make, {
                              url: "https://www.avo.app/docs/workspace/connect-inspector-to-posthog",
                              icon: React.createElement(Icons.Destinations.Posthog.make, {
                                    size: 16
                                  }),
                              label: "PostHog",
                              emptyStateLocation: emptyStateLocation
                            }), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("none"),
                                    tl: {
                                      hd: Css.selector(" + a > svg:first-child path", {
                                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.avoPink),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }
                                  })
                            }), React.createElement(EmptyState.LinkButtonWithIcon.make, {
                              url: "https://www.avo.app/docs/implementation/avo-inspector-overview",
                              icon: React.createElement(Icon.make, {
                                    type_: "folder",
                                    size: "small"
                                  }),
                              label: "SDK",
                              emptyStateLocation: emptyStateLocation
                            })), React.createElement(Spacer.make, {
                          height: 16
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          children: null
                        }, "Head to ", React.createElement("a", {
                              className: Curry._1(Css.merge, {
                                    hd: EmptyStatesStyles.linkStyles,
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.textDecoration("none"),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              href: "https://www.avo.app/docs/data-design/start-using-inspector",
                              rel: "noopener",
                              target: "_blank",
                              onClick: (function (param) {
                                  return AnalyticsRe.emptyStateInteracted(schemaBundle, emptyStateLocation, "OpenDocs", "https://www.avo.app/docs/data-design/start-using-inspector");
                                })
                            }, React.createElement($$Text.make, {
                                  element: "Span",
                                  weight: "Semi",
                                  children: "our docs"
                                })), " for more details on setting up and using Avo Inspector."))));
}

var make = InspectorEmptyState;

export {
  Style ,
  make ,
  
}
/* content Not a pure module */
