// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Env from "../../shared/models/Env.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as CopyButton from "./CopyButton.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as InspectorStats from "./inspector/InspectorStats.mjs";
import * as InspectorViewApi from "./inspector/InspectorViewApi.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as InspectorViewInstallDocs from "./inspector/InspectorViewInstallDocs.mjs";
import * as InspectorViewInstallDocsGo from "./inspector/InspectorViewInstallDocsGo.mjs";
import * as InspectorViewInstallDocsIos from "./inspector/InspectorViewInstallDocsIos.mjs";
import * as InspectorViewInstallDocsJava from "./inspector/InspectorViewInstallDocsJava.mjs";
import * as InspectorViewInstallDocsNode from "./inspector/InspectorViewInstallDocsNode.mjs";
import * as InspectorViewInstallDocsAndroid from "./inspector/InspectorViewInstallDocsAndroid.mjs";
import * as InspectorViewInstallDocsFlutter from "./inspector/InspectorViewInstallDocsFlutter.mjs";
import * as InspectorViewInstallDocsPosthog from "./inspector/InspectorViewInstallDocsPosthog.mjs";
import * as InspectorViewInstallDocsSegment from "./inspector/InspectorViewInstallDocsSegment.mjs";
import * as InspectorViewInstallDocsJsFamily from "./inspector/InspectorViewInstallDocsJsFamily.mjs";
import * as InspectorViewInstallDocsScriptTag from "./inspector/InspectorViewInstallDocsScriptTag.mjs";
import * as InspectorViewInstallDocsRudderStack from "./inspector/InspectorViewInstallDocsRudderStack.mjs";
import * as InspectorViewInstallDocsGtmWebTemplate from "./inspector/InspectorViewInstallDocsGtmWebTemplate.mjs";

var root = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(32)),
      tl: {
        hd: Css.paddingRight(Css.px(40)),
        tl: {
          hd: Css.paddingBottom(Css.px(40)),
          tl: {
            hd: Css.paddingLeft(Css.px(60)),
            tl: /* [] */0
          }
        }
      }
    });

var link = Curry._1(Css.style, {
      hd: Css.fontWeight("semiBold"),
      tl: {
        hd: Css.color(Styles.Color.primaryPurpleShade1),
        tl: {
          hd: Css.textDecoration("none"),
          tl: {
            hd: Css.hover({
                  hd: Css.textDecoration("underline"),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var apiKeyLoading = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.height(Css.px(42)),
            tl: {
              hd: Css.width(Css.px(250)),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.marginLeft(Css.px(-9)),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                    tl: /* [] */0
                  }
                }
              }
            }
          }),
      tl: {
        hd: Curry._1(Css.style, Styles.shimmer),
        tl: /* [] */0
      }
    });

var apiKeyBox = Curry._1(Css.style, {
      hd: Css.width("fitContent"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.paddingTop(Css.px(4)),
                tl: {
                  hd: Css.paddingRight(Css.px(4)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(4)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(8)),
                      tl: {
                        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.marginLeft(Css.px(-9)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var apiKey = Curry._1(Css.style, {
      hd: Css.margin(Css.px(0)),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.polar),
        tl: {
          hd: Css.fontSize(Styles.FontSize.small),
          tl: {
            hd: Css.fontWeight(Styles.FontWeight.medium),
            tl: {
              hd: Css.color(Styles.Color.light12),
              tl: {
                hd: Css.marginRight(Css.px(24)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var statusLoading = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.height(Css.px(28)),
            tl: {
              hd: Css.width(Css.px(190)),
              tl: {
                hd: Css.borderRadius(Css.px(99)),
                tl: {
                  hd: Css.marginLeft(Css.px(-14)),
                  tl: /* [] */0
                }
              }
            }
          }),
      tl: {
        hd: Curry._1(Css.style, Styles.shimmer),
        tl: /* [] */0
      }
    });

var initializedPill = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.gap(Css.px(4)),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.width("fitContent"),
              tl: {
                hd: Css.background(Styles.Color.paleGreen),
                tl: {
                  hd: Css.borderRadius(Css.px(99)),
                  tl: {
                    hd: Css.paddingTop(Css.px(6)),
                    tl: {
                      hd: Css.paddingRight(Css.px(12)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(6)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(10)),
                          tl: {
                            hd: Css.marginLeft(Css.px(-12)),
                            tl: {
                              hd: Css.marginRight(Css.px(6)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var waitingPill = Curry._1(Css.style, {
      hd: Css.display("inlineFlex"),
      tl: {
        hd: Css.background(Styles.Color.light04),
        tl: {
          hd: Css.borderRadius(Css.px(99)),
          tl: {
            hd: Css.paddingTop(Css.px(6)),
            tl: {
              hd: Css.paddingRight(Css.px(12)),
              tl: {
                hd: Css.paddingBottom(Css.px(6)),
                tl: {
                  hd: Css.paddingLeft(Css.px(12)),
                  tl: {
                    hd: Css.marginLeft(Css.px(-12)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var docs = Curry._1(Css.style, {
      hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.borderRadius(Css.px(4)),
          tl: {
            hd: Css.marginTop(Css.px(4)),
            tl: {
              hd: Css.overflow("hidden"),
              tl: {
                hd: Css.marginLeft(Css.px(-16)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var docsNav = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.overflowX("auto"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.justifyContent("flexStart"),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.alignItems("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var docsNavItemBase_0 = Css.display("flex");

var docsNavItemBase_1 = {
  hd: Css.flexGrow(1.0),
  tl: {
    hd: Css.alignItems("center"),
    tl: {
      hd: Css.justifyContent("center"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.semi),
          tl: {
            hd: Css.lineHeight(Styles.LineHeight.regular),
            tl: {
              hd: Css.padding(Css.px(12)),
              tl: {
                hd: Css.borderTopWidth(Css.px(0)),
                tl: {
                  hd: Css.borderRightWidth(Css.px(1)),
                  tl: {
                    hd: Css.borderLeftWidth(Css.px(0)),
                    tl: {
                      hd: Css.borderStyle("solid"),
                      tl: {
                        hd: Css.borderRightColor(Styles.Color.light04),
                        tl: {
                          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                          tl: {
                            hd: Css.margin(Css.px(0)),
                            tl: {
                              hd: Css.lastChild({
                                    hd: Css.borderRightWidth(Css.px(0)),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var docsNavItemBase = {
  hd: docsNavItemBase_0,
  tl: docsNavItemBase_1
};

var docsNavItem = Curry._1(Css.style, Belt_List.concatMany([
          Styles.button,
          docsNavItemBase,
          {
            hd: Css.color(Styles.Color.light11),
            tl: {
              hd: Css.fontSize(Styles.FontSize.small),
              tl: {
                hd: Css.borderBottomColor(Styles.Color.light04),
                tl: /* [] */0
              }
            }
          }
        ]));

var docsNavItemSelected = Curry._1(Css.style, Belt_List.concatMany([
          Styles.button,
          docsNavItemBase,
          {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.fontSize(Styles.FontSize.small),
              tl: {
                hd: Css.color(Styles.Color.light12),
                tl: {
                  hd: Css.borderBottomColor("transparent"),
                  tl: /* [] */0
                }
              }
            }
          }
        ]));

var docsContent = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: {
        hd: Css.paddingTop(Css.px(24)),
        tl: /* [] */0
      }
    });

var Style = {
  root: root,
  link: link,
  apiKeyLoading: apiKeyLoading,
  apiKeyBox: apiKeyBox,
  apiKey: apiKey,
  statusLoading: statusLoading,
  initializedPill: initializedPill,
  waitingPill: waitingPill,
  docs: docs,
  docsNav: docsNav,
  docsNavItemBase: docsNavItemBase,
  docsNavItem: docsNavItem,
  docsNavItemSelected: docsNavItemSelected,
  docsContent: docsContent
};

function integrationProviderToString(provider) {
  switch (provider) {
    case /* Rudderstack */0 :
        return "RudderStack";
    case /* Segment */1 :
        return "Segment";
    case /* Tag */2 :
        return "HTML";
    case /* Posthog */3 :
        return "PostHog";
    case /* GoogleTagManager */4 :
        return "Google Tag Manager";
    
  }
}

function SourceConfigInspectorSetup(Props) {
  var currentBranch = Props.currentBranch;
  var onClose = Props.onClose;
  var source = Props.source;
  var inspectorStats = Props.inspectorStats;
  var handleTabChange = Props.handleTabChange;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = React.useState(function () {
        return /* Loading */0;
      });
  var setApiKey = match[1];
  var apiKeyState = match[0];
  var inspectorStats$1 = InspectorStats.useStatsWithPolling(typeof inspectorStats === "number" ? undefined : inspectorStats._0, undefined);
  var match$1 = React.useState(function () {
        return InspectorStats.getSourceStatus(source, inspectorStats$1);
      });
  var setStatus = match$1[1];
  var status = match$1[0];
  var integrationProviders = Caml_obj.caml_equal(source.platform, "Web") ? [
      /* Segment */1,
      /* Rudderstack */0,
      /* Posthog */3,
      /* Tag */2,
      /* GoogleTagManager */4
    ] : [
      /* Segment */1,
      /* Rudderstack */0,
      /* Posthog */3
    ];
  var match$2 = React.useState(function () {
        return {
                TAG: /* Stream */1,
                _0: /* Segment */1
              };
      });
  var setTab = match$2[1];
  var currentTab = match$2[0];
  React.useEffect((function () {
          Curry._1(setStatus, (function (param) {
                  return InspectorStats.getSourceStatus(source, inspectorStats$1);
                }));
          
        }), [inspectorStats$1]);
  React.useEffect((function () {
          Curry._1(setApiKey, (function (param) {
                  return /* Loading */0;
                }));
          $$Promise.$$catch(InspectorViewApi.getApiOrGenerateKey(workspace.id, source.id, workspace.name, AvoConfig.getSourceName(source)).then(function (apiKey) {
                    Curry._1(setApiKey, (function (param) {
                            return /* Success */{
                                    _0: apiKey
                                  };
                          }));
                    return Promise.resolve(undefined);
                  }), (function (error) {
                  console.log(error);
                  Curry._1(setApiKey, (function (param) {
                          return /* Error */1;
                        }));
                  return Promise.resolve(undefined);
                }));
          
        }), [
        workspace.id,
        source.id
      ]);
  var apiKeyWithPlaceholder = typeof apiKeyState === "number" ? "MY-API-KEY" : apiKeyState._0;
  var hasSeenEvents = typeof status === "number" ? false : true;
  var inspectorSupportedLanguages = AvoConfig.inspectorSupportedLanguages(source.platform);
  var tmp;
  if (source.platform === undefined) {
    tmp = React.createElement($$Text.make, {
          size: "Medium",
          weight: "Medium",
          color: Styles.Color.light12,
          children: null
        }, "Select a Development Platform from the ", React.createElement(TextButton.make, {
              onClick: (function (param) {
                  return Curry._1(handleTabChange, "overview");
                }),
              style: "Blue",
              children: "Overview tab"
            }), " to get started.");
  } else {
    var tmp$1;
    if (typeof apiKeyState === "number") {
      tmp$1 = apiKeyState !== 0 ? React.createElement($$Text.make, {
              size: "Medium",
              weight: "Semi",
              color: Styles.Color.red,
              children: "Something went wrong when loading the Inspector Api key."
            }) : React.createElement("div", {
              className: apiKeyLoading
            });
    } else {
      var apiKey$1 = apiKeyState._0;
      tmp$1 = React.createElement("div", {
            className: apiKeyBox
          }, React.createElement("p", {
                className: apiKey
              }, apiKey$1), React.createElement(CopyButton.make, {
                copyString: apiKey$1
              }));
    }
    var firstSupportedLanguage = Belt_Array.get(inspectorSupportedLanguages, 0);
    var tmp$2;
    if (firstSupportedLanguage !== undefined) {
      var defaultLanguage = Belt_Option.getWithDefault(Belt_Array.getBy(inspectorSupportedLanguages, (function (language) {
                  return Caml_obj.caml_equal(language, source.language);
                })), firstSupportedLanguage);
      var tmp$3;
      tmp$3 = currentTab.TAG === /* SDK */0 ? docsNavItemSelected : docsNavItem;
      tmp$2 = React.createElement("button", {
            className: tmp$3,
            onClick: (function (param) {
                return Curry._1(setTab, (function (param) {
                              return {
                                      TAG: /* SDK */0,
                                      _0: defaultLanguage
                                    };
                            }));
              })
          }, "SDK");
    } else {
      tmp$2 = null;
    }
    var tmp$4;
    if (currentTab.TAG === /* SDK */0) {
      var language = currentTab._0;
      var match$3 = source.platform;
      tmp$4 = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                size: "Medium",
                color: Styles.Color.light12,
                children: "The Inspector SDK extracts tracking plan information from your existing tracking calls. It provides methods to get the shape of an event you are going to send to the analytics platform and then to send that shape alongside the event name for analysis."
              }), React.createElement(Spacer.make, {
                height: 8
              }), React.createElement(InspectorViewInstallDocs.Link.make, {
                path: "reference/avo-inspector-sdks/overview",
                label: "Learn more about setting up Inspector via SDK"
              }), React.createElement(Spacer.make, {
                height: 24
              }), React.createElement($$Text.make, {
                size: "Medium",
                weight: "Semi",
                color: Styles.Color.light12,
                children: "Install instructions"
              }), React.createElement(Spacer.make, {
                height: 24
              }), React.createElement(Select.make, {
                onSelect: (function (language) {
                    var language$1 = AvoConfig.languageFromLabel(language);
                    if (language$1 !== undefined) {
                      return Curry._1(setTab, (function (param) {
                                    return {
                                            TAG: /* SDK */0,
                                            _0: language$1
                                          };
                                  }));
                    }
                    
                  }),
                options: Belt_Array.map(AvoConfig.inspectorSupportedLanguages(source.platform), (function (language) {
                        return [
                                {
                                  NAME: "Label",
                                  VAL: AvoConfig.languageLabel(language)
                                },
                                AvoConfig.languageLabel(language)
                              ];
                      })),
                value: AvoConfig.languageLabel(language)
              }), match$3 !== undefined ? (
              match$3 === "Expo" || match$3 === "Web" || match$3 === "ReactNative" ? React.createElement(InspectorViewInstallDocsJsFamily.make, {
                      apiKey: apiKeyWithPlaceholder,
                      language: language,
                      platform: match$3,
                      onClose: onClose
                    }) : (
                  match$3 === "Android" ? React.createElement(InspectorViewInstallDocsAndroid.make, {
                          apiKey: apiKeyWithPlaceholder,
                          language: language,
                          onClose: onClose
                        }) : (
                      match$3 === "Go" ? React.createElement(InspectorViewInstallDocsGo.make, {
                              apiKey: apiKeyWithPlaceholder,
                              language: language,
                              onClose: onClose
                            }) : (
                          match$3 === "IOS" ? React.createElement(InspectorViewInstallDocsIos.make, {
                                  apiKey: apiKeyWithPlaceholder,
                                  onClose: onClose,
                                  language: language
                                }) : (
                              match$3 === "Java" ? React.createElement(InspectorViewInstallDocsJava.make, {
                                      apiKey: apiKeyWithPlaceholder,
                                      language: language,
                                      onClose: onClose
                                    }) : (
                                  match$3 === "Node" ? React.createElement(InspectorViewInstallDocsNode.make, {
                                          apiKey: apiKeyWithPlaceholder,
                                          language: language,
                                          onClose: onClose
                                        }) : (
                                      match$3 === "Flutter" ? React.createElement(InspectorViewInstallDocsFlutter.make, {
                                              apiKey: apiKeyWithPlaceholder,
                                              onClose: onClose,
                                              language: language
                                            }) : null
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null);
    } else {
      switch (currentTab._0) {
        case /* Rudderstack */0 :
            tmp$4 = React.createElement(InspectorViewInstallDocsRudderStack.make, {
                  apiKey: apiKeyWithPlaceholder,
                  sourceGroup: AnalyticsRe.Bundle.source(source.id, AvoConfig.getSourceName(source))
                });
            break;
        case /* Segment */1 :
            tmp$4 = React.createElement(InspectorViewInstallDocsSegment.make, {
                  apiKey: apiKeyWithPlaceholder
                });
            break;
        case /* Tag */2 :
            tmp$4 = React.createElement(InspectorViewInstallDocsScriptTag.make, {
                  apiKey: apiKeyWithPlaceholder,
                  sourceGroup: AnalyticsRe.Bundle.source(source.id, AvoConfig.getSourceName(source)),
                  currentBranch: currentBranch
                });
            break;
        case /* Posthog */3 :
            tmp$4 = React.createElement(InspectorViewInstallDocsPosthog.make, {});
            break;
        case /* GoogleTagManager */4 :
            tmp$4 = React.createElement(InspectorViewInstallDocsGtmWebTemplate.make, {
                  apiKey: apiKeyWithPlaceholder,
                  hasSeenEvents: hasSeenEvents
                });
            break;
        
      }
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.light10,
              children: "API key"
            }), React.createElement(Spacer.make, {
              height: 4
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.height(Css.px(42)),
                    tl: /* [] */0
                  })
            }, tmp$1), React.createElement(Spacer.make, {
              height: 24
            }), React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.light10,
              children: "Setup Status"
            }), React.createElement(Spacer.make, {
              height: 4
            }), typeof status === "number" ? (
            status !== 0 ? React.createElement("div", {
                    className: waitingPill
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.light11,
                        children: "Waiting to receive events"
                      })) : React.createElement("div", {
                    className: statusLoading
                  })
          ) : React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement("div", {
                    className: initializedPill
                  }, React.createElement(Icon.make, {
                        type_: "checkmark",
                        color: Styles.Color.deepGreen
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.deepGreen,
                        children: "Receiving events"
                      })), React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.light11,
                    children: null
                  }, "in ", React.createElement($$Text.make, {
                        element: "Span",
                        weight: "Semi",
                        children: Belt_Array.map(status._0, Env.toFormattedString).join(" and ")
                      })), React.createElement(Spacer.make, {
                    width: 20,
                    grow: 1.0
                  }), React.createElement(Button.make, {
                    label: "View in Inspector",
                    onClick: (function (param) {
                        return Router.push(undefined, Router.Link.getSchemaRouteLink(undefined, {
                                        NAME: "inspector",
                                        VAL: "events"
                                      }));
                      }),
                    style: "outline"
                  })), React.createElement(Spacer.make, {
              height: 24
            }), React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.light10,
              children: "Documentation"
            }), React.createElement(Spacer.make, {
              height: 2
            }), React.createElement("div", {
              className: docs
            }, React.createElement("div", {
                  className: docsNav
                }, Belt_Array.map(integrationProviders, (function (provider) {
                        return React.createElement("button", {
                                    key: integrationProviderToString(provider),
                                    className: Caml_obj.caml_equal({
                                          TAG: /* Stream */1,
                                          _0: provider
                                        }, currentTab) ? docsNavItemSelected : docsNavItem,
                                    onClick: (function (param) {
                                        return Curry._1(setTab, (function (param) {
                                                      return {
                                                              TAG: /* Stream */1,
                                                              _0: provider
                                                            };
                                                    }));
                                      })
                                  }, integrationProviderToString(provider));
                      })), tmp$2), React.createElement("div", {
                  className: docsContent
                }, tmp$4)));
  }
  return React.createElement("div", {
              className: root
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light11,
                  maxWidth: Css.px(500),
                  children: null
                }, "Avo Inspector extracts your event schemas and compares them against your tracking plan to helps you uncover and fix inconsistencies in your event tracking. Learn more in our ", React.createElement("a", {
                      className: link,
                      href: "https://www.avo.app/docs/data-design/start-using-inspector",
                      rel: "noopener noreferrer",
                      target: "_blank"
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "docs"
                        })), "."), React.createElement(Spacer.make, {
                  height: 24
                }), tmp);
}

var make = SourceConfigInspectorSetup;

export {
  Style ,
  integrationProviderToString ,
  make ,
  
}
/* root Not a pure module */
