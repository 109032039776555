// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Quill from "./externals/quill.mjs";
import * as React from "react";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import QuillDelta from "quill-delta";
import * as DiffComments from "./DiffComments.mjs";
import * as DiffComponents from "./DiffComponents.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";

function DiffMetric(Props) {
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var branchId = Props.branchId;
  var goToCommentId = Props.goToCommentId;
  var withComments = Props.withComments;
  var events = Props.events;
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  if (match.NAME !== "Metric") {
    return null;
  }
  var metricId = match.VAL;
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  if (!actions$1) {
    return null;
  }
  var hasCreate = Belt_List.some(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "AddMetric";
          } else {
            return false;
          }
        }));
  var hasArchive = Belt_List.some(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          if (x.NAME !== "Archive") {
            return false;
          }
          var match = x.VAL;
          if (typeof match === "object") {
            return match.NAME === "Metric";
          } else {
            return false;
          }
        }));
  var metric = Belt_Option.getWithDefault(Belt_List.getBy(masterModel.metrics, (function (metric) {
              return metric.id === metricId;
            })), TrackingPlanModel.emptyMetric(metricId));
  var nextMetric = Belt_Option.getWithDefault(Belt_List.getBy(branchModel.metrics, (function (metric) {
              return metric.id === metricId;
            })), TrackingPlanModel.emptyMetric(metricId));
  var addActions = Belt_List.keep(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "AddEventToMetric";
          } else {
            return false;
          }
        }));
  var removeActions = Belt_List.keep(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "RemoveEventFromMetric";
          } else {
            return false;
          }
        }));
  var hasTypeUpdate = Belt_List.some(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "UpdateMetricType";
          } else {
            return false;
          }
        }));
  var maybeAction = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "UpdateMetricDescription";
          } else {
            return false;
          }
        }));
  var tmp;
  if (withComments) {
    var tmp$1 = {
      branchId: branchId,
      threadId: metric.id,
      itemType: "Metric",
      itemName: metric.name,
      events: events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp = React.createElement(DiffComments.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              key: metricId,
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement(DiffComponents.Item.make, {
                  borderColor: hasCreate ? Styles.Color.darkGreen : (
                      hasArchive ? Styles.Color.darkRed : Styles.Color.light06
                    ),
                  children: null,
                  onClick: (function (param) {
                      return Router.Schema.pushDrawerItem(undefined, undefined, {
                                  NAME: "metric",
                                  VAL: [
                                    metric.id,
                                    undefined
                                  ]
                                });
                    })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: JsDiff.renderStringDiff(undefined, undefined, undefined, metric.name, nextMetric.name)
                    }), maybeAction !== undefined ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: /* [] */0
                            }),
                        dangerouslySetInnerHTML: {
                          __html: Quill.toHTMLWithDiff(new QuillDelta(JSON.parse(metric.description)), new QuillDelta(JSON.parse(nextMetric.description)), undefined, undefined)
                        }
                      }) : null, hasTypeUpdate ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: /* [] */0
                            })
                      }, JsDiff.renderStringDiff(undefined, undefined, undefined, AvoUtils.getMetricTypeLabel(metric.type_), AvoUtils.getMetricTypeLabel(nextMetric.type_))) : null, addActions === /* [] */0 && removeActions === /* [] */0 ? null : React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.light11,
                        children: "Events"
                      }), Belt_List.toArray(Belt_List.map(Belt_List.keepMap(addActions, (function (x) {
                                if (typeof x !== "object") {
                                  return ;
                                }
                                if (x.NAME !== "AddEventToMetric") {
                                  return ;
                                }
                                var eventId = x.VAL[2];
                                return Belt_List.getBy(branchModel.events, (function ($$event) {
                                              return $$event.id === eventId;
                                            }));
                              })), (function ($$event) {
                            return React.createElement(DiffComponents.Row.make, {
                                        label: $$event.name,
                                        change: "Add",
                                        key: $$event.id
                                      });
                          }))), Belt_List.toArray(Belt_List.map(Belt_List.keepMap(removeActions, (function (x) {
                                if (typeof x !== "object") {
                                  return ;
                                }
                                if (x.NAME !== "RemoveEventFromMetric") {
                                  return ;
                                }
                                var eventId = x.VAL[2];
                                return Belt_List.getBy(branchModel.events, (function ($$event) {
                                              return $$event.id === eventId;
                                            }));
                              })), (function ($$event) {
                            return React.createElement(DiffComponents.Row.make, {
                                        label: $$event.name,
                                        change: "Remove",
                                        key: $$event.id
                                      });
                          })))), tmp);
}

var make = DiffMetric;

export {
  make ,
  
}
/* Css Not a pure module */
