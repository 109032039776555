// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../../bs-case/src/case.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "../Title.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Tooltip from "../Tooltip.mjs";
import * as ArrayExt from "../ArrayExt.mjs";
import * as Markdown from "../Markdown.mjs";
import * as AvoLimits from "../AvoLimits.mjs";
import * as NameUtils from "../../../shared/utils/NameUtils.mjs";
import * as Workspace from "../../../model/src/Workspace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as TextButton from "../TextButton.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Core from "@mantine/core";
import * as FramerMotion from "framer-motion";
import * as AppFeatureFlag from "../AppFeatureFlag.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as EntityLightning from "../EntityLightning.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as ReactBeautifulDnd from "../ReactBeautifulDnd.mjs";
import * as DiscrepancyContext from "../DiscrepancyContext.mjs";
import * as ValidationConfigV2 from "./ValidationConfigV2.mjs";
import * as ReactBeautifulDnd$1 from "react-beautiful-dnd";
import * as GuardRailsConfigUtils from "./GuardRailsConfigUtils.mjs";
import * as GuardRailsConfigComponents from "./GuardRailsConfigComponents.mjs";

var _map = {"actionObject":"action object","objectAction":"object action","custom":"custom"};

var _revMap = {"action object":"actionObject","object action":"objectAction","custom":"custom"};

function frameworkToJs(param) {
  return _map[param];
}

function frameworkFromJs(param) {
  return _revMap[param];
}

var _map$1 = {"freeInput":"freeInput","allowedValues":"allowedValues","separator":"separator"};

function valueToJs(param) {
  return param;
}

function valueFromJs(param) {
  return _map$1[param];
}

function toLabel(v) {
  if (v === "freeInput") {
    return "Free input";
  } else if (v === "separator") {
    return "Separator";
  } else {
    return "Predefined values list";
  }
}

function fromSelectValue(s) {
  return _map$1[Case.to_("Camel", s)];
}

function select(absWidth) {
  return {
          hd: Css.label("name-component-type"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light03),
            tl: {
              hd: Css.borderRadius(Css.px(16)),
              tl: {
                hd: Css.borderWidth("zero"),
                tl: {
                  hd: Css.fontFamily(Styles.FontFamily.polar),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.regular),
                    tl: {
                      hd: Css.paddingLeft(Css.px(16)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(8)),
                        tl: {
                          hd: absWidth !== undefined ? Css.maxWidth(Css.px(absWidth)) : Styles.emptyStyle,
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        };
}

var _map$2 = {"freeInput":"Free input","allowedValues":"Predefined values list","separator":"Separator"};

var _revMap$1 = {"Free input":"freeInput","Predefined values list":"allowedValues","Separator":"separator"};

function valueToJs$1(param) {
  return _map$2[param];
}

function valueFromJs$1(param) {
  return _revMap$1[param];
}

function valueToLabel(v) {
  if (v === "freeInput") {
    return "Free input";
  } else if (v === "separator") {
    return "Separator";
  } else {
    return "Predefined values list";
  }
}

var ResizableSelect = GuardRailsConfigComponents.MakeResizableSelect({
      valueToLabel: valueToLabel,
      valueToString: valueToJs$1,
      valueFromString: valueFromJs$1
    });

function NameConventionsConfig$NameComponentTypeSelector(Props) {
  var currentBuildingBlock = Props.currentBuildingBlock;
  var hasPredefinedValues = Props.hasPredefinedValues;
  var hasCustomStructs = Props.hasCustomStructs;
  var lightningOnDisabledItemsOpt = Props.lightningOnDisabledItems;
  var setValue = Props.setValue;
  var value = Props.value;
  var lightningOnDisabledItems = lightningOnDisabledItemsOpt !== undefined ? lightningOnDisabledItemsOpt : true;
  var globalSend = GlobalSendContext.use(undefined);
  return React.createElement(ResizableSelect.make, {
              disabled: value === "separator" && !hasCustomStructs,
              enableDisabledItems: true,
              lightningOnDisabledItems: lightningOnDisabledItems,
              onSelect: (function (v) {
                  var currentBuildingBlock$1 = Belt_Option.getWithDefault(currentBuildingBlock, ValidationConfigV2.NameComponent.NameBuildingBlock.empty);
                  if (v === "freeInput") {
                    return Curry._1(setValue, {
                                NAME: "nameBuildingBlock",
                                VAL: {
                                  name: currentBuildingBlock$1.name,
                                  case: currentBuildingBlock$1.case,
                                  allowedValues: Curry._1(ValidationConfigV2.Rule.empty, {
                                        NAME: "allowedValues",
                                        VAL: []
                                      }),
                                  blockedValues: currentBuildingBlock$1.blockedValues
                                }
                              });
                  } else if (v === "separator") {
                    if (hasCustomStructs) {
                      return Curry._1(setValue, {
                                  NAME: "separator",
                                  VAL: ValidationConfigV2.NameComponent.Separator.empty
                                });
                    } else {
                      return Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "BillingPrompt",
                                    VAL: "IssueReportConfig"
                                  }
                                });
                    }
                  } else if (hasPredefinedValues) {
                    return Curry._1(setValue, {
                                NAME: "nameBuildingBlock",
                                VAL: {
                                  name: currentBuildingBlock$1.name,
                                  case: currentBuildingBlock$1.case,
                                  allowedValues: Curry._1(ValidationConfigV2.Rule.empty, {
                                        NAME: "allowedValues",
                                        VAL: [""]
                                      }),
                                  blockedValues: currentBuildingBlock$1.blockedValues
                                }
                              });
                  } else {
                    return Curry._1(globalSend, {
                                TAG: /* OpenModal */4,
                                _0: {
                                  NAME: "BillingPrompt",
                                  VAL: "IssueReportConfig"
                                }
                              });
                  }
                }),
              options: [
                {
                  NAME: "Label",
                  VAL: "freeInput"
                },
                hasPredefinedValues ? ({
                      NAME: "Label",
                      VAL: "allowedValues"
                    }) : ({
                      NAME: "Disabled",
                      VAL: "allowedValues"
                    }),
                hasCustomStructs ? ({
                      NAME: "Label",
                      VAL: "separator"
                    }) : ({
                      NAME: "Disabled",
                      VAL: "separator"
                    })
              ],
              value: value
            });
}

var NameComponentTypeSelector = {
  valueToJs: valueToJs,
  valueFromJs: valueFromJs,
  toLabel: toLabel,
  toSelectValue: valueToJs,
  fromSelectValue: fromSelectValue,
  select: select,
  ResizableSelect: ResizableSelect,
  make: NameConventionsConfig$NameComponentTypeSelector
};

function valueToLabel$1(v) {
  if (v === "objectAction") {
    return "Object Action";
  } else if (v === "custom") {
    return "Custom";
  } else {
    return "Action Object";
  }
}

var FrameworkSelector = GuardRailsConfigComponents.MakeResizableSelect({
      valueToLabel: valueToLabel$1,
      valueToString: frameworkToJs,
      valueFromString: frameworkFromJs
    });

function valueToLabel$2(value) {
  if (value === "custom") {
    return "Custom";
  } else {
    return Case.toUILabel(value);
  }
}

function valueToString(value) {
  if (value === "custom") {
    return "custom";
  } else {
    return Case.tToJs(value);
  }
}

function valueFromString(string) {
  if (string === "custom") {
    return "custom";
  }
  var $$case = Case.tFromJs(string);
  if ($$case !== undefined && $$case !== "None") {
    return $$case;
  } else {
    return "custom";
  }
}

var GlobalCaseSelector = GuardRailsConfigComponents.MakeResizableSelect({
      valueToLabel: valueToLabel$2,
      valueToString: valueToString,
      valueFromString: valueFromString
    });

var CaseSelector = GuardRailsConfigComponents.MakeResizableSelect({
      valueToLabel: Case.toUILabel,
      valueToString: Case.tToJs,
      valueFromString: Case.tFromJs
    });

function NameConventionsConfig$RemoveNameComponentButton(Props) {
  var disabled = Props.disabled;
  var removeValue = Props.removeValue;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("row"),
                      tl: {
                        hd: Css.flexGrow(1.0),
                        tl: {
                          hd: Css.width(Css.pct(100.0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(Spacer.make, {
                  width: 8,
                  grow: 1.0
                }), React.createElement(Button.make, {
                  disabled: disabled,
                  icon: "trash",
                  onClick: (function (param) {
                      if (disabled) {
                        return ;
                      } else {
                        return Curry._1(removeValue, undefined);
                      }
                    }),
                  size: "tiny",
                  style: "ghost"
                }));
}

var RemoveNameComponentButton = {
  make: NameConventionsConfig$RemoveNameComponentButton
};

var container = Curry._1(Css.style, {
      hd: Css.label("container"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.marginLeft(Css.px(-36)),
            tl: {
              hd: Css.marginRight(Css.px(-8)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var innerContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.gap(Css.px(20)),
          tl: /* [] */0
        }
      }
    });

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.gap(Css.px(16)),
          tl: {
            hd: Css.width(Css.pct(100.0)),
            tl: {
              hd: Css.borderRadius(Css.px(4)),
              tl: {
                hd: Css.outline(Css.px(1), "solid", Styles.Color.light06),
                tl: {
                  hd: Css.padding(Css.px(16)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(24)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var innerBlock = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.gap(Css.px(16)),
          tl: {
            hd: Css.paddingLeft(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

var input = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.FontFamily.polar),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.medium),
        tl: {
          hd: Css.fontSize(Styles.FontSize.regular),
          tl: {
            hd: Css.color(Styles.Color.light12),
            tl: {
              hd: Css.placeholder({
                    hd: Css.color(Styles.Color.light07),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var nameRow = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.gap(Css.px(8)),
            tl: {
              hd: Css.marginBottom(Css.px(-8)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Styles$1 = {
  Color: Styles.Color,
  FontFamily: Styles.FontFamily,
  FontWeight: Styles.FontWeight,
  FontSize: Styles.FontSize,
  LineHeight: Styles.LineHeight,
  Border: Styles.Border,
  Shadow: Styles.Shadow,
  ZIndex: Styles.ZIndex,
  Duration: Styles.Duration,
  button: Styles.button,
  buttonReset: Styles.buttonReset,
  linkReset: Styles.linkReset,
  select: Styles.select,
  shimmer: Styles.shimmer,
  backgroundColorGuarantee: Styles.backgroundColorGuarantee,
  backgroundColorWithOverlayGuarantee: Styles.backgroundColorWithOverlayGuarantee,
  maxLines: Styles.maxLines,
  emptyStyle: Styles.emptyStyle,
  container: container,
  innerContainer: innerContainer,
  content: content,
  innerBlock: innerBlock,
  input: input,
  nameRow: nameRow
};

function NameConventionsConfig$NameComponent(Props) {
  var globalCase = Props.globalCase;
  var hasPredefinedValues = Props.hasPredefinedValues;
  var hasCustomStructs = Props.hasCustomStructs;
  var nameComponent = Props.nameComponent;
  var removeValue = Props.removeValue;
  var setValue = Props.setValue;
  var workspace = WorkspaceContext.use(undefined);
  var onChange = function (value) {
    var tmp;
    if (nameComponent.NAME === "separator") {
      tmp = {
        NAME: "separator",
        VAL: value
      };
    } else {
      var buildingBlock = nameComponent.VAL;
      tmp = {
        NAME: "nameBuildingBlock",
        VAL: {
          name: value,
          case: buildingBlock.case,
          allowedValues: buildingBlock.allowedValues,
          blockedValues: buildingBlock.blockedValues
        }
      };
    }
    return Curry._1(setValue, tmp);
  };
  var onValueChange = function (value) {
    if (hasCustomStructs) {
      return onChange(value);
    }
    
  };
  var tmp;
  if (nameComponent.NAME === "separator") {
    tmp = React.createElement("div", {
          className: nameRow
        }, React.createElement(GuardRailsConfigComponents.AutoGrowingInput.make, {
              className: Curry._1(Css.merge, {
                    hd: input,
                    tl: {
                      hd: Curry._1(Css.style, {
                            hd: Css.letterSpacing(Css.px(1)),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }),
              disabled: !hasCustomStructs,
              height: 30,
              inputHeightOffset: 2,
              onChange: onValueChange,
              showWhitespaceOnBlur: true,
              placeholder: "__",
              value: nameComponent.VAL,
              autoFocus: false
            }), React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexShrink(0.0),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  lines: 1,
                  color: Styles.Color.light11,
                  children: "Component type:"
                })), React.createElement(NameConventionsConfig$NameComponentTypeSelector, {
              hasPredefinedValues: hasPredefinedValues,
              hasCustomStructs: hasCustomStructs,
              lightningOnDisabledItems: false,
              setValue: setValue,
              value: "separator"
            }), React.createElement(NameConventionsConfig$RemoveNameComponentButton, {
              disabled: !hasCustomStructs,
              removeValue: removeValue
            }));
  } else {
    var buildingBlock = nameComponent.VAL;
    if (Curry._1(ValidationConfigV2.Value.getAllowedValues, buildingBlock.allowedValues.value).length === 0) {
      var $$case = Curry._1(ValidationConfigV2.Value.getCase, buildingBlock.case.value);
      var addNewBlockedValue = function (param) {
        var init = buildingBlock.blockedValues;
        return Curry._1(setValue, {
                    NAME: "nameBuildingBlock",
                    VAL: {
                      name: buildingBlock.name,
                      case: buildingBlock.case,
                      allowedValues: buildingBlock.allowedValues,
                      blockedValues: {
                        value: {
                          NAME: "blockedValues",
                          VAL: Belt_Array.concat(Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getBlockedValues, buildingBlock), [""])
                        },
                        severity: init.severity
                      }
                    }
                  });
      };
      tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
                className: nameRow
              }, React.createElement(GuardRailsConfigComponents.AutoGrowingInput.make, {
                    className: input,
                    disabled: !hasCustomStructs,
                    height: 30,
                    inputHeightOffset: 2,
                    onChange: onValueChange,
                    placeholder: "Object",
                    value: buildingBlock.name,
                    autoFocus: false
                  }), React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexShrink(0.0),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        lines: 1,
                        color: Styles.Color.light11,
                        children: "Component type:"
                      })), React.createElement(NameConventionsConfig$NameComponentTypeSelector, {
                    currentBuildingBlock: buildingBlock,
                    hasPredefinedValues: hasPredefinedValues,
                    hasCustomStructs: hasCustomStructs,
                    setValue: setValue,
                    value: "freeInput"
                  }), React.createElement(NameConventionsConfig$RemoveNameComponentButton, {
                    disabled: !hasCustomStructs,
                    removeValue: removeValue
                  })), hasCustomStructs && globalCase === "hybrid" ? React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(Icon.make, {
                          type_: "arrowDownRightTurn",
                          size: "small",
                          color: Styles.Color.light12
                        }), React.createElement(Spacer.make, {
                          width: 4
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.light12,
                          children: "Case:"
                        }), React.createElement(Spacer.make, {
                          width: 4
                        }), React.createElement(CaseSelector.make, {
                          onSelect: (function (newCase) {
                              var init = buildingBlock.case;
                              var init$1 = buildingBlock.blockedValues;
                              return Curry._1(setValue, {
                                          NAME: "nameBuildingBlock",
                                          VAL: {
                                            name: buildingBlock.name,
                                            case: {
                                              value: {
                                                NAME: "case",
                                                VAL: newCase
                                              },
                                              severity: init.severity
                                            },
                                            allowedValues: buildingBlock.allowedValues,
                                            blockedValues: {
                                              value: {
                                                NAME: "blockedValues",
                                                VAL: Belt_Array.mapU(Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getBlockedValues, buildingBlock), (function (blockedValue) {
                                                        return Case.to_(newCase, blockedValue);
                                                      }))
                                              },
                                              severity: init$1.severity
                                            }
                                          }
                                        });
                            }),
                          options: Belt_Array.keepMap(Case.casingOptions, (function ($$case) {
                                  if ($$case !== "None") {
                                    return {
                                            NAME: "Label",
                                            VAL: $$case
                                          };
                                  }
                                  
                                })),
                          value: Curry._1(ValidationConfigV2.Value.getCase, buildingBlock.case.value)
                        }))) : null, React.createElement("div", undefined, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.gap(Css.px(4)),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement(Icon.make, {
                        type_: "arrowDownRightTurn",
                        size: "small",
                        color: Styles.Color.light12
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        children: "Blocked values:"
                      })), Belt_Array.mapWithIndex(Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getBlockedValues, buildingBlock), (function (changingIndex, value) {
                      return React.createElement(GuardRailsConfigComponents.PredefinedValue.make, {
                                  addNewValue: (function (v) {
                                      if (hasPredefinedValues) {
                                        return addNewBlockedValue(v);
                                      }
                                      
                                    }),
                                  case: $$case,
                                  disabled: !hasPredefinedValues,
                                  value: value,
                                  setValue: (function (newValue) {
                                      var init = buildingBlock.blockedValues;
                                      return Curry._1(setValue, {
                                                  NAME: "nameBuildingBlock",
                                                  VAL: {
                                                    name: buildingBlock.name,
                                                    case: buildingBlock.case,
                                                    allowedValues: buildingBlock.allowedValues,
                                                    blockedValues: {
                                                      value: {
                                                        NAME: "blockedValues",
                                                        VAL: Belt_Array.mapWithIndex(Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getBlockedValues, buildingBlock), (function (i, blockedValue) {
                                                                if (changingIndex === i) {
                                                                  return newValue;
                                                                } else {
                                                                  return blockedValue;
                                                                }
                                                              }))
                                                      },
                                                      severity: init.severity
                                                    }
                                                  }
                                                });
                                    }),
                                  removeValue: (function (param) {
                                      var init = buildingBlock.blockedValues;
                                      return Curry._1(setValue, {
                                                  NAME: "nameBuildingBlock",
                                                  VAL: {
                                                    name: buildingBlock.name,
                                                    case: buildingBlock.case,
                                                    allowedValues: buildingBlock.allowedValues,
                                                    blockedValues: {
                                                      value: {
                                                        NAME: "blockedValues",
                                                        VAL: Belt_Array.keepMapU(Belt_Array.mapWithIndex(Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getBlockedValues, buildingBlock), (function (i, blockedValue) {
                                                                    if (changingIndex === i) {
                                                                      return ;
                                                                    } else {
                                                                      return blockedValue;
                                                                    }
                                                                  })), (function (x) {
                                                                return x;
                                                              }))
                                                      },
                                                      severity: init.severity
                                                    }
                                                  }
                                                });
                                    }),
                                  key: String(changingIndex)
                                });
                    })), React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.gap(Css.px(4)),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(TextButton.make, {
                        onClick: addNewBlockedValue,
                        style: "Blue",
                        size: "Small",
                        children: "+ Add Blocked Value"
                      }), React.createElement(EntityLightning.make, {
                        availability: Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention.PredefinedValues.computeAvailability, workspace),
                        planHint: Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention.PredefinedValues.availableOnPlan, workspace),
                        stroke: Styles.Color.light10,
                        fill: Styles.Color.white
                      }))));
    } else {
      var $$case$1 = Curry._1(ValidationConfigV2.Value.getCase, buildingBlock.case.value);
      var addNewAllowedValue = function (param) {
        var init = buildingBlock.allowedValues;
        return Curry._1(setValue, {
                    NAME: "nameBuildingBlock",
                    VAL: {
                      name: buildingBlock.name,
                      case: buildingBlock.case,
                      allowedValues: {
                        value: {
                          NAME: "allowedValues",
                          VAL: Belt_Array.concat(Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getAllowedValues, buildingBlock), [""])
                        },
                        severity: init.severity
                      },
                      blockedValues: buildingBlock.blockedValues
                    }
                  });
      };
      tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
                className: nameRow
              }, React.createElement(GuardRailsConfigComponents.AutoGrowingInput.make, {
                    className: input,
                    disabled: !hasCustomStructs,
                    height: 30,
                    inputHeightOffset: 2,
                    onChange: onChange,
                    placeholder: "Object",
                    value: buildingBlock.name,
                    autoFocus: false
                  }), React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexShrink(0.0),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        lines: 1,
                        color: Styles.Color.light11,
                        children: "Component type:"
                      })), React.createElement(NameConventionsConfig$NameComponentTypeSelector, {
                    currentBuildingBlock: buildingBlock,
                    hasPredefinedValues: hasPredefinedValues,
                    hasCustomStructs: hasCustomStructs,
                    setValue: setValue,
                    value: "allowedValues"
                  }), React.createElement(NameConventionsConfig$RemoveNameComponentButton, {
                    disabled: !hasCustomStructs,
                    removeValue: removeValue
                  })), React.createElement("div", {
                className: innerBlock
              }, globalCase === "hybrid" ? React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("column"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement(Icon.make, {
                              type_: "arrowDownRightTurn",
                              size: "small",
                              color: Styles.Color.light12
                            }), React.createElement(Spacer.make, {
                              width: 4
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              children: "Case:"
                            }), React.createElement(Spacer.make, {
                              width: 4
                            }), React.createElement(CaseSelector.make, {
                              onSelect: (function (newCase) {
                                  var init = buildingBlock.case;
                                  var init$1 = buildingBlock.allowedValues;
                                  return Curry._1(setValue, {
                                              NAME: "nameBuildingBlock",
                                              VAL: {
                                                name: buildingBlock.name,
                                                case: {
                                                  value: {
                                                    NAME: "case",
                                                    VAL: newCase
                                                  },
                                                  severity: init.severity
                                                },
                                                allowedValues: {
                                                  value: {
                                                    NAME: "allowedValues",
                                                    VAL: Belt_Array.mapU(Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getAllowedValues, buildingBlock), (function (allowedValue) {
                                                            return Case.to_(newCase, allowedValue);
                                                          }))
                                                  },
                                                  severity: init$1.severity
                                                },
                                                blockedValues: buildingBlock.blockedValues
                                              }
                                            });
                                }),
                              options: Belt_Array.keepMap(Case.casingOptions, (function ($$case) {
                                      if ($$case !== "None") {
                                        return {
                                                NAME: "Label",
                                                VAL: $$case
                                              };
                                      }
                                      
                                    })),
                              value: Curry._1(ValidationConfigV2.Value.getCase, buildingBlock.case.value)
                            }))) : null, React.createElement("div", undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.gap(Css.px(4)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement(Icon.make, {
                            type_: "arrowDownRightTurn",
                            size: "small",
                            color: Styles.Color.light12
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            children: "Allowed values:"
                          })), Belt_Array.mapWithIndex(Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getAllowedValues, buildingBlock), (function (changingIndex, value) {
                          return React.createElement(GuardRailsConfigComponents.PredefinedValue.make, {
                                      addNewValue: addNewAllowedValue,
                                      case: $$case$1,
                                      value: value,
                                      setValue: (function (newValue) {
                                          var init = buildingBlock.allowedValues;
                                          return Curry._1(setValue, {
                                                      NAME: "nameBuildingBlock",
                                                      VAL: {
                                                        name: buildingBlock.name,
                                                        case: buildingBlock.case,
                                                        allowedValues: {
                                                          value: {
                                                            NAME: "allowedValues",
                                                            VAL: Belt_Array.mapWithIndex(Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getAllowedValues, buildingBlock), (function (i, allowedValue) {
                                                                    if (changingIndex === i) {
                                                                      return newValue;
                                                                    } else {
                                                                      return allowedValue;
                                                                    }
                                                                  }))
                                                          },
                                                          severity: init.severity
                                                        },
                                                        blockedValues: buildingBlock.blockedValues
                                                      }
                                                    });
                                        }),
                                      removeValue: (function (param) {
                                          var init = buildingBlock.allowedValues;
                                          return Curry._1(setValue, {
                                                      NAME: "nameBuildingBlock",
                                                      VAL: {
                                                        name: buildingBlock.name,
                                                        case: buildingBlock.case,
                                                        allowedValues: {
                                                          value: {
                                                            NAME: "allowedValues",
                                                            VAL: Belt_Array.keepMapU(Belt_Array.mapWithIndex(Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getAllowedValues, buildingBlock), (function (i, allowedValue) {
                                                                        if (changingIndex === i) {
                                                                          return ;
                                                                        } else {
                                                                          return allowedValue;
                                                                        }
                                                                      })), (function (x) {
                                                                    return x;
                                                                  }))
                                                          },
                                                          severity: init.severity
                                                        },
                                                        blockedValues: buildingBlock.blockedValues
                                                      }
                                                    });
                                        }),
                                      key: String(changingIndex)
                                    });
                        })), React.createElement(TextButton.make, {
                        onClick: addNewAllowedValue,
                        style: "Blue",
                        size: "Small",
                        children: "+ Add Allowed Value"
                      }))));
    }
  }
  return React.createElement("div", {
              className: container
            }, React.createElement("div", {
                  className: innerContainer
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingTop(Css.px(20)),
                            tl: {
                              hd: Css.height("fitContent"),
                              tl: {
                                hd: Css.cursor("grab"),
                                tl: {
                                  hd: Icon.styles({
                                        hd: Css_Legacy_Core.SVG.fill(hasCustomStructs ? Styles.Color.light08 : Styles.Color.light06),
                                        tl: {
                                          hd: Css_Legacy_Core.SVG.stroke(hasCustomStructs ? Styles.Color.light08 : Styles.Color.light06),
                                          tl: {
                                            hd: Css.transition({
                                                  NAME: "ms",
                                                  VAL: 150.0
                                                }, undefined, undefined, "all"),
                                            tl: /* [] */0
                                          }
                                        }
                                      }),
                                  tl: {
                                    hd: hasCustomStructs ? Css.hover({
                                            hd: Icon.styles({
                                                  hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light12),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }) : Styles.emptyStyle,
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement(Icon.make, {
                          type_: "dragHandle",
                          size: "small",
                          color: Styles.Color.light10
                        })), React.createElement("div", {
                      className: content
                    }, tmp)));
}

var NameComponent = {
  Styles: Styles$1,
  make: NameConventionsConfig$NameComponent
};

function container$1(offset) {
  return Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.top(Css.px(0)),
                tl: {
                  hd: Css.left(Css.px(offset)),
                  tl: {
                    hd: Css.width({
                          NAME: "subtract",
                          VAL: [
                            Css.pct(100.0),
                            Css.px(offset)
                          ]
                        }),
                    tl: {
                      hd: Css.height(Css.vh(100.0)),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.overflow("auto"),
                            tl: {
                              hd: Css.maxWidth({
                                    NAME: "subtract",
                                    VAL: [
                                      Css.pct(100.0),
                                      Css.px(offset)
                                    ]
                                  }),
                              tl: {
                                hd: Css.height(Css.pct(100)),
                                tl: {
                                  hd: Css.paddingTop(Css.px(48)),
                                  tl: {
                                    hd: Css.paddingRight(Css.px(60)),
                                    tl: {
                                      hd: Css.paddingBottom(Css.px(48)),
                                      tl: {
                                        hd: Css.paddingLeft(Css.px(60)),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.light01),
                                          tl: {
                                            hd: Css.boxShadow(Styles.Shadow.box),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var innerContainer$1 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("flexStart"),
        tl: {
          hd: Css.gap(Css.px(30)),
          tl: /* [] */0
        }
      }
    });

var closeArrow = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.top(Css.px(4)),
                tl: {
                  hd: Css.left(Css.px(-40)),
                  tl: {
                    hd: Icon.styles({
                          hd: Css_Legacy_Core.SVG.fill(Styles.Color.light09),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: 100.0
                                }, undefined, undefined, "all"),
                            tl: /* [] */0
                          }
                        }),
                    tl: {
                      hd: Css.hover({
                            hd: Icon.color(Styles.Color.light12),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function configBlock(activeBlock) {
  return Curry._1(Css.style, {
              hd: Css.borderRadius(Css.px(6)),
              tl: {
                hd: Css.padding(Css.px(24)),
                tl: {
                  hd: Css.paddingLeft(Css.px(16)),
                  tl: {
                    hd: Css.margin(Css.px(-24)),
                    tl: {
                      hd: Css.outline(Css.px(activeBlock ? 1 : 0), "solid", Styles.Color.light05),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.gap(Css.px(16)),
                            tl: {
                              hd: activeBlock ? Styles.emptyStyle : Css.backgroundColor(Styles.Color.light02),
                              tl: {
                                hd: activeBlock ? Css.boxShadow(Styles.Shadow.box) : Styles.emptyStyle,
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function innerConfigBlock(activeBlock) {
  return Curry._1(Css.style, {
              hd: Css.paddingLeft(Css.px(36)),
              tl: {
                hd: Css.pointerEvents(activeBlock ? "auto" : "none"),
                tl: {
                  hd: Css.opacity(activeBlock ? 1.0 : 0.6),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: 250.0
                        }, undefined, undefined, "opacity"),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.gap(Css.px(16)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function radioContainer(availability) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.gap(Css.px(12)),
                  tl: {
                    hd: Css.selector(" label", {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.color(availability === "AvailableDuringTrial" || availability === "Available" ? Styles.Color.light12 : Styles.Color.light10),
                            tl: /* [] */0
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var Styles$2 = {
  Color: Styles.Color,
  FontFamily: Styles.FontFamily,
  FontWeight: Styles.FontWeight,
  FontSize: Styles.FontSize,
  LineHeight: Styles.LineHeight,
  Border: Styles.Border,
  Shadow: Styles.Shadow,
  ZIndex: Styles.ZIndex,
  Duration: Styles.Duration,
  input: Styles.input,
  button: Styles.button,
  buttonReset: Styles.buttonReset,
  linkReset: Styles.linkReset,
  select: Styles.select,
  shimmer: Styles.shimmer,
  backgroundColorGuarantee: Styles.backgroundColorGuarantee,
  backgroundColorWithOverlayGuarantee: Styles.backgroundColorWithOverlayGuarantee,
  maxLines: Styles.maxLines,
  emptyStyle: Styles.emptyStyle,
  container: container$1,
  innerContainer: innerContainer$1,
  closeArrow: closeArrow,
  configBlock: configBlock,
  innerConfigBlock: innerConfigBlock,
  radioContainer: radioContainer
};

function NameConventionsConfig(Props) {
  var mount = Props.mount;
  var onClose = Props.onClose;
  var onSave = Props.onSave;
  var viewerRole = Props.viewerRole;
  var match = React.useContext(DiscrepancyContext.context);
  var config = match.config;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var canForceBranchAudit = AvoLimits.ForceBranchAudit.computeAvailability(workspace);
  var forceBranchAuditPlanHint = AvoLimits.ForcePreferredCasing.availableOnPlan(workspace);
  var viewerCanEdit = viewerRole === "Admin";
  var resetOnNextOpen = React.useRef(false);
  var globalSend = GlobalSendContext.use(undefined);
  var hasGuardrailsForAllFeatureFlag = AppFeatureFlag.useFeatureFlag("GuardrailsForAll");
  var hasDeprecatedEnterpriseSupport = Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention_deprecated.isAvailable, workspace);
  var hasCustomStructs = Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention.CustomStructs.isAvailable, workspace) && hasGuardrailsForAllFeatureFlag || hasDeprecatedEnterpriseSupport;
  var hasPredefinedValues = Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention.PredefinedValues.isAvailable, workspace) && hasGuardrailsForAllFeatureFlag || hasDeprecatedEnterpriseSupport;
  var eventNames = ModelUtils.getEventNames(ModelStore.useEvents(undefined));
  var commonEventNameCase = NameUtils.getCommonCase(eventNames);
  var match$1 = React.useState(function () {
        var match = ValidationConfigV2.EventsV2.getRuleComplexity(config.events);
        if (match.NAME === "advanced") {
          return "advanced";
        } else {
          return "basic";
        }
      });
  var setEventNamingConventions = match$1[1];
  var eventNamingConventions = match$1[0];
  var match$2 = React.useState(function () {
        var match = ValidationConfigV2.EventsV2.getRuleComplexity(config.events);
        if (match.NAME === "advanced") {
          return ValidationConfigV2.NameComponent.getSimplestNameCasing(match.VAL);
        } else {
          return match.VAL;
        }
      });
  var setSimpleEventNamingConventions = match$2[1];
  var simpleEventNamingConventions = match$2[0];
  var $$case = ValidationConfigV2.EventsV2.getCase(config.events);
  var advancedNameConventions;
  var exit = 0;
  if (typeof $$case === "object" && ValidationConfigV2.EventsV2.hasBasicNameCasing(config.events)) {
    advancedNameConventions = ValidationConfigV2.NameComponent.objectAction($$case.VAL);
  } else {
    exit = 1;
  }
  if (exit === 1) {
    advancedNameConventions = Belt_Array.map(ValidationConfigV2.EventsV2.getNameComponents(config.events), (function (nameComponent) {
            if (nameComponent.NAME === "separator") {
              return nameComponent;
            }
            var buildingBlock = nameComponent.VAL;
            if (buildingBlock.name.trim() === "") {
              return {
                      NAME: "nameBuildingBlock",
                      VAL: {
                        name: "Object",
                        case: buildingBlock.case,
                        allowedValues: buildingBlock.allowedValues,
                        blockedValues: buildingBlock.blockedValues
                      }
                    };
            } else {
              return nameComponent;
            }
          }));
  }
  var match$3 = React.useReducer((function (state, action) {
          var variant = action.NAME;
          if (variant === "setFramework") {
            var newFramework = Curry._1(action.VAL, state.framework);
            var match = state.framework;
            var match$1 = state.case;
            var tmp;
            var exit = 0;
            if (newFramework === "custom") {
              tmp = typeof match$1 === "object" ? ValidationConfigV2.NameComponent.convertCase(state.advancedNameConventions, match$1.VAL) : state.advancedNameConventions;
            } else if (match === "actionObject") {
              if (newFramework === "objectAction") {
                tmp = Belt_Array.reverse(state.advancedNameConventions);
              } else {
                exit = 1;
              }
            } else if (match === "objectAction" && newFramework === "actionObject") {
              tmp = Belt_Array.reverse(state.advancedNameConventions);
            } else {
              exit = 1;
            }
            if (exit === 1) {
              tmp = newFramework === "objectAction" ? (
                  typeof match$1 === "object" ? ValidationConfigV2.NameComponent.objectAction(match$1.VAL) : ValidationConfigV2.NameComponent.objectAction("Title")
                ) : (
                  typeof match$1 === "object" ? ValidationConfigV2.NameComponent.actionObject(match$1.VAL) : ValidationConfigV2.NameComponent.actionObject("Title")
                );
            }
            return {
                    framework: newFramework,
                    case: state.case,
                    advancedNameConventions: tmp
                  };
          }
          if (variant === "setCase") {
            var newCase = Curry._1(action.VAL, state.case);
            return {
                    framework: state.framework,
                    case: newCase,
                    advancedNameConventions: typeof newCase === "object" ? ValidationConfigV2.NameComponent.convertCase(state.advancedNameConventions, newCase.VAL) : state.advancedNameConventions
                  };
          }
          if (variant !== "setNameConventions") {
            return Curry._1(action.VAL, state);
          }
          var advancedNameConventions = Curry._1(action.VAL, state.advancedNameConventions);
          var $$case = ValidationConfigV2.NameComponent.getCase(advancedNameConventions);
          return {
                  framework: GuardRailsConfigUtils.getFramework(advancedNameConventions, $$case),
                  case: state.case,
                  advancedNameConventions: advancedNameConventions
                };
        }), {
        framework: GuardRailsConfigUtils.getFramework(advancedNameConventions, $$case),
        case: $$case,
        advancedNameConventions: advancedNameConventions
      });
  var reduceFrameworkState = match$3[1];
  var match$4 = match$3[0];
  var advancedNameConventions$1 = match$4.advancedNameConventions;
  var globalCase = match$4.case;
  var validation = React.useMemo((function () {
          if (eventNamingConventions === "advanced") {
            return GuardRailsConfigUtils.validateComponents(advancedNameConventions$1);
          } else {
            return [];
          }
        }), [
        eventNamingConventions,
        advancedNameConventions$1
      ]);
  var changes = React.useMemo((function () {
          var currentConfigString = JSON.stringify(Json_encode.array(ValidationConfigV2.NameComponent.encode, ValidationConfigV2.EventsV2.getNameComponents(config.events)));
          var editedConfig = GuardRailsConfigUtils.getNameComponents(undefined, eventNamingConventions, simpleEventNamingConventions, advancedNameConventions$1);
          var editedConfigString = JSON.stringify(Json_encode.array(ValidationConfigV2.NameComponent.encode, editedConfig));
          return currentConfigString !== editedConfigString;
        }), [
        eventNamingConventions,
        simpleEventNamingConventions,
        advancedNameConventions$1
      ]);
  var onReset = function (param) {
    var simpleEventNamingConventions = ValidationConfigV2.EventsV2.getSimplestNameCasing(config.events);
    Curry._1(setSimpleEventNamingConventions, (function (param) {
            return simpleEventNamingConventions;
          }));
    if (ValidationConfigV2.EventsV2.hasBasicNameCasing(config.events)) {
      var $$case = Curry._1(ValidationConfigV2.Value.getCase, simpleEventNamingConventions.value);
      Curry._1(reduceFrameworkState, {
            NAME: "reset",
            VAL: (function (currentState) {
                return {
                        framework: currentState.framework,
                        case: {
                          NAME: "simple",
                          VAL: $$case
                        },
                        advancedNameConventions: ValidationConfigV2.NameComponent.objectAction($$case)
                      };
              })
          });
    } else {
      Curry._1(reduceFrameworkState, {
            NAME: "setNameConventions",
            VAL: (function (param) {
                return ValidationConfigV2.EventsV2.getNameComponents(config.events);
              })
          });
    }
    return Curry._1(setEventNamingConventions, (function (param) {
                  var match = ValidationConfigV2.EventsV2.getRuleComplexity(config.events);
                  if (match.NAME === "advanced") {
                    return "advanced";
                  } else {
                    return "basic";
                  }
                }));
  };
  React.useEffect((function () {
          if (mount && resetOnNextOpen.current) {
            onReset(undefined);
            resetOnNextOpen.current = false;
          }
          
        }), [mount]);
  var tmp;
  if (mount) {
    var tmp$1;
    if (hasGuardrailsForAllFeatureFlag) {
      var tmp$2;
      if (typeof globalCase === "object") {
        var $$case$1 = globalCase.VAL;
        tmp$2 = (function (value) {
            return Case.to_($$case$1, frameworkToJs(value));
          });
      } else {
        tmp$2 = undefined;
      }
      var tmp$3 = {
        disabled: eventNamingConventions === "basic",
        enableDisabledItems: true,
        lightningOnDisabledItems: true,
        onSelect: (function (framework) {
            if (framework === "custom") {
              if (hasCustomStructs) {
                return Curry._1(reduceFrameworkState, {
                            NAME: "setFramework",
                            VAL: (function (_v) {
                                return "custom";
                              })
                          });
              } else {
                return Curry._1(globalSend, {
                            TAG: /* OpenModal */4,
                            _0: {
                              NAME: "BillingPrompt",
                              VAL: "IssueReportConfig"
                            }
                          });
              }
            } else {
              return Curry._1(reduceFrameworkState, {
                          NAME: "setFramework",
                          VAL: (function (_v) {
                              return framework;
                            })
                        });
            }
          }),
        options: [
          {
            NAME: "Label",
            VAL: "objectAction"
          },
          {
            NAME: "Label",
            VAL: "actionObject"
          },
          hasCustomStructs ? ({
                NAME: "Label",
                VAL: "custom"
              }) : ({
                NAME: "Disabled",
                VAL: "custom"
              })
        ],
        value: match$4.framework
      };
      if (tmp$2 !== undefined) {
        tmp$3.toLabelOverride = Caml_option.valFromOption(tmp$2);
      }
      tmp$1 = React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.flexDirection("column"),
                    tl: {
                      hd: Css.gap(Css.px(16)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(36)),
                        tl: /* [] */0
                      }
                    }
                  }
                })
          }, React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    color: Styles.Color.light12,
                    children: "Naming convention rules"
                  }), React.createElement(Tooltip.Default.make, {
                    size: "small",
                    title: "Naming convention rules",
                    children: React.createElement(Tooltip.Content.make, {
                          children: React.createElement(Markdown.make, {
                                source: "Standardize the naming convention of your events.\n\nThe case rule ensures consistent name casing, and the Framework defines the correct order of words in an event name.\n\nFor example, in the Object Action framework, the name starts with an Object (the element being interacted with), followed by an Action (the verb for the interaction). Some examples:\n\n```\nform_submitted\npage_viewed\nsubscription_modal_interacted\n```\n"
                              })
                        })
                  })), React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.gap(Css.px(8)),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement($$Text.make, {
                    size: "Medium",
                    color: Styles.Color.light12,
                    children: "Case"
                  }), React.createElement(GlobalCaseSelector.make, {
                    enableDisabledItems: true,
                    lightningOnDisabledItems: true,
                    onSelect: (function ($$case) {
                        if ($$case === "custom") {
                          if (hasCustomStructs) {
                            return Curry._1(reduceFrameworkState, {
                                        NAME: "setCase",
                                        VAL: (function (param) {
                                            return "hybrid";
                                          })
                                      });
                          } else {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "IssueReportConfig"
                                        }
                                      });
                          }
                        } else {
                          return Curry._1(reduceFrameworkState, {
                                      NAME: "setCase",
                                      VAL: (function (param) {
                                          return {
                                                  NAME: "simple",
                                                  VAL: $$case
                                                };
                                        })
                                    });
                        }
                      }),
                    options: Belt_Array.concat(Belt_Array.keepMap(Case.casingOptions, (function ($$case) {
                                if ($$case === "None") {
                                  return ;
                                } else {
                                  return {
                                          NAME: "Label",
                                          VAL: $$case
                                        };
                                }
                              })), [hasCustomStructs ? ({
                                NAME: "Label",
                                VAL: "custom"
                              }) : ({
                                NAME: "Disabled",
                                VAL: "custom"
                              })]),
                    value: typeof globalCase === "object" ? globalCase.VAL : "custom"
                  })), React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.gap(Css.px(8)),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement($$Text.make, {
                    size: "Medium",
                    color: Styles.Color.light12,
                    children: "Framework"
                  }), React.createElement(FrameworkSelector.make, tmp$3)));
    } else {
      tmp$1 = null;
    }
    tmp = React.createElement(FramerMotion.motion.div, {
          animate: {
            opacity: 1,
            x: 0
          },
          transition: {
            duration: 0.3
          },
          initial: {
            opacity: 0,
            x: 50
          },
          exit: {
            opacity: 0,
            x: 50
          },
          className: container$1(32),
          key: "event-name-conventions",
          children: React.createElement("div", {
                className: innerContainer$1
              }, React.createElement("div", undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("relative"),
                              tl: /* [] */0
                            })
                      }, React.createElement("button", {
                            className: closeArrow,
                            onClick: (function (param) {
                                return Curry._1(onClose, undefined);
                              })
                          }, React.createElement(Icon.make, {
                                type_: "arrowLeft",
                                size: {
                                  NAME: "int",
                                  VAL: 32
                                }
                              })), React.createElement(Title.make, {
                            children: "Configure Event Name Conventions",
                            size: "Medium"
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.maxWidth(Css.px(480)),
                                  tl: /* [] */0
                                })
                          }, React.createElement("span", undefined, React.createElement($$Text.make, {
                                    element: "Span",
                                    size: "Large",
                                    color: Styles.Color.light10,
                                    children: "Enable anyone on your team to create events that match your naming convention. The advanced rules setting lets you break down your events into its components and define and enforce rules for each component. "
                                  })))), React.createElement(Spacer.make, {
                        height: 80
                      }), React.createElement(Core.Radio.Group, {
                        onChange: (function (value) {
                            return Curry._1(setEventNamingConventions, (function (param) {
                                          return value;
                                        }));
                          }),
                        value: eventNamingConventions,
                        children: null
                      }, React.createElement("div", {
                            className: configBlock(eventNamingConventions === "basic")
                          }, React.createElement("div", {
                                className: radioContainer("Available")
                              }, React.createElement(Core.Radio, {
                                    value: "basic",
                                    size: "md",
                                    color: "magenta100",
                                    label: "Basic"
                                  })), React.createElement("div", {
                                className: innerConfigBlock(eventNamingConventions === "basic")
                              }, React.createElement(GuardRailsConfigComponents.GuardRailRow.make, {
                                    name: "Case",
                                    editAvailability: "Available",
                                    editPlanHint: Workspace.emptyPlan,
                                    enforceAvailability: canForceBranchAudit,
                                    enforcePlanHint: forceBranchAuditPlanHint,
                                    viewerCanEdit: viewerCanEdit,
                                    rule: simpleEventNamingConventions,
                                    configUpgradePrompt: {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "BillingPrompt",
                                        VAL: "IssueReportConfig"
                                      }
                                    },
                                    commonNameCase: commonEventNameCase,
                                    handleChange: (function (updatedRule) {
                                        return Curry._1(setSimpleEventNamingConventions, (function (param) {
                                                      return updatedRule;
                                                    }));
                                      }),
                                    tooltipMarkdown: "Checks that event name casing is consistent across all events to maximize data accessibility.",
                                    withToggles: false,
                                    nameType: "simple"
                                  }))), React.createElement(Spacer.make, {
                            height: 56
                          }), React.createElement("div", {
                            className: configBlock(eventNamingConventions === "advanced")
                          }, React.createElement("div", {
                                className: radioContainer(canForceBranchAudit)
                              }, React.createElement(Core.Radio, {
                                    value: "advanced",
                                    size: "md",
                                    color: "magenta100",
                                    label: "Advanced"
                                  }), hasGuardrailsForAllFeatureFlag ? null : React.createElement(EntityLightning.make, {
                                      availability: canForceBranchAudit,
                                      planHint: forceBranchAuditPlanHint,
                                      fill: Styles.Color.light08
                                    })), tmp$1, React.createElement("div", {
                                className: innerConfigBlock(eventNamingConventions === "advanced")
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Medium",
                                        weight: "Semi",
                                        color: Styles.Color.light12,
                                        children: "Framework components"
                                      }), React.createElement(Tooltip.Default.make, {
                                        size: "small",
                                        title: "Framework components",
                                        children: React.createElement(Tooltip.Content.make, {
                                              children: React.createElement(Markdown.make, {
                                                    source: "Components are the building blocks of your event name structure.\n\n__Free input components__ allow any input, but you can define blocked words to prevent synonyms.\n\n__Predefined values components__ let you provide a list of allowed words for the component\n\n> Example: Allowed Action values could be “cancelled, clicked, opened, submitted, viewed, etc\"\n"
                                                  })
                                            })
                                      })), React.createElement(ReactBeautifulDnd$1.DragDropContext, {
                                    onDragEnd: (function (info) {
                                        if (!hasCustomStructs) {
                                          return Curry._1(globalSend, {
                                                      TAG: /* OpenModal */4,
                                                      _0: {
                                                        NAME: "BillingPrompt",
                                                        VAL: "IssueReportConfig"
                                                      }
                                                    });
                                        }
                                        var match = info.destination;
                                        var ordered = ArrayExt.reinsertIndex(advancedNameConventions$1, info.source.index, !(match == null) ? match.index : 0);
                                        return Curry._1(reduceFrameworkState, {
                                                    NAME: "setNameConventions",
                                                    VAL: (function (param) {
                                                        return ordered;
                                                      })
                                                  });
                                      }),
                                    children: React.createElement(ReactBeautifulDnd$1.Droppable, {
                                          droppableId: "nameComponents",
                                          children: (function (provided, _snapshot) {
                                              return React.createElement(ReactBeautifulDnd.DraggableWrapper.make, {
                                                          droppableProps: provided.droppableProps,
                                                          children: React.createElement("div", {
                                                                className: Curry._1(Css.style, {
                                                                      hd: Css.display("flex"),
                                                                      tl: {
                                                                        hd: Css.flexDirection("column"),
                                                                        tl: {
                                                                          hd: Css.gap(Css.px(16)),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    })
                                                              }, Belt_Array.mapWithIndex(advancedNameConventions$1, (function (changingIndex, nameComponent) {
                                                                      return React.createElement(ReactBeautifulDnd$1.Draggable, {
                                                                                  key: String(changingIndex),
                                                                                  draggableId: String(changingIndex),
                                                                                  index: changingIndex,
                                                                                  children: (function (provided, _snapshot) {
                                                                                      return React.createElement(React.Fragment, undefined, React.createElement(ReactBeautifulDnd.ItemWrapper.make, {
                                                                                                      dragHandleProps: provided.dragHandleProps,
                                                                                                      draggableProps: provided.draggableProps,
                                                                                                      children: React.createElement(NameConventionsConfig$NameComponent, {
                                                                                                            globalCase: globalCase,
                                                                                                            hasPredefinedValues: hasPredefinedValues,
                                                                                                            hasCustomStructs: hasCustomStructs,
                                                                                                            nameComponent: nameComponent,
                                                                                                            removeValue: (function (param) {
                                                                                                                return Curry._1(reduceFrameworkState, {
                                                                                                                            NAME: "setNameConventions",
                                                                                                                            VAL: (function (currentValues) {
                                                                                                                                return Belt_Array.keepMapU(Belt_Array.mapWithIndex(currentValues, (function (removingIndex, nameComponent) {
                                                                                                                                                  if (changingIndex === removingIndex) {
                                                                                                                                                    return ;
                                                                                                                                                  } else {
                                                                                                                                                    return nameComponent;
                                                                                                                                                  }
                                                                                                                                                })), (function (x) {
                                                                                                                                              return x;
                                                                                                                                            }));
                                                                                                                              })
                                                                                                                          });
                                                                                                              }),
                                                                                                            setValue: (function (value) {
                                                                                                                return Curry._1(reduceFrameworkState, {
                                                                                                                            NAME: "setNameConventions",
                                                                                                                            VAL: (function (currentValues) {
                                                                                                                                return Belt_Array.mapWithIndex(currentValues, (function (i, nameComponent) {
                                                                                                                                              if (changingIndex === i) {
                                                                                                                                                return value;
                                                                                                                                              } else {
                                                                                                                                                return nameComponent;
                                                                                                                                              }
                                                                                                                                            }));
                                                                                                                              })
                                                                                                                          });
                                                                                                              }),
                                                                                                            key: String(changingIndex) + "-" + (
                                                                                                              nameComponent.NAME === "separator" ? "separator" : "buildingBlock"
                                                                                                            )
                                                                                                          }),
                                                                                                      ref: provided.innerRef
                                                                                                    }), provided.placeholder);
                                                                                    })
                                                                                });
                                                                    })), provided.placeholder),
                                                          ref: provided.innerRef
                                                        });
                                            })
                                        })
                                  }), React.createElement(Spacer.make, {
                                    height: 0
                                  }), React.createElement(TextButton.make, {
                                    onClick: (function (param) {
                                        if (hasCustomStructs) {
                                          return Curry._1(reduceFrameworkState, {
                                                      NAME: "setNameConventions",
                                                      VAL: (function (currentValues) {
                                                          var match = Belt_Array.get(currentValues, currentValues.length - 1 | 0);
                                                          if (match !== undefined && match.NAME !== "separator") {
                                                            var lastBuildingBlock = match.VAL;
                                                            var $$case = Curry._1(ValidationConfigV2.Value.getCase, lastBuildingBlock.case.value);
                                                            var expectedSeparator = Case.getCaseSeparator($$case);
                                                            if (expectedSeparator !== undefined) {
                                                              return Belt_Array.concat(currentValues, [{
                                                                            NAME: "separator",
                                                                            VAL: expectedSeparator
                                                                          }]);
                                                            }
                                                            var init = lastBuildingBlock.allowedValues;
                                                            return Belt_Array.concat(currentValues, [{
                                                                          NAME: "nameBuildingBlock",
                                                                          VAL: {
                                                                            name: lastBuildingBlock.name,
                                                                            case: lastBuildingBlock.case,
                                                                            allowedValues: {
                                                                              value: {
                                                                                NAME: "allowedValues",
                                                                                VAL: []
                                                                              },
                                                                              severity: init.severity
                                                                            },
                                                                            blockedValues: lastBuildingBlock.blockedValues
                                                                          }
                                                                        }]);
                                                          }
                                                          return Belt_Array.concat(currentValues, [{
                                                                        NAME: "nameBuildingBlock",
                                                                        VAL: ValidationConfigV2.NameComponent.NameBuildingBlock.empty
                                                                      }]);
                                                        })
                                                    });
                                        } else {
                                          return Curry._1(globalSend, {
                                                      TAG: /* OpenModal */4,
                                                      _0: {
                                                        NAME: "BillingPrompt",
                                                        VAL: "IssueReportConfig"
                                                      }
                                                    });
                                        }
                                      }),
                                    style: "Blue",
                                    children: null
                                  }, "+ Add Name Component", React.createElement(Spacer.make, {
                                        width: 8
                                      }), React.createElement(EntityLightning.make, {
                                        availability: Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention.CustomStructs.computeAvailability, workspace),
                                        planHint: Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention.CustomStructs.availableOnPlan, workspace),
                                        stroke: Styles.Color.light10,
                                        fill: Styles.Color.white
                                      }))))), React.createElement(Spacer.make, {
                        height: 40
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.justifyContent("flexEnd"),
                                tl: {
                                  hd: Css.alignItems("flexEnd"),
                                  tl: {
                                    hd: Css.gap(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement(Button.make, {
                            label: "Cancel",
                            onClick: (function (param) {
                                return Curry._1(onClose, undefined);
                              }),
                            style: "ghost"
                          }), React.createElement(Button.make, {
                            disabled: !changes,
                            label: "Reset",
                            onClick: onReset,
                            style: "ghost"
                          }), React.createElement(Button.make, {
                            disabled: validation.length !== 0 || !changes,
                            label: "Save",
                            onClick: (function (param) {
                                Curry._1(onSave, GuardRailsConfigUtils.getNameComponents(true, eventNamingConventions, simpleEventNamingConventions, advancedNameConventions$1));
                                resetOnNextOpen.current = true;
                                return Curry._1(onClose, undefined);
                              })
                          })), React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.flexDirection("column"),
                                tl: {
                                  hd: Css.alignItems("flexEnd"),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, Belt_Array.map(validation, (function (validationItem) {
                              var validationType = validationItem.VAL;
                              return React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.red,
                                          children: typeof validationType === "object" ? React.createElement(React.Fragment, undefined, "Allowed value ", React.createElement("em", undefined, validationType.VAL.VAL), " doesn’t match case") : "Name is required for name components"
                                        });
                            })))), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.label("Examples"),
                          tl: {
                            hd: Css.width(Css.px(350)),
                            tl: /* [] */0
                          }
                        })
                  }))
        });
  } else {
    tmp = null;
  }
  return React.createElement(FramerMotion.AnimatePresence, {
              exitBeforeEnter: true,
              children: tmp
            });
}

var make = NameConventionsConfig;

export {
  frameworkToJs ,
  frameworkFromJs ,
  NameComponentTypeSelector ,
  FrameworkSelector ,
  GlobalCaseSelector ,
  CaseSelector ,
  RemoveNameComponentButton ,
  NameComponent ,
  Styles$2 as Styles,
  make ,
  
}
/* ResizableSelect Not a pure module */
