// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Select from "./Select.mjs";
import * as Styles from "./styles.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ActionUtils from "../../model/src/ActionUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as ProfilePhoto from "./ProfilePhoto.mjs";
import * as App from "firebase/app";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function resendInvite(schemaId, invite, viewerId, schemaBundle, globalSend) {
  return Belt_Option.forEach(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser), (function (user) {
                user.getIdToken().then(function (token) {
                      return fetch(Firebase.apiUrl + "/sendInvite", Fetch.RequestInit.make(/* Post */2, [
                                          [
                                            "Accept",
                                            "application/json"
                                          ],
                                          [
                                            "Content-Type",
                                            "application/json"
                                          ],
                                          [
                                            "Authorization",
                                            "Bearer " + token
                                          ]
                                        ], Caml_option.some(Belt_Option.getWithDefault(JSON.stringify({
                                                      emailToInvite: invite.id,
                                                      schemaId: schemaId
                                                    }), "")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (response) {
                                  var match = response.status;
                                  if (match !== 200) {
                                    if (match !== 429) {
                                      Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "AlertModal",
                                              VAL: [
                                                "Failed to send invite",
                                                "It looks like something unexpected occurred, please try again. If this issue persists, please contact support.",
                                                "Ok",
                                                (function (param) {
                                                    
                                                  })
                                              ]
                                            }
                                          });
                                      return Promise.resolve(undefined);
                                    } else {
                                      Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "AlertModal",
                                              VAL: [
                                                "Failed to send invite",
                                                "You've just recently resent an invite to this user. Please wait a moment before trying again.",
                                                "Ok",
                                                (function (param) {
                                                    
                                                  })
                                              ]
                                            }
                                          });
                                      return Promise.resolve(undefined);
                                    }
                                  }
                                  var match$1 = invite.role;
                                  var tmp;
                                  switch (match$1) {
                                    case "Admin" :
                                        tmp = "Admin";
                                        break;
                                    case "Comment Only" :
                                        tmp = "CommentOnly";
                                        break;
                                    case "Editor" :
                                        tmp = "Editor";
                                        break;
                                    case "Viewer" :
                                        tmp = "Viewer";
                                        break;
                                    default:
                                      tmp = "Editor";
                                  }
                                  AnalyticsRe.memberInviteResent(schemaBundle, tmp, invite.id, schemaBundle.branchId, schemaBundle.schemaId);
                                  var actionRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").doc();
                                  var action_1 = [
                                    invite.id,
                                    invite.role
                                  ];
                                  var action = {
                                    NAME: "ResendInvite",
                                    VAL: action_1
                                  };
                                  return actionRef.set({
                                              id: actionRef.id,
                                              contentsJson: Printer.printAction(action),
                                              createdAt: App.default.firestore.FieldValue.serverTimestamp(),
                                              createdBy: viewerId,
                                              branchId: "master",
                                              audit: true,
                                              actionImpactLevel: ActionUtils.getActionImpactLevelFromAction(action),
                                              order: 0
                                            });
                                });
                    });
                
              }));
}

function removeInvite(schemaId, invite, viewerId, schemaBundle) {
  var batch = Firebase.app(undefined).firestore().batch();
  var inviteRef = Firebase.app(undefined).firestore().collection("invites").doc(invite.inviteId);
  var schemaInviteRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("invites").doc(invite.id);
  batch.delete(inviteRef);
  batch.delete(schemaInviteRef);
  var actionRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").doc();
  var action_1 = [
    invite.id,
    invite.role
  ];
  var action = {
    NAME: "RemoveInvite",
    VAL: action_1
  };
  batch.set(actionRef, {
        id: actionRef.id,
        contentsJson: Printer.printAction(action),
        createdAt: App.default.firestore.FieldValue.serverTimestamp(),
        createdBy: viewerId,
        branchId: "master",
        audit: true,
        actionImpactLevel: ActionUtils.getActionImpactLevelFromAction(action),
        order: 0
      });
  $$Promise.$$catch(batch.commit(), (function (error) {
          return Promise.resolve((console.log("error", error), undefined));
        }));
  var match = invite.role;
  var tmp;
  switch (match) {
    case "Admin" :
        tmp = "Admin";
        break;
    case "Comment Only" :
        tmp = "CommentOnly";
        break;
    case "Editor" :
        tmp = "Editor";
        break;
    case "Viewer" :
        tmp = "Viewer";
        break;
    default:
      tmp = "Editor";
  }
  AnalyticsRe.memberInviteRemoved(schemaBundle, invite.id, tmp, schemaBundle.branchId, schemaBundle.schemaId);
  
}

var rootStyles = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(16)),
      tl: {
        hd: Css.paddingBottom(Css.px(16)),
        tl: {
          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
          tl: /* [] */0
        }
      }
    });

var infoStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.marginLeft(Css.px(12)),
          tl: {
            hd: Css.flexGrow(1.0),
            tl: {
              hd: Css.flexShrink(1.0),
              tl: {
                hd: Css.marginRight(Css.px(8)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function SchemaInvites(Props) {
  var viewerId = Props.viewerId;
  var viewerRole = Props.viewerRole;
  var workspace = WorkspaceContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var schemaInvites = FirebaseFetcherHooks.useSchemaInvites(workspace.id);
  var hasCodegenAccessRole = AppFeatureFlag.useFeatureFlag("CodegenAccessRole");
  var globalSend = GlobalSendContext.use(undefined);
  return Belt_List.toArray(Belt_List.map(schemaInvites, (function (invite) {
                    var filter = invite.filter;
                    var match = Models.Role.tFromJs(invite.role);
                    var tmp;
                    var exit = 0;
                    if (viewerRole === "Admin" && match !== undefined) {
                      tmp = React.createElement(Select.make, {
                            disabled: true,
                            onSelect: (function (param) {
                                
                              }),
                            options: Belt_Array.map(RoleUtils.availableRoles(hasCodegenAccessRole, workspace), (function (inviteRole) {
                                    return [
                                            {
                                              NAME: "Label",
                                              VAL: RoleUtils.getLabel(inviteRole, AvoLimits.ViewersCanComment.isAvailable(workspace))
                                            },
                                            Models.Role.tToJs(inviteRole)
                                          ];
                                  })),
                            value: Models.Role.tToJs(match)
                          });
                    } else {
                      exit = 1;
                    }
                    if (exit === 1) {
                      tmp = match !== undefined ? React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light10,
                              children: RoleUtils.getLabel(match, AvoLimits.ViewersCanComment.isAvailable(workspace))
                            }) : null;
                    }
                    return React.createElement("div", {
                                key: invite.id,
                                className: rootStyles
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement(ProfilePhoto.Base.make, {
                                        picture: undefined,
                                        name: undefined,
                                        email: invite.id,
                                        size: 40
                                      }), React.createElement("div", {
                                        className: infoStyles
                                      }, React.createElement($$Text.make, {
                                            size: "Medium",
                                            weight: "Semi",
                                            children: invite.id
                                          }), React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            color: Styles.Color.light08,
                                            children: null
                                          }, "Invited", filter !== undefined && Caml_obj.caml_notequal(filter.tags, []) ? " - Limited to tags: " + filter.tags.join(", ") : null)), tmp, viewerRole === "Admin" ? React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.marginLeft(Css.px(8)),
                                                tl: /* [] */0
                                              })
                                        }, React.createElement(ContextMenu.make, {
                                              options: [
                                                {
                                                  NAME: "Option",
                                                  VAL: {
                                                    label: "Resend Invite",
                                                    onClick: (function (param) {
                                                        return resendInvite(workspace.id, invite, viewerId, schemaBundle, globalSend);
                                                      })
                                                  }
                                                },
                                                {
                                                  NAME: "Option",
                                                  VAL: {
                                                    label: "Remove Invite",
                                                    onClick: (function (param) {
                                                        return removeInvite(workspace.id, invite, viewerId, schemaBundle);
                                                      })
                                                  }
                                                }
                                              ]
                                            })) : null));
                  })));
}

var make = SchemaInvites;

export {
  resendInvite ,
  removeInvite ,
  rootStyles ,
  infoStyles ,
  make ,
  
}
/* rootStyles Not a pure module */
