// Generated by ReScript, PLEASE EDIT WITH CARE

import * as PropertyUtils from "../PropertyUtils.mjs";

function oneLine(overrideType) {
  var absenceOverride;
  if (!overrideType) {
    return "Excluded from this variant";
  }
  var match = overrideType._0;
  var match$1 = match.pinnedValue;
  if (match$1) {
    var literal = match$1._0;
    if (literal !== undefined) {
      return "Pinned to " + PropertyUtils.printPinnedValue(literal) + " on this variant";
    }
    var absenceOverride$1 = match.absence;
    if (!absenceOverride$1) {
      return "Pinned value removed on this variant";
    }
    absenceOverride = absenceOverride$1._0;
  } else {
    var absenceOverride$2 = match.absence;
    if (!absenceOverride$2) {
      return "Included in this variant";
    }
    absenceOverride = absenceOverride$2._0;
  }
  var tmp;
  switch (absenceOverride) {
    case /* AlwaysSent */0 :
        tmp = "always sent";
        break;
    case /* SometimesSent */1 :
        tmp = "sometimes sent";
        break;
    case /* NeverSent */2 :
        tmp = "never sent";
        break;
    
  }
  return "Presence set to " + tmp + " on this variant";
}

function includeExcludeOverride(overrideType) {
  if (overrideType) {
    return "Included in this variant";
  } else {
    return "Excluded from this variant";
  }
}

function pinOverride(overrideType) {
  if (!overrideType) {
    return "";
  }
  var match = overrideType._0.pinnedValue;
  if (!match) {
    return "No pinned value override on this variant";
  }
  var literal = match._0;
  if (literal !== undefined) {
    return "Pinned to " + PropertyUtils.printPinnedValue(literal) + " on this variant";
  } else {
    return "Pinned value removed on this variant";
  }
}

function absenceOverride(overrideType) {
  if (!overrideType) {
    return "";
  }
  var absenceOverride$1 = overrideType._0.absence;
  if (!absenceOverride$1) {
    return "No presence override on this variant";
  }
  var tmp;
  switch (absenceOverride$1._0) {
    case /* AlwaysSent */0 :
        tmp = "Always Sent";
        break;
    case /* SometimesSent */1 :
        tmp = "Sometimes Sent";
        break;
    case /* NeverSent */2 :
        tmp = "Never Sent";
        break;
    
  }
  return "Presence override set to " + tmp + " on this variant";
}

export {
  oneLine ,
  includeExcludeOverride ,
  pinOverride ,
  absenceOverride ,
  
}
/* PropertyUtils Not a pure module */
