// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Metric from "./Metric.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import Shortid from "shortid";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as RouterStore from "./RouterStore.mjs";
import QuillDelta from "quill-delta";
import * as CommentInput from "./CommentInput.mjs";
import * as DrawerHeader from "./detailviews/DrawerHeader.mjs";
import * as OpsWithState from "./OpsWithState.mjs";
import * as EventAutofill from "./EventAutofill.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as MetricAutofill from "./MetricAutofill.mjs";
import * as IconDescription from "./IconDescription.mjs";
import * as QuillWithToolbar from "./QuillWithToolbar.mjs";
import * as ObjectActivityLog from "./ObjectActivityLog.mjs";
import * as OpsWithDeltaState from "./OpsWithDeltaState.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var itemRowLinkStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.margin2(Css.px(3), Css.px(0)),
          tl: {
            hd: Css.padding2(Css.px(10), Css.px(15)),
            tl: {
              hd: Css.borderRadius(Styles.Border.radius),
              tl: {
                hd: Css.width(Css.pct(100)),
                tl: {
                  hd: Css.flexGrow(1.0),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light02),
                    tl: {
                      hd: Css.color(Styles.Color.light12),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$short
                            }, undefined, undefined, "all"),
                        tl: {
                          hd: Css.hover({
                                hd: Css.borderColor(Styles.Color.light04),
                                tl: {
                                  hd: Css.color(Styles.Color.darkBlue),
                                  tl: /* [] */0
                                }
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function Category(Props) {
  var category = Props.category;
  var currentBranch = Props.currentBranch;
  var goToCommentId = Props.goToCommentId;
  var openBranches = Props.openBranches;
  var model = Props.model;
  var role = Props.role;
  var currentFilters = Props.currentFilters;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var context = {
    goalId: category.id,
    goalQuery: category.id
  };
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  var tmp = {
    object_: {
      NAME: "Goal",
      VAL: [
        category.id,
        category.name
      ]
    },
    objectId: category.id,
    currentBranch: currentBranch,
    openBranches: openBranches
  };
  if (goToCommentId !== undefined) {
    tmp.goToCommentId = Caml_option.valFromOption(goToCommentId);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.height(Css.pct(100.0)),
                        tl: {
                          hd: Css.overflowY("auto"),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(DrawerHeader.make, {
                  kind: "Category",
                  options: [{
                      NAME: "Option",
                      VAL: {
                        label: "Archive Category",
                        onClick: (function (param) {
                            if (window.confirm("Are you sure you would like to archive the category \"" + category.name + "\"")) {
                              var newDrawerItems = Belt_List.keep(drawerItems, (function (item) {
                                      if (typeof item === "object" && item.NAME === "category") {
                                        return item.VAL[0] !== category.id;
                                      } else {
                                        return true;
                                      }
                                    }));
                              Router.Schema.replaceDrawerItems(undefined, newDrawerItems);
                              Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (branch) {
                                        return AnalyticsRe.categoryArchived(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), category.id, category.name, NamedBranch.getId(branch), schemaBundle.schemaId);
                                      }),
                                    undefined,
                                    [[
                                        {
                                          NAME: "Archive",
                                          VAL: {
                                            NAME: "Goal",
                                            VAL: category.id
                                          }
                                        },
                                        {
                                          goalId: category.id,
                                          goalQuery: category.id
                                        }
                                      ]]
                                  ]);
                              return Curry._1(addToast, {
                                          message: "The category has been archived"
                                        });
                            }
                            
                          })
                      }
                    }],
                  children: React.createElement(OpsWithState.make, {
                        value: category.name,
                        onFlush: (function (name) {
                            return Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [[
                                            {
                                              NAME: "UpdateGoalName",
                                              VAL: [
                                                category.id,
                                                name
                                              ]
                                            },
                                            context
                                          ]]
                                      ]);
                          }),
                        onChangeCompleted: (function (value, param) {
                            AnalyticsRe.categoryUpdated(schemaBundle, value, category.id, "NameUpdated", "N/A", undefined, "N/A", "N/A", schemaBundle.branchId, schemaBundle.schemaId);
                            
                          }),
                        children: (function (value, onChange, onBlur, onFocus) {
                            return React.createElement("input", {
                                        className: Curry._1(Css.merge, {
                                              hd: Curry._1(Css.style, Styles.input),
                                              tl: {
                                                hd: Curry._1(Css.style, {
                                                      hd: Css.color(Styles.Color.light12),
                                                      tl: {
                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                        tl: {
                                                          hd: Css.fontSize(Styles.FontSize.medium),
                                                          tl: {
                                                            hd: Css.border(Css.px(1), "solid", "transparent"),
                                                            tl: {
                                                              hd: Css.borderRadius(Styles.Border.radius),
                                                              tl: {
                                                                hd: Css.backgroundColor("transparent"),
                                                                tl: {
                                                                  hd: Css.paddingLeft(Css.px(4)),
                                                                  tl: {
                                                                    hd: Css.marginLeft(Css.px(-4)),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }),
                                        placeholder: "Untitled Category...",
                                        value: value,
                                        onFocus: (function (domEvent) {
                                            return Curry._1(onFocus, domEvent.target.value);
                                          }),
                                        onBlur: (function (domEvent) {
                                            return Curry._1(onBlur, domEvent.target.value);
                                          }),
                                        onChange: (function (domEvent) {
                                            return Curry._1(onChange, domEvent.target.value);
                                          })
                                      });
                          })
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.position("relative"),
                              tl: {
                                hd: Css.overflowY("auto"),
                                tl: {
                                  hd: Css.zIndex(0),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("flexStart"),
                              tl: {
                                hd: Css.padding4(Css.px(15), Css.px(30), Css.px(0), Css.px(18)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginTop(Css.px(13)),
                                tl: /* [] */0
                              })
                        }, React.createElement(IconDescription.make, {})), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.border(Css.px(1), "solid", "transparent"),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.marginLeft(Css.px(5)),
                                      tl: {
                                        hd: Css.width(Css.pct(100)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, React.createElement(OpsWithDeltaState.make, {
                              value: new QuillDelta(JSON.parse(category.description)),
                              onFlush: (function (description) {
                                  return Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              [[
                                                  {
                                                    NAME: "UpdateGoalDescription",
                                                    VAL: [
                                                      category.id,
                                                      JSON.stringify(description)
                                                    ]
                                                  },
                                                  context
                                                ]]
                                            ]);
                                }),
                              onChangeCompleted: (function (param, param$1) {
                                  return AnalyticsRe.categoryUpdated(schemaBundle, category.name, category.id, "DescriptionUpdated", "N/A", undefined, "N/A", "N/A", schemaBundle.branchId, schemaBundle.schemaId);
                                }),
                              children: (function (value, onChange, onBlur, param) {
                                  return React.createElement(QuillWithToolbar.make, {
                                              value: value,
                                              onChange: Curry.__1(onChange),
                                              id: "category-" + category.id,
                                              placeholder: "Description...",
                                              onBlur: (function (param) {
                                                  return Curry._1(onBlur, value);
                                                }),
                                              scrollOnFocus: false,
                                              className: {
                                                hd: Css.backgroundColor("transparent"),
                                                tl: {
                                                  hd: Css.padding(Css.px(0)),
                                                  tl: {
                                                    hd: Css.margin(Css.px(0)),
                                                    tl: {
                                                      hd: Css.fontSize(Styles.FontSize.tiny),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            });
                                })
                            }))), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding2(Css.px(0), Css.px(15)),
                            tl: /* [] */0
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "Metrics"
                        }), React.createElement(Spacer.make, {
                          height: 12
                        }), Belt_List.toArray(Belt_List.map(Belt_List.keepMap(category.metrics, (function (metricId) {
                                    return Belt_List.getBy(model.metrics, (function (metric) {
                                                  return metric.id === metricId;
                                                }));
                                  })), (function (metric) {
                                return React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement(Link.make, {
                                                path: Router.Link.addDrawerItem(undefined, {
                                                      NAME: "metric",
                                                      VAL: [
                                                        metric.id,
                                                        undefined
                                                      ]
                                                    }),
                                                className: itemRowLinkStyles,
                                                children: null
                                              }, React.createElement(Metric.FunnelTypeIcon.make, {
                                                    type_: metric.type_
                                                  }), React.createElement(Spacer.make, {
                                                    width: 12
                                                  }), React.createElement($$Text.make, {
                                                    size: "Medium",
                                                    weight: "Semi",
                                                    children: metric.name === "" ? "Untitled Metric" : metric.name
                                                  })), React.createElement(Spacer.make, {
                                                width: 8
                                              }), React.createElement(ContextMenu.make, {
                                                options: [{
                                                    NAME: "Option",
                                                    VAL: {
                                                      label: "Remove Metric",
                                                      onClick: (function (param) {
                                                          return Curry.app(sendActions, [
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      (function (branch) {
                                                                          return AnalyticsRe.categoryUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), category.name, category.id, "MetricRemoved", metric.name, metric.id, "N/A", "N/A", NamedBranch.getId(branch), schemaBundle.schemaId);
                                                                        }),
                                                                      undefined,
                                                                      [[
                                                                          {
                                                                            NAME: "RemoveMetricFromGoal",
                                                                            VAL: [
                                                                              category.id,
                                                                              metric.id
                                                                            ]
                                                                          },
                                                                          {
                                                                            goalId: category.id,
                                                                            goalQuery: category.id,
                                                                            metricId: metric.id,
                                                                            metricQuery: metric.id
                                                                          }
                                                                        ]]
                                                                    ]);
                                                        })
                                                    }
                                                  }]
                                              }));
                              }))), React.createElement(MetricAutofill.make, {
                          metrics: model.metrics,
                          selectedMetrics: category.metrics,
                          onSelect: (function (item, param, param$1) {
                              return Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          [[
                                              {
                                                NAME: "AddMetricToGoal",
                                                VAL: [
                                                  category.id,
                                                  item.id
                                                ]
                                              },
                                              {
                                                goalId: category.id,
                                                goalQuery: category.id,
                                                metricId: item.id
                                              }
                                            ]]
                                        ]);
                            }),
                          onCreate: (function (metricName) {
                              var metricId = Shortid();
                              var metricContext = {
                                metricId: metricId,
                                metricQuery: metricId
                              };
                              return Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (branch) {
                                              Router.Schema.pushDrawerItem(undefined, undefined, {
                                                    NAME: "metric",
                                                    VAL: [
                                                      metricId,
                                                      undefined
                                                    ]
                                                  });
                                              AnalyticsRe.metricInitiated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), metricId, metricName, NamedBranch.getId(branch), schemaBundle.schemaId);
                                              return AnalyticsRe.categoryUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), category.name, category.id, "MetricAdded", metricName, metricId, "N/A", "N/A", NamedBranch.getId(branch), schemaBundle.schemaId);
                                            }),
                                          undefined,
                                          [
                                            [
                                              {
                                                NAME: "AddMetric",
                                                VAL: metricId
                                              },
                                              metricContext
                                            ],
                                            [
                                              {
                                                NAME: "UpdateMetricName",
                                                VAL: [
                                                  metricId,
                                                  metricName
                                                ]
                                              },
                                              metricContext
                                            ],
                                            [
                                              {
                                                NAME: "AddMetricToGoal",
                                                VAL: [
                                                  category.id,
                                                  metricId
                                                ]
                                              },
                                              {
                                                goalId: category.id,
                                                goalQuery: category.id,
                                                metricId: metricId
                                              }
                                            ]
                                          ]
                                        ]);
                            })
                        })), React.createElement(Spacer.make, {
                      height: 12
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding2(Css.px(0), Css.px(15)),
                            tl: /* [] */0
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "Events"
                        }), React.createElement(Spacer.make, {
                          height: 12
                        }), Belt_List.toArray(Belt_List.map(Belt_List.keepMap(category.events, (function (eventId) {
                                    return Belt_List.getBy(model.events, (function ($$event) {
                                                  return $$event.id === eventId;
                                                }));
                                  })), (function ($$event) {
                                return React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement(Link.make, {
                                                path: Router.Link.addDrawerItem(undefined, {
                                                      NAME: "event",
                                                      VAL: [
                                                        $$event.id,
                                                        undefined,
                                                        undefined,
                                                        false
                                                      ]
                                                    }),
                                                className: itemRowLinkStyles,
                                                children: React.createElement($$Text.make, {
                                                      size: "Medium",
                                                      weight: "Semi",
                                                      children: $$event.name === "" ? "Untitled Event" : $$event.name
                                                    })
                                              }), React.createElement(Spacer.make, {
                                                width: 8
                                              }), React.createElement(ContextMenu.make, {
                                                options: [{
                                                    NAME: "Option",
                                                    VAL: {
                                                      label: "Remove Event",
                                                      onClick: (function (param) {
                                                          return Curry.app(sendActions, [
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      (function (branch) {
                                                                          return AnalyticsRe.categoryUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), category.name, category.id, "EventRemoved", "N/A", undefined, $$event.name, $$event.id, NamedBranch.getId(branch), schemaBundle.schemaId);
                                                                        }),
                                                                      undefined,
                                                                      [[
                                                                          {
                                                                            NAME: "RemoveEventFromGoal",
                                                                            VAL: [
                                                                              category.id,
                                                                              $$event.id
                                                                            ]
                                                                          },
                                                                          {
                                                                            eventId: $$event.id,
                                                                            eventQuery: $$event.id,
                                                                            goalId: category.id,
                                                                            goalQuery: category.id
                                                                          }
                                                                        ]]
                                                                    ]);
                                                        })
                                                    }
                                                  }]
                                              }));
                              }))), React.createElement(EventAutofill.make, {
                          events: model.events,
                          selectedEvents: Belt_List.toArray(category.events),
                          onSelectSuccess: (function (eventId, eventName, branch) {
                              return AnalyticsRe.categoryUpdated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), category.name, category.id, "EventAdded", "N/A", undefined, eventName, eventId, NamedBranch.getId(branch), schemaBundle.schemaId);
                            }),
                          renderButton: (function (onClick, buttonRef) {
                              return React.createElement(React.Fragment, undefined, React.createElement(TextButton.make, {
                                              onClick: onClick,
                                              style: "Blue",
                                              size: "Medium",
                                              children: "+ Add Event",
                                              ref: buttonRef
                                            }));
                            }),
                          currentFilters: currentFilters,
                          eventOrigin: "CategoryDetails",
                          selectActions: (function (eventId) {
                              return [[
                                        {
                                          NAME: "AddEventToGoal",
                                          VAL: [
                                            category.id,
                                            eventId
                                          ]
                                        },
                                        {
                                          eventId: eventId,
                                          goalId: category.id,
                                          goalQuery: category.id
                                        }
                                      ]];
                            })
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginTop(Css.px(35)),
                            tl: /* [] */0
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.padding4(Css.px(15), Css.px(0), Css.px(15), Css.px(25)),
                                tl: /* [] */0
                              })
                        }, React.createElement(ObjectActivityLog.make, tmp)), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.backgroundColor(Styles.Color.light02),
                                tl: /* [] */0
                              })
                        }, React.createElement(CommentInput.make, {
                              threadId: category.id,
                              itemType: "Goal",
                              itemName: category.name,
                              mini: true,
                              currentBranch: currentBranch,
                              role: role,
                              location: "Category"
                            })))));
}

var make = Category;

export {
  itemRowLinkStyles ,
  make ,
  
}
/* itemRowLinkStyles Not a pure module */
