// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DocsLink from "./DocsLink.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as ConnectToSlackButton from "./inspector/ConnectToSlackButton.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: /* [] */0
    });

var textBoxStyles = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(420)),
      tl: {
        hd: Css.marginTop(Css.px(12)),
        tl: {
          hd: Css.marginBottom(Css.px(16)),
          tl: /* [] */0
        }
      }
    });

function WorkspaceNotifications(Props) {
  var onClose = Props.onClose;
  var match = React.useContext(WorkspaceContext.workspaceContext);
  var settings = match.settings;
  var schemaId = match.id;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var allNotificationChannels = FirebaseFetcherHooks.useAllNotificationChannels(schemaId);
  var match$1 = typeof allNotificationChannels === "object" ? (
      allNotificationChannels.NAME === "Loaded" ? Belt_List.reduce(allNotificationChannels.VAL, [
              false,
              []
            ], (function (state, channel) {
                return Belt_Array.reduce(channel.events, state, (function (param, $$event) {
                              var savedViewsEvents = param[1];
                              var hasInspectorAlertsEnabled = param[0];
                              var match = Models.NotificationChannel.parseEventType($$event);
                              if (typeof match === "number") {
                                if (match !== 0) {
                                  return [
                                          hasInspectorAlertsEnabled,
                                          savedViewsEvents
                                        ];
                                } else {
                                  return [
                                          true,
                                          savedViewsEvents
                                        ];
                                }
                              } else {
                                return [
                                        hasInspectorAlertsEnabled,
                                        Belt_Array.concat([$$event], savedViewsEvents)
                                      ];
                              }
                            }));
              })) : [
          false,
          []
        ]
    ) : [
      false,
      []
    ];
  var savedViewsEvents = match$1[1];
  var hasInspectorAlertsEnabled = match$1[0];
  var channelsLength = savedViewsEvents.length + (
    hasInspectorAlertsEnabled ? 1 : 0
  ) | 0;
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: /* [] */0
                      })
                }, React.createElement(Title.make, {
                      children: "Workspace Notifications",
                      size: "Tiny"
                    }), React.createElement(Spacer.make, {
                      width: 16
                    }), React.createElement(DocsLink.make, {
                      path: "workspace/integrations#slack"
                    })), React.createElement("div", {
                  className: textBoxStyles
                }, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Medium",
                      color: Styles.Color.light10,
                      children: "To keep your team up to date with what's happening in your Avo workspace, Avo can post to a channel in your Slack when branches are opened, closed and merged, when branch status is updated, when comment is added, and when branch collaborators are added or removed."
                    })), React.createElement(ConnectToSlackButton.make, {
                  schemaId: schemaId,
                  eventType: /* WorkspaceNotifications */1,
                  onSuccessRedirectPath: "/schemas/" + schemaId + "/",
                  onConnect: (function (param) {
                      return AnalyticsRe.workspaceSettingUpdated(schemaBundle, "On", "WorkspaceSlackNotifications", undefined, schemaBundle.branchId, schemaBundle.schemaId, schemaId, settings.protectedMainBranch ? "ProtectedMainBranch" : "None", Belt_Array.concatMany([
                                      ["WorkspaceNotifications"],
                                      hasInspectorAlertsEnabled || Caml_obj.caml_notequal(savedViewsEvents, []) ? ["InspectorNotifications"] : []
                                    ]), settings.protectedMainBranch ? (
                                    settings.requireAdminApproval ? "Admin" : "Editor"
                                  ) : "None", settings.numRequiredApprovals);
                    }),
                  onDisconnect: (function (param) {
                      return AnalyticsRe.workspaceSettingUpdated(schemaBundle, "Off", "WorkspaceSlackNotifications", undefined, schemaBundle.branchId, schemaBundle.schemaId, schemaId, undefined, hasInspectorAlertsEnabled || Caml_obj.caml_notequal(savedViewsEvents, []) ? ["InspectorNotifications"] : [], undefined, undefined);
                    }),
                  disconnectWarningMessage: "Disconnecting will stop delivery of Slack notifications to the configured channel. You can reconnect at any time. Are you sure you want to disconnect?"
                }), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: /* [] */0
                      })
                }, React.createElement(Title.make, {
                      children: "Inspector Notifications",
                      size: "Tiny"
                    }), React.createElement(Spacer.make, {
                      width: 16
                    }), React.createElement(DocsLink.make, {
                      path: "workspace/inspector#connect-to-slack"
                    })), React.createElement("div", {
                  className: textBoxStyles
                }, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Medium",
                      color: Styles.Color.light10,
                      children: null
                    }, "Your workspace has Slack alerts set up for " + AvoUtils.plural(undefined, undefined, channelsLength, "saved view") + ". To review and manage them, navigate to the ", React.createElement(Link.make, {
                          path: Router.Link.getSchemaRouteLink(undefined, {
                                NAME: "inspector",
                                VAL: "savedViews"
                              }),
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.color(Styles.Color.darkBlue),
                                  tl: /* [] */0
                                }
                              }),
                          onClick: (function (param) {
                              return Curry._1(onClose, undefined);
                            }),
                          children: "saved views overview"
                        }), ".")));
}

var make = WorkspaceNotifications;

export {
  make ,
  
}
/* rootStyles Not a pure module */
