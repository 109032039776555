// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as ArrayExt from "../../../../../app/src/ArrayExt.mjs";
import * as AvoModel from "../../../../../app/src/avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ModelUtils_mapped from "../../../../../app/src/ModelUtils_mapped.mjs";
import * as InspectorPropertyType from "../../../../../shared/models/InspectorPropertyType.mjs";
import * as TrackingPlanMappedModel from "../../../../../model/src/TrackingPlanMappedModel.mjs";
import * as InspectorTrackingPlanUtils from "../../../../../shared/InspectorTrackingPlanUtils.mjs";
import * as InspectorIssuesGeneratorTrackingPlanUtils from "./InspectorIssuesGeneratorTrackingPlanUtils.mjs";
import * as GetAllEventUserAndGroupPropertyIdsForEventUseCase from "../../../../../model/src/base/GetAllEventUserAndGroupPropertyIdsForEventUseCase.mjs";

function getTrackingPlanEvent_mappedModel(events, rules, eventName) {
  return Curry._2(TrackingPlanMappedModel.Events.getBy, events, (function ($$event) {
                return Caml_obj.caml_equal(Belt_List.head(Belt_List.keepMapU(ModelUtils.getEventPossibleNamesFromRules($$event, Belt_List.fromArray(rules)), (function (name) {
                                      if (name === eventName) {
                                        return $$event;
                                      }
                                      
                                    }))), $$event);
              }));
}

function checkUnexpectedEventIssue(sourceId, modelEvent, model) {
  var isIncluded = ModelUtils.isEventIncludedInSource(undefined, model, sourceId, modelEvent.id);
  if (isIncluded) {
    return ;
  } else {
    return /* UnexpectedEvent */1;
  }
}

function checkUnexpectedEventIssue_mappedModel(sourceId, modelEvent, events) {
  var isIncluded = ModelUtils_mapped.isEventIncludedInSource(undefined, events, sourceId, modelEvent.id);
  if (isIncluded) {
    return ;
  } else {
    return /* UnexpectedEvent */1;
  }
}

function checkUnexpectedProperty(property, sourceId, modelEvent, model, propertyName, propertyType) {
  if (propertyType === "null" || property !== undefined && ModelUtils.isPropertyIncludedOnEventAndSource(true, property, modelEvent.id, sourceId, model)(undefined)) {
    return ;
  } else {
    return {
            TAG: /* UnexpectedProperty */2,
            _0: {
              eventId: modelEvent.id,
              propertyName: propertyName,
              propertyType: propertyType
            }
          };
  }
}

function checkUnexpectedProperty_mappedModel(property, sourceId, modelEvent, events, propertyBundles, propertyName, propertyType) {
  if (propertyType === "null" || property !== undefined && ModelUtils_mapped.isPropertyIncludedOnEventAndSource(true, events, sourceId, modelEvent.id, property)(propertyBundles)) {
    return ;
  } else {
    return {
            TAG: /* UnexpectedProperty */2,
            _0: {
              eventId: modelEvent.id,
              propertyName: propertyName,
              propertyType: propertyType
            }
          };
  }
}

function getLikeliestPropertyForEvent(model, propertyName, modelEvent, propertyType) {
  var maybeProperties = ModelUtils.getAllPropertiesByNameOrNameMapping(Caml_option.some(modelEvent.id), model, propertyName);
  var propertiesOnEvent = Belt_SetString.fromArray(GetAllEventUserAndGroupPropertyIdsForEventUseCase.get(model, modelEvent));
  var maybeEventProperty = Belt_List.getByU(maybeProperties, (function (property) {
          return Belt_SetString.has(propertiesOnEvent, property.id);
        }));
  if (maybeEventProperty !== undefined) {
    return maybeEventProperty;
  }
  var property = Belt_List.getByU(maybeProperties, (function (property) {
          return property.type_ === propertyType;
        }));
  if (property !== undefined) {
    return property;
  } else {
    return Belt_List.head(maybeProperties);
  }
}

function getLikeliestPropertyForEvent__mappedModel(model, propertyName, modelEvent, propertyType) {
  var maybeProperties = ModelUtils_mapped.getAllPropertiesByNameOrNameMapping(model.rules, model.destinations, model.properties, propertyName, modelEvent.id);
  var propertiesOnEvent = Belt_SetString.fromArray(ModelUtils_mapped.getEventPropertyIds(model.events, model.propertyBundles, modelEvent.id));
  var maybeEventProperty = Curry._2(TrackingPlanMappedModel.Properties.getBy, maybeProperties, (function (property) {
          return Belt_SetString.has(propertiesOnEvent, property.id);
        }));
  if (maybeEventProperty !== undefined) {
    return maybeEventProperty;
  }
  var property = Curry._2(TrackingPlanMappedModel.Properties.getBy, maybeProperties, (function (property) {
          return property.type_ === propertyType;
        }));
  if (property !== undefined) {
    return property;
  } else {
    return Curry._2(TrackingPlanMappedModel.Properties.getAtIndex, maybeProperties, 0);
  }
}

function checkMissingExpectedProperty(eventVariationProperties, modelEvent, sourceId, model) {
  var eventProperties = Belt_List.toArray(AvoModel.getResolvedPropertiesForEvent(model, modelEvent));
  var propertyIds = Belt_Array.keepMapU(eventVariationProperties, (function (param) {
          var property = getLikeliestPropertyForEvent(model, param[0], modelEvent, param[1]);
          if (property !== undefined) {
            return property.id;
          }
          
        }));
  return Belt_Array.keepMapU(eventProperties, (function (property) {
                var isEventPropertyFoundInVariation = Belt_Array.someU(propertyIds, (function (propertyId) {
                        return propertyId === property.id;
                      }));
                if (isEventPropertyFoundInVariation || !ModelUtils.isPropertyIncludedOnEventAndSource(undefined, property, modelEvent.id, sourceId, model)(undefined) || ModelUtils.isPropertyOptionalOnEventAndSource(property, modelEvent.id, sourceId, model)) {
                  return ;
                } else {
                  return {
                          TAG: /* MissingExpectedProperty */0,
                          _0: {
                            eventId: modelEvent.id,
                            propertyId: property.id,
                            propertyName: property.name
                          }
                        };
                }
              }));
}

function checkMissingExpectedProperty_mappedModel(eventVariationProperties, modelEvent, sourceId, model) {
  var eventProperties = AvoModel.getResolvedPropertiesForEvent_mappedModel(model.propertyBundles, model.properties, modelEvent);
  var propertyIds = Belt_Array.keepMapU(eventVariationProperties, (function (param) {
          var property = getLikeliestPropertyForEvent__mappedModel(model, param[0], modelEvent, param[1]);
          if (property !== undefined) {
            return property.id;
          }
          
        }));
  return Belt_Array.keepMapU(eventProperties, (function (property) {
                var isEventPropertyFoundInVariation = Belt_Array.someU(propertyIds, (function (propertyId) {
                        return propertyId === property.id;
                      }));
                if (isEventPropertyFoundInVariation || ModelUtils_mapped.isPropertyOptionalOnEventAndSource(modelEvent.id, sourceId, model.events, property)) {
                  return ;
                } else {
                  return {
                          TAG: /* MissingExpectedProperty */0,
                          _0: {
                            eventId: modelEvent.id,
                            propertyId: property.id,
                            propertyName: property.name
                          }
                        };
                }
              }));
}

function checkPropertyTypeInconsistentWithTrackingPlan(property, inspectorPropertyType, eventId, sourceId, model) {
  if (property === undefined) {
    return ;
  }
  var rawType = InspectorPropertyType.getRawType(inspectorPropertyType, property.type_);
  if (rawType === undefined) {
    return ;
  }
  var typesAreEqualRegardingLists = true;
  if (property.type_ !== "any") {
    var match = property.list;
    var match$1 = InspectorPropertyType.isList(inspectorPropertyType);
    typesAreEqualRegardingLists = match ? (
        match$1 ? true : false
      ) : (
        match$1 ? false : true
      );
  }
  if (InspectorTrackingPlanUtils.receivedPropertyTypeMatchesTrackingPlan(property, rawType, ModelUtils.isPropertyOptionalOnEventsAndSources(Belt_List.keepMap({
                  hd: eventId,
                  tl: /* [] */0
                }, (function (i) {
                    return i;
                  })), {
              hd: sourceId,
              tl: /* [] */0
            }, model, property)) && typesAreEqualRegardingLists) {
    return ;
  } else {
    return {
            TAG: /* PropertyTypeInconsistentWithTrackingPlan */1,
            _0: {
              eventId: eventId,
              propertyId: property.id,
              propertyName: property.name,
              expectedPropertyType: property.list ? InspectorPropertyType.addListToRawType(property.type_) : property.type_,
              actualPropertyType: InspectorPropertyType.enrichListPropertyTypeWithTrackingPlan(inspectorPropertyType, property.type_)
            }
          };
  }
}

function checkPropertyTypeInconsistentWithTrackingPlan_mappedModel(inspectorPropertyType, eventId, sourceId, events, property) {
  if (property === undefined) {
    return ;
  }
  var rawType = InspectorPropertyType.getRawType(inspectorPropertyType, property.type_);
  if (rawType === undefined) {
    return ;
  }
  var match = property.list;
  var match$1 = InspectorPropertyType.isList(inspectorPropertyType);
  var typesAreEqualRegardingLists = match ? (
      match$1 ? true : false
    ) : (
      match$1 ? false : true
    );
  if (InspectorTrackingPlanUtils.receivedPropertyTypeMatchesTrackingPlan(property, rawType, ModelUtils_mapped.isPropertyOptionalOnEventsAndSources(Belt_List.keepMap({
                  hd: eventId,
                  tl: /* [] */0
                }, (function (i) {
                    return i;
                  })), {
              hd: sourceId,
              tl: /* [] */0
            }, events, property)) && typesAreEqualRegardingLists) {
    return ;
  } else {
    return {
            TAG: /* PropertyTypeInconsistentWithTrackingPlan */1,
            _0: {
              eventId: eventId,
              propertyId: property.id,
              propertyName: property.name,
              expectedPropertyType: property.list ? InspectorPropertyType.addListToRawType(property.type_) : property.type_,
              actualPropertyType: InspectorPropertyType.enrichListPropertyTypeWithTrackingPlan(inspectorPropertyType, property.type_)
            }
          };
  }
}

function checkPropertyIssues(propertyNameSignature, propertyTypeSignature, sourceId, model, modelEvent) {
  var properties = Belt_Array.zip(propertyNameSignature, propertyTypeSignature);
  var missingExpectedPropertyIssues = checkMissingExpectedProperty(properties, modelEvent, sourceId, model);
  return Belt_Array.concat(Belt_Array.concatMany(Belt_Array.mapU(properties, (function (param) {
                        var propertyName = param[0];
                        var propertyType = Belt_Option.getWithDefault(InspectorPropertyType.consolidatePropertyType(param[1]), "null");
                        var property = getLikeliestPropertyForEvent(model, propertyName, modelEvent, propertyType);
                        return Belt_Array.keepMap([
                                    checkPropertyTypeInconsistentWithTrackingPlan(property, propertyType, modelEvent.id, sourceId, model),
                                    checkUnexpectedProperty(property, sourceId, modelEvent, model, propertyName, propertyType)
                                  ], (function (i) {
                                      return i;
                                    }));
                      }))), missingExpectedPropertyIssues);
}

function checkPropertyIssues_mappedModel(propertyNameSignature, propertyTypeSignature, sourceId, model, modelEvent) {
  var properties = Belt_Array.zip(propertyNameSignature, propertyTypeSignature);
  var missingExpectedPropertyIssues = checkMissingExpectedProperty_mappedModel(properties, modelEvent, sourceId, model);
  return Belt_Array.concat(Belt_Array.concatMany(Belt_Array.mapU(properties, (function (param) {
                        var propertyName = param[0];
                        var propertyType = Belt_Option.getWithDefault(InspectorPropertyType.consolidatePropertyType(param[1]), "null");
                        var property = getLikeliestPropertyForEvent__mappedModel(model, propertyName, modelEvent, propertyType);
                        return Belt_Array.keepMap([
                                    checkPropertyTypeInconsistentWithTrackingPlan_mappedModel(propertyType, modelEvent.id, sourceId, model.events, property),
                                    checkUnexpectedProperty_mappedModel(property, sourceId, modelEvent, model.events, model.propertyBundles, propertyName, propertyType)
                                  ], (function (i) {
                                      return i;
                                    }));
                      }))), missingExpectedPropertyIssues);
}

function comparePropertiesVariations(sourceId, propertyNameSignature, propertyTypeSignature, otherPropertyNameSignature, otherPropertyTypeSignature, model) {
  var otherEventVariationProperties = Belt_Array.zip(otherPropertyNameSignature, otherPropertyTypeSignature);
  var eventVariationProperties = Belt_Array.zip(propertyNameSignature, propertyTypeSignature);
  return Belt_Array.keepMapU(eventVariationProperties, (function (param) {
                var type_ = param[1];
                var name = param[0];
                var property = ModelUtils.getPropertyByNameOrNameMapping(name, model, undefined);
                var propertyType = Belt_Option.getWithDefault(InspectorPropertyType.consolidatePropertyType(type_), "null");
                if (property !== undefined) {
                  return checkPropertyTypeInconsistentWithTrackingPlan(property, propertyType, undefined, sourceId, model);
                }
                var otherProperty = Belt_Array.getByU(otherEventVariationProperties, (function (param) {
                        return name === param[0];
                      }));
                if (otherProperty === undefined) {
                  return ;
                }
                var otherType = otherProperty[1];
                if (InspectorPropertyType.comparePropertyTypes(type_, otherType)) {
                  return ;
                }
                var type_$1 = Belt_Option.getExn(InspectorPropertyType.consolidatePropertyType(type_));
                var otherType$1 = Belt_Option.getExn(InspectorPropertyType.consolidatePropertyType(otherType));
                var types = type_$1.localeCompare(otherType$1) <= 0.0 ? [
                    type_$1,
                    otherType$1
                  ] : [
                    otherType$1,
                    type_$1
                  ];
                return {
                        TAG: /* InconsistentType */3,
                        _0: {
                          propertyName: name,
                          propertyTypes: [
                            types[0],
                            types[1]
                          ]
                        }
                      };
              }));
}

function comparePropertiesVariations_mappedModel(sourceId, propertyNameSignature, propertyTypeSignature, otherPropertyNameSignature, otherPropertyTypeSignature, model) {
  var otherEventVariationProperties = Belt_Array.zip(otherPropertyNameSignature, otherPropertyTypeSignature);
  var eventVariationProperties = Belt_Array.zip(propertyNameSignature, propertyTypeSignature);
  return Belt_Array.keepMapU(eventVariationProperties, (function (param) {
                var type_ = param[1];
                var name = param[0];
                var property = ModelUtils_mapped.getPropertyByNameOrNameMapping(model.rules, model.destinations, model.properties, name, undefined);
                var propertyType = Belt_Option.getWithDefault(InspectorPropertyType.consolidatePropertyType(type_), "null");
                if (property !== undefined) {
                  return checkPropertyTypeInconsistentWithTrackingPlan_mappedModel(propertyType, undefined, sourceId, model.events, property);
                }
                var otherProperty = Belt_Array.getByU(otherEventVariationProperties, (function (param) {
                        return name === param[0];
                      }));
                if (otherProperty === undefined) {
                  return ;
                }
                var otherType = otherProperty[1];
                if (InspectorPropertyType.comparePropertyTypes(type_, otherType)) {
                  return ;
                }
                var type_$1 = Belt_Option.getExn(InspectorPropertyType.consolidatePropertyType(type_));
                var otherType$1 = Belt_Option.getExn(InspectorPropertyType.consolidatePropertyType(otherType));
                var types = type_$1.localeCompare(otherType$1) <= 0.0 ? [
                    type_$1,
                    otherType$1
                  ] : [
                    otherType$1,
                    type_$1
                  ];
                return {
                        TAG: /* InconsistentType */3,
                        _0: {
                          propertyName: name,
                          propertyTypes: [
                            types[0],
                            types[1]
                          ]
                        }
                      };
              }));
}

function compare(a, b) {
  var makeKey = function (issue) {
    return Belt_Option.getWithDefault(issue.eventId, "") + issue.propertyId + issue.propertyName + issue.expectedPropertyType + issue.actualPropertyType;
  };
  return Caml.caml_string_compare(makeKey(a), makeKey(b));
}

var DedupePropertyTypeInconsistentWithTrackingPlanIssue = ArrayExt.Dedupe({
      compare: compare
    });

function mergeInconsistentPropertyTypeIssues(allPropertyIssues) {
  var inconsistentTypeIssues = Belt_Array.keepMap(allPropertyIssues, (function (issue) {
          if (typeof issue === "number" || issue.TAG !== /* InconsistentType */3) {
            return ;
          } else {
            return issue._0;
          }
        }));
  var inconsistentWithTrackingPlanIssues = Belt_Array.keepMap(allPropertyIssues, (function (issue) {
          if (typeof issue === "number" || issue.TAG !== /* PropertyTypeInconsistentWithTrackingPlan */1) {
            return ;
          } else {
            return issue._0;
          }
        }));
  var nonInconsistentTypeIssues = Belt_Array.keepMap(allPropertyIssues, (function (issue) {
          if (typeof issue === "number") {
            return issue;
          }
          switch (issue.TAG | 0) {
            case /* PropertyTypeInconsistentWithTrackingPlan */1 :
            case /* InconsistentType */3 :
                return ;
            default:
              return issue;
          }
        }));
  var mergedInconsistentTypeIssues = Belt_Array.map(Js_dict.values(Belt_Array.reduceU(inconsistentTypeIssues, {}, (function (propertyIssues, issue) {
                  var existingIssue = Js_dict.get(propertyIssues, issue.propertyName);
                  if (existingIssue !== undefined) {
                    var propertyTypes = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concat(existingIssue.propertyTypes, issue.propertyTypes)));
                    var newIssue_propertyName = issue.propertyName;
                    var newIssue = {
                      propertyName: newIssue_propertyName,
                      propertyTypes: propertyTypes
                    };
                    propertyIssues[issue.propertyName] = newIssue;
                    return propertyIssues;
                  }
                  propertyIssues[issue.propertyName] = issue;
                  return propertyIssues;
                }))), (function (issue) {
          return {
                  TAG: /* InconsistentType */3,
                  _0: issue
                };
        }));
  var mergedInconsistentWithTrackingPlanIssues = Belt_Array.map(Curry._1(DedupePropertyTypeInconsistentWithTrackingPlanIssue.dedupe, inconsistentWithTrackingPlanIssues), (function (issue) {
          return {
                  TAG: /* PropertyTypeInconsistentWithTrackingPlan */1,
                  _0: issue
                };
        }));
  return Belt_Array.concatMany([
              nonInconsistentTypeIssues,
              mergedInconsistentTypeIssues,
              mergedInconsistentWithTrackingPlanIssues
            ]);
}

function compare$1(a, b) {
  var makeKey = function (variation) {
    return variation.eventKey + variation.sourceKey + variation.eventName + variation.appVersion + variation.sourceId + variation.propertyNameSignature.join(",") + variation.propertyTypeSignature.join(",") + variation.schemaId;
  };
  return Caml.caml_string_compare(makeKey(a), makeKey(b));
}

var DedupeVariation = ArrayExt.Dedupe({
      compare: compare$1
    });

function validateVariationsAgainstOtherVariations(eventVariation, allEventVariationsForEvent, log, model) {
  var propertyTypeSet = Belt_SetString.fromArray(eventVariation.propertyTypeSignature);
  var propertyNameSet = Belt_SetString.fromArray(eventVariation.propertyNameSignature);
  var allEventVariationsForEvent$1 = Belt_Array.keep(allEventVariationsForEvent, (function (otherVariation) {
          var otherPropertyTypeSet = Belt_SetString.fromArray(otherVariation.propertyTypeSignature);
          var otherPropertyNameSet = Belt_SetString.fromArray(otherVariation.propertyNameSignature);
          return !(Belt_SetString.eq(propertyNameSet, otherPropertyNameSet) && Belt_SetString.eq(propertyTypeSet, otherPropertyTypeSet));
        }));
  var allPropertyIssues = Belt_Array.concatMany(Belt_Array.map(Curry._1(DedupeVariation.dedupe, Belt_Array.keepMap(allEventVariationsForEvent$1, (function (otherVariation) {
                      var invalidPropertyTypeSignatures = Belt_Array.keep(otherVariation.propertyTypeSignature, (function (propertyType) {
                              return !InspectorPropertyType.validatePropertyType(propertyType);
                            }));
                      if (invalidPropertyTypeSignatures.length !== 0) {
                        Belt_Option.forEach(log, (function (log) {
                                return Curry._2(log, "Ignoring existing event variation due to invalid type signature: " + invalidPropertyTypeSignatures.join(", "), {
                                            schemaId: eventVariation.schemaId,
                                            eventVariation: otherVariation,
                                            invalidPropertyTypeSignatures: invalidPropertyTypeSignatures
                                          });
                              }));
                        return ;
                      } else {
                        return {
                                eventVariationKey: otherVariation.eventVariationKey,
                                eventKey: otherVariation.eventKey,
                                sourceKey: otherVariation.sourceKey,
                                eventName: otherVariation.eventName,
                                appVersion: otherVariation.appVersion,
                                sourceId: otherVariation.sourceId,
                                minCreatedAt: otherVariation.minCreatedAt,
                                maxCreatedAt: otherVariation.maxCreatedAt,
                                propertyNameSignature: otherVariation.propertyNameSignature,
                                propertyTypeSignature: Belt_Array.map(otherVariation.propertyTypeSignature, (function (propertyType) {
                                        return Belt_Option.getWithDefault(InspectorPropertyType.consolidatePropertyType(propertyType), "null");
                                      })),
                                schemaId: otherVariation.schemaId,
                                count: otherVariation.count,
                                hour: otherVariation.hour
                              };
                      }
                    }))), (function (otherVariation) {
              return comparePropertiesVariations(eventVariation.sourceId, eventVariation.propertyNameSignature, eventVariation.propertyTypeSignature, otherVariation.propertyNameSignature, otherVariation.propertyTypeSignature, model);
            })));
  var propertyIssues = mergeInconsistentPropertyTypeIssues(allPropertyIssues);
  return Belt_Array.concat([/* EventNotInTrackingPlan */0], propertyIssues);
}

function validateVariationAgainstTrackingPlanEvent(eventVariation, model, $$event) {
  var issue = checkUnexpectedEventIssue(eventVariation.sourceId, $$event, model);
  if (issue !== undefined) {
    return [issue];
  } else {
    return checkPropertyIssues(eventVariation.propertyNameSignature, eventVariation.propertyTypeSignature, eventVariation.sourceId, model, $$event);
  }
}

function walkValidateTrackingPlanEvents(eventVariation, model, _trackingPlanEvents, defaultTrackingPlanEvent) {
  while(true) {
    var trackingPlanEvents = _trackingPlanEvents;
    var $$event = Belt_Option.getWithDefault(Belt_Array.get(trackingPlanEvents, 0), defaultTrackingPlanEvent);
    var trackingPlanEvents$1 = Belt_Array.sliceToEnd(trackingPlanEvents, 1);
    var issues = validateVariationAgainstTrackingPlanEvent(eventVariation, model, $$event);
    if (issues.length === 0) {
      return [
              [],
              InspectorIssuesGeneratorTrackingPlanUtils.getValidateInConfigIfIgnored(eventVariation, $$event)
            ];
    }
    if (trackingPlanEvents$1.length === 0) {
      return [
              validateVariationAgainstTrackingPlanEvent(eventVariation, model, defaultTrackingPlanEvent),
              InspectorIssuesGeneratorTrackingPlanUtils.getValidateInConfigIfIgnored(eventVariation, defaultTrackingPlanEvent)
            ];
    }
    _trackingPlanEvents = trackingPlanEvents$1;
    continue ;
  };
}

function validateVariationAgainstTrackingPlanEvents(eventNamesToTrackingPlanEventsMap, eventVariation, model) {
  var trackingPlanEvents = Belt_Option.getWithDefault(Belt_MapString.get(eventNamesToTrackingPlanEventsMap, eventVariation.eventName), []);
  var maybeDefaultTrackingPlanEvent = InspectorIssuesGeneratorTrackingPlanUtils.getDefaultTrackingPlanEvent(trackingPlanEvents, eventVariation.eventName);
  if (maybeDefaultTrackingPlanEvent !== undefined) {
    return walkValidateTrackingPlanEvents(eventVariation, model, trackingPlanEvents, maybeDefaultTrackingPlanEvent);
  }
  
}

export {
  getTrackingPlanEvent_mappedModel ,
  checkUnexpectedEventIssue ,
  checkUnexpectedEventIssue_mappedModel ,
  checkUnexpectedProperty ,
  checkUnexpectedProperty_mappedModel ,
  getLikeliestPropertyForEvent ,
  getLikeliestPropertyForEvent__mappedModel ,
  checkMissingExpectedProperty ,
  checkMissingExpectedProperty_mappedModel ,
  checkPropertyTypeInconsistentWithTrackingPlan ,
  checkPropertyTypeInconsistentWithTrackingPlan_mappedModel ,
  checkPropertyIssues ,
  checkPropertyIssues_mappedModel ,
  comparePropertiesVariations ,
  comparePropertiesVariations_mappedModel ,
  DedupePropertyTypeInconsistentWithTrackingPlanIssue ,
  mergeInconsistentPropertyTypeIssues ,
  DedupeVariation ,
  validateVariationsAgainstOtherVariations ,
  validateVariationAgainstTrackingPlanEvents ,
  
}
/* DedupePropertyTypeInconsistentWithTrackingPlanIssue Not a pure module */
